import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { queryClient } from 'config/query-client'
import { TellersTeam } from '../../models'

export type AppointTellersTeamParams = {
    team: TellersTeam
    electionId: string
}
export function useAppointTellersTeam() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function appointTellersTeam({ team, electionId }: AppointTellersTeamParams) {
        return await axios(moduleUrl
            + `elections/${electionId}/vote-counting/appoint-teller-team`,
            {
                method: 'post',
                data: {
                    ...team
                }
            })
    }

    return useMutation({
        mutationFn: appointTellersTeam,
        onSuccess: () => {
            queryClient.invalidateQueries(['chief-teller-elections'])
        }
    })
}