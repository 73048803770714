import Empty from 'components/molecules/Empty'
import { ElectionType } from 'models'
import Scrollbars from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { useLsaElectionsData } from '../../hooks'
import ElectionsHeader from '../ElectionsHeader'
import ElectionsItem from '../ElectionsItem'
import ElectionsLoader from '../ElectionsLoader'
import style from './index.module.scss'

const LEFT_AND_RIGHT_BORDER = 2

type ElectionsProps = {
    electionType: ElectionType
}

function Elections({ electionType }: ElectionsProps) {
    const { lsaElectionsData } = useLsaElectionsData()

    const { t } = useTranslation('administration')

    return (
        <div className={style.elections}>
            <ElectionsLoader electionType={electionType} />
            <ElectionsHeader electionType={electionType} />
            {lsaElectionsData.elections?.length ?
                <AutoSizer>
                    {({ height, width }: any) => (
                        <List
                            style={style}
                            outerElementType={Scrollbars}
                            className={style.list}
                            height={height - 89}
                            itemCount={lsaElectionsData.elections.length}
                            itemSize={40}
                            width={width - LEFT_AND_RIGHT_BORDER}
                            itemData={{ electionType }}
                        >
                            {ElectionsItem}
                        </List>
                    )}
                </AutoSizer>
                : <div className={style.empty}>
                    <Empty text={lsaElectionsData.reportError && !lsaElectionsData.elections?.length
                        ? t('elections_not_loaded_reason',
                            {
                                reason: lsaElectionsData.reportError,
                                interpolation: { escapeValue: false }
                            })
                        : undefined} />
                </div>
            }
        </div>
    )
}

export default Elections