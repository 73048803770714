import { Col, Row } from 'antd'
import { useMembersName } from 'api'
import TextArea from 'components/atoms/TextArea'
import dayjs from 'dayjs'
import { useConfirmPopup } from 'hooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useComment, useSaveComment } from '../../../../api'
import { Comment as CommentType } from '../../../../model'
import { useCommentInfo } from '../../hooks'
import style from './index.module.scss'

interface CommentProps {
    viewMode: boolean
    readOnlyComment?: CommentType
}

const COMMENT_LENGTH = 500

function Comment({ viewMode, readOnlyComment }: CommentProps) {
    const { t } = useTranslation('teller')
    const { electionId } = useParams()
    const { mutateAsync: saveComment } = useSaveComment()
    const { data: commentData, refetch } = useComment(electionId || '', !readOnlyComment)
    const savedComment = readOnlyComment || commentData
    const { confirmPortal, showConfirm } = useConfirmPopup()
    const { data: modifiedBy } = useMembersName(!!savedComment?.modifiedBy
        ? [savedComment.modifiedBy]
        : [], !!savedComment?.modifiedBy)
    const { commentInfo, setCommentInfo } = useCommentInfo()

    useEffect(() => {
        if (savedComment?.text) {
            setCommentInfo(current => ({ ...current, comment: savedComment?.text }))
            closeEditMode()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedComment])

    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCommentInfo({ isEditing: true, comment: e.target.value })
    }

    const saveComm = async () => {
        if (electionId) {
            await saveComment({ electionId, comment: commentInfo.comment || '' })
            closeEditMode()
            await refetch()
        }
    }

    const deleteComm = async () => {
        showConfirm({
            text: <div>
                {t('results:are_you_sure_you_want_to_delete_comment')}
            </div>,
            title: t('results:delete_comment'),
            onOk: async () => {
                await saveComment({ electionId: electionId || '', comment: '' })
                await refetch()
                setCommentInfo(current => ({ ...current, comment: '' }))

                enterEditMode()
            },
            okText: t('confirm'),
            reverseButtons: true
        })
    }

    const formatDate = (dateString: string) => dayjs(dateString).format('MM/DD/YYYY hh:mm A')

    const cancel = () => {
        setCommentInfo(current => ({ ...current, comment: savedComment?.text || '' }))
        closeEditMode()
    }

    const enterEditMode = () => {
        setCommentInfo(current => ({ ...current, isEditing: true }))
    }

    const closeEditMode = () => {
        setCommentInfo(current => ({ ...current, isEditing: false }))
    }

    if (viewMode && !savedComment?.text) {
        return (<></>)
    }

    return (
        <div className={style.mainContainer}>
            {confirmPortal}
            <div className={style.headerWrapper}>
                <div className={style.caption}>
                    <div className={style.blockTitles}>
                        <div className={style.blockTitle}>
                            {t('comment')}
                        </div>
                    </div>
                </div>
                <div className={style.commentContent}>
                    {!commentInfo.isEditing && !!savedComment?.text &&
                        <div className="label">
                            {`${t('results:last_edited_by')} ${modifiedBy?.length
                                ? modifiedBy[0].name
                                : ''}, ${formatDate(
                                    savedComment?.modifiedAt || '')}`}
                        </div>
                    }
                    <Row gutter={24} justify="space-evenly">
                        <Col span={viewMode ? 24 : 20}>
                            <TextArea
                                className={style.createModeArea}
                                showCount={commentInfo.isEditing || !savedComment?.text}
                                value={commentInfo.comment}
                                disabled={!(commentInfo.isEditing || !savedComment?.text)}
                                maxLength={COMMENT_LENGTH}
                                autoSize={{ minRows: 3, maxRows: 8 }}
                                placeholder={t('results:add_a_comment')}
                                onChange={handleCommentChange} />
                        </Col>
                        {savedComment?.text && !viewMode &&
                            <Col span={4}>
                                {!commentInfo.isEditing ? (<>
                                    <button className={`${style.commentButton} btn-main-primary`}
                                        type="button"
                                        onClick={enterEditMode}>
                                        {t('edit')}
                                    </button>
                                    <button className={`${style.commentButton} btn-main-secondary`}
                                        onClick={deleteComm}
                                        type="button">
                                        {t('delete')}
                                    </button>
                                </>) : (<>
                                    <button className={`${style.commentButton} btn-main-primary`}
                                        type="button"
                                        disabled={!commentInfo.comment || !commentInfo.comment.trim()}
                                        onClick={saveComm}>
                                        {t('results:save_comment')}
                                    </button>
                                    <button className={`${style.commentButton} btn-main-secondary`}
                                        type="button"
                                        onClick={cancel}>
                                        {t('cancel')}
                                    </button>
                                </>)}
                            </Col>}
                        {!viewMode && !savedComment?.text &&
                            <Col span={4}>
                                <button className={`${style.commentButton} btn-main-primary`}
                                    type="button"
                                    disabled={!commentInfo.comment || !commentInfo.comment.trim()}
                                    onClick={saveComm}>
                                    {t('results:post')}
                                </button>
                            </Col>
                        }
                    </Row>
                </div>
            </div>
        </div>
    )
}


export default Comment