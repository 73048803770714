import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { queryClient } from 'config/query-client'

export function useCancelOfficersElection() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function cancelOfficersElection(electionPeriod: string) {
        return await axios(moduleUrl + `officers-election-periods/${electionPeriod}/cancel`,
            {
                method: 'post',
                data: {}
            })
    }

    return useMutation({
        mutationFn: cancelOfficersElection,
        onSuccess: () => {
            queryClient.invalidateQueries(['my-officers-election'])
        }
    })
}