import PopupTitle from 'components/atoms/PopupTitle'
import { usePopup } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { usePartialReset } from '../api'
import ChooseStationsAndMode from '../components/ChooseStationsAndMode'
import ConfirmChosen from '../components/ConfirmChosen'
import FailedReset from '../components/FailedReset'
import SuccessfulReset from '../components/SuccessfulReset'
import { StationResetMode } from '../models'

export const useResetStationEntriesProcess = () => {
    const { popupPortal, show, hide } = usePopup()
    const { t } = useTranslation('teller')
    const { mutateAsync: performReset } = usePartialReset()
    const { electionId } = useParams()

    const startResetProcess = () => {
        show({
            title: <PopupTitle>{t('reset_entry_at_station_level')}</PopupTitle>,
            children: <ChooseStationsAndMode hide={hide}
                reset={(mode: StationResetMode, stations: string[]) => {
                    hide()
                    confirmChosen(mode, stations)
                }} />,
            footer: null
        })
    }

    const confirmChosen = (mode: StationResetMode, stations: string[]) => {
        show({
            title: <PopupTitle>{t('reset_entry_at_station_level')}</PopupTitle>,
            children: <ConfirmChosen hide={hide}
                stations={stations}
                mode={mode}
                confirm={async () => {
                    const failedStations: string[] = []
                    for (let i = 0; i < stations.length; i++) {
                        try {
                            await performReset({
                                electionId: electionId || '',
                                stationId: stations[i],
                                mode
                            })
                        }
                        catch {
                            failedStations.push(stations[i])
                        }
                    }

                    if (failedStations.length) {
                        failedReset(failedStations)
                    } else {
                        successfulReset(mode, stations)
                    }
                }} />,
            footer: null
        })
    }

    const successfulReset = (mode: StationResetMode, stations: string[]) => {
        show({
            title: <PopupTitle>{t('success')}</PopupTitle>,
            children: <SuccessfulReset
                hide={hide}
                stations={stations}
                mode={mode} />,
            footer: null
        })
    }

    const failedReset = (stations: string[]) => {
        show({
            title: <PopupTitle>{t('error')}</PopupTitle>,
            children: <FailedReset
                hide={hide}
                failedStations={stations}
            />,
            footer: null
        })
    }

    return { startResetProcess, popupPortal }
}