import { auth } from 'features/auth'
import { useChangeUser } from 'hooks'
import { useEffect, useState } from 'react'

export const useReturnFromStationToUser = () => {
    const [sessionIsOver, setSessionIsOver] = useState(false)
    const [onSuccessCb, setOnSuccessCb] = useState<() => void>()
    const { data: myDetails } = auth.api.query.useMyDetails(sessionIsOver)
    const { changeAuth } = useChangeUser()


    useEffect(() => {
        if (sessionIsOver && myDetails) {
            localStorage.removeItem('theme')
            localStorage.removeItem('user')

            changeAuth({ homeLocality: myDetails.homeLocality, id: myDetails.id, name: myDetails.name })
            onSuccessCb?.()
        }
    }, [myDetails, onSuccessCb, sessionIsOver])

    const returnFromStationToUser = (onSuccess?: () => void) => {
        setOnSuccessCb(() => onSuccess)
        setSessionIsOver(true)
    }

    return { returnFromStationToUser }
}