import { useCopyToClipboardWithToast } from 'hooks'
import { ReactComponent as Call } from 'icons/call.svg'
import { ReactComponent as Envelope } from 'icons/envelope.svg'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import style from './index.module.scss'

function Footer() {
    const { t } = useTranslation('auth')
    const { copy, contextHolder } = useCopyToClipboardWithToast()

    return (
        <div className={`${style.footer} ${isMobile ? style.mobile : ''}`}>
            <div tabIndex={0} role={'contentinfo'} className={style.operatingHours}>
                {t('bnc_operating_hours')}
            </div>
            <div className={style.contacts}>
                {contextHolder}
                <div
                    role={'contentinfo'}
                    tabIndex={0}
                    aria-label={`${t('phone')},`}
                    className={style.contact}
                    onClick={() => { copy('8477333445') }}>
                    <Call />
                    (847) 733-3445
                </div>
                <div className={style.contact}>
                    <Envelope />
                    <Link aria-label={`, ${t('email')}, membership@usbnc.org`}
                        to="mailto:membership@usbnc.org"> membership@usbnc.org</Link>
                </div>
            </div>
        </div >
    )
}

export default Footer