import { $createLinkNode, $isAutoLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { $findMatchingParent, mergeRegister } from '@lexical/utils'
import Input from 'components/atoms/Input'
import {
    $getSelection,
    $isRangeSelection,
    BaseSelection,
    COMMAND_PRIORITY_HIGH,
    COMMAND_PRIORITY_LOW,
    KEY_ESCAPE_COMMAND,
    LexicalEditor,
    SELECTION_CHANGE_COMMAND
} from 'lexical'
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getSelectedNode, sanitizeUrl, setFloatingElemPositionForLinkEditor } from 'utils'
import style from './index.module.scss'

type RichTextEditorLinkEditorProps = {
    editor: LexicalEditor
    isLink: boolean
    setIsLink: Dispatch<boolean>
    anchorElem: HTMLElement
    isLinkEditMode: boolean
    setIsLinkEditMode: Dispatch<boolean>
}

function RichTextEditorLinkEditor({
    editor,
    isLink,
    setIsLink,
    anchorElem,
    isLinkEditMode,
    setIsLinkEditMode
}: RichTextEditorLinkEditorProps) {
    const { t } = useTranslation()
    const editorRef = useRef<HTMLDivElement | null>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const [linkUrl, setLinkUrl] = useState('')
    const [editedLinkUrl, setEditedLinkUrl] = useState('https://')
    const [lastSelection, setLastSelection] = useState<BaseSelection | null>(null)

    const $updateLinkEditor = useCallback(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection)
            const linkParent = $findMatchingParent(node, $isLinkNode)

            if (linkParent) {
                setLinkUrl((linkParent as any).getURL())
            } else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL())
            } else {
                setLinkUrl('')
            }
            if (isLinkEditMode) {
                setEditedLinkUrl(linkUrl)
            }
        }
        const editorElem = editorRef.current
        const nativeSelection = window.getSelection()
        const activeElement = document.activeElement

        if (editorElem === null) {
            return
        }

        const rootElement = editor.getRootElement()

        if (
            selection !== null &&
            nativeSelection !== null &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode) &&
            editor.isEditable()
        ) {
            const domRect: DOMRect | undefined =
                nativeSelection.focusNode?.parentElement?.getBoundingClientRect()
            if (domRect) {
                domRect.y += 120
                setFloatingElemPositionForLinkEditor(domRect, editorElem, anchorElem)
            }
            setLastSelection(selection)
        } else if (!activeElement || activeElement.className !== 'link-input') {
            if (rootElement !== null) {
                setFloatingElemPositionForLinkEditor(null, editorElem, anchorElem)
            }
            setLastSelection(null)
            setIsLinkEditMode(false)
            setLinkUrl('')
        }

        return true
    }, [anchorElem, editor, setIsLinkEditMode, isLinkEditMode, linkUrl])

    useEffect(() => {
        const scrollerElem = anchorElem.parentElement

        const update = () => {
            editor.getEditorState().read(() => {
                $updateLinkEditor()
            })
        }

        window.addEventListener('resize', update)

        if (scrollerElem) {
            scrollerElem.addEventListener('scroll', update)
        }

        return () => {
            window.removeEventListener('resize', update)

            if (scrollerElem) {
                scrollerElem.removeEventListener('scroll', update)
            }
        }
    }, [anchorElem.parentElement, editor, $updateLinkEditor])

    useEffect(() => mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                $updateLinkEditor()
            })
        }),

        editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            () => {
                $updateLinkEditor()

                return true
            },
            COMMAND_PRIORITY_LOW
        ),
        editor.registerCommand(
            KEY_ESCAPE_COMMAND,
            () => {
                if (isLink) {
                    setIsLink(false)

                    return true
                }

                return false
            },
            COMMAND_PRIORITY_HIGH
        )
    ), [editor, $updateLinkEditor, setIsLink, isLink])

    useEffect(() => {
        editor.getEditorState().read(() => {
            $updateLinkEditor()
        })
    }, [editor, $updateLinkEditor])

    useEffect(() => {
        if (isLinkEditMode && inputRef.current) {
             inputRef.current.focus()
        }
    }, [isLinkEditMode, isLink])

    const monitorInputInteraction = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            handleLinkSubmission()
        } else if (event.key === 'Escape') {
            event.preventDefault()
            setIsLinkEditMode(false)
        }
    }

    const handleLinkSubmission = () => {
        if (lastSelection !== null) {
            if (linkUrl !== '') {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(editedLinkUrl))
                editor.update(() => {
                    const selection = $getSelection()
                    if ($isRangeSelection(selection)) {
                        const parent = getSelectedNode(selection).getParent()
                        if ($isAutoLinkNode(parent)) {
                            const linkNode = $createLinkNode(parent.getURL(), {
                                rel: parent.__rel,
                                target: parent.__target,
                                title: parent.__title
                            })
                            parent.replace(linkNode, true)
                        }
                    }
                })
            }
            setEditedLinkUrl('https://')
            setIsLinkEditMode(false)
        }
    }

    return (
        <div ref={editorRef} className={style.linkEditor}>
            {!isLink ? null : isLinkEditMode ? (
                <div className={style.editor}>
                    <Input
                        ref={inputRef}
                        className="link-input"
                        value={editedLinkUrl}
                        placeholder={'Link URL'}
                        onChange={(event) => {
                            setEditedLinkUrl(event.target.value)
                        }}
                        onKeyDown={(event) => {
                            monitorInputInteraction(event)
                        }}
                    />
                    <button className="btn-main-secondary-md"
                        type="button"
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => { setIsLinkEditMode(false) }}
                    >
                        {t('cancel')}
                    </button>
                    <button className="btn-main-primary-md"
                        type="button"
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={handleLinkSubmission}
                    >
                        {t('ok')}
                    </button>
                </div>
            ) : (
                <div className={style.viewLink}>
                    <Link
                        to={sanitizeUrl(linkUrl)}
                        target="_blank"
                        className={`${style.link} ellipsis`}
                    >
                        {linkUrl}
                    </Link>
                    <button className="btn-main-tertiary-md"
                        type="button"
                        tabIndex={0}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => {
                            if (isLink) {
                                setIsLink(false)
                            }
                        }}
                    >
                        {t('cancel')}
                    </button>
                    <button className="btn-main-secondary-md"
                        type="button"
                        tabIndex={0}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => {
                            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
                        }}
                    >
                        {t('delete')}
                    </button>
                    <button className="btn-main-primary-md"
                        type="button"
                        tabIndex={0}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => {
                            setEditedLinkUrl(linkUrl)
                            setIsLinkEditMode(true)
                        }}
                    >
                        {t('edit')}

                    </button>
                </div>
            )}
        </div>
    )
}

export default RichTextEditorLinkEditor