import { useBreakingTieRounds, useElection } from 'api'
import Card from 'components/atoms/Card'
import ElectionBadge from 'components/molecules/ElectionBadge'
import Note from 'components/molecules/Note'
import { useAuth, useConfirmPopup, useHandleEntityLoadingError } from 'hooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useCancelLastTieRoundResultPopup, useMergeResultsData } from '../../hooks'
import TieInputPhysicalBallotsTable from '../TieInputPhysicalBallotsTable'
import style from './index.module.scss'

type TieInputPhysicalBallotsProps = {
    onEndRevote: () => void
}

function TieInputPhysicalBallots({ onEndRevote }: TieInputPhysicalBallotsProps) {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const { electionId } = useParams()
    const { confirmPortal, showConfirm, hide } = useConfirmPopup()
    const { cancelLastTieRoundResultPopup } = useCancelLastTieRoundResultPopup(showConfirm, hide)
    const navigate = useNavigate()
    const { setMergedResultsData } = useMergeResultsData()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()

    const { data: tieElection, error } = useElection(electionId || '', !!electionId)
    const { data: breakingTieRounds } = useBreakingTieRounds(auth!.electionId!, !!auth!.electionId!)

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if (breakingTieRounds && breakingTieRounds.length > 0) {
            setMergedResultsData(current =>
                ({ ...current, lastRound: breakingTieRounds[breakingTieRounds.length - 1] }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakingTieRounds])

    const onClearAllClick = () => {
        cancelLastTieRoundResultPopup(false, () => {
            navigate(`/merged-results${tieElection?.originatingElection
                ? ('/' + tieElection.originatingElection)
                : ''}`, { replace: true })
        })
    }

    return (
        <>
            {confirmPortal}
            <div className={style.cardWrapper}>
                <Card
                    noContentPadding
                    className={style.card}
                    title={
                        <div className={style.titleWrapper}>
                            {t('input_physical_ballots')}
                            {!!tieElection && <ElectionBadge status={tieElection.status} />}
                        </div>
                    }>
                    <div className={style.noteWrapper}>
                        <Note mode="info" icon="regular">
                            <div>
                                {t('manually_input_votes_collected_by_other_methods_using_the_buttons')}<br />
                                {t('other_methods_can_include_physical_ballots_or_ballots_submitted_by_phone')}
                            </div>
                        </Note>
                    </div>
                    <div className={style.tableWrapper}>
                        <TieInputPhysicalBallotsTable />
                    </div>

                    <div className={style.buttonWrapper}>
                        <button style={{ width: 302 }} className="btn-main-secondary"
                            onClick={() => onClearAllClick()}                     >
                            {t('clear_all_votes')}
                        </button>
                        <button
                            style={{ width: 302 }}
                            className="btn-main-primary"
                            onClick={() => { onEndRevote() }}>
                            {t('end_revote')}
                        </button>
                    </div>
                </Card>
            </div>
        </>
    )
}

export default TieInputPhysicalBallots