import { Dispatch, SetStateAction, createContext } from 'react'
import { VoterVerificationTableData } from '../models'

export type VoterVerificationTableDataContextProps = {
    tableData: VoterVerificationTableData,
    setTableData: Dispatch<SetStateAction<VoterVerificationTableData>>
}

export const VoterVerificationTableDataContext =
    createContext<VoterVerificationTableDataContextProps>({} as VoterVerificationTableDataContextProps)
