import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionType, ParticipantsStatistic } from 'models'
import { getElectionTypeApiModule } from '../../utils'

export const useLocalDelegateParticipantsStatistic = (electionPeriodId: string,
    enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getParticipantsStatistic = async ({ queryKey }: QueryFunctionContext)
        : Promise<ParticipantsStatistic[]> => {
        const [, electionPeriodId] = queryKey

        const result = await axios<ParticipantsStatistic[]>(
            `${moduleUrl}ridvan-election-periods/${electionPeriodId}/` +
            `${getElectionTypeApiModule(ElectionType.LOCAL_DELEGATE_ELECTION)}/participants/count`)

        return result.data

    }

    return useQuery({
        queryKey: ['local-delegate-participants-statistic', electionPeriodId],
        queryFn: getParticipantsStatistic,
        enabled
    })
}