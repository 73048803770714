import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ConfirmPopupSettings } from './popup'

export const useSignOut = (showConfirm: (settings: ConfirmPopupSettings) => void) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const signOut = (forse?: boolean) => {
        if (forse) {
            showConfirm({
                title: t('session_timeout'),
                text: <>
                    {t('your_connection_times_out_after_you_ve_been_inactive_for_a_while')}<br />
                    <strong>
                        {t('you_are_about_to_return_to_the_login_screen')}.
                    </strong>
                </>,
                stayOpenedOnCancel: true,
                onOk: async () => {
                    navigate('/sign-in')
                }
            })
        } else {
            showConfirm({
                title: t('log_out'),
                text: t('you_are_about_to_return_to_the_login_screen'),
                okText: t('log_out'),
                onOk: async () => {
                    navigate('/sign-in')
                }
            })
        }
    }

    return { signOut }
}