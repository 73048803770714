import { Tooltip as AntTooltip } from 'antd'
import { TooltipPropsWithOverlay, TooltipPropsWithTitle } from 'antd/es/tooltip'
import style from './index.module.scss'

export type TooltipProps = TooltipPropsWithTitle | TooltipPropsWithOverlay

function Tooltip(props: TooltipProps) {
    return (
        <AntTooltip
            {...props}
            overlayClassName={`${style.tooltip} ${props.className}`}/>
    )
}

export default Tooltip