import { FormInstance } from 'antd'
import { useTranslation } from 'react-i18next'
import DateAndTimeOrJustTime from '../DateAndTimeOrJustTime'
import SectionTitle from '../SectionTitle'
import SelectTime from '../SelectTime'
type ShowVotingInPersonPopupProps = {
    form: FormInstance
    timeOnly?: boolean
}

function ShowVotingInPersonPopup({ form, timeOnly }: ShowVotingInPersonPopupProps) {
    const { t } = useTranslation('configuration')

    return (
        <>
            {timeOnly ?
                <SelectTime
                    formProperty="showVotingInPersonPopupDateTypeTime"
                    sectionTitle={<SectionTitle>{t('show_voting_in_person_pop_up')}:</SectionTitle>}
                    inputNote={t('display_prompt_for_online_voter_from_specific_time')}
                /> :
                <DateAndTimeOrJustTime form={form}
                    sectionTitle={<SectionTitle>{t('show_voting_in_person_pop_up')}:</SectionTitle>}
                    radioFormProperty="showVotingInPersonPopupDateType"
                    inputNote={t('display_prompt_for_online_voter')} />
            }</>
    )
}

export default ShowVotingInPersonPopup