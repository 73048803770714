import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { AssignedTellers } from '../../models'

export const useOfficerElectionTellers = (localityCode: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getOfficerElectionTellers = async () => {
        const response = await axios<AssignedTellers>(moduleUrl
            + `localities/${localityCode}/officers-election-tellers`)

        return response.data
    }

    return useQuery({
        queryKey: ['officers-election-tellers', localityCode],
        queryFn: getOfficerElectionTellers,
        enabled
    })
}