import { FormInstance } from 'antd'
import { useTranslation } from 'react-i18next'
import DateAndTimeOrJustTime from '../DateAndTimeOrJustTime'
import SectionTitle from '../SectionTitle'
import SelectTime from '../SelectTime'

type StartTellingProcessFromProps = {
    form: FormInstance
    timeOnly?: boolean
}

function StartTellingProcessFrom({ form, timeOnly }: StartTellingProcessFromProps) {
    const { t } = useTranslation('configuration')

    return (<>
        {timeOnly ? 
            <SelectTime
                formProperty="startTellingProcessDateTypeTime"
                sectionTitle={<SectionTitle>{t('start_telling_process_from')}:</SectionTitle>}
                inputNote={t('allows_to_start_telling_process_from_specific_time')}
            /> :
        <DateAndTimeOrJustTime form={form}
            sectionTitle={<SectionTitle>{t('start_telling_process_from')}:</SectionTitle>}
            radioFormProperty="startTellingProcessDateType"
            inputNote={t('allows_to_start_telling_process_from_specific_time_date_n_time_local_timezone')} />
    }</>
    )
}

export default StartTellingProcessFrom