import Badge, { BadgeType } from 'components/atoms/Badge'
import { useElectionPushStatusMap } from 'hooks'
import { ElectionPushStatus } from 'models'


type ElectionPushStatusBadgeProps = {
    status: ElectionPushStatus
}

const ElectionPushBadge = ({ status }: ElectionPushStatusBadgeProps) => {
    const { toString } = useElectionPushStatusMap()

    let type = BadgeType.Initial
    switch (status) {
        case ElectionPushStatus.PENDING:
            type = BadgeType.Pending
            break
        case ElectionPushStatus.ACCEPTED:
            type = BadgeType.Success
            break
        case ElectionPushStatus.UNKNOWN:
        case ElectionPushStatus.REJECTED:
        case ElectionPushStatus.AZURE_ERROR:
            type = BadgeType.Error
            break
    }

    return <Badge title={toString(ElectionPushStatus[status])} type={type} />
}

export default ElectionPushBadge