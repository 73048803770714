import { useElection, useElectionsBallotsSummary, useElectionsVotesCount } from 'api'
import Tooltip from 'components/atoms/Tooltip'
import { ReactComponent as InfoRounded } from 'icons/info-rounded.svg'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'
type UnitElectionBallotsDetailsProps = {
    allElectionsIds: string[],
    electionId: string
}

function UnitElectionBallotsDetails({
    allElectionsIds,
    electionId }: UnitElectionBallotsDetailsProps) {
    const { t } = useTranslation('election')

    const [invalidVotes, setInvalidVotes] = useState(0)
    const [invalidBallots, setInvalidBallots] = useState(0)
    const [validBallots, setValidBallots] = useState(0)
    const { data: electionsVotesCount } = useElectionsVotesCount({ electionIds: allElectionsIds })
    const { data: electionsBallotsSummary } = useElectionsBallotsSummary({ electionIds: allElectionsIds })
    const { data: election } = useElection(electionId)

    useEffect(() => {
        const votesCount = electionsVotesCount?.find(s => s.election.toString() === electionId)
        if (votesCount) {
            setInvalidVotes(votesCount.votes.invalidVoteCount)
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [electionsVotesCount])

    useEffect(() => {
        const ballotsSummary = electionsBallotsSummary?.find(s => s.election.toString() === electionId)
        if (ballotsSummary) {
            setInvalidBallots(ballotsSummary.ballots.invalidBallotCount)
            setValidBallots(ballotsSummary.ballots.validBallotCount)
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [electionsBallotsSummary])

    return (
        <>
            {!election?.bypassFlow ? (
                <Tooltip
                    title={
                        <>
                            <div>{t('valid_ballots')}: {validBallots}</div>
                            <div>{t('invalid_ballots')}: {invalidBallots}</div>
                            <div>{t('invalid_votes')}: {invalidVotes}</div>
                        </>
                    }
                >
                    <InfoRounded className={style.info} />
                </Tooltip>
            ) : null}
        </>
    )

}

export default UnitElectionBallotsDetails