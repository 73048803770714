import Card from 'components/atoms/Card'
import { useTranslation } from 'react-i18next'
import { BallotingElection } from '../../models/balloting-election'
import ElectionItem from '../ElectionItem'
import style from './index.module.scss'

export interface AvailableElectionsProps {
    elections: BallotingElection[]
}

export function AvaliableElections({ elections }: AvailableElectionsProps) {
    const { t } = useTranslation('voter')

    return (
        <Card
            noContentPadding={true}
            title={<div tabIndex={0} className={style.headerText}>{t('available_election')}</div>}>
            {elections?.map((election) => (
                <ElectionItem
                    key={election.id}
                    election={election}
                />
            ))}
        </Card>
    )
}

export default AvaliableElections