import { useElection } from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import LeaveFeedback from 'components/organisms/LeaveFeedback'
import { auth as authFeature } from 'features/auth'
import { electionManagement } from 'features/electionManagement'
import { useAuth, useChangeUser } from 'hooks'
import { ReactComponent as ChevronBold } from 'icons/chevron-bold.svg'
import { ElectionType, Role } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import style from './index.module.scss'

function LeaveTellerFeedback() {
    const { t } = useTranslation('teller')
    const navigate = useNavigate()
    const { changeRole } = useChangeUser()
    const { availableRoles } = authFeature.hooks.useAvailableRoles()
    const { auth } = useAuth()

    const { data: election } = useElection(auth?.electionId!, !!auth?.electionId)
    const { data: elections } = electionManagement.api.queries.useElections()

    const isElectionValid = useMemo(() => {
        if (!elections || elections.length === 0) {
            return false
        }

        return elections.some(e => e.id === election?.id)
    }, [elections, election])

    const proceedToResultConfirmation = () => {
        const electionId = auth?.electionId
        changeRole(Role.LSA_OFFICER)
        navigate(`/confirm-results/${electionId}`, {
            state: {
                backButtonTitle: election?.type === ElectionType.LOCAL_DELEGATE_ELECTION
                    ? t('election:back_to_election_board')
                    : t('back_to_dashboard')
            }
        })
    }

    const backToDashboard = () => {
        navigate('/', { replace: true })
    }

    const getMessage = () => {
        if (!availableRoles.isLsaOfficer) {
            return t('your_lsa_officer_will_now_have_to_confirm_and_send_results_to_dest', {
                dest: t('eMembership2')
            })
        } else if (availableRoles.isLsaOfficer && isElectionValid) {
            return t('you_as_the_lsa_officer_will_now_have_to_confirm_and_send_results_to_dest', {
                dest: t('eMembership2')
            })
        } else {
            return t('your_hosting_lsa_officer_will_now_have_to_confirm_and_send_results_to_dest', {
                dest: t('eMembership2')
            })
        }
    }


    return (
        <LeaveFeedback>
            <div className={style.thankYouWrapper}>
                <div className={style.thankYou}>
                    <div className={style.title}>{t('thank_you')}</div>
                    <div className={style.message}>
                        {t('your_telling_process_is_now_completed')}<br />
                        {getMessage()}
                    </div>
                    {(isElectionValid && availableRoles.isLsaOfficer)
                        ? (
                            <button
                                onClick={proceedToResultConfirmation}
                                className={`btn-main-primary ${style.backBtn}`}>
                                {t('proceed_with_result_confirmation')}
                            </button>
                        ) : (
                            <button
                                onClick={backToDashboard}
                                className={`btn-main-primary ${style.backBtn}`}>
                                <IconWrapper>
                                    <ChevronBold className="flip-horizontal" width="16" height="16" />
                                </IconWrapper>
                                {t('back_to_dashboard')}
                            </button>
                        )
                    }
                </div>
            </div>
        </LeaveFeedback>
    )
}

export default LeaveTellerFeedback