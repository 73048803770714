
import Footer from 'components/molecules/Footer'
import Elections from '../../components/Elections'
import Panel from '../../components/Panel'
import VotingWrapper from '../../components/VotingWrapper'

export function Dashboard() {
    return (
        <VotingWrapper>
            <Panel >
                <Elections />
            </Panel>
            <Footer />
        </VotingWrapper>
    )
}

export default Dashboard

