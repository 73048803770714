import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { AppointedTellersCounts } from '../../models'

export const useAppointedTellersCounts = (elactionId: number, enabled?: boolean) => {
    const { appointedTellersCountsQueryParams } = useAppointedTellersCountsQueryParams()

    return useQuery(appointedTellersCountsQueryParams(elactionId, enabled))
}

export const useAppointedTellersCountsQueryParams = () => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getAppointedTellersCounts = async ({ queryKey }: QueryFunctionContext)
        : Promise<AppointedTellersCounts> => {
        const [, elactionId] = queryKey

        const result = await axios<AppointedTellersCounts>(
            `${moduleUrl}elections/${elactionId}/appointed-tellers/count`)

        return result.data
    }

    const queryParams = (elactionId: number, enabled?: boolean) => ({
        queryKey: ['appointedTellersCounts', elactionId],
        queryFn: getAppointedTellersCounts,
        enabled
    })

    return { appointedTellersCountsQueryParams: queryParams }
}