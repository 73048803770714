import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export type LaunchStationParams = {
    stationId: string,
    electionId: string
}
export function useLaunchStation() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    async function launchStation({ stationId, electionId }: LaunchStationParams) {
        return await axios(moduleUrl
            + `elections/${electionId}/stations/${stationId}/launch`,
            {
                method: 'post',
                data: {}
            })
    }

    return useMutation({
        mutationFn: launchStation
    })
}