import { Election, ElectionStatusType } from 'models'
import { dayjsToString } from 'utils'
import { AppointedTellersCounts, ElectionItem } from '../models'

export const getElectionItem = (
    election: Election,
    appointedTellersCountsData: AppointedTellersCounts | null,
    electionTitle: string
): ElectionItem => {
    let requiredTellersAssigned: boolean

    if (election.status === ElectionStatusType.COMPLETED) {
        requiredTellersAssigned = true
    } else {
        requiredTellersAssigned = (appointedTellersCountsData!.chiefTellerCount > 0) &&
            (appointedTellersCountsData!.chiefTellerAssistantCount > 0)
    }

    return ({
        status: election.status,
        type: election.type,
        title: electionTitle,
        onlineVotingStartDate: election.onlineVotingStart
            ? dayjsToString(election.onlineVotingStart, 'MM/DD/YYYY')
            : '-',
        electionDay: election.voteCountingStart
            ? dayjsToString(election.voteCountingStart, 'MM/DD/YYYY')
            : '-',
        electionPeriodId: election.electionPeriod,
        requiredTellersAssigned: requiredTellersAssigned,
        resultsSignedBy: election.resultsSignedBy !== null,
        resultsConfirmedBy: election.resultsConfirmedBy !== null,
        electionId: election.id,
        atLeastOneTellerAssigned: !!appointedTellersCountsData?.totalCount
    })
}