import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionParticipant, TellerType } from 'models'
import { getUrlParamsFromFilter, isPhoneLengthCorrect, isPhoneNumberValidFunc } from 'utils'

type CandidatesFilter = {
    id?: string,
    name?: string,
    offset?: number,
    limit?: number,
    possibleRole: TellerType
}


export const useCandidatesToServe = (electionId: string, filter?: CandidatesFilter, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getCandidatesToServe = async ({ queryKey }: QueryFunctionContext)
        : Promise<ElectionParticipant[]> => {
        const [, electionId, filter] = queryKey

        const queryParams = getUrlParamsFromFilter(filter as CandidatesFilter)

        const result = await axios<ElectionParticipant[]>(
            `${moduleUrl}elections/${electionId}/teller-candidates${queryParams ? `?${queryParams}` : ''}`)

        return result.data.map(d => {
            const phone = d.phone

            if (phone && isPhoneLengthCorrect(phone) && isPhoneNumberValidFunc(phone)) {
                return { ...d, phone }
            }

            return { ...d, phone: '' }
        })
    }

    return useQuery({
        queryKey: ['election-candidates', electionId, filter],
        queryFn: getCandidatesToServe,
        enabled
    })
}