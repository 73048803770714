import ContentWrapper from 'components/atoms/ContentWrapper'
import PageHeader from 'components/atoms/PageHeader'
import BackToDashboardBtn from 'components/molecules/BackToDashboardBtn'
import { useConfirmPopup, useHeaderLogoRedirect, usePageHeader } from 'hooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLsaTellersEditing } from '../../hooks'
import ElectedOfficers from '../ElectedOfficers'
import Tellers from '../Tellers'
import style from './index.module.scss'

const ManageOfficersElection = () => {
    const { setPageHeader } = usePageHeader()
    const { t } = useTranslation('officers-election')
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const navigate = useNavigate()
    const { setConfirmationCallback } = useHeaderLogoRedirect()
    const { isEditing: editMode } = useLsaTellersEditing()

    const goBack = () => {
        if (editMode) {
            showConfirm({
                title: t('leave_current_page'),
                text: t('would_you_like_to_leave_this_page_without_saving'),
                okText: <>{t('leave')}</>,
                cancelText: <>{t('continue_editing')}</>,
                onOk: async () => navigate('/', { replace: true })
            })
        } else {
            navigate('/', { replace: true })
        }
    }

    useEffect(() => {
        setConfirmationCallback(editMode)

        return () => {
            setConfirmationCallback(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    useEffect(() => {
        setPageHeader(
            <PageHeader
                greeting={t('election:manage_officers_election')}
                subheader={
                    <BackToDashboardBtn onClick={() => goBack()} />
                }
            />
        )

        return () => setPageHeader(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    return (
        <>
            {confirmPortal}
            <ContentWrapper>
                <div className={style.contentWrapper}>
                    <Tellers />
                    <ElectedOfficers />
                </div>
            </ContentWrapper>
        </>
    )
}

export default ManageOfficersElection