import Footer from 'components/molecules/Footer'
import SelectFunctionPanel from '../components/SelectFunctionPanel'
import AuthPageWrapper from '../components/common/AuthPageWrapper'

function SelectFunction() {

    return (
        <AuthPageWrapper>
            <SelectFunctionPanel />
            <Footer />
        </AuthPageWrapper>
    )
}

export default SelectFunction