import { PropsWithChildren, useState } from 'react'
import { MergedResultsContext } from '../context'
import { MergedResultsData } from '../model'

const MergedResultsProvider = ({ children }: PropsWithChildren) => {
    const [mergedResultsData, setMergedResultsData] = useState<MergedResultsData>({
        resolvedCandidatesName: [],
        isTieResolved: false,
        isAnyBreakingTieRound: false,
        waitingForOnlineVotingStart: false
    })

    return (
        <MergedResultsContext.Provider value={{ mergedResultsData, setMergedResultsData }}>
            {children}
        </MergedResultsContext.Provider >
    )
}

export default MergedResultsProvider