import { useElection, useElectionPushResult, useElectionSummary, useLocalDelegateElectionPushResult } from 'api'
import ContentWrapper from 'components/atoms/ContentWrapper'
import { electionResults } from 'features/electionResults'
import { useAuth } from 'hooks'
import { ElectionType } from 'models'
import { useEffect } from 'react'
import AppointedTellers from '../components/AppointedTellers'
import DashboardHeader from '../components/DashboardHeader'
import EligibleIndividuals from '../components/EligibleIndividuals'
import ResultsManagement from '../components/ResultsManagement'
import Tasks from '../components/Tasks'

export function Dashboard() {
    const { auth } = useAuth()

    useEffect(() => { window.scrollTo({ top: 0, left: 0 }) }, [])

    const { data: summary } = useElectionSummary(auth?.electionId!, !!auth?.electionId)
    const { data: election } = useElection(auth?.electionId?.toString() || '', !!auth?.electionId)
    const { data: ldePushResult } = useLocalDelegateElectionPushResult(election?.electionPeriod.toString() || '',
        election?.type === ElectionType.LOCAL_DELEGATE_ELECTION)
    const { data: electionPushResult } = useElectionPushResult(election?.id.toString() || '',
        !!election && election.type !== ElectionType.LOCAL_DELEGATE_ELECTION)

    const pushResult = ldePushResult || electionPushResult

    return (
        <>
            <DashboardHeader />
            <ContentWrapper>
                {!pushResult?.status && election?.type === ElectionType.LOCAL_DELEGATE_ELECTION &&
                    summary?.confirmedBy && <electionResults.components.LuElectionResultsNotPushedNote />}
                <Tasks />
                <ResultsManagement isOnDashboard />
                <EligibleIndividuals />
                <AppointedTellers />
            </ContentWrapper>
        </>
    )
}

export default Dashboard