import { useCheckReportStatus, useGenerateReport, useReportUrl } from 'api'
import { queryClient } from 'config/query-client'
import { ReportFilter, ReportFormat, ReportParams, ReportStatus, ReportType } from 'models'
import { useEffect, useState } from 'react'

type LoadReportParams = {
    reportType: ReportType,
    reportFormat: ReportFormat,
    params: ReportParams
}

export const useReportLoader = () => {
    const [reportFilter, setReportFilter] = useState<ReportFilter>()
    const [reportIsReady, setReportIsReady] = useState(false)
    const [timerId, setTimerId] = useState<NodeJS.Timer>()
    const [isReportLoading, setIsReportLoading] = useState(false)
    const [error, setError] = useState<string>()

    const { data: reportId } = useGenerateReport(reportFilter || ({} as any), !!reportFilter)
    const { data: status, isLoading: isStatusLoading } = useCheckReportStatus(reportId!, !!reportId)
    const { getReportUrl } = useReportUrl()

    useEffect(() => {
        const resetReportStatusChecking = () => {
            if (timerId) {
                clearInterval(timerId)
                setTimerId(undefined)
                setIsReportLoading(false)
            }
        }
        if (status) {
            if (status.includes(ReportStatus.FAILED)) {
                setError(status.split(`${ReportStatus.FAILED}: `)[1])
            }
            switch (status) {
                case ReportStatus.PENDING:
                case ReportStatus.IN_PROGRESS:
                    if (!timerId) {
                        setIsReportLoading(true)
                        const tId = setInterval(async () => {
                            await queryClient.invalidateQueries(['report-status', reportId])
                        }, 1000)
                        setTimerId(tId)
                    }
                    break
                case ReportStatus.COMPLETED:
                    resetReportStatusChecking()
                    if (!reportIsReady) {
                        const tempLink = document.createElement('a')
                        tempLink.href = getReportUrl(reportId!)
                        tempLink.target = 'blank'
                        tempLink.click()
                        setReportIsReady(true)
                    }
                    break
                default:
                    resetReportStatusChecking()
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, isStatusLoading])

    const loadReport = async ({ reportType, reportFormat, params }: LoadReportParams) => {
        setError(undefined)
        await queryClient.removeQueries(['report',
            reportFilter?.reportType,
            reportFilter?.reportFormat])
        await queryClient.removeQueries(['report-status', reportId])
        setReportIsReady(false)
        setReportFilter({
            reportFormat,
            reportType,
            params
        })
    }

    return { loadReport, isReportLoading, error }
}