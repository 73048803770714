import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ReactElement } from 'react'
import WizardChip from '../WizardChip'
import { IWizardStepProps } from '../models'
import { WizardTabStatus } from '../models/wizard-tab-status'
import styles from './index.module.scss'

interface Props {
    steps: ReactElement<IWizardStepProps>[],
    tabStatuses: WizardTabStatus[],
    currentStepIndex: number,
    goToStep: (index: number) => void
}

function WizardChips({ steps, tabStatuses, currentStepIndex, goToStep }: Props) {

    return (
        <div className={styles.chips}>
            {
                steps.map((step: ReactElement<IWizardStepProps>, index) => {
                    const chipSettings = step.props.chipSettings

                    return (
                        <div key={chipSettings.title} className={styles.chip}>
                            <WizardChip
                                status={tabStatuses[index]}
                                settings={chipSettings}
                                leaveStepCallback={steps[currentStepIndex].props.leaveStepCallback}
                                onClick={() => goToStep(index)}
                            />
                            {steps.indexOf(step) !== steps.length - 1 &&
                                <Chevron width="6.5" height="12" />}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default WizardChips