import { MenuProps } from 'antd'
import IconWrapper from 'components/atoms/IconWrapper'
import Dropdown from 'components/molecules/Dropdown'
import Preloader from 'components/molecules/Preloader'
import { useReportLoader } from 'hooks'
import { ReactComponent as ArrowDownload } from 'icons/arrow-download.svg'
import { ElectionStatusType, ElectionType, ReportFormat, ReportType } from 'models'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LsaElection } from '../../model'
import style from './index.module.scss'

type ElectionReportCellProps = {
    election: LsaElection,
    electionType: ElectionType
}

function ElectionReportCell({ election, electionType }: ElectionReportCellProps) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { loadReport, isReportLoading } = useReportLoader()

    const items: MenuProps['items'] = []

    election?.stations?.sort((a, b) => {
        if (a > b)
            return 1

        if (a < b)
            return -1

        return 0
    }).forEach(s => items.push({
        key: s,
        label: t('teller:station_n', { n: s.replace('S', '') }),
        onClick: () => navigate(`/station-report/${s}/${election.id}`,
            {
                state: { backButtonTitle: t('back_to_list') }
            })
    }))

    if (electionType === ElectionType.LOCAL_DELEGATE_ELECTION) {
        items.push({
            key: '3',
            label: t('summary_report'),
            onClick: () => loadReport({
                reportType: ReportType.LDESummaryReport,
                reportFormat: ReportFormat.PDF,
                params: {
                    electionPeriod: election.electionPeriod.toString()
                }
            })
        })
    } else {
        items.unshift({
            key: '2',
            label: t('confirmed_results'),
            onClick: () => loadReport({
                reportType: ReportType.ConfirmedCandidateTellerReport,
                reportFormat: ReportFormat.PDF,
                params: {
                    election: election.id.toString()
                }
            })
        })
        items.unshift({
            key: '1',
            label: t('election:election_results'),
            onClick: () => loadReport({
                reportType: ReportType.ElectedCandidateTellerReport,
                reportFormat: ReportFormat.PDF,
                params: {
                    election: election.id.toString()
                }
            })
        })
    }

    return (
        <>
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            {election.electionStatus === ElectionStatusType.COMPLETED
                ?
                <Dropdown menu={{ items }} openClassName={style.reports}
                    trigger={['click']}
                >
                    <button className={`btn-main-tertiary-md ${style.download}`}>
                        <IconWrapper>
                            <ArrowDownload />
                        </IconWrapper>
                    </button>
                </Dropdown>
                : <></>
            }
        </>
    )
}

export default ElectionReportCell