import { useInfiniteQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionResultSummary } from 'models'
import { useState } from 'react'

type ElectionsSummaryParams = {
    electionIds: string[]
}

const CHUNK_SIZE = 50

export const useElectionsSummaryChunks = ({ electionIds }: ElectionsSummaryParams, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)
    const [nextPage, setNextPage] = useState(0)

    const fetchElectionsSummaryChunks = async () => {
        const chunk = electionIds?.slice(nextPage * CHUNK_SIZE, (nextPage + 1) * CHUNK_SIZE)
        if (chunk?.length === 0) {
            return { data: [], nextPage: null }
        }

        setNextPage(current => current + 1)

        const result = await axios<{ election: number, electionResults: ElectionResultSummary }[]>(
            `${moduleUrl}elections/election-results/summary?elections=${chunk?.join(',')}`
        )

        return {
            data: result.data,
            nextPage: nextPage + 1
        }
    }

    return useInfiniteQuery({
        queryKey: ['elections-summary', electionIds],
        queryFn: fetchElectionsSummaryChunks,
        enabled,
        getNextPageParam: (lastPage) => lastPage.nextPage
    })
}