import PopupTitle from 'components/atoms/PopupTitle'
import { PopupProps } from 'components/molecules/Popup'
import { useTranslation } from 'react-i18next'
import SelectTellingStation from '../components/SelectTellingStation'

export const useLaunchStationPopup = (electionId: string, showPopup: (props: PopupProps) => void,
    hidePopup: () => void) => {
    const { t } = useTranslation('teller')

    const openLaunchStationPopup = () => {
        showPopup({
            width: '660px',
            title: <PopupTitle>{t('select_telling_station')}</PopupTitle >,
            onCancel: () => hidePopup(),
            children: <SelectTellingStation
                electionId={electionId}
                onLaunch={() => hidePopup()} />,
            footer: null
        })
    }

    return {
        openLaunchStationPopup
    }
}