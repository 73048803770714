import { defineHeaderAbility } from 'ability/header.a'
import Header from 'components/organisms/Header'
import AdaptiveLayout from 'components/templates/AdaptiveLayout'
import { auth as authHeader } from 'features/auth'
import { useAuth } from 'hooks'
import React, { ReactNode } from 'react'

interface RoleBasedLayoutProps {
    children: ReactNode
}

const RoleBasedLayout: React.FC<RoleBasedLayoutProps> = ({ children }) => {
    const { auth } = useAuth()
    const ability = defineHeaderAbility(auth)
    const useSupportedWidth = ability.can('use', 'SupportedWidth')
    const HeaderToUse = !auth?.role ? <authHeader.components.Header /> : <Header />

    return (
        <AdaptiveLayout useSupportedWidth={useSupportedWidth} header={HeaderToUse}>
            {children}
        </AdaptiveLayout>
    )
}

export default RoleBasedLayout
