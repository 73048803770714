import { useElectionWordingConfiguration, useIsCastingBallotInPersonAvailable } from 'api'
import Note from 'components/molecules/Note'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import DOMPurify from 'dompurify'
import { useConfirmPopup } from 'hooks'
import { ReactComponent as Pen } from 'icons/pen.svg'
import { ElectionParticipant, ElectionType, VotingApproach } from 'models'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCastBallot } from '../../api/mutations/castBallot'
import { BallotingElection } from '../../models/balloting-election'
import { FocusContext } from '../BallotingStepWrapper'
import { BallotingContext, SelectedPeopleContext, Steps } from '../BallotingStepsManagement'
import style from './index.module.scss'

type SubmitBallotProps = {
    election?: BallotingElection,
    setShouldFocusHeader: React.Dispatch<React.SetStateAction<boolean>>
}

export function SubmitBallot({ election, setShouldFocusHeader }: SubmitBallotProps) {
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { t } = useTranslation('voter')
    const infoText = t('please_review_carefully_as_once_the_ballot')
    const { setCurrentStep } = useContext(BallotingContext)
    const { mutateAsync: castBallotMutation } = useCastBallot()
    const selectedPeopleContext = useContext(SelectedPeopleContext)
    const focusHeaderText = useContext(FocusContext)
    const { data: isCastingBallotInPersonAvailable } = useIsCastingBallotInPersonAvailable(election?.id, !!election?.id)
    const { data: wordingConfiguration } = useElectionWordingConfiguration(
        election?.electionType || ElectionType.LOCAL_DELEGATE_ELECTION
    )

    const inPersonPopupBody = useMemo(() => {
        if (!wordingConfiguration?.votingInPersonBody) {
            return null
        }

        return (
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(wordingConfiguration.votingInPersonBody) }} />
        )
    }, [wordingConfiguration?.votingInPersonBody])

    const printSelectedPeople = async (votingApproach: VotingApproach) => {
        const candidates = selectedPeople.map(person => person.id)

        if (!election || typeof election.id === 'undefined') {
            console.error('Election or election ID is missing!')

            return
        }

        await castBallotMutation({
            candidates,
            votingApproach,
            electionId: election.id
        })
    }

    if (!selectedPeopleContext) {
        throw new Error('SubmitBallot must be used within a SelectedPeopleContext.Provider')
    }

    const { selectedPeople } = selectedPeopleContext

    const handleEditClick = () => {
        setCurrentStep(Steps.EnterVotes)
        focusHeaderText && focusHeaderText()
    }

    const handleSubmitClick = () => {

        if (isCastingBallotInPersonAvailable && election?.electionType !== ElectionType.BREAKING_TIE) {
            showConfirm({
                title: wordingConfiguration?.votingInPersonTitle,
                text: inPersonPopupBody,
                okText: wordingConfiguration?.votingInPersonYes,
                cancelText: wordingConfiguration?.votingInPersonNo,
                onOk: () => {
                    printSelectedPeople(VotingApproach.IN_PERSON)
                    setCurrentStep(Steps.ThankYou)
                    setShouldFocusHeader(true)

                    return Promise.resolve()
                },
                onCancel: () => {
                    printSelectedPeople(VotingApproach.IN_ABSENCE)
                    setCurrentStep(Steps.ThankYou)
                    setShouldFocusHeader(true)

                    return Promise.resolve()
                },
                onClose: () => { }
            })
        } else {
            printSelectedPeople(VotingApproach.IN_ABSENCE)
            setCurrentStep(Steps.ThankYou)
            setShouldFocusHeader(true)
        }
    }

    return (
        <>
            <div className={style.contentBlock}>
                <div className={style.infoBlockWrapper}>
                    <Note icon={'regular'} mode={'info'}>
                        <div className={style.tipTextWrapper}>
                            <span tabIndex={0} aria-label={infoText} className={style.tipText}>
                                {infoText}</span>
                        </div>
                    </Note>
                </div>
                <div className={style.fieldList}>
                    {selectedPeople.map((person: ElectionParticipant, index: number) => (
                        <div key={index} className={style.fieldWrapper}>
                            <div className={style.disc}>
                                {index + 1}
                            </div>
                            <div tabIndex={0} aria-label={person.name} className={style.nameWrapper}>
                                <OptionalTooltip contenWrapperClassName="ellipsis">
                                    {person.name}
                                </OptionalTooltip>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={style.buttonsWrapper}>
                    <div className={style.buttons}>
                        <button
                            aria-label={t('submit_my_ballot_button_by_clicking')}
                            className={`btn-main-primary hasIcon ${style.submitButton}`}
                            onClick={handleSubmitClick}>
                            <span>{t('submit_my_ballot')}</span>
                        </button>
                        <button
                            aria-label={t('edit_button_by_clicking')}
                            className={`btn-main-secondary ${style.editButton}`}
                            onClick={handleEditClick}>
                            <Pen />
                            <span>{t('edit')}</span>
                        </button>
                    </div>
                </div>
            </div>
            {confirmPortal}
        </>
    )
}

export default SubmitBallot
