import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { queryClient } from 'config/query-client'
import { ElectionType } from 'models'
import { getElectionTypeForUrl } from 'utils'
import { ChangeRestrictionData } from '../../model'
import { getElectionRestriction } from '../../utils'

export function useChangeRestriction() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function changeRestriction(data: ChangeRestrictionData) {
        const electionRestriction = getElectionRestriction(data)

        if (Object.values(ElectionType).includes(data.electionType)) {
            const electionTypeUrl = getElectionTypeForUrl(data.electionType)

            const url = `${moduleUrl}${data.electionPeriod}/${electionTypeUrl}/change-restriction`

            return await axios(url, {
                method: 'post',
                data: electionRestriction
            })
        } else {
            throw new Error(`Invalid election type: ${data.electionType}`)
        }
    }

    return useMutation({
        mutationFn: changeRestriction,
        onSuccess: async () => {
            await queryClient.invalidateQueries(['restrictions-old'])
        }
    })
}