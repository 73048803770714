import { ballotingAssets } from 'features/ballotingAssets'
import { useAuth } from 'hooks'
import { VotesCounts, VoteSummary } from 'models'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ListItem from '../ListItem'
import StatisticList from '../StatisticList'

type InvalidVotesStatisticProps = {
    voteSummary?: VoteSummary,
    stationsVotesCounts?: VotesCounts,
    onTotalInvalidVotesChange?: (totalInvalidVotes: number) => void
}

function InvalidVotesStatistic({
    voteSummary,
    stationsVotesCounts,
    onTotalInvalidVotesChange
}: InvalidVotesStatisticProps) {

    const { auth } = useAuth()
    const { electionId } = useParams<{ electionId: string }>()
    const [ivalidStatistic, setIvalidStatistic] = useState<{ count: number; id: string; text: string }[]>([])
    const { data: electionInvalidationReasons } =
        ballotingAssets.api.queries.useVoteInvalidationReasons(electionId || auth!.electionId!,
            !!electionId || !!auth?.electionId)

    useEffect(() => {

        if (electionInvalidationReasons) {
            const invalidVoteCountsSource =
                voteSummary?.invalidVoteCounts || stationsVotesCounts?.invalidVoteCounts

            if (invalidVoteCountsSource) {
                const invalidReasonsStatistic = electionInvalidationReasons.map(reason => {
                    const count = invalidVoteCountsSource[reason.id] || 0

                    return {
                        id: reason.id,
                        text: reason.text,
                        count
                    }
                })

                setIvalidStatistic(invalidReasonsStatistic)
                const totalSum = invalidReasonsStatistic.reduce((sum, reason) => sum + reason.count, 0)

                if (onTotalInvalidVotesChange) {
                    onTotalInvalidVotesChange(totalSum)
                }
            }
        }
    }, [electionInvalidationReasons, voteSummary, stationsVotesCounts, onTotalInvalidVotesChange])

    return (
        <>
            <ListItem>
                {ivalidStatistic.map(reason => (
                    <StatisticList text={reason.text} statistic={reason.count} key={reason.id} />
                ))}
            </ListItem>
        </>
    )
}

export default InvalidVotesStatistic
