import { TimeZone } from 'models'
import { useTranslation } from 'react-i18next'

export const useTimeZoneMap = () => {
    const { t } = useTranslation('election')

    return {
        toString: (timeZone: TimeZone): string => {
            switch (timeZone) {
                case TimeZone.NewYork:
                    return t('eastern_gmt_minus_4')
                case TimeZone.Chicago:
                    return t('central_gmt_minus_5')
                case TimeZone.Denver:
                    return t('mountain_gmt_minus_6')
                case TimeZone.LosAngeles:
                    return t('pacific_gmt_minus_7')
            }
        }
    }

}