import PageHeader from 'components/atoms/PageHeader'
import BackToDashboardBtn from 'components/molecules/BackToDashboardBtn'
import { useAuth, useConfirmPopup, useHeaderLogoRedirect, useInfoPopup, usePageHeader, usePopup } from 'hooks'
import { BallotVote } from 'models'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useBallot, useBallotInvalidationReasons, useVoteInvalidationReasons } from '../../api'
import { useBallotActions, useBallotVotes } from '../../hooks'
import BallotEditor from '../BallotEditor'
import style from './index.module.scss'

function EditBallot() {
    const { t } = useTranslation()
    const { ballotId } = useParams()
    const { auth } = useAuth()

    const { data: ballot } = useBallot(ballotId!, auth!.electionId!, !!ballotId && !!auth)
    const { data: reasons } = useVoteInvalidationReasons(auth?.electionId!, !!auth?.electionId)
    const { ballotVotes, setBallotVotes } = useBallotVotes()
    const { setPageHeader } = usePageHeader()
    const { updateBallot } = useBallotActions()
    const { popupPortal, show, hide } = usePopup()
    const { confirmPortal, showConfirm } = useConfirmPopup()
    const { infoPortal, showInfo } = useInfoPopup()
    const { data: ballotInvalidationReasons }
        = useBallotInvalidationReasons(auth?.electionId!, !!auth?.electionId)
    const navigate = useNavigate()
    const { setConfirmationCallback } = useHeaderLogoRedirect()

    function navigateToDashboard() {
        navigate(-1)
    }

    const onBackClick = () => {
        showConfirm({
            text: <>{t('would_you_like_to_leave_this_page_without_saving')}</>,
            title: <>{t('leave_current_page')}</>,
            okText: <>{t('leave')}</>,
            cancelText: <>{t('continue_editing')}</>,
            onOk: async () => navigateToDashboard()
        })
    }

    useEffect(() => {
        setConfirmationCallback(true)

        return () => {
            setPageHeader(null)
            setConfirmationCallback(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setPageHeader(
            <PageHeader
                sticky={false}
                greeting={<BackToDashboardBtn backButtonTitle={t('back')} onClick={onBackClick} />}>
                <button className="btn-main-primary-md" onClick={saveBallot}>{t('save_changes')}</button>
            </PageHeader>
        )


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ballotVotes])

    useEffect(() => {
        if (ballot && reasons?.length) {
            setBallotVotes(current => {
                const vts: BallotVote[] = []

                for (let i = 0; i < ballot.votes.length; i++) {
                    const ballotVote = ballot.votes[i]
                    if (ballotVote?.candidate?.id && ballotVote?.candidate?.id === current[i]?.candidate?.id) {
                        vts.push({
                            ...current[i],
                            invalidationReason: reasons.find(r => r.id === ballotVote.invalidationReason)!
                        })
                    } else {
                        vts.push(
                            {
                                invalidationReason: reasons.find(r => r.id === ballotVote.invalidationReason)!,
                                id: i,
                                invalid: !!ballotVote.invalidationReason,
                                candidate: { id: ballotVote?.candidate?.id } as any
                            }
                        )
                    }
                }

                return vts
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ballot, reasons])

    useEffect(() => {
        if (ballot) {
            if (ballot.deleted || ballot.invalidationReason) {
                showInfo({
                    title: `${ballot.deleted ?
                        t('ballot:deleted_ballot')
                        : t('ballot:invalid_ballot')} ${ballotId}`,
                    text: <>
                        <div>
                            {ballot.deleted ?
                                t('ballot:deleted_ballot')
                                : t('ballot:invalid_ballot')} <strong>{ballotId}
                            </strong> {t('ballot:can_not_be_edited')}
                        </div>
                        <div>
                            {t('ballot:you_will_be_redirected_to_the_telling_station')}
                        </div>
                    </>,
                    onOk: async () => navigateToDashboard(),
                    stayOpenedOnCancel: true
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ballot])

    const saveBallot = () => {
        if (ballot?.deleted || ballot?.invalidationReason) {
            navigateToDashboard()

            return
        }
        updateBallot({
            ballotId: ballotId || '',
            hide,
            show,
            ballotInvalidationReasons,
            onSuccess: () => navigateToDashboard()
        })
    }

    if (!ballotId) {
        return <></>
    }

    return <div className={style.wrapper}>
        {popupPortal}
        {confirmPortal}
        {infoPortal}
        {!ballot?.deleted && !ballot?.invalidationReason && <BallotEditor
            ballotExists
            ballotId={ballotId}
            electionId={auth!.electionId!}
            onBallotInvalidate={() => navigateToDashboard()}
        />}
    </div>
}

export default EditBallot