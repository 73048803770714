import { useElection, useElectionSummary } from 'api'
import { electionResults } from 'features/electionResults'
import { useAuth } from 'hooks'
import { useParams } from 'react-router-dom'

type ResultsManagementProps = {
    isOnDashboard?: boolean
}

function ResultsManagement({ isOnDashboard }: ResultsManagementProps) {
    const { auth } = useAuth()
    const { electionId } = useParams()

    const { data: electionResultSummary } = useElectionSummary(electionId || auth?.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: election } = useElection(electionId || auth?.electionId!, !!electionId || !!auth?.electionId)

    return (
        <>
            {election && electionResultSummary?.mergedBy
                && !electionResultSummary?.confirmedBy
                && (!electionResultSummary?.tiePresent || electionResultSummary?.breakingTieSkipped)
                && <electionResults.providers.MergedResultsProvider>
                    <electionResults.components.MergedResultsTable
                        election={election}
                        showAllVacanciesOnly
                        showLinkToConfirmResults
                    />
                </electionResults.providers.MergedResultsProvider>}
            {electionResultSummary?.confirmedBy &&
                <electionResults.providers.ConfirmationProvider>
                    <electionResults.components.ConfirmedResultsTable
                        election={election}
                        showAllVacanciesOnly
                        canShowLocalUnit
                        showLinkToConfirmedResults
                        isOnDashboard={isOnDashboard} />
                </electionResults.providers.ConfirmationProvider>}
        </>
    )
}

export default ResultsManagement