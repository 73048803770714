import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export type ChangeInvalidateBallotParams = {
    id: string
    invalidationReason: string
    electionId: string
    stationId: string
}
export function useChangeInvalidateBallot() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    async function changeInvalidateBallot({ id, electionId, stationId, invalidationReason
    }: ChangeInvalidateBallotParams) {
        return await axios(moduleUrl
            + `elections/${electionId}/stations/${stationId}/ballots/${id}/change-invalidation-reason`,
            {
                method: 'post',
                data: {
                    invalidationReason
                }
            })
    }

    return useMutation({
        mutationFn: changeInvalidateBallot
    })
}