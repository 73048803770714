import { useElectionSummary } from 'api'
import BackToDashboardLine from 'components/molecules/BackToDashboardLine'
import Preloader from 'components/molecules/Preloader'
import { useBackToDashboardLine, useInfoPopup } from 'hooks'
import { Election } from 'models'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMergeResultsLogic } from '../../../hooks'
import style from './index.module.scss'

type PageHeaderButtonsProps = {
    election?: Election
}

const PageHeaderButtons = ({ election }: PageHeaderButtonsProps) => {
    const { t } = useTranslation('teller')
    const { setBackToDashboardLine } = useBackToDashboardLine()
    const { infoPortal, showInfo } = useInfoPopup()
    const { data: summary } = useElectionSummary(election?.id.toString() || '', !!election?.id)

    const { waitingForConfirmMergeResults, confirmMergeResults } =
        useMergeResultsLogic(election?.id?.toString(), summary)

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine sticky fixedWidth>
                <button className="btn-main-primary-md" onClick={() => showInfo({
                    title: <>{t('merge_results')}</>,
                    text:
                        <>
                            <div className={style.content}>{t('you_are_about_to_merge_results')}</div>
                            <div className={style.contentBold}>
                                {t('please_confirm_this_action_you_will_be_able_to_unmerge_results')}
                            </div>
                        </>,
                    onOk: () => {
                        confirmMergeResults()
                    },
                    okText: <>{t('confirm')}</>
                })}>{t('merge_results')}</button>
            </BackToDashboardLine>
        )

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [election])

    return (
        <>
            {waitingForConfirmMergeResults && <Preloader />}
            {infoPortal}
        </>
    )
}

export default PageHeaderButtons