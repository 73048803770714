import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { VotingApproach } from 'models'

export type VotesCount = {
    totalCount: number
}

export const useVotesCount = (id?: string, enabled?: boolean, votingApproach?: VotingApproach) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.OnlineBallot)

    const getVotesCount = async () => {
        let url = `${moduleUrl}elections/${id}/votes/count`
        if (votingApproach) {
            url += `?voting-approach=${votingApproach}`
        }

        const res = await axios<VotesCount>(url)

        return res.data
    }

    return useQuery<VotesCount>({
        queryKey: ['online-ballot-votes-count', id, votingApproach],
        queryFn: getVotesCount,
        enabled
    })
}