import { WizardChipSettings } from 'components/molecules/Wizard/models'
import { ReactComponent as PeopleCheckmarkFilled } from 'icons/people-checkmark-filled.svg'
import { ReactComponent as PeopleCheckmark } from 'icons/people-checkmark.svg'
import { ReactComponent as ReportFilled } from 'icons/report-filled.svg'
import { ReactComponent as Report } from 'icons/report.svg'
import { ReactComponent as TicketFilled } from 'icons/ticket-filled.svg'
import { ReactComponent as Ticket } from 'icons/ticket.svg'
import { useTranslation } from 'react-i18next'
export const useWizardChipsSettings = () => {
    const { t } = useTranslation('telling-station')

    const voterVerificationChipsSettings: WizardChipSettings = {
        title: t('voter_verification'),
        icon: <PeopleCheckmark width="20" height="20" />,
        activeIcon: <PeopleCheckmarkFilled width="20" height="20" />
    }
    const enterBallotsChipSettings: WizardChipSettings = {
        title: t('enter_ballots'),
        icon: <Ticket width="20" height="20" />,
        activeIcon: <TicketFilled width="20" height="20" />
    }
    const reportingChipSettings: WizardChipSettings = {
        title: t('reporting'),
        icon: <Report width="20" height="20" />,
        activeIcon: <ReportFilled width="20" height="20" />
    }

    return {
        voterVerificationChipsSettings,
        enterBallotsChipSettings,
        reportingChipSettings
    }
}