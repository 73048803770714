import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { Dispatch } from 'react'
import { useFloatingLinkEditorToolbar } from './useFloatingLinkEditorToolbar'

type RichTextEditorLinkEditorPluginProps = {
    anchorElem: HTMLElement
    isLinkEditMode: boolean
    setIsLinkEditMode: Dispatch<boolean>
}

function RichTextEditorLinkEditorPlugin({
    anchorElem,
    isLinkEditMode,
    setIsLinkEditMode
}: RichTextEditorLinkEditorPluginProps) {
    const [editor] = useLexicalComposerContext()

    return useFloatingLinkEditorToolbar({
        editor,
        anchorElem,
        isLinkEditMode,
        setIsLinkEditMode
    }
    )
}

export default RichTextEditorLinkEditorPlugin