import { useElectionCandidates } from 'api'
import LookupSelect from 'components/molecules/LookupSelect'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { ballotingAssets } from 'features/ballotingAssets'
import { useAbility, useAuth, usePopup } from 'hooks'
import { ElectionParticipant } from 'models'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getNameWithMatchHighlighting } from 'utils'
import { useBallotInvalidationReasons } from '../../api'
import { useBallotVotesActions } from '../../hooks'
import style from './index.module.scss'

const maxCandidatesInDropDown = 30
const DUPLICATED_NAMES_KEY = 'DUPLICATED_NAMES'

type EnterVoteProps = {
    ballotId: string
    ballotExists?: boolean
}

function EnterVote({ ballotId, ballotExists }: EnterVoteProps) {
    const { t } = useTranslation('ballot')
    const { auth } = useAuth()
    const navigate = useNavigate()
    const { ability } = useAbility()
    const { appendVote, canAppendMore, isAlreadyInBallot } = useBallotVotesActions()
    const { data: ballotInvalidationReasons } = useBallotInvalidationReasons(auth?.electionId!)

    const [candidate, setCandidate] = useState<ElectionParticipant | undefined>()
    const [customNotFoundContent, setCustomNotFoundContent] = useState<ReactNode | undefined>()
    const [clear, setClear] = useState<boolean>()
    const [nameFilter, setNameFilter] = useState<string | undefined>()
    const { showVotesMismatchPopup } = ballotingAssets.hooks.useBallotActions()
    const { popupPortal, show, hide } = usePopup()

    const { data: candidates, isFetching: isCandidatesFetching } = useElectionCandidates(auth?.electionId!,
        { limit: maxCandidatesInDropDown, offset: 0, name: nameFilter },
        !!nameFilter?.length)

    const addVote = () => {
        if (candidate) {
            if (isAlreadyInBallot(candidate.id)) {
                showVotesMismatchPopup({
                    show,
                    hide,
                    onOk: () => {
                        ability?.can('redirect', 'TellingProcess')
                            ? navigate('/')
                            : navigate(-1)
                    },
                    ballotExists,
                    ballotId,
                    title: t('duplicate_name'),
                    text: t('if_the_name_just_entered_is_a_duplicate_name_select_reason_and_mark_it_as_invalid',
                        {
                            reason: ballotInvalidationReasons?.find(r => r.id === DUPLICATED_NAMES_KEY)?.text
                                || 'No reasons'
                        }),
                    invalidationReasonsKey: DUPLICATED_NAMES_KEY
                })

                return
            }
            if (appendVote(candidate)) {
                setClear(true)
                setNameFilter('')
            }
        }
    }

    const onCandidateChange = (candidate?: ElectionParticipant) => {
        setCandidate(candidate)
        setClear(false)
    }

    return (
        <div className={style.wrapper} id="enter-vote">
            {popupPortal}
            <div className={style.lookup}>
                <LookupSelect
                    disabled={!canAppendMore()}
                    onChange={(candidate) => onCandidateChange(candidate)}
                    className="small"
                    noOptionalTooltip
                    optionRenderFunction={(cand) =>
                        <div className={style.candidate}>
                            <div className={style.name}>
                                <OptionalTooltip contenWrapperClassName="ellipsis">
                                    {getNameWithMatchHighlighting(cand.name, nameFilter)}
                                </OptionalTooltip>
                            </div>
                            <div className={style.bid}>
                                {cand.id}
                            </div>
                            <div className={style.locality}>
                                <OptionalTooltip contenWrapperClassName="ellipsis">
                                    {`${cand.homeLocality?.name}, ${cand.homeLocality?.state}`}
                                </OptionalTooltip>
                            </div>
                        </div>}
                    onSearchChange={(name) => setNameFilter(name?.trim())}
                    options={candidates}
                    isFetchingOptions={isCandidatesFetching}
                    autoClear={clear}
                    customNotFoundContent={customNotFoundContent}
                    onFocus={() => setCustomNotFoundContent(undefined)}
                    onKeyDown={(e) => {
                        if (candidate && e.code === 'Enter' && canAppendMore()) {
                            addVote()
                            setCustomNotFoundContent(null)
                        } else {
                            setCustomNotFoundContent(undefined)
                        }
                    }}
                    placeholder={t('teller:start_typing_the_name')} />
            </div>
            <button
                className={`${style.submit} btn-main-primary-md`}
                onClick={addVote}
                disabled={!canAppendMore() || !candidate}>
                {t('add_vote')}
            </button>
        </div>
    )
}

export default EnterVote