import { Col, Row } from 'antd'
import IconWrapper from 'components/atoms/IconWrapper'
import { ReactComponent as ChevronBold } from 'icons/chevron-bold.svg'
import { ReactComponent as LogoBig } from 'icons/logo-big.svg'
import { ReactComponent as OfficersElectionMaintenance } from 'icons/officers-elections-maintenance.svg'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import styles from './index.module.scss'

const ManageOfficersElectionMaintenance = () => {
    const { t } = useTranslation('election')
    const navigate = useNavigate()

    return (
        <div className={styles.wrapper}>
            <Row gutter={24} justify="space-evenly">
                <Col>
                    <section
                        tabIndex={0}
                        aria-label={t('online_balloting_system')}
                        className={styles.logoBig}>
                        <LogoBig width="393" height="76" viewBox="0 0 393 76" />
                    </section>
                    <h1>
                        Using OBS for electing Assembly officers {String.fromCharCode(8212)} coming soon!
                    </h1>
                    <div className={styles.date}>
                        We regret that there has been a delay in making OBS available<br/>
                        for electing Assembly officers. It will be available starting April 29, 2024.
                    </div>
                    <div className={styles.info}>
                        For more details about the Officers’ Election process,
                    </div>
                    <div className={styles.info}>
                        visit the <Link
                            to="https://helpcenter.obsbahai.org/"
                            target="_blank"
                            className={styles.link}>
                            OBS Help Center
                        </Link>
                    </div>
                    <button
                        type="submit"
                        onClick={() => navigate('/')}
                        className={`btn-main-primary hasIcon ${styles.button}`}>
                        <IconWrapper><ChevronBold className="flip-horizontal" width="16" height="16" /></IconWrapper>
                        <span>{t('back_to_dashboard')}</span>
                    </button>
                </Col>
                <Col>
                    <section
                        tabIndex={0}
                        aria-label={t('online_balloting_system')}
                        className={styles.rightIcon}>
                        <OfficersElectionMaintenance />
                    </section>
                </Col>
            </Row>
        </div>
    )
}

export default ManageOfficersElectionMaintenance