import { BallotVote } from 'models'
import { PropsWithChildren, useState } from 'react'
import { BallotVotesContext } from '../context'

function BallotVotesProvider({ children }: PropsWithChildren) {
    const [ballotVotes, setBallotVotes] = useState<BallotVote[]>([])

    return (
        <BallotVotesContext.Provider value={{ ballotVotes, setBallotVotes }} >
            {children}
        </BallotVotesContext.Provider>
    )
}

export default BallotVotesProvider