import OptionalTooltip from 'components/molecules/OptionalTooltip'
import {useAgeCategoryMap, useGenderMap} from 'hooks'
import React, {CSSProperties, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useEligibleIndividualsTableData} from '../../hooks'
import {getTableColumnWidths} from '../../utils'

import style from './index.module.scss'
import 'style/shared/full-data-table/full-data-table.scss'

type EligibleIndividualsTableItemProps = {
    index: number,
    style?: CSSProperties,
    isScrolling?: boolean
}
const tableCellContentWith = (cellWidth: number) => `calc(${cellWidth}px - calc(var(--double-space) * 2)`

const EligibleIndividualsTableItem = ({ index, style: itemStyle }: EligibleIndividualsTableItemProps) => {
    const { t } = useTranslation('telling-station')
    const { tableData } = useEligibleIndividualsTableData()
    const { toString: ageCategoryToString } = useAgeCategoryMap()
    const { toString: genderToString } = useGenderMap()

    const participant = tableData.participants[index]
    const tableColumnWidths = useMemo(() => getTableColumnWidths(tableData.includeLocalUnit), [tableData])

    return (
        <div className="full-data-table-listItem" style={itemStyle}>
            <div
                style={{width: tableColumnWidths.name}}
                className="full-data-table-cell-column"
            >
                <span style={{maxWidth: tableCellContentWith(tableColumnWidths.name)}}>
                    <OptionalTooltip contenWrapperClassName="ellipsis">{participant.name}</OptionalTooltip>
                </span>
            </div >

            <div
                style={{width: tableColumnWidths.id}}
                className="full-data-table-cell-column"
            >
                {participant.id}
            </div >

            <div
                style={{width: tableColumnWidths.gender}}
                className="full-data-table-cell-column"
            >
                {genderToString(participant.gender)}
            </div >

            <div
                style={{width: tableColumnWidths.ageCategory}}
                className="full-data-table-cell-column"
            >
                {ageCategoryToString(participant.ageCategory)}
            </div >

            <div
                style={{width: tableColumnWidths.homeLocality}}
                className="full-data-table-cell-column"
            >
                {participant.homeLocality.code}
            </div >

            {tableData.includeLocalUnit &&
                <div
                  style={{width: tableColumnWidths.homeLocalUnit}}
                  className="full-data-table-cell-column"
                >
                    {participant.homeLocalUnit?.code}
                </div >
            }

            <div
                style={{width: tableColumnWidths.city}}
                className="full-data-table-cell-column"
            >
                {participant.city}
            </div >

            <div
                style={{width: tableColumnWidths.votedOnline}}
                className="full-data-table-cell-column"
            >
                <>{participant.votedOnline? t('yes') : '-'}</>
            </div >

            <div
                style={{width: tableColumnWidths.action}}
                className={`${style.disksWrapper} full-data-table-cell-column`}
            >
                {participant.candidate
                    ? <div className={style.disk} style={{ background: 'var(--surface-status-eligible)' }} />
                    : <div className={style.disk} style={{ background: 'var(--surface-status-not-eligible)' }} />}
                {participant.voter
                    ? <div className={style.disk} style={{ background: 'var(--surface-status-eligible)' }} />
                    : <div className={style.disk} style={{ background: 'var(--surface-status-not-eligible)' }} />}
            </div>
        </div >
    )
}

export default EligibleIndividualsTableItem