import { tellingStation } from 'features/tellingStation'
import StationDashboard from '../components/StationDashboard'
import TutorialButton from '../components/TutorialButton'
import TellingStationProcessProvider from '../providers/TellingStationProcessProvider'

function Dashboard() {
    return (
        <tellingStation.providers.TellingStationProvider>
            <TellingStationProcessProvider>
                <StationDashboard />
                <TutorialButton />
            </TellingStationProcessProvider>
        </tellingStation.providers.TellingStationProvider>
    )
}

export default Dashboard