import { useLocalDelegateElectionPushResult } from 'api'
import { useElectionTypeMap } from 'hooks'
import { Election, ElectionStatusType, ElectionType } from 'models'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { electionEditing } from '../../../../../electionEditing'
import { useAppointedTellersCountsChunk } from '../../api'
import { DelegateElectionItem, ElectionItem, RidvanElectionItem } from '../../models'
import { getElectionItem, getLocalDelegateItem, getRidvanDraftElectionItem } from '../../utils'
import ElectionListItem from '../ElectionListItem'

type ElectionPeriodProps = {
    election?: Election,
    localDelegatePeriodUnits?: Election[]
}

const ElectionPeriod: React.FC<ElectionPeriodProps> = ({ election, localDelegatePeriodUnits }) => {
    const { t } = useTranslation('election')
    const { toString } = useElectionTypeMap()

    const { data: ldePushResult } =
        useLocalDelegateElectionPushResult(
            !!localDelegatePeriodUnits?.length ? localDelegatePeriodUnits![0].electionPeriod.toString() : '',
            !!localDelegatePeriodUnits?.length)

    const localDelegateElectionStatus = useMemo(() => {
        if (!localDelegatePeriodUnits) return

        return electionEditing.ridvan.utils.getLocalDelegateStatus(localDelegatePeriodUnits, ldePushResult)
    }, [localDelegatePeriodUnits, ldePushResult])

    const electionIdArray = useMemo(() => {
        if (election && (election.status !== ElectionStatusType.COMPLETED)) {
            return [election.id]
        }

        if (localDelegatePeriodUnits && (localDelegateElectionStatus !== ElectionStatusType.COMPLETED)) {
            return localDelegatePeriodUnits.map(unit => unit.id)
        }

        return []
    }, [election, localDelegatePeriodUnits, localDelegateElectionStatus])


    const appointedTellersCountsData = useAppointedTellersCountsChunk(electionIdArray)

    const electionTitle = (election: Election, year?: string) => {
        if (!election)
            return ''
        switch (election.type) {
            case ElectionType.NATIONAL_DELEGATE_ELECTION:
                return (t('year_b_e_election_rbc_name', {
                    year: election.ballotingYear,
                    election: toString(election.type),
                    rbc: election.region.unit
                }))
            default:
                return (t('year_b_e_election', {
                    year: year || election.ballotingYear,
                    election: toString(election.type)
                }))
        }
    }

    const periodElections = useMemo(() => {
        let elections: (ElectionItem | DelegateElectionItem)[] = []
        if (election) {
            if ((election.status === ElectionStatusType.COMPLETED) || appointedTellersCountsData.length) {
                const electionItem = getElectionItem(
                    election,
                    appointedTellersCountsData[0] || null,
                    electionTitle(election)
                )
                if (election.type === ElectionType.RIDVAN_ELECTION) {
                    const ridvan: RidvanElectionItem = {
                        ...electionItem,
                        isRidvanStage: true
                    }

                    return [ridvan]
                }

                return [electionItem]
            }
        }

        if (localDelegatePeriodUnits) {
            if ((localDelegateElectionStatus === ElectionStatusType.COMPLETED) || appointedTellersCountsData.length) {
                const dashboardLocalDelegateElection = (getLocalDelegateItem(
                    localDelegatePeriodUnits,
                    localDelegateElectionStatus,
                    appointedTellersCountsData,
                    (t('year_b_e_election', {
                        year: localDelegatePeriodUnits[0].ballotingYear.toString(),
                        election: toString(ElectionType.LOCAL_DELEGATE_ELECTION)
                    }))
                ))
                elections = [...elections, dashboardLocalDelegateElection]
            }

            if (localDelegatePeriodUnits.find(unit => (unit.status !== ElectionStatusType.COMPLETED))) {
                const dashboardRidvanDraftElection = getRidvanDraftElectionItem(
                    (t('year_b_e_election', {
                        year: localDelegatePeriodUnits[0].ballotingYear.toString(),
                        election: toString(ElectionType.RIDVAN_ELECTION)
                    })),
                    localDelegatePeriodUnits[0].electionPeriod
                )
                elections = [...elections, dashboardRidvanDraftElection]
            }
        }

        return elections
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [election, localDelegatePeriodUnits, appointedTellersCountsData, localDelegateElectionStatus])

    return (
        <>
            {periodElections && periodElections.map((election, index) => (
                <ElectionListItem
                    key={index}
                    election={election}
                />
            ))
            }
        </>
    )
}

export default ElectionPeriod