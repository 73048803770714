import { useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { TimeZone } from 'models'

export const useTimeZones = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getTimeZones = async (): Promise<TimeZone[]> => {

        const result = await axios<TimeZone[]>(
            `${moduleUrl}elections/restrictions/available-time-zones`)

        return result.data
    }

    return useQuery({
        queryKey: ['available-time-zones'],
        queryFn: getTimeZones,
        enabled
    })
}