import { BOSA_URL } from 'api'
import { administration } from 'features/administration'
import { useAccessToken } from 'hooks'
import { ReactComponent as LogoBig } from 'icons/logo-big.svg'
import React, { ReactNode, useEffect } from 'react'
import { MobileView, isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import Panel from '../common/Panel'
import PanelContent from '../common/PanelContent'
import PanelHeader from '../common/PanelHeader'
import style from './index.module.scss'

const CreateAccountLink = 'https://www.bahai.us/community/createbos'
const ForgotPassLink = 'https://www.bahai.us/community/forgot '

type SignInPanelProps = {
    logo?: ReactNode
    desktopOnly?: boolean
    cardClassName?: string
    panelClassName?: string
}

function SignInPanel({
    logo, desktopOnly, cardClassName, panelClassName
}: SignInPanelProps) {
    const { t } = useTranslation('auth')
    const { setAccessToken } = useAccessToken()
    const { resetAdminDashboardData } = administration.hooks.useResetAdminDashboardData()
    const panelHeaderRef = React.useRef<HTMLDivElement>(null)
    useEffect(() => {
        panelHeaderRef.current?.focus()
        localStorage.removeItem('theme')
        localStorage.removeItem('user')
        resetAdminDashboardData()
        setAccessToken(null)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Panel
            showSupportedBrowserHint={true}
            cardClassName={cardClassName}
            className={panelClassName}>
            <PanelHeader
                tabIndex={0}
                role={'contentinfo'}
                ref={panelHeaderRef}
                ariaLabel={t('sing_in_balloting_online_system')}
                className={style.header}>
                {logo || <LogoBig height="48px" width="240px" />}
                <label>
                    {t('sign_in')}
                </label>
            </PanelHeader>
            <PanelContent className={style.actions}>
                <MobileView>
                    {desktopOnly &&
                        <div className={style.desktopOnlyInfo}>
                            {t('this_functionality_can_t_be_accessed_from_the_mobile_device')}
                        </div>}
                </MobileView>
                <button className={`${style.button} btn-main-primary`} disabled={desktopOnly && isMobile}
                    onClick={() => { window.location.href = BOSA_URL }}>
                    <span className={style.short}>{t('sign_in_with_bosa')}</span>
                    <span className={style.long}>{t('sign_in_with_bahai_online_services_account')}</span>
                </button>
                <button className={`${style.button} btn-main-secondary`}
                    disabled={desktopOnly && isMobile}
                    onClick={() => { window.location.href = CreateAccountLink }}>
                    <span>{t('create_account')}</span>
                </button>
                <button className={`${style.button} btn-main-tertiary`} disabled={desktopOnly && isMobile}
                    onClick={() => { window.location.href = ForgotPassLink }}>
                    <span>{t('forgot_password')}</span>
                </button>
            </PanelContent>
        </Panel>
    )
}

export default SignInPanel