import { Tour } from 'antd'
import style from './index.module.scss'
type TutorialHighlightProps = React.ComponentProps<typeof Tour> & {
}

function TutorialHighlight({
    ...props
}: TutorialHighlightProps) {
    return (
        <Tour
            {...props}
            arrow={false}
            rootClassName={`${props.rootClassName} ${style.tutorialHighlight}`}
        />
    )
}

export default TutorialHighlight