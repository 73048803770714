import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export function useOpenStationAfterReset() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Toolkit)

    async function openStationAfterReset(electionId: string) {
        return await axios(`${moduleUrl}elections/${electionId}/stations/S1/open`,
            {
                method: 'post',
                data: {}
            })
    }

    return useMutation({
        mutationFn: openStationAfterReset
    })
}