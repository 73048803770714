import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import PopupFooter from 'components/atoms/PopupFooter'
import TextArea from 'components/atoms/TextArea'
import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

const COMMENT_LENGTH = 300

type DeleteBallotProps = {
    onDelete: (invalidationReason: string) => void
    onCancel: () => void,
    defaultReasonKey?: string,
    text?: ReactNode
}

function DeleteBallot({ onDelete, onCancel, defaultReasonKey, text }: DeleteBallotProps) {
    const [form] = useForm()
    const { t } = useTranslation('ballot')

    useEffect(() => {
        form.setFieldsValue({ reason: defaultReasonKey })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultReasonKey])

    return (
        <>
            <Form
                layout="vertical"
                form={form}>
                {text || <div className={style.note}>{t('are_you_sure_you_want_to_delete_this_ballot')}</div>}
                <Form.Item
                    name={['comment']}
                    className="no-padding"
                    label={t('please_type_the_reason_below', { reason: '' })}
                    rules={[{
                        required: true,
                        whitespace: true,
                        message: t('please_add_the_reason_of_deletion')
                    }
                    ]}
                >
                    <TextArea
                        placeholder={t('reason')}
                        showCount={true}
                        maxLength={COMMENT_LENGTH}
                        autoSize={{ minRows: 3, maxRows: 8 }} />
                </Form.Item>
            </Form>
            <div className={style.footer}>
                <PopupFooter>
                    <button className="btn-main-secondary"
                        onClick={(e) => {
                            onCancel()
                        }}>
                        {t('common:cancel')}
                    </button>
                    <button
                        type="submit"
                        onClick={async () => {
                            try {
                                await form.validateFields()
                            } catch {
                                return
                            }
                            onDelete(form.getFieldsValue().comment)
                        }
                        }
                        className={'btn-error-primary'}>
                        {t('delete_ballot')}
                    </button>
                </PopupFooter>
            </div>
        </>
    )
}

export default DeleteBallot