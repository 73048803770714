import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionResult } from 'models'

export const useElectedElectionResultsCandidates = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectedElectionResultsCandidates = async ({ queryKey }: QueryFunctionContext)
        : Promise<ElectionResult[]> => {
        const [, electionId] = queryKey

        const res = await axios<ElectionResult[]>(
            `${moduleUrl}elections/${electionId}/election-results/candidates/elected`)

        return res.data
    }

    return useQuery({
        queryKey: ['election-results-elected-candidates', electionId],
        queryFn: getElectedElectionResultsCandidates,
        enabled
    })
}