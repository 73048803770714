import style from './index.module.scss'

type TitleStatisticProps = {
    number?: string
    text?: string
    mainText?: string
    statistic?: number
}

function TitleStatistic({ number, text, mainText, statistic }: TitleStatisticProps) {

    return (
        <div className={style.total}>
            {mainText}
            <div>
                <span className={style.totalNumber}>{number}</span>
                {text}
            </div>
            <div>{statistic}</div>
        </div>
    )
}

export default TitleStatistic