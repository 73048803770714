import Tooltip from 'components/atoms/Tooltip'
import { ReactComponent as BulbInCircle } from 'icons/bulb-in-circle.svg'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTellingStationProcessInfo } from '../../hooks'
import style from './index.module.scss'


function TutorialButton() {
    const [isAnimating, setIsAnimating] = useState(false)
    const { info, setInfo } = useTellingStationProcessInfo()
    const { t } = useTranslation('telling-station-sandbox')

    useEffect(() => {
        const htmlElement = document.getElementsByTagName('html')[0]
        if (!info.tutorialShown) {
            setIsAnimating(true)
            htmlElement.classList.remove('noGlobalScroll')
        } else {
            htmlElement.classList.add('noGlobalScroll')
        }
    }, [info.tutorialShown])

    return (
        <>
            <button
                onAnimationEnd={() => setIsAnimating(false)}
                onClick={() => {
                    if (!isAnimating) {
                        window.scrollTo({ top: 0, left: 0 })
                        setInfo((current) => ({ ...current, tutorialShown: true }))
                    }
                }}
                type="button"
                className={`${style.tutorialButton} ${isAnimating ? style.animating : ''}`}>
                <Tooltip title={t('view_instruction')}>
                    <BulbInCircle height={24} width={24} />
                </Tooltip>
            </button>
        </>
    )
}

export default TutorialButton