import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { VerifiedVotersCount } from 'models'

export const useVerifiedVoterCount = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getVerifiedVoterCount = async ({ queryKey }: QueryFunctionContext)
        : Promise<any> => {
        const [, electionId] = queryKey

        const res = await axios<VerifiedVotersCount>(`${moduleUrl}elections/${electionId}/verified-voters/count`)

        return res.data
    }

    return useQuery<VerifiedVotersCount>({
        queryKey: ['verified-voters', electionId],
        queryFn: getVerifiedVoterCount,
        enabled
    })
}