import { PropsWithChildren } from 'react'
import { isMobile } from 'react-device-detect'
import styles from './index.module.scss'

function VotingWrapper({ children }: PropsWithChildren) {
    return (
        <div className={`${styles.wrapper} ${isMobile ? styles.mobile : ''}`}>
            {children}
        </div>
    )
}

export default VotingWrapper