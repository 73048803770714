import { useQuery } from '@tanstack/react-query'
import { ApiModule } from 'api/api-module'
import { useAxios } from 'api/hooks'
import { getModuleUrl } from 'api/utils'

export const useVoteCountingStarted = (id: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getVoteCountingStarted = async (id: string) => {
        const res = await axios<boolean>(`${moduleUrl}elections/${id}/vote-counting-started`)

        return res.data
    }

    return useQuery({
        queryKey: ['vote-counting-started', id],
        queryFn: () => getVoteCountingStarted(id),
        enabled
    })
}