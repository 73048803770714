import { useElection, useLocality } from 'api'
import PageHeader from 'components/atoms/PageHeader'
import BackToDashboardBtn from 'components/molecules/BackToDashboardBtn'
import { useAbility, useElectionTypeMap, usePageHeader } from 'hooks'
import { Election } from 'models'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import style from './index.module.scss'

function ElectionBoardHeader() {
    const { setPageHeader } = usePageHeader()
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const { ability } = useAbility()
    const { electionId } = useParams()
    const { data: election } = useElection(electionId!, !!electionId)
    const { toString } = useElectionTypeMap()
    const { data: locality } = useLocality(election?.region?.locality || '', !!election && !!election?.region?.locality)

    useEffect(() => {

        const getElectionLocation = (election?: Election) => {
            const unitOrRbc = (election?.region?.localUnit || '') + (election?.region?.rbc || '')
                + (election?.region?.unit || '')
            if (ability?.can('view', 'LocalityName') && locality?.name) {
                return ` (${locality?.name}${unitOrRbc ? (', ' + unitOrRbc) : ''})`
            } else {
                if (unitOrRbc) {
                    return ` (${unitOrRbc})`
                }
            }

            return ''
        }


        setPageHeader(
            <PageHeader
                sticky={true}
                greeting={<BackToDashboardBtn backButtonTitle={location.state?.backButtonTitle}
                    onClick={() => {
                        location.state?.backButtonTitle === t('back_to_list') ?
                            navigate(-1) :
                            navigate('/')
                    }} />}>
                <div
                    className={style.electionName}
                >
                    {t('year_b_e_election', {
                        year: election?.ballotingYear,
                        election: election ? toString(election.type) : ''
                    })}
                    {
                        getElectionLocation(election)
                    }
                </div>
            </PageHeader>
        )

        return () => setPageHeader(null)
    }, [election, locality])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        null
    )
}

export default ElectionBoardHeader