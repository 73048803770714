import { FormInstance } from 'antd'
import { useTranslation } from 'react-i18next'
import DateAndTimeOrJustTime from '../DateAndTimeOrJustTime'
import SectionTitle from '../SectionTitle'


type OnlineVotingDateAndTimeProps = {
    form: FormInstance
}

function OnlineVotingDateAndTime({ form }: OnlineVotingDateAndTimeProps) {
    const { t } = useTranslation('configuration')

    return (
        <DateAndTimeOrJustTime form={form}
            sectionTitle={<SectionTitle>{t('online_voting_end_date_n_time')}:</SectionTitle>}
            radioFormProperty="onlineVotingEndType"
            inputNote={t('ends_online_voting_automatically_on_specific_time_date_n_time')} />
    )
}

export default OnlineVotingDateAndTime