import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'

export const useCities = (electionPeriodId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getCities = async ({ queryKey }: QueryFunctionContext)
        : Promise<string[]> => {
        const [, electionPeriodId] = queryKey

        const result = await axios<string[]>(
            `${moduleUrl}by-election-periods/${electionPeriodId}/by-election/participants/cities`)

        return result.data
    }

    return useQuery({
        queryKey: ['election-period-cities', electionPeriodId],
        queryFn: getCities,
        enabled
    })
}