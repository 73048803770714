import { AgeCategory } from 'models'
import { useTranslation } from 'react-i18next'

export const useAgeCategoryMap = () => {
    const { t } = useTranslation('election')

    return {
        toString: (ageCategory: AgeCategory): string => {
            switch (ageCategory) {
                case AgeCategory.ADULT:
                    return t('adult')
                case AgeCategory.YOUTH:
                    return t('youth')
            }
        }
    }

}