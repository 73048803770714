import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useElectionRestrictionsOld } from 'api'
import { ElectionType } from 'models'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ConfigWrapper from '../../../../components/ConfigWrapper'
import Divider from '../../../../components/Divider'
import { useElectionConfigData } from '../../hooks/useElectionConfigData'
import { ElectionPeriod } from '../../model'
import ElectionDate from '../ElectionDate'
import GeneralSettings from '../GeneralSettings'
import OnlineVotingDateAndTime from '../OnlineVotingDateAndTime'
import OnlineVotingStartDate from '../OnlineVotingStartDate'

function LocalDelegateElectionConfig() {
    const { t } = useTranslation('configuration')
    const [form] = useForm()
    const { setElectionConfigData } = useElectionConfigData()
    const { data: electionRestrictionsOld, isLoading: isLoadingRestrictions } = useElectionRestrictionsOld()

    useEffect(() => {
        form.setFieldsValue({
            onlineVotingInPersonPromptEnabled: false,
            electionEnabled: false,
            byPassFlowEnabled: false
        })

        setElectionConfigData(current => {
            const updatedData = {
                ...current, form,
                isFormTouched: false,
                electionType: ElectionType.LOCAL_DELEGATE_ELECTION,
                electionPeriod: ElectionPeriod.RIDVAN_ELECTION_PERIODS
            }

            return updatedData
        })
    },
        [form, setElectionConfigData])

    useEffect(() => {
        if (electionRestrictionsOld && !isLoadingRestrictions) {
            setElectionConfigData(current => {
                const updatedData = {
                    ...current,
                    electionRestrictions: electionRestrictionsOld.localDelegateElectionRestriction
                }

                return updatedData
            })
        }
    }, [electionRestrictionsOld, isLoadingRestrictions, setElectionConfigData])

    return (
        <ConfigWrapper >
            <Form
                layout="vertical"
                form={form}
                onChange={() => setElectionConfigData(current => ({ ...current, isFormTouched: true }))}>
                <GeneralSettings form={form}
                    enabledTitle={t('enable_disable_national_delegate_election')}
                    enabledSubtitle={
                        <>
                            <div>{t('applicable_only_for_hosting_lsa')}.</div>
                            <div>{t('even_if_the_checkbox_will_allows_a_particular_type_of_election')}</div>
                        </>
                    }
                />
                <Divider />
                <OnlineVotingStartDate
                    form={form}
                />
                <Divider />
                <OnlineVotingDateAndTime
                    form={form}
                />
                <Divider />
                <ElectionDate
                    form={form}
                />
            </Form>
        </ConfigWrapper>
    )
}

export default LocalDelegateElectionConfig