
import { Radio } from 'antd'
import { isMobile } from 'react-device-detect'
import style from './index.module.scss'

type PopupRadioButtonProps = {
    buttonIcon: React.ReactNode | (() => React.ReactNode),
    value: string | number | boolean,
    labelTitle: string,
    labelSubTitle?: string,
    labelText?: string,
    defaultChecked?: boolean,
    disabledOnMobile?: boolean,
    centerAlign?: boolean
}

function PopupRadioButton({
    buttonIcon,
    labelTitle,
    labelSubTitle,
    labelText,
    value,
    defaultChecked,
    disabledOnMobile,
    centerAlign = false
}: PopupRadioButtonProps) {

    const containerStyle = {
        alignItems: centerAlign ? 'center' : 'flex-start'
    }

    return (
        <Radio.Button
            disabled={disabledOnMobile && isMobile}
            defaultChecked={defaultChecked}
            value={value}
            className={style.containerWrapper}>
            <div className={style.container} style={containerStyle}>
                <div className={`${style.icon} radio-button-icon`}>
                    {typeof buttonIcon === 'function' ? buttonIcon() : buttonIcon}
                </div>
                <div className={style.selectLabel}>
                    <span className={style.labelTitle}>{labelTitle}</span>
                    {labelSubTitle && <span className={style.labelSubTitle}>{labelSubTitle}</span>}
                    {labelText && <span className={style.labelText}>{labelText}</span>}
                </div>
            </div>
        </Radio.Button>
    )
}

export default PopupRadioButton
