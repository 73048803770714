import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { Vote } from '../../models'

type UpdateBallotParams = {
    ballotId: string,
    electionId: string,
    stationId: string,
    votes?: Vote[],
    invalidationReason?: string
}

export function useUpdateBallot() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    async function updateBallot({ ballotId, electionId, stationId, votes, invalidationReason }: UpdateBallotParams) {
        return await axios(moduleUrl
            + `elections/${electionId}/stations/${stationId}/ballots/${ballotId}/update-votes`,
            {
                method: 'post',
                data: {
                    votes,
                    invalidationReason
                }
            })
    }

    return useMutation({
        mutationFn: updateBallot
    })
}