import EmptyMessage from 'components/atoms/EmptyMessage'
import {ReactComponent as Vote} from 'icons/vote.svg'
import {Election} from 'models'
import React from 'react'
import {useTranslation} from 'react-i18next'
import ElectionsPeriods from '../ElectionsPeriods'

type CompletedElectionsProps = {
    elections: Election[]
}

const CompletedElections: React.FC<CompletedElectionsProps> = ({elections}) => {
    const { t } = useTranslation('election')

    return (
        <>
            {(elections && elections.length)
                ?
                <ElectionsPeriods elections={elections} />
                :
                <EmptyMessage
                    title={t('no_history')}
                    description={t('there_are_currently_no_completed_elections')}
                >
                    <Vote/>
                </EmptyMessage>
            }
        </>
    )
}

export default CompletedElections