import {
    ConfirmPopupSettings,
    useBackToDashboardLine,
    useInfoPopup,
    usePageHeader,
    useRefreshTokenInfo,
    useSignOut
} from 'hooks'
import { ReactNode, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../../organisms/Header'
import styles from './index.module.scss'

type LayoutProps = {
    headerLogoBig?: ReactNode
    onEndTraining?: (showConfirm: (settings: ConfirmPopupSettings) => void, hide: () => void) => void
}

const Layout = ({ headerLogoBig, onEndTraining }: LayoutProps) => {
    const { pageHeader } = usePageHeader()
    const { backToDashboardLine } = useBackToDashboardLine()
    const { refreshTokenInfo, setRefreshTokenInfo } = useRefreshTokenInfo()
    const { infoPortal, showInfo } = useInfoPopup()
    const { signOut } = useSignOut(showInfo)

    useEffect(() => {
        if (refreshTokenInfo.logoutIsRequired) {
            signOut(true)
            setRefreshTokenInfo(current => ({ ...current, logoutIsRequired: false }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTokenInfo])

    return <>
        {infoPortal}
        <div className={styles.headerWrapper}>
            <Header logoBig={headerLogoBig} onEndTraining={onEndTraining} />
        </div>
        {pageHeader}
        {backToDashboardLine}
        <div className={styles.content}>
            <Outlet />
        </div>
    </>
}

export default Layout