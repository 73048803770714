import Popup, { PopupProps } from 'components/molecules/Popup'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

export const usePopup = () => {
    const [open, setOpen] = useState(false)
    const [popupProps, setPopupProps] = useState<PopupProps>({})

    const popupPortal = useMemo(
        () =>
            !open
                ? null
                : createPortal(
                    <Popup
                        {...popupProps}

                        open={open}
                        onCancel={(e) => {
                            popupProps.onCancel?.(e)
                            if (popupProps.stayOpenedOnCancel) {
                                return false
                            } else {
                                hide()
                            }
                        }}
                        onOk={(e) => {
                            popupProps.onOk?.(e)
                            hide()
                        }}
                    />,
                    document.body
                ),
        [open, popupProps]
    )

    const show = (props: PopupProps) => {
        setPopupProps(props)
        setOpen(true)
    }

    const hide = () => {
        setOpen(false)
    }

    return { show, hide, open, popupPortal }
}
