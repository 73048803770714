import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'

export function useSignElectionResult(electionId: string) {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function signElectionResult() {
        return await axios(`${moduleUrl}elections/${electionId}/vote-counting/election-results/sign`,
            {
                method: 'post',
                data: {
                }
            })
    }

    return useMutation({
        mutationFn: signElectionResult
    })
}