import { AbilityBuilder } from '@casl/ability'
import { Auth, Role } from 'models'
import { AppAbility } from './ability'

export type ConfigSubject =
    'Configuration'
export function defineConfigAbility(builder: AbilityBuilder<AppAbility>, auth?: Auth) {
    const { can } = builder

    if (auth?.role === Role.SYSTEM_ADMIN) {
        can('edit', 'Configuration')
    }
}
