import Footer from 'components/molecules/Footer'
import BallotingStepsManagement from '../../components/BallotingStepsManagement'
import VotingWrapper from '../../components/VotingWrapper'

export function Balloting() {
    return (
        <VotingWrapper>
            <BallotingStepsManagement />
            <Footer />
        </VotingWrapper>
    )
}

export default Balloting

