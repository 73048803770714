import { SortOrder, Sortable } from 'models'

export const useSortable = () => {

    const changeSortBy = (sortable: Sortable, field: string, order?: SortOrder) => {
        if (!sortable.sortBy || sortable.sortBy.field !== field) {
            sortable.sortBy = {
                field,
                order: order || SortOrder.ASC
            }

            return { ...sortable, sortBy: sortable.sortBy }
        }

        if (order) {
            sortable.sortBy.order = order
        }
        else {
            const o = sortable.sortBy.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC
            sortable.sortBy.order = o
        }

        return { ...sortable, sortBy: { ...sortable.sortBy } }
    }

    return { changeSortBy }
}