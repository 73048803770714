import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { InvalidationReason } from 'models'

export const useVoteInvalidationReasons = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getVoteInvalidationReasons = async ({ queryKey }: QueryFunctionContext)
        : Promise<InvalidationReason[]> => {
        const [, electionId] = queryKey

        const result = await axios<InvalidationReason[]>(
            `${moduleUrl}elections/${electionId}/vote-invalidation-reasons`)

        return result.data
    }

    return useQuery({
        queryKey: ['vote-invalidation-reasons', electionId],
        queryFn: getVoteInvalidationReasons,
        enabled
    })
}