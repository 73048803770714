import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionType, HomeLocality } from 'models'
import { getElectionTypeApiModule } from '../../utils'

export const useLocalities = (electionPeriodId: string,
    type: ElectionType,
    enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getLocalities = async ({ queryKey }: QueryFunctionContext)
        : Promise<HomeLocality[]> => {
        const [, electionPeriodId, type] = queryKey

        const result = await axios<HomeLocality[]>(
            `${moduleUrl}ridvan-election-periods/${electionPeriodId}/` +
            `${getElectionTypeApiModule(type as ElectionType)}/participants/home-localities`)

        return result.data
    }

    return useQuery({
        queryKey: ['election-period-localities', electionPeriodId, type],
        queryFn: getLocalities,
        enabled
    })
}