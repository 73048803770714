import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { AxiosError } from 'axios'
import Input from 'components/atoms/Input'
import Note from 'components/molecules/Note'
import { administration } from 'features/administration'
import { useAccessToken, useChangeUser } from 'hooks'
import { Role } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import packageJson from '../../../../../package.json'
import { useAuthSa } from '../../api'
import { useAvailableRoles } from '../../hooks'
import Panel from '../common/Panel'
import PanelContent from '../common/PanelContent'
import PanelHeader from '../common/PanelHeader'
import style from './index.module.scss'

function SaSignInPanel() {
    const { t } = useTranslation('auth')
    const { setAccessToken } = useAccessToken()
    const { mutateAsync: authSystemAdmin } = useAuthSa()
    const { changeRole } = useChangeUser()
    const [form] = useForm()
    const [error, setError] = useState('')
    const { availableRoles } = useAvailableRoles(false)
    const { resetAdminDashboardData } = administration.hooks.useResetAdminDashboardData()


    useEffect(() => {
        localStorage.removeItem('theme')
        localStorage.removeItem('user')
        resetAdminDashboardData()
        setAccessToken(null)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (availableRoles?.isSystemAdmin) {
            changeRole(Role.SYSTEM_ADMIN)

            return
        }
        if (availableRoles?.isNsaAdmin) {
            changeRole(Role.NSA_ADMIN)

            return
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableRoles])

    const submit = async () => {
        try {
            await form.validateFields()
        } catch {
            return
        }
        const credentials = form.getFieldsValue()
        try {
            const token = await authSystemAdmin({
                login: credentials.loginName,
                password: credentials.password
            })

            setAccessToken(token.accessToken)
        } catch (e: any) {
            const err = e as AxiosError
            setError((err?.response?.data || '') as string)

            return
        }
    }

    return (
        <Panel >
            <PanelHeader
                tabIndex={0}
                role={'contentinfo'}
                ariaLabel={t('sing_in_balloting_online_system')}
                className={style.header}>
                <label>
                    {t('obs_v_version', { version: packageJson?.version })}
                </label>
            </PanelHeader>
            <PanelContent>
                {!!error
                    && <div className={style.error}>
                        <Note
                            icon="filled"
                            mode="error">
                            {error}
                        </Note>
                    </div>
                }
                <Form
                    name="credentials"
                    layout="vertical"
                    onValuesChange={() => { if (error) setError('') }}
                    form={form}>
                    <Form.Item
                        name={['loginName']}
                        label={t('login')}
                        rules={[{
                            required: true,
                            message: t('common:please_enter_the_input', { input: t('login') })
                        }
                        ]}
                    >
                        <Input
                            placeholder={t('common:please_enter_the_input', { input: t('login') })}
                        />
                    </Form.Item>
                    <Form.Item
                        name={['password']}
                        label={t('password')}
                        rules={[{
                            required: true,
                            message: t('common:please_enter_the_input', { input: t('password') })
                        }
                        ]}
                    >
                        <Input
                            type="password"
                            placeholder={t('common:please_enter_the_input', { input: t('password') })}
                        />
                    </Form.Item>
                    <button
                        className={`${style.button} btn-main-primary`}
                        type="submit"
                        onClick={submit}>
                        {t('sign_in')}
                    </button>
                </Form >
            </PanelContent>
        </Panel>
    )
}

export default SaSignInPanel