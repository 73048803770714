import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { OnlineVotingInfo } from 'models'

type OnlineVotingInfosParams = {
    electionIds: string[]
}
export const useOnlineVotingInfos = ({ electionIds }: OnlineVotingInfosParams, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getOnlineVotingInfos = async () => {

        const result = await axios<Promise<OnlineVotingInfo[]>>(
            `${moduleUrl}elections/online-voting?elections=${electionIds.join(',')}`)

        return result.data
    }

    return useQuery({
        queryKey: ['online-voting-infos', electionIds],
        queryFn: getOnlineVotingInfos,
        enabled
    })
}