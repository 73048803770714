import { useOnlineVoterDashboardWording } from 'api'
import Card from 'components/atoms/Card'
import { useMemo } from 'react'
import style from './index.module.scss'

export function NoActiveElection() {
    const { data: onlineVoterDashboardWording } = useOnlineVoterDashboardWording()

    const sorryTextBody = useMemo(() => {
        if (!onlineVoterDashboardWording?.body) {
            return null
        }

        return (
            <div dangerouslySetInnerHTML={{ __html: onlineVoterDashboardWording?.body }} />
        )
    }, [onlineVoterDashboardWording?.body])

    return (
        <Card noContentPadding={true} noHeaderLine={true} title={
            <div tabIndex={0} className={style.sorryHeaderText}>{onlineVoterDashboardWording?.title}</div>}>
            <div tabIndex={0} role={'contentinfo'} className={style.sorryTextWrapper}>
                {sorryTextBody}
            </div>
        </Card>
    )
}

export default NoActiveElection