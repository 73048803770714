import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { StationResetMode } from '../../models'

export type PartialResetParams = {
    electionId: string
    stationId: string,
    mode: StationResetMode
}
export function usePartialReset() {
    const axios = useAxios()
    const queryClient = useQueryClient()
    const moduleUrl = getModuleUrl(ApiModule.Toolkit)

    async function partialReset({ electionId, stationId, mode }: PartialResetParams) {

        const modeUrl = mode === StationResetMode.FullReset ? 'full-reset' : 'partial-reset'

        return await axios(moduleUrl
            + `elections/${electionId}/stations/${stationId}/${modeUrl}`,
            {
                method: 'post'
            })
    }

    return useMutation({
        mutationFn: partialReset,
        onSuccess: () => {
            queryClient.invalidateQueries(['election-stations-details'])
            queryClient.invalidateQueries(['ballots-count-of-station'])
        },
        retry: 3
    })
}