import { useQueryClient } from '@tanstack/react-query'
import {
    useBreakingTieRounds,
    useElectedCandidates,
    useElection,
    useElectionRestrictionsOld,
    useElectionResultsTie,
    useElectionSummary,
    useElectionTellingStations,
    useOnlineVoting,
    useTieDesignatedCandidates,
    useVoteCountingStarted
} from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import MilestoneItem from 'components/atoms/MilestoneItem'
import ElectionBadge from 'components/molecules/ElectionBadge'
import Preloader from 'components/molecules/Preloader'
import { auth as authFeature } from 'features/auth'
import { useAbility, useAuth, useChangeUser, usePopup } from 'hooks'
import { ReactComponent as ArrowDown } from 'icons/arrow-down.svg'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ReactComponent as QuestionInCircle } from 'icons/question-mark-in-circle.svg'
import { ElectionStatusType, MilestoneColorType, Restriction, Role } from 'models'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { dayjsToString, getElectionRestrictions } from 'utils'
import { stationLauncher } from '../../../stationLauncher'
import { tellingProcess } from '../../../tellingProcess'
import style from './index.module.scss'

function TellingProcessMilestone() {
    const { t } = useTranslation('teller')
    const { electionId } = useParams()
    const { auth } = useAuth()
    const { popupPortal, show, hide } = usePopup()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { ability } = useAbility()

    const { availableRoles } = authFeature.hooks.useAvailableRoles()
    const { data: election } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: electionResultSummary } = useElectionSummary(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: onlineVoting } = useOnlineVoting(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: tellingStations } = useElectionTellingStations(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: voteCountingStarted } = useVoteCountingStarted(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: electionResultTie } = useElectionResultsTie(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: electedCandidates } = useElectedCandidates(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: tieDesignatedCandidates } = useTieDesignatedCandidates(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: breakingTieRounds } = useBreakingTieRounds(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)

    const [milestoneColor, setMilestoneColor] = useState<MilestoneColorType>(MilestoneColorType.DEFAULT)
    const [milestoneStatus, setMilestoneStatus] = useState<ElectionStatusType | undefined>()
    const [milestoneDescription, setMilestoneDescription] = useState<string | undefined>()
    const [milestoneAction, setMilestoneAction] = useState<ReactNode>()
    const [restriction, setRestriction] = useState<Restriction | null | undefined>()

    const numberOfVacancies = election?.numberOfVacancies ?? 0
    const isAnyBreakingTieRound = breakingTieRounds?.some(round => round.status !== ElectionStatusType.CANCELED)
    const isTieResolved = electedCandidates?.length === numberOfVacancies && electionResultTie?.resolution.resolved
        && !electionResultSummary?.tiePresent

    const formattedDate = election ? dayjsToString(election.voteCountingStart, 'MM/DD/YYYY, h:mm A') : ''

    const [waitingForOnlineVotingStop, setWaitingForOnlineVotingStop] = useState(false)

    const { openLaunchStationPopup } =
        stationLauncher.hooks.useLaunchStationPopup(electionId || auth!.electionId!, show, hide)

    const { changeRole } = useChangeUser()

    const { selectElectionFlow } =
        tellingProcess.hooks.useSelectElectionFlow(show, hide, auth!.electionId!)

    const onVotingStop = async () => {
        setWaitingForOnlineVotingStop(true)
    }

    useEffect(() => {
        if (waitingForOnlineVotingStop) {
            if (!onlineVoting?.closed && !onlineVoting?.votingReportAccepted) {
                setTimeout(async () => {
                    await queryClient.invalidateQueries(['online-voting'])
                }, 1000)
            } else {
                setWaitingForOnlineVotingStop(false)
                queryClient.invalidateQueries(['voting'])
            }
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onlineVoting,
            waitingForOnlineVotingStop
        ])

    const { stopOnlineVoting } =
        tellingProcess.hooks.useStopOnlineVoting(show, hide, auth!.electionId!, onVotingStop)

    const { data: restrictions } = useElectionRestrictionsOld()

    const { unmergeResults } =
        tellingProcess.hooks.useUnmergeResults(show, hide)

    useEffect(() => {
        if (election && restrictions) {
            setRestriction(getElectionRestrictions(election.type, restrictions))
        }
    },
        [election, restrictions])

    useEffect(() => {
        if (onlineVoting && restriction && election) {
            if (restriction.bypassFlowAllowed && election.bypassFlow) {
                setMilestoneStatus(undefined)

                return
            }
            if (!onlineVoting.closed) {
                setMilestoneStatus(ElectionStatusType.NOT_STARTED)

                return
            }
            if (electionResultSummary?.mergedBy) {
                setMilestoneStatus(ElectionStatusType.COMPLETED)

                return
            }

            if (election.bypassFlow !== null) {
                setMilestoneStatus(ElectionStatusType.IN_PROGRESS)

                return
            }

            if (voteCountingStarted) {
                if (!restriction.bypassFlowAllowed) {
                    setMilestoneStatus(ElectionStatusType.IN_PROGRESS)

                    return
                }
                setMilestoneStatus(ElectionStatusType.NOT_STARTED)

                return
            }
        }
    },
        [onlineVoting,
            restriction,
            election,
            electionResultSummary,
            voteCountingStarted])

    useEffect(() => {
        if (election) {
            if (election.bypassFlow) {
                setMilestoneColor(MilestoneColorType.DEFAULT)

                return
            }
            if (electionResultSummary?.mergedBy) {
                setMilestoneColor(MilestoneColorType.SUCCESS)

                return
            }
            if (voteCountingStarted || onlineVoting?.votingReportAccepted) {
                setMilestoneColor(MilestoneColorType.PRIMARY)
            } else {
                setMilestoneColor(MilestoneColorType.DEFAULT)
            }
        }
    }, [
        election,
        electionResultSummary,
        voteCountingStarted,
        onlineVoting
    ])

    useEffect(() => {
        if (election) {
            if (election.bypassFlow) {
                setMilestoneDescription(t('online_ballots_only_mode_enabled'))
            } else {
                setMilestoneDescription(undefined)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        election
    ])

    useEffect(() => {
        if (election && onlineVoting) {

            if (ability?.can('view-only', 'ChiefTellerActions')) {
                if (election.bypassFlow) {
                    setMilestoneAction(null)
                } else {
                    if (electionResultSummary?.mergedBy) {
                        setMilestoneAction(<button className="btn-main-secondary-md"
                            onClick={() => {
                                navigate(`/voter-verification/${electionId || auth!.electionId!}`,
                                    electionId
                                        ? { state: { backButtonTitle: t('election:back_to_election_board') } }
                                        : undefined)
                            }}>
                            {t('view_voter_verification_details')}
                        </button>)
                    } else {
                        setMilestoneAction(
                            <div className={style.description}>
                                {t('only_chief_teller_can_proceed_with_telling_process')}
                                {ability?.can('perform', 'ChangeRole') &&
                                    availableRoles.chiefTellerElections?.some(el => el.id.toString() === electionId) &&
                                    <span className={style.link}
                                        onClick={() => changeRole(Role.CHIEF_TELLER,
                                            electionId)}>
                                        {t('change_role')}
                                        <span className={style.arrowDownIcon}>
                                            <ArrowDown className={`${style.icon} rotate-270`}
                                                width="14" height="14" /></span>
                                    </span>}
                            </div>)
                    }
                }

                return
            }

            if (election.bypassFlow) {
                if (isTieResolved || electionResultSummary?.signedBy || isAnyBreakingTieRound
                    || (tieDesignatedCandidates?.length ?? 0) !== 0) {

                    setMilestoneAction(null)
                } else {
                    setMilestoneAction(
                        <button className={`btn-main-tertiary-md ${style.linkLikeButton}`}
                            onClick={onStandardFlowClick}>
                            <IconWrapper><QuestionInCircle height={16} width={16} /></IconWrapper>
                            {t('what_if_we_have_physical_ballots')}
                        </button>
                    )
                }

                return
            }

            if (electionResultSummary?.mergedBy) {
                setMilestoneAction(<button className="btn-main-secondary-md"
                    onClick={() => {
                        navigate('/voter-verification',
                            electionId
                                ? { state: { backButtonTitle: t('election:back_to_election_board') } }
                                : undefined)
                    }}>
                    {t('view_voter_verification_details')}
                </button>)

                return
            }
            if (!onlineVoting.closed) {
                if (voteCountingStarted) {
                    setMilestoneAction(<button className="btn-main-primary-md"
                        onClick={() => {
                            stopOnlineVoting(election.bypassFlow === null,
                                !restriction?.bypassFlowAllowed)
                        }}>
                        {t('start_telling_process')}</button>)

                    return

                } else {
                    setMilestoneAction(<button className="btn-main-secondary-md" disabled>
                        {`${t('starts_on')} ${formattedDate}`}
                    </button>)

                    return
                }
            }

            if (election.bypassFlow === null) {
                setMilestoneAction(<button className="btn-main-primary-md"
                    onClick={() => { selectElectionFlow(!restriction?.bypassFlowAllowed) }}>
                    {t('start_telling_process')}</button>)

                return
            }

            if (tellingStations?.length) {
                setMilestoneAction(<button className="btn-main-primary-md" onClick={() => { openLaunchStationPopup() }}>
                    {t('go_to_telling_station')}
                </button>)

                return
            }

            setMilestoneAction(<button className="btn-main-primary-md"
                onClick={() => { navigate(`/election-stations/${election.id}`) }}
            >
                {t('set_up_telling_station_s')}
            </button>)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        election,
        onlineVoting,
        electionResultSummary,
        tellingStations,
        restriction
    ])

    const onStandardFlowClick = () => {
        if (electionResultSummary?.mergedBy) {
            unmergeResults()
        } else {
            selectElectionFlow()
        }
    }

    return (
        <>
            {waitingForOnlineVotingStop && <Preloader />}
            <div className={style.contentWrapper}>
                {popupPortal}
                <MilestoneItem
                    title={t('telling_process')}
                    color={milestoneColor}
                    description={milestoneDescription}
                    children={milestoneAction}
                    status={milestoneStatus ? <ElectionBadge status={milestoneStatus} /> : null}
                />
                <div className={style.arrowIcon}><Chevron /></div>
            </div>
        </>
    )
}

export default TellingProcessMilestone