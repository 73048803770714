import IconWrapper from 'components/atoms/IconWrapper'
import Preloader from 'components/molecules/Preloader'
import { useReportLoader } from 'hooks'
import { ReactComponent as ArrowDownload } from 'icons/arrow-download.svg'
import { ReportFormat, ReportType } from 'models'
import { useTranslation } from 'react-i18next'
import { useOfficersElectionsData } from '../../hooks'
import { OfficersElection } from '../../model'
import style from './index.module.scss'

type ElectionReportCellProps = {
    election: OfficersElection
}

function OfficersElectionReportCell({ election }: ElectionReportCellProps) {
    const { t } = useTranslation()
    const { loadReport, isReportLoading } = useReportLoader()
    const { officersElectionsData } = useOfficersElectionsData()

    const downloadReport = () => {
        loadReport({
            reportType: ReportType.OfficersElectionResultsReport,
            reportFormat: ReportFormat.PDF,
            params: {
                ballotingYear: officersElectionsData?.badiYear,
                locality: election.localityCode
            }
        })
    }

    return (
        <>
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            <button className={`btn-main-tertiary-md ${style.download}`}
                    onClick={downloadReport}>
                <IconWrapper>
                    <ArrowDownload />
                </IconWrapper>
            </button>
        </>
    )
}

export default OfficersElectionReportCell