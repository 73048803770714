import { PropsWithChildren } from 'react'
import style from './index.module.scss'

function ReportRightCardBlock({ children }: PropsWithChildren) {
    return (
        <div className={style.totalStatisticWrapper}>
            {children}
        </div>
    )
}

export default ReportRightCardBlock
