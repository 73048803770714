import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { Vote } from '../../models'

type StationSubmitBallotParams = {
    ballotId: string,
    votes?: Vote[],
    invalidationReason?: string
}

export function useStationSubmitBallot() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    async function stationSubmitBallot({ ballotId, votes, invalidationReason }: StationSubmitBallotParams) {
        return await axios(moduleUrl
            + 'stations/my/submit-ballot',
            {
                method: 'post',
                data: {
                    id: ballotId,
                    votes,
                    invalidationReason
                }
            })
    }

    return useMutation({
        mutationFn: stationSubmitBallot
    })
}