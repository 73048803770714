import PopupTitle from 'components/atoms/PopupTitle'
import { PopupProps } from 'components/molecules/Popup'
import { useTranslation } from 'react-i18next'
import TieVoting from '../components/TieVoting'
import style from './index.module.scss'

export const useRevotePopup = (show: (props: PopupProps) => void, hide: () => void) => {
    const { t } = useTranslation('teller')

    const showRevotePopup = () => {
        hide()
        show({
            title: (
                <PopupTitle>
                    <div>{t('break_the_tie_revote_using_obs')}</div>
                    <div className={style.popupSubtitle}>
                        {t('revote_using_obs_round_will_occur_for_open_position')}
                    </div>
                </PopupTitle>
            ),
            children: <TieVoting
                isDesignateElectee={false}
                onClose={hide}
            />,
            width: '890px',
            footer: null
        })
    }

    return {
        showRevotePopup
    }
}