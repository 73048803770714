import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { Ballot } from 'models'

export const useBallots = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getBallots = async (): Promise<Ballot[]> => {
        const response = await axios<Ballot[]>(moduleUrl + 'elections/my/stations/my/ballots')

        const ballots = response.data

        if (ballots.length) {
            return ballots.sort((a, b) => {
                const aId = parseInt(a.id.split('-')[1])
                const bId = parseInt(b.id.split('-')[1])

                return bId - aId
            })
        }

        return ballots
    }

    return useQuery({
        queryKey: ['my-station-ballots'],
        queryFn: getBallots,
        enabled
    })
}