import React, {ReactNode} from 'react'
import styles from './index.module.scss'

interface CardSubheaderProps {
    children?: ReactNode,
    title?: ReactNode,
    subTitle?: ReactNode,
    badge?: ReactNode
    noPadding?: boolean
}

const CardSubheader: React.FC<CardSubheaderProps> = ({children, title, subTitle, badge, noPadding}) => (
        <div className={`${styles.header} ${noPadding ? styles.noPadding : ''}`}>
            <div className={styles.titleSection}>
                {title && <div className={styles.title}>{title}</div>}
                {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
            </div>
            {badge && <div className={styles.statusName}>{badge}</div>}
            {children && <div className={styles.rightSection}>{children}</div>}
        </div>
    )

export default CardSubheader