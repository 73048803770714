export const getTableColumnWidths = (withLocalUnit?: boolean)  => ({
    name: withLocalUnit ? 260 : 380,
    id: 140,
    gender: 120,
    ageCategory: 120,
    homeLocality: 135,
    homeLocalUnit: 120,
    city: 187,
    votedOnline: 120,
    action: 140
})
