import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export type StationChangeInvalidateBallotParams = {
    id: string
    invalidationReason: string
}
export function useStationChangeInvalidateBallot() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    async function stationChangeInvalidateBallot({ id, invalidationReason }: StationChangeInvalidateBallotParams) {
        return await axios(moduleUrl
            + `stations/my/ballots/${id}/change-invalidation-reason`,
            {
                method: 'post',
                data: {
                    invalidationReason
                }
            })
    }

    return useMutation({
        mutationFn: stationChangeInvalidateBallot
    })
}