import { $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getRoot, $insertNodes, $setSelection } from 'lexical'
import { useEffect } from 'react'
type Props = {
    initialContent?: string,
    onContentLoaded?: () => void
}

function RichTextEditorLoadInitialContent({ initialContent, onContentLoaded }: Props) {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        if (initialContent) {
            editor.update(
                () => {
                    const parser = new DOMParser()
                    const dom = parser.parseFromString(initialContent, 'text/html')
                    const nodes = $generateNodesFromDOM(editor, dom)
                    $getRoot().clear()
                    $insertNodes(nodes as any)
                    onContentLoaded?.()
                    $setSelection(null)
                }
            )
        }
    }, [initialContent])

    return null
}

export default RichTextEditorLoadInitialContent