import { useStationBallotsSummary } from 'api'

type StationValidBallotsProps = {
    stationId: string,
    electionId: string
}

function StationValidBallots({ electionId, stationId }: StationValidBallotsProps) {

    const { data: stationBallotsSummary } = useStationBallotsSummary({ electionId, stationId })

    return (
        <>
            {stationBallotsSummary?.validBallotCount || 0}
        </>
    )
}

export default StationValidBallots