import PopupTitle from 'components/atoms/PopupTitle'
import { PopupProps } from 'components/molecules/Popup'
import { useTranslation } from 'react-i18next'
import TieSelectFlow from '../components/TieSelectFlow'
import style from './index.module.scss'

export const useBreakTheTieFlowPopup = (show: (props: PopupProps) => void, hide: () => void) => {
    const { t } = useTranslation('teller')

    const showBreakTheTieFlowPopup = (
        showDesignateElecteePopup: () => void, showRevotePopup: () => void) => {
        show({
            title: (
                <PopupTitle>
                    <div>{t('break_the_tie')}</div>
                    <div className={style.popupSubtitle}>
                        {t('please_select_how_you_will_break_the_tie_for_open_position')}
                    </div>
                </PopupTitle>
            ),
            children: <TieSelectFlow
                onClose={hide}
                onProceedDesignateElectee={showDesignateElecteePopup}
                onProceedRevoteUsing={showRevotePopup} />,
            width: '660px',
            footer: null
        })
    }

    return { showBreakTheTieFlowPopup }
}