import Preloader from 'components/molecules/Preloader'
import { useAccessToken, useAuth, useChangeUser } from 'hooks'
import { Role } from 'models'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isSandbox } from 'utils'
import { useExchangeToken, useMyDetails } from '../../api'
import { useAvailableRoles } from '../../hooks'

function PerformAuth() {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { changeAuth, changeRole } = useChangeUser()
    const [searchParams] = useSearchParams()
    const [tokenProvided, setTokenProvided] = useState(false)
    const [loginTokenUsed, setLoginTokenUsed] = useState(false)
    const { data: myDetails, isFetching } = useMyDetails(tokenProvided && !!auth)
    const { mutateAsync: exchangeToken } = useExchangeToken()
    const { accessToken, setAccessToken } = useAccessToken()
    const { availableRoles, isVoterOnly } = useAvailableRoles(!!accessToken && !isSandbox)

    const exchange = async () => {
        const loginToken = searchParams.get('login-token')
        if (loginToken) {
            const result = await exchangeToken(loginToken)

            setAccessToken(result.data as string)
            setTokenProvided(true)
        }
    }

    useEffect(() => {
        //As StrictMode is enabled, the component is created twice in devmode. But login token can be used only once
        if (process.env.NODE_ENV === 'development' && !loginTokenUsed) {
            exchange()
            setLoginTokenUsed(true)
        } else {
            exchange()
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    useEffect(() => {
        if (!myDetails) {
            return
        }
        changeAuth({ homeLocality: myDetails.homeLocality, id: myDetails.id, name: myDetails.name })

        if (isSandbox) {
            navigate('/', { replace: true })

            return
        }

        if (!!auth && availableRoles.tokenChecked && availableRoles.chiefTellerElections
            && availableRoles.officerElectionTellerRoleChecked
        ) {
            if (isVoterOnly(availableRoles)) {
                changeRole(Role.VOTER)
            } else {
                navigate('/function', { replace: true })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myDetails, availableRoles, auth])

    useEffect(() => {
        if (!!accessToken && !isFetching && isVoterOnly(availableRoles)
            && availableRoles.tokenChecked && availableRoles.chiefTellerElections
            && !!availableRoles.officerElectionTellerInfo && availableRoles.officerElectionTellerRoleChecked) {
            changeRole(Role.VOTER)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableRoles, isFetching, accessToken])

    return (
        <Preloader />
    )
}

export default PerformAuth