import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { BallotsSummary } from 'models'

export const useElectionBallotsCounts = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getStationsBallotsCounts = async () => {
        const res =
            await axios<BallotsSummary>(`${moduleUrl}elections/${electionId}/ballots/count`)

        return res.data
    }

    return useQuery<BallotsSummary>({
        queryKey: [electionId, 'election-physical-ballots-counts'],
        queryFn: getStationsBallotsCounts,
        enabled
    })
}