import { ConfirmPopupSettings } from 'hooks'
import { useTranslation } from 'react-i18next'

export const useCancelEditingPopup = (
    showConfirm: (settings: ConfirmPopupSettings) => void
) => {
    const { t } = useTranslation('results')


    const onConfirmPopup = (onOk?: () => void) => {
            showConfirm({
                title: t('cancel_comment_changes'),
                text: t('would_you_like_to_cancel_the_editing_of_the_comment'),
                cancelText: t('continue_editing'),
                okText: t('cancel_changes'),
                onOk: async () => onOk?.()
            })
        }

        return { onConfirmPopup }
}