import { useElectionSummary } from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import Preloader from 'components/molecules/Preloader'
import StatisticCounter from 'components/molecules/StatisticCounter'
import { useAuth, useReportLoader } from 'hooks'
import { ReactComponent as ArrowDownload } from 'icons/arrow-download.svg'
import { ReportFormat, ReportType } from 'models'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useVoterVerificationTableData } from '../../hooks'
import style from './index.module.scss'

function VoterVerificationTableStatistic() {
    const { t } = useTranslation('telling-station')
    const { electionId } = useParams()
    const { auth } = useAuth()
    const { tableData } = useVoterVerificationTableData()
    const { loadReport, isReportLoading } = useReportLoader()

    const { data: electionResultSummary } = useElectionSummary(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)

    const downloadReport = () => {
        loadReport({
            reportType: ReportType.VoterVerificationList,
            reportFormat: ReportFormat.XLSX,
            params: {
                election: electionId || auth!.electionId!
            }
        })
    }

    return (
        <div className={style.headerWrapper}>
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            <div className={style.caption}>
                <div className={style.tableTitles}>
                    <div className={style.tableTitle}>
                        {t('voter_verification')}
                    </div>
                    <div className={style.tableSubtitle}>
                        {t('total_numbers_shown_on_the_right_are_for_all_stations')}
                    </div>
                </div>
                <div className={style.statistic} id="voter-verification-statistic">
                    {!!electionResultSummary?.signedBy && <button className="btn-main-tertiary-md"
                        onClick={() => downloadReport()}>
                        <IconWrapper><ArrowDownload className="flip-horizontal" width="16" height="16" /></IconWrapper>
                        {t('download_the_voter_verification_list')}
                    </button>
                    }
                    <StatisticCounter value={tableData?.statistics?.totalVotersCount || 0}
                        title={t('eligible_voters')} />
                    <StatisticCounter value={tableData?.statistics?.verifiedCount || 0}
                        title={t('verified_voters')} />
                    <StatisticCounter value={tableData?.statistics?.inPersonCount || 0}
                        title={t('in_person')} />
                    <StatisticCounter value={tableData?.statistics?.absenteeCount || 0}
                        title={t('absentee')} />
                    <StatisticCounter value={tableData?.statistics?.onlineVotersCount || 0}
                        title={t('online')} />
                </div>
            </div>
        </div>
    )
}

export default VoterVerificationTableStatistic