import { PhoneNumberUtil } from 'google-libphonenumber'

export const isPhoneNumberValidFunc = (phoneString: string) => {
    if (!phoneString) {
        return true
    }
    const phoneUtil = PhoneNumberUtil.getInstance()
    try {
        const phoneNumber = phoneUtil.parse(phoneString, 'US')

        return phoneUtil.isValidNumber(phoneNumber)
    } catch {
        return false
    }
}

export const phoneNumberValidator = (_: any, value: string) => {
    if (isPhoneNumberValidFunc(value)) {
        return Promise.resolve()
    }

    return Promise.reject()
}

export const isPhoneLengthCorrect = (phone: string) => {
    const correctPhoneLength = 12

    return phone.length === correctPhoneLength
}