import AntTextArea from 'antd/lib/input/TextArea'

type TextAreaProps = React.ComponentProps<typeof AntTextArea>
export function TextArea(props: TextAreaProps) {
    return (
        <AntTextArea {...props} className={props.className || ''}>
            {props.children}
        </AntTextArea>
    )
}

export default TextArea