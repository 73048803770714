import { FormInstance } from 'antd'
import { ElectionSchedule } from 'models'

export function useExtractSetUpElectionSchedule() {
    const extractSchedule = (form: FormInstance) => {
        const formValues = form.getFieldsValue()
        const electionSchedule: ElectionSchedule = {
            timeZone: formValues.timeZone,
            electionDay: formValues?.electionDay?.format('YYYY-MM-DD'),
            onlineVotingStart: formValues?.onlineVotingStart?.format('YYYY-MM-DD')
        }

        return electionSchedule
    }

    return { extractSchedule }
}