import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { queryClient } from 'config/query-client'
import { useState } from 'react'

export function useSkipBreakingTie() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)
    const [electionId, setElectionId] = useState('')

    async function skipBreakingTie(electionId: string) {
        setElectionId(electionId)

        return await axios(`${moduleUrl}elections/${electionId}/skip-breaking-tie`,
            {
                method: 'post',
                data: {}
            })
    }

    return useMutation({
        mutationFn: skipBreakingTie,
        onSuccess: async () => {
            await queryClient.invalidateQueries(['election-summary', electionId])
        }
    })
}