import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { queryClient } from 'config/query-client'
import { LsaOfficerRole } from 'models'

export function useAnnounceOfficersElection() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function announceOfficersElection(position: LsaOfficerRole) {
        return await axios(moduleUrl + 'officers-election-periods/election/announce',
            {
                method: 'post',
                data: {
                    position
                }
            })
    }

    return useMutation({
        mutationFn: announceOfficersElection,
         onSuccess: () => {
             queryClient.invalidateQueries(['my-officers-election'])
        }
    })
}