import { InfoPopupSettings, useAuth } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import style from './index.module.scss'

export const useHandleStationLogoutError = () => {
    const { t } = useTranslation('telling-station')
    const { auth } = useAuth()
    const navigate = useNavigate()

    const handleError = (error: any, showInfo: (settings: InfoPopupSettings) => void) => {
        if (error.response && error.response.status === 403 &&
            error.response.data === `The station '${auth?.stationId}' has already launched on another computer.`) {
            showInfo({
                onOk: () => navigate('/sign-in'),
                title: t('station_launched_on_another_device'),
                text: <div className={style.bodyText}>
                    {t('the_telling_station_mode_has_been_activated_for_station_number_on_another_device',
                        { number: auth?.stationId?.replace('S', '') })}
                    {t('common:for_security_reasons_you_are_about_to_be_logged_out')}
                    <div className={style.footerText} style={{ paddingTop: 'var(--default-space)' }}>
                        <b>
                            {/* eslint-disable-next-line max-len */}
                            {t('please_ask_your_chief_teller_to_log_back_in_and_relaunch_the_station_in_case_of_an_error')}
                        </b>
                    </div>
                </div>
            })
        } else {
            throw error
        }
    }

    return { handleError }
}