import { useHandleEntityLoadingError } from 'hooks'
import { ElectionPeriodStatus } from 'models'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useElectionPeriod } from '../../api'
import { isRidvanStage } from '../../utils'
import LocalDelegateEditingProcess from '../LocalDelegateEditingProcess'
import RidvanEditingProcess from '../RidvanEditingProcess'

function DefineStage() {
    const { electionPeriodId } = useParams()
    const { data: ridvanElectionPeriod, isFetching, error } = useElectionPeriod(electionPeriodId!)
    const navigate = useNavigate()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()

    useEffect(() => {
        if (ridvanElectionPeriod) {
            if (ridvanElectionPeriod.status === ElectionPeriodStatus.CANCELED
                || ridvanElectionPeriod.status === ElectionPeriodStatus.CLOSED) {
                navigate('/', { replace: true })

                return
            }
        }
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ridvanElectionPeriod, error])

    if (isFetching || !ridvanElectionPeriod) {
        return <></>
    }

    const isRidvan = isRidvanStage(ridvanElectionPeriod)

    return (
        <>
            {isRidvan && <RidvanEditingProcess ridvanElectionPeriod={ridvanElectionPeriod!} />}
            {!isRidvan && <LocalDelegateEditingProcess ridvanElectionPeriod={ridvanElectionPeriod!} />}
        </>
    )
}

export default DefineStage