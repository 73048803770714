import PageHeader from 'components/atoms/PageHeader'
import BackToDashboardBtn from 'components/molecules/BackToDashboardBtn'
import { usePageHeader } from 'hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { voterVerification } from '../../features/voterVerification'
import TellingStationProvider from '../../providers/TellingStationProvider'
import style from './index.module.scss'

function ViewVoterVerification() {
    const { setPageHeader } = usePageHeader()
    const navigate = useNavigate()

    useEffect(() => {
        setPageHeader(
            <PageHeader
                sticky={true}
                greeting={<BackToDashboardBtn onClick={() => { navigate(-1) }} />} />
        )

        return () => { setPageHeader(null) }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={style.wrapper}>
            <TellingStationProvider>
                <voterVerification.component />
            </TellingStationProvider>
        </div>
    )
}

export default ViewVoterVerification