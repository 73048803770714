import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

type SaveDailyReportEmailsParams = {
    name: string,
    enabled: boolean
    reportJobConfiguration: {triggerTime: string}
}

export function useSaveDailyReportEmails() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Report)

    async function saveDailyReportEmails({ name, enabled, reportJobConfiguration }: SaveDailyReportEmailsParams) {
        return await axios(moduleUrl + 'lsa-election-statistics/change-job-configuration',
            {
                method: 'post',
                data: {
                    name,
                    enabled,
                    reportJobConfiguration
                }
            })
    }

    return useMutation({
        mutationFn: saveDailyReportEmails
    })
}