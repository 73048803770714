import { ElectionPushStatus } from 'models'
import { useTranslation } from 'react-i18next'

export const useElectionPushStatusMap = () => {
    const { t } = useTranslation()

    return {
        toString: (status: ElectionPushStatus): string => {
            switch (status) {
                case ElectionPushStatus.NOT_SENT:
                    return t('not_sent')
                case ElectionPushStatus.ACCEPTED:
                    return t('accepted')
                case ElectionPushStatus.PENDING:
                    return t('pending')
                case ElectionPushStatus.UNKNOWN:
                    return t('unknown')
                case ElectionPushStatus.REJECTED:
                case ElectionPushStatus.AZURE_ERROR:
                    return t('rejected')
            }
        }
    }
}