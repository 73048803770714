import { TourProps } from 'antd'
import TutorialHighlight from 'components/molecules/TutorialHighlight'
import { useConfirmPopup } from 'hooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSuggestToDownloadPdfInstruction, useTellingStationProcessInfo, useTourStepCreator } from '../../hooks'

const TIPS_KEY = 'includeVoterVerificationTips'
const SHOW_PDF_DOWNLOAD_SUGGESTION_KEY = 'showPdfDownloadSuggestion'


function VoterVerificationTutorial() {
    const { t } = useTranslation('telling-station-sandbox')
    const { info, setInfo } = useTellingStationProcessInfo()
    const { createTourStep } = useTourStepCreator()
    const { suggestToDownloadPdfInstruction } = useSuggestToDownloadPdfInstruction()
    const { confirmPortal, hide, showConfirm } = useConfirmPopup()

    useEffect(() => {
        const startTutorial = () => {
            const tutorialShown = sessionStorage.getItem(TIPS_KEY) === 'true'
            setInfo(current => ({ ...current, tutorialShown }))
        }

        if (sessionStorage.getItem(SHOW_PDF_DOWNLOAD_SUGGESTION_KEY) === 'true') {
            suggestToDownloadPdfInstruction(showConfirm, hide,
                () => {
                    startTutorial()
                    sessionStorage.removeItem(SHOW_PDF_DOWNLOAD_SUGGESTION_KEY)
                })
        } else {
            startTutorial()
        }


    }, [setInfo])

    const onClose = () => {
        setInfo(current => ({ ...current, tutorialShown: false }))
        sessionStorage.removeItem(TIPS_KEY)
    }

    const steps: TourProps['steps'] = [
        createTourStep({
            placement: 'leftTop',
            targetId: 'download-instruction-btn',
            title: t('training_instructions'),
            body: t('you_can_download_the_training_instructions_at_any_step'),
            onClose
        }),
        createTourStep({
            placement: 'right',
            targetId: 'voter-verification-name-and-id',
            title: t('eligible_voters'),
            body: t('verify_the_voters_from_the_outer_mailing_envelopes'),
            onClose
        }),
        createTourStep({
            placement: 'left',
            targetId: 'voter-verification-online',
            title: t('online_ballots'),
            body: t('eligible_voters_who_voted_using_online_ballots_in_OBS_will_be_marked_in_this_column'),
            onClose
        }),
        createTourStep({
            placement: 'left',
            targetId: 'voter-verification-in-person-absentee',
            title: t('in_person_n_absentee_voters'),
            body: t('check_the_box_for_paper_ballots_in_person_or_absentee'),
            onClose
        }),
        createTourStep({
            placement: 'left',
            targetId: 'voter-verification-verified-by',
            title: t('verification'),
            body: t('this_column_would_show_which_station_verified_the_voter'),
            onClose
        }),
        createTourStep({
            placement: 'leftTop',
            targetId: 'voter-verification-statistic',
            title: t('live_statistics'),
            body: t('live_statistics_show_consolidated_numbers_by_all_stations'),
            onClose
        }),
        createTourStep({
            placement: 'leftTop',
            targetId: 'wizard-next-step',
            title: t('next_step'),
            body: t('once_you_verified_all_voters_from_the_outer_envelopes_click_next'),
            onClose
        })
    ]

    return (
        <>
            {confirmPortal}
            <TutorialHighlight
                steps={steps}
                open={info.tutorialShown}
                disabledInteraction
            />
        </>
    )
}

export default VoterVerificationTutorial