import PopupTitle from 'components/atoms/PopupTitle'
import { PopupProps } from 'components/molecules/Popup'
import SelectElectionFlow from 'components/organisms/SelectElectionFlow'
import { queryClient } from 'config/query-client'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const useSelectTypeOfElectionFlow =
    (show: (props: PopupProps) => void, hide: () => void) => {
        const { t } = useTranslation('teller')
        const navigate = useNavigate()

        const invalidateQueries = async () => {
            await queryClient.invalidateQueries(['online-voting'])
            await queryClient.invalidateQueries(['breaking-tie-rounds'])
        }

        const showSelectElectionTypeFlowPopup = (electionId: string) => {
            hide()
            show({
                title: (
                    <PopupTitle>
                        <div>{t('physical_paper_ballots_exist')}</div>
                    </PopupTitle>
                ),
                children: <SelectElectionFlow
                    byPassFlowOption={{
                        title: t('no_we_dont_have_physical_ballots'),
                        subtitle: t('online_ballots_only'),
                        text: t('if_only_online_balloting_is_used_and_there_are_no_physical_ballots_revote_using_obs')
                    }}
                    standardFlowOption={{
                        title: t('yes_we_have_physical_ballots'),
                        subtitle: t('input_the_votes_received_by_other_methods'),
                        text: t('this_is_for_processing_physical_ballots_including_voter_verification_revote_using_obs')
                    }}
                    onClose={async () => {
                        hide()
                        await invalidateQueries()
                    }}
                    electionId={electionId}
                    onByPassSelect={async () => {
                        await queryClient.invalidateQueries(['election-summary'])
                        await queryClient.invalidateQueries(['candidates-elected'])
                        await queryClient.invalidateQueries(['election-results-candidates'])
                        await queryClient.invalidateQueries(['election-results-tie'])
                        await queryClient.invalidateQueries(['designated-tie-candidates'])
                        await invalidateQueries()
                    }}
                    onStandardSelect={() => { navigate(`/tie-physical-ballots/${electionId}`) }}
                />,
                width: '660px',
                footer: null,
                onCancel: async () => {
                    await invalidateQueries()
                }
            })
        }

        return {
            showSelectElectionTypeFlowPopup
        }
    }