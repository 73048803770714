import style from './index.module.scss'

type StatisticListProps = {
    text: string
    statistic?: number
}

function StatisticList({ text, statistic }: StatisticListProps) {

    return (
        <li className={style.customListItem}>
            {text}
            <span className={style.listValue}>{statistic}</span>
        </li>
    )
}

export default StatisticList