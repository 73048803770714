import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { UserDetails } from '../../models'

export const myDetailsQueryKey = 'my-details'

export const useMyDetails = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getMyDetails = async () => {
        const res = await axios<UserDetails>(moduleUrl + 'me')

        return res.data
    }

    return useQuery({
        queryKey: [myDetailsQueryKey],
        queryFn: getMyDetails,
        enabled,
        staleTime: 3 * (60 * 1000)
    })
}