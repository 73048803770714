import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'

type ClarifyMeetingStartTimeCommand = {
    electionId: string,
    time: string,
    timeZone: string,
    date: string
}

export function useClarifyMeetingStartTime() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function clarifyMeetingStartTime({ electionId, time, timeZone, date }: ClarifyMeetingStartTimeCommand) {

        return await axios(`${moduleUrl}elections/${electionId}/vote-counting/clarify-meeting-start-time`,
            {
                method: 'post',
                data: {
                    time,
                    timeZone,
                    date
                }
            })
    }

    return useMutation({
        mutationFn: clarifyMeetingStartTime
    })
}