import { useOfficersElections } from 'api'
import Preloader from 'components/molecules/Preloader'
import { useConfirmPopup, usePopup } from 'hooks'
import { ElectionStatusType } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRevotePosition, useVotingPositionActions } from '../../../../hooks'
import { ElectedPosition } from '../../../../models'

type ElectedPositionActionsProps = {
    electedPosition: ElectedPosition
}

function ElectedPositionActions({ electedPosition }: ElectedPositionActionsProps) {
    const { t } = useTranslation()
    const { show, hide, popupPortal } = usePopup()
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { startVoting } = useVotingPositionActions()
    const navigate = useNavigate()
    const { revotePosition, waitingForCreating } = useRevotePosition()

    const { data: elections } = useOfficersElections()

    const onStart = () => {
        startVoting({ show, hide, positionType: electedPosition.role })
    }

    const isAnyElectionInProgress = elections?.some(
        (e) => e.status === ElectionStatusType.IN_PROGRESS
    )

    const election = elections?.find(e => e.electedPosition === electedPosition.role)

    const viewResults = () => {
        navigate('/officers-election-session')
    }

    const onRevotePosition = () => {
        if (!!election)
            return

        if (!!electedPosition.id) {
            navigate('/election-results/' + electedPosition.id)
        } else {
            revotePosition({
                role: electedPosition.role,
                showConfirm,
                onSuccess: () => viewResults(),
                show,
                hide
            })
        }
    }

    const actionButton = useMemo(() => {
        switch (election?.status || electedPosition.status) {
            case ElectionStatusType.NOT_STARTED:
                return <button
                    disabled={isAnyElectionInProgress}
                    className="btn-main-primary-md"
                    onClick={onStart}>
                    {t('start')}
                </button>
            case ElectionStatusType.IN_PROGRESS:
            case ElectionStatusType.STOPED:
                return <button
                    className="btn-main-primary-md"
                    onClick={viewResults}>
                    {t('view_results')}
                </button>
            case ElectionStatusType.CONFIRMED:
            case ElectionStatusType.COMPLETED:
                return <button
                    disabled={isAnyElectionInProgress && !election}
                    onClick={() => onRevotePosition()}
                    className="btn-main-secondary-md">
                    {t('revote_position')}
                </button>
            default:
                return <div>Invalid Status</div>
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electedPosition.status, isAnyElectionInProgress, election])

    return (
        <>
            {waitingForCreating && <Preloader />}
            {popupPortal}
            {confirmPortal}
            {actionButton}
        </>
    )
}

export default ElectedPositionActions