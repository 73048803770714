import { useMutation} from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { NotificationName } from '../../model'

type SaveEmailRecipientsVariables = {
    emailRecipients: string[]
    notificationName: NotificationName
}
 
export function useSaveEmailRecipients() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Notification)

    async function saveEmailRecipients ({ emailRecipients, notificationName }: SaveEmailRecipientsVariables) {
        return await axios.post(
            `${moduleUrl}${notificationName}/as-email/configuration/set-up-additional-recipients`,
            emailRecipients
        )
    }

    return useMutation(
        {mutationFn: saveEmailRecipients})
}