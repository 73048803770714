import { BrowserZoomRatioContext } from 'context'
import { PropsWithChildren, useState } from 'react'

function BrowserZoomRatioProvider({ children }: PropsWithChildren) {
    const [browserZoomRatio, setBrowserZoomRatio] = useState(100)

    return (
        <BrowserZoomRatioContext.Provider value={{ browserZoomRatio, setBrowserZoomRatio }} >
            {children}
        </BrowserZoomRatioContext.Provider>
    )
}

export default BrowserZoomRatioProvider