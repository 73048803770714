import { ColumnsType } from 'antd/es/table'
import { useCompleteElectionWithPhysicalVotes, useElection } from 'api'
import Card from 'components/atoms/Card'
import IconWrapper from 'components/atoms/IconWrapper'
import BackToDashboardLine from 'components/molecules/BackToDashboardLine'
import CardHeader from 'components/molecules/CardHeader'
import ElectionBadge from 'components/molecules/ElectionBadge'
import Note from 'components/molecules/Note'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import Table from 'components/molecules/Table'
import { useBackToDashboardLine, useConfirmPopup, useLsaOfficerRoleMap, usePopup } from 'hooks'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { OfficersElection } from 'models'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePhysicalVotes } from '../../api'
import { useRevotePosition } from '../../hooks'
import style from './index.module.scss'

const MIN_VOTES_TO_WIN = 5

type Candidate = {
    id: string
    name: string
    votes: number
}

type ElectionResultsProps = {
    needToConfirm?: boolean,
    electionId: string
    candidates: Candidate[]
}

function ElectionResults({ needToConfirm, electionId, candidates }: ElectionResultsProps) {
    const { t } = useTranslation('officers-election')
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { show, hide, popupPortal } = usePopup()
    const { toString } = useLsaOfficerRoleMap()
    const { setBackToDashboardLine } = useBackToDashboardLine()
    const navigate = useNavigate()
    const { revotePosition } = useRevotePosition()

    const { data: election } = useElection(electionId || '', !!electionId)
    const { data: physicalVotes } = usePhysicalVotes(electionId, !!electionId)
    const { mutateAsync: completeElectionWithPhysicalVotes } = useCompleteElectionWithPhysicalVotes()

    const confirmPosition = async () => {
        showConfirm({
            okText: t('confirm_position'),
            onOk: async () => {
                if (physicalVotes) {
                    await completeElectionWithPhysicalVotes({
                        electionId,
                        candidateVotes: physicalVotes
                    })
                    navigate(`/election-results/${electionId}`, { replace: true })
                }
            },
            title: `${t('confirm')} ${officersElection
                ? toString(officersElection?.electedPosition)
                : ''}?`,
            text: <>
                <div>{t('you_are_about_to_confirm_the_position_of_name',
                    { name: officersElection ? toString(officersElection?.electedPosition) : '' })}
                </div>
                <br />
                <strong>
                    {t('the_results_of_the_officers_elections_in_OBS_need_to_be_reported')}
                </strong>
            </>
        })

    }


    const officersElection = election
        ? election as any as OfficersElection
        : undefined

    const onRevotePosition = () => {
        if (officersElection) {
            revotePosition({
                show,
                hide,
                showConfirm,
                electionToCancel: needToConfirm ? officersElection : undefined,
                role: officersElection?.electedPosition,
                onSuccess: () => {
                    navigate('/officers-election-session', { replace: true })
                }
            })
        }
    }

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine
                sticky={true}
                fixedWidth={false}
                onBackToDashboardBtnClick={() => { navigate('/', { replace: true }) }}
                backButtonTitle={t('back_to_dashboard')}
            />
        )

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const columns: ColumnsType<Candidate> = [
        {
            title: t('voters'),
            width: 504,
            render: (candidate: Candidate) => <div style={{ maxWidth: 464 }}>
                <OptionalTooltip
                    contenWrapperClassName="ellipsis">
                    {candidate.name}
                </OptionalTooltip>
            </div>
        },
        {
            title: t('votes'),
            width: 100,
            render: (candidate: Candidate) => candidate.votes
        }
    ]

    return (
        <div className={style.wrapper}>
            {confirmPortal}
            {popupPortal}
            <div className={style.cardWrapper}>
                <Card
                    noContentPadding
                    className={style.cardContainer}
                    title={
                        <CardHeader
                            title={t('election_results')}
                            subTitle={!!officersElection && t('for_the_position_of',
                                { positionName: toString(officersElection.electedPosition) })
                            }
                            additionalTitleContent={!!election && <ElectionBadge status={election.status} />}
                        />
                    }>
                    <div className={style.cardContentWrapper}>
                        <Note icon={'regular'} mode={'info'}>
                            {t('click_revote_position_to_open_the_voting_session_for_this_position_again')}
                        </Note>
                        <Table
                            obsSize="small"
                            obsHeaderSize="small"
                            headerDividerVisible={true}
                            dataSource={candidates}
                            columns={columns}
                            pagination={false}
                            noOuterBorder
                            rowClassName={(candidate: Candidate) => candidate.votes >= MIN_VOTES_TO_WIN
                                ? (needToConfirm ? style.highlight : style.winner)
                                : ''
                            }
                            rootClassName={`${style.candidatesTable}`}
                            rowKey="id" />
                        <div className={style.candidatesTableMobile}>
                            {
                                candidates?.map(candidate =>
                                    <div className={`${style.candidateInfo} ${candidate.votes >= MIN_VOTES_TO_WIN
                                        ? (needToConfirm ? style.highlight : style.winner)
                                        : ''
                                        }`} key={candidate.id}>

                                        <OptionalTooltip
                                            contenWrapperClassName="ellipsis">
                                            {candidate.name}
                                        </OptionalTooltip>
                                        <div className={style.votes}>
                                            {t('votes')}: {candidate.votes}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={style.actionsGroup}>
                            <button className={'btn-main-secondary'}
                                onClick={() => onRevotePosition()}>
                                {t('revote_position')}
                            </button>
                            {needToConfirm
                                ? <button className={'btn-main-primary'}
                                    onClick={() => confirmPosition()}>
                                    {t('confirm_position')}
                                </button>
                                : <button className={'btn-main-primary'}
                                    onClick={() => { navigate('/', { replace: true }) }}>
                                    <IconWrapper>
                                        <Chevron className="flip-horizontal" width="18" height="18" />
                                    </IconWrapper>
                                    {t('back_to_dashboard')}
                                </button>
                            }
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default ElectionResults