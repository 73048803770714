import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { JobConfiguration } from '../../model'

export const useJobConfiguration = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Report)

    const getjobConfiguration = async () => {
        const config = await axios<JobConfiguration>(moduleUrl +'lsa-election-statistics/job-configuration')

        return config.data
    }

    return useQuery({
        queryKey: ['job-configuration'],
        queryFn: getjobConfiguration,
        enabled
    })
}