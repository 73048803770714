import ElectionBadge from 'components/molecules/ElectionBadge'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { useLsaOfficerRoleMap } from 'hooks'
import { useTranslation } from 'react-i18next'
import { ElectedPosition as Position } from '../../../../models'
import style from './index.module.scss'

type ElectedPositionProps = {
    electedPosition: Position
}

function ElectedPosition({ electedPosition }: ElectedPositionProps) {
    const { toString } = useLsaOfficerRoleMap()
    const { t } = useTranslation()

    return (
        <div className={style.positionContainer}>
            <div className={style.electionBlock}>
                <ElectionBadge status={electedPosition.status} />
                <div className={style.positionTitle}>
                    {`${toString(electedPosition
                        .role)}${electedPosition.optional ? ' (' + t('optional') + ')' : ''}`}
                </div>
            </div>
            <div className={style.actionBlock}>
                {!!electedPosition.electedMember
                    ? <OptionalTooltip contenWrapperClassName={`ellipsis ${style.memberWrapper}`}>
                        <span className={style.electedMember}>{electedPosition.electedMember}</span>
                    </OptionalTooltip>
                    : t('officers-election:not_elected')
                }
            </div>
        </div>
    )
}

export default ElectedPosition