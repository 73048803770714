import PageHeader from 'components/atoms/PageHeader'
import { usePageHeader } from 'hooks'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import ElectedOfficers from '../../components/ElectedOfficers'
import PageHeaderActions from '../../components/PageHeaderActions'
import style from './index.module.scss'

const Dashboard = () => {
    const { setPageHeader } = usePageHeader()
    const { t } = useTranslation('officers-election')

    useEffect(() => {
        setPageHeader(
            <PageHeader
                greeting={t('officers_election_dashboard')}
                subgreeting={t('only_one_position_can_be_voted_on_at_a_time')}
                fixedWidth={false}
                minHeaderContentWidth={790}
                sticky={!isMobile}>
                <PageHeaderActions />
            </PageHeader>
        )

        return () => setPageHeader(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={style.wrapper}>
            <div className={style.contentWrapper}>
                <ElectedOfficers />
            </div>
        </div>
    )
}

export default Dashboard