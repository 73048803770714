import { PopupProps } from 'components/molecules/Popup'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ViewUser from '../components/ViewUser'

export const useViewUser = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const view = (show: (props: PopupProps) => void,
        hide: () => void) => {
        show({
            width: '660px',
            title: <div style={{ width: '100%', textAlign: 'center' }}>{t('view')}</div>,
            children: <ViewUser onCancel={hide} onSubmit={() => {
                navigate('/function', { replace: true })
                hide()
            }} />,
            footer: null
        })
    }

    return { view }
}