import { ColumnsType } from 'antd/es/table'
import Tooltip from 'components/atoms/Tooltip'
import Table from 'components/molecules/Table'
import { useAuth, usePopup } from 'hooks'
import { Ballot } from 'models'
import { useTranslation } from 'react-i18next'
import { dayjsToString } from 'utils'
import { useBallotInvalidationReasons, useVoteInvalidationReasons } from '../../api'
import { useBallotEntryViewPopupProps } from '../../hooks'
import { ballotVotesString, inactiveBallotCellClassName, validAndTotalVotesLabel } from '../../utils'
import style from './index.module.scss'

type BallotsTableProps = {
    ballots?: Ballot[],
    onBallotUpdate?: () => void,
    stickyOffset?: string,
    onEditBallotClick?: () => Promise<boolean>
    isStation?: boolean,
    canEditBallot?: boolean //TODO: remove this flag after fixing the issue with counters and votes

    onScrollToBottom?: () => void
}

function BallotsTable({
    onScrollToBottom,
    ballots,
    onBallotUpdate,
    stickyOffset,
    onEditBallotClick,
    isStation = true,
    canEditBallot = true
}: BallotsTableProps) {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const { popupPortal, show, hide } = usePopup()
    const { data: voteInvalidationReasons } = useVoteInvalidationReasons(auth?.electionId!)
    const { data: ballotInvalidationReasons }
        = useBallotInvalidationReasons(auth?.electionId!)
    const { getPopupProps } = useBallotEntryViewPopupProps()

    const openBallotEntryView = (ballot: Ballot) => {
        show(getPopupProps({
            electionId: auth!.electionId!,
            ballot,
            onEditBallotClick,
            hideBallotEntryView: hide,
            onReasonUpdate: () => {
                onBallotUpdate?.()
                hide()
            }
        }, style))
    }

    const columns: ColumnsType<Ballot> = [
        {
            title: t('ballot_id'),
            width: 109,
            render: (ballot: Ballot, _, index) => (
                <div
                    onClick={() => {
                        if (isStation && canEditBallot) {
                            openBallotEntryView(ballot)
                        }
                    }}
                    className={isStation && canEditBallot ? `${style.linkColor} custom-cell-color` : ''}
                >
                    {index === 0 && <div id="first-ballot-id" className={style.firstBallotId} />}
                    {isStation ? ballot.id : `OS-${ballot.id}`}
                </div>
            )
        },
        {
            title: t('valid'),
            width: 79,
            render: (ballot: Ballot) => (
                <Tooltip title={ballot.deleted ? t('ballot:deleted_ballot') : (ballot.invalidationReason
                    ? t('ballot:invalid_ballot') : t('ballot:valid_ballot'))}>
                    <div className={`${style.statusIndicator} ${ballot.deleted
                        ? style.deleted
                        : (ballot.invalidationReason
                            ? style.invalid
                            : style.valid)}`} />
                </Tooltip>
            )
        },
        {
            title: t('votes'),
            width: 689,
            render: (ballot: Ballot) => (
                <div className={inactiveBallotCellClassName(ballot)}
                    style={{ maxWidth: 689 }}>
                    <Tooltip
                        className={style.tooltip}
                        title={ballotVotesString(
                            t, ballot, true, voteInvalidationReasons ?? [],
                            ballotInvalidationReasons ?? [], isStation)}>
                        <div className="ellipsis">
                            {ballotVotesString(
                                t, ballot, false, voteInvalidationReasons ?? [],
                                ballotInvalidationReasons ?? [], isStation)}
                        </div>
                    </Tooltip>
                </div>
            )
        },
        {
            title: t('submitted'),
            width: 175,
            render: (ballot: Ballot) => (
                <div className={inactiveBallotCellClassName(ballot)}>
                    {isStation ? dayjsToString(ballot.submitTime, 'MM/DD/YYYY hh:mm A') || String.fromCharCode(8212) :
                        String.fromCharCode(8212)}
                </div>
            )
        },
        {
            title: t('submitted_by'),
            width: 115,
            render: (ballot: Ballot) => (
                <div className={inactiveBallotCellClassName(ballot)}>{
                    isStation ? t('station:station_number', { number: ballot.station.replace('S', '') }) :
                        t('teller:online')}
                </div>
            )
        },
        {
            title: t('valid_votes'),
            width: 99,
            render: (ballot: Ballot) => (
                <div className={inactiveBallotCellClassName(ballot)}>
                    {validAndTotalVotesLabel(ballot, isStation)}
                </div>
            )
        }
    ]

    return (
        <>
            {popupPortal}
            <Table
                onScrollToBottom={onScrollToBottom}
                obsSize="small"
                stickyOffset={stickyOffset}
                obsHeaderSize="small"
                headerDividerVisible
                dataSource={ballots}
                columns={columns}
                pagination={false}
                rootClassName={style.table}
                rowKey="id"
            />
        </>
    )
}

export default BallotsTable