import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export type VotersCount = {
    totalCount: number
}

export const useVotersCount = (id?: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.OnlineBallot)

    const getVotersCount = async () => {
        const res = await axios<VotersCount>(`${moduleUrl}elections/${id}/voters/count`)

        return res.data
    }

    return useQuery<VotersCount>({
        queryKey: ['online-ballot-voters-count', id],
        queryFn: getVotersCount,
        enabled
    })
}