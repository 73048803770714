import ErrorPage from 'components/organisms/ErrorAlert'
import RoleBasedLayout from 'components/organisms/RoleBasedLayout'
import { useAuth } from 'hooks'
import { ReactComponent as AccessDenied } from 'icons/access-denied.svg'
import { Role } from 'models'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

interface IRequireAuthProps {
    allowedRoles: Role[]
}

function RequireAuth({ allowedRoles }: IRequireAuthProps) {
    const { auth } = useAuth()
    const location = useLocation()
    const { t } = useTranslation()

    return (
        (auth?.role && allowedRoles.includes(auth.role))
            ? <Outlet />
            : auth?.name
                ? <RoleBasedLayout>
                    <ErrorPage
                        title={t('access_denied')}
                        image={<AccessDenied />}
                        text={
                            <div>
                                {t('you_do_not_have_permission_to_access')}<br />
                                {t('please_contact_support_for_more_information')}
                            </div>
                        }
                        footer={true}
                    />
                </RoleBasedLayout>
                : <Navigate to="/sign-in" state={{ from: location }} replace />
    )
}

export default RequireAuth