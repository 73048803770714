import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export const usePhysicalVotes = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getOfficersElection = async () => {
        const response = await axios<{ candidate: string, voteCount: number }[]>(moduleUrl
            + `draft/officers-elections/elections/${electionId}/election-results`)

        return response.data
    }

    return useQuery({
        queryKey: ['election-physical-votes', electionId],
        queryFn: getOfficersElection,
        enabled
    })
}