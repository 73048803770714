import { ElectionStatusType, ElectionType } from 'models'
import React from 'react'
import { DelegateElectionItem, ElectionItem, ElectionItemBase } from '../../models'
import DefaultTellersCard from '../DefaultTellersCard'
import DelegateTellersCard from '../DelegateTellersCard'

type TellersCardProps = {
    election: ElectionItemBase
    status: ElectionStatusType
}

const TellersCard: React.FC<TellersCardProps> = ({ election, status }) => (
    (election.type === ElectionType.LOCAL_DELEGATE_ELECTION)
        ? <DelegateTellersCard election={election as DelegateElectionItem} status={status} />
        : <DefaultTellersCard election={election as ElectionItem} status={status} />
)

export default TellersCard