import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { useTranslation } from 'react-i18next'
import { useOfficers } from '../../hooks'
import { ByElectionOfficer } from '../../models'
import style from './index.module.scss'

type LsaOfficerProps = {
    index: number
    officer: ByElectionOfficer
}

function LsaOfficer({ officer, index }: LsaOfficerProps) {
    const { t } = useTranslation('election')
    const { setOfficers } = useOfficers()

    return (
        <div className={style.officer}>
            <div className={style.index}>{index}</div>
            <div
                className={`${style.name} ${officer.name ? '' : style.empty} ${officer.noLongerServing
                    ? style.noServing
                    : ''}`}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {officer.name ?
                        `${officer.name} (${t('bid_id', { id: officer.id })})` : t('vacancy')}
                </OptionalTooltip>
            </div>
            <div className={`${style.changeServing} ${officer.noLongerServing ? style.noServing : ''}`}
                onClick={() => setOfficers(current => current.map(o => {
                    if (o.id === officer.id)
                        return { ...o, noLongerServing: !o.noLongerServing }

                    return { ...o }
                }))}>
                {officer.name
                    ? <>
                        {officer.noLongerServing
                            ? t('marked_as_no_longer_serving_click_to_undo')
                            : t('mark_as_no_longer_serving')
                        }
                    </>
                    : <>
                        {officer.noLongerServing
                            ? t('unvote_for_vacancy')
                            : t('vote_for_vacancy')
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default LsaOfficer