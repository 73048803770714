import { ColumnsType } from 'antd/es/table'
import { useCandidatesOnlineVotes, useOfficersElectionParticipants } from 'api'
import Card from 'components/atoms/Card'
import BackToDashboardLine from 'components/molecules/BackToDashboardLine'
import CardHeader from 'components/molecules/CardHeader'
import Note from 'components/molecules/Note'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import Table from 'components/molecules/Table'
import { useBackToDashboardLine, useConfirmPopup, useElectionStatusMap, useLsaOfficerRoleMap, usePopup } from 'hooks'
import { ReactComponent as MinusRounded } from 'icons/minus-rounded.svg'
import { ReactComponent as PlusRounded } from 'icons/plus-rounded.svg'
import { debounce } from 'lodash'
import { ElectionParticipant, OfficersElection } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePhysicalVotes, useUpdateCandidatePhysicalVotes } from '../../../../api'
import { useVotingPositionActions } from '../../../../hooks'
import style from './index.module.scss'

const DEBOUNCE_TIME_MS = 15 * 1000
const MIN_VOTES_TO_WIN = 5

type InputPhysicalBallotsProps = {
    election: OfficersElection
}

function InputPhysicalVotes({ election }: InputPhysicalBallotsProps) {
    const { t } = useTranslation('officers-election')
    const navigate = useNavigate()
    const { toString } = useLsaOfficerRoleMap()
    const { toString: statusToString } = useElectionStatusMap()
    const { completeVotingWithWinner, completeVotingWithoutWinner } = useVotingPositionActions()

    const [sortedParticipants, setSortedParticipants] = useState<ElectionParticipant[]>([])
    const [showTooManyVotesWarning, setShowTooManyVotersWarning] = useState(false)

    const { show, hide, popupPortal } = usePopup()
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { setBackToDashboardLine } = useBackToDashboardLine()
    const { data: onlineVotes } = useCandidatesOnlineVotes(election.id.toString())
    const { data: participants } = useOfficersElectionParticipants(election?.region?.locality || '',
        !!election?.region?.locality)
    const { data: physicalVotes } = usePhysicalVotes(election.id.toString())
    const { mutateAsync: updateCandidatePhysicalVotes } = useUpdateCandidatePhysicalVotes()

    useEffect(() => {
        if (participants?.length) {
            setSortedParticipants(participants.sort((a, b) => a.name.localeCompare(b.name) || a.id.localeCompare(b.id)))
        }
    }, [participants])

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine
                sticky={true}
                fixedWidth={false}
                onBackToDashboardBtnClick={() => { navigate('/') }}
                backButtonTitle={t('back_to_dashboard')}
            />
        )

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const totalOnlineVotes = useMemo(() => {
        if (!onlineVotes?.length) {
            return 0
        }

        return onlineVotes.reduce((sum, votes) => (sum + votes.voteCount), 0)
    },
        [onlineVotes])

    const totalPhysicalVotes = useMemo(() => {
        if (!physicalVotes?.length) {
            return 0
        }

        return physicalVotes.reduce((sum, participant) => (sum + participant.voteCount), 0)
    },
        [physicalVotes])

    const votersInfo = useMemo(() =>
    (
        <div className={style.votingInfo}>
            <div className={style.votingStatus}>{statusToString(election.status)}</div>
            <div className={style.votingStatistic}>
                {t('voted_x_of_y', { x: totalOnlineVotes + totalPhysicalVotes, y: sortedParticipants?.length })}
            </div>
        </div>
    )
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [sortedParticipants, totalOnlineVotes, totalPhysicalVotes])

    const getOnlineVotes = (id: string) => {
        if (!onlineVotes?.length) {
            return 0
        }

        const candidate = onlineVotes.filter(o => o.candidate === id)

        if (!candidate?.length) {
            return 0
        }

        return candidate[0].voteCount
    }

    const getPhysicalVotes =
        (participantId: string) => physicalVotes?.find(p => p.candidate === participantId)?.voteCount || 0

    const hideWarningByTimeout = useMemo(() =>
        debounce(() => {
            setShowTooManyVotersWarning(false)
        }, DEBOUNCE_TIME_MS),
        []
    )

    const updatePhysicalVotes = async (participantId: string, increase: boolean) => {
        if (increase && totalOnlineVotes + totalPhysicalVotes >= sortedParticipants.length) {
            setShowTooManyVotersWarning(true)
            hideWarningByTimeout()

            return
        } else {
            setShowTooManyVotersWarning(false)
        }

        const participantVotes = physicalVotes?.find(p => p.candidate === participantId)?.voteCount || 0

        await updateCandidatePhysicalVotes({
            electionId: election.id.toString(),
            candidateId: participantId,
            votes: increase
                ? participantVotes + 1
                : participantVotes - 1
        })
    }

    const columns: ColumnsType<ElectionParticipant> = [
        {
            title: t('recipient'),
            width: 404,
            render: (participant: ElectionParticipant) => <div style={{ maxWidth: 364 }}>
                <OptionalTooltip
                    contenWrapperClassName="ellipsis">
                    {participant.name}
                </OptionalTooltip>
            </div>
        },
        {
            title: t('online'),
            width: 80,
            render: (participant: ElectionParticipant) => getOnlineVotes(participant.id)
        },
        {
            title: t('other_methods'),
            width: 120,
            render: (participant: ElectionParticipant) => <div className={style.physicalVotesInput}>
                <div className={style.icon} onClick={() => updatePhysicalVotes(participant.id, true)}>
                    <PlusRounded />
                </div>
                <div className={style.physicalVotes}>{getPhysicalVotes(participant.id)}</div>
                <div className={`${style.icon} ${getPhysicalVotes(participant.id) === 0 ? style.disabled : ''}`}
                    onClick={() => updatePhysicalVotes(participant.id, false)}>
                    <MinusRounded />
                </div>
            </div>
        }
    ]

    const winnerExists = () => {
        if (participants?.length) {
            for (let i = 0; i < participants.length; i++) {
                const id = participants[i].id
                if (getOnlineVotes(id) + getPhysicalVotes(id) >= MIN_VOTES_TO_WIN) {
                    return true
                }
            }
        }

        return false
    }

    return (
        <div className={style.wrapper}>
            {confirmPortal}
            {popupPortal}
            <div className={style.cardWrapper}>
                <Card
                    noContentPadding={true}
                    className={style.cardContainer}
                    title={
                        <CardHeader
                            title={t('election_results')}
                            subTitle={t('for_the_position_of',
                                { positionName: toString(election.electedPosition) })}
                            additionalTitleContent={votersInfo}
                        />
                    }>
                    <div className={style.cardContentWrapper}>
                        <Note icon={'regular'} mode={'info'}>
                            {t('to_add_or_remove_an_absentee_vote_received_by_phone_text_email_etc')}
                        </Note>
                        {showTooManyVotesWarning
                            && <div className={style.warning}>
                                <Note icon={'filled'} mode={'error'}>
                                    {t('you_are_trying_to_add_more_votes_than_eligible_voters_in_the_system')}
                                </Note>
                            </div>
                        }
                        <Table
                            obsSize="small"
                            obsHeaderSize="small"
                            headerDividerVisible={true}
                            dataSource={sortedParticipants}
                            columns={columns}
                            noOuterBorder
                            pagination={false}
                            rootClassName={`${style.recipientsTable}`}
                            rowKey="id" />
                        <div className={style.recipientsTableMobile}>
                            {
                                participants?.map(participant =>
                                    <div className={style.participantInfo} key={participant.id}>

                                        <div className={style.nameAndOnline}>
                                            <OptionalTooltip
                                                contenWrapperClassName="ellipsis">
                                                {participant.name}
                                            </OptionalTooltip>
                                            <div className={style.online}>
                                                {t('online')}: {getOnlineVotes(participant.id)}
                                            </div>
                                        </div>

                                        <div className={style.physicalVotesInput}>
                                            <div className={style.icon}
                                                onClick={() => updatePhysicalVotes(participant.id, true)}>
                                                <PlusRounded />
                                            </div>
                                            <div className={style.physicalVotes}>
                                                {getPhysicalVotes(participant.id)}
                                            </div>
                                            <div
                                                className={`${style.icon} ${getPhysicalVotes(participant.id) === 0
                                                    ? style.disabled
                                                    : ''}`}
                                                onClick={() => updatePhysicalVotes(participant.id, false)}>
                                                <MinusRounded />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={style.actionsGroup}>
                            <button className={'btn-main-primary'}
                                onClick={() => {
                                    if (winnerExists()) {
                                        completeVotingWithWinner(showConfirm, election.id.toString())
                                    } else {
                                        completeVotingWithoutWinner({
                                            showConfirm,
                                            election,
                                            show,
                                            hide,
                                            role: election.electedPosition
                                        })
                                    }
                                }}
                            >
                                {t('submit_voting_entry')}
                            </button>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default InputPhysicalVotes