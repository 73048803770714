import { CloudWatchLogsClient, PutLogEventsCommand } from '@aws-sdk/client-cloudwatch-logs'
import { Spin } from 'antd'
import GeneralPreloader from 'components/templates/GeneralPreloader'
import { Crisp } from 'crisp-sdk-web'
import dayjs from 'dayjs'
import { tellingStationSandbox } from 'features/tellingStationSandbox'
import { useBrowserZoomRatio } from 'hooks'
import { ReactComponent as PreloaderIcon } from 'icons/preloader.svg'
import { useEffect, useMemo } from 'react'
import ReactGA from 'react-ga4'
import { RouterProvider } from 'react-router-dom'
import { isSandbox } from 'utils'
import { router } from './routing/routing'

const CRISP_WEBSITE_ID = '609f5bf2-8f64-4761-b3b3-338336ed7fcd'
export default function App() {
    const { setBrowserZoomRatio } = useBrowserZoomRatio()

    useEffect(() => {
        const region = (window as any).__RUNTIME_CONFIG__?.AWS_DEFAULT_REGION || 'us-east-2'
        const accessKeyId = (window as any).__RUNTIME_CONFIG__?.AWS_ACCESS_KEY_ID
        const secretAccessKey = (window as any).__RUNTIME_CONFIG__?.AWS_SECRET_ACCESS_KEY
        const logGroupName = (window as any).__RUNTIME_CONFIG__?.AWS_LOGS_GROUP
        const logStreamName = (window as any).__RUNTIME_CONFIG__?.AWS_LOGS_STREAM

        const client = new CloudWatchLogsClient({
            region,
            credentials: {
                secretAccessKey,
                accessKeyId
            }
        })

        const errorListener = async (error: ErrorEvent) => {
            if (accessKeyId && secretAccessKey && logGroupName && logStreamName) {
                const command = new PutLogEventsCommand({
                    logEvents: [
                        {
                            message: JSON.stringify({
                                message: error.message,
                                source: error.filename,
                                lineNumber: error.lineno,
                                columnNumber: error.colno,
                                error
                            }),
                            timestamp: dayjs().unix() * 1000
                        }
                    ],
                    logGroupName,
                    logStreamName
                })
                try {
                    await client.send(command)
                } finally {
                    window.location.href = '/oops'

                    return true
                }
            } else {
                window.location.href = '/oops'

                return true
            }
        }

        window.addEventListener('error', errorListener)

        return () => {
            client.destroy()
            window.removeEventListener('error', errorListener)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => sessionStorage.removeItem('RefreshTokenInerceptorId'), [])

    useEffect(()=> {
        if (isSandbox && process.env.NODE_ENV !== 'development') {
            ReactGA.initialize('G-JNP6ZP0DHL')
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
        }
    }, [])

    const onWindowResize = useMemo(() => () => {
        const supportedWidth = 1440
        const innerWidth = window.innerWidth
        const ratio = supportedWidth / innerWidth
        setBrowserZoomRatio(Math.round(ratio * 100) / 100)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onWindowResize)
        onWindowResize()

        return () => {
            window.removeEventListener('resize', onWindowResize)
        }
    }, [])

    Spin.setDefaultIndicator(<PreloaderIcon className="obs-spin" />)
    Crisp.configure(CRISP_WEBSITE_ID)

    return <>
        <GeneralPreloader />
        <RouterProvider router={isSandbox ? tellingStationSandbox.tellingStationSandboxRouter : router} />
    </>
}
