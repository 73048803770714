import { ElectionResult } from 'models'
import { PropsWithChildren, useState } from 'react'
import { ConfirmationContext } from '../context/ConfirmationContext'

function ConfirmationProvider({ children }: PropsWithChildren) {
    const [candidatesToConfirm, setCandidatesToConfirm] = useState<ElectionResult[]>([])

    return (
        <ConfirmationContext.Provider value={{ candidatesToConfirm, setCandidatesToConfirm }}>
            {children}
        </ConfirmationContext.Provider >
    )
}

export default ConfirmationProvider