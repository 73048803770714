import { ReactNode, forwardRef } from 'react'
import styles from './card.module.scss'

export type CardProps = {
    title?: ReactNode,
    noContentPadding?: boolean,
    noHeaderLine?: boolean,
    contentClassName?: string,
    className?: string,
    tabIndex?: number
    children?: ReactNode
    highlighted?: boolean
    highlightedWithOrange?: boolean
    id?: string
}

const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
    const { title, noContentPadding, noHeaderLine, children, contentClassName,
        highlighted, highlightedWithOrange,
        className } = props

    return (
        <div className={`${styles.card} ${className || ''} ${highlighted ? styles.highlighted : ''}
        ${highlightedWithOrange ? styles.highlightedWithOrange : ''}`}
            id={props.id}
        >
            <div ref={ref}>{title}</div>
            <div className={`${noHeaderLine ? '' : styles.headerLine} 
            ${highlighted ? styles.highlightedBackground : ''}`} />
            <div className={`${contentClassName || ''} ${noContentPadding ? '' : styles.cardContent}`}>{children}</div>
        </div>
    )
})

export default Card