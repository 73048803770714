import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionParticipant, ElectionType } from 'models'
import { getUrlParamsFromFilter, transformSortable } from 'utils'
import { ParticipantFilter } from '../../../../models'
import { getElectionTypeApiModule } from '../../utils'

export const useParticipants = (electionPeriodId: string,
    type: ElectionType,
    filter?: ParticipantFilter,
    enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getParticipants = async ({ queryKey }: QueryFunctionContext)
        : Promise<ElectionParticipant[]> => {
        const [, electionPeriodId, type, filter] = queryKey

        const queryParams = getUrlParamsFromFilter(transformSortable(filter as ParticipantFilter))
        const result = await axios<ElectionParticipant[]>(
            `${moduleUrl}ridvan-election-periods/${electionPeriodId}/` +
            `${getElectionTypeApiModule(type as ElectionType)}/participants${queryParams ? `?${queryParams}` : ''}`)

        return result.data
    }

    return useQuery({
        queryKey: ['election-period-participant', electionPeriodId, type, filter],
        queryFn: getParticipants,
        enabled
    })
}