import {useQueries} from '@tanstack/react-query'
import {AppointedTellersCounts} from '../../models'
import {useAppointedTellersCountsQueryParams} from './appointedTellersCounts'

export const useAppointedTellersCountsChunk = (electionIdArray: number[]) => {
    const { appointedTellersCountsQueryParams } = useAppointedTellersCountsQueryParams()
    const useAppointedTellersCountsQueries = electionIdArray.map(electionId =>
        appointedTellersCountsQueryParams(electionId)
    ) || []
    const appointedTellersCountsQueryResults = useQueries({ queries: useAppointedTellersCountsQueries })
    const appointedTellersCountsData = appointedTellersCountsQueryResults
        .map(result => result.data).filter(Boolean) as AppointedTellersCounts[]

    if(appointedTellersCountsData.length === electionIdArray.length) {
        return appointedTellersCountsData
    }

    return []
}
