export enum FontStyle {
    Small = 'Small',
    Caption = 'Caption',
    Medium = 'Medium',
    Body1 = 'Body1',
    Large = 'Large',
    Subheadline1 = 'Subheadline1',
    Headline = 'Headline',
    Title3 = 'Title3',
    Title2 = 'Title2',
    Title1 = 'Title1'
}