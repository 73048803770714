import { useAllOption } from './useAllOption'

export function useFilterable<T>() {
    const allOption = useAllOption()

    const changeFilter = (filters: T, field: keyof T, value: any) => {
        const result: T = { ...filters }

        result[field] = value

        return result
    }

    const prepareFiltersForRequest = (filters: T) => {
        const requestFilters: T = { ...filters }

        for (var field in requestFilters) {
            if (Object.prototype.hasOwnProperty.call(requestFilters, field)) {
                const value = requestFilters[field]
                if (value &&
                    (value === allOption.value
                        || (value as any).includes?.(allOption.value)
                        || (value as any).length === 0)) {
                    requestFilters[field] = undefined as any
                }
            }
        }

        return requestFilters
    }

    return { changeFilter, prepareFiltersForRequest }
}