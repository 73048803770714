import LsaElections from './components/LsaElections'
import { useLsaElectionsData } from './hooks'
import LsaElectionsProvider from './provider/LsaElectionsProvider'

export const lsaElections = {
    components: {
        LsaElections
    },
    hooks: {
        useLsaElectionsData
    },
    providers: {
        LsaElectionsProvider
    }
}