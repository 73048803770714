import PopupTitle from 'components/atoms/PopupTitle'
import { ConfirmPopupSettings } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useDownloadPdfInstruction } from './useDownloadPdfInstruction'

export const useSuggestToDownloadPdfInstruction = () => {
    const { t } = useTranslation('telling-station-sandbox')
    const { downloadPdfInstruction } = useDownloadPdfInstruction()

    const suggestToDownloadPdfInstruction = (showConfirm: (settings: ConfirmPopupSettings) => void,
        hide: () => void,
        onClose?: () => void) => {
        showConfirm({
            title: <PopupTitle>{t('training_instructions')}?</PopupTitle >,
            text: t('you_can_use_autogenerated_instructions'),
            cancelText: t('skip_for_now'),
            onCancel: async () => {
                onClose?.()
                hide()
            },
            okText: t('download_pdf'),
            onOk: async () => {
                downloadPdfInstruction()
                onClose?.()
                hide()
            }
        })
    }

    return { suggestToDownloadPdfInstruction }
}