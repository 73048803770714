import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export const useIsCastingBallotInPersonAvailable = (electionId: number | undefined, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.OnlineBallot)

    const getIsCastingBallotInPersonAvailable = async () => {
        const res =
            await axios<boolean>(`${moduleUrl}elections/${electionId}/casting-ballot-in-person-available`)

        return res.data
    }

    return useQuery({
        queryKey: ['casting-ballot-in-person-available'],
        queryFn: getIsCastingBallotInPersonAvailable,
        enabled
    })
}