import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { VoteSummary } from '../../../../../../models/telling-station/vote-summary'

export const useVoteSummary = (electionId: string, stationId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getVoteSummary = async ({ queryKey }: QueryFunctionContext)
        : Promise<any> => {
        const [, electionId, stationId] = queryKey

        const res = await axios<VoteSummary>(`${moduleUrl}elections/${electionId}/stations/${stationId}/votes/count`)

        return res.data
    }

    return useQuery<VoteSummary>({
        queryKey: ['voters', stationId, electionId],
        queryFn: getVoteSummary,
        enabled
    })
}