import { ElectionStatusType, ElectionType } from 'models'
import React from 'react'
import { DelegateElectionItem, ElectionItem, ElectionItemBase } from '../../models'
import DefaultElectionProcessCard from '../DefaultElectionProcessCard'
import DelegateElectionProcessCard from '../DelegateElectionProcessCard'

type ElectionProcessCardProps = {
    election: ElectionItemBase
    status: ElectionStatusType
}

const ElectionProcessCard: React.FC<ElectionProcessCardProps> = ({ election, status }) => (
    (election.type === ElectionType.LOCAL_DELEGATE_ELECTION)
        ? <DelegateElectionProcessCard election={election as DelegateElectionItem} status={status} />
        : <DefaultElectionProcessCard election={election as ElectionItem} status={status} />
)

export default ElectionProcessCard