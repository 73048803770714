import { useQueryClient } from '@tanstack/react-query'
import { Radio } from 'antd'
import { useElection } from 'api'
import { useSelectBypassFlow, useSelectStandardFlow } from 'api/mutations'
import PopupRadioButton from 'components/atoms/PopupRadioButton'
import PopupButtons from 'components/molecules/PopupButtons'
import Preloader from 'components/molecules/Preloader'
import { ReactComponent as Checkmark } from 'icons/checkmark.svg'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type ElectionFlowOption = {
    title: string
    subtitle: string
    text: string
}

type SelectElectionFlowProps = {
    onClose?: () => void
    onByPassSelect?: () => void
    onStandardSelect?: () => void
    electionId: string
    byPassFlowOption: ElectionFlowOption
    standardFlowOption: ElectionFlowOption
}

function SelectElectionFlow({
    onClose,
    onByPassSelect,
    onStandardSelect,
    electionId,
    byPassFlowOption,
    standardFlowOption
}: SelectElectionFlowProps) {
    const { t } = useTranslation('teller')
    const [selected, setSelected] = useState()
    const [waitingForByPassSelecting, setWaitingForByPassSelecting] = useState(false)
    const [waitingForStandardSelecting, setWaitingForStandardSelecting] = useState(false)
    const { mutateAsync: selectBypassFlow } = useSelectBypassFlow()
    const { mutateAsync: selectStandardFlow } = useSelectStandardFlow()
    const { data: election } = useElection(electionId!)
    const queryClient = useQueryClient()

    const proceed = async () => {
        if (selected === true) {
            await selectBypassFlow(electionId)
            setWaitingForByPassSelecting(true)
        } else if (selected === false) {
            await selectStandardFlow(electionId)
            setWaitingForStandardSelecting(true)
        }
    }

    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval> | undefined

        if (waitingForByPassSelecting) {
            intervalId = setInterval(async () => {
                await queryClient.invalidateQueries(['election', electionId])
                await queryClient.invalidateQueries(['breaking-tie-rounds', electionId])
                if (election?.bypassFlow === true) {
                    clearInterval(intervalId)
                    setWaitingForByPassSelecting(false)
                    onByPassSelect?.()
                    onClose?.()
                }
            }, 1000)
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitingForByPassSelecting, election])

    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval> | undefined

        if (waitingForStandardSelecting) {
            intervalId = setInterval(async () => {
                await queryClient.invalidateQueries(['election', electionId])
                await queryClient.invalidateQueries(['breaking-tie-rounds', electionId])

                if (election?.bypassFlow === false) {
                    clearInterval(intervalId)
                    setWaitingForStandardSelecting(false)
                    onStandardSelect?.()
                }
            }, 1000)
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitingForStandardSelecting, election])

    const handleCancel = () => {
        onClose?.()
    }

    return (
        <>
            {(waitingForStandardSelecting || waitingForByPassSelecting) && <Preloader />}
            <Radio.Group
                className={style.contentBlock}
                onChange={(e) => setSelected(e.target.value)}
            >
                <PopupRadioButton
                    value={true}
                    buttonIcon={<Dismiss className={`${style.dismissIcon} custom-color`} width={16} height={16} />}
                    labelTitle={byPassFlowOption.title}
                    labelSubTitle={byPassFlowOption.subtitle}
                    labelText={byPassFlowOption.text}
                />
                <PopupRadioButton
                    value={false}
                    buttonIcon={<Checkmark className={`${style.checkmarkIcon} custom-color`} />}
                    labelTitle={standardFlowOption.title}
                    labelSubTitle={standardFlowOption.subtitle}
                    labelText={standardFlowOption.text}
                />
            </Radio.Group>
            <PopupButtons
                cancelButtonName={t('common:cancel')}
                proccedButtonName={t('proceed_with_selected_option')}
                onCancel={handleCancel}
                onProceed={proceed}
            />
        </>
    )
}

export default SelectElectionFlow