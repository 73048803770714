import { useQuery } from '@tanstack/react-query'
import { ApiModule } from 'api/api-module'
import { useAxios } from 'api/hooks'
import { getModuleUrl } from 'api/utils'
import { Locality } from 'models'

export const useLocality = (id: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getLocality = async (id: string) => {
        const res = await axios<Locality>(`${moduleUrl}institutions/localities/${id}`)

        return res.data
    }

    return useQuery({
        queryKey: ['locality', id],
        queryFn: () => getLocality(id),
        enabled,
        staleTime: 3 * (60 * 1000)
    })
}