import { Radio as AntRadio, Space } from 'antd'
import { useElectionStations } from 'api'
import Checkbox from 'components/atoms/Checkbox'
import PopupFooter from 'components/atoms/PopupFooter'
import Radio from 'components/atoms/Radio'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { StationResetMode } from '../../models'
import style from './index.module.scss'

type ChooseStationsAndModeProps = {
    hide: () => void
    reset: (mode: StationResetMode, stations: string[]) => void
}

function ChooseStationsAndMode({
    hide,
    reset
}: ChooseStationsAndModeProps) {
    const { t } = useTranslation('teller')
    const [mode, setMode] = useState(StationResetMode.PartialReset)
    const [stationsToReset, setStationsToReset] = useState<string[]>([])
    const { electionId } = useParams()

    const { data: stations } = useElectionStations(electionId || '', !!electionId)

    const onReset = () => reset(mode, stationsToReset)

    const onStationChange = (id: string) => {
        if (stationsToReset.includes(id)) {
            setStationsToReset(current => current.filter(s => s !== id))
        } else {
            setStationsToReset(current => current.concat([id]))
        }
    }

    return (
        <>
            <div className={style.chooseStationAndMode}>
                <AntRadio.Group
                    onChange={(e) => setMode(e.target.value)}
                    value={mode}
                >
                    <Space direction="vertical">
                        <Radio value={StationResetMode.PartialReset} >
                            <div className={`${style.mode} ${mode === StationResetMode.PartialReset
                                ? style.selected
                                : ''}`}>
                                {t('partial_reset_ballot_entry_only')}
                            </div>
                        </Radio>
                        <Radio value={StationResetMode.FullReset}  >
                            <div className={`${style.mode} ${mode === StationResetMode.FullReset
                                ? style.selected
                                : ''}`}>
                                {t('full_reset_all_entries')}
                            </div>
                        </Radio>
                    </Space>
                </AntRadio.Group>
                <div className={style.stationsLabel}>{t('select_the_station_s_you_wish_to_reset')}</div>
                <div className={style.stations}>
                    {!!stations && stations.map(station => <Checkbox
                        key={station.id}
                        value={station.id}
                        onChange={(e) => onStationChange(e.target.value)}
                    >
                        <div className={`${style.station} ${stationsToReset.includes(station.id)
                            ? style.selected
                            : ''}`}>
                            {t('station_n', { n: station.id.replace('S', '') })}
                        </div>
                    </Checkbox>)}
                </div>
            </div>
            <PopupFooter>
                <button
                    onClick={hide}
                    className={'btn-main-secondary'}>
                    {t('cancel')}
                </button>
                <button
                    type="submit"
                    disabled={!stationsToReset.length}
                    onClick={onReset}
                    className={'btn-main-primary'}>
                    {t('reset')}
                </button>
            </PopupFooter>
        </>
    )
}

export default ChooseStationsAndMode