import { Input as AntInput } from 'antd'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import React, { forwardRef } from 'react'

type InputProps = Omit<React.ComponentProps<typeof AntInput>, 'allowClear'> & {
    allowClear?: boolean
}

const Input = forwardRef<any, InputProps>((props, ref) =>
    <AntInput {...props}
        ref={ref}
        className={`obs ${props.className || ''}`}
        allowClear={props.allowClear
            ? { clearIcon: <Dismiss className="rotate-90" width="12" height="12" /> }
            : false}>
        {props.children}
    </AntInput>
)

export default Input