import { useQueryClient } from '@tanstack/react-query'
import Card from 'components/atoms/Card'
import StatisticCounter from 'components/molecules/StatisticCounter'
import { ballotingAssets } from 'features/ballotingAssets'
import { tellingStation } from 'features/tellingStation'
import { useAuth, useConfirmPopup } from 'hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBallots } from '../../../../api'
import { useEnterBallotsInfo } from '../../hooks'
import style from './index.module.scss'

type EnteredBallotsProps = {
    enteredBallotsTableOffset?: string
}

function EnteredBallots({ enteredBallotsTableOffset }: EnteredBallotsProps) {
    const { t } = useTranslation('ballot')
    const { auth } = useAuth()
    const { data: ballots } = useBallots()
    const { info, setInfo } = useEnterBallotsInfo()
    const { info: tsInfo, setInfo: setTsInfo } = tellingStation.hooks.useTellingStationInfo()
    const queryClient = useQueryClient()
    const { confirmPortal, showConfirm } = useConfirmPopup()

    const [validVotes, setValidVotes] = useState(0)
    const [invalidVotes, setInvalidVotes] = useState(0)
    const [validBallots, setValidBallots] = useState(0)
    const [invalidBallots, setInvalidBallots] = useState(0)

    useEffect(() => {
        setInfo({ ...info, ballots })
        setTsInfo({ ...tsInfo, hasEnteredBallots: !!ballots?.length })
        setInvalidBallots(() => 0)
        setValidBallots(() => 0)
        setInvalidVotes(() => 0)
        setValidVotes(() => 0)
        ballots?.forEach(b => {
            if (!b.deleted) {
                if (b.invalidationReason) {
                    setInvalidBallots((current) => current + 1)
                } else {
                    setValidBallots((current) => current + 1)
                    b.votes?.forEach(v => {
                        if (!v.valid || v.invalidationReason) {
                            setInvalidVotes((current) => current + 1)
                        } else {
                            setValidVotes((current) => current + 1)
                        }
                    })
                }
            }
        })
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ballots])

    const onEditBallotClick = () => {
        const promise = new Promise<boolean>((resolve) => {
            if (tsInfo.hasNotSubmittedBallot) {
                showConfirm({
                    onOk: async () => { resolve(true) },
                    onCancel: async () => { resolve(false) },
                    okText: t('proceed_to_editing'),
                    title: t('not_submitted_ballot'),
                    text: <div>
                        {t('ballot_is_not_submitted')}
                        <div style={{ paddingTop: 'var(--default-space' }}>
                            <b>{t('ballot_id_contains_unsubmitted_entry',
                                { id: `${auth?.stationId}-${(info.ballots?.length || 0) + 1}` })}</b><br />
                            <b>{t('would_you_like_to_proceed_without_submitting_the_ballot')}</b>
                        </div>
                    </div>
                })
            } else {
                resolve(true)
            }
        })

        return promise
    }

    return ballots?.length
        ? <Card noHeaderLine noContentPadding
            title={<div className={style.header} >
                <div id="entered-ballots-highlight" className={style.enteredBallotsHighlight} />
                <div className={style.titles}>
                    <div className={style.title}>
                        {t('ballot_entry')}
                    </div>
                    <div className={style.subtitle}>
                        {t('current_station')}
                    </div>
                </div>
                <div className={style.statistic}>

                    <StatisticCounter value={validBallots}
                        title={t('valid_ballots')} />
                    <StatisticCounter isInvalid={!!invalidBallots} value={invalidBallots}
                        title={t('invalid_ballots')} />
                    <StatisticCounter value={validVotes} title={t('valid_votes')} />
                    <StatisticCounter isInvalid={!!invalidVotes} value={invalidVotes} title={t('invalid_votes')} />
                </div>
            </div>}

        >
            {confirmPortal}
            <ballotingAssets.components.BallotsTable
                onEditBallotClick={onEditBallotClick}
                ballots={ballots || []}
                stickyOffset={enteredBallotsTableOffset || '111px'}
                onBallotUpdate={() => { queryClient.invalidateQueries(['my-station-ballots']) }} />
        </Card>
        : <></>
}

export default EnteredBallots