import { Role } from 'models'
import { useTranslation } from 'react-i18next'

export const useRoleMap = () => {
    const { t } = useTranslation()

    return {
        toString: (role: Role): string => {
            switch (role) {
                case Role.SYSTEM_ADMIN:
                    return t('system_admin')
                case Role.TELLING_STATION:
                    return t('telling_station')
                case Role.CHIEF_TELLER:
                    return t('chief_teller')
                case Role.CHIEF_TELLER_ASSISTANT:
                    return t('assistant_chief_teller')
                case Role.LSA_OFFICER:
                    return t('lsa_officer')
                case Role.LSA_TELLER:
                    return t('lsa_teller')
                case Role.VOTER:
                    return t('online_voter')
                case Role.NSA_ADMIN:
                    return t('nsa_admin')
            }
        }
    }

}