import { PropsWithChildren, useState } from 'react'
import { LsaTellersEditingContext } from '../context'

function LsaTellersEditingProvider({ children }: PropsWithChildren) {
    const [isEditing, setIsEditing] = useState<boolean>(false)

    return (
        <LsaTellersEditingContext.Provider value={{ isEditing, setIsEditing }} >
            {children}
        </LsaTellersEditingContext.Provider>
    )
}

export default LsaTellersEditingProvider