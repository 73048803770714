import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { BallotsSummary } from 'models'

type StationBallotsSummary = {
    electionId: string,
    stationId: string
}

export const useStationBallotsSummary = ({ electionId, stationId }: StationBallotsSummary, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getStationBallotsSummary = async (): Promise<BallotsSummary> => {
        const response = await axios<BallotsSummary>(moduleUrl +
            `elections/${electionId}/stations/${stationId}/ballots/count`)

        return response.data
    }

    return useQuery({
        queryKey: ['ballots-count-of-station', stationId, 'of-election', electionId],
        queryFn: getStationBallotsSummary,
        enabled
    })
}