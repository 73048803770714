import { Dispatch, SetStateAction, createContext } from 'react'
import { EligibleIndividualsTableData } from '../models'

export type EligibleIndividualsTableDataContextProps = {
    tableData: EligibleIndividualsTableData,
    setTableData: Dispatch<SetStateAction<EligibleIndividualsTableData>>
}

export const EligibleIndividualsTableDataContext =
    createContext<EligibleIndividualsTableDataContextProps>({} as EligibleIndividualsTableDataContextProps)
