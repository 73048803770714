import { Col, Form, FormInstance, Row } from 'antd'
import Checkbox from 'components/atoms/Checkbox'
import Input from 'components/atoms/Input'
import RichTextEditor from 'components/molecules/RichTextEditor'
import { useTranslation } from 'react-i18next'
import { richTextEditorEmptyValue } from 'utils'
import { useElectionConfigData } from '../../hooks/useElectionConfigData'
import style from './index.module.scss'

const MAX_TITLE_LENGTH = 100
const MAX_BUTTON_TITLE_LENGTH = 30

type ElectionWordingSettingsProps = {
    form: FormInstance
    bodyInitValue?: string
}

function InPersonPopupWordingSettings({ form, bodyInitValue }: ElectionWordingSettingsProps) {
    const { t } = useTranslation('configuration')
    const { setElectionConfigData } = useElectionConfigData()

    return (
        <>
            <Row gutter={16}>
                <Col span={6}>
                    <Form.Item
                        name={'onlineVotingInPersonPromptEnabled'}
                        className="no-padding"
                        valuePropName="checked"
                    >
                        <Checkbox className={style.checkbox}
                        >
                            <div className={style.checkboxTitle}>{t('enable_disable_prompt')}</div>
                            {<div className={style.checkboxSubtitle}>
                                {t('even_if_the_checkbox_enabled_but_dates_are_not_in_range_the_prompt_will_not_show')}
                            </div>}
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item
                        name={'votingInPersonTitle'}
                        className={`no-padding ${style.wideInput}`}
                        label={t('title')}
                        rules={[{
                            required: true,
                            message: t('please_enter_the_input', { input: t('title') })
                        }]}
                    >
                        <Input
                            placeholder={t('please_enter_the_input', { input: t('title') })}
                            maxLength={MAX_TITLE_LENGTH}
                        />
                    </Form.Item>
                </Col>
            </Row >
            <Row gutter={16} className={style.alignBottom}>
                <Col span={6}>
                    <Form.Item
                        name={'votingInPersonYes'}
                        className={`no-padding ${style.input}`}
                        label={t('yes_button_name')}
                        rules={[{
                            required: true,
                            message: t('please_enter_the_input', {
                                input: t('yes_button_name'),
                                interpolation: { escapeValue: false }
                            })
                        }]}
                    >
                        <Input
                            placeholder={t('please_enter_the_input', {
                                input: t('yes_button_name'),
                                interpolation: { escapeValue: false }
                            })}
                            maxLength={MAX_BUTTON_TITLE_LENGTH}
                        />
                    </Form.Item>
                    <Form.Item
                        name={'votingInPersonNo'}
                        className={`no-padding ${style.input}`}
                        label={t('no_button_name')}
                        rules={[{
                            required: true,
                            message: t('please_enter_the_input', {
                                input: t('no_button_name'),
                                interpolation: { escapeValue: false }
                            })
                        }]}
                    >
                        <Input
                            placeholder={t('please_enter_the_input', {
                                input: t('no_button_name'),
                                interpolation: { escapeValue: false }
                            })}
                            maxLength={MAX_BUTTON_TITLE_LENGTH}
                        />
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <div className={style.inputAndLabel}>
                        <Form.Item
                            name={'votingInPersonBody'}
                            className={`no-padding ${style.wideInput}`}
                            label={t('body')}
                            rules={[{
                                required: true,
                                validator: () => {
                                    if (form.getFieldValue('votingInPersonBody') === richTextEditorEmptyValue)
                                        return Promise.reject()

                                    return Promise.resolve()
                                },
                                message: t('please_enter_the_input', { input: t('body') })
                            }]}
                        >
                            <RichTextEditor width={817} height={90}
                                placeholder={t('please_enter_the_input', { input: t('body') })}
                                errorMessage={t('please_enter_the_input', { input: t('body') })}
                                form={form}
                                fieldName="votingInPersonBody"
                                initValue={bodyInitValue}
                                onChange={() => {
                                    setElectionConfigData(current => ({ ...current, isFormTouched: true }))
                                }}
                            />
                        </Form.Item>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default InPersonPopupWordingSettings