import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionPushResult } from 'models'

export const useLocalDelegateElectionPushResult = (electionPeriodId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getLocalDelegateElectionPushResult = async (electionPeriodId: string) => {
        const result =
            await axios<ElectionPushResult>(
                `${moduleUrl}ridvan-election-periods/${electionPeriodId}/local-delegate-elections/push`)

        return result.data
    }

    return useQuery({
        queryKey: ['election-period-push-result', electionPeriodId],
        queryFn: () => getLocalDelegateElectionPushResult(electionPeriodId),
        enabled
    })
}
