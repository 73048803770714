import { useElectionParticipantsStatistic, useLocality } from 'api'
import { ElectionParticipant, SortOrder } from 'models'
import { useEffect, useState } from 'react'
import VerifyParticipantsTable from '../../../../components/VerifyParticipantsTable'
import { ParticipantFilter } from '../../../../models'
import { useCities, useElectionPeriod, useLocalities, useParticipants } from '../../api'


type VerifyByElectionParticipantsProps = {
    electionPeriodId: string
}

function VerifyByElectionParticipants({ electionPeriodId }: VerifyByElectionParticipantsProps) {
    const defaultFilter: ParticipantFilter = { sortBy: { field: 'NAME', order: SortOrder.ASC }, offset: 0, limit: 30 }

    const [participantFilter, setParticipantFilter] = useState<ParticipantFilter>({ ...defaultFilter })
    const [participants, setParticipants] = useState<ElectionParticipant[]>([])

    const { data: loadedParticipants, isFetching }
        = useParticipants(electionPeriodId, participantFilter)
    const { data: localities } = useLocalities(electionPeriodId)
    const { data: cities } = useCities(electionPeriodId)
    const { data: period } = useElectionPeriod(electionPeriodId)

    const { data: participantsStatistic } = useElectionParticipantsStatistic(period!.byElection.id.toString(), !!period)
    const { data: locality } = useLocality(period!.localityCode, !!period)

    const [page, setPage] = useState(0)
    const [canLoadMore, setCanLoadMore] = useState(true)

    const handleScrollToBottom = () => {
        if (!canLoadMore || isFetching)
            return
        setPage(page + 1)
        setParticipantFilter({ ...participantFilter, offset: participantFilter.limit! * (page + 1) })
    }

    const onFilterChange = (filter: ParticipantFilter) => {
        window.scrollTo(0, 0)
        setPage(0)
        setParticipantFilter({ ...filter, offset: 0 })
    }

    useEffect(() => {
        setCanLoadMore(!!loadedParticipants?.length)
        if (loadedParticipants) {
            setParticipants(page ? participants.concat(loadedParticipants) : [...loadedParticipants])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedParticipants])

    return (
        <VerifyParticipantsTable
            participants={participants}
            participantsStatistic={participantsStatistic}
            localities={localities}
            cities={cities}
            onFilterChange={onFilterChange}
            onScrollToBottom={handleScrollToBottom}
            defaultFilter={defaultFilter}
            isFetching={isFetching}
            withLocalUnit={!!locality?.localUnits?.length}
            localUnits={locality?.localUnits?.map(lu => lu.code)}
        />
    )
}

export default VerifyByElectionParticipants