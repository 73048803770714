import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { NoLongerServingOfficers } from '../../models'

export const useByElectionNoLongerServingOfficers = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getByElectionNoLongerServingOfficers = async ({ queryKey }: QueryFunctionContext)
        : Promise<NoLongerServingOfficers[]> => {
        const [, electionId] = queryKey

        const res = await axios<NoLongerServingOfficers[]>(
            `${moduleUrl}elections/by-election/${electionId}/no-longer-serving-officers`)

        return res.data
    }

    return useQuery({
        queryKey: ['by-election-no-longer-serving-officers', electionId],
        queryFn: getByElectionNoLongerServingOfficers,
        enabled
    })
}