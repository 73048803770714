import { BASE_URL, 
    NOTIFICATION_URL, 
    ONLINE_BALLOT_URL, 
    REPORT_URL, 
    TELLING_STATION_URL, 
    TOOLKIT_URL } from 'api/obs-api'
import { ApiModule } from '../api-module'

export const getModuleUrl = (apiModule: ApiModule) => {
    switch (apiModule) {
        case ApiModule.TellingStation:
            return TELLING_STATION_URL + apiModule
        case ApiModule.OnlineBallot:
            return ONLINE_BALLOT_URL + apiModule
        case ApiModule.Report:
            return REPORT_URL + apiModule
        case ApiModule.Notification:
            return NOTIFICATION_URL  + apiModule
        case ApiModule.Toolkit:
            return TOOLKIT_URL + apiModule
        default:
            return BASE_URL + apiModule
    }

}