import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { VotingApproach } from 'models'

type CastBallotParams = {
    candidates: string[],
    votingApproach: VotingApproach,
    electionId: number
}

export function useCastBallot() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.OnlineBallot)

    async function castBallot({ candidates, votingApproach, electionId }: CastBallotParams) {
        return await axios(`${moduleUrl}elections/${electionId}/voting/cast-ballot`,
            {
                method: 'post',
                data: {
                    candidates,
                    votingApproach
                }
            })
    }

    return useMutation({
        mutationFn: castBallot
    })
}