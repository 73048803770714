import { ColorStyle } from 'models'
import { useTranslation } from 'react-i18next'

export const useColorStyleMap = () => {
    const { t } = useTranslation('configuration')

    return {
        toString: (colorStyle: ColorStyle): string => {
            switch (colorStyle) {
                case ColorStyle.Active:
                    return t('active')
                case ColorStyle.Body:
                    return t('body')
                case ColorStyle.Disabled:
                    return t('disabled')
                case ColorStyle.Error:
                    return t('error')
                case ColorStyle.Link:
                    return t('link')
                case ColorStyle.Primary:
                    return t('primary')
                case ColorStyle.Secondary:
                    return t('secondary')
                case ColorStyle.Success:
                    return t('success')
                case ColorStyle.Tertiary:
                    return t('tertiary')
                case ColorStyle.Warning:
                    return t('warning')
            }
        }
    }

}