import { auth as authObj } from 'features/auth'
import { useAuth, usePopup, useRoleMap } from 'hooks'
import { ReactComponent as User } from 'icons/user.svg'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'

type RoleNavProps = {
    asListItem?: boolean,
    isRoleDisabled?: boolean
}

const RoleNav = ({ asListItem, isRoleDisabled }: RoleNavProps) => {
    const { t } = useTranslation('auth')
    const { toString } = useRoleMap()
    const { auth } = useAuth()
    const { show, hide, popupPortal } = usePopup()

    const handleClick = (event: any) => {
        if (event.type === 'click' || (event.type === 'keypress' && event.key === 'Enter')) {
            show({
                children: (<>
                    <div className={styles.greeting}>
                        <div tabIndex={0} className={styles.name}>
                            { auth?.name}
                        </div>
                    <div tabIndex={0}
                    className={styles.bid}> 
                        {t('common:bid_id', { id: auth?.id })}
                    </div>
                </div>
            <div className={styles.divider} />
                <label>
                    {t('select_the_function_you_are_performing')}
                </label>
                    <authObj.components.ActionsPanel
                        showTutorialLink={false}
                        onProceed={hide}
                        defaultSelectedRole={auth?.electionId
                            ? { electionId: auth.electionId!, role: auth.role! }
                            : auth?.role}
                    /></>
                ),
                footer: null
            })
        }
    }

    return (
        <nav
            onKeyPress={handleClick}
            tabIndex={0}
            role={'combobox'}
            aria-expanded={false}
            aria-controls={'user'}
            className={`${styles.roleNav} ${asListItem ? styles.asListItem : ''}`}
        >
            {popupPortal}
            {auth && (
                <div
                    id={'user'}
                    className={`${styles.roleSwitcher} ${isRoleDisabled ? styles.isDisabled : ''}`}
                    onClick={isRoleDisabled ? undefined : handleClick}
                >
                    <div className={styles.roleIcon}><User /></div>
                    <span className={styles.roleBrowser}>{toString(auth.role!)}</span>
                </div>
            )}
        </nav>
    )
}

export default RoleNav