import { useTranslation } from 'react-i18next'
import { IWizardButtonNextSettings } from '../models'

type Props = {
    buttonSettings: IWizardButtonNextSettings,
    onClick: (callback: () => Promise<boolean>) => void
}

function WizardNextButton({ buttonSettings, onClick }: Props) {
    const { t } = useTranslation()

    return (
        <button className="btn-main-primary-md hasIcon"
            id="wizard-next-step"
            onClick={() => onClick(buttonSettings.callback)}>
            <span>{buttonSettings.title || t('next_step')}</span>
            {buttonSettings.withDivider && <span className="divider" />}
            {
                buttonSettings.icon
            }
        </button>
    )
}

export default WizardNextButton