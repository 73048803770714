import {
    useBreakingTieRounds, useConfirmedCandidates, useElectedCandidates,
    useElectionResultsTie, useElectionSummary
} from 'api'
import Empty from 'components/molecules/Empty'
import InternalScrollTableDefaultHeader from 'components/molecules/InternalScrollTableDefaultHeader'
import { Election, ElectionResult, ElectionStatusType, InternalScrollTableDefaultColumn } from 'models'
import { useEffect, useMemo, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import { FixedSizeList as List } from 'react-window'
import { useElectionResultsCandidates } from '../../../../api'
import ResultsTableFilter from '../../../../components/ResultsTableFilter'
import { useConfirmation } from '../../hooks'
import ResultTableItem from '../ResultTableItem'
import style from './index.module.scss'

const LIST_ITEM_HEIGHT = 40
const SHOW_ALL_VACANCIES = '1'
const SHOW_TOP_30 = '2'
type ResultsTableProps = {
    election?: Election,
    showAllVacanciesOnly?: boolean,
    canShowLocalUnit?: boolean,
    showLinkToConfirmedResults?: boolean,
    isOnDashboard?: boolean
}

function ResultsTable({
    election,
    showAllVacanciesOnly,
    canShowLocalUnit,
    showLinkToConfirmedResults,
    isOnDashboard }: ResultsTableProps) {
    const { t } = useTranslation('teller')
    const [shownResultsCountOption, setShownResultsCountOption] = useState(SHOW_ALL_VACANCIES)
    const [allResults, setAllResults] = useState<ElectionResult[]>([])
    const [resultsToShow, setResultsToShow] = useState<ElectionResult[]>([])
    const [confirmed, setConfirmed] = useState(false)
    const [skippedTieCandidatesCount, setSkippedTieCandidatesCount] = useState(0)
    const [skippedTieCandidates, setSkippedTieCandidates] = useState<Set<string>>(new Set())
    const [tieButNotElectedCandidates, setTieButNotElectedCandidates] = useState<Set<string>>(new Set())
    const [confirmedTieCandidates, setConfirmedTieCandidates] = useState<Set<string>>(new Set())

    const { data: candidates } =
        useElectionResultsCandidates(election?.id.toString() || '', showAllVacanciesOnly
            ? { limit: election?.numberOfVacancies || 0 }
            : {},
            !!election?.id && !showAllVacanciesOnly)
    const { data: confirmedCandidates } = useConfirmedCandidates(election?.id.toString() || '', !!election?.id)
    const { data: tieResults } = useElectionResultsTie(election?.id.toString() || '', !!election?.id)

    const { setCandidatesToConfirm } = useConfirmation()

    const { data: summary } = useElectionSummary(election?.id.toString() || '', !!election)

    const { data: breakingTieRounds } = useBreakingTieRounds(election?.id.toString() || '', !!election?.id)

    const { data: electedCandidates } =
        useElectedCandidates(election?.id.toString() || '', !!election?.id)

    useMemo(() => {
        if (!electedCandidates || !electedCandidates || !tieResults) {
            setSkippedTieCandidatesCount(0)
            setSkippedTieCandidates(new Set())
            setTieButNotElectedCandidates(new Set())
            setConfirmedTieCandidates(new Set())

            return
        }

        const electedCandidatesCount = electedCandidates.length
        const electedCandidateIds = new Set(electedCandidates.map((candidate: ElectionResult) => candidate.id))
        const confirmedCandidateIds = new Set(confirmedCandidates?.map((candidate: ElectionResult) => candidate.id))

        let filteredTieResultsCount = 0
        const newSkippedTieCandidates = new Set<string>()
        const newTieButNotElectedCandidates = new Set<string>()
        const newConfirmedTieCandidates = new Set<string>()

        if (Array.isArray(tieResults.candidates)) {
            tieResults.candidates.forEach((candidate) => {
                if (!electedCandidateIds.has(candidate.id)) {
                    filteredTieResultsCount++
                    newTieButNotElectedCandidates.add(candidate.id)
                    if (confirmedCandidateIds.has(candidate.id)) {
                        newConfirmedTieCandidates.add(candidate.id)
                    }
                    if (summary?.breakingTieSkipped && !summary?.confirmedBy) {
                        newSkippedTieCandidates.add(candidate.id)
                    }
                }
            })

        }

        setSkippedTieCandidatesCount(filteredTieResultsCount + electedCandidatesCount)
        setSkippedTieCandidates(newSkippedTieCandidates)
        setTieButNotElectedCandidates(newTieButNotElectedCandidates)
        setConfirmedTieCandidates(newConfirmedTieCandidates)
    }, [electedCandidates, tieResults, summary, confirmedCandidates, setSkippedTieCandidatesCount])

    const allVacanciesWithTie = summary?.breakingTieSkipped && !summary?.confirmedBy
        ? skippedTieCandidatesCount : election?.numberOfVacancies || 0

    const lastCompletedRound = useMemo(() => {
        const completedRounds = breakingTieRounds?.filter(round =>
            round.status === ElectionStatusType.COMPLETED)

        return completedRounds?.pop()
    }, [breakingTieRounds])

    const { data: lastRoundCandidates } =
        useElectionResultsCandidates(lastCompletedRound?.id.toString() || '',
            {}, !!lastCompletedRound?.id.toString())

    const tableMaxHeight = useMemo(() => {
        const defaultMaxHeight = 400
        const allShownResultsHeight = allResults.length * LIST_ITEM_HEIGHT

        switch (shownResultsCountOption) {
            case SHOW_ALL_VACANCIES:
                return allResults.length * LIST_ITEM_HEIGHT

            case SHOW_TOP_30:

                if (allResults.length < 10) {
                    return allShownResultsHeight
                }

                if (allResults.length >= 10) {
                    return defaultMaxHeight
                }
                break

            default:
                if (allResults.length < 10) {
                    return allShownResultsHeight
                }

                return defaultMaxHeight
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allResults, shownResultsCountOption, allResults])

    useEffect(() => {
        setConfirmed(!!summary?.confirmedBy)
    }, [summary])

    useEffect(() => {
        const confirmedWithRounds =
            confirmedCandidates?.map(c => {
                const lastRoundResult = lastRoundCandidates?.find(l => l.id === c.id)

                return { ...c, tieLatestRoundVoteCount: lastRoundResult?.voteCount }
            })

        const candidatesWithRounds = candidates?.map(c => {
            const lastRoundResult = lastRoundCandidates?.find(l => l.id === c.id)

            return { ...c, tieLatestRoundVoteCount: lastRoundResult?.voteCount }
        })

        if (showAllVacanciesOnly) {
            setAllResults(confirmedWithRounds?.sort(
                (a, b) => {
                    if (b.voteCount !== a.voteCount)
                        return b.voteCount - a.voteCount

                    if (!!a.tieLatestRoundVoteCount && !!b.tieLatestRoundVoteCount) {
                        return b.tieLatestRoundVoteCount - a.tieLatestRoundVoteCount
                    }

                    if (!!a.tieLatestRoundVoteCount) {
                        return -1
                    }
                    if (!!b.tieLatestRoundVoteCount) {
                        return 1
                    }

                    return a.name.localeCompare(b.name)
                }
            ) || [])
        } else {
            if (confirmedWithRounds?.length) {
                const confimedCandidatesMap = {} as any

                confirmedWithRounds?.forEach(c => { confimedCandidatesMap[c.id] = true })

                if (candidatesWithRounds) {
                    setAllResults(candidatesWithRounds.map(r => ({
                        ...r,
                        confirmed: summary?.breakingTieSkipped && !summary?.confirmedBy
                            ? electedCandidates?.some(ec => ec.id === r.id) ?? false
                            : !!confimedCandidatesMap[r.id]
                    })).sort(
                        (a, b) => {
                            if (a.confirmed && !b.confirmed) {
                                return -1
                            }
                            if (!a.confirmed && b.confirmed) {
                                return 1
                            }

                            if (b.voteCount !== a.voteCount)
                                return b.voteCount - a.voteCount

                            if (!!a.tieLatestRoundVoteCount && !!b.tieLatestRoundVoteCount) {
                                return b.tieLatestRoundVoteCount - a.tieLatestRoundVoteCount
                            }

                            if (!!a.tieLatestRoundVoteCount) {
                                return -1
                            }
                            if (!!b.tieLatestRoundVoteCount) {
                                return 1
                            }

                            return a.name.localeCompare(b.name)
                        }
                    ))
                }
            } else {
                if (candidatesWithRounds?.length) {
                    const sorted = candidatesWithRounds.sort(
                        (a, b) => {
                            if (b.voteCount !== a.voteCount)
                                return b.voteCount - a.voteCount

                            if (tieResults?.resolution?.candidates?.length) {
                                const isATieElectee = tieResults.resolution.candidates.includes(a.id)
                                const isBTieElectee = tieResults.resolution.candidates.includes(b.id)
                                if (isATieElectee && !isBTieElectee) {
                                    return -1
                                }
                                if (!isATieElectee && isBTieElectee) {
                                    return 1
                                }
                            }

                            if (!!a.tieLatestRoundVoteCount && !!b.tieLatestRoundVoteCount) {
                                return b.tieLatestRoundVoteCount - a.tieLatestRoundVoteCount
                            }

                            if (!!a.tieLatestRoundVoteCount) {
                                return -1
                            }
                            if (!!b.tieLatestRoundVoteCount) {
                                return 1
                            }

                            return a.name.localeCompare(b.name)
                        }
                    )

                    for (let i = 0; i < (election?.numberOfVacancies || 0); i++) {
                        if (sorted[i]) {
                            sorted[i].confirmed = summary?.breakingTieSkipped && !summary?.confirmedBy
                                ? electedCandidates?.some(ec => ec.id === sorted[i].id) ?? false
                                : true
                        }
                    }
                    setAllResults(Array.from(sorted))
                }
            }
        }
    }, [showAllVacanciesOnly, candidates, confirmedCandidates, election,
        tieResults, lastCompletedRound, lastRoundCandidates, summary, electedCandidates])

    useEffect(() => {
        let shownResults = [...allResults]

        if (shownResultsCountOption === SHOW_ALL_VACANCIES) {
            shownResults = shownResults.slice(0, allVacanciesWithTie)
        } else if (shownResultsCountOption === SHOW_TOP_30) {
            shownResults = shownResults.slice(0, 30)
        }

        setResultsToShow(shownResults)
    }, [allResults, shownResultsCountOption, allVacanciesWithTie])

    useEffect(() => {
        setCandidatesToConfirm(allResults.filter(r => r.confirmed))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allResults])

    const handleResultsCountOptionChange = (value: unknown) => {
        setShownResultsCountOption(value as string)
    }

    const updateConfirmedFlag = (participantId: string) => {
        if (!confirmed) {
            const result = allResults.find(r => r.id === participantId)!
            result.confirmed = !result.confirmed
            setAllResults(allResults.map(r => r.id === participantId ? result : r))
        }
    }

    const columns: InternalScrollTableDefaultColumn[] = useMemo(() => {

        const res = [
            { title: t('pos') + '.', width: 60 },
            { title: t('bahai_id'), width: 78 },
            { title: t('full_name'), width: !!lastCompletedRound ? 220 : 300 },
            { title: t('gender'), width: 72 },
            { title: t('locality_code'), width: 90 },
            { title: t('phone'), width: 136 },
            { title: t('race'), width: 214 },
            { title: t('ethnicity'), width: 215 },
            { title: t('votes'), width: 80 }]

        if (!!lastCompletedRound) {
            res.push({
                title: t('latest_round_number',
                    { number: lastCompletedRound?.roundNumber.toString() }), width: 80
            })
        }

        res.push({ title: t('confirmed'), width: 86 })

        return res
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [lastCompletedRound])

    return (
        <div className={style.listContainer}>
            <ResultsTableFilter
                title={(confirmed ? t('confirmed_results') : t('merged_results')) +
                    (election?.region?.localUnit && canShowLocalUnit ? ` (${election.region.localUnit})` : '')
                }
                election={election}
                onFilterChange={handleResultsCountOptionChange}
                showAllVacanciesOnly={showAllVacanciesOnly}
                showLinkToConfirmedResults={showLinkToConfirmedResults}
                isOnDashboard={isOnDashboard}
            />
            <InternalScrollTableDefaultHeader columns={columns} />
            {resultsToShow?.length ?
                <List
                    style={{
                        ...style,
                        maxHeight: tableMaxHeight
                    }}
                    outerElementType={Scrollbars}
                    className={style.list}
                    height={(resultsToShow.length ?? 0) * LIST_ITEM_HEIGHT}
                    itemCount={resultsToShow.length}
                    itemSize={LIST_ITEM_HEIGHT}
                    width={'auto'}
                >
                    {({ index, style: itemStyle }) => (

                        <ResultTableItem
                            index={index}
                            style={itemStyle}
                            result={resultsToShow[index]}
                            resultsConfirmed={confirmed}
                            withLatestRound={!!lastCompletedRound}
                            isTieElectee={tieResults?.resolution?.candidates?.length
                                ? tieResults.resolution.candidates.includes(resultsToShow[index].id)
                                : false
                            }
                            isSkippedTieCandidate={skippedTieCandidates.has(resultsToShow[index].id)}
                            isTieButNotElected={tieButNotElectedCandidates.has(resultsToShow[index].id)}
                            isConfirmedTieCandidate={confirmedTieCandidates.has(resultsToShow[index].id)}
                            onConfirmCheckboxChange={() => {
                                updateConfirmedFlag(resultsToShow[index].id)
                            }}
                        />
                    )}
                </List>
                : <div className={style.empty}>
                    <Empty />
                </div>
            }
        </div>
    )
}

export default ResultsTable