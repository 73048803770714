import { PropsWithChildren, useState } from 'react'
import { TellingStationProcessContext } from '../context'
import { TellingStationProcessInfo } from '../models'

function TellingStationProcessProvider({ children }: PropsWithChildren) {
    const [info, setInfo] = useState<TellingStationProcessInfo>({
        tutorialShown: false,
        isPdfInstructionReady: false,
        isPdfInstructionLoading: false
    })

    return (
        <TellingStationProcessContext.Provider value={{ info, setInfo }} >
            {children}
        </TellingStationProcessContext.Provider>
    )
}

export default TellingStationProcessProvider