import Preloader from 'components/molecules/Preloader'
import { queryClient } from 'config/query-client'
import { tellingStation } from 'features/tellingStation'
import { useBrowserZoomRatio, useConfirmPopup, useInfoPopup } from 'hooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCompletedPopup, useDownloadPdfInstruction, useTellingStationProcessInfo } from '../../hooks'
import DownloadInstructionButton from '../DownloadInstructionButton'
import EnterBallotsTutorial from '../EnterBallotsTutorial'
import ReportingTutorial from '../ReportingTutorial'
import VoterVerificationTutorial from '../VoterVerificationTutorial'
import style from './index.module.scss'

const MaxRatioForTutorialVisible = 1.20

function StationDashboard() {
    const { t } = useTranslation('telling-station-sandbox')
    const { openCompletedPopup } = useCompletedPopup()
    const { confirmPortal, showConfirm, hide } = useConfirmPopup()
    const { downloadPdfInstruction } = useDownloadPdfInstruction()
    const { info, setInfo } = useTellingStationProcessInfo()
    const { infoPortal, showInfo } = useInfoPopup()
    const { browserZoomRatio } = useBrowserZoomRatio()


    useEffect(() => {
        if (browserZoomRatio > MaxRatioForTutorialVisible
            && info?.tutorialShown) {
            setInfo(current => ({ ...current, tutorialShown: false }))
            showInfo({
                title: t('not_enough_space_for_training_tips'),
                text: t('there_is_not_enough_space_to_display_training_tips'),
                onOk: () => { }
            })
        }
    }, [browserZoomRatio, info?.tutorialShown])

    useEffect(() => {
        setInfo(current => ({ ...current, isPdfInstructionReady: false }))
        queryClient.removeQueries(['instructions'])
    }, [])

    return (
        <>
            {info.isPdfInstructionLoading && < Preloader />}
            {confirmPortal}
            {infoPortal}
            <tellingStation.components.TellingStationProcess
                voterVerificationTutorial={<VoterVerificationTutorial />}
                voterVerificationTableHeight="calc(100vh - 167px)"
                voterVerificationTableColHighlightingHeight="calc(100vh - 250px)"
                enterBallotsTutorial=<EnterBallotsTutorial />
                enteredBallotsTableOffset="135px"
                reportingTutorial=<ReportingTutorial />
                wizardRightSideExtraContent=<DownloadInstructionButton onClick={downloadPdfInstruction} />
                banner=<div className={style.banner}>{t('training_mode')}
                    <span className={style.hint}> ({t('uses_mock_membership_data')})</span>
                </div>
                onStationReportSigned={() => openCompletedPopup(showConfirm, hide)}
                hideElectionTypeInfo
            />

        </>
    )
}

export default StationDashboard