import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { RidvanElectionPeriod } from 'models'

export const useElectionPeriod = (electionPeriodId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectionPeriod = async (electionPeriodId: string): Promise<RidvanElectionPeriod> => {
        const result = await axios<RidvanElectionPeriod>(
            `${moduleUrl}ridvan-election-periods/${electionPeriodId}`)

        return result.data
    }

    return useQuery({
        queryKey: ['ridvan-election-period', electionPeriodId],
        queryFn: () => getElectionPeriod(electionPeriodId),
        enabled
    })
}
