import { SelectProps } from 'antd'
import {
    useElection,
    useElectionFrozenParticipants,
    useElectionParticipants,
    useElectionParticipantsStatistic,
    useOnlineVoters
} from 'api'
import Input from 'components/atoms/Input'
import { Select } from 'components/atoms/Select'
import FilterableHeaderCell from 'components/molecules/FilterableHeaderCell'
import MultiSelect from 'components/molecules/MultiSelect'
import SortableHeaderCell from 'components/molecules/SortableHeaderCell'
import { electionEditing } from 'features/electionEditing'
import { useAgeCategoryMap, useAllOption, useFilterable, useGenderMap, useSortable } from 'hooks'
import { forIn } from 'lodash'
import { AgeCategory, ElectionStatusType, ElectionType, Gender, SortOrder } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import 'style/shared/full-data-table/full-data-table.scss'
import { numbersOnly } from 'utils'
import { useEligibleIndividualsTableData } from '../../hooks'
import { EigibleIndividualsParticipant, EligibleIndividualsParticipantFilter } from '../../models'
import { filterParticipants, getTableColumnWidths } from '../../utils'

const DEFAULT_FILTER: EligibleIndividualsParticipantFilter = {
    sortBy: {
        field: 'name',
        order: SortOrder.ASC
    }
}

const EligibleIndividualsTableHeader = () => {
    const { electionId } = useParams()

    const { setTableData, tableData } = useEligibleIndividualsTableData()

    const { data: election } = useElection(electionId || '')
    const electionPeriodId = election?.electionPeriod.toString()
    const { data: unfrozenParticipants } =
        useElectionParticipants(electionId!, election?.status !== ElectionStatusType.COMPLETED)
    const { data: frozenParticipants } =
        useElectionFrozenParticipants(electionId!, election?.status === ElectionStatusType.COMPLETED)
    const { data: onlineVoters } = useOnlineVoters(electionId!, !!electionId)
    const { data: statistic } = useElectionParticipantsStatistic(electionId!, !!electionId)
    const { data: ridvanElectionPeriod } =
        electionEditing.ridvan.api.useElectionPeriod(electionPeriodId!, election?.type === ElectionType.RIDVAN_ELECTION)

    const [allParticipants, setAllParticipants] = useState<EigibleIndividualsParticipant[]>([])
    const [loadedParticipants, setLoadedParticipants] = useState<EigibleIndividualsParticipant[]>([])
    const [participants, setParticipants] = useState<EigibleIndividualsParticipant[]>([])
    const [filter, setFilter] = useState<EligibleIndividualsParticipantFilter>({ ...DEFAULT_FILTER })
    const [allLocalityCities, setAllLocalityCities] = useState<string[]>([] as any)
    const [allLocalUnits, setAllLocalUnits] = useState<string[]>([] as any)
    const [allLocalities, setAllLocalities] = useState<string[]>([] as any)
    const [filterCleared, setFilterCleared] = useState(false)

    const { prepareFiltersForRequest } = useFilterable<EligibleIndividualsParticipantFilter>()
    const { changeFilter } = useFilterable<EligibleIndividualsParticipantFilter>()
    const { changeSortBy } = useSortable()
    const allOption = useAllOption()
    const { toString: ageCategoryToString } = useAgeCategoryMap()
    const { toString: genderToString } = useGenderMap()
    const { t } = useTranslation()

    const onSortingChange = (field: string, order?: SortOrder) =>
        setFilter(changeSortBy(filter, field, order) as EligibleIndividualsParticipantFilter)

    const tableColumnWidths = useMemo(() => getTableColumnWidths(tableData.includeLocalUnit), [tableData])

    useEffect(() => {
        if (!loadedParticipants || !onlineVoters) return

        const participantsWithVotedOnlineFlag = loadedParticipants.map(participant => {
            const isParticipantVotedOnline = onlineVoters.verification.find(
                (votedParticipant: any) => (
                    +votedParticipant.id === +participant.id
                )
            )

            return { ...participant, votedOnline: !!isParticipantVotedOnline }
        })
        setAllParticipants(participantsWithVotedOnlineFlag)
    }, [loadedParticipants, onlineVoters])

    useEffect(() => {
        if (!unfrozenParticipants && !frozenParticipants) return

        const loaded = frozenParticipants || unfrozenParticipants

        setLoadedParticipants(loaded!)
    }, [frozenParticipants, unfrozenParticipants])

    useEffect(() => {
        if (!allParticipants.length) return

        const filterToApply = prepareFiltersForRequest(filter)
        setParticipants(filterParticipants(allParticipants, filterToApply))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allParticipants, filter])

    useEffect(() => {
        if (!participants || !election || !onlineVoters) return

        let withLocalUnit = false

        if (
            (election.type === ElectionType.LOCAL_DELEGATE_ELECTION) ||
            (election.type === ElectionType.BY_ELECTION)
        ) withLocalUnit = true

        if ((election.type === ElectionType.RIDVAN_ELECTION) && !ridvanElectionPeriod) return

        if (
            (election.type === ElectionType.RIDVAN_ELECTION) &&
            electionEditing.ridvan.utils.isRidvanSecondStage(ridvanElectionPeriod!)
        ) withLocalUnit = true

        setTableData({
            participants,
            statistics: { ...statistic, votedOnlineCount: onlineVoters.total },
            includeLocalUnit: withLocalUnit
        })
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [participants, election, ridvanElectionPeriod, statistic, onlineVoters])

    useEffect(() => {
        if (loadedParticipants) {
            const cities: string[] = []
            const localities: string[] = []
            const localUnits: string[] = []

            loadedParticipants.forEach(p => {
                if (p.city)
                    cities.push(p.city)
                if (p.homeLocality.code) {
                    localities.push(p.homeLocality.code)
                }
                if (p.homeLocalUnit?.code) {
                    localUnits.push(p.homeLocalUnit.code)
                }
            })
            setAllLocalityCities([...new Set(cities)].sort())
            setAllLocalities([...new Set(localities)].sort())
            setAllLocalUnits([...new Set(localUnits)].sort())
        } else {
            setAllLocalityCities([])
            setAllLocalities([])
            setAllLocalUnits([])
        }
    }, [loadedParticipants])

    const filterCellContentWith = (cellWidth: number) => `calc(${cellWidth}px - calc(var(--default-space) * 2)`

    const genderOptions: SelectProps['options'] = []
    forIn(Gender, (value) => {
        genderOptions.push({ value: value, label: genderToString(value) })
    })

    const ageCategoryOptions: SelectProps['options'] = []
    forIn(AgeCategory, (value) => {
        ageCategoryOptions.push({ value: value, label: ageCategoryToString(value) })
    })

    const clearAllFilters = () => {
        setFilter({ ...DEFAULT_FILTER })
        setFilterCleared(() => true)
    }

    const onChange = (f: EligibleIndividualsParticipantFilter) => {
        setFilter(f)
        setFilterCleared(() => false)
    }

    return (
        <div className="full-data-table-header">
            <div
                style={{ width: getTableColumnWidths(tableData.includeLocalUnit).name }}
                className="full-data-table-column"
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Input
                            allowClear={true}
                            className="small"
                            style={{ width: filterCellContentWith(tableColumnWidths.name) }}
                            value={filter.name || undefined}
                            onChange={(e) => onChange(
                                changeFilter(filter, 'name', e.target.value)
                            )}
                            placeholder={t('search_by_name')}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('full_name')}
                            field={'name'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div >
            <div
                style={{ width: tableColumnWidths.id }}
                className="full-data-table-column"
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Input
                            className="small"
                            style={{ width: filterCellContentWith(tableColumnWidths.id) }}
                            allowClear={true}
                            value={filter.id || undefined}
                            onInput={(e) => {
                                const target = e.target as any
                                if (target.value) {
                                    if (!numbersOnly(target.value)) {
                                        target.value = filter.id || ''
                                    }
                                }
                            }}
                            onChange={(e) => onChange(changeFilter(filter, 'id', e.target.value))}
                            placeholder={t('telling-station:search_by_id')}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('bahai_id')}
                            field={'id'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div >
            <div
                style={{ width: tableColumnWidths.gender }}
                className="full-data-table-column"
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Select
                            title=""
                            className="small"
                            style={{ width: filterCellContentWith(tableColumnWidths.gender) }}
                            value={filter.gender || undefined}
                            onChange={(value) => onChange(
                                changeFilter(filter, 'gender', value as string[]))}
                            placeholder={t('all')}
                            onClick={(e) => e.stopPropagation()}
                            options={[allOption, ...genderOptions]}
                        />
                    }
                    title={t('gender')}
                />
            </div >
            <div
                style={{ width: tableColumnWidths.ageCategory }}
                className="full-data-table-column"
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Select
                            title=""
                            className="small"
                            style={{ width: filterCellContentWith(tableColumnWidths.ageCategory) }}
                            value={filter.ageCategory || undefined}
                            onChange={(value) => onChange(
                                changeFilter(filter, 'ageCategory', value as string[]))}
                            placeholder={t('all')}
                            onClick={(e) => e.stopPropagation()}
                            options={[allOption, ...ageCategoryOptions]}
                        />
                    }
                    title={t('age_category')}
                />
            </div >
            <div
                style={{ width: tableColumnWidths.homeLocality }}
                className="full-data-table-column"
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <MultiSelect
                            searchCleared={filterCleared}
                            onSearchValueChanged={() => setFilterCleared(false)}
                            className="small"
                            popupClassName="small"
                            style={{ width: filterCellContentWith(tableColumnWidths.homeLocality) }}
                            popupWidth={200}
                            value={filter.homeLocality || []}
                            onChange={(value) => onChange(
                                changeFilter(filter, 'homeLocality', value as string[]))}
                            options={allLocalities.map(c => ({ value: c, label: c }))}
                            placeholder={t('all')}
                        />
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('locality_code')}
                            field={'homeLocality'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div >
            {tableData.includeLocalUnit &&
                <div
                    style={{ width: tableColumnWidths.homeLocalUnit }}
                    className="full-data-table-column"
                >
                    <FilterableHeaderCell
                        size="small"
                        filter={
                            <MultiSelect
                                searchCleared={filterCleared}
                                onSearchValueChanged={() => setFilterCleared(false)}
                                className="small"
                                popupClassName="small"
                                style={{ width: filterCellContentWith(tableColumnWidths.homeLocalUnit) }}
                                popupWidth={200}
                                value={filter.homeLocalUnit || []}
                                onChange={(value) => onChange(
                                    changeFilter(filter, 'homeLocalUnit', value as string[]))}
                                options={allLocalUnits.map(s => ({
                                    value: s,
                                    label: s
                                }))}
                                placeholder={t('all')}
                            />
                        }
                        title={
                            <SortableHeaderCell
                                value={filter.sortBy}
                                title={t('local_unit')}
                                field={'homeLocalUnit'}
                                onChange={onSortingChange}
                            />
                        }
                    />
                </div>
            }
            <div
                style={{ width: tableColumnWidths.city }}
                className="full-data-table-column"
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <MultiSelect
                            searchCleared={filterCleared}
                            onSearchValueChanged={() => setFilterCleared(false)}
                            className="small"
                            popupClassName="small"
                            style={{ width: filterCellContentWith(tableColumnWidths.city) }}
                            popupWidth={200}
                            value={filter.city || []}
                            onChange={(value) => onChange(
                                changeFilter(filter, 'city', value as string[]))}
                            options={allLocalityCities.map(c => ({ value: c, label: c }))}
                            placeholder={t('all')}
                        />
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('city')}
                            field={'city'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div >
            <div
                style={{ width: tableColumnWidths.votedOnline }}
                className="full-data-table-column"
            >
                <FilterableHeaderCell
                    size="small"
                    filter={<></>}
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('election:voted_online')}
                            field={'votedOnline'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div >
            <div
                style={{ width: tableColumnWidths.action }}
                className="full-data-table-column"
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <button
                            className="btn-main-tertiary-md"
                            onClick={clearAllFilters}
                        >
                            {t('clear_all_fillters')}
                        </button>
                    }
                    title={
                        <div>
                            {t('election:eligible_to')}
                            <br />
                            {t('election:serve_vote')}
                        </div>
                    }
                />
            </div>
        </div >
    )
}

export default EligibleIndividualsTableHeader