import { useAvailableElections } from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import PopupTitle from 'components/atoms/PopupTitle'
import Tooltip from 'components/atoms/Tooltip'
import { electionEditing } from 'features/electionEditing'
import { usePopup } from 'hooks'
import { ReactComponent as Plus } from 'icons/plus.svg'
import { AvailableElection, ElectionType } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'

function CreateElectionButton() {
    const { data: allAvailableElections } = useAvailableElections()
    const { t } = useTranslation('election')
    const { show, popupPortal } = usePopup()
    const [availableElections, setAvailableElections] = useState<AvailableElection[]>()

    useEffect(() => {
        if (allAvailableElections) {
            setAvailableElections(allAvailableElections.filter(e => e.electionType !== ElectionType.OFFICERS_ELECTION))
        }
    }, [allAvailableElections])

    const showElectionSelect = () => {
        if (!availableElections!.length) return

        show({
            children: <electionEditing.components.SelectElectionToCreate
                availableElections={availableElections || []} />,
            title: <PopupTitle>{t('select_an_election_to_create')}</PopupTitle>,
            footer: null
        })
    }

    const canCreate = useMemo(() => !!availableElections?.length, [availableElections])

    const createAnElectionButton = useMemo(() => (
        <button className="btn-main-primary-md" onClick={() => showElectionSelect()}
            disabled={!canCreate}>
            <IconWrapper><Plus width="18" height="18" /></IconWrapper>
            <span>{t('create_an_election')}</span>
        </button>
    ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [canCreate, availableElections]
    )

    return (
        <>
            {canCreate
                ? <>{createAnElectionButton}</>
                : <Tooltip
                    title={t('there_are_no_available_election_types_to_create')}
                    destroyTooltipOnHide={true}
                    placement="bottom"
                    className={styles.tooltipWidth}
                >
                    {createAnElectionButton}
                </Tooltip>
            }
            {popupPortal}
        </>
    )
}

export default CreateElectionButton