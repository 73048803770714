import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionStation } from 'models'

export const useElectionTellingStations = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getTellingStations = async ({ queryKey }: QueryFunctionContext)
        : Promise<ElectionStation[]> => {
        const [, electionId] = queryKey

        const result = await axios<ElectionStation[]>(
            `${moduleUrl}elections/${electionId}/telling-stations`)

        return result.data
    }

    return useQuery({
        queryKey: ['telling-stations', electionId],
        queryFn: getTellingStations,
        enabled
    })
}
