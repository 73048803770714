import LuElectionResultsNotPushedNote from './components/LuElectionResultsNotPushedNote'
import ResultsTable from './components/ResultsTable'
import ConfirmResults from './pages/ConfirmResults'
import ConfirmedResults from './pages/ConfirmedResults'
import ConfirmationProvider from './provider/ConfirmationProvider'

export const confirmation = {
    pages: {
        ConfirmedResults,
        ConfirmResults
    },
    components: {
        ConfirmedResultsTable: ResultsTable,
        LuElectionResultsNotPushed: LuElectionResultsNotPushedNote
    },
    providers: {
        ConfirmationProvider
    }
}