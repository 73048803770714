import { Radio as AntRadio } from 'antd'
import React from 'react'

export type RadioProps = React.ComponentProps<typeof AntRadio>


function Radio(props: RadioProps) {
    return (
        <AntRadio
            {...props}
            className={`obs ${props.className || ''}`}
        >
            {props.children}
        </AntRadio>
    )
}

export default Radio