import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { BallotingElection } from '../../models/balloting-election'

export const useElectionList = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.OnlineBallot)

    const getElectionList = async () => {
        const res = await axios<BallotingElection[]>(`${moduleUrl}elections/my/voting/available`)

        return res.data
    }

    return useQuery<BallotingElection[]>({
        queryKey: ['elections-my-available'],
        queryFn: getElectionList,
        enabled
    })
}