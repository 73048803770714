import { FormInstance } from 'antd'

type isFormValidParams = {
    form: FormInstance
    scrollToFirstError?: boolean
    validateOnly?: boolean
}

export const isFormValid = async ({ form, scrollToFirstError = true, validateOnly = false }: isFormValidParams) => {
    try {
        await form.validateFields({ validateOnly })
    }
    catch {
        if (scrollToFirstError) {
            const formErrors = form.getFieldsError().filter(fieldError => fieldError.errors.length)
            if (formErrors.length) {
                form.scrollToField(formErrors[0].name, { behavior: 'smooth', block: 'center' })
            }
        }

        return false
    }

    return true
}