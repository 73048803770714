import { useQuery } from '@tanstack/react-query'
import { ApiModule } from 'api/api-module'
import { useAxios } from 'api/hooks'
import { getModuleUrl } from 'api/utils'
import { Locality } from 'models'

export const useLocalities = (localityCodes: string[], enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getLocalities = async (localityCodes: string[]) => {
        const res = await axios<Locality[]>(`${moduleUrl}institutions/localities?codes=${localityCodes.toString()}`)

        return res.data
    }

    return useQuery({
        queryKey: ['localities', localityCodes],
        queryFn: () => getLocalities(localityCodes),
        enabled,
        staleTime: 3 * (60 * 1000)
    })
}