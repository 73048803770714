import { useOfficersElections } from 'api'
import Card from 'components/atoms/Card'
import CardHeader from 'components/molecules/CardHeader'
import { useAuth } from 'hooks'
import { ElectionStatusType, LsaOfficerRole } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useElectedOfficers } from '../../../../api'
import { ElectedPosition as Position } from '../../../../models'
import ElectedPosition from '../ElectedPosition'
import style from './index.module.scss'

const defaultPositions: Position[] = [
    { role: LsaOfficerRole.CHAIRMAN, status: ElectionStatusType.NOT_STARTED },
    { role: LsaOfficerRole.VICE_CHAIRMAN, status: ElectionStatusType.NOT_STARTED },
    { role: LsaOfficerRole.TREASURER, status: ElectionStatusType.NOT_STARTED },
    { role: LsaOfficerRole.CORRESPONDING_SECRETARY, status: ElectionStatusType.NOT_STARTED },
    { role: LsaOfficerRole.RECORDING_SECRETARY, status: ElectionStatusType.NOT_STARTED, optional: true }
]

function ElectedOfficers() {
    const { t } = useTranslation('officers-election')
    const { auth } = useAuth()
    const [postions, setPositions] = useState([...defaultPositions])
    const { data: electedOfficers } = useElectedOfficers(auth?.homeLocality?.code || '', !!auth?.homeLocality?.code)
    const { data: elections } = useOfficersElections()

    useEffect(() => {
        if (electedOfficers) {
            const updatedPositions: Position[] = []
            postions.forEach(pos => {
                const election = elections?.find(e => e.electedPosition === pos.role)
                const electedOfficer = electedOfficers.find(e => e.electedPosition === pos.role)
                if (electedOfficer) {
                    const person = electedOfficer.candidates?.length
                        ? electedOfficer.candidates[0] : null
                    updatedPositions.push({
                        ...pos,
                        electedMember: person ? `${person.name} (${t('bid_id', { id: person.id })})` : '',
                        id: electedOfficer.id,
                        status: !!person
                            ? (!!election
                                ? ElectionStatusType.IN_PROGRESS
                                : ElectionStatusType.COMPLETED)
                            : ElectionStatusType.NOT_STARTED
                    })
                } else {
                    updatedPositions.push({
                        ...pos,
                        status: !!election
                            ? ElectionStatusType.IN_PROGRESS
                            : ElectionStatusType.NOT_STARTED
                    })
                }
            })
            setPositions(updatedPositions)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electedOfficers, elections])

    return (
        <Card
            noContentPadding={true}
            title={
                <CardHeader
                    title={t('elected_officers')}
                />
            }>
            <div className={style.listContainer}>
                {postions.map((position) => (
                    <div className={style.listItem} key={position.role}>
                        <ElectedPosition electedPosition={position} />
                    </div>
                ))}
            </div>
        </Card>
    )
}

export default ElectedOfficers