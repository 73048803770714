import { ElectionType } from 'models'
import { PropsWithChildren, useState } from 'react'
import { ElectionConfigContext } from '../context'
import { ElectionConfigData, ElectionPeriod } from '../model'


function ElectionConfigProvider({ children }: PropsWithChildren) {
    const [electionConfigData, setElectionConfigData] = useState<ElectionConfigData>({
        isFormTouched: false,
        electionPeriod: ElectionPeriod.RIDVAN_ELECTION_PERIODS,
        electionType: ElectionType.RIDVAN_ELECTION
    })

    return (
        <ElectionConfigContext.Provider value={{ electionConfigData, setElectionConfigData }}>
            {children}
        </ElectionConfigContext.Provider >
    )
}

export default ElectionConfigProvider