import { FormInstance } from 'antd'
import { isFormValid } from 'utils'

type SaveSetUpElectionFormParams = {
    form: FormInstance
    withValidation?: boolean,
    saveFunc: () => Promise<void>,
    checkExtraValidation?: () => Promise<boolean>
}

export function useSetUpElectionActions() {

    const saveSetUpElectionForm = async (
        {
            form,
            withValidation,
            saveFunc,
            checkExtraValidation
        }: SaveSetUpElectionFormParams) => {
        let saved = false
        if (withValidation) {
            if (!await isFormValid({ form })) {
                return saved
            }
            if (checkExtraValidation && !(await checkExtraValidation())) {
                return saved
            }
        }
        await saveFunc()
        saved = true

        return saved

    }

    return { saveSetUpElectionForm }
}