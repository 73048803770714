import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

type registerTellingStationsParams = {
    electionId: string
    stationsWithTellers: {
        station: string,
        tellers: string[]
    }[],
}

export function useRegisterTellingStations() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function registerTellingStations({ stationsWithTellers, electionId }: registerTellingStationsParams) {
        return await axios(moduleUrl
            + `elections/${electionId}/vote-counting/register-telling-stations`,
            {
                method: 'post',
                data: stationsWithTellers
            })

    }

    return useMutation({
        mutationFn: registerTellingStations
    })
}