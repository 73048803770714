import Empty from 'components/molecules/Empty'
import Scrollbars from 'react-custom-scrollbars-2'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { useEligibleIndividualsTableData } from '../../hooks'
import EligibleIndividualsTableHeader from '../EligibleIndividualsTableHeader'
import EligibleIndividualsTableItem from '../EligibleIndividualsTableItem'
import EligibleIndividualsTableStatistic from '../EligibleIndividualsTableStatistic'
import 'style/shared/full-data-table/index.scss'

const LEFT_AND_RIGHT_BORDER = 2

function EligibleIndividualsTable() {
    const { tableData } = useEligibleIndividualsTableData()

    return (
        <div className="full-data-table-listContainer" style={{
            height: 'calc(100vh - 152px)',
            minHeight: '367px'
        }}>
            <EligibleIndividualsTableStatistic />
            <EligibleIndividualsTableHeader />
            {tableData?.participants?.length ?
                <AutoSizer>
                    {({ height, width }: any) => (
                        <List
                            outerElementType={Scrollbars}
                            className="full-data-table-list"
                            height={height - 179}
                            itemCount={tableData.participants.length}
                            itemSize={40}
                            width={width - LEFT_AND_RIGHT_BORDER}
                        >
                            {EligibleIndividualsTableItem}
                        </List>
                    )}
                </AutoSizer>
                : <div className="full-data-table-empty">
                    <Empty />
                </div>
            }
        </div>
    )
}

export default EligibleIndividualsTable