import { ChildrenAndClassProps } from 'models'
import React from 'react'
import style from './panel-header.module.scss'

interface PanelProps extends ChildrenAndClassProps {
    ariaLabel?: string
    role?: string
    tabIndex?: number
}

const PanelHeader = React.forwardRef<HTMLDivElement, PanelProps>(
    (props, ref) => {
        const { children, className, ariaLabel, role, tabIndex } = props

        return (
            <div
                tabIndex={tabIndex}
                ref={ref}
                role={role}
                aria-label={ariaLabel}
                className={className ? `${className} ${style.header}` : style.header}>
                {children}
            </div>
        )
    }
)

export default PanelHeader

