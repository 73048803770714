import IconWrapper from 'components/atoms/IconWrapper'
import { ReactComponent as ChevronBold } from 'icons/chevron-bold.svg'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import packageJson from '../../../../../../../package.json'
import { useBackendVersion, useTellingStationVersion } from '../../api'
import style from './style.module.scss'


function SandboxAuthPageWrapper({ children }: PropsWithChildren) {
    const { t } = useTranslation('telling-station-sandbox')
    const { data: backendVersionInfo } = useBackendVersion()
    const { data: tellingStationVersionInfo } = useTellingStationVersion()

    const openHelpCenter = () => {
        window.location.href = 'https://helpcenter.obsbahai.org/'
    }

    return (
        <div className={style.wrapper}>
            <div className={style.openHelpCenterButton}>
                <button className="btn-main-tertiary" onClick={openHelpCenter}>
                    <IconWrapper>
                        <ChevronBold className="flip-horizontal" width="16" height="16" />
                    </IconWrapper>
                    <span>{t('return_to_help_center')}</span>
                </button>
            </div>
            <div className={style.content}>

                <div className={style.controls}>
                    {children}
                </div>
            </div>
            <div className={style.version}>
                <div>client: {packageJson?.version}</div>
                <div>starter:{backendVersionInfo?.version}, {backendVersionInfo?.buildTimestamp}</div>
                <div>ts:{tellingStationVersionInfo?.version}, {tellingStationVersionInfo?.buildTimestamp}</div>
            </div>
        </div>
    )
}

export default SandboxAuthPageWrapper