import { ElectionPushResult, MilestoneColorType } from 'models'

export const electionResultsColor = (resultsSignedBy: boolean, pushResult: ElectionPushResult | undefined) => {
    if (resultsSignedBy) {
        if (!pushResult) {
            return MilestoneColorType.PRIMARY
        } else {
            return MilestoneColorType.SUCCESS
        }
    }

    return MilestoneColorType.DEFAULT
}