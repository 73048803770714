import ContentWrapper from 'components/atoms/ContentWrapper'
import MainReport from '../components/MainReport'

export function MainElectionReport() {
    return (
        <>
            <ContentWrapper>
                <MainReport />
            </ContentWrapper>
        </>
    )
}

export default MainElectionReport
