import { PropsWithChildren } from 'react'
import styles from './popup-title.module.scss'

type PopupTitleProps = PropsWithChildren & {
    withHeaderDivider?: boolean
}

function PopupTitle({ children, withHeaderDivider }: PopupTitleProps) {
    return (
        <div id="dialogLabel" className={`${styles.title} ${withHeaderDivider ?
            styles.divider : ''}`}>
            {children}
        </div>
    )
}

export default PopupTitle