import ManageOfficersElection from '../components/ManageOfficersElection'
import LsaTellersEditingProvider from '../providers/LsaTellersEditingProvider'

function Manage() {
    return (
        <LsaTellersEditingProvider>
            <ManageOfficersElection />
        </LsaTellersEditingProvider>
    )
}

export default Manage