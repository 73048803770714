import { ColumnsType } from 'antd/es/table'
import {
    useAppointedTellers,
    useElection,
    useElectionSummary,
    useElectionTellingStations,
    useVoteCountingStarted
} from 'api'
import { Can } from 'components/atoms/Can'
import Card from 'components/atoms/Card'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import Table from 'components/molecules/Table'
import { useAbility, useAuth, useCopyToClipboardWithToast, useTellerTypeMap } from 'hooks'
import { ReactComponent as PeopleCommunity } from 'icons/people-community.svg'
import { ReactComponent as PeopleSettings } from 'icons/people-settings.svg'
import { AppointedTeller, TellerType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { applyPhoneMask } from 'utils'
import style from './index.module.scss'

type ExtendedAppointedTeller = AppointedTeller & {
    stationId?: string
}

function AppointedTellers() {
    const { t } = useTranslation('teller')
    const { toString } = useTellerTypeMap()
    const { electionId } = useParams()
    const { auth } = useAuth()
    const navigate = useNavigate()
    const { copy, contextHolder } = useCopyToClipboardWithToast()
    const { ability } = useAbility()
    const { data: election } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: summary } = useElectionSummary(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)

    const [appointedTellers, setAppointedTellers] = useState<ExtendedAppointedTeller[]>([])
    const { data: appointedTellersData } = useAppointedTellers(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: tellingStations } = useElectionTellingStations(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: voteCountingStarted } = useVoteCountingStarted(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: electionResultSummary } = useElectionSummary(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)

    useEffect(() => {
        if (appointedTellersData?.length) {
            const sorted = Array.from(appointedTellersData).sort((a, b) => {
                if (a.role === TellerType.CHIEF_TELLER)
                    return -1
                if (b.role === TellerType.CHIEF_TELLER)
                    return 1

                if (a.role === TellerType.CHIEF_TELLER_ASSISTANT && b.role !== TellerType.CHIEF_TELLER_ASSISTANT)
                    return -1
                if (b.role === TellerType.CHIEF_TELLER_ASSISTANT && a.role !== TellerType.CHIEF_TELLER_ASSISTANT)
                    return 1

                if (a.name.toUpperCase() < b.name.toUpperCase())
                    return -1
                if (a.name.toUpperCase() > b.name.toUpperCase())
                    return 1

                return 0
            })
            setAppointedTellers(sorted.map(teller => {
                const stationId = tellingStations?.find(s => s.tellers?.find(t => t.id === teller.id))?.id

                return { ...teller, stationId }
            }))
        } else {
            setAppointedTellers([])
        }
    },
        [tellingStations, appointedTellersData])

    const columns: ColumnsType<ExtendedAppointedTeller> = [
        {
            title: t('common:position'),
            dataIndex: 'role',
            render: (role: TellerType) => <>{toString(role)}</>,
            width: 199
        },
        {
            title: t('common:bahai_id'),
            width: 105,
            render: (teller: ExtendedAppointedTeller) => ability?.can('copy', 'BahaiIdToClipboard')
                ? <div
                    className={style.pointer}
                    onClick={()=>copy(teller.id)}
                >
                    {teller.id}
                </div>
                : teller.id
        },
        {
            title: t('common:full_name'),
            dataIndex: 'name',
            width: 420,
            render: name => <div style={{ maxWidth: 396 }}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {name}
                </OptionalTooltip>
            </div>
        },
        {
            title: t('common:phone'),
            dataIndex: 'phone',
            width: 160,
            render: phone => phone ?
                <div className={style.pointer} onClick={() => copy(phone)}>
                    {applyPhoneMask(phone)}
                </div>
                : String.fromCharCode(8212)
        },
        {
            title: t('common:email'),
            dataIndex: 'email',
            width: 245,
            render: email => email ?
                <div className={style.pointer} onClick={() => copy(email)}
                    style={{ maxWidth: 221 }}>
                    <OptionalTooltip contenWrapperClassName="ellipsis">
                        {email}
                    </OptionalTooltip>
                </div>
                : String.fromCharCode(8212)
        },
        {
            title: t('telling_station'),
            dataIndex: 'stationId',
            width: 135,
            render: stationId => election?.bypassFlow ? String.fromCharCode(8212)
                : stationId
                    ? t('station_n', { n: stationId.replace('S', '') })
                    : String.fromCharCode(8212)
        }
    ]

    const caption = (
        <div className={style.caption}>
            <div className={style.titleWrapper}>
                <div>{t('tellers')}</div>
                <div className={style.actions}>
                    {!election?.bypassFlow
                        && !summary?.mergedBy
                        && <Can I="edit" a="Stations">
                            <button className={`${style.button} btn-main-tertiary-md`}
                                onClick={() => navigate(`/election-stations/${electionId || auth?.electionId}`,
                                    ability?.can('redirect', 'ElectionBoard')
                                        ? {
                                            state: { backButtonTitle: t('election:back_to_election_board') }
                                        }
                                        : undefined)}>
                                <PeopleCommunity width="20" height="20" />
                                {tellingStations?.length
                                    ? t('station:manage_telling_station_s')
                                    : t('set_up_telling_station_s')}
                            </button>
                        </Can>}
                    <Can I="edit-before-vote-counting" a="Tellers">
                        {!electionResultSummary?.mergedBy && !electionResultSummary?.confirmedBy &&
                            <button className={`${style.button} btn-main-tertiary-md`}
                                onClick={() => navigate(`/election-team/${electionId || auth?.electionId}`,
                                    ability?.can('redirect', 'ElectionBoard')
                                        ? {
                                            state: { backButtonTitle: t('election:back_to_election_board') }
                                        }
                                        : undefined)}>
                                <PeopleSettings width="20" height="20" />
                                {appointedTellers?.length ? t('manage_tellers') : t('set_up_tellers')}
                            </button>
                        }
                    </Can>
                    <Can not I="edit-before-vote-counting" a="Tellers">
                        {!electionResultSummary?.mergedBy
                            && !electionResultSummary?.confirmedBy
                            && voteCountingStarted &&
                            <button className={`${style.button} btn-main-tertiary-md`}
                                onClick={() => navigate(`/election-team/${electionId || auth?.electionId}`,
                                    ability?.can('redirect', 'ElectionBoard')
                                        ? {
                                            state: { backButtonTitle: t('election:back_to_election_board') }
                                        }
                                        : undefined)}>
                                <PeopleSettings width="20" height="20" />
                                {t('manage_tellers')}
                            </button>
                        }
                    </Can>
                </div>
            </div>
        </div>
    )

    return (
        <>
            {appointedTellers?.length
                ? <Table
                    obsSize="small"
                    obsHeaderSize="small"
                    headerDividerVisible={true}
                    dataSource={appointedTellers}
                    columns={columns}
                    pagination={false}
                    caption={
                        caption
                    }
                    rootClassName={`${style.table}`}
                    rowKey="id" />
                : <Card noContentPadding noHeaderLine title={caption} />
            }
            {contextHolder}
        </>
    )
}

export default AppointedTellers