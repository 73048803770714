import { Election, ElectionType } from 'models'
import React, { useMemo } from 'react'
import ElectionsPeriod from '../ElectionsPeriod'

type ElectionPeriodsProps = {
    elections: Election[]
}

const ElectionPeriods: React.FC<ElectionPeriodsProps> = ({ elections }) => {
    const notLdeElections = useMemo(() => {
        if (!elections) return []

        return elections.filter(
            (election: Election) => (election.type !== ElectionType.LOCAL_DELEGATE_ELECTION)
        )
    }, [elections])

    const localDelegateElections = useMemo(() => {
        if (!elections) return []

        const allLocalDelegateUnits = elections.filter(
            (election: Election) => (election.type === ElectionType.LOCAL_DELEGATE_ELECTION))

        const allElectionsPeriodIds = allLocalDelegateUnits.map(election => election.electionPeriod)
        const uniqueElectionsPeriodIds = allElectionsPeriodIds.filter((item, index) => (
            allElectionsPeriodIds.indexOf(item) === index)
        )

        return uniqueElectionsPeriodIds.map(periodId => allLocalDelegateUnits.filter(
            unit => unit.electionPeriod === periodId
        ))
    }, [elections])

    return (
        <>
            {!!notLdeElections.length && notLdeElections.map(election => (
                <ElectionsPeriod
                    key={election.electionPeriod}
                    election={election}
                />
            ))}
            {!!localDelegateElections.length && localDelegateElections.map(
                (localDelegatePeriodUnits, index) => (
                    <ElectionsPeriod
                        key={index}
                        localDelegatePeriodUnits={localDelegatePeriodUnits}
                    />
                )
            )}
        </>
    )
}

export default ElectionPeriods