
import { Col, Form, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useOnlineVoterDashboardWording } from 'api'
import Card from 'components/atoms/Card'
import TextArea from 'components/atoms/TextArea'
import Preloader from 'components/molecules/Preloader'
import RichTextEditor from 'components/molecules/RichTextEditor'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { richTextEditorEmptyValue } from 'utils'
import { useSaveVoterDashboardWording } from '../../api/mutations'
import { useAnyFormChanged } from '../../hooks'
import ConfigWrapper from '../ConfigWrapper'
import style from './index.module.scss'

const MAX_TITLE_LENGTH = 100

function VoterDashboard() {
    const { t } = useTranslation('configuration')
    const [form] = useForm()
    const { data: onlineVoterDashboardWording, isLoading } = useOnlineVoterDashboardWording()
    const { mutateAsync: saveVoterDashboardWording } = useSaveVoterDashboardWording()
    const { isAnyFormChanged, setIsAnyFormChanged } = useAnyFormChanged()

    useEffect(() => {
        if (onlineVoterDashboardWording) {
            form.setFieldsValue({
                serviceTextTitle: onlineVoterDashboardWording.title,
                serviceTextBody: onlineVoterDashboardWording.body
            })
        }
    }, [onlineVoterDashboardWording, form])

    const saveChanges = async () => {
        const values = await form.validateFields()
        await saveVoterDashboardWording({
            title: values.serviceTextTitle,
            body: values.serviceTextBody
        })
        setIsAnyFormChanged(false)
    }

    const cardTitle = (
        <div className={style.content}>
            <div className={style.label}>{t('online_voter_dashboard')}</div>
            <button className={`${style.commentButton} btn-main-primary`}
                type="button"
                disabled={!isAnyFormChanged}
                onClick={saveChanges}>
                {t('save_changes')}
            </button>
        </div>
    )

    const handleFormChange = () => {
        setIsAnyFormChanged(true)
    }

    if (isLoading) {
        return <Preloader />
    }

    return (
        <Card
            title={cardTitle}
            noContentPadding={true}
        >
            <ConfigWrapper>
                <Form form={form} layout="vertical" onValuesChange={handleFormChange}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name={'serviceTextTitle'}
                                label={t('title')}
                                rules={[{
                                    required: true,
                                    message: t('please_enter_the_input', { input: t('title') })
                                }]}
                            >
                                <TextArea
                                    placeholder={t('please_enter_the_input', { input: t('title') })}
                                    showCount={true}
                                    maxLength={MAX_TITLE_LENGTH}
                                    autoSize={{ minRows: 1, maxRows: 1 }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={'serviceTextBody'}
                                label={t('body')}
                                rules={[{
                                    required: true,
                                    validator: () => {
                                        if (form.getFieldValue('serviceTextBody') === richTextEditorEmptyValue)
                                            return Promise.reject()

                                        return Promise.resolve()
                                    },
                                    message: t('please_enter_the_input', { input: t('body') })
                                }]}
                            >
                                <RichTextEditor height={200}
                                    placeholder={t('please_enter_the_input', { input: t('body') })}
                                    errorMessage={t('please_enter_the_input', { input: t('body') })}
                                    form={form}
                                    fieldName="serviceTextBody"
                                    initValue={onlineVoterDashboardWording?.body}
                                />
                            </Form.Item>
                        </Col>
                    </Row >
                </Form>
            </ConfigWrapper>
        </Card>
    )
}

export default VoterDashboard