import { Select as AntSelect, Form } from 'antd'
import { Select } from 'components/atoms/Select'
import MultiSelect from 'components/molecules/MultiSelect'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { useLsaOfficerRoleMap, useReasonForVacancyMap } from 'hooks'
import { LsaOfficerRole, ReasonForVacancy } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useOfficers } from '../../hooks'
import { ByElectionOfficer } from '../../models'
import style from './index.module.scss'

type NoLongerServingLsaOfficerProps = {
    index: number
    officer: ByElectionOfficer
}

function NoLongerServingLsaOfficer({ index, officer }: NoLongerServingLsaOfficerProps) {
    const { t } = useTranslation('election')
    const { setOfficers } = useOfficers()
    const { toString: reasonToString } = useReasonForVacancyMap()
    const { toString: roleToString } = useLsaOfficerRoleMap()

    const reasons = useMemo(() => Object.values(ReasonForVacancy).map((r, index) =>
        <AntSelect.Option value={r} key={index} >
            {reasonToString(r)}
        </AntSelect.Option>), [reasonToString])

    const onRoleChange = (value: any) => {
        setOfficers(current => current.map(o => {
            if (o.id === officer.id)
                return { ...o, role: value }

            return { ...o }
        }))
    }

    const onReasonChange = (value: any) => {
       setOfficers(current => current.map(o => {
            if (o.id === officer.id)
                return { ...o, reasonForVacancy: value }

            return { ...o }
        }))
    }

    return (
        <div className={style.officer} >
            <div className={style.index}>{index + 1}</div>
            <div className={style.officerInfo}>
                <div className={style.name}>
                    <OptionalTooltip contenWrapperClassName="ellipsis">
                        {`${officer.name} (${t('bid_id', { id: officer.id })})`}
                    </OptionalTooltip>
                </div>
                <div className={style.form}>
                    <div className={style.roles}>
                        <div className={style.label}>{t('office_held_if_any')}</div>
                        <MultiSelect
                            popupClassName="small"
                            style={{ width: 279 }}
                            value={officer.role}
                            filterable={false}
                            onChange={onRoleChange}
                            options={Object.values(LsaOfficerRole).filter(r => r !== LsaOfficerRole.MEMBER).map(r => ({
                                label: roleToString(r),
                                value: r
                            }))}
                            includeAll={false}
                            placeholder={t('select_any')}
                        />
                    </div>
                    <Form.Item
                        className={`no-padding ${style.reason}`}
                        name={[index, 'reasonForVacancy']}
                        label={t('reason_for_vacancy')}
                        rules={[{
                            required: true,
                            message: t('common:please_select_the_input', { input: t('reason_for_vacancy') })
                        }]}
                    >
                        <Select
                            onChange={onReasonChange}
                            placeholder={t('common:select_input', { input: t('reason') })}
                        >
                            {reasons}
                        </Select>
                    </Form.Item>
                </div>
            </div>
        </div >
    )
}

export default NoLongerServingLsaOfficer