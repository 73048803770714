import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export const useVotingSessionStep = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getVotingSessionStep = async () => {
        const response = await axios<string>(moduleUrl
            + `draft/officers-elections/elections/${electionId}/current-step`)

        return response.data
    }

    return useQuery({
        queryKey: ['election-voting-session-step', electionId],
        queryFn: getVotingSessionStep,
        enabled
    })
}