import { Col, Form, Row } from 'antd'
import DatePicker from 'components/atoms/DatePicker'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type SelectTimeTimeProps = {
    formProperty: string
    sectionTitle: ReactNode
    inputNote: string
}

function SelectTime({
    formProperty,
    sectionTitle,
    inputNote
}: SelectTimeTimeProps) {
    const { t } = useTranslation('configuration')

    return (
        <Row gutter={16}>
            <Col span={6}>
                {sectionTitle}
            </Col>
            <Col span={18}>
                <div className={style.inputAndLabel}>
                    <Form.Item
                        className={style.input}
                        name={formProperty}
                        label={t('time')}
                        rules={[{
                            required: true,
                            message: t('please_select_the_input', { input: t('time') })
                        }]}
                        validateTrigger={['onChange']}
                    >
                        <DatePicker
                            placeholder={t('common:select_input', { input: t('time') })}
                            inputReadOnly
                            changeOnBlur
                            format="hh:mm A"
                            picker="time"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <div className={style.label}>
                        {inputNote}
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default SelectTime
