import { Select as AntSelect } from 'antd'
import { Select } from 'components/atoms/Select'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useBahaiYears } from '../../../../api'
import { useOfficersElectionsData } from '../../hooks' 
import style from './index.module.scss'

function OfficersElectionsTitle() { 

    const { t } = useTranslation('administration')
    const { data: bahaiYears } = useBahaiYears()
    const { officersElectionsData, setOfficersElectionsData } = useOfficersElectionsData()

    useEffect(() => {
        if (!officersElectionsData?.badiYear && bahaiYears?.length) {
            const fromStorage = localStorage.getItem('admin_dbd_officers_year')
            const yearFromStorage = parseInt(fromStorage || '', 10)
            if (!isNaN(yearFromStorage) && !!bahaiYears.find(b => b?.badiYear === yearFromStorage)) {
                setOfficersElectionsData((current) => ({ ...current, badiYear: yearFromStorage }))
            } 
            else {
                setOfficersElectionsData((current) => ({ ...current, badiYear: bahaiYears[0].badiYear }))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bahaiYears])

    return (
        <div className={style.content}>
            <div>
                <div className={style.label}>{t('officers_elections')}</div>
                <div className={style.labelText}>{t('officer_s_elections_data_below_isn_t_pushed')}</div>
            </div> 
            {bahaiYears &&
                            <Select
                                value={officersElectionsData?.badiYear}
                                onChange={(value: any) => {
                                    setOfficersElectionsData((current) =>
                                        ({ ...current, badiYear: value }))
                                    localStorage.setItem('admin_dbd_officers_year', value)
                                }}
                                className={`${style.select} small`}>
                                {bahaiYears.map(y => <AntSelect.Option key={y.years} value={y.badiYear} >
                                    {t('common:year_b_e_period',
                                        {
                                            year: y.badiYear,
                                            period: y.years
                                        })
                                    }
                                </AntSelect.Option>)}

                            </Select>
                        }
        </div>
    )
}

export default OfficersElectionsTitle