import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionType, ElectionWordingConfiguration } from 'models'
import { getElectionTypeForUrl } from 'utils'

export const useElectionWordingConfiguration = (electionType: ElectionType,
    isSecondStage?: boolean, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Toolkit)

    const getElectionWordingConfiguration = async ({ queryKey }: QueryFunctionContext<[string, ElectionType]>)
        : Promise<ElectionWordingConfiguration> => {
        const [, currentElectionType] = queryKey
        const electionTypeUrl = getElectionTypeForUrl(currentElectionType, isSecondStage)

        const response = await axios.get<ElectionWordingConfiguration>(
            `${moduleUrl}ui/${electionTypeUrl}/ui-wording`
        )

        return response.data
    }

    return useQuery({
        queryKey: ['election-wording-config', electionType],
        queryFn: getElectionWordingConfiguration,
        enabled
    })
}