import { PropsWithChildren } from 'react'
import styles from './auth-page-wrapper.module.scss'

function AuthPageWrapper({ children }: PropsWithChildren) {

    return (
        <div className={styles.wrapper}>
            {children}
        </div>
    )
}

export default AuthPageWrapper