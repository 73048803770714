import { ballotingAssets } from 'features/ballotingAssets'
import { ReactNode } from 'react'
import EnterBallotsProvider from '../../providers/EnterBallotsProvider'
import BallotEditor from '../BallotEditor'
import EnteredBallots from '../EnteredBallots'
import RealTimeResults from '../RealTimeResults'
import style from './index.module.scss'

type EnterBallotsProps = {
    tutorial?: ReactNode
    enteredBallotsTableOffset?: string
}

function EnterBallots({ tutorial, enteredBallotsTableOffset }: EnterBallotsProps) {
    return (
        <EnterBallotsProvider>
            <div className={style.wrapper}>
                <div className={style.ballotEditing} >
                    <ballotingAssets.providers.BallotVotesProvider>
                        <BallotEditor hideBallotIdTutorial={!!tutorial} />
                        <RealTimeResults />
                    </ballotingAssets.providers.BallotVotesProvider>
                </div>
                <EnteredBallots enteredBallotsTableOffset={enteredBallotsTableOffset} />
                {tutorial}
            </div>
        </EnterBallotsProvider>
    )
}

export default EnterBallots