import { useAvailableElections, useLocalities, useLocality, useOfficersElections } from 'api'
import { useAccessToken, useAuth, useChangeUser, useElectionTypeMap } from 'hooks'
import jwt_decode from 'jwt-decode'
import { ElectionStatusType, ElectionType, Role } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useChiefTellerElections, useMyDetails } from '../api'
import { AvailableRoles, TellerElection } from '../models'

export const useAvailableRoles = (enabled?: boolean) => {
    const { t } = useTranslation('auth')
    const { toString } = useElectionTypeMap()
    const { auth } = useAuth()
    const { changeAuth } = useChangeUser()
    const [officerElectionLocalityCode, setOfficerElectionLocalityCode] = useState('')
    const [availableRoles, setAvailableRoles] = useState<AvailableRoles>({
        isVoter: true,
        chiefTellerElections: [],
        tokenChecked: false,
        ctElectionsChecked: false
    })
    const { accessToken } = useAccessToken()
    const { data: details } = useMyDetails(enabled)
    const { data: ctElections } = useChiefTellerElections(enabled)
    const ctElectionsLocalityCodes = ctElections?.filter(e => !!e.region.locality).map(e => e.region.locality)
    const { data: ctElectionsLocalities } = useLocalities(ctElectionsLocalityCodes!,
        !!ctElectionsLocalityCodes?.length && !!ctElections && enabled)
    const { data: availableElections } = useAvailableElections(enabled)
    const { data: officersElections } = useOfficersElections(enabled)
    const { data: locality } = useLocality(officerElectionLocalityCode, !!officerElectionLocalityCode && enabled)

    useEffect(() => {
        const newRoles: AvailableRoles = {
            chiefTellerElections: [],
            tokenChecked: false,
            ctElectionsChecked: false
        }

        const updateLocality = (code: string) => {
            if (!!locality && code === locality.code) {
                newRoles.officerElectionTellerInfo = {
                    id: -1,
                    name: locality.name
                }
                newRoles.officerElectionTellerRoleChecked = true
            } else {
                setOfficerElectionLocalityCode(code)
            }
        }

        if (((!!ctElections && (!!ctElectionsLocalities || !ctElectionsLocalityCodes?.length))) || !enabled) {
            newRoles.ctElectionsChecked = true
            const activeElections = ctElections?.filter(e => e.status !== ElectionStatusType.NOT_STARTED
                && e.status !== ElectionStatusType.DRAFT) || []
            if (activeElections.length) {
                activeElections.forEach(e => newRoles.chiefTellerElections.push({ id: e.id, name: getElectionName(e) }))
            }
            if (accessToken) {
                newRoles.tokenChecked = true
                const decoded = jwt_decode<{ rol: any[] }>(accessToken)
                if (decoded?.rol?.length) {
                    newRoles.isLsaOfficer = decoded.rol.includes(Role.LSA_OFFICER)
                    newRoles.isNsaAdmin = decoded.rol.includes(Role.NSA_ADMIN)
                    newRoles.isSystemAdmin = decoded.rol.includes(Role.SYSTEM_ADMIN)
                }
            }
            newRoles.isVoter = !newRoles.isSystemAdmin
            if (officersElections?.length) {
                updateLocality(officersElections[0].region?.locality)
            }
            if (!!availableElections?.length && details) {
                if (!!availableElections?.find(e => e.electionType === ElectionType.OFFICERS_ELECTION)) {
                    updateLocality(details.homeLocality?.code)
                }
            }

            if (officersElections && !officersElections.length
                && availableElections
                && !availableElections.find(e => e.electionType === ElectionType.OFFICERS_ELECTION)) {
                newRoles.officerElectionTellerRoleChecked = true
            }

            setAvailableRoles({ ...newRoles })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details, ctElections, accessToken, ctElectionsLocalities, availableElections, officersElections])

    useEffect(() => {
        if (locality) {
            setAvailableRoles(current => ({
                ...current,
                officerElectionTellerInfo: {
                    id: -1,
                    name: locality.name
                },
                officerElectionTellerRoleChecked: true
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locality])

    useEffect(() => {
        if (details && !auth?.id) {
            changeAuth(auth
                ? {
                    ...auth,
                    homeLocality: details.homeLocality, id: details.id, name: details.name
                }
                : { homeLocality: details.homeLocality, id: details.id, name: details.name }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details, auth])

    const isVoterOnly = (availableRoles: AvailableRoles) => !availableRoles.chiefTellerElections?.length
        && !availableRoles.isNsaAdmin
        && !availableRoles.isLsaOfficer
        && !availableRoles.officerElectionTellerInfo

    const hasDesktopOnlyRoles = (availableRoles: AvailableRoles) => !!availableRoles.chiefTellerElections?.length
        || availableRoles.isNsaAdmin
        || availableRoles.isLsaOfficer

    const isSystemAdmin = (availableRoles: AvailableRoles) => availableRoles.isSystemAdmin


    const getElectionName = (election: TellerElection) => {

        const electionLocality = ctElectionsLocalities?.find(l => l.code === election.region.locality)

        const getInstitutionName = () => {
            switch (election.type) {
                case ElectionType.RIDVAN_ELECTION:
                case ElectionType.BY_ELECTION:
                    return electionLocality?.name || ''
                case ElectionType.LOCAL_DELEGATE_ELECTION:
                    return `${electionLocality?.name || ''}, ${election.region.localUnit}`
                case ElectionType.NATIONAL_DELEGATE_ELECTION:
                    return `${election.region.name}${election.region.unit ? ', ' + election.region.unit : ''}`
            }

            return 'Unknown Institution'
        }

        const result = t('access_year_b_e_election_locality', {
            year: election.ballotingYear,
            election: toString(election.type),
            locality: getInstitutionName(),
            interpolation: { escapeValue: false }
        })

        return result
    }

    return { availableRoles, isVoterOnly, isSystemAdmin, hasDesktopOnlyRoles }
}