import { MenuProps } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useElectionStations, useElectionSummary } from 'api'
import { Can } from 'components/atoms/Can'
import IconWrapper from 'components/atoms/IconWrapper'
import Dropdown from 'components/molecules/Dropdown'
import Table from 'components/molecules/Table'
import { useAuth } from 'hooks'
import { ReactComponent as Dots } from 'icons/dots.svg'
import { ReactComponent as Reset } from 'icons/reset.svg'
import { TellingStation } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { resetStationEntries } from '../../features/resetStationEntries'
import BallotsEntering from '../StationTaskProgress/ballots-entering'
import Reporting from '../StationTaskProgress/reporting'
import VoterVerification from '../StationTaskProgress/voter-verifications'
import style from './index.module.scss'

function StationsProgress() {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const { electionId } = useParams()
    const { data: electionStations } = useElectionStations(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const [stations, setStations] = useState<TellingStation[]>([])
    const { popupPortal, startResetProcess } = resetStationEntries.hooks.useResetStationEntriesProcess()
    const { data: summary } = useElectionSummary(electionId || '', !!electionId)

    useEffect(() => {
        if (electionStations?.length) {
            setStations(Array.from(electionStations).sort((a, b) => {
                const aId = parseInt(a.id.replace('S', ''), 10)
                const bId = parseInt(b.id.replace('S', ''), 10)

                return aId - bId
            }))
        } else {
            setStations([])
        }
    },
        [electionStations])

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <div className={style.option}>
                <IconWrapper>
                    <Reset className={style.icon} />
                </IconWrapper>
                {t('reset_entry')}</div>,
            onClick: () => startResetProcess()
        }
    ]

    const columns: ColumnsType<TellingStation> = [
        {
            title: t('#_station'),
            render: (station: TellingStation) => <>{t('station_n', { n: station.id.replace('S', '') })}</>,
            width: 125
        },
        {
            title: t('voter_verification'),
            width: 165,
            render: (station: TellingStation) => <VoterVerification station={station} />
        },
        {
            title: t('ballots_entering'),
            width: 165,
            render: (station: TellingStation) => <BallotsEntering station={station} />
        },
        {
            title: t('reporting'),
            width: 165,
            render: (station: TellingStation) => <Reporting station={station} />
        }
    ]

    return (
        <>
            {popupPortal}
            <Table
                obsSize="small"
                obsHeaderSize="small"
                headerDividerVisible={true}
                dataSource={stations}
                columns={columns}
                pagination={false}
                caption={
                    <div className={style.caption}>
                        <div className={style.textWrapper}>
                            <div className={style.text}>{t('station_tasks_progress')}</div>
                        </div>
                        {!summary?.mergedBy &&
                            <Can I="perform" a="StationBallotsReset">
                                <Dropdown menu={{ items }} openClassName={style.active}
                                    trigger={['click']}
                                >
                                    <button className={`btn-main-tertiary-md ${style.actions}`}>
                                        <IconWrapper>
                                            <Dots />
                                        </IconWrapper>
                                    </button>
                                </Dropdown>
                            </Can>}
                    </div>
                }
                rootClassName={style.table}
                rowKey="id" />
        </>
    )
}

export default StationsProgress