import { Dropdown as AntDropdown } from 'antd'

type DropdownProps = React.ComponentProps<typeof AntDropdown>

function Dropdown(props: DropdownProps) {
    return (
        <AntDropdown {...props}
            openClassName={`obs ${props.openClassName || ''}`}
            rootClassName={`obs ${props.rootClassName || ''}`}
            className={`obs ${props.className || ''}`} />
    )
}

export default Dropdown