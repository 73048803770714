import Header from 'components/organisms/Header'
import NoAccess from 'components/organisms/NoAccess'
import PageNotFound from 'components/organisms/PageNotFound'
import SomethingWentWrong from 'components/organisms/SomethingWentWrong'
import AdaptiveLayout from 'components/templates/AdaptiveLayout'



export const errorHandlingRoutesChildren = [
    {
        path: '/access-denied',
        element: <NoAccess />
    },
    {
        path: '/page-not-found',
        element: <PageNotFound />
    },
    {
        path: '/oops',
        element: <SomethingWentWrong />
    }
]

export const errorHandlingRoutes = {
    element: <AdaptiveLayout useSupportedWidth={true} header={< Header />} />,
    children: errorHandlingRoutesChildren
}