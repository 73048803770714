import MergedResultsTable from './components/MergedResultsTable'
import MergedResultsWrapper from './pages/MergedResultsWrapper'
import RoundResultsInfo from './pages/RoundResultsInfo'
import TiePhysicalBallots from './pages/TiePhysicalBallots'
import MergedResultsProvider from './provider/MergedResultsProvider'

export const mergedResults = {
    pages: {
        MergedResultsWrapper,
        TiePhysicalBallots,
        RoundResultsInfo
    },
    components: {
        MergedResultsTable
    },
    providers: {
        MergedResultsProvider
    }
}