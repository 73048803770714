import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export type StationInvalidateBallotParams = {
    id: string
    invalidationReason: string
}
export function useStationInvalidateBallot() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    async function stationInvalidateBallot({ id, invalidationReason }: StationInvalidateBallotParams) {
        return await axios(moduleUrl
            + `stations/my/ballots/${id}/invalidate`,
            {
                method: 'post',
                data: {
                    invalidationReason
                }
            })
    }

    return useMutation({
        mutationFn: stationInvalidateBallot
    })
}