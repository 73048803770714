import Input from 'components/atoms/Input'
import FilterableHeaderCell from 'components/molecules/FilterableHeaderCell'
import MultiSelect from 'components/molecules/MultiSelect'
import SortableHeaderCell from 'components/molecules/SortableHeaderCell'
import { useFilterable, useSortable } from 'hooks'
import { ElectionPushStatus, ElectionStatusType, ElectionType, SortOrder } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLsaElectionsData } from '../../hooks'
import { LsaElectionsFilter } from '../../model'
import { filterElections } from '../../utils'
import style from './index.module.scss'

const DEFAULT_FILTER: LsaElectionsFilter = {
    sortBy: {
        field: 'localityName',
        order: SortOrder.ASC
    },
    electionStatus: [],
    electionPushStatus: []
}

type ElectionHeaderProps = {
    electionType: ElectionType
}

function ElectionsHeader({ electionType }: ElectionHeaderProps) {
    const { t } = useTranslation('administration')
    const { changeFilter } = useFilterable<LsaElectionsFilter>()
    const { changeSortBy } = useSortable()

    const { lsaElectionsData, setLsaElectionsData } = useLsaElectionsData()

    const [filter, setFilter] = useState<LsaElectionsFilter>({ ...DEFAULT_FILTER })

    useEffect(() => {
        const filterFromStorage = localStorage.getItem(`admin_dbd_filters_${electionType}`)
        if (filterFromStorage) {
            setFilter(JSON.parse(filterFromStorage))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateFilter = (newFilter: LsaElectionsFilter) => {
        localStorage.setItem(`admin_dbd_filters_${electionType}`, JSON.stringify(newFilter))
        setFilter(newFilter)
    }

    const allPushStatuses = [
        {
            value: ElectionPushStatus.UNKNOWN,
            label: t('unknown')
        },
        {
            value: ElectionPushStatus.NOT_SENT,
            label: t('not_sent')
        },
        {
            value: ElectionPushStatus.PENDING,
            label: t('pending')
        },
        {
            value: ElectionPushStatus.ACCEPTED,
            label: t('accepted')
        },
        {
            value: ElectionPushStatus.REJECTED,
            label: t('rejected')
        }
    ]

    const allStatuses = [
        {
            value: ElectionStatusType.NOT_STARTED,
            label: t('not_started')
        },
        {
            value: ElectionStatusType.IN_PROGRESS,
            label: t('in_progress')
        },
        {
            value: ElectionStatusType.COMPLETED,
            label: t('completed')
        }
    ]

    useEffect(() => {
        setLsaElectionsData(current => ({
            ...current,
            elections: current.allElections ? filterElections(current.allElections, filter) : []
        }))
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [lsaElectionsData.allElections, filter])

    const clearAllFilters = () => {
        updateFilter({ ...DEFAULT_FILTER })
    }

    const onSortingChange = (field: string, order?: SortOrder) =>
        updateFilter(changeSortBy(filter, field, order) as LsaElectionsFilter)

    return (
        <div className={style.header}>
            <div className={style.column}
                style={{ width: 135 }}
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Input
                            allowClear={true}
                            className="small"
                            value={filter.localityName}
                            style={{ width: 160 }}
                            onChange={(e) => updateFilter(changeFilter(filter, 'localityName', e.target.value))}
                            placeholder={t('search_by') + '...'}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('locality_name')}
                            field={'localityName'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div>
            <div className={style.column}
                style={{ width: 130 }}
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Input
                            allowClear={true}
                            className="small"
                            value={filter.localityCode}
                            style={{ width: 112 }}
                            onChange={(e) => updateFilter(changeFilter(filter, 'localityCode', e.target.value))}
                            placeholder={t('search_by') + '...'}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('locality_code')}
                            field={'localityCode'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div>
            <div className={style.column}
                style={{ width: 286 }}
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Input
                            allowClear={true}
                            className="small"
                            value={filter.contactPerson}
                            style={{ width: 276 }}
                            onChange={(e) => updateFilter(changeFilter(filter, 'contactPerson', e.target.value))}
                            placeholder={t('search_by_name_and_bahai_id')}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('lsa_contact_person')}
                            field={'contactPerson'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div>
            <div className={style.column}
                style={{ width: 110 }}
            >
                < FilterableHeaderCell
                    size="small"
                    filter={<></>}
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('start_date')}
                            field={'startDay'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div>
            <div className={style.column}
                style={{ width: 116 }}
            >
                < FilterableHeaderCell
                    size="small"
                    filter={<></>}
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('election_date')}
                            field={'electionDay'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div>
            <div className={style.column}
                style={{ width: 110 }}
            >
                <FilterableHeaderCell
                    size="small"
                    filter={<></>}
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('eligible_voters')}
                            field={'eligibleVoters'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div>
            <div className={`${style.column} ${style.noFilterColumn}`}
                style={{ width: 110 }}
            >
                <div className={style.noFilter}>
                    {t('participated_voters_online_paper_ballots')}
                </div>
            </div>
            <div className={style.column}
                style={{ width: 120 }}
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <MultiSelect
                            className="small"
                            popupClassName="small"
                            style={{ width: 102 }}
                            popupWidth={200}
                            value={filter.electionStatus}
                            onChange={(value) =>
                                updateFilter(changeFilter(filter, 'electionStatus', value as string[]))}
                            options={allStatuses}
                            placeholder={t('all')}
                        />
                    }
                    title={t('election_status')}
                />
            </div>
            <div className={style.column}
                style={{ width: 120 }}
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <MultiSelect
                            className="small"
                            popupClassName="small"
                            style={{ width: 102 }}
                            popupWidth={200}
                            value={filter.electionPushStatus}
                            onChange={(value) =>
                                updateFilter(changeFilter(filter, 'electionPushStatus', value as string[]))}
                            options={allPushStatuses}
                            placeholder={t('all')}
                        />
                    }
                    title={t('election_push_status')}
                />
            </div>
            <div className={style.column}
                style={{ width: 97 }}
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <button
                            className={`btn-main-tertiary-md ${style.clearFilters}`}
                            onClick={clearAllFilters}
                        >
                            {t('clear_all_fillters')}
                        </button>
                    }
                    title={t('reports')}
                />
            </div>
        </div>
    )
}

export default ElectionsHeader