import { ReactComponent as LogoBigSandbox } from 'icons/logo-big-sandbox.svg'
import style from './index.module.scss'

type SandboxLogoProps = {
    reversedColors?: boolean
}

function SandboxLogo({ reversedColors }: SandboxLogoProps) {
    return (
        <div className={`${style.sandboxLogo} ${reversedColors ? style.reversedColors : ''}`}>
            <LogoBigSandbox height="45px" width="332px" className={style.icon} />
        </div>)
}

export default SandboxLogo