import { PropsWithChildren, useState } from 'react'
import { EligibleIndividualsTableDataContext } from '../context'
import { EligibleIndividualsTableData } from '../models'

function EligibleIndividualsTableDataProvider({ children }: PropsWithChildren) {
    const [tableData, setTableData] = useState<EligibleIndividualsTableData>({
        participants: [],
        statistics: {}
    })

    return (
        <EligibleIndividualsTableDataContext.Provider value={{ tableData, setTableData }} >
            {children}
        </EligibleIndividualsTableDataContext.Provider>
    )
}

export default EligibleIndividualsTableDataProvider