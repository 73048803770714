import ContentWrapper from 'components/atoms/ContentWrapper'
import BackToDashboardLine from 'components/molecules/BackToDashboardLine'
import { useAuth, useBackToDashboardLine } from 'hooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import TiePhysicalBallotsManagement from '../components/TiePhysicalBallotsManagement'
import MergedResultsProvider from '../provider/MergedResultsProvider'

function TiePhysicalBallots() {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const navigate = useNavigate()
    const { setBackToDashboardLine } = useBackToDashboardLine()

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine
                sticky
                fixedWidth
                backButtonTitle={t('back_to_merged_results')}
                onBackToDashboardBtnClick={() => { navigate(`/merged-results/${auth?.electionId}`) }} />)

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ContentWrapper>
            <MergedResultsProvider>
                <TiePhysicalBallotsManagement />
            </MergedResultsProvider>
        </ContentWrapper>
    )
}

export default TiePhysicalBallots