import ErrorPage from 'components/organisms/ErrorAlert'
import { ReactComponent as AccessDenied } from 'icons/access-denied.svg'
import { useTranslation } from 'react-i18next'

function NoAccess() {
    const { t } = useTranslation()

    return (
        <ErrorPage
            title={t('access_denied')}
            image={<AccessDenied />}
            text={
                <div>
                    {t('you_do_not_have_permission_to_access')}<br />
                    {t('please_contact_support_for_more_information')}
                </div>
            }
            footer
        />
    )
}

export default NoAccess