import { Select as AntSelect, Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { ColumnsType } from 'antd/es/table'
import PopupFooter from 'components/atoms/PopupFooter'
import PopupTitle from 'components/atoms/PopupTitle'
import { Select } from 'components/atoms/Select'
import Tooltip from 'components/atoms/Tooltip'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import Table from 'components/molecules/Table'
import {
    useAbility,
    useAuth,
    useHandleStationLogoutError,
    useInfoPopup,
    usePopup
} from 'hooks'
import { Ballot, Vote } from 'models'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
    useBallotInvalidationReasons,
    useChangeInvalidateBallot,
    useStationChangeInvalidateBallot,
    useVoteInvalidationReasons
} from '../../api'
import { useBallotActions } from '../../hooks'
import DeleteBallot from '../DeleteBallot'
import style from './index.module.scss'

export type BallotEntryViewProps = {
    electionId: string,
    ballot: Ballot,
    onReasonUpdate: () => void,
    onEditBallotClick?: () => Promise<boolean>
    hideBallotEntryView?: () => void
}

function BallotEntryView({
    electionId,
    ballot,
    onReasonUpdate,
    onEditBallotClick,
    hideBallotEntryView
}: BallotEntryViewProps) {
    const { t } = useTranslation('ballot')
    const { auth } = useAuth()
    const { ability } = useAbility()
    const { data: voteInvalidationReasons } = useVoteInvalidationReasons(auth?.electionId!)
    const { data: ballotInvalidationReasons } = useBallotInvalidationReasons(auth?.electionId!)
    const { mutateAsync: stationChangeInvalidateBallot } = useStationChangeInvalidateBallot()
    const { mutateAsync: changeInvalidateBallot } = useChangeInvalidateBallot()
    const navigate = useNavigate()
    const { hide, show, popupPortal } = usePopup()
    const { deleteBallot } = useBallotActions()
    const { infoPortal, showInfo } = useInfoPopup()
    const { handleError } = useHandleStationLogoutError()

    const [form] = useForm()

    const nameClassName = (vote: Vote) => (vote.invalidationReason)
        ? `${style.customСolor} custom-cell-color`
        : ''

    useEffect(() => {
        form.setFieldsValue({ reason: ballot.invalidationReason })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ballot])

    const showDeleteBallotPopup = () => {
        show({
            title: <PopupTitle>{`${t('delete_ballot')} ${ballot.id}`}</PopupTitle >,
            children: <DeleteBallot
                onCancel={() => hide()}
                onDelete={async (comment: string) => {
                    try {
                        await deleteBallot(comment, ballot.id)
                        hide()
                        hideBallotEntryView?.()
                        onReasonUpdate()
                    } catch (e) {
                        handleError(e, showInfo)

                        return
                    }
                }} />,
            footer: null
        })
    }

    const columns: ColumnsType<Vote> = [
        {
            title: t('common:full_name'),
            width: 386,
            render: (vote: Vote) => (
                <div style={{ maxWidth: vote.invalidationReason ? 470 : 354 }}
                    className={nameClassName(vote)}>
                    <OptionalTooltip contenWrapperClassName="ellipsis">
                        {vote.invalidationReason
                            ? voteInvalidationReasons?.find(r => r.id === vote.invalidationReason)?.text || ''
                            : vote?.candidate?.name}
                    </OptionalTooltip>
                </div>
            ),
            onCell: (vote: Vote) => ({
                colSpan: vote.invalidationReason ? 2 : 1
            })
        },
        {
            title: t('common:bahai_id'),
            width: 116,
            render: (vote: Vote) => (vote?.candidate?.id),
            onCell: (vote: Vote) => ({
                colSpan: vote.invalidationReason ? 0 : 1
            })
        },
        {
            title: t('valid_vote'),
            width: 126,
            render: (vote: Vote) => (
                <Tooltip title={vote.invalidationReason
                    ? t('invalid_vote') : t('valid_vote')}>
                    <div className={`${style.statusIndicator} ${vote.invalidationReason
                        ? style.invalid
                        : style.valid}`} />
                </Tooltip>
            )
        }
    ]

    return (
        <>
            {infoPortal}
            {popupPortal}
            {ballot.deleted && <>
                <div className={style.commentTitle}>
                    {t('common:comment')}
                </div>
                <div className={style.commentBody}>
                    {ballot.deletionComment}
                </div>
            </>}
            {!ballot.invalidationReason && !ballot.deleted
                && <Table
                    obsSize="small"
                    obsHeaderSize="small"
                    headerDividerVisible={true}
                    dataSource={ballot.votes}
                    columns={columns}
                    pagination={false}
                    rootClassName={style.table}
                    noOuterBorder
                    //TODO: If order is added it should be used as alternative key
                    rowKey={(vote: Vote) => vote?.candidate?.id || Math.random()}
                />}
            {!ballot.deleted && ballot.invalidationReason &&
                <Form
                    layout="vertical"
                    form={form}>
                    <Form.Item
                        name={['reason']}
                        className="no-padding"
                        label={t('ballot_is_invalid_due_to_the_following')}
                        rules={[{
                            required: true,
                            message: t('common:please_select_the_input', { input: t('reason') })
                        }
                        ]}
                    >
                        <Select
                            placeholder={t('common:select_the_input', { input: t('reason') })}
                        >
                            {ballotInvalidationReasons?.map(reason => <AntSelect.Option
                                value={reason.id} key={reason.id}>
                                {reason.text}</AntSelect.Option>)}
                        </Select>
                    </Form.Item>
                </Form>
            }
            <div className={style.footer}>
                <PopupFooter>
                    {!ballot.deleted
                        && <button className="btn-error-secondary"
                            onClick={showDeleteBallotPopup}>
                            {t('delete_ballot')}
                        </button>
                    }
                    {!ballot.deleted && !ballot.invalidationReason
                        && <button
                            type="submit"
                            onClick={async () => {
                                if (onEditBallotClick) {
                                    const canEdit = await onEditBallotClick()
                                    if (canEdit) {
                                        navigate(`/ballot/${ballot.id}`)
                                    }
                                } else {
                                    navigate(`/ballot/${ballot.id}`)
                                }
                            }
                            }
                            className={'btn-main-primary'}>
                            {t('edit_ballot')}
                        </button>
                    }
                    {!ballot.deleted && ballot.invalidationReason
                        && <button
                            type="submit"
                            onClick={async () => {
                                try {
                                    await form.validateFields()
                                    if (ability?.can('edit', 'StationBallot')) {
                                        await stationChangeInvalidateBallot({
                                            id: ballot.id, invalidationReason: form.getFieldsValue().reason
                                        })
                                    } else {
                                        await changeInvalidateBallot({
                                            id: ballot.id, electionId,
                                            stationId: ballot.id.split('-')[0],
                                            invalidationReason: form.getFieldsValue().reason
                                        })
                                    }
                                    onReasonUpdate()
                                } catch (e) {
                                    handleError(e, showInfo)

                                    return
                                }
                            }
                            }
                            className={'btn-main-primary'}>
                            {t('update_reason')}
                        </button>
                    }
                </PopupFooter>
            </div >
        </>
    )
}

export default BallotEntryView