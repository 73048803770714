import { PropsWithChildren, useState } from 'react'
import { EnterBallotsContext } from '../context'
import { EnterBallotsInfo } from '../models'

function EnterBallotsProvider({ children }: PropsWithChildren) {
    const [info, setInfo] = useState<EnterBallotsInfo>({})

    return (
        <EnterBallotsContext.Provider value={{ info, setInfo }} >
            {children}
        </EnterBallotsContext.Provider>
    )
}

export default EnterBallotsProvider