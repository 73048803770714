import { SortOrder } from 'models'
import { OfficersElection, OfficersElectionsFilter } from '../model'

export const filterElections = (allElections: OfficersElection[], filter: OfficersElectionsFilter) => {
    let elections = [...allElections]

    let sortingOrder = SortOrder.ASC
    let sortingField: keyof OfficersElectionsFilter = 'localityName'

    if (filter.sortBy) {
        sortingOrder = filter.sortBy.order
        sortingField = filter.sortBy.field as keyof OfficersElectionsFilter
    }

    elections = elections.sort((a: OfficersElection, b: OfficersElection) => {
        const conditions = sortingOrder === SortOrder.ASC
            ? [
                (p1: any, p2: any) => p1 > p2,
                (p1: any, p2: any) => p1 < p2
            ]
            : [
                (p1: any, p2: any) => p1 < p2,
                (p1: any, p2: any) => p1 > p2
            ]

        if (sortingField !== 'sortBy') {
            if (conditions[0](a[sortingField], b[sortingField])) {
                return 1
            }
            if (conditions[1](a[sortingField], b[sortingField])) {
                return -1
            }
        }

        if (sortingField !== 'localityName') {
            const field = 'localityName'
            if (a[field] > b[field]) {
                return 1
            }
            if (a[field] < b[field]) {
                return -1
            }
        }

        return 0
    })

    if (filter.localityName) {
        const names = filter.localityName!.trim().toLowerCase().split(' ').filter(n => !!n)
        names.forEach(name => {
            elections = elections.filter(e => e.localityName.toLowerCase().includes(name))
        })
    }

    if (filter.localityCode) {
        const code = filter.localityCode!.trim().toLowerCase()
        elections = elections.filter(e => e.localityCode.toLowerCase().includes(code))
    }

    return elections
}