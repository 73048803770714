import IconWrapper from 'components/atoms/IconWrapper'
import { ReactComponent as ArrowDownload } from 'icons/arrow-download.svg'
import { useTranslation } from 'react-i18next'

type DownloadInstructionButtonProps = {
    onClick?: () => void
}

function DownloadInstructionButton({ onClick }: DownloadInstructionButtonProps) {
    const { t } = useTranslation('telling-station-sandbox')

    return (
        <>
            <button type="button"
                onClick={() => onClick?.()}
                id="download-instruction-btn"
                className="btn-main-tertiary-md">
                <IconWrapper>
                    <ArrowDownload />
                </IconWrapper>
                {t('download_instructions')}
            </button>
        </>
    )
}

export default DownloadInstructionButton