import { PropsWithChildren, useState } from 'react'
import { ReportingContext } from '../context/ReportingContext'
import { ReportingData } from '../model'

function ReportingProvider({ children }: PropsWithChildren) {
    const [reportingData, setReportingData] = useState<ReportingData>({})

    return (
        <ReportingContext.Provider value={{ reportingData, setReportingData }}>
            {children}
        </ReportingContext.Provider >
    )
}

export default ReportingProvider