import IconWrapper from 'components/atoms/IconWrapper'
import Tabs from 'components/atoms/Tabs'
import Tooltip from 'components/atoms/Tooltip'
import { useAbility } from 'hooks'
import { ReactComponent as Gear } from 'icons/gear.svg'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { delegateElections } from '../../features/delegateElections'
import { lsaElections } from '../../features/lsaElections'
import { officersElections } from '../../features/officersElections'
import { reporting } from '../../features/reporting'
import Icons from '../Icons'
import style from './index.module.scss'

function AdministrationOptions() {
    const { t } = useTranslation('administration')
    const { ability } = useAbility()
    const navigate = useNavigate()

    const slot = useMemo(() => {
        if (ability?.can('edit', 'Configuration')) {
            return <Tooltip title={t('configuration')}>
                <button className={`btn-main-tertiary ${style.config}`}
                    onClick={() => { navigate('/config') }}>
                    <IconWrapper>
                        <Gear className="flip-horizontal" width="24" height="24" />
                    </IconWrapper>
                </button>
            </Tooltip>

        }

        return null
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ability])

    const tabs = useMemo(() => {
        const result = [
            // {
            //     key: 'rbc',
            //     label: t('rbc_elections'),
            //     children: 'Comming Soon...'
            // },
            {
                key: 'lsa',
                label: t('lsa_elections'),
                children: <lsaElections.components.LsaElections />
            },
            {
                key: 'officers',
                label: t('officers_elections'),
                children: <officersElections.components.Elections />
            },
            {
                key: 'delegate',
                label: t('delegate_elections'),
                children: <delegateElections.components.Elections />
            },
            {
                key: 'reporting',
                label: t('reporting'),
                children: <reporting.components.Reporting />
            }//,
            // {
            //     key: 'db',
            //     label: t('obs_database'),
            //     children: 'Comming Soon...'
            // }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        ]

        if (process.env.NODE_ENV === 'development') {
            result.push({
                key: 'icons',
                label: 'Icons',
                children: <Icons />
            })
        }

        return result
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Tabs
            defaultActiveKey={localStorage.getItem('admin_dbd_tab') || undefined}
            onChange={(tab) => localStorage.setItem('admin_dbd_tab', tab)}
            className={style.adminOptions}
            items={tabs}
            tabBarExtraContent={slot}
        />
    )
}

export default AdministrationOptions