import Footer from 'components/molecules/Footer'
import { ReactComponent as ChevronBold } from 'icons/chevron-bold.svg'
import { ReactComponent as ErrorPageIcon } from 'icons/error-page.svg'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import IconWrapper from '../../atoms/IconWrapper'
import style from './index.module.scss'

interface ErrorPageProps {
    errorTitle?: string
    title?: string
    image?: React.ReactNode
    text?: React.ReactNode
    footer?: boolean
}

const ErrorPage: React.FC<ErrorPageProps> = ({ title, image, text, footer }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const goBack = useCallback(() => navigate(-1), [navigate])
    const defaultTitle = t('page_not_found')
    const defaultImage = <ErrorPageIcon />
    const defaultText = t('the_requested_url_was_not_found')

    return (
        <div className={style.contentWrapper}>
            <div tabIndex={0} className={style.content}>
                <div className={style.title}>{title || defaultTitle}</div>
                <div className={style.image}>{image || defaultImage}</div>
                <div className={style.text}>{text || defaultText}</div>
                <button className={`btn-main-primary ${style.backBtn}`} onClick={goBack}>
                    <IconWrapper>
                        <ChevronBold className="flip-horizontal" width="16" height="16" />
                    </IconWrapper>
                    <span>{t('back')}</span>
                </button>
            </div>
            {footer && <Footer />}
        </div>
    )
}

export default ErrorPage
