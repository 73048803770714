import { ChildrenAndClassProps } from 'models'
import style from './panel-content.module.scss'


function PanelContent({ children, className }: ChildrenAndClassProps) {
    return (
        <div className={`${style.content} ${className || ''}}`}>
            {children}
        </div>
    )
}

export default PanelContent