import Empty from 'components/molecules/Empty'
import Scrollbars from 'react-custom-scrollbars-2'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { useVotingStatisticTableData } from '../../hooks'
import { VotingStatisticTableData } from '../../models'
import VotingStatisticHeader from '../VotingStatisticHeader'
import VotingStatisticItem from '../VotingStatisticItem'
import VotingStatisticTableStatistic from '../VotingStatisticTableStatistic'
import 'style/shared/full-data-table/index.scss'

const LEFT_AND_RIGHT_BORDER = 2

function VotingstatisticTable() {
    const { tableData }: { tableData: VotingStatisticTableData } = useVotingStatisticTableData()

    return (
        <div className="full-data-table-listContainer" style={{
            height: 'calc(100vh - 152px)',
            minHeight: '367px'
        }}>
            <VotingStatisticTableStatistic />
            <VotingStatisticHeader />
            {tableData?.localities?.length ?
                <AutoSizer>
                    {({ height, width }: any) => (
                        <List
                            outerElementType={Scrollbars}
                            className="full-data-table-list"
                            height={height - 179}
                            itemCount={tableData.localities.length}
                            itemSize={40}
                            width={width - LEFT_AND_RIGHT_BORDER}
                        >
                            {VotingStatisticItem}
                        </List>
                    )}
                </AutoSizer>
                : <div className="full-data-table-empty">
                    <Empty />
                </div>
            }
        </div>
    )
}

export default VotingstatisticTable