import { useCandidatesOnlineVotes, useOfficersElectionParticipants } from 'api'
import { OfficersElection } from 'models'
import { useEffect, useState } from 'react'
import { usePhysicalVotes } from '../../../../api'
import ElectionResults from '../../../../components/ElectionResults'

type ElectionResultsBeforeConfirmProps = {
    election: OfficersElection
}

function ElectionResultsBeforeConfirm({
    election
}: ElectionResultsBeforeConfirmProps) {
    const [candidates, setCandidates] = useState<any[]>([])

    const { data: onlineVotes } = useCandidatesOnlineVotes(election.id.toString())
    const { data: participants } = useOfficersElectionParticipants(election.region?.locality || '',
        !!election.region?.locality)
    const { data: physicalVotes } = usePhysicalVotes(election.id.toString())


    useEffect(() => {
        if (participants && onlineVotes && physicalVotes) {
            const participantsWithVotes = participants.map(p => ({
                id: p.id,
                name: p.name,
                votes: (onlineVotes.find(v => v.candidate === p.id)?.voteCount || 0)
                    + (physicalVotes.find(v => v.candidate === p.id)?.voteCount || 0)
            }))
            const sorted = participantsWithVotes.sort((a, b) => {
                if (a.votes !== b.votes) {
                    return b.votes - a.votes
                }

                return a.name.localeCompare(b.name) || a.id.localeCompare(b.id)
            })
            setCandidates(sorted)
        }
    }, [participants, onlineVotes, physicalVotes])

    return (
        <ElectionResults electionId={election.id.toString()} needToConfirm candidates={candidates} />
    )
}

export default ElectionResultsBeforeConfirm