import { FC, ReactNode } from 'react'
import styles from './index.module.scss'

type CardHeaderProps = {
    type?: 'regular' | 'mini',
    additionalTitleContent?: ReactNode,
    title?: ReactNode,
    subTitle?: ReactNode,
    titleAreaLabel?: string
}

const CardHeader: FC<CardHeaderProps> = ({
    type = 'regular',
    title,
    subTitle,
    additionalTitleContent,
    titleAreaLabel }) => (
    <div className={`${styles.header} ${styles[type]}`}>
        <div area-aria-label={titleAreaLabel} className={styles.title}>
            {title}
            <div className={styles.subTitle}>{subTitle}</div>
        </div>
        {
            additionalTitleContent && <div>{additionalTitleContent}</div>
        }
    </div>
)

export default CardHeader