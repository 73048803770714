import { ElectionPushResult, ElectionStatusType } from 'models'

export const getLocalDelegateStatus = (
    localDelegateElections: { status: ElectionStatusType }[],
    pushResult?: ElectionPushResult
): ElectionStatusType => {
    //if there is at least one 'IN_PROGRESS' unit status, election status should be 'IN PROGRESS'
    if (localDelegateElections.find((election) => (
        election.status === ElectionStatusType.IN_PROGRESS
    ))) {
        return ElectionStatusType.IN_PROGRESS
    }

    //if there is no 'OPEN' and at least one 'NOT STARTED' unit status, election status should be 'NOT STARTED'
    if (localDelegateElections.find((election) => (
        election.status === ElectionStatusType.NOT_STARTED
    ))) {
        return ElectionStatusType.NOT_STARTED
    }

    // if all unit statuses are 'CLOSED' or 'CANCELED', election status should be 'CLOSED'
    if (
        localDelegateElections.filter(
            (election) => (election.status === 'COMPLETED')
        ).length === localDelegateElections.length
    ) {
        if (!!pushResult) {
            return ElectionStatusType.COMPLETED
        }

        return ElectionStatusType.IN_PROGRESS
    }

    return ElectionStatusType.DRAFT
}