import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { queryClient } from 'config/query-client'

export type SetupOfficersElectionTellersParams = {
    firstTeller: string
    secondTeller: string
}
export function useSetupOfficersElectionTellers(localityCode: string) {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function setupOfficersElectionTellers({ firstTeller, secondTeller }
        : SetupOfficersElectionTellersParams) {
        return await axios(moduleUrl + 'setup-officers-election-tellers',
            {
                method: 'post',
                data: {
                    firstTeller,
                    secondTeller
                }
            })
    }

    return useMutation({
        mutationFn: setupOfficersElectionTellers,
        onSuccess: () => {
            queryClient.invalidateQueries(['officers-election-tellers', localityCode])
        }
    })
}