import { Tabs as AntTabs } from 'antd'

const tabIndicatorOffset = 47

type TabsProps = React.ComponentProps<typeof AntTabs>
export function Tabs(props: TabsProps) {
    return (
        <AntTabs
            {...props}
            className={props.className || ''}
            indicator={{ size: props.indicator?.size || ((origin) => origin - tabIndicatorOffset) }}
        >
            {props.children}
        </AntTabs >
    )
}

export default Tabs
