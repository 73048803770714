import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { queryClient } from 'config/query-client'
import { ElectionWordingConfiguration } from 'models'
import { getElectionTypeForUrl } from 'utils'

export function useUpdateElectionWordingConfiguration() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Toolkit)

    async function updateElectionWordingConfiguration(config: ElectionWordingConfiguration) {
        const electionTypeUrl = getElectionTypeForUrl(config.electionType, config.electionStage !== 1)

        return await axios(
            `${moduleUrl}ui/${electionTypeUrl}/ui-wording`,
            {
                method: 'post',
                data: config
            }
        )
    }

    return useMutation({
        mutationFn: updateElectionWordingConfiguration,
        onSuccess: async () => {
            await queryClient.invalidateQueries(['election-wording-config'])
        }
    })
}