import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { queryClient } from 'config/query-client'
import { OnlineVoterDashboardWording } from 'models'

export function useSaveVoterDashboardWording() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Toolkit)

    async function saveVoterDashboardWording({ title, body }: OnlineVoterDashboardWording) {
        return await axios(moduleUrl + 'ui/online-voters-dashboard',
            {
                method: 'post',
                data: {
                    title,
                    body
                }
            })
    }

    return useMutation({
        mutationFn: saveVoterDashboardWording,
        onSuccess: async () => {
            await queryClient.invalidateQueries(['online-voters-dashboard'])
        }

    })
}