import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { Ballot } from 'models'

type StationTypes = {
    electionId: string,
    stationId: string
}
export const useStationBallots = ({ electionId, stationId }: StationTypes, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getStationBallots = async () => {
        const res =
            await axios<Ballot[]>(`${moduleUrl}elections/${electionId}/stations/${stationId}/ballots`)

        return res.data
    }

    return useQuery({
        queryKey: ['election-station-ballots'],
        queryFn: getStationBallots,
        enabled
    })
}