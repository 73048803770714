import { Radio as AntRadio, Col, Form, FormInstance, Row, Space } from 'antd'
import DatePicker from 'components/atoms/DatePicker'
import Radio from 'components/atoms/Radio'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfigDateTypeMap } from '../../../../hooks'
import { ConfigDateType } from '../../../../model'
import style from './index.module.scss'

type DateAndTimeOrJustTimeProps = {
    form: FormInstance
    sectionTitle: ReactNode
    radioFormProperty: string
    inputNote: string
}

function DateAndTimeOrJustTime({
    form,
    sectionTitle,
    radioFormProperty,
    inputNote
}: DateAndTimeOrJustTimeProps) {
    const { t } = useTranslation('configuration')
    const { toString } = useConfigDateTypeMap()
    const [dateType, setDateType] = useState<ConfigDateType>()

    useEffect(() => {
        const currentValue = form.getFieldValue(radioFormProperty)
        if (currentValue && currentValue !== dateType) {
            setDateType(currentValue)
        }
    }, [form, radioFormProperty, dateType])

    useEffect(() => {
        if (dateType === ConfigDateType.ELECTION_DAY) {
            form.setFieldsValue({
                [`${radioFormProperty}DateTime`]: undefined
            })
        } else if (dateType === ConfigDateType.CERTAIN_DATE) {
            form.setFieldsValue({
                [`${radioFormProperty}Time`]: undefined
            })
        }
    }, [dateType, form, radioFormProperty])

    const handleDateTypeChange = (value: ConfigDateType) => {
        setDateType(value)
        form.setFieldsValue({
            [`${radioFormProperty}DateTime`]: undefined,
            [`${radioFormProperty}Time`]: undefined
        })
    }

    return (
        <Row gutter={16}>
            <Col span={6}>
                {sectionTitle}
            </Col>
            <Col span={6}>
                <Form.Item
                    name={radioFormProperty}
                >
                    <AntRadio.Group
                        onChange={(e) => handleDateTypeChange(e.target.value)}>
                        <Space direction="horizontal" size={24}>
                            <Radio value={ConfigDateType.ELECTION_DAY}
                                className={`highlight-selected ${style.option}`}>
                                <div className={`radio-title ${style.title}`}>
                                    {toString(ConfigDateType.ELECTION_DAY)}
                                </div>
                                <div className={style.radioNote}>
                                    {t('rely_on_election_date')}
                                </div>
                            </Radio>
                            <Radio value={ConfigDateType.CERTAIN_DATE}
                                className={`highlight-selected ${style.option}`}>
                                <div className={`radio-title ${style.title}`}>
                                    {toString(ConfigDateType.CERTAIN_DATE)}
                                </div>
                                <div className={style.radioNote}>
                                    {t('rely_on_selected_date')}
                                </div>
                            </Radio>
                        </Space>
                    </AntRadio.Group>
                </Form.Item>
            </Col>
            <Col span={12}>
                <div className={style.inputAndLabel}>
                    {dateType === ConfigDateType.ELECTION_DAY &&
                        <Form.Item
                            className={style.input}
                            name={`${radioFormProperty}Time`}
                            label={t('time')}
                            rules={[{
                                required: dateType === ConfigDateType.ELECTION_DAY,
                                message: t('please_select_the_input', { input: t('time') })
                            }]}
                            validateTrigger={['onChange']}
                        >
                            <DatePicker
                                placeholder={t('common:select_input', { input: t('time') })}
                                inputReadOnly
                                changeOnBlur
                                format="hh:mm A"
                                picker="time"
                                style={{ width: '100%' }} />
                        </Form.Item>
                    }
                    {dateType === ConfigDateType.CERTAIN_DATE &&
                        <Form.Item
                            className={style.input}
                            name={`${radioFormProperty}DateTime`}
                            label={t('specific_date_n_time', { interpolation: { escapeValue: false } })}
                            rules={[{
                                required: dateType === ConfigDateType.CERTAIN_DATE,
                                message: t('please_select_the_input', {
                                    input: t('specific_date_n_time', { interpolation: { escapeValue: false } }),
                                    interpolation: { escapeValue: false }
                                })
                            }]}
                            validateTrigger={['onChange']}
                        >
                            <DatePicker
                                placeholder={t('common:select_input', {
                                    input: t('date_n_time', { interpolation: { escapeValue: false } }),
                                    interpolation: { escapeValue: false }
                                })}
                                inputReadOnly
                                changeOnBlur
                                showTime
                                format="MM/DD/YYYY hh:mm A"
                                style={{ width: '100%' }} />
                        </Form.Item>
                    }
                    <div className={style.label}>
                        {inputNote}
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default DateAndTimeOrJustTime