import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { VerifiedVotersCount } from 'models'

export const useStationVerifiedVoterCount = (electionId: string, stationId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getStationVerifiedVoterCount = async ({ queryKey }: QueryFunctionContext)
        : Promise<any> => {
        const [, electionId] = queryKey

        const res = await axios<VerifiedVotersCount>(
            `${moduleUrl}elections/${electionId}/stations/${stationId}/verified-voters/count`)

        return res.data
    }

    return useQuery<VerifiedVotersCount>({
        queryKey: ['station-verified-voters', electionId],
        queryFn: getStationVerifiedVoterCount,
        enabled
    })
}