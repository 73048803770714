import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { BallotsSummary } from 'models'

export const useBallotsSummary = (electionId: string, stationId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getBallotSummary = async ({ queryKey }: QueryFunctionContext)
        : Promise<any> => {
        const [, electionId, stationId] = queryKey
        const res = await axios<BallotsSummary>(
            `${moduleUrl}elections/${electionId}/stations/${stationId}/ballots/count`)

        return res.data
    }

    return useQuery<BallotsSummary>({
        queryKey: ['ballots-summary', stationId, electionId],
        queryFn: getBallotSummary,
        enabled
    })
}