import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectedPerson } from '../../models'

export const useElectionResultCandidates = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectionResultCandidates = async () => {
        const response = await axios<ElectedPerson[]>(moduleUrl
            + `elections/${electionId}/election-results/candidates/complete-statistics`)

        return response.data
    }

    return useQuery({
        queryKey: ['officers-election-result-candidates', electionId],
        queryFn: getElectionResultCandidates,
        enabled
    })
}