import { SortOrder } from 'models'
import { LocalityVotingStatistic, VotingStatisticTableLocalityFilter } from '../models'

export const filterLocalities = (
    allLocalities: LocalityVotingStatistic[],
    filter: VotingStatisticTableLocalityFilter
): LocalityVotingStatistic[] => {
    let localities = [...allLocalities]

    if (filter.localityName) {
        const localityName = filter.localityName.trim().toLowerCase()
        localities = localities.filter(p => p.localityName.toLowerCase().includes(localityName))
    }

    if (filter.localityCode) {
        const localityCode = filter.localityCode.trim().toLowerCase()
        if (localityCode !== '') {
            localities = localities.filter(p => p.localityCode.toLowerCase().includes(localityCode))
        }
    }

    if (filter.sortBy) {
        const { field, order } = filter.sortBy
        localities.sort((a, b) => {
            let comparison = 0
            switch (field) {
                case 'localityName':
                case 'localityCode':
                    comparison = a[field].localeCompare(b[field])
                    break
                case 'onlineVoters':
                    comparison = (a.votedOnlineInAbsence + a.votedOnlineInPerson)
                        - (b.votedOnlineInAbsence + b.votedOnlineInPerson)
                    break
                case 'paperBallots':
                    comparison = (a.votedPaperInAbsence + a.votedPaperInPerson)
                        - (b.votedPaperInAbsence + b.votedPaperInPerson)
                    break
                case 'eligibleToVote':
                case 'totalVoted':
                case 'percentageTotalVoted':
                    comparison = a[field] - b[field]
                    break
            }

            return order === SortOrder.ASC ? comparison : -comparison
        })
    }

    return localities
}