import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

type DeleteBallotParams = {
    id: string,
    comment: string
    stationId: string
    electionId: string
}

export function useDeleteBallot() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    async function deleteBallot({ id, comment, stationId, electionId }: DeleteBallotParams) {
        return await axios(moduleUrl
            + `elections/${electionId}/stations/${stationId}/delete-ballot`,
            {
                method: 'post',
                data: {
                    id,
                    comment
                }
            })
    }

    return useMutation({
        mutationFn: deleteBallot
    })
}