import style from './index.module.scss'

type StatisticCounterProps = {
    value: number,
    title: string,
    isInvalid?: boolean
}
function StatisticCounter({ value, title, isInvalid }: StatisticCounterProps) {
    const valueClassName = isInvalid ? style.invalidValue : style.value

    return (
        <div className={style.counter}>
            <div className={valueClassName}>{value}</div>
            <div className={style.title}>{title}</div>
        </div>
    )
}

export default StatisticCounter