import DelegateElectionsProvider from '../../provider/DelegateElectionsProvider'
import DelegateElections from '../DelegateElections'

function Elections() {

    return (
        <DelegateElectionsProvider>
            <DelegateElections />
        </DelegateElectionsProvider>
    )

}

export default Elections