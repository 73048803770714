import Tooltip from 'components/atoms/Tooltip'
import ElectionBadge from 'components/molecules/ElectionBadge'
import ElectionPushBadge from 'components/molecules/ElectionPushBadge'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { electionEditing } from 'features/electionEditing'
import { ReactComponent as WarningFilled } from 'icons/warning.svg'
import { ElectionResultSummary, ElectionStatusType } from 'models'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useDelegateElectionsData } from '../../hooks'
import DelegateElectionBallotingProgress from '../DelegateElectionBallotingProgress'
import style from './index.module.scss'

const canOpenElectionBoardFor = [
    ElectionStatusType.NOT_STARTED,
    ElectionStatusType.IN_PROGRESS,
    ElectionStatusType.COMPLETED
]

type ElectionsItemProps = {
    index: number,
    style?: CSSProperties,
    electionsSummary?: { election: number, electionResults: ElectionResultSummary }[]
}

function DelegateElectionsItem({ electionsSummary, index, style: itemStyle }: ElectionsItemProps) {
    const { t } = useTranslation('election')
    const { delegateElectionsData } = useDelegateElectionsData()

    const electionItem = delegateElectionsData?.elections[index]
    const electionSummary = electionsSummary?.find(summary => summary.election === electionItem?.id)
    const containsSkippedTie = electionSummary?.electionResults.tiePresent
        && electionSummary?.electionResults.breakingTieSkipped

    return (
        <div className={style.listItem} style={itemStyle}>
            <div style={{ width: 53 }}
                className={style.column} >
                <div className={`${style.icon} ${style['warningIcon']}`}>
                    {containsSkippedTie ?
                        <Tooltip title={t('teller:election_results_included_ties')}>
                            <WarningFilled />
                        </Tooltip> : ''}
                </div>
            </div>
            <div style={{ width: 195 }}
                className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis" title={electionItem?.unitName}>
                    {canOpenElectionBoardFor.includes(electionItem.electionStatus) ?
                        <Link
                            to={`/election-board/${electionItem.id}`} state={{ backButtonTitle: t('back_to_list') }}>
                            {electionItem?.unitName}</Link> :
                        (electionItem?.unitName)}
                </OptionalTooltip>
            </div>
            <div style={{ width: 130 }}
                className={style.column}>
                {electionItem?.unitCode}
            </div>
            <div style={{ width: 120 }}
                className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {electionItem?.numberOfDelegates || String.fromCharCode(8212)}
                </OptionalTooltip>
            </div >

            <div style={{ width: 120 }}
                className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {electionItem?.eligibleVoters || String.fromCharCode(8212)}
                </OptionalTooltip>
            </div >

            <div style={{ width: 110 }}
                className={style.column}>
                <div className="ellipsis">
                    {electionItem.participatedVoters.markedAsInPersonOnline
                        + electionItem.participatedVoters.markedAsAbsenceOnline}
                    /{electionItem.participatedVoters.markedAsAbsencePaper
                        + electionItem.participatedVoters.markedAsInPersonPaper}
                </div>
            </div>

            <div style={{ width: 210 }}
                className={style.column}>
                <DelegateElectionBallotingProgress
                    chiefTellerTasks={electionItem.chiefTellerTasks}
                    electionStatus={electionItem.electionStatus} />
            </div >

            <div style={{
                width: 140
            }}
                className={style.column}>
                <ElectionBadge status={electionItem?.electionStatus} />
            </div >

            <div style={{
                width: 190
            }}
                className={style.column}>
                <electionEditing.ridvan.components.UnitElectionResultConfirm
                    allElectionsIds={[]}
                    electionId={electionItem?.id.toString()}
                    allElectionsSummary={electionsSummary}
                />
            </div>

            <div style={{
                width: 120
            }}
                className={style.column}>
                {electionItem?.electionPushErrorReason ?
                    <Tooltip title={electionItem?.electionPushErrorReason}>
                        <div><ElectionPushBadge status={electionItem?.electionPushStatus} /></div>
                    </Tooltip> :
                    <ElectionPushBadge status={electionItem?.electionPushStatus} />}
            </div >
        </div>
    )
}

export default DelegateElectionsItem