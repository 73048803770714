import { Form } from 'antd'
import { LookupOption } from 'components/molecules/LookupSelect'
import Note from 'components/molecules/Note'
import { useInfoPopup } from 'hooks'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ReactComponent as Eraser } from 'icons/eraser.svg'
import { ElectionParticipant, ElectionType } from 'models'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BallotingElection } from '../../models/balloting-election'
import { FocusContext } from '../BallotingStepWrapper'
import { BallotingContext, SelectedPeopleContext, Steps } from '../BallotingStepsManagement'
import CandidateSelection from '../CandidateSelection'
import style from './index.module.scss'

interface EnterOfVotesProps {
    election?: BallotingElection
}

interface FormValues {
    [key: string]: ElectionParticipant
}

function checkForDuplicates(form: any, changedFieldIndex: number): number | null {
    const fieldValues = form.getFieldsValue()
    const valueCounts: { [key: string]: number } = {}
    let duplicateIndex: number | null = null

    Object.values(fieldValues).forEach((fieldValue, index) => {
        const castedValue = fieldValue as { id?: string }
        if (castedValue && castedValue.id) {
            if (!valueCounts[castedValue.id]) {
                valueCounts[castedValue.id] = 1
            } else {
                valueCounts[castedValue.id] += 1
                if (valueCounts[castedValue.id] === 2 && duplicateIndex === null) {
                    duplicateIndex = changedFieldIndex
                }
            }
        }
    })

    return duplicateIndex
}

export function EnterOfVotes({ election }: EnterOfVotesProps) {
    const { t } = useTranslation('voter')
    const [form] = Form.useForm()
    const { showInfo, infoPortal } = useInfoPopup()
    const [duplicateIndex, setDuplicateIndex] = useState<number | null>(null)
    const { setCurrentStep } = useContext(BallotingContext)
    const focusHeaderText = useContext(FocusContext)
    const selectedPeopleContext = useContext(SelectedPeopleContext)
    const [isCleared, setIsCleared] = useState(false)
    const [selectedCandidates, setSelectedCandidates] = useState<LookupOption[]>([])

    if (!selectedPeopleContext) {
        throw new Error('SelectedPeopleContext is not available!')
    }

    const details = {
        nextString: t('next'),
        numberOfVacancies: election ? election.numberOfVacancies : 9
    }

    const infoText = t('please_vote_for_exactly_value_individual', details)
    const tip = t('search_tip')
    const contentTip = t('start_with_last_name_before_trying_first_or_middle')

    const { setSelectedPeople } = selectedPeopleContext

    const handleClear = () => {
        setIsCleared(true)
    }

    const handleNextClick = async () => {
        try {
            await form.validateFields()
            const values: ElectionParticipant[] = Object.values(form.getFieldsValue())
            const people = values.filter(Boolean)
            setSelectedPeople(people)
            setCurrentStep(Steps.SubmitBallot)
            focusHeaderText && focusHeaderText()
        } catch (error) {
        }
    }

    useEffect(() => {
        if (isCleared) {
            form.resetFields()
            setIsCleared(false)
        }
    }, [isCleared, form])

    useEffect(() => {
        if (selectedPeopleContext.selectedPeople.length > 0) {
            const formValues = selectedPeopleContext.selectedPeople.reduce<FormValues>((acc, candidate, index) => {
                acc[`input${index}`] = candidate

                return acc
            }, {})
            form.setFieldsValue(formValues)
        }
    }, [form, selectedPeopleContext.selectedPeople])

    const resetField = useCallback((index: number) => {
        form.setFieldsValue({ ['input' + index]: undefined })
    }, [form])

    useEffect(() => {
        if (duplicateIndex !== null) {
            resetField(duplicateIndex)
            setDuplicateIndex(null)

        }
    }, [duplicateIndex, resetField])

    const handleFieldChange = useCallback((value: ElectionParticipant | undefined, index: number) => {
        const duplicateIndex = checkForDuplicates(form, index)
        if (duplicateIndex !== null) {
            showInfo({
                text: <>{t('the_individual_is_already_added')}</>,
                title: t('duplicate_name'),
                onOk: () => {
                    setDuplicateIndex(duplicateIndex)
                },
                onClose: () => setDuplicateIndex(duplicateIndex)
            })
        }
    }, [form, showInfo, t])

    const candidateChange = () => {
        const candidates = form.getFieldsValue()


        const selectedCandidates = Object.values(candidates).reduce((acc: LookupOption[], candidate) => {
            const candidateData = candidate as LookupOption

            if (candidateData && typeof candidateData ===
                'object' && 'id' in candidateData && 'name' in candidateData) {
                acc.push({ id: candidateData.id, name: candidateData.name })
            }

            return acc
        }, [] as LookupOption[])

        setSelectedCandidates(selectedCandidates)
    }

    return (
        <div className={style.contentBlock}>
            {infoPortal}
            <div className={style.infoBlockWrapper}>
                <Note icon={'regular'} mode={'info'}>
                    <div tabIndex={0} aria-label={infoText}>
                        {infoText}
                    </div>
                </Note>
            </div>
            {election?.electionType !== ElectionType.BREAKING_TIE &&
                <div className={style.searchTipWrapper}>
                    <div
                        tabIndex={0}
                        aria-label={(tip + contentTip)}
                        className={style.searchTip}>
                        {tip}
                        <span
                            className={style.tip}>
                            {contentTip}
                        </span>
                    </div>
                </div>
            }
            <div className={style.listWrapper}>
                <Form form={form}>
                    {Array.from({ length: election?.numberOfVacancies ?? 0 }).map((_, index) => (
                        <CandidateSelection
                            isCleared={isCleared}
                            initSelectedCandidate={selectedPeopleContext.selectedPeople[index]}
                            selectedCandidates={selectedCandidates}
                            key={index}
                            election={election}
                            index={index}
                            duplicateIndex={duplicateIndex}
                            handleFieldChange={handleFieldChange}
                            onChange={candidateChange}
                        />
                    ))}
                </Form>
            </div>
            <div className={style.buttonsWrapper}>
                <div className={style.buttons}>
                    <button
                        aria-label={t('next_button_by_clicking')}
                        className={`btn-main-primary hasIcon ${style.nextButton}`}
                        onClick={handleNextClick}>
                        <span>{t('next')}</span>
                        <Chevron className="flip-vertical" width="18" height="18" />
                    </button>
                    <button
                        aria-label={t('clear_entire_ballot_button_by_clicking')}
                        className={`btn-main-secondary ${style.clearButton}`}
                        onClick={() => handleClear()}
                    >
                        <Eraser />
                        <span>{t('clear_entire_ballot')}</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EnterOfVotes
