import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ByElectionPeriod } from 'models'

export const useElectionPeriod = (electionPeriodId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectionPeriod = async () => {
        const result = await axios<ByElectionPeriod>(
            `${moduleUrl}by-election-periods/${electionPeriodId}`)

        return result.data
    }

    return useQuery({
        queryKey: ['by-election-period', electionPeriodId],
        queryFn: () => getElectionPeriod(),
        enabled
    })
}
