import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'

export function useMergeResults() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function mergeResults(electionId: string) {

        return await axios(`${moduleUrl}elections/${electionId}/merge-results`,
            {
                method: 'post',
                data: {}
            })
    }

    return useMutation({
        mutationFn: mergeResults
    })
}