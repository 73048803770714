import { Select as AntSelect, Form } from 'antd'
import { Select } from 'components/atoms/Select'
import { Key } from 'react'
import { useTranslation } from 'react-i18next'
import { useBahaiYears } from '../../../../api'

type BallotingYearProps = {
    disabled?: boolean,
    key?: Key | null | undefined
}

function BallotingYear({ disabled, key }: BallotingYearProps) {
    const { data: bahaiYears } = useBahaiYears()
    const { t } = useTranslation()

    return (
        <Form.Item
            key={key}
            name={['ballotingYear']}
            className="no-padding"
            label={t('election:balloting_year')}
            style={{ width: 240 }}
            required
        >
            <Select
                disabled={disabled}
                placeholder={t('select_input', { input: t('election:balloting_year') })}
                className="small">
                {bahaiYears?.map(y => <AntSelect.Option key={y.years} value={y.badiYear} >
                    {t('year_b_e_period',
                        {
                            year: y.badiYear,
                            period: y.years
                        })
                    }
                </AntSelect.Option>)}

            </Select>
        </Form.Item>
    )
}

export default BallotingYear