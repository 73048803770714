import Card from 'components/atoms/Card'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ElectionType } from 'models'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useElectionTitle } from '../../hooks'
import { BallotingElection } from '../../models/balloting-election'
import { FocusHeaderContext } from '../BallotingStepsManagement'
import style from './index.module.scss'

interface ThankYouProps {
    election: BallotingElection,
    shouldFocusHeader: boolean,
    setShouldFocusHeader: React.Dispatch<React.SetStateAction<boolean>>
}

export function ThankYou({
    election,
    shouldFocusHeader,
    setShouldFocusHeader
}: ThankYouProps) {
    const { t } = useTranslation('voter')
    const navigate = useNavigate()
    const focusHeaderRef = useContext(FocusHeaderContext)
    const { electionTitle } = useElectionTitle(election)
    const handleBackToDashboard = () => {
        navigate('/')
    }

    useEffect(() => {
        if (shouldFocusHeader && focusHeaderRef && focusHeaderRef.current) {
            focusHeaderRef.current.focus()
            setShouldFocusHeader(false)
        }
    }, [shouldFocusHeader, focusHeaderRef, setShouldFocusHeader])

    return (
        <div className={style.contentBlock}>
            <Card
                noContentPadding={true} noHeaderLine={true}
                title={<div
                    tabIndex={0}
                    ref={focusHeaderRef}
                    className={style.headerText}>{t('your_online_ballot_has_been_submitted')}</div>}>
                <div
                    tabIndex={0}
                    className={style.bodyText}>
                    {`${t('thank_you_for_your_participation_in_the')} ${electionTitle}.`}
                </div>
                {election?.electionType !== ElectionType.OFFICERS_ELECTION &&
                    <div
                        tabIndex={0}
                        className={style.footerText}>
                        {t('confirmation_email_will_be_sent_to_you')}
                    </div>}
                <div className={style.buttonWrapper}>
                    <button className={`btn-main-primary hasIcon ${style.backDashButton}`}
                        onClick={handleBackToDashboard}>
                        <Chevron className="flip-horizontal" width="18" height="18" />
                        <span>{t('back_to_dashboard')}</span>
                    </button>
                </div>
            </Card>
        </div>
    )
}

export default ThankYou