import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionResult } from 'models'

export const useConfirmedCandidates = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getConfirmedCandidates = async ({ queryKey }: QueryFunctionContext) => {
        const [, electionId] = queryKey

        const res = await axios<ElectionResult[]>(
            `${moduleUrl}elections/${electionId}/election-results/candidates/confirmed`)

        return res.data.map(c => ({ ...c, confirmed: true }))
    }

    return useQuery({
        queryKey: ['confirmed-candidates', electionId],
        queryFn: getConfirmedCandidates,
        enabled
    })
}