import { Collapse as AntCollapse } from 'antd'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import IconWrapper from '../IconWrapper'
export type CollapseProps = React.ComponentProps<typeof AntCollapse>

function Collapse(props: CollapseProps) {
    return (
        <AntCollapse
            {...props}
            expandIcon={props.expandIcon
                ? props.expandIcon
                : () => <IconWrapper>
                    <Chevron className="rotate-90" height={12} /></IconWrapper>} />
    )
}

export default Collapse