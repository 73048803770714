import { useElectionSummary, useVoteCountingStarted } from 'api'
import { useConfirmPopup } from 'hooks'
import { ReactComponent as PeopleSettings } from 'icons/people-settings.svg'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.scss'

type StationsHeaderProps = {
    electionId: string | undefined
}

const StationsHeader: React.FC<StationsHeaderProps> = ({electionId}) => {
    const { data: voteCountingStarted } = useVoteCountingStarted(electionId!, !!electionId)
    const { data: electionResultSummary } = useElectionSummary(electionId!, !!electionId!)
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const navigate = useNavigate()
    const { t } = useTranslation('station')

    const showPopup = () => {
        showConfirm({
            text: <>{t('would_you_like_to_leave_this_page_without_saving')}</>,
            title: <>{t('leave_current_page')}</>,
            okText: <>{t('leave')}</>,
            cancelText: <>{t('continue_editing')}</>,
            onOk: async () => navigate(`/election-team/${electionId}`)
        })
    }

    return (
        <div className={styles.rightSection}>
            <div className={styles.info}>{
                t('click_add_new_station_button_to_add_station')}<br />
                {t('if_the_telling_process_started_by_the_station_it_cant_be_removed')}
            </div>
            {voteCountingStarted
                && !electionResultSummary?.mergedBy
                && !electionResultSummary?.confirmedBy
                && <button className="btn-main-tertiary-md" onClick={showPopup}>
                <PeopleSettings width="21"/>
                <div>{t('teller:manage_tellers')}</div>
              </button>
            }
            {confirmPortal}
        </div>
    )
}

export default StationsHeader