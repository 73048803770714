import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { useReportingData } from '../../hooks'
import { ReportItem } from '../../model'
import style from './index.module.scss'
type ReportGroupItemsProps = {
    items: ReportItem[]
}

function ReportGroupItems({ items }: ReportGroupItemsProps) {
    const { reportingData, setReportingData } = useReportingData()

    return (
        <ul className={style.items}>
            {items.map(item => <li
                className={`${style.item} ${reportingData.selectedReport?.key === item.key ? style.selected : ''}`}
                onClick={() => { setReportingData((current) => ({ ...current, selectedReport: { ...item } })) }}
                key={item.key}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {item.label}
                </OptionalTooltip>
            </li>)}
        </ul>
    )
}

export default ReportGroupItems