import PopupFooter from 'components/atoms/PopupFooter'
import { Trans, useTranslation } from 'react-i18next'
import { StationResetMode } from '../../models'
import style from './index.module.scss'

type SuccessfulResetProps = {
    hide: () => void
    mode: StationResetMode
    stations: string[]
}

function SuccessfulReset({
    hide,
    mode,
    stations
}: SuccessfulResetProps) {
    const { t } = useTranslation('teller')

    return (
        <>
            <div className={style.label}>
                <Trans i18nKey={t('mode_has_been_completed_for_the_following_station_s',
                    {
                        interpolation: { escapeValue: false },
                        mode: `<strong>${mode === StationResetMode.PartialReset
                            ? t('partial_reset_ballot_entry_only')
                            : t('full_reset_all_entries')}</strong>`
                    })} components={[<strong />]} />:
            </div>
            <ul className={style.stations}>
                {!!stations && stations.sort().map(station => <li className={style.station} key={station}>
                    {t('station_n', { n: station.replace('S', '') })}
                </li>)}
            </ul>
            <PopupFooter>
                <button
                    type="submit"
                    onClick={hide}
                    className={'btn-main-primary'}>
                    {t('ok')}
                </button>
            </PopupFooter >
        </>
    )
}

export default SuccessfulReset