import { Select as AntSelect } from 'antd'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import React, { useMemo } from 'react'

export type SelectProps = React.ComponentProps<typeof AntSelect>

export const Select = React.forwardRef((props: SelectProps, ref: React.Ref<any>) => {

    const allowClear = useMemo(() => {
        const defaultCustomClearIcon = { clearIcon: <Dismiss className="rotate-90" width="100%" height="100%" /> }

        switch (typeof props.allowClear) {
            case ('boolean' || undefined):
                if (props.allowClear) return defaultCustomClearIcon

                return false
            case 'object':
                return props.allowClear?.hasOwnProperty('clearIcon') ? props.allowClear : defaultCustomClearIcon
        }
    }, [props.allowClear])

    return (
        <AntSelect
            ref={ref}
            {...props}
            className={`obs ${props.className || ''}`}
            allowClear={allowClear}
            suffixIcon={props.suffixIcon || <Chevron className="rotate-90" width="100%" height="100%" />}
            notFoundContent={props.notFoundContent}
        >
            {props.children}
        </AntSelect>
    )
})