import { Col, Row } from 'antd'
import ReportingProvider from '../../provider/ReportingProvider'
import Filters from '../Filters'
import Reports from '../Reports'
import style from './index.module.scss'

function Reporting() {
    return (
        <ReportingProvider>
            <Row gutter={16} className={style.reporting}>
                <Col span={7}>
                    <Reports />
                </Col>
                <Col span={17}>
                    <Filters />
                </Col>
            </Row>
        </ReportingProvider>
    )
}

export default Reporting