import { defineAbility } from 'ability/ability'
import { Auth, ColorThemeType, Role } from 'models'
import { useNavigate } from 'react-router-dom'
import { useAbility } from './useAbility'
import { useAuth } from './useAuth'
import { useColorTheme } from './useColorTheme'

export const useChangeUser = () => {
    const { auth, setAuth } = useAuth()
    const { setColorTheme } = useColorTheme()
    const { setAbility } = useAbility()
    const navigate = useNavigate()

    const setTheme = (theme: ColorThemeType) => {
        setColorTheme(theme)
        localStorage.setItem('theme', theme)
    }

    const changeTheme = (role?: Role) => {
        switch (role) {
            case Role.SYSTEM_ADMIN:
            case Role.NSA_ADMIN:
                setTheme(ColorThemeType.AdminTheme)

                return
            case Role.CHIEF_TELLER_ASSISTANT:
            case Role.CHIEF_TELLER:
            case Role.LSA_TELLER:    
                setTheme(ColorThemeType.ChiefTellerTheme)

                return
            case Role.TELLING_STATION:
                setTheme(ColorThemeType.TellingStationTheme)

                return
            case Role.LSA_OFFICER:
                setTheme(ColorThemeType.LsaOfficerTheme)

                return
            case Role.VOTER:
                setTheme(ColorThemeType.VoterTheme)

                return
        }
        setTheme(ColorThemeType.AuthTheme)
    }

    const path = (role?: Role): string => {
        if (role) {
            return '/'
        }

        return '/sign-in'
    }

    const resetTheme = () => {
        setTheme(ColorThemeType.AuthTheme)
    }

    const changeRole = (newRole: Role, electionId?: string, stationId?: string) => {
        if (!auth)
            return
        changeAuth({ ...auth, role: newRole, electionId, stationId })
        changeTheme(newRole)
        navigate({ pathname: path(newRole) }, { replace: true })
    }

    const changeAuth = (auth?: Auth) => {
        setAbility(defineAbility(auth))
        setAuth(auth || null)
        if (auth) {
            localStorage.setItem('user', JSON.stringify(auth))
        } else {
            localStorage.removeItem('user')
        }
    }

    return {
        changeAuth,
        changeRole,
        resetTheme
    }


}