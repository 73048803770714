import { PropsWithChildren } from 'react'
import style from './index.module.scss'

function DashboardWrapper({ children }: PropsWithChildren) {

    return (
        <div className={style.wrapper}>
            {children}
        </div>
    )
}

export default DashboardWrapper