import { BackToDashboardLineContext } from 'context'
import { PropsWithChildren, ReactNode, useState } from 'react'

function BackToDashboardLineProvider({ children }: PropsWithChildren) {
    const [backToDashboardLine, setBackToDashboardLine] = useState<ReactNode>(null)

    return (
        <BackToDashboardLineContext.Provider value={{ backToDashboardLine, setBackToDashboardLine }} >
            {children}
        </BackToDashboardLineContext.Provider>
    )
}

export default BackToDashboardLineProvider