import { Table as AntTable, TableProps as AntTableProps } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import classNames from 'classnames'
import { CSSProperties, useEffect } from 'react'
import style from './index.module.scss'

interface TableProps<RecordType = unknown> extends AntTableProps<RecordType> {
    headerDividerVisible?: boolean,
    filterable?: boolean,
    stickyOffset?: string,
    obsSize?: 'small' | 'medium' | 'large',
    obsHeaderSize?: 'small' | 'medium' | 'large',
    noOuterBorder?: boolean,
    onScrollToBottom?: () => void
}

type StickyCSSProps = CSSProperties & {
    '--stickyOffset': string | undefined
}

function Table<RecordType extends AnyObject = AnyObject>({ onScrollToBottom, ...props }: TableProps<RecordType>) {
    const handleScroll = () => {
        if ((window.innerHeight === document.documentElement.offsetHeight)
            || (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight)) {
            return
        }
        onScrollToBottom?.()
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onScrollToBottom])

    return <AntTable {...props}
        style={
            { '--stickyOffset': props.stickyOffset } as StickyCSSProps
        } rootClassName={
            ` ${classNames(props.rootClassName,
                {
                    [style.obsTable]: true,
                    [style.headerDividerVisible]: props.headerDividerVisible,
                    [style.filterable]: props.filterable,
                    [style.noOuterBorder]: props.noOuterBorder
                },
                props.obsSize ? style[props.obsSize] : style.medium,
                (props.obsHeaderSize ? style[props.obsHeaderSize + 'Header'] : style.mediumHeader),
                props.stickyOffset ? style.sticky : '')
            }`} />
}

export default Table