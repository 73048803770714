import { useState } from 'react'
import Spinner from '../Spinner'
import style from './index.module.scss'

// eslint-disable-next-line max-len
const SUERVEY_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdst-foJ5AnsGU-ql4-6ZrwDs6dcD5g2H42xzZyF-dBZshWpQ/viewform?embedded=true'
function Survey() {

    const [loaded, setLoaded] = useState(false)

    return (
        <>
            {!loaded && <div className={style.spinnerWrapper}>
                <Spinner />
            </div>
            }
            <iframe
                onLoad={() => setLoaded(true)}
                title="Leave your feedback here (via Google Form)"
                className={`${style.embedded} ${loaded ? '' : style.hidden}`}
                src={SUERVEY_URL}
            >
                Loading…
            </iframe>
        </>
    )
}

export default Survey