import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export const useOfficersElectionLocalities = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getOfficersElectionLocalities = async () => {
        const response = await axios<string[]>(moduleUrl
            + 'me/officers-election-teller/localities')

        if (response.data?.length)
            return response.data[0]

        return ''
    }

    return useQuery({
        queryKey: ['my-officers-election-localities'],
        queryFn: getOfficersElectionLocalities,
        enabled
    })
}