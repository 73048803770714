import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

type ElectionPeriodYear = {
    badiYear: number
}

export const useElectionPeriodsYears = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectionPeriodsYears = async (): Promise<ElectionPeriodYear[]> => {
        const result = await axios<ElectionPeriodYear[]>(moduleUrl + 'election-periods/bahai-years')

        return result.data
    }

    return useQuery({
        queryKey: ['election-periods-years'],
        queryFn: getElectionPeriodsYears,
        enabled
    })
}