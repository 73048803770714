import { ElectionRestrictions, ElectionType, Restriction } from 'models'

export const getElectionRestrictions
    = (electionType: ElectionType, restrictions?: ElectionRestrictions): Restriction | null => {
        if (!restrictions) {
            return null
        }

        switch (electionType) {
            case ElectionType.RIDVAN_ELECTION:
                return restrictions.ridvanElectionRestriction
            case ElectionType.LOCAL_DELEGATE_ELECTION:
                return restrictions.localDelegateElectionRestriction
            case ElectionType.BY_ELECTION:
                return restrictions.byElectionRestriction
            case ElectionType.OFFICERS_ELECTION:
                return restrictions.officersElectionRestriction
            case ElectionType.NATIONAL_DELEGATE_ELECTION:
                return restrictions.nationalDelegateElectionRestriction
            default:
                return null
        }
    }