import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { VotesCounts } from 'models'

type ElectionsVotesCountParams = {
    electionIds: string[]
}

export const useElectionsVotesCount =
    ({ electionIds }: ElectionsVotesCountParams, enabled?: boolean) => {
        const axios = useAxios()
        const moduleUrl = getModuleUrl(ApiModule.TellingStation)

        const getElectionsVotesCount = async () => {
            const res = await axios<{ election: number, votes: VotesCounts }[]>(
                `${moduleUrl}elections/votes/count?elections=${electionIds.join(',')}`)

            return res.data
        }

        return useQuery({
            queryKey: ['elections-votes-count', electionIds],
            queryFn: getElectionsVotesCount,
            enabled
        })
    }