export const useResetAdminDashboardData = () => {
    const resetAdminDashboardData = () => {
        var adminDbdItems = []
        for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i)?.substring(0, 10) === 'admin_dbd_') {
                adminDbdItems.push(localStorage.key(i)!)
            }
        }

        for (let i = 0; i < adminDbdItems.length; i++) {
            localStorage.removeItem(adminDbdItems[i])
        }
    }

    return {
        resetAdminDashboardData
    }
}