import Empty from 'components/molecules/Empty'
import { ReactNode } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { useVoterVerificationTableData } from '../../hooks'
import VoterVerificationTableHeader from '../VoterVerificationTableHeader'
import VoterVerificationTableItem from '../VoterVerificationTableItem'
import VoterVerificationTableStatistic from '../VoterVerificationTableStatistic'
import style from './index.module.scss'

const LEFT_AND_RIGHT_BORDER = 2

type VoterVerificationTableProps = {
    tutorial?: ReactNode
    height?: string
    colHighlightingHeight?: string
}

function VoterVerificationTable({
    tutorial,
    height: tableHeight,
    colHighlightingHeight
}: VoterVerificationTableProps) {
    const { tableData } = useVoterVerificationTableData()

    return (
        <div className={style.listContainer}
            style={{ height: tableHeight }}>
            {tutorial}
            <VoterVerificationTableStatistic />
            <VoterVerificationTableHeader columnHighlightingHeight={colHighlightingHeight} />
            {tableData?.voters?.length ?
                <AutoSizer>
                    {({ height, width }: any) => (
                        <List
                            style={style}
                            outerElementType={Scrollbars}
                            className={style.list}
                            height={height - 164}
                            itemCount={tableData.voters.length}
                            itemSize={40}
                            width={width - LEFT_AND_RIGHT_BORDER}
                        >
                            {VoterVerificationTableItem}
                        </List>
                    )}
                </AutoSizer>
                : <div className={style.empty}>
                    <Empty />
                </div>
            }
        </div>
    )
}

export default VoterVerificationTable