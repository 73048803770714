import { useMilestoneColorMap } from 'hooks'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { Milestone } from 'models/milestone'
import React, { PropsWithChildren } from 'react'
import styles from './index.module.scss'

const MilestoneItem: React.FC<PropsWithChildren<Milestone>> = ({
    children, title, color, description, status, descriptionWithoutMargin }) => {
    const { toString } = useMilestoneColorMap()

    return (
        <div className={`${styles.milestone} ${styles[toString(color) || '']}`}>
            <div className={styles.milestoneContent}>
                <div>
                    <div className={styles.titleWrapper}><div className={styles.title}>{title}</div>
                        {status && <div>{status}</div>}
                    </div>
                    {description
                        && <div className={
                            descriptionWithoutMargin
                                ? styles.descriptionWithoutMargin
                                : styles.description}>{description
                            }</div>}
                </div>
                <div className={styles.action}>{children}</div>
            </div>
            <div className={styles.line} />
            <div className={styles.arrowIcon}> <Chevron /></div>
        </div>
    )
}

export default MilestoneItem