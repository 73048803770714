import { useAppointedTellers, useElection } from 'api'
import PageHeader from 'components/atoms/PageHeader'
import { useAuth, useElectionTypeMap, usePageHeader, useTellerTypeMap } from 'hooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

function DashboardHeader() {
    const { setPageHeader } = usePageHeader()
    const { t } = useTranslation('teller')
    const { toString } = useElectionTypeMap()
    const { toString: tellerTypeToString } = useTellerTypeMap()
    const { auth } = useAuth()
    const { data: election } = useElection(auth!.electionId!, !!auth?.electionId)
    const { data: tellers } = useAppointedTellers(auth!.electionId!, !!auth?.electionId)

    const details = {
        year: election?.ballotingYear,
        election: election ? toString(election.type) : ''
    }

    useEffect(() => {
        let tellerRole = null

        if (tellers && auth) {
            const teller = tellers.find(t => t.id === auth.id)
            if (teller) {
                tellerRole = teller.role
            }
        }

        setPageHeader(
            <PageHeader
                sticky={true}
                greeting={auth?.role ? t('hello_role', { role: tellerRole ? tellerTypeToString(tellerRole) : '' }) : ''}
                subgreeting={t('main_dashboard')}>
                <div className={style.electionName}>
                    {t('year_b_e_election', details)}
                    {election?.region?.localUnit ? ` (${election.region.localUnit})` : ''}
                    {election?.region?.rbc ? ` (${election.region.rbc})` : ''}
                    {election?.region?.unit ? ` (${election.region.unit})` : ''}
                </div>
            </PageHeader>
        )

        return () => setPageHeader(null)
    }, [election, tellers, auth])// eslint-disable-line react-hooks/exhaustive-deps

    return null
}

export default DashboardHeader