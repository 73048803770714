
import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { queryClient } from 'config/query-client'
import { useState } from 'react'

type CompleteElectionWithPhysicalVotesProps = {
    electionId: string,
    candidateVotes: { candidate: string, voteCount: number }[]
}

export function useCompleteElectionWithPhysicalVotes() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)
    const [electionId, setElectionId] = useState('')

    async function completeElectionWithPhysicalVotes(
        { electionId, candidateVotes }
            : CompleteElectionWithPhysicalVotesProps) {

        setElectionId(electionId)

        return await axios(
            `${moduleUrl}elections/${electionId}/end-voting`,
            {
                method: 'post',
                data: { candidateVotes }
            })
    }

    return useMutation({
        mutationFn: completeElectionWithPhysicalVotes,
        onSuccess: async () => {
            await queryClient.invalidateQueries(['election-summary', electionId])
            await queryClient.invalidateQueries(['candidates-elected', electionId])
            await queryClient.invalidateQueries(['election-results-candidates', electionId])
            await queryClient.invalidateQueries(['online-voting', electionId])
        }
    })
}