import { useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ParticipantsStatistic } from 'models'

export const useElectionParticipantsStatistic = (id?: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectionParticipantsStatistic = async (id?: string) => {
        const res = await axios<ParticipantsStatistic>(`${moduleUrl}elections/${id}/participants/count`)

        return res.data
    }

    return useQuery({
        queryKey: ['election', id, 'participantsStatistic'],
        queryFn: () => getElectionParticipantsStatistic(id),
        enabled
    })
}