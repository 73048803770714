import Card, { CardProps } from 'components/atoms/Card'
import SupportedBrowsersHint from '../../SupportedBrowsersHint'
import style from './panel.module.scss'

interface PanelProps extends Omit<CardProps, 'noContentPadding' | 'contentClassName'> {
    showSupportedBrowserHint?: boolean
    maxWidth?: string
    noBorder?: boolean
    cardClassName?: string
    className?: string
}

function Panel({ children,
    title,
    noHeaderLine = true,
    showSupportedBrowserHint,
    maxWidth,
    cardClassName,
    className,
    noBorder }: PanelProps) {
    return (
        <div className={`${className} ${style.panel}`} style={{ maxWidth }}>
            <Card
                title={title}
                noHeaderLine={noHeaderLine}
                contentClassName={style.adaptiveContent}
                className={`${cardClassName} ${noBorder ? style.noBorder : ''}`}
            >
                {children}
            </Card>
            {showSupportedBrowserHint && <SupportedBrowsersHint />}
        </div>
    )
}

export default Panel