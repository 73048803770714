import { AbilityBuilder } from '@casl/ability'
import {Auth, Role} from 'models'
import { AppAbility } from './ability'

export type ManageTellersSubject = 'MyselfAsTeller'

export function defineManageTellersAbility(builder: AbilityBuilder<AppAbility>, auth?: Auth) {
    const { can } = builder

    if (auth?.role !== Role.CHIEF_TELLER && auth?.role !== Role.CHIEF_TELLER_ASSISTANT) {
        can('delete', 'MyselfAsTeller')
    }
}
