import React from 'react'
import { useTranslation } from 'react-i18next'
import { useElectionTitle } from '../../hooks'
import { BallotingElection } from '../../models/balloting-election'
import style from './index.module.scss'

interface BallotingStepsWrapperProps {
    election: BallotingElection
    children?: React.ReactNode
}

export const FocusContext = React.createContext<(() => void) | undefined>(undefined)

export function BallotingStepWrapper({ children, election }: BallotingStepsWrapperProps) {
    const { t } = useTranslation('voter')
    const focusRef = React.useRef<HTMLDivElement>(null)
    const { electionInstitutionTitle, electionYearAndTypeTitle } = useElectionTitle(election)
    const focusHeaderText = () => {
        focusRef.current?.focus()
    }

    React.useEffect(() => {
        focusRef.current?.focus()
    }, [])

    return (
        <div className={style.contentBlock}>
            <div className={style.ballotingPanelWrapper}>
                <div className={style.headerPanel}>
                    <div
                        tabIndex={0}
                        className={style.headerTextWrapper}
                        ref={focusRef}
                    >
                        <div className={style.headerText}>
                            {electionYearAndTypeTitle}
                        </div>
                        <span className={style.subheaderText}>
                            {electionInstitutionTitle}
                        </span>
                    </div>
                    <div className={style.statusesWrapper}>
                        <span className={style.statuses}>{t('online_ballot')}</span>
                    </div>
                </div>
                <div>
                    <FocusContext.Provider value={focusHeaderText}>
                        {children}
                    </FocusContext.Provider>
                </div>
            </div>
        </div>
    )
}

export default BallotingStepWrapper