import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl } from 'api'
import axios from 'axios'

export function useExchangeToken() {
    const moduleUrl = getModuleUrl(ApiModule.AuthenticationService)

    async function exchangeToken(loginToken: string) {
        const api = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                'Authorization': loginToken
            },
            withCredentials: true
        })

        return await api(moduleUrl
            + 'user/exchange-token',
            {
                method: 'post',
                data: {}
            })
    }

    return useMutation({
        mutationFn: exchangeToken
    })
}