import MergedResults from '../components/MergedResults'
import MergedResultsProvider from '../provider/MergedResultsProvider'

function MergedResultsWrapper() {

    return (
        <MergedResultsProvider>
            <MergedResults />
        </MergedResultsProvider>
    )
}

export default MergedResultsWrapper