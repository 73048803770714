import { useElectionStations, useElectionsSummary, useOnlineVotingInfos } from 'api'
import { ReactComponent as CheckmarkInDoubleCircleFilled } from 'icons/checkmark-in-double-circle-filled.svg'
import { ReactComponent as Circle } from 'icons/circle.svg' 
import { ReactComponent as RoundedCircle } from 'icons/rounded-circle.svg'
import { LocalDelegateStageElection, RidvanElectionPeriod } from 'models'
import { useEffect, useState } from 'react'
import style from './index.module.scss'

enum Status {
    TellingNotStarted,
    TellingInProgress,
    TellingComplete,
    MergingInProgress,
    MergingComplete,
    ReportingInProgress,
    ReportingComplete
}

type UnitElectionBallotingProgressProps = {
    allElectionsIds: string[],
    election: LocalDelegateStageElection,
    ridvanElectionPeriod: RidvanElectionPeriod
}


function UnitElectionBallotingProgress({
    allElectionsIds,
    election,
    ridvanElectionPeriod
}: UnitElectionBallotingProgressProps) {

    const [status, setStatus] = useState<Status>(Status.TellingNotStarted)
    const { data: electionsSummary } = useElectionsSummary({
        electionIds: ridvanElectionPeriod?.localDelegateElectionStage?.elections.map(e => e.id.toString()) || []
    }, !!ridvanElectionPeriod)
    const { data: onlineVotingInfos } = useOnlineVotingInfos({
        electionIds: allElectionsIds
    })
    const { data: electionStations } = useElectionStations(election.id.toString(), true)

    useEffect(() => {
        if (electionsSummary) {

            const electionSummary = electionsSummary.find(i => i.election === election.id)

            if (electionSummary) {
                const signed = !!electionSummary.electionResults.signedBy
                const merged = !!electionSummary.electionResults.mergedBy

                if (signed) {
                    setStatus(Status.ReportingComplete)

                    return
                }

                if (merged) {
                    setStatus(Status.MergingComplete)

                    return
                }

                if (electionSummary.electionResults.tiePresent) {
                    setStatus(Status.MergingInProgress)
                    
                    return
                }
            }

            if(electionStations && electionStations.length 
                && electionStations.filter(i => i.stationReport.signed).length === electionStations.length) {
                setStatus(Status.TellingComplete)

                    return
            }

            const onlineVotingInfo = onlineVotingInfos?.filter(s => s.election === election.id)
            if (onlineVotingInfo?.filter(i => i.votingReportAccepted).length) {
                setStatus(Status.TellingInProgress)

                return
            }

            setStatus(Status.TellingNotStarted)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electionsSummary, electionStations, onlineVotingInfos])

    const getTellingItem = () => {
        if (election.bypassFlow) {
            return <div className={`${style.status} ${style.hidden}`}>
                <Circle />
            </div>
        }
        switch (status) {
            case Status.TellingNotStarted:
                return <div className={`${style.status} ${style.untouched} ${style.nextStepExpected}`}>
                    <Circle />
                </div>
            case Status.TellingInProgress:
                return <div className={`${style.status} ${style.expected} ${style.nextStepUntouched}`}>
                    <RoundedCircle />
                </div>
            default:
                return <div className={`${style.status} ${style.done} ${style.nextStepExpected}`}>
                    <CheckmarkInDoubleCircleFilled />
                </div>
        }
    }

    const getMergingItem = () => {
        switch (status) {
            case Status.TellingNotStarted:
            case Status.TellingInProgress:
                return <div className={`${style.status} ${style.untouched}
                 ${election.bypassFlow ? style.nextStepHidden : style.nextStepUntouched}`}>
                    <Circle />
                </div>
            case Status.TellingComplete:
                return <div className={`${style.status} ${style.expected}
                 ${election.bypassFlow ? style.nextStepHidden : style.nextStepExpected}`}>
                    {election.bypassFlow ? <><Circle /></> : <><RoundedCircle /></>}
                </div>
            case Status.MergingInProgress:
                return <div className={`${style.status} ${style.expected}
                 ${election.bypassFlow ? style.nextStepHidden : style.nextStepExpected}`}>
                    <RoundedCircle />
                </div>
            default:
                return <div className={`${style.status} ${style.done}
                 ${election.bypassFlow ? style.nextStepHidden : style.nextStepDone}`}>
                    <CheckmarkInDoubleCircleFilled />
                </div>
        }
    }

    const getReportingItem = () => {
        switch (status) {
            case Status.MergingComplete:
                return <div className={`${style.status} ${style.expected} ${style.currentStepExpected}`}>
                    <RoundedCircle />
                </div>
            case Status.ReportingInProgress:
                return <div className={`${style.status} ${style.expected} ${style.currentStepExpected}`}>
                    <RoundedCircle />
                </div>
            case Status.ReportingComplete:
                return <div className={`${style.status} ${style.done} ${style.currentStepDone}`}>
                    <CheckmarkInDoubleCircleFilled />
                </div>
            default:
                return <div className={`${style.status} ${style.untouched} ${style.currentStepUntouched}`}>
                    <Circle />
                </div>
        }
    }

    return (
        <div className={style.ballotingProgress}>
            {getTellingItem()}
            {getMergingItem()}
            {getReportingItem()}
        </div>
    )
}

export default UnitElectionBallotingProgress