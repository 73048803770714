import Card from 'components/atoms/Card'
import Empty from 'components/molecules/Empty'
import { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { useDelegateElectionsData } from '../../hooks'
import { useElectionsSummaryChunks } from '../../hooks/useElectionSummaryChunks'
import DelegateElectionsItem from '../DelegateElectionItem'
import DelegateElectionsHeader from '../DelegateElectionsHeader'
import DelegateElectionsLoader from '../DelegateElectionsLoader'
import DelegateElectionsTitle from '../DelegateElectionsTitle'
import style from './index.module.scss'

function DelegateElections() {
    const LEFT_AND_RIGHT_BORDER = 2
    const { t } = useTranslation('administration')
    const [allIds, setAllIds] = useState<string[]>([])

    const { delegateElectionsData } = useDelegateElectionsData()

    const {
        data: electionsSummaryPages,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = useElectionsSummaryChunks({ electionIds: allIds })

    const allElectionsSummary = electionsSummaryPages?.pages?.flatMap(page => page.data) || []

    useEffect(() => {
        if (delegateElectionsData.allElections && delegateElectionsData.allElections.length) {
            setAllIds(delegateElectionsData.elections?.map((data) => data.id.toString()).filter(id => id !== '0') || [])
        }
    }, [delegateElectionsData])

    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage()
        }
    }, [hasNextPage, isFetchingNextPage])

    return (
        <Card
            title={<DelegateElectionsTitle />}
            className={style.officersElections}
            noContentPadding={true}>
            <div className={style.elections}>
                <DelegateElectionsLoader />
                <DelegateElectionsHeader />
                {delegateElectionsData?.elections?.length ?
                    <AutoSizer>
                        {({ height, width }: any) => (
                            <List
                                style={style}
                                outerElementType={Scrollbars}
                                className={style.list}
                                height={height - 49}
                                itemCount={delegateElectionsData.elections.length}
                                itemSize={56}
                                width={width - LEFT_AND_RIGHT_BORDER}
                            >
                                {({ index, style: itemStyle }) => (
                                    <DelegateElectionsItem
                                        index={index}
                                        style={itemStyle}
                                        electionsSummary={allElectionsSummary}
                                    />
                                )}
                            </List>
                        )}
                    </AutoSizer>
                    :
                    <div className={style.empty}>
                        <Empty text={delegateElectionsData.reportError && !delegateElectionsData.elections?.length
                            ? t('elections_not_loaded_reason',
                                {
                                    reason: delegateElectionsData.reportError,
                                    interpolation: { escapeValue: false }
                                })
                            : undefined} />
                    </div>
                }
            </div>
        </Card>
    )
}

export default DelegateElections