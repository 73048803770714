import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useElectionRestrictionsOld } from 'api'
import { ElectionType } from 'models'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ConfigWrapper from '../../../../components/ConfigWrapper'
import { useElectionConfigData } from '../../hooks/useElectionConfigData'
import { ElectionPeriod } from '../../model'
import GeneralSettings from '../GeneralSettings'

function OfficersElectionConfig() {
    const { t } = useTranslation('configuration')
    const [form] = useForm()
    const { setElectionConfigData } = useElectionConfigData()
    const { data: electionRestrictionsOld, isLoading: isLoadingRestrictions } = useElectionRestrictionsOld()

    useEffect(() => {
        form.setFieldsValue({
            electionEnabled: false
        })

        setElectionConfigData(current => {
            const updatedData = {
                ...current, form,
                isFormTouched: false,
                electionType: ElectionType.OFFICERS_ELECTION,
                electionPeriod: ElectionPeriod.RIDVAN_ELECTION_PERIODS
            }

            return updatedData
        })
    },
        [form, setElectionConfigData])

    useEffect(() => {
        if (electionRestrictionsOld && !isLoadingRestrictions) {
            setElectionConfigData(current => {
                const updatedData = {
                    ...current,
                    electionRestrictions: electionRestrictionsOld.officersElectionRestriction
                }

                return updatedData
            })
            form.setFieldsValue({
                electionEnabled: electionRestrictionsOld.officersElectionRestriction.electionCreationEnabled
            })
        }
    }, [electionRestrictionsOld, isLoadingRestrictions, setElectionConfigData])

    return (
        <ConfigWrapper >
            <Form
                layout="vertical"
                form={form}
                onChange={() => setElectionConfigData(current => ({ ...current, isFormTouched: true }))}>
                <GeneralSettings form={form}
                    hideByPassFlowConfig
                    enabledTitle={t('enable_disable_officers_election')}
                    enabledSubtitle={
                        <>
                            <div>{t('show_hide_manage_officers_election')}.</div>
                        </>
                    }
                />
            </Form>
        </ConfigWrapper>
    )
}

export default OfficersElectionConfig