import PopupTitle from 'components/atoms/PopupTitle'
import { ConfirmPopupSettings, useAccessToken, useAuth } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useRestartTrainingSession } from '../../dashboard/api'
import { useFinish, useOpenStationAfterReset, useResetStation } from '../api'
import { useReturnFromStationToUser } from './useReturnFromStationToUser'

export const useCompletedPopup = () => {
    const { t } = useTranslation('telling-station-sandbox')
    const { mutateAsync: finish } = useFinish()
    const { mutateAsync: openStationAfterReset } = useOpenStationAfterReset()
    const { mutateAsync: resetStation } = useResetStation()
    const { returnFromStationToUser } = useReturnFromStationToUser()
    const { setAccessToken } = useAccessToken()
    const { mutateAsync: restartTrainingSession } = useRestartTrainingSession()
    const { auth } = useAuth()

    const openCompletedPopup = (showConfirm: (settings: ConfirmPopupSettings) => void, hide: () => void) => {
        showConfirm({
            title: <PopupTitle>{t('completed_successfully')}!</PopupTitle >,
            text: t('you_can_restart_the_current_training_session'),
            cancelText: t('go_to_dashboard'),
            okText: t('restart_this_training'),
            onCancel: async () => {
                const response = await finish()
                setAccessToken(response.headers['access-token'])
                returnFromStationToUser(hide)
            },
            onOk: async () => {
                await resetStation(auth!.electionId!)
                await openStationAfterReset(auth!.electionId!)
                await restartTrainingSession()
                window.location.reload()
            },
            maskClosable: false
        })
    }

    return { openCompletedPopup }
}