import TableHeaderCell from 'components/atoms/TableHeaderCell'
import { InternalScrollTableDefaultColumn } from 'models'
import style from './index.module.scss'

type InternalScrollTableDefaultHeaderProps = {
    columns: InternalScrollTableDefaultColumn[]
}

function InternalScrollTableDefaultHeader({ columns }: InternalScrollTableDefaultHeaderProps) {
    return (
        <div className={style.header}>
            {columns.map((col, index) => <div
                key={index}
                style={{ width: col.width }}
                className={style.column}>
                <TableHeaderCell>
                    {col.title}
                </TableHeaderCell>
            </div >)}
        </div>
    )
}

export default InternalScrollTableDefaultHeader