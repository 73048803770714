import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionResult } from 'models'
import { getUrlParamsFromFilter } from 'utils'

type ResultsFilter = {
    offset?: number,
    limit?: number,
    elected?: boolean
}

export const useElectionResultsCandidates = (electionId: string, filter?: ResultsFilter, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectionResultsCandidates = async ({ queryKey }: QueryFunctionContext)
        : Promise<ElectionResult[]> => {
        const [, electionId, filter] = queryKey

        const queryParams = getUrlParamsFromFilter(filter as ResultsFilter)

        const res = await axios<ElectionResult[]>(
            `${moduleUrl}elections/${electionId}/election-results/candidates${queryParams ?
                `?${queryParams}` : ''}`)

        return res.data
    }

    return useQuery({
        queryKey: ['election-results-candidates', electionId, filter],
        queryFn: getElectionResultsCandidates,
        enabled
    })
}