
import Note from 'components/molecules/Note'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

function TieErrorBlock() {
    const { t } = useTranslation('teller')

    return (
        <div className={style.noteWrapper}>
            <Note mode={'error'} icon={'filled'}>
                <div className={style.textWrapper}>
                    {t('number_of_selected_electees_cannot_exceed_the_number_of_available_positions')} <br />
                </div>
            </Note>
        </div>
    )
}

export default TieErrorBlock