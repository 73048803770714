import { useElectionPeriodsYears } from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import Preloader from 'components/molecules/Preloader'
import { usePopup, useReportLoader } from 'hooks'
import { ReactComponent as IconPrinter } from 'icons/printer.svg'
import { ReportFormat, ReportType } from 'models'
import { useTranslation } from 'react-i18next'
import { useOfficersElectionLocalities } from '../../../../api'
import { useVotingPositionActions } from '../../../../hooks'
import styles from './index.module.scss'

const PageHeaderActions = () => {
    const { t } = useTranslation('officers-election')
    const { show, hide, popupPortal } = usePopup()
    const { loadReport, isReportLoading } = useReportLoader()
    const { verifyMembership } = useVotingPositionActions()

    const onVerify = () => {
        verifyMembership({ show, hide })
    }

    const { data: locality } = useOfficersElectionLocalities()
    const { data: bahaiYears } = useElectionPeriodsYears()

    const loadOfficerElectionResultsReport = () => {
        if (locality && bahaiYears && bahaiYears.length > 0) {
            const lastBahaiYear = bahaiYears[bahaiYears.length - 1]
            loadReport({
                reportType: ReportType.OfficersElectionResultsReport,
                reportFormat: ReportFormat.PDF,
                params: {
                    ballotingYear: lastBahaiYear.badiYear,
                    locality
                }
            })
        }
    }

    return (
        <>
            {popupPortal}
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            <div className={styles.pageHeaderActions}>
                <button
                    onClick={loadOfficerElectionResultsReport}
                    className="btn-main-tertiary-md">
                    <IconWrapper><IconPrinter width="20" height="20" /></IconWrapper>
                    {t('officers_election_report')}
                </button>
                <button className="btn-main-secondary-md" onClick={onVerify}>{t('verify_lsa_membership')}</button>
            </div>
        </>
    )
}

export default PageHeaderActions