import { useElectionRestrictionsOld } from 'api'
import { useMemo } from 'react'
import CreateElectionButton from '../CreateElectionButton'
import ManageOfficersElectionButton from '../ManageOfficersElection'
import styles from './index.module.scss'

const PageHeaderActions = () => {
    const { data: restrictions } = useElectionRestrictionsOld()

    const manageOfficersBtn = useMemo(() => {
        if (restrictions?.officersElectionRestriction?.electionCreationEnabled) {
            return <ManageOfficersElectionButton />
        }

        return null
    }, [restrictions])

    return (
        <div className={styles.pageHeaderActions}>
            {manageOfficersBtn}
            <CreateElectionButton />
        </div>
    )
}

export default PageHeaderActions