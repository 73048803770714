import { useAbility, useAuth, useChangeUser } from 'hooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ActionsPanel from '../ActionsPanel'
import Panel from '../common/Panel'
import PanelHeader from '../common/PanelHeader'
import style from './index.module.scss'

function SelectFunctionPanel() {
    const { t } = useTranslation('auth')
    const { auth } = useAuth()
    const navigate = useNavigate()
    const { resetTheme } = useChangeUser()
    const { ability } = useAbility()

    useEffect(() => {
        if (auth?.role && ability?.cannot('edit', 'RoleOnFunctionPage')) {
            navigate('/sign-in')
        }
        resetTheme()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Panel title={<div className={style.greeting}>
            <div tabIndex={0} className={style.welcome}>
                {t('common:welcome_name', { name: auth?.name, interpolation: { escapeValue: false } })}
            </div>
            <div
                tabIndex={0}
                className={style.bid}>
                {t('common:bid_id', { id: auth?.id })}
            </div>
        </div>}
        >
            <PanelHeader role={'contentinfo'} tabIndex={0} className={style.header}>
                    <label>
                        {t('select_the_function_you_are_performing')}
                    </label>
                </PanelHeader><ActionsPanel />
            </Panel>
    )
}

export default SelectFunctionPanel