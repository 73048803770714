import { useElection, useElectionSummary } from 'api'
import BackToDashboardLine from 'components/molecules/BackToDashboardLine'
import Preloader from 'components/molecules/Preloader'
import { queryClient } from 'config/query-client'
import { useBackToDashboardLine, useConfirmPopup, useHeaderLogoRedirect, useInfoPopup, usePopup } from 'hooks'
import { ElectionType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useComment } from '../../../../api'
import { useCancelEditingPopup, useCommentInfo, useConfirmResultsActions, useConfirmation } from '../../hooks'

function ConfirmResultsHeader() {
    const { t } = useTranslation('results')
    const { setBackToDashboardLine } = useBackToDashboardLine()
    const navigate = useNavigate()
    const { infoPortal, showInfo } = useInfoPopup()
    const { confirmPortal, showConfirm } = useConfirmPopup()
    const { show, popupPortal, hide } = usePopup()
    const { electionId } = useParams()
    const { data: election } = useElection(electionId!, !!electionId)
    const { data: summary } = useElectionSummary(electionId!, !!electionId)
    const { confirmResults } = useConfirmResultsActions({ showPopup: show, hidePopup: hide, showInfo })
    const { onConfirmPopup } = useCancelEditingPopup(showConfirm)
    const { candidatesToConfirm } = useConfirmation()
    const [waitingForConfirming, setWaitingForConfirming] = useState(false)
    const { commentInfo, setCommentInfo } = useCommentInfo()
    const { data: savedComment } = useComment(electionId || '', !!electionId)
    const { setConfirmationCallback } = useHeaderLogoRedirect()

    const onConfirm = () => {
        if (commentInfo.isEditing) {
            onConfirmPopup(async () => {
                setCommentInfo({ isEditing: false, comment: savedComment?.text || '' })
                confirmResults({
                    onConfirm: () => {
                        setWaitingForConfirming(true)
                    }
                })
            })
        } else {
            confirmResults({
                onConfirm: () => {
                    setWaitingForConfirming(true)
                }
            })
        }
    }

    useEffect(() => {
        if (commentInfo.isEditing) {
            setConfirmationCallback(true)
        }

        return () => {
            setConfirmationCallback(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commentInfo])

    const onBackButton = () => {
        if (commentInfo.isEditing) {
            onConfirmPopup(() => navigate(-1))
        } else {
            if (election?.type === ElectionType.LOCAL_DELEGATE_ELECTION) {
                navigate(`/local-delegate-election-board/${election.electionPeriod}`, { replace: true })
            } else {
                navigate(-1)
            }
        }
    }

    useEffect(() => {
        if (waitingForConfirming) {
            if (!summary?.confirmedBy) {
                setTimeout(async () => {
                    await queryClient.invalidateQueries(['election-summary'])
                }, 1000)

            } else {
                setWaitingForConfirming(false)
                navigate(`/confirmed-results/${electionId}`, {
                    replace: true,
                    state: { backButtonTitle: t('election:back_to_election_board') }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitingForConfirming, summary, electionId])

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine sticky fixedWidth
                onBackToDashboardBtnClick={() => { onBackButton() }}>
                <>
                    {!summary?.confirmedBy &&
                        <button
                            className="btn-main-primary-md"
                            onClick={() => onConfirm()}>
                            {election?.type === ElectionType.LOCAL_DELEGATE_ELECTION
                                ? t('common:confirm_results')
                                : t('confirm_results_and_send_to_dest', { dest: t('common:eMembership2') })}
                        </button>
                    }
                </>
            </BackToDashboardLine>
        )

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setBackToDashboardLine, election, candidatesToConfirm, summary, commentInfo, savedComment])

    return (
        <>
            {waitingForConfirming && <Preloader />}
            {infoPortal}
            {popupPortal}
            {confirmPortal}
        </>
    )
}

export default ConfirmResultsHeader