import { useElection, useElectionPushResult, useElectionSummary, useLocalDelegateElectionPushResult } from 'api'
import ContentWrapper from 'components/atoms/ContentWrapper'
import ElectionPushResultNote from 'components/molecules/ElectionPushResultNote'
import { electionResults } from 'features/electionResults'
import { useHandleEntityLoadingError } from 'hooks'
import { ElectionType } from 'models'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import AppointedTellers from '../components/AppointedTellers'
import ElectionBoardHeader from '../components/ElectionBoardHeader'
import EligibleIndividuals from '../components/EligibleIndividuals'
import ResultsManagement from '../components/ResultsManagement'
import Tasks from '../components/Tasks'


function ElectionBoard() {
    const { electionId } = useParams()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()

    const { data: election, error } = useElection(electionId!, !!electionId)
    const { data: summary } = useElectionSummary(electionId!, !!electionId)
    const { data: ldePushResult } = useLocalDelegateElectionPushResult(election?.electionPeriod.toString() || '',
        !!election && election?.type === ElectionType.LOCAL_DELEGATE_ELECTION)
    const { data: electionPushResult } = useElectionPushResult(election?.id.toString() || '',
        !!election && election.type !== ElectionType.LOCAL_DELEGATE_ELECTION)

    const pushResult = election?.type === ElectionType.LOCAL_DELEGATE_ELECTION ? ldePushResult : electionPushResult

    useEffect(() => { window.scrollTo({ top: 0, left: 0 }) }, [])

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [error])



    return (
        <>
            <ElectionBoardHeader />
            <ContentWrapper>
                {!!pushResult && <ElectionPushResultNote pushResult={pushResult} />}
                {!pushResult?.status && election?.type === ElectionType.LOCAL_DELEGATE_ELECTION &&
                    summary?.confirmedBy && <electionResults.components.LuElectionResultsNotPushedNote />}
                <Tasks />
                <ResultsManagement />
                <EligibleIndividuals />
                <AppointedTellers />
            </ContentWrapper>
        </>
    )
}

export default ElectionBoard