import { useOnlineVotingElection } from './api/queries/onlineVotingElection'
import { Panel } from './components/Panel'
import { Balloting } from './pages/Balloting'
import { Dashboard } from './pages/Dashboard'
export * from './models/balloting-election'
export * from './models/balloting-election-status'

export const onlineVoting = {
    api: {
        useOnlineVotingElection
    },
    components: {
        Panel: Panel
    },
    pages: {
        Dashboard: Dashboard,
        Balloting: Balloting
    }
}