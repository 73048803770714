import { useOfficersElections } from 'api'
import PopupTitle from 'components/atoms/PopupTitle'
import { PopupProps } from 'components/molecules/Popup'
import { queryClient } from 'config/query-client'
import { ConfirmPopupSettings, useLsaOfficerRoleMap } from 'hooks'
import { LsaOfficerRole, OfficersElection } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAnnounceOfficersElection, useCancelOfficersElection } from '../api'
import StartVoting from '../components/StartVoting'

type RevotePositionProps = {
    role: LsaOfficerRole
    onSuccess?: () => void
    electionToCancel?: OfficersElection
    showConfirm?: (settings: ConfirmPopupSettings) => void,
    show: (props: PopupProps) => void
    hide: () => void
}

export const useRevotePosition = () => {
    const { t } = useTranslation('officers-election')
    const { toString } = useLsaOfficerRoleMap()
    const [waitingForCreating, setWaitingForCreating] = useState(false)
    const [onSuccessCb, setOnSuccessCb] = useState<() => void>(() => { })

    const { mutateAsync: cancelOfficersElection } = useCancelOfficersElection()
    const { mutateAsync: announce } = useAnnounceOfficersElection()
    const { data: elections } = useOfficersElections()

    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval> | undefined

        if (waitingForCreating) {
            intervalId = setInterval(async () => {
                await queryClient.invalidateQueries(['my-officers-election'])
                if (elections?.length) {
                    clearInterval(intervalId)
                    setWaitingForCreating(false)
                    onSuccessCb?.()
                }
            }, 1000)
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitingForCreating, elections])

    const revotePosition = async ({
        role,
        onSuccess,
        electionToCancel,
        showConfirm,
        show,
        hide
    }: RevotePositionProps) => {
        setOnSuccessCb(() => onSuccess)

        const performRevote = async () => {
            show({
                title: <PopupTitle>
                    {`${t('start_voting_session_for_the_position_of')} ${toString(role)}`}?
                </PopupTitle >,
                children: <StartVoting
                    onStartVoting={async () => {
                        if (!!electionToCancel) {
                            await cancelOfficersElection(electionToCancel.electionPeriod.toString())
                        }
                        await announce(role)
                        setWaitingForCreating(true)
                    }
                    }
                    onCancel={() => hide()}
                    viewMode={false}
                />,
                footer: null
            })
        }

        if (showConfirm) {
            showConfirm({
                title: t('are_you_sure_you_want_to_start_revoting_for_this_position') + '?',
                text: t('please_note_this_is_an_irreversible_action'),
                okText: t('start_revoting'),
                onOk: async () => {
                    await performRevote()
                }
            })
        } else {
            await performRevote()
        }
    }

    return { revotePosition, waitingForCreating }
}