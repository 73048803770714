
import ElectionEndYearBlock from './components/ElectionEndYearBlock'
import InfoBlock from './components/InfoBlock'
import InstitutionName from './components/InstitutionName'
import InvalidBallotsStatistic from './components/InvalidBallotsStatistic'
import InvalidVotesStatistic from './components/InvalidVotesStatistic'
import ListItem from './components/ListItem'
import ListOfCandidates from './components/ListOfCandidates'
import ReportLeftCardBlock from './components/ReportLeftCardBlock'
import ReportRightCardBlock from './components/ReportRightCardBlock'
import StationInfo from './components/StationInfo'
import StatisticList from './components/StatisticList'
import SubtitleStatistic from './components/SubtitleStatistic'
import TitleStatistic from './components/TitleStatistic'

export const reportingAssets = {

    components: {
        TitleStatistic,
        ListOfCandidates,
        SubtitleStatistic,
        StatisticList,
        InvalidBallotsStatistic,
        InvalidVotesStatistic,
        ListItem,
        InfoBlock,
        InstitutionName,
        ReportLeftCardBlock,
        ReportRightCardBlock,
        StationInfo,
        ElectionEndYearBlock
    }
}