import { Col, Row } from 'antd'
import { useElectionVoters } from 'api'
import Card from 'components/atoms/Card'
import CardHeader from 'components/molecules/CardHeader'
import { ElectionParticipant, RidvanElectionPeriod } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UnitElectedDelegates } from '../../models'
import UnitElectedLocalDelegates from '../UnitElectedLocalDelegates'
import style from './index.module.scss'

type ElectedLocalDelegatesProps = {
    ridvanElectionPeriod: RidvanElectionPeriod
}

function ElectedLocalDelegates({ ridvanElectionPeriod }: ElectedLocalDelegatesProps) {
    const { t } = useTranslation('election')
    const [electionParticipants, setElectionParticipants] = useState<ElectionParticipant[]>([])

    const { data: votersResponse } = useElectionVoters(
        ridvanElectionPeriod!.ridvanElectionStage!.election!.id.toString(), !!ridvanElectionPeriod)

    useEffect(() => {
        if (votersResponse) {
            setElectionParticipants(votersResponse.voters)
        } else {
            setElectionParticipants([])
        }
    },
        [votersResponse])

    const [unitsDelegates, setUnitsDelegates] = useState<UnitElectedDelegates[]>([])

    useEffect(() => {
        if (electionParticipants?.length) {
            const units = electionParticipants.map(p => p.homeLocalUnit?.code)
            const uniqueUnits = [...new Set(units)].sort()
            const delegatesByUnit: UnitElectedDelegates[] = uniqueUnits.map(unit => ({ unit, delegates: [] }))
            delegatesByUnit.forEach(u => {
                const delegates = electionParticipants.filter(p => p.homeLocalUnit?.code === u.unit)
                    .sort((a, b) => {
                        if (a.name.toUpperCase() < b.name.toUpperCase())
                            return -1
                        if (a.name.toUpperCase() > b.name.toUpperCase())
                            return 1

                        return 0
                    })
                u.delegates = delegates
            })
            setUnitsDelegates([...delegatesByUnit])
        } else {
            setUnitsDelegates([])
        }
    }, [electionParticipants])


    return (
        <Card title={<CardHeader title={t('elected_local_delegates')} />} >
            <Row>
                <Col span={24}>
                    <div className={style.tip}>
                        {/* eslint-disable-next-line max-len */}
                        {t('listing_below_are_the_elected_local_delegates_for_your_locality_the_list_cannot_be_modified')}
                    </div>
                </Col>
                <Col span={12}>
                    {unitsDelegates?.map(u => <UnitElectedLocalDelegates
                        unitElectedDelegates={u}
                        key={u.unit} />)}
                </Col>
            </Row>
        </Card>
    )
}

export default ElectedLocalDelegates