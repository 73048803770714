import { ReactNode } from 'react'
import style from './index.module.scss'

type FilterableHeaderCellProps = {
    filter: ReactNode,
    title: ReactNode,
    size?: 'small' | 'medium'
}

function FilterableHeaderCell({ filter, title, size = 'medium' }: FilterableHeaderCellProps) {
    return (
        <div className={`${style.filterable} ${style[size]}`}>
            <div className={style.filter}>{filter}</div>
            <div className={style.title}>{title}</div>
        </div>
    )
}

export default FilterableHeaderCell