import { Modal, ModalProps } from 'antd'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import { useEffect, useRef } from 'react'
import styles from './popup.module.scss'

export type PopupProps = Omit<ModalProps, 'closeIcon'> & {
    closeIcon?: boolean,
    stayOpenedOnCancel?: boolean
}

function Popup(props: PopupProps) {
    const popupRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const closeBtnWrapper = document.querySelector('.ant-modal-close')
        if (closeBtnWrapper) {
            closeBtnWrapper.setAttribute('tabindex', '-1')
            closeBtnWrapper.setAttribute('aria-hidden', 'true')
        }

        const modal = document.querySelector('.ant-modal')

        if (modal) {
            modal?.setAttribute('role', 'group')
            modal?.setAttribute('aria-labelledby', 'dialogLabel')
            modal?.setAttribute('aria-describedby', 'dialogDesc')
        }
    }, [])

    const closeIcon = <div className={styles.dismissIcon} ref={popupRef}><Dismiss /></div>

    return (
        <div className={styles.wrapper}>
            <Modal {...props}
                closeIcon={props.closeIcon ? closeIcon : false}
                width={props.width || 432}
                centered={props.centered === undefined ? true : props.centered}
            />
        </div>
    )
}

export default Popup
