import { Form } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import Card from 'components/atoms/Card'
import CardHeader from 'components/molecules/CardHeader'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOfficers } from '../../hooks'
import { ByElectionOfficer } from '../../models'
import NoLongerServingLsaOfficer from '../NoLongerServingLsaOfficer'
import style from './index.module.scss'

type NoLongerServingProps = {
    form: FormInstance
}

function NoLongerServing({ form }: NoLongerServingProps) {
    const { t } = useTranslation('election')
    const { officers } = useOfficers()
    const [noLongerServingOfficers, setNoLongerServingOfficers] = useState<ByElectionOfficer[]>([])

    useEffect(() => {
        const noLongerServing = officers.filter(o => !!o.name && o.noLongerServing)
        setNoLongerServingOfficers(noLongerServing)
        form.setFieldsValue({ officers: [...noLongerServing] })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [officers])

    return (
        <>
            {!!noLongerServingOfficers.length
                && <Card title={<CardHeader title={t('individuals_no_longer_serving')} />} >
                    <div className={style.note}>
                        {t('listing_below_are_individual_s_who_will_no_longer_serve_on_your_lsa')}
                    </div>
                    <Form
                        name="noLongerServing"
                        layout="vertical"
                        form={form}
                    >
                        <Form.List
                            name="officers"
                        >
                            {(fields) => (
                                <>
                                    {fields.map((field, index) => (
                                        <div className={style.officer} key={noLongerServingOfficers[index].id}>
                                            <NoLongerServingLsaOfficer
                                                officer={noLongerServingOfficers[index]} index={index} />
                                        </div>
                                    )
                                    )}
                                </>
                            )
                            }
                        </Form.List>
                    </Form>
                </Card>
            }
        </>
    )
}

export default NoLongerServing