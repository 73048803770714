import { Select as AntSelect, Col, Form, Row } from 'antd'
import { useElectionWordingConfiguration } from 'api'
import { Select } from 'components/atoms/Select'
import { IWizardStepProps } from 'components/molecules/Wizard/models'
import { queryClient } from 'config/query-client'
import dayjs from 'dayjs'
import DOMPurify from 'dompurify'
import { useConfirmPopup, useHandleEntityLoadingError, useInfoPopup } from 'hooks'
import { ReactComponent as ChevronBold } from 'icons/chevron-bold.svg'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ElectionPeriodStatus, ElectionStatusType, ElectionType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { isFormValid, isToday, toDayjs } from 'utils'
import ElectionEditingWizard from '../../../../components/ElectionEditingWizard'
import ManageElectionTellersForm from '../../../../components/ManageElectionTellersForm'
import SetUpElectionForm from '../../../../components/SetUpElectionForm'
import {
    useCheckIfStartDateExpired,
    useElectionWizardChipsSettings,
    useExtractSetUpElectionSchedule,
    useManageTellersActions,
    useSaveElectionAsDraft,
    useSetUpElectionActions,
    useVotingStartDateDependentTitles
} from '../../../../hooks'
import { useAnnounceByElectionPeriod, useDefineCandidates, useElectionPeriod, useSetUpSchedule } from '../../api'
import { useExtractNoLongerServing, useOfficers } from '../../hooks'
import Officers from '../Officers'
import VerifyByElectionParticipants from '../VerifyByElectionParticipants'

function ByElectionEditingProcess() {
    const { t } = useTranslation('election')
    const navigate = useNavigate()
    const { electionPeriodId } = useParams()
    const { officers } = useOfficers()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()

    const { data: electionPeriod, error } = useElectionPeriod(electionPeriodId!)
    const { data: wordingConfig } = useElectionWordingConfiguration(ElectionType.BY_ELECTION)
    const { mutateAsync: setUpSchedule } = useSetUpSchedule()
    const { mutateAsync: defineCandidates } = useDefineCandidates()
    const { mutateAsync: announce } = useAnnounceByElectionPeriod()

    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [initStep, setInitStep] = useState<number | undefined>()

    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { showInfo, infoPortal } = useInfoPopup()

    const [setUpElectionForm] = Form.useForm()
    const [manageTellersForm] = Form.useForm()

    const { manageTellers } = useManageTellersActions()
    const { saveAsDraft } = useSaveElectionAsDraft()
    const { checkIfStartDateExpired } = useCheckIfStartDateExpired(showInfo)
    const { saveSetUpElectionForm } = useSetUpElectionActions()
    const { extractSchedule } = useExtractSetUpElectionSchedule()
    const { extractNoLongerServing } = useExtractNoLongerServing()
    const { setUpElectionChipSettings, verifyParticipantsChipSettings, setUpTellersChipSettings }
        = useElectionWizardChipsSettings()
    const { getCompleteSetUpButtonText, getLastStepNote } = useVotingStartDateDependentTitles()

    useEffect(() => {
        if (electionPeriod) {
            if (electionPeriod.status === ElectionPeriodStatus.CANCELED
                || electionPeriod.status === ElectionPeriodStatus.CLOSED) {
                navigate('/', { replace: true })

                return
            }
        }
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electionPeriod, error])

    const saveSetUpForm = async () => {
        const electionSchedule = extractSchedule(setUpElectionForm)
        const noLongerServing = extractNoLongerServing(setUpElectionForm)
        officers.filter(o => !o.name && o.noLongerServing).forEach(() => noLongerServing.push({
            id: '',
            officeHeld: '',
            reasonForVacancy: ''
        }))

        await defineCandidates({
            electionPeriod: electionPeriod?.id.toString() || '',
            noLongerServing
        })
        await setUpSchedule({
            schedule: electionSchedule,
            electionPeriod: electionPeriod?.id.toString() || ''
        })

        await queryClient.invalidateQueries(['by-election-period', electionPeriodId])
    }

    const saveForm = async () => await saveSetUpElectionForm({
        form: setUpElectionForm,
        withValidation: true,
        saveFunc: saveSetUpForm,
        checkExtraValidation: async () => {
            Promise.resolve(!!officers.filter(o => o.noLongerServing).length)

            const promise = new Promise<boolean>(async (resolve) => {
                if (!!officers.filter(o => o.noLongerServing).length) {
                    resolve(true)
                } else {
                    showInfo({
                        title: t('no_vacancies'),
                        text: t('please_mark_the_member_s_who_will_no_longer_serve'),
                        onOk: () => { }
                    })
                    resolve(false)
                }
            })

            return promise
        }
    })

    useEffect(() => {
        if (electionPeriod) {
            const byElection = electionPeriod.byElection
            if (byElection.onlineVotingStart &&
                (byElection.status === ElectionStatusType.DRAFT
                    || byElection.status === ElectionStatusType.NOT_STARTED)) {
                checkIfStartDateExpired(byElection.onlineVotingStart, () => { setInitStep(0) })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electionPeriod])

    useEffect(() => {
        const formValue = setUpElectionForm.getFieldsValue()
        setUpElectionForm.setFieldsValue({
            ...formValue,
            numberOfVacancies: officers.filter(o => o.noLongerServing).length
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [officers])

    const getVotingStartDate = () => {
        const formValue = setUpElectionForm.getFieldValue('onlineVotingStart')
        if (formValue)
            return formValue
        if (electionPeriod && electionPeriod!.byElection.onlineVotingStart) {
            return dayjs(new Date(electionPeriod!.byElection.onlineVotingStart))
        }

        return null
    }

    const setUpElectionFormInitial = {
        ballotingYear: t('common:year_b_e_period',
            {
                year: electionPeriod?.ballotingYear,
                period: `${dayjs().year()}-${dayjs().year() + 1}`
            }),
        numberOfVacancies: 0,
        timeZone: electionPeriod?.byElection.timeZone,
        electionDay: electionPeriod?.byElection.electionDay ?
            toDayjs(electionPeriod?.byElection.electionDay)
            : undefined,
        onlineVotingStart: electionPeriod?.byElection.onlineVotingStart ?
            toDayjs(electionPeriod.byElection.onlineVotingStart)
            : undefined
    }

    const getLastStepButtonText = () => getCompleteSetUpButtonText(getVotingStartDate())

    const steps: IWizardStepProps[] = [
        {
            chipSettings: setUpElectionChipSettings,
            nextButton: {
                callback: async () => await saveForm(),
                icon: <ChevronBold height="10px" width="5.5px" />
            },
            children: <Row justify="space-evenly" gutter={16}>
                <Col span={12}>
                    <SetUpElectionForm form={setUpElectionForm}
                        electionType={ElectionType.BY_ELECTION}
                        initialValues={setUpElectionFormInitial}
                        importantText={<div dangerouslySetInnerHTML={
                            { __html: DOMPurify.sanitize(wordingConfig?.importantMessage || '') }} />}>
                        <Form.Item
                            name="numberOfVacancies"
                            label={<>
                                {t('number_of_vacancies')}
                            </>}>
                            <Select
                                disabled={true}
                                placeholder={t('number_of_vacancies')}>
                                <AntSelect.Option value={0}>
                                    {0}
                                </AntSelect.Option>
                            </Select>
                        </Form.Item>
                    </SetUpElectionForm>
                </Col>
                <Col span={12}>
                    <Officers
                        form={setUpElectionForm}
                        noLongerServing={electionPeriod?.byElection?.noLongerServing || []} />
                </Col>
            </Row>
        }
        ,
        {
            chipSettings: verifyParticipantsChipSettings,
            nextButton: { callback: () => Promise.resolve(true), icon: <Chevron height="10" width="5.5" /> },
            leaveStepCallback: () => Promise.resolve(true),
            children: <VerifyByElectionParticipants electionPeriodId={electionPeriod?.id.toString() || ''} />
        },
        {
            chipSettings: setUpTellersChipSettings,
            nextButton: {
                callback: async () => {
                    const tellersValid = await isFormValid({ form: manageTellersForm })
                    if (!tellersValid) {
                        return false
                    }
                    if (electionPeriod?.byElection.status === ElectionStatusType.DRAFT
                        || electionPeriod?.byElection.status === ElectionStatusType.NOT_STARTED) {
                        if (await checkIfStartDateExpired(getVotingStartDate(), () => { setInitStep(0) })) {
                            return Promise.resolve(false)
                        }
                    }

                    const promise = new Promise<boolean>(async (resolve) => {
                        const electionStringDetails = {
                            electionYear: electionPeriod?.ballotingYear,
                            electionName: t('by_election')
                        }

                        showConfirm({
                            onOk: async () => {
                                await manageTellers({
                                    form: manageTellersForm,
                                    electionId: electionPeriod?.byElection.id.toString() || ''
                                })
                                await announce(electionPeriod?.id.toString() || '')
                                navigate('/')
                                resolve(true)
                            },
                            onCancel: async () => resolve(false),
                            title: isToday(getVotingStartDate())
                                ? t('complete_setup_and_start_online_election')
                                : t('complete_setup'),
                            text: isToday(getVotingStartDate())
                                ? t('you_are_about_to_start_the_year_name_would_you_like_to_proceed',
                                    electionStringDetails)
                                // eslint-disable-next-line max-len
                                : t('you_are_about_to_complete_setup_and_create_the_year_name_would_you_like_to_proceed',
                                    electionStringDetails
                                ),
                            okText: isToday(getVotingStartDate())
                                ? t('start_election') : t('complete_setup')
                        })
                    })

                    return promise
                },
                title: getLastStepButtonText()
            },
            minHeaderContentWidth: 1016,
            children: <>{!!electionPeriod && <ManageElectionTellersForm
                form={manageTellersForm} canSaveWithoutMandatoryTellers
                election={electionPeriod?.byElection} />
            }
            </>
        }

    ]

    const checkAndSaveAsDraft = async () => {
        const votingStartDate = getVotingStartDate()

        if (votingStartDate) {
            const isExpired = await checkIfStartDateExpired(votingStartDate.format(), () => { setInitStep(0) })
            if (isExpired) {
                return
            }
        }

        saveAsDraft({
            showConfirm: showConfirm,
            showInfo: showInfo,
            nextStepButtonText: currentStepIndex === 2 ? getLastStepButtonText() : t('common:next_step'),
            saveAsItIs: async () => {
                switch (currentStepIndex) {
                    case 0:
                        return await saveSetUpElectionForm({
                            form: setUpElectionForm,
                            saveFunc: saveSetUpForm
                        })
                    case 2:
                        await manageTellers({
                            form: manageTellersForm,
                            electionId: electionPeriod?.byElection.id.toString() || ''
                        })

                        return Promise.resolve(true)
                }

                return Promise.resolve(true)
            }
        })
    }

    return (
        <>
            {confirmPortal}
            {infoPortal}
            {electionPeriod && <ElectionEditingWizard
                announced={electionPeriod.byElection.status !== ElectionStatusType.DRAFT}
                steps={steps}
                initStep={initStep}
                saveAsDraftCb={checkAndSaveAsDraft}
                lastStepNote={getLastStepNote(getVotingStartDate())
                    .map((line, index) => <div key={index}>{line}</div>)}
                type={ElectionType.BY_ELECTION}
                year={electionPeriod.ballotingYear}
                wizardStepStorageKey={`${ElectionType.BY_ELECTION}_${electionPeriodId}_wizard_step`}
                onCurrentStepChange={(index) => setCurrentStepIndex(index)} />
            }
        </>
    )
}

export default ByElectionEditingProcess