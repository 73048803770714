import dayjs from 'dayjs'
import { InfoPopupSettings } from 'hooks'
import { useTranslation } from 'react-i18next'
import { toDayjs } from 'utils'

export const useCheckIfStartDateExpired = (showInfo: (settings: InfoPopupSettings) => void) => {
    const { t } = useTranslation('election')

    const checkIfStartDateExpired = (startDate: string, onExpired: () => void) => {
        const promise = new Promise<boolean>((resolve) => {
            const onlineVotingStart = toDayjs(startDate)
            const isInPast = onlineVotingStart && onlineVotingStart.endOf('day').diff(dayjs().endOf('day'), 'days') < 0
            if (isInPast) {
                showInfo(
                    {
                        title: t('invalid_online_voting_starts_date'),
                        text: t('selected_online_voting_starts_date_has_already_passed'),
                        onOk: () => {
                            onExpired()
                            resolve(true)
                        },
                        stayOpenedOnCancel: true
                    }
                )
            } else {
                resolve(false)
            }
        })

        return promise
    }

    return { checkIfStartDateExpired }
}