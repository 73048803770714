import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { BallotsSummary } from 'models'

type ElectionsBallotsSummaryParams = {
    electionIds: string[]
}

export const useElectionsBallotsSummary =
    ({ electionIds }: ElectionsBallotsSummaryParams, enabled?: boolean) => {
        const axios = useAxios()
        const moduleUrl = getModuleUrl(ApiModule.TellingStation)

        const getElectionsBallotsSummary = async () => {
            const res = await axios<{ election: number, ballots: BallotsSummary }[]>(
                `${moduleUrl}elections/ballots/count?elections=${electionIds.join(',')}`)

            return res.data
        }

        return useQuery({
            queryKey: ['elections-ballots-count', electionIds],
            queryFn: getElectionsBallotsSummary,
            enabled
        })
    }