import { Select as AntSelect, Col, Form, FormInstance, Row } from 'antd'
import { MaskedInput } from 'antd-mask-input'
import { useCandidatesToServe, useElectionTellingStations } from 'api'
import Card from 'components/atoms/Card'
import IconWrapper from 'components/atoms/IconWrapper'
import Input from 'components/atoms/Input'
import RequiredMark from 'components/atoms/RequiredMark'
import { Select } from 'components/atoms/Select'
import Tooltip from 'components/atoms/Tooltip'
import CardHeader from 'components/molecules/CardHeader'
import LookupSelect, { LookupOption } from 'components/molecules/LookupSelect'
import { useAbility, useAuth, useConfirmPopup, useInfoPopup, useTellerTypeMap } from 'hooks'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import { AgeCategory, ElectionBase, ElectionParticipant, Gender, TellerType } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { applyPhoneMask, getNameWithMatchHighlighting, isEmailValidFunc, phoneNumberValidator } from 'utils'
import { SelectedTeller } from '../../models'
import style from './index.module.scss'

const maxTellersInDropDown = 30
const MaxTellersCount = 20
const MaxAssistantsCount = 5
const maxEmailLength = 100

type TellerSelectionFormProps = {
    initSelectedTeller: SelectedTeller,
    selectedTellers: LookupOption[]
    tellerName: number,
    onRemove: () => void,
    onChangeRole: () => void,
    onChange?: (reset: (autoClear?: boolean) => void, participant?: ElectionParticipant) => void,
    form: FormInstance<any>,
    election: ElectionBase,
    canSaveWithoutMandatoryTellers?: boolean
}

function TellerSelectionForm({ initSelectedTeller, selectedTellers, tellerName, onRemove,
    form, election, onChangeRole, onChange, canSaveWithoutMandatoryTellers }
    : TellerSelectionFormProps) {
    const { toString } = useTellerTypeMap()
    const { t } = useTranslation()
    const { auth } = useAuth()
    const { ability } = useAbility()
    const { confirmPortal, showConfirm } = useConfirmPopup()
    const { infoPortal, showInfo } = useInfoPopup()
    const [validatable, setValidatable] = useState(false)
    const [tellerIsPreselected, setTellerIsPreselected] = useState<boolean>(!!initSelectedTeller.id)
    const [nameFilter, setNameFilter] = useState<string | undefined>(undefined)
    const [selectedTeller, setSelectedTeller] = useState<SelectedTeller>({ ...initSelectedTeller })
    const { data, isFetching } = useCandidatesToServe(election?.id.toString(),
        { name: nameFilter, limit: maxTellersInDropDown, offset: 0, possibleRole: selectedTeller.role! },
        !tellerIsPreselected && !!selectedTeller.role && !!nameFilter?.length)
    const [checkIfCandidateIsAssistant, setCheckIfCandidateIsAssistant] = useState(false)
    const { data: candidateAsAssistant, isLoading: isCandidateLoading } = useCandidatesToServe(election?.id.toString(),
        {
            id: selectedTeller.id,
            limit: maxTellersInDropDown,
            offset: 0,
            possibleRole: TellerType.CHIEF_TELLER_ASSISTANT
        },
        checkIfCandidateIsAssistant)
    const { data: tellingStations } = useElectionTellingStations(election.id.toString() || auth!.electionId!,
        !!election.id || !!auth?.electionId)
    const [clear, setClear] = useState(false)
    const [isPhoneTouched, setIsPhoneTouched] = useState(false)
    const [isEmailTouched, setIsEmailTouched] = useState(false)
    const isTellerInAnyStation = tellingStations?.some((station) =>
        station.tellers.some((teller) => teller.id === selectedTeller.id)
    )

    const emailLengthError = t('email_cannot_exceed_characters', { maxEmailLength })
    const emailValidationError = t('please_enter_the_valid_input', { input: t('email') })

    const preselectedTeller: ElectionParticipant | undefined = initSelectedTeller.id
        ? {
            ageCategory: AgeCategory.ADULT,
            candidate: true,
            city: '',
            gender: Gender.MALE,
            id: initSelectedTeller.id,
            email: initSelectedTeller.email,
            name: initSelectedTeller.name,
            phone: initSelectedTeller.phone,
            voter: false,
            homeLocality: {
                code: '',
                name: '',
                state: '',
                stateName: ''
            },
            homeLocalUnit: {
                code: '',
                name: ''
            }
        }
        : undefined

    const onTellerChange = (participant?: ElectionParticipant) => {
        if (participant) {
            setNameFilter(undefined)
            setClear(false)
            let newTeller: SelectedTeller
            if (participant.id === initSelectedTeller.id) {
                newTeller = {
                    ...selectedTeller,
                    id: initSelectedTeller.id,
                    name: initSelectedTeller.name,
                    phone: initSelectedTeller.phone ? applyPhoneMask(initSelectedTeller.phone) : '',
                    email: initSelectedTeller.email
                }
            } else {

                newTeller = {
                    ...selectedTeller,
                    id: participant.id || '',
                    name: participant.name || '',
                    phone: participant.phone ? applyPhoneMask(participant.phone) : '',
                    email: participant.email
                }
            }

            form.setFieldValue(['tellers', tellerName], { ...newTeller })

            setSelectedTeller({ ...newTeller })
            setValidatable(!!participant)
        } else {
            reset()
        }
        onChange?.(reset, participant)
        form.validateFields(['tellers', tellerName])
    }

    const reset = (autoClear?: boolean) => {
        setTellerIsPreselected(false)
        setClear(autoClear || false)
        const newTeller: SelectedTeller = {
            ...selectedTeller,
            id: '',
            name: '',
            phone: '',
            email: undefined
        }
        setSelectedTeller({ ...newTeller })
        form.setFieldValue(['tellers', tellerName], { ...newTeller })
        form.validateFields(['tellers', tellerName])
        setValidatable(false)
        setIsPhoneTouched(false)
        setIsEmailTouched(false)
    }

    const onRoleChange = (value: TellerType) => {

        if (value === TellerType.TELLER
            && selectedTellers.filter((r: any) => r.role === TellerType.TELLER).length === MaxTellersCount) {
            showInfo({
                title: t('number_of_entity_exceeded', { entity: t('election:tellers') }),
                text: t('teller:quantity_of_tellers_for_election_cannot_be_greater_than_number',
                    { number: MaxTellersCount }),
                onOk: () => {
                    form.setFieldValue(['tellers', tellerName, 'role'], selectedTeller.role)
                }
            })

            return
        }

        if (value === TellerType.CHIEF_TELLER_ASSISTANT && selectedTellers
            .filter((r: any) => r.role === TellerType.CHIEF_TELLER_ASSISTANT).length === MaxAssistantsCount) {
            showInfo({
                title: t('number_of_entity_exceeded', { entity: t('election:assistant_chief_tellers') }),
                text: t('teller:quantity_of_assistant_chieft_tellers_for_election_cannot_be_greater_than_number',
                    { number: MaxAssistantsCount }),
                onOk: () => {
                    form.setFieldValue(['tellers', tellerName, 'role'], selectedTeller.role)
                }
            })

            return
        }

        if (value === TellerType.CHIEF_TELLER_ASSISTANT && selectedTeller.id) {
            setCheckIfCandidateIsAssistant(true)
        }

        setSelectedTeller({
            ...selectedTeller,
            role: value
        })

        onChangeRole()

        form.validateFields({ validateOnly: true })
    }

    useEffect(() => {
        if (!candidateAsAssistant?.length && checkIfCandidateIsAssistant) {
            reset(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candidateAsAssistant])

    useEffect(() => {
        if (!isCandidateLoading) {
            setCheckIfCandidateIsAssistant(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCandidateLoading])

    const onTellerRemove = () => {
        if (isTellerInAnyStation) {
            showConfirm({
                onOk: async () => onRemove(),
                title: t('teller:delete_teller_assigned_to_telling_station'),
                text:
                    <>
                        <div className={style.popupText}>
                            {t('teller:are_you_sure_you_want_to_delete_this_teller')}
                        </div>
                        <div>{t('teller:please_note_that_the_teller_is_assigned_to_the_telling_station')}</div>
                    </>,
                okText: t('teller:delete_teller')
            })
        } else {
            showConfirm({
                onOk: async () => onRemove(),
                title: t('teller:delete_teller'),
                text: t('teller:are_you_sure_you_want_to_delete_this_teller'),
                okText: t('teller:delete_teller')
            })
        }
    }

    const handleClearRequested = (clearFunction: () => void) => {
        showConfirm({
            onOk: async () => clearFunction(),
            title: t('teller:delete_teller_assigned_to_telling_station'),
            text:
                <>
                    <div className={style.popupText}>
                        {t('teller:are_you_sure_you_want_to_delete_this_teller')}
                    </div>
                    <div>{t('teller:please_note_that_the_teller_is_assigned_to_the_telling_station')}</div>
                </>,
            okText: t('teller:delete_teller')
        })
    }

    const emailRequiredRule = {
        required: validatable && selectedTeller.role && selectedTeller.role !== TellerType.TELLER,
        message: t('please_enter_the_input', { input: t('email') })
    }

    const canDelete = useMemo(() => (
        (auth?.id !== selectedTeller?.id) || ability?.can('delete', 'MyselfAsTeller')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [auth, selectedTeller])

    return (
        <Card
            highlighted={!selectedTeller?.mandatory}
            title={<CardHeader title={
                selectedTeller?.mandatory
                    ? <> {toString(selectedTeller.role!)} {!canSaveWithoutMandatoryTellers ? <RequiredMark /> : ''} </>
                    : <Row gutter={24}>
                        <Col span={9} >
                            <Form.Item
                                name={[tellerName, 'role']}
                                className={`${style.typeSelect} no-padding small`}
                                rules={[{
                                    required: validatable,
                                    message: t('common:please_select_the_input', { input: t('role') })
                                }
                                ]}
                            >
                                <Select
                                    className="small"
                                    placeholder={t('election:select_role')}
                                    disabled={!canDelete}
                                    onChange={(value) => { onRoleChange(value as TellerType) }}>
                                    <AntSelect.Option
                                        value={TellerType.CHIEF_TELLER_ASSISTANT}>
                                        {toString(TellerType.CHIEF_TELLER_ASSISTANT)}</AntSelect.Option>)
                                    <AntSelect.Option
                                        value={TellerType.TELLER}
                                    >{toString(TellerType.TELLER)}</AntSelect.Option>)
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col flex="auto" />
                        <Col flex="32px">
                            <Tooltip
                                title={t('teller:delete_teller')}
                                destroyTooltipOnHide={true}
                            >
                                {!selectedTeller.mandatory && canDelete &&
                                    <button
                                        className={`btn-main-tertiary-md ${style.deleteButton}`}
                                        type="button"
                                        onClick={() => onTellerRemove()}>
                                        <IconWrapper><Dismiss width="16" height="16" /></IconWrapper>
                                    </button>
                                }
                            </Tooltip>
                        </Col>
                    </Row>}
            />}>

            {confirmPortal}
            {infoPortal}
            <Row gutter={24}>
                <Col span={9}>
                    <Form.Item
                        className="no-padding"
                        name={[tellerName, 'candidate']}
                        label={t('full_name')}
                        validateTrigger={['onBlur', 'onChange']}
                        rules={[{
                            validator: (_, value) => {
                                if ((canSaveWithoutMandatoryTellers && selectedTeller.mandatory)
                                    || selectedTeller.id
                                    || !selectedTeller.role) {
                                    return Promise.resolve()
                                }

                                return Promise.reject()
                            },
                            required: (selectedTeller.role && !selectedTeller?.mandatory)
                                || (selectedTeller?.mandatory && !canSaveWithoutMandatoryTellers),
                            message: t('please_enter_the_input', { input: t('full_name') })
                        }]}
                    >
                        <LookupSelect
                            onBeforeClear={isTellerInAnyStation ? handleClearRequested : undefined}
                            disabled={!selectedTeller.role || !canDelete}
                            onChange={(teller) => onTellerChange(teller)}
                            popupWidth={584}
                            optionRenderFunction={(participant) =>
                                <>
                                    {getNameWithMatchHighlighting(participant.name, nameFilter)}
                                    <span> ({t('bid')}: {participant.id})</span>
                                </>}
                            alreadySelectedOptions={selectedTellers as any}
                            autoSelectOnFirstDataReceive={!!initSelectedTeller.id}
                            onSearchChange={(name) => setNameFilter(name?.trim())}
                            options={tellerIsPreselected ? [preselectedTeller!] : data}
                            showSearch={initSelectedTeller.id !== selectedTeller.id}
                            isFetchingOptions={isFetching}
                            autoClear={clear}
                            placeholder={t('teller:start_typing_the_name')} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        className="no-padding"
                        name={[tellerName, 'id']}
                        label={t('bahai_id')}
                        validateTrigger="onBlur" >
                        <Input
                            disabled
                            placeholder={t('bahai_id')}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={[tellerName, 'phone']}
                        className="no-padding"
                        label={t('phone')}
                        rules={selectedTeller.id ? [
                            {
                                message: t('please_enter_the_valid_input', { input: t('phone') }),
                                validator: (_, value) => {
                                    if (!isPhoneTouched) {
                                        return Promise.resolve()
                                    }

                                    return phoneNumberValidator(_, value)
                                }
                            }
                        ] : []}
                        validateTrigger={['onBlur', 'onChange']}
                    >{selectedTeller.id ?
                        <MaskedInput
                            mask="(000) 000-0000"
                            maskOptions={{ lazy: true }}
                            placeholder={t('phone')}
                            onBlur={() => setIsPhoneTouched(true)}
                        />
                        :
                        <Input
                            placeholder={t('phone')}
                            disabled
                        />
                        }
                    </Form.Item>

                </Col>
                <Col span={7}>
                    <Form.Item
                        className="no-padding"
                        name={[tellerName, 'email']}
                        label={t('email')}
                        rules={isEmailTouched ? [emailRequiredRule,
                            {
                                validator: (_, value) =>
                                    isEmailValidFunc(emailValidationError, emailLengthError, maxEmailLength, _, value)
                            }
                        ] : [emailRequiredRule]}
                        validateTrigger={['onBlur', 'onChange']}
                    >
                        <Input
                            placeholder={t('email')}
                            disabled={!selectedTeller.id}
                            onBlur={() => setIsEmailTouched(true)}
                        />
                    </Form.Item>
                </Col>
            </Row>

        </Card >
    )
}

export default TellerSelectionForm