import { PropsWithChildren, useState } from 'react'
import { DelegateElectionsContext } from '../context'
import { DelegateElectionsData } from '../model'

function OfficersElectionsProvider({ children }: PropsWithChildren) {
    const [delegateElectionsData, setDelegateElectionsData] = useState<DelegateElectionsData>({} as any)

    return (
        <DelegateElectionsContext.Provider value={{ delegateElectionsData, setDelegateElectionsData }}>
            {children}
        </DelegateElectionsContext.Provider >
    )
}

export default OfficersElectionsProvider