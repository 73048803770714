import { Form } from 'antd'
import { useElectionCandidates } from 'api'
import LookupSelect, { LookupOption } from 'components/molecules/LookupSelect'
import { BallotingElection } from 'features/onlineVoting/models/balloting-election'
import { ElectionParticipant, ElectionType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getNameWithMatchHighlighting } from 'utils'
import style from './index.module.scss'

const maxCandidateInDropDown = 30

type CandidateSelectionProps = {
    onChange: () => void,
    initSelectedCandidate?: ElectionParticipant,
    selectedCandidates?: LookupOption[],
    election?: BallotingElection,
    index: number,
    duplicateIndex: number | null,
    isCleared: boolean,
    handleFieldChange: (value: ElectionParticipant | undefined, index: number) => void
}

function CandidateSelection({
    onChange,
    selectedCandidates,
    election,
    index,
    duplicateIndex,
    initSelectedCandidate,
    isCleared,
    handleFieldChange }:
    CandidateSelectionProps) {

    const { t } = useTranslation('voter')
    const [nameFilter, setNameFilter] = useState<string | undefined>(undefined)
    const isBreakingTieElection = election?.electionType === ElectionType.BREAKING_TIE
    const electionId = String(election?.id)
    const [preselectedCandidateId, setPreselectedCandidateId] =
        useState<string | undefined>(initSelectedCandidate?.id)
    const [selectedCandidate, setSelectedCandidate] =
        useState<ElectionParticipant | undefined>(initSelectedCandidate)

    const shouldFetch = isBreakingTieElection || (!preselectedCandidateId && !!nameFilter?.length)

    const { data, isFetching } = useElectionCandidates(
        electionId,
        isBreakingTieElection ? { limit: maxCandidateInDropDown, offset: 0 } : {
            name: nameFilter, limit: maxCandidateInDropDown, offset: 0
        },
        shouldFetch
    )

    const handleClear = () => {
        setNameFilter(undefined)
        setPreselectedCandidateId(undefined)
    }

    useEffect(() => {
        if (isCleared) {
            setNameFilter(undefined)
            setPreselectedCandidateId(undefined)
        }
    }, [isCleared])

    const handleOnChange = (value: ElectionParticipant | undefined) => {
        setSelectedCandidate(value)
        if (value) {
            setPreselectedCandidateId(value.id)
            handleFieldChange(value, index)
        }

        onChange()
    }

    useEffect(() => {
        if (initSelectedCandidate) {
            setSelectedCandidate(initSelectedCandidate)
            setPreselectedCandidateId(initSelectedCandidate.id)
        }
    }, [initSelectedCandidate])

    return (
        <div className={style.fieldWrapper}>
            <div tabIndex={-1} aria-hidden={true} className={style.disc}>
                {index + 1}
            </div>
            <div className={style.formItemWrapper}>
                <Form.Item
                    name={'input' + index}
                    className={style.formItem}
                    rules={[
                        {
                            message: t('please_select_an_input', { input: t('individual') }),
                            validator: (_, value) => selectedCandidate ? Promise.resolve() :
                                Promise.reject()
                        }
                    ]}
                    validateTrigger={['onSubmit', 'onSelect']}
                >
                    <LookupSelect
                        autoselectOptionId={initSelectedCandidate?.id}
                        searchEnabled={!isBreakingTieElection}
                        onClear={handleClear}
                        isClearedAllFields={isCleared}
                        className={'small'}
                        optionRenderFunction={(cand) => <>{getNameWithMatchHighlighting(cand.name, nameFilter)}</>}
                        autoClear={index === duplicateIndex || isCleared}
                        onChange={handleOnChange}
                        alreadySelectedOptions={selectedCandidates as any}
                        autoSelectOnFirstDataReceive={selectedCandidate && !!initSelectedCandidate?.id}
                        onSearchChange={(name) => setNameFilter(name?.trim() ?? '')}
                        options={isBreakingTieElection ? data : selectedCandidate?.id ? [selectedCandidate!] : data}
                        isFetchingOptions={isFetching}
                        placeholder={isBreakingTieElection ? t('select_name') : t('start_typing_name')}
                    />
                </Form.Item>
            </div>
        </div>
    )
}

export default CandidateSelection
