import { useStationBallotsSummary } from 'api'

type StationInvalidBallotsProps = {
    stationId: string,
    electionId: string
}

function StationInvalidBallots({ electionId, stationId }: StationInvalidBallotsProps) {

    const { data: stationBallotsSummary } = useStationBallotsSummary({ electionId, stationId })

    return (
        <>
            {stationBallotsSummary?.invalidBallotCount || 0}
        </>
    )
}

export default StationInvalidBallots