import Tooltip, { TooltipProps } from 'components/atoms/Tooltip'
import { useState } from 'react'

export type OptionalTooltipProps = Omit<TooltipProps, 'open'> & {
    contenWrapperClassName?: string
}

function OptionalTooltip({ contenWrapperClassName, title, children, ...props }: OptionalTooltipProps) {
    const [isTooltipShown, setIsTooltipShow] = useState(false)

    const onMouseEnter = (e: any) => {
        const target = e.target
        setIsTooltipShow(target.scrollWidth > target.clientWidth)
    }
    const onMouseLeave = () => {
        setIsTooltipShow(false)
    }

    return (
        <Tooltip {...props}
            open={isTooltipShown}
            title={title || children}
            children={
                <div
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    className={contenWrapperClassName}>
                    {children}
                </div>
            } />
    )
}

export default OptionalTooltip