import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectedOfficer } from '../../models'

export const useElectedOfficers = (localityCode: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectedOfficers = async () => {
        const response = await axios<ElectedOfficer[]>(moduleUrl
            + `officers-elections/election-results/candidates/elected?locality=${localityCode}`)

        return response.data
    }

    return useQuery({
        queryKey: ['elected-officers', localityCode],
        queryFn: getElectedOfficers,
        enabled
    })
}