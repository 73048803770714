import { notification } from 'antd'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import { useTranslation } from 'react-i18next'
import { useCopyToClipboard } from './useCopyToClipboard'

export const useCopyToClipboardWithToast = () => {

    const { copy } = useCopyToClipboard()
    const { t } = useTranslation('common')
    const [api, contextHolder] = notification.useNotification()

    const copyWithToast = (text: string, cb?: (text: string) => any) => {
        copy(text, cb)


        api.open({
            message: t('copied_to_clipboard'),
            placement: 'bottomRight',
            className: 'copied-toast',
            closeIcon: <Dismiss
                style={{ 'fill': 'var(--icon-inactive)', 'height': 'var(--double-space)' }} />
        })
    }

    return {
        copy: copyWithToast,
        contextHolder
    }

}