import { Election, ElectionStatusType, ElectionType } from 'models'
import { AppointedTellersCounts, DelegateElectionItem } from '../models'
import { getLocalDelegateDate } from './getLocalDelegateDate'

export const getLocalDelegateItem = (
    localDelegateElections: Election[],
    status: ElectionStatusType | undefined,
    appointedTellersCountsData: AppointedTellersCounts[],
    electionTitle: string
): DelegateElectionItem => {
    const unitsNumber = localDelegateElections.length

    let unitsWithSetUpTellersNumber: number
    let resultsConfirmedBy: boolean

    if (status === ElectionStatusType.COMPLETED) {
        unitsWithSetUpTellersNumber = unitsNumber
        resultsConfirmedBy = true
    } else {
        unitsWithSetUpTellersNumber = appointedTellersCountsData.filter(unit => unit.totalCount > 0).length
        resultsConfirmedBy = !!appointedTellersCountsData.find(unit =>
            (unit.chiefTellerCount > 0) && (unit.chiefTellerAssistantCount > 0)
        )
    }

    return {
        status: status!,
        type: ElectionType.LOCAL_DELEGATE_ELECTION,
        title: electionTitle,
        onlineVotingStartDate:
            localDelegateElections.find(unit => unit.onlineVotingStart)
                ? getLocalDelegateDate(localDelegateElections.map(unit => unit.onlineVotingStart))
                : '-',
        electionDay: localDelegateElections.find(unit => unit.voteCountingStart)
            ? getLocalDelegateDate(localDelegateElections.map(unit => unit.voteCountingStart))
            : '-',
        electionPeriodId: localDelegateElections[0].electionPeriod,
        requiredTellersAssigned: unitsWithSetUpTellersNumber > 0,
        resultsSignedBy: !!localDelegateElections.find(election => election.resultsSignedBy !== null),
        resultsConfirmedBy: resultsConfirmedBy,
        unitsNumber: unitsNumber,
        unitsWithSetUpTellersNumber: unitsWithSetUpTellersNumber,
        unitsWithResultsSignedByNumber: localDelegateElections.filter(
            election => (election.resultsSignedBy !== null)
        ).length,
        unitsWithResultsConfirmedByNumber: localDelegateElections.filter(
            election => (election.resultsConfirmedBy !== null)
        ).length,
        atLeastOneTellerAssigned: false
    }
}