import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'

type ConfirmResultsCommand = {
    electionId: string,
    confirmedCandidatesIds: string[],
    contactPersonId?: string
}

export function useConfirmResults() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function confirmResults({ electionId, confirmedCandidatesIds, contactPersonId }: ConfirmResultsCommand) {

        return await axios(`${moduleUrl}elections/${electionId}/vote-counting/election-results/confirm`,
            {
                method: 'post',
                data: {
                    contactPerson: contactPersonId,
                    confirmedCandidates: confirmedCandidatesIds
                }
            })
    }

    return useMutation({
        mutationFn: confirmResults
    })
}