import ManageOfficersElectionMaintenance from './components/ManageOfficersElection/ManageOfficersElections'
import SelectElectionToCreate from './components/SelectElectionToCreate'
import { byElection } from './features/byElection'
import { nationalDelegate } from './features/national'
import { ridvan } from './features/ridvan'
import ElectionTeam from './pages/ElectionTeam'

export const electionEditing = {
    components: {
        SelectElectionToCreate,
        ManageOfficersElectionMaintenance
    },
    pages: {
        ElectionTeam
    },
    ridvan,
    byElection,
    nationalDelegate
}