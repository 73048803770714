import { ElectionResult } from 'models'
import { useTranslation } from 'react-i18next'
import { formatNames } from 'utils'

export function useRevoteRoundTieCandidates(
    resolutionCandidates: string[],
    tieCandidates: ElectionResult[],
    designatedCandidates: ElectionResult[]
) {
    const { t } = useTranslation()
    const resolutionCandidateIds = resolutionCandidates || []
    const designatedCandidateIds = designatedCandidates?.map(c => c.id) || []
    const remainingCandidateIds = resolutionCandidateIds.filter(id => !designatedCandidateIds.includes(id))
    const remainingCandidates = tieCandidates.filter(candidate => remainingCandidateIds.includes(candidate.id))
    const names = remainingCandidates.map(candidate => candidate.name)

    const formattedNames = formatNames(names, t('and'))

    return { formattedNames }
}