
import Input from 'components/atoms/Input'
import PopupFooter from 'components/atoms/PopupFooter'
import Note from 'components/molecules/Note'
import { ballotingAssets } from 'features/ballotingAssets'
import { useAuth, useHandleStationLogoutError, useInfoPopup } from 'hooks'
import { Dispatch, FormEvent, SetStateAction, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { numbersOnly } from 'utils'
import { useBallots, useReportVotersSummary, useVerifyVotersSummary, useVoterSummary } from '../../api'
import { useTellingStationInfo } from '../../hooks'
import style from './index.module.scss'

const MAX_LENGTH = 4

type CountVerificationProps = {
    onCancel: () => void,
    onSubmit: () => void,
    includeNotCountedDuringBallotEntry?: boolean,
}

function CountVerification({ onCancel, onSubmit, includeNotCountedDuringBallotEntry }: CountVerificationProps) {
    const { t } = useTranslation('telling-station')
    const { info, setInfo } = useTellingStationInfo()
    const { auth } = useAuth()

    const [invalidReasonCounts, setInvalidReasonCounts] = useState<{ [key: string]: number }>({})

    const { mutateAsync: verify } = useVerifyVotersSummary()
    const { mutateAsync: report } = useReportVotersSummary()
    const { data: voterSummary } = useVoterSummary(!info.votersSummary)
    const { data: ballots } = useBallots(includeNotCountedDuringBallotEntry)
    const { data: ballotInvalidationReasons } =
        ballotingAssets.api.queries.useBallotInvalidationReasons(auth?.electionId!, includeNotCountedDuringBallotEntry)

    const invalidBallotsCount = useMemo(() => ballots?.filter(b => !b.deleted && b.invalidationReason).length || 0,
        [ballots])

    const validBallotsCount = useMemo(() => ballots?.filter(b => !b.deleted && !b.invalidationReason).length || 0,
        [ballots])

    useEffect(() => {
        if (ballots && ballotInvalidationReasons) {
            const counts: { [key: string]: number } = {}
            ballotInvalidationReasons.forEach(reason => {
                counts[reason.id] = ballots.filter(b => !b.deleted && b.invalidationReason === reason.id).length
            })
            setInvalidReasonCounts({ ...counts })
        }
    },
        [ballots, ballotInvalidationReasons])

    const { infoPortal, showInfo } = useInfoPopup()
    const { handleError } = useHandleStationLogoutError()

    const [showOuterEnvelopeValidation, setShowOuterEnvelopeValidation] = useState(false)
    const [isCorrectionRequired, setIsCorrectionRequired] = useState(false)

    const [outerEnvelopeCount, setOuterEnvelopeCount] =
        useState<number | undefined>(info.votersSummary?.outerEnvelopeCount || 0)
    const [ineligiblePersonEnvelopeCount, setIneligiblePersonEnvelopeCount] =
        useState<number | undefined>(info.votersSummary?.ineligiblePersonEnvelopeCount || 0)
    const [markedInnerEnvelopeCount, setMarkedInnerEnvelopeCount] =
        useState<number | undefined>(info.votersSummary?.markedInnerEnvelopeCount || 0)
    const [votedOnlinePersonEnvelopeCount, setVotedOnlinePersonEnvelopeCount] =
        useState<number | undefined>(info.votersSummary?.votedOnlinePersonEnvelopeCount || 0)

    useEffect(() => {
        if (voterSummary && !info.votersSummary) {
            setInfo(current => ({ ...current, votersSummary: { ...voterSummary } }))
            setOuterEnvelopeCount(voterSummary.outerEnvelopeCount)
            setIneligiblePersonEnvelopeCount(voterSummary.ineligiblePersonEnvelopeCount)
            setMarkedInnerEnvelopeCount(voterSummary.markedInnerEnvelopeCount)
            setVotedOnlinePersonEnvelopeCount(voterSummary.votedOnlinePersonEnvelopeCount)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voterSummary])

    useEffect(() => {
        if (info.votersSummary) {
            const summary = {
                outerEnvelopeCount: outerEnvelopeCount!,
                ineligiblePersonEnvelopeCount: ineligiblePersonEnvelopeCount!,
                markedInnerEnvelopeCount: markedInnerEnvelopeCount!,
                votedOnlinePersonEnvelopeCount: votedOnlinePersonEnvelopeCount!
            }

            setInfo(current => ({ ...current, votersSummary: { ...summary } }))
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [outerEnvelopeCount,
            ineligiblePersonEnvelopeCount,
            markedInnerEnvelopeCount,
            votedOnlinePersonEnvelopeCount])

    const tryToInput = (e: FormEvent<HTMLInputElement>, setField: Dispatch<SetStateAction<number | undefined>>,
        field?: number, isOuterEnvelope?: boolean) => {
        const target = e.target as any

        if (target.value) {
            if (!numbersOnly(target.value)) {
                target.value = field || ''
            } else {
                setField(parseInt(target.value))
                if (isOuterEnvelope)
                    setShowOuterEnvelopeValidation(false)
            }
        } else {
            setField(0)
        }
        setIsCorrectionRequired(false)
    }

    const submit = async () => {
        if (outerEnvelopeCount === 0) {
            setShowOuterEnvelopeValidation(true)

            return
        }
        const sum = ineligiblePersonEnvelopeCount! + markedInnerEnvelopeCount! + votedOnlinePersonEnvelopeCount!
        if (includeNotCountedDuringBallotEntry) {
            if ((sum + invalidBallotsCount + validBallotsCount) !== outerEnvelopeCount!) {
                setIsCorrectionRequired(true)

                return
            }
        } else {
            if (sum > outerEnvelopeCount!) {
                setIsCorrectionRequired(true)

                return
            }

        }

        const summary = {
            outerEnvelopeCount: outerEnvelopeCount!,
            ineligiblePersonEnvelopeCount: ineligiblePersonEnvelopeCount!,
            markedInnerEnvelopeCount: markedInnerEnvelopeCount!,
            votedOnlinePersonEnvelopeCount: votedOnlinePersonEnvelopeCount!
        }
        try {
            if (includeNotCountedDuringBallotEntry) {
                await verify(summary)
            } else {
                await report(summary)
            }
        } catch (e) {
            handleError(e, showInfo)

            return
        }
        onSubmit()
    }

    return (<>
        {infoPortal}
        {isCorrectionRequired &&
            <div className={style.correctionRequired} >
                <Note
                    mode="error"
                    icon="filled"
                    title={t('correction_is_required')}>
                    <span className={style.notHighlighted}>
                        {includeNotCountedDuringBallotEntry
                            ? <>
                                <Trans
                                    i18nKey={t('the_sum_of_b_c_d_e_and_f')}
                                    components={{
                                        red: <span className={style.importantText} />
                                    }} /><br />
                                {`(${[ineligiblePersonEnvelopeCount,
                                    markedInnerEnvelopeCount,
                                    votedOnlinePersonEnvelopeCount,
                                    invalidBallotsCount,
                                    validBallotsCount].join('+')} `}
                                <Trans
                                    i18nKey={t('is_not_equal_to')}
                                    components={{
                                        red: <span className={style.importantText} />
                                    }} />
                            </>
                            : <>
                                <Trans
                                    i18nKey={t('the_sum_of_b_c_and_d')}
                                    components={{
                                        red: <span className={style.importantText} />
                                    }} /> <br />
                                {`(${ineligiblePersonEnvelopeCount}
                                    +${markedInnerEnvelopeCount}
                                    +${votedOnlinePersonEnvelopeCount} `}
                                <Trans
                                    i18nKey={t('is_greater_than')}
                                    components={{
                                        red: <span className={style.importantText} />
                                    }} />
                            </>}
                        {` ${outerEnvelopeCount})`}
                    </span>
                </Note>
            </div>
        }
        <div>
            <div className={`${style.section} ${style.firstSection}`}>
                <div className={style.item}>
                    <div className={style.letterContainer}>
                        <div className={style.letter}>
                            A
                        </div>
                    </div>
                    <div className={style.itemTitles}>
                        <div className={style.itemTitle}>{t('total')}</div>
                        <div className={style.itemSubtitle}>
                            <Trans i18nKey={t(
                                'enter_total_number_for_all_the_ballots_envelopes_station_started_with',
                                {
                                    interpolation: { escapeValue: false },
                                    station: `<strong>${t('this_station')}</strong>`
                                })} components={[<strong />]} />
                        </div>
                        {showOuterEnvelopeValidation
                            && <div className={`${style.itemSubtitle} ${style.validation}`}>
                                {t('you_marked_at_least_one_individual_as_in_person_absentee')} <br />
                                {t('this_value_should_be_more_than_number', { number: 0 })}
                            </div>}
                    </div>
                    <div className={style.input}>
                        <Input
                            className={(showOuterEnvelopeValidation ||
                                (isCorrectionRequired && includeNotCountedDuringBallotEntry)
                            ) ? 'ant-input-status-error' : ''}
                            value={outerEnvelopeCount}
                            onInput={(e) => tryToInput(e, setOuterEnvelopeCount, outerEnvelopeCount, true)}
                            maxLength={MAX_LENGTH}
                            onBlur={() => { setShowOuterEnvelopeValidation(outerEnvelopeCount === 0) }}
                        />
                    </div>
                </div>
            </div>
            <div className={`${style.section} ${style.secondSection}`}>
                <div className={style.sectionTitle}>
                    {t('not_counted_during_voter_verification')}
                </div>
                <div className={style.sectionSubtitle}>
                    {t('set_aside_as_invalid_ballots')}
                </div>
                <div className={style.item}>
                    <div className={style.letterContainer}>
                        <div className={style.letter}>
                            B
                        </div>
                    </div>
                    <div className={style.itemTitles}>
                        <div className={style.itemTitle}>{t('ineligible_electors')}</div>
                        <div className={style.itemSubtitle}>
                            {t('enter_number_of_outer_ballot_envelopes_from_ineligible_electors')}
                        </div>
                    </div>
                    <div className={style.input}>
                        <Input
                            value={ineligiblePersonEnvelopeCount}
                            onInput={(e) =>
                                tryToInput(e, setIneligiblePersonEnvelopeCount, ineligiblePersonEnvelopeCount)}
                            maxLength={MAX_LENGTH}
                        />
                    </div>
                </div>
                <div className={style.item}>
                    <div className={style.letterContainer}>
                        <div className={style.letter}>
                            C
                        </div>
                    </div>
                    <div className={style.itemTitles}>
                        <div className={style.itemTitle}>{t('marked_inner_envelopes_or_no_inner_envelope')}</div>
                        <div className={style.itemSubtitle}>
                            {t('enter_number_of_all_inner_envelopes_that_identify_an_elector_or_are_missing')}
                        </div>
                    </div>
                    <div className={style.input}>
                        <Input
                            value={markedInnerEnvelopeCount}
                            onInput={(e) => tryToInput(e, setMarkedInnerEnvelopeCount, markedInnerEnvelopeCount)}
                            maxLength={MAX_LENGTH}
                        />
                    </div>
                </div>
                <div className={style.item}>
                    <div className={style.letterContainer}>
                        <div className={style.letter}>
                            D
                        </div>
                    </div>
                    <div className={style.itemTitles}>
                        <div className={style.itemTitle}>{t('duplicate_envelopes')}</div>
                        <div className={style.itemSubtitle}>
                            {t('enter_number_of_duplicate_envelopes_from_those_who_already_voted_online')}
                        </div>
                    </div>
                    <div className={style.input}>
                        <Input
                            value={votedOnlinePersonEnvelopeCount}
                            onInput={(e) =>
                                tryToInput(e, setVotedOnlinePersonEnvelopeCount, votedOnlinePersonEnvelopeCount)}
                            maxLength={MAX_LENGTH}
                        />
                    </div>
                </div>
            </div>
            {includeNotCountedDuringBallotEntry &&
                <div className={`${style.section} ${style.thirdSection}`}>
                    <div className={style.sectionTitle}>
                        {t('counted_during_ballot_entry')}
                    </div>
                    <div className={style.sectionSubtitle}>
                        {t('entered_ballots')}
                    </div>
                    <div className={style.item}>
                        <div className={style.letterContainer}>
                            <div className={style.letter}>
                                E
                            </div>
                        </div>
                        <div className={style.itemTitles}>
                            <div className={style.itemTitle}>{t('ballot:invalid_ballots')}</div>
                            <div className={style.itemSubtitle}>
                                {t('entered_as_invalid_ballots')}
                            </div>
                        </div>
                        <div className={style.counter}>{invalidBallotsCount}</div>
                    </div>
                    {
                        ballotInvalidationReasons?.length && <div className={style.invalidDescription}>
                            {
                                ballotInvalidationReasons?.map(reason => <div
                                    className={style.invalid}
                                    key={reason.id}>
                                    <div className={style.reason}>{reason.text}</div>
                                    <div className={style.reasonCounter}>{invalidReasonCounts[reason.id] || 0}</div>
                                </div>)
                            }
                        </div>
                    }
                    <div className={style.item}>
                        <div className={style.letterContainer}>
                            <div className={style.letter}>
                                F
                            </div>
                        </div>
                        <div className={style.itemTitles}>
                            <div className={style.itemTitle}>{t('ballot:valid_ballots')}</div>
                            <div className={style.itemSubtitle}>
                                {t('entered_as_valid_ballots')}
                            </div>
                        </div>
                        <div className={style.counter}>{validBallotsCount}</div>
                    </div>
                </div>
            }
        </div>
        <div className={style.footer}>
            <PopupFooter>
                <button
                    type="submit"
                    onClick={onCancel}
                    className={'btn-main-secondary'}>
                    {t('common:cancel')}
                </button>
                <button
                    type="submit"
                    onClick={submit}
                    className={'btn-main-primary'}>
                    {t('common:submit')}
                </button>
            </PopupFooter>
        </div>
    </>
    )
}

export default CountVerification