import { EMPTY_TELLER } from '../constants'
import { useStations } from './useStations'


export const useStationActions = () => {
    const { stations, setStations } = useStations()

    const appendNewStation = () => {
        const id = stations.length
            ? 'S' + (parseInt(stations[stations.length - 1].id.replace('S', ''), 10) + 1)
            : 'S1'

        setStations([...stations, {
            id, closed: true, saved: false, tellers: [EMPTY_TELLER, EMPTY_TELLER],
            stationReportAccepted: false
        }])
    }

    const onStationCleanUp = (stationName: number) => {
        setStations(prev => (
            prev.map((station, index) => {
                if (index === stationName) {
                    return { ...station, saved: false, tellers: [EMPTY_TELLER, EMPTY_TELLER] }
                } else {
                    return station
                }
            })
        ))
    }

    const onStationRemove = (stationName: number) => {
        const updatedStationsData = [...stations]
        updatedStationsData.splice(stationName, 1)
        setStations(updatedStationsData)
    }

    return {
        appendNewStation,
        onStationCleanUp,
        onStationRemove
    }
}