import { TellerType } from 'models'
import { useTranslation } from 'react-i18next'

export const useTellerTypeMap = () => {
    const { t } = useTranslation()

    return {
        toString: (role: TellerType): string => {
            switch (role) {
                case TellerType.TELLER:
                    return t('teller:teller')
                case TellerType.CHIEF_TELLER:
                    return t('chief_teller')
                case TellerType.CHIEF_TELLER_ASSISTANT:
                    return t('assistant_chief_teller')
            }
        }
    }

}