import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionResult } from 'models'

export const useElectedCandidates = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectedCandidates = async ({ queryKey }: QueryFunctionContext) => {
        const [, electionId] = queryKey

        const res =
            await axios<ElectionResult[]>(`${moduleUrl}elections/${electionId}/election-results/candidates/elected`)

        return res.data
    }

    return useQuery<ElectionResult[], Error>({
        queryKey: ['candidates-elected', electionId],
        queryFn: getElectedCandidates,
        enabled: !!electionId && enabled
    })
}