import { useElectionResultsCandidates } from './api'
import { confirmation } from './features/confirmation'
import { mergeResults } from './features/mergeResults'
import { mergedResults } from './features/mergedResults'

export const electionResults = {
    pages: {
        ConfirmedResults: confirmation.pages.ConfirmedResults,
        ConfirmResults: confirmation.pages.ConfirmResults,
        MergedResults: mergedResults.pages.MergedResultsWrapper,
        TiePhysicalBallots: mergedResults.pages.TiePhysicalBallots,
        MergeResults: mergeResults.pages.MergeResults,
        RoundResultsInfo: mergedResults.pages.RoundResultsInfo
    },
    components: {
        MergedResultsTable: mergedResults.components.MergedResultsTable,
        ConfirmedResultsTable: confirmation.components.ConfirmedResultsTable,
        LuElectionResultsNotPushedNote: confirmation.components.LuElectionResultsNotPushed
    },
    providers: {
        ConfirmationProvider: confirmation.providers.ConfirmationProvider,
        MergedResultsProvider: mergedResults.providers.MergedResultsProvider
    },
    api: {
        queries: {
            useElectionResultsCandidates
        }
    }
}