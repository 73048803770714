import { useBackToDashboardLine, useInfoPopup, usePageHeader, useRefreshTokenInfo, useSignOut } from 'hooks'
import { ReactNode, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../../organisms/Header'
import styles from './index.module.scss'

export type AdaptiveLayoutProps = {
    header?: ReactNode,
    children?: ReactNode,
    useSupportedWidth?: boolean,
    background?: ReactNode
}

const AdaptiveLayout = ({
    header,
    children,
    useSupportedWidth = false,
    background
}: AdaptiveLayoutProps) => {
    const { pageHeader } = usePageHeader()
    const { backToDashboardLine } = useBackToDashboardLine()
    const contentStyle = background ? { backgroundImage: `url(${background})` } : {}

    const { refreshTokenInfo, setRefreshTokenInfo } = useRefreshTokenInfo()
    const { infoPortal, showInfo } = useInfoPopup()
    const { signOut } = useSignOut(showInfo)

    useEffect(() => {
        if (refreshTokenInfo.logoutIsRequired) {
            signOut(true)
            setRefreshTokenInfo(current => ({ ...current, logoutIsRequired: false }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTokenInfo])

    return (
        <>
            {infoPortal}
            <div className={`${useSupportedWidth ? styles.headerWrapperSupportWidth : ''} ${styles.headerWrapper}`}>
                {header || <Header />}
            </div>
            {pageHeader}
            {backToDashboardLine}
            <div className={styles.content} style={contentStyle || {}}>
                {children || <Outlet />}
            </div >
        </>
    )
}

export default AdaptiveLayout