import { useElectionVerifiedVoters } from 'api'
import { VotingApproach } from 'models'
import { useEffect, useState } from 'react'

type StationInPersonVotersProps = {
    stationId: string,
    electionId: string
}


function StationInPersonVoters({ electionId, stationId }: StationInPersonVotersProps) {
    const { data: electionVerifiedVoters } = useElectionVerifiedVoters(electionId)

    const [stationInPersonVoters, setStationInPersonVoters] = useState(0)

    useEffect(() => {
        if (electionVerifiedVoters?.verification?.length) {
            const inPerson = electionVerifiedVoters.verification
                .filter(v => v.verifier === stationId && v.votingApproach === VotingApproach.IN_PERSON).length
            setStationInPersonVoters(inPerson)
        } else {
            setStationInPersonVoters(0)
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [electionVerifiedVoters])

    return <>{stationInPersonVoters}</>
}

export default StationInPersonVoters