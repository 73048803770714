import {
    useElection,
    useElectionVerifiedVoters,
    useElectionVoters
} from 'api'
import { useOnlineVoters } from 'api/query/onlineVoting'
import Input from 'components/atoms/Input'
import FilterableHeaderCell from 'components/molecules/FilterableHeaderCell'
import MultiSelect from 'components/molecules/MultiSelect'
import SortableHeaderCell from 'components/molecules/SortableHeaderCell'
import { electionEditing } from 'features/electionEditing'
import { useAuth, useFilterable, useHandleEntityLoadingError, useSortable } from 'hooks'
import { ElectionType, SortOrder } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { numbersOnly } from 'utils'
import { useTellingStationInfo } from '../../../../hooks'
import { useVoterVerificationTableData } from '../../hooks'
import { Voter, VotersFilter, VotingApproach } from '../../models'
import { filterVoters } from '../../utils'
import style from './index.module.scss'

const DEFAULT_FILTER: VotersFilter = {
    sortBy: {
        field: 'name',
        order: SortOrder.ASC
    }
}

type VoterVerificationTableHeaderProps = {
    columnHighlightingHeight?: string
}

function VoterVerificationTableHeader({ columnHighlightingHeight }: VoterVerificationTableHeaderProps) {
    const { t } = useTranslation()
    const { auth } = useAuth()
    const { changeSortBy } = useSortable()
    const { changeFilter } = useFilterable<VotersFilter>()
    const { setInfo } = useTellingStationInfo()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()

    const { electionId } = useParams()

    const [allVoters, setAllVoters] = useState<Voter[]>([] as any)
    const [allStates, setAllStates] = useState<string[]>([] as any)
    const [allLocalUnits, setAllLocalUnits] = useState<string[]>([] as any)
    const [allLocalities, setAllLocalities] = useState<string[]>([] as any)

    const [voters, setVoters] = useState<Voter[]>([] as any)
    const [filter, setFilter] = useState<VotersFilter>({ ...DEFAULT_FILTER })
    const { setTableData } = useVoterVerificationTableData()

    const [totalVotersCount, setTotalVotersCount] = useState(0)
    const [verifiedCount, setVerifiedCount] = useState(0)
    const [inPersonCount, setInPersonCount] = useState(0)
    const [absenteeCount, setAbsenteeCount] = useState(0)
    const [onlineVotersCount, setOnlineVotersCount] = useState(0)

    const { data: votersResponse } = useElectionVoters(auth!.electionId || electionId || '')
    const { data: verifiedVotersResponse } = useElectionVerifiedVoters(auth!.electionId || electionId || '',
        !!votersResponse)
    const { data: onlineVoters } = useOnlineVoters(auth!.electionId || electionId || '', !!votersResponse)
    const { data: election, error } = useElection(auth!.electionId || electionId || '')
    const { data: electionPeriod } = electionEditing.ridvan.api.useElectionPeriod(
        election?.electionPeriod?.toString() || '',
        election?.type === ElectionType.RIDVAN_ELECTION)

    const { prepareFiltersForRequest } = useFilterable<VotersFilter>()
    const [includeLocalUnits, setIncludeLocalUnits] = useState(false)

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        setAllVoters(votersResponse?.voters || [])
        if (votersResponse) {
            const states: string[] = []
            const localities: string[] = []
            const localUnits: string[] = []
            setTotalVotersCount(votersResponse.voters.length)
            votersResponse.voters.forEach(v => {
                if (v.homeLocality?.state)
                    states.push(v.homeLocality?.state)
                if (v.homeLocality?.name) {
                    localities.push(v.homeLocality?.name)
                }
                if (v.homeLocalUnit?.code) {
                    localUnits.push(v.homeLocalUnit.code)
                }
            })
            setAllStates([...new Set(states)].sort())
            setAllLocalities([...new Set(localities)].sort())
            setAllLocalUnits([...new Set(localUnits)].sort())
        } else {
            setAllStates([])
            setAllLocalities([])
            setAllLocalUnits([])
        }
    }, [votersResponse])

    useEffect(() => {
        switch (election?.type) {
            case ElectionType.LOCAL_DELEGATE_ELECTION:
                setIncludeLocalUnits(true)

                return
            case ElectionType.RIDVAN_ELECTION:
                if (!!electionPeriod?.localDelegateElectionStage)
                    setIncludeLocalUnits(true)

                return
        }

        setIncludeLocalUnits(false)
    }, [election, electionPeriod])

    useEffect(() => {
        setTableData({
            voters,
            statistics: {
                absenteeCount,
                inPersonCount,
                verifiedCount,
                totalVotersCount,
                onlineVotersCount
            },
            includeLocalUnit: includeLocalUnits
        })
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [voters,
            absenteeCount,
            inPersonCount,
            verifiedCount,
            totalVotersCount,
            onlineVotersCount,
            includeLocalUnits])

    useEffect(() => {
        if (verifiedVotersResponse && onlineVoters) {
            setVerifiedCount(verifiedVotersResponse.totalCount)
            setInPersonCount(verifiedVotersResponse.votedInPersonCount)
            setAbsenteeCount(verifiedVotersResponse.votedInAbsenceCount)
            setOnlineVotersCount(onlineVoters.total)

            const getNewAllVoters = (all: Voter[]) => {
                const newAllVoters: Voter[] = []

                all.forEach(voter => {
                    const newVoter = {
                        ...voter,
                        inAbsence: false,
                        inPerson: false,
                        verifier: '',
                        online: false
                    }
                    if (verifiedVotersResponse.verification) {
                        const verification = verifiedVotersResponse.verification
                            .find((v: any) => voter.id === v.id.toString())
                        if (verification) {
                            newVoter.inAbsence = verification.votingApproach === VotingApproach.IN_ABSENCE
                            newVoter.inPerson = verification.votingApproach === VotingApproach.IN_PERSON
                            newVoter.verifier = verification.verifier
                        }
                    }
                    if (onlineVoters.verification) {
                        newVoter.online = !!onlineVoters.verification
                            .find((v: any) => voter.id === v.id.toString())
                    }
                    newAllVoters.push(newVoter)
                })

                return newAllVoters
            }
            if (allVoters?.length) {
                setAllVoters(getNewAllVoters(allVoters))
            } else {
                setAllVoters(getNewAllVoters)
            }
        } else {
            setVerifiedCount(0)
            setInPersonCount(0)
            setAbsenteeCount(0)
            setOnlineVotersCount(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        verifiedVotersResponse, onlineVoters
    ])

    useEffect(() => {
        const filterToApply = prepareFiltersForRequest(filter)
        setVoters(filterVoters(allVoters, filterToApply))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allVoters, filter])

    useEffect(() => {
        setInfo(current => ({
            ...current,
            hasVerifiedVoters: !!allVoters.find(v => v.verifier === auth?.stationId)
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allVoters])

    const onSortingChange = (field: string, order?: SortOrder) =>
        setFilter(changeSortBy(filter, field, order) as VotersFilter)

    const clearAllFilters = () => {
        setFilter({ ...DEFAULT_FILTER })
    }

    return (
        <div className={style.header}>
            <div style={{
                width: includeLocalUnits ? 345 : 451
            }}
                className={style.column}>
                <div
                    className={style.colHighlighting}
                    id="voter-verification-name-and-id"
                    style={{
                        width: includeLocalUnits ? 493 : 599,
                        height: columnHighlightingHeight
                    }} />
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Input
                            allowClear={true}
                            className="small"
                            value={filter.name}
                            style={{ width: includeLocalUnits ? 327 : 433 }}
                            onChange={(e) => setFilter(changeFilter(filter, 'name', e.target.value))}
                            placeholder={t('search_by_name')}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('full_name')}
                            field={'name'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div >
            <div style={{
                width: 148
            }}
                className={style.column}>
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Input
                            className="small"
                            value={filter.id}
                            style={{ width: 130 }}
                            allowClear={true}
                            onInput={(e) => {
                                const target = e.target as any
                                if (target.value) {
                                    if (!numbersOnly(target.value)) {
                                        target.value = filter.id || ''
                                    }
                                }
                            }}
                            onChange={(e) => setFilter(changeFilter(filter, 'id', e.target.value))}
                            placeholder={t('telling-station:search_by_id')}
                            onClick={(e) => e.stopPropagation()}
                            maxLength={8}

                        />
                    }
                    title={t('bahai_id')}
                />
            </div >
            <div style={{
                width: 89
            }}
                className={style.column}>
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <MultiSelect
                            className="small"
                            allowClear={false}
                            popupClassName="small"
                            style={{ width: 71 }}
                            popupWidth={170}
                            value={filter.state || []}
                            onChange={(value) => setFilter(
                                changeFilter(filter, 'state', value as string[]))}
                            options={allStates.map(s => ({
                                value: s,
                                label: s
                            }))}
                            placeholder={t('all')}
                        />
                    }
                    title={t('state')}
                />
            </div >
            <div style={{
                width: 109
            }}
                className={style.column}>
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <MultiSelect
                            className="small"
                            allowClear={false}
                            popupClassName="small"
                            style={{ width: 91 }}
                            popupWidth={200}
                            value={filter.homeLocality || []}
                            onChange={(value) => setFilter(
                                changeFilter(filter, 'homeLocality', value as string[]))}
                            options={allLocalities.map(s => ({
                                value: s,
                                label: s
                            }))}
                            placeholder={t('all')}
                        />
                    }
                    title={t('locality_name')}
                />
            </div >
            {includeLocalUnits
                ? <div style={{
                    width: 105
                }}
                    className={style.column}>
                    <FilterableHeaderCell
                        size="small"
                        filter={
                            <MultiSelect
                                className="small"
                                allowClear={false}
                                popupClassName="small"
                                style={{ width: 87 }}
                                popupWidth={200}
                                value={filter.homeLocalUnit || []}
                                onChange={(value) => setFilter(
                                    changeFilter(filter, 'homeLocalUnit', value as string[]))}
                                options={allLocalUnits.map(u => ({
                                    value: u,
                                    label: u
                                }))}
                                placeholder={t('all')}
                            />
                        }
                        title={t('local_unit')}
                    />
                </div >
                : <></>
            }
            <div style={{
                width: 141
            }}
                className={style.column}>
                <div
                    className={style.colHighlighting}
                    id="voter-verification-in-person-absentee"
                    style={{
                        width: 280,
                        height: columnHighlightingHeight
                    }} />
                < FilterableHeaderCell
                    size="small"
                    filter={<></>}
                    title={
                        < SortableHeaderCell
                            value={filter.sortBy}
                            title={< div style={{ textAlign: 'left' }}>
                                {t('telling-station:paper_ballots')} <br />
                                {t('telling-station:in_person')}
                            </div >}
                            field={'inPerson'}
                            onChange={field => onSortingChange(field,
                                filter.sortBy?.field === field ? undefined : SortOrder.DESC)}
                        />
                    }
                />
            </div >
            <div style={{
                width: 141
            }}
                className={style.column}>
                <FilterableHeaderCell
                    size="small"
                    filter={<></>}
                    title={
                        < SortableHeaderCell
                            value={filter.sortBy}
                            title={<div style={{ textAlign: 'left' }}>
                                {t('telling-station:paper_ballots_absentee')}
                            </div>}
                            field={'inAbsence'}
                            onChange={field => onSortingChange(field,
                                filter.sortBy?.field === field ? undefined : SortOrder.DESC)}
                        />
                    }
                />
            </div >
            <div style={{
                width: 119
            }}
                className={style.column}>
                <div
                    className={style.colHighlighting}
                    id="voter-verification-online"
                    style={{
                        width: 119,
                        height: columnHighlightingHeight
                    }} />
                < FilterableHeaderCell
                    size="small"
                    filter={<></>}
                    title={
                        < SortableHeaderCell
                            value={filter.sortBy}
                            title={< div style={{ textAlign: 'left' }}>
                                {t('telling-station:all_online_ballots')}
                            </div >}
                            field={'online'}
                            onChange={
                                field => onSortingChange(field,
                                    filter.sortBy?.field === field ? undefined : SortOrder.DESC)
                            }
                        />
                    }
                />
            </div >
            <div style={{
                width: 144
            }}
                id="column"
                className={style.column}>
                <div
                    className={style.colHighlighting}
                    id="voter-verification-verified-by"
                    style={{
                        width: 144,
                        height: columnHighlightingHeight
                    }} />
                <FilterableHeaderCell
                    size="small"
                    filter={
                        < button className="btn-main-tertiary-md" onClick={clearAllFilters} >
                            {t('clear_all_fillters')}
                        </button >
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('telling-station:verified_by')}
                            field={'verifier'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div >
        </div>
    )
}

export default VoterVerificationTableHeader