import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { BallotingElection } from '../../models/balloting-election'

export const useOnlineVotingElection = (id: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.OnlineBallot)

    const getOnlineVotingElection = async () => {
        const res = await axios<BallotingElection>(`${moduleUrl}elections/${id}/voting`)

        return res.data
    }

    return useQuery<BallotingElection>({
        queryKey: ['voting', id],
        queryFn: getOnlineVotingElection,
        enabled
    })
}