import {
    useElection,
    useElectionBallotsCounts,
    useElectionOnlineBallotsCount,
    useElectionStationsVotesCounts
} from 'api'
import StatisticCounter from 'components/molecules/StatisticCounter'
import { useAuth } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import style from './index.module.scss'

function ConsolidatedBallotEntryStatistic() {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const { electionId } = useParams()

    const { data: election } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: stationsBallotsCounts } = useElectionBallotsCounts(electionId || auth!.electionId!)
    const { data: stationsVotesCounts } = useElectionStationsVotesCounts(electionId || auth!.electionId!)
    const { data: onlineBallotsCount } = useElectionOnlineBallotsCount(electionId || auth!.electionId!)
    const invalidVotesCount = stationsVotesCounts?.invalidVoteCount || 0
    const invalidBallotCount = stationsBallotsCounts?.invalidBallotCount || 0

    return (
        <div className={style.headerWrapper}>
            <div className={style.caption}>
                <div className={style.tableTitles}>
                    <div className={style.tableTitle}>
                        {t('consolidated_ballot_entry')}
                    </div>
                </div>
                <div className={style.statistic}>
                    <StatisticCounter value={onlineBallotsCount?.totalCount || 0}
                        title={t('online_ballots')} />
                    <StatisticCounter value={(stationsBallotsCounts?.validBallotCount || 0)}
                        title={t('election:valid_ballots')} />
                    <StatisticCounter value={(stationsBallotsCounts?.invalidBallotCount || 0)}
                        isInvalid={invalidBallotCount > 0}
                        title={t('election:invalid_ballots')} />
                    <StatisticCounter value={(stationsVotesCounts?.validVoteCount || 0) +
                        (onlineBallotsCount?.totalCount || 0) * (election?.numberOfVacancies || 0)}
                        title={t('valid_votes')} />
                    <StatisticCounter value={stationsVotesCounts?.invalidVoteCount || 0}
                        isInvalid={invalidVotesCount > 0}
                        title={t('election:invalid_votes')} />
                </div>
            </div>
        </div>
    )
}

export default ConsolidatedBallotEntryStatistic