import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { queryClient } from 'config/query-client'
import { useState } from 'react'

export function useSelectStandardFlow() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)
    const [electionId, setElectionId] = useState('')

    async function selectStandardFlow(electionId: string) {
        setElectionId(electionId)

        return await axios(`${moduleUrl}elections/${electionId}/vote-counting/follow-standard-flow`,
            {
                method: 'post',
                data: {}
            })
    }

    return useMutation({
        mutationFn: selectStandardFlow,
        onSuccess: async () => {
            await queryClient.invalidateQueries(['election-summary', electionId])
            await queryClient.invalidateQueries(['candidates-elected', electionId])
            await queryClient.invalidateQueries(['election-results-candidates', electionId])
            await queryClient.invalidateQueries(['election-results-tie', electionId])
            await queryClient.invalidateQueries(['breaking-tie-rounds', electionId])
            await queryClient.invalidateQueries(['online-voting', electionId])
            await queryClient.invalidateQueries(['candidates-online-votes', electionId])
        }
    })
}