import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { AvailableElection } from 'models'

export const useAvailableElections = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getAvailableElections = async () => {
        const restrictions = await axios<AvailableElection[]>(
            `${moduleUrl}me/elections/available-for-creation`
        )

        return restrictions.data
    }

    return useQuery({
        queryKey: ['elections-available-for-creation'],
        queryFn: () => getAvailableElections(),
        enabled
    })
}