import { Select as AntSelect } from 'antd'
import IconWrapper from 'components/atoms/IconWrapper'
import { Select } from 'components/atoms/Select'
import Preloader from 'components/molecules/Preloader'
import StatisticCounter from 'components/molecules/StatisticCounter'
import { useReportLoader } from 'hooks'
import { ReactComponent as ArrowDownload } from 'icons/arrow-download.svg'
import { ReportFormat, ReportType } from 'models'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useBahaiYears } from '../../../../api'
import { useDelegateElectionsData } from '../../hooks'
import style from './index.module.scss'

function DelegateElectionsTitle() {
    const { t } = useTranslation('administration')
    const { data: bahaiYears } = useBahaiYears()
    const { delegateElectionsData, setDelegateElectionsData } = useDelegateElectionsData()
    const { loadReport, isReportLoading } = useReportLoader()

    useEffect(() => {
        if (!delegateElectionsData.badiYear && bahaiYears?.length) {
            const fromStorage = localStorage.getItem('admin_dbd_delegate_year')
            const yearFromStorage = parseInt(fromStorage || '', 10)
            if (!isNaN(yearFromStorage) && !!bahaiYears.find(b => b.badiYear === yearFromStorage)) {
                setDelegateElectionsData((current) => ({ ...current, badiYear: yearFromStorage }))
            } else {
                setDelegateElectionsData((current) => ({ ...current, badiYear: bahaiYears[0].badiYear }))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bahaiYears])

    const downloadElectionStatistics = () => {
        loadReport({
            reportType: ReportType.NationalDelegateElections,
            reportFormat: ReportFormat.XLSX,
            params: {
                ballotingYear: delegateElectionsData.badiYear
            }
        })
    }

    return (
        <>
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            <div className={style.content}>
                <div className={style.label}>{t('national_bahai_delegate_elections')}</div>
                <div className={style.elements}>
                    <div className={style.actions}>
                        {bahaiYears &&
                            <Select
                                value={delegateElectionsData.badiYear}
                                onChange={(value: any) => {
                                    setDelegateElectionsData((current) =>
                                        ({ ...current, badiYear: value }))
                                    localStorage.setItem('admin_dbd_delegate_year', value)
                                }}
                                className={`${style.select} small`}>
                                {bahaiYears.map(y => <AntSelect.Option key={y.years} value={y.badiYear} >
                                    {t('common:year_b_e_period',
                                        {
                                            year: y.badiYear,
                                            period: y.years
                                        })
                                    }
                                </AntSelect.Option>)}

                            </Select>
                        }
                        <button
                            className={`btn-main-tertiary-md ${style.download}`}
                            onClick={downloadElectionStatistics}>
                            <IconWrapper>
                                <ArrowDownload className="flip-horizontal" width="16" height="16" />
                            </IconWrapper>
                            {t('election_statistic')}
                        </button>
                    </div>
                    <div className={style.counters}>
                        <StatisticCounter
                            value={(delegateElectionsData.summary?.totalUnits || 0)}
                            title={t('total_number_of_units')} />
                        <StatisticCounter
                            value={(delegateElectionsData.summary?.notCreated || 0)}
                            title={t('not_created')} />
                        <StatisticCounter
                            value={(delegateElectionsData.summary?.notStarted || 0)}
                            title={t('not_started')} />
                        <StatisticCounter
                            value={(delegateElectionsData.summary?.inProgress || 0)}
                            title={t('in_progress')} />
                        <StatisticCounter
                            value={(delegateElectionsData.summary?.completed || 0)}
                            title={t('completed')} />
                    </div>
                </div>
            </div >
        </>
    )
}

export default DelegateElectionsTitle