import { Form, FormListFieldData } from 'antd'
import Card from 'components/atoms/Card'
import Tooltip from 'components/atoms/Tooltip'
import CardSubheader from 'components/molecules/CardSubheader'
import Note from 'components/molecules/Note'
import { useConfirmPopup, useInfoPopup } from 'hooks'
import { ReactComponent as Bin } from 'icons/bin.svg'
import { ReactComponent as Eraser } from 'icons/eraser.svg'
import { ReactComponent as Plus } from 'icons/plus.svg'
import { AppointedTeller } from 'models'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MIN_TELLERS_PER_STATION } from '../../constants'
import { useStationActions, useStations, useTellerActions } from '../../hooks'
import TellerForm from '../TellerForm'

type StationFormProps = {
    field: FormListFieldData,
    remove: (field: any) => void,
    availableTellers?: AppointedTeller[]
}

const StationForm: FC<StationFormProps> = ({
    field,
    remove,
    availableTellers
}) => {
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { t } = useTranslation('station')
    const { showInfo, infoPortal } = useInfoPopup()
    const { stations } = useStations()
    const { onStationRemove, onStationCleanUp } = useStationActions()
    const { appendNewTeller } = useTellerActions()
    const [showTooFewTellersOfSavedStationWarning, setShowTooFewTellersOfSavedStationWarning] = useState(false)
    const ref = useRef<any | null>(null)

    const station = useMemo(() => stations[field.name],
        [stations, field.name])

    useEffect(() => {
        if (station?.saved && station?.tellers.filter(t => (t.id !== '')).length < MIN_TELLERS_PER_STATION) {
            setShowTooFewTellersOfSavedStationWarning(true)
        } else {
            setShowTooFewTellersOfSavedStationWarning(false)
        }
    }, [station]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div ref={ref}>
            <Card
                noContentPadding={false}
                title={
                    <CardSubheader
                        title={t('station_number', { number: station?.id.replace('S', '') })}
                    >
                        <button
                            className="btn-main-tertiary-md"
                            type="button"
                            onClick={() => {
                                showConfirm({
                                    text: <>{t('are_you_sure_you_want_to_clean_up_the_station_and_its_tellers')}</>,
                                    title: <>{t('clean_up_station')}</>,
                                    okText: <>{t('clean_up_station')}</>,
                                    onOk: async () => {
                                        onStationCleanUp(field.name)
                                    }
                                })
                            }}>
                            <Eraser width="20" height="20" />
                            <div>{t('clean_up_station')}</div>
                        </button>

                        {(stations.length > 1) &&
                            <Tooltip
                                title={t('station:delete_station')}
                                destroyTooltipOnHide={true}
                            >
                                <button
                                    className="btn-main-tertiary-md"
                                    type="button"
                                    onClick={() => {
                                        !station.wasOperating
                                            ? showConfirm({
                                                text: <>{t('are_you_sure_you_want_to_delete_this_station')}</>,
                                                title: <>{t('delete_station')}</>,
                                                okText: <>{t('delete_station')}</>,
                                                onOk: async () => {
                                                    remove(field.name)
                                                    onStationRemove(field.name)
                                                }
                                            })
                                            : showInfo({
                                                text: <>{t('the_station_station_sequence_number_cannot_be_deleted',
                                                    { station_number: station.id.replace('S,', '') })}</>,
                                                title: <>{t('cannot_delete_station')}</>,
                                                onOk: () => {
                                                }
                                            })
                                    }}>
                                    <Bin width="15" height="16" />
                                    <div>{t('delete_station')}</div>
                                </button>
                            </Tooltip>
                        }
                    </CardSubheader>
                }
            >
                {showTooFewTellersOfSavedStationWarning &&
                    <Form.Item>
                        <Note mode="error" icon="filled">
                            {t('telling_station_must_have_2_or_more_tellers_to_operate')}
                        </Note>
                    </Form.Item>
                }
                <Form.Item className="no-padding">
                    <Form.List name={[field.name, 'tellers']}>
                        {(tellersFields, options) => (
                            <>
                                {tellersFields.map((tellersField) => (
                                    <TellerForm
                                        key={tellersField.key}
                                        stationName={field.name}
                                        name={tellersField.name}
                                        remove={options.remove}
                                        availableTellers={availableTellers}
                                    />
                                )
                                )}
                                <button
                                    type="button"
                                    className="btn-main-tertiary"
                                    onClick={() => appendNewTeller(station)}
                                    disabled={tellersFields.length > 9}
                                >
                                    <Plus width="12" height="12" />
                                    <div>{t('add_teller_to_the_station')}</div>
                                </button>
                            </>
                        )
                        }
                    </Form.List>
                </Form.Item>
            </Card>
            {confirmPortal}
            {infoPortal}
        </div>
    )
}

export default StationForm