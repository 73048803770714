import { useSelectStandardFlow, useStopOnlineVoting } from 'api/mutations'
import { PopupProps } from 'components/molecules/Popup'
import { queryClient } from 'config/query-client'
import { useTranslation } from 'react-i18next'
import { useOnStandardFlowSelect } from '../../hooks'
import style from './index.module.scss'

type StopOnlineVotingProps = {
    onClose?: () => void
    selectElectionFlow: () => void
    electionId: string
    mustSelectFlow: boolean
    autoSelectFlow: boolean
    onVotingStop?: () => Promise<void>
    showPopup: (props: PopupProps) => void
    hidePopup: () => void
}

function StopOnlineVoting({ onClose,
    selectElectionFlow,
    electionId,
    mustSelectFlow,
    autoSelectFlow,
    showPopup,
    hidePopup,
    onVotingStop
}: StopOnlineVotingProps) {
    const { t } = useTranslation('teller')
    const { mutateAsync: stopOnlineVotingMutation } = useStopOnlineVoting()
    const { mutateAsync: selectStandardFlow } = useSelectStandardFlow()
    const { onStandardFlowSelect } = useOnStandardFlowSelect(showPopup, hidePopup, electionId)


    const stopOnlineVoting = async () => {
        await stopOnlineVotingMutation(electionId)
        if (onVotingStop) {
            await onVotingStop()
        }

        if (mustSelectFlow) {
            if (autoSelectFlow) {
                selectStandardFlow(electionId)
                await queryClient.invalidateQueries(['election', electionId])
                onClose?.()
                onStandardFlowSelect()
            } else {
                selectElectionFlow()
            }
        } else {
            onClose?.()
        }
    }

    const handleCancel = () => {
        onClose?.()
    }

    return (
        <>
            <div className={style.contentBlock}>
                {t('you_are_about_to_stop_online_voting_to_start_telling_process')}
            </div>
            <div className={style.boldText}>
                {t('please_note_this_is_an_irreversible_action')}
            </div>
            <div className={style.buttonsWrapper}>
                <button className={`${style.submit} btn-main-secondary`} onClick={() => stopOnlineVoting()}>
                    <span>{t('stop_online_voting')}</span>
                </button>
                <button className={`${style.cancel} btn-main-primary`} onClick={handleCancel}>
                    <span>{t('common:cancel')}</span>
                </button>
            </div>
        </>
    )
}

export default StopOnlineVoting