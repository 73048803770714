import IconWrapper from 'components/atoms/IconWrapper'
import { ReactComponent as ChevronBold } from 'icons/chevron-bold.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'


interface BackToDashboardBtnProps {
    backButtonTitle?: string,
    onClick?: (navigateToDashboard: (replace?: boolean) => void) => void
}

const BackToDashboardBtn: React.FC<BackToDashboardBtnProps> = ({ onClick, backButtonTitle }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    function navigateToDashboard(replace: boolean = true) {
        navigate('/', { replace })
    }

    return <button className="btn-main-tertiary-md hasIcon"
        onClick={() => { onClick ? onClick(navigateToDashboard) : navigateToDashboard(true) }}>
        <IconWrapper><ChevronBold className="flip-horizontal" width="16" height="16" /></IconWrapper>
        <span>{`${backButtonTitle ? backButtonTitle :
            (location.state?.backButtonTitle || t('back_to_dashboard'))}`}</span>
    </button>
}

export default BackToDashboardBtn