import { TourStepProps } from 'antd'
import TutorialPanel from 'components/molecules/TutorialPanel'
import TutorialPanelFooter from 'components/molecules/TutorialPanelFooter'
import { ReactNode } from 'react'

export const useTourStepCreator = () => {
    const createTourStep = (params: {
        placement: any
        targetId: string
        title?: ReactNode
        body?: ReactNode
        onClose?: () => void
        width?: number
        scrollIntoViewOptions?: boolean | ScrollIntoViewOptions
    }): TourStepProps => ({
        placement: params.placement,
        title: '',
        onFinish: params.onClose,
        target: document.getElementById(params.targetId),
        scrollIntoViewOptions: params.scrollIntoViewOptions,
        style: { width: params.width || 423 },
        renderPanel: (step: TourStepProps, current: number) => <TutorialPanel
            title={params.title}
            body={params.body}
            footer={<TutorialPanelFooter current={current} step={step} />}
            onClose={params.onClose}
            placement={step.placement} />
    })

    return { createTourStep }
}