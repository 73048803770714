import {
    useElection,
    useElectionBallotsCounts,
    useElectionOnlineBallotsCount,
    useElectionParticipantsStatistic,
    useElectionResultsTie,
    useElectionStationsVotesCounts,
    useElectionSummary,
    useElectionTellingStations,
    useVerifiedVoterCount,
    useVotersCount,
    useVotesCount
} from 'api'
import Card from 'components/atoms/Card'
import Checkbox from 'components/atoms/Checkbox'
import Note from 'components/molecules/Note'
import { useAuth } from 'hooks'
import { ElectionType, VotingApproach } from 'models'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
    useAlternativeCandidates,
    useByElectionNoLongerServingOfficers,
    useElectedElectionResultsCandidates,
    useElectionVoterSammaryReport
} from '../../api/queries'
import { reportingAssets } from '../../features/reportingAssets'
import TieResolvedInfo from '../TieResolvedInfo'
import style from './index.module.scss'

type ElectionResultProps = {
    onCheckboxChange: (checked: boolean) => void
    isValid: boolean
}

const ALTERNATIVE_CANDIDATES_COUNT = 3

function ElectionResult({ onCheckboxChange, isValid }: ElectionResultProps) {
    const { t } = useTranslation('telling-station')
    const { auth } = useAuth()
    const { electionId } = useParams<{ electionId: string }>()
    const [totalInvalidVotes, setTotalInvalidVotes] = useState<number>(0)

    const { data: election } = useElection(electionId! || auth?.electionId!, !!electionId || !!auth?.electionId)
    const { data: votesCount } = useVotesCount(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: votersCount } = useVotersCount(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: inPersonVotesCount } = useVotesCount(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId, VotingApproach.IN_PERSON)
    const { data: inAbsenceVotesCount } = useVotesCount(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId, VotingApproach.IN_ABSENCE)
    const { data: tellingStations } = useElectionTellingStations(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: participantsStatistic } = useElectionParticipantsStatistic(electionId ||
        auth?.electionId, !!electionId || !!auth?.electionId)
    const { data: verifiedVoterCount } =
        useVerifiedVoterCount(electionId || auth!.electionId!,
            !!electionId || !!auth?.electionId)
    const { data: ballotsCounts } = useElectionBallotsCounts(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: onlineBallotsCount } = useElectionOnlineBallotsCount(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: stationsVotesCounts } = useElectionStationsVotesCounts(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: electedElectionResultsCandidates } = useElectedElectionResultsCandidates(
        electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: topAlternativeCandidates } = useAlternativeCandidates(
        electionId || auth!.electionId!, election?.type === ElectionType.LOCAL_DELEGATE_ELECTION
    || election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION, { limit: ALTERNATIVE_CANDIDATES_COUNT })
    const { data: electionVoterSammaryReport } = useElectionVoterSammaryReport(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: electionResultTie } = useElectionResultsTie(election?.id.toString() || '', !!election)
    const { data: noLongerServingOfficers } =
        useByElectionNoLongerServingOfficers(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: summary } =
        useElectionSummary(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)

    const handleCheckboxChange = (bahaiId: string, checked: boolean) => {
        setCheckedStates({ ...checkedStates, [bahaiId]: checked })
        onCheckboxChange(checked)
    }

    const initialCheckboxStates = Array.isArray(auth)
        ? auth.reduce((states, teller) => {
            states[teller.id] = false

            return states
        }, {})
        : {}

    const [checkedStates, setCheckedStates] = useState(initialCheckboxStates)

    const handleTotalInvalidVotesChange = (totalVotes: number) => {
        setTotalInvalidVotes(totalVotes)
    }

    return (
        <>
            <Card noContentPadding={true} noHeaderLine={true} >
                <div className={style.contentWrapper}>
                    <reportingAssets.components.ReportLeftCardBlock
                        title={`${t('teller:tellers_report')} 
                        (${t('teller:unconfirmed_results')})`}>
                        <reportingAssets.components.ElectionEndYearBlock election={election}
                        />
                        {election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION &&
                            <Note mode={'info'} icon={'filled'}>
                                <div>
                                    {t('teller:results_must_be_confirmed_by')}
                                </div>
                            </Note>}
                        {!election?.bypassFlow && Array.isArray(tellingStations) && tellingStations.map(station => (
                            <div key={station.id} className={style.stationBlock}>
                                <reportingAssets.components.StationInfo
                                    stationId={station.id}
                                />
                                <div className={style.tellerBlock}>
                                    {station.signers?.map(signer => (
                                        <div key={signer.id} className={style.checkboxBlock}>
                                            <Checkbox disabled checked={station.stationReportAccepted}>
                                                <span className={style.disabledCheckbox}>
                                                    {/* TODO: Avoid 'I' as string constant.
                                                    TODO: Make whole string as constant instead */}
                                                    {`${t('i')}, ${signer.name} (${t('bahai_id')}:${signer.id}), 
                                                        ${t('confirm_the_telling_data_entered_by_the_station')}.`}
                                                </span>
                                            </Checkbox>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <div className={style.signBlock}>
                            <span className={style.signTitle}>{t('teller:signature_section')}</span>
                            <span className={style.signText}>{t('teller:signature_of_chief_tellers')}
                                <span className={style.asteriskMarkColor}>*</span>
                            </span>
                            <span className={style.checkboxBlock}>
                                <span className={isValid || checkedStates[auth!.id] ? ''
                                    : style.checkboxError}>
                                    <Checkbox onChange={(e) =>
                                        handleCheckboxChange(auth!.id, e.target.checked)}>
                                        <span className={checkedStates[auth!.id]
                                            ? style.checkedCheckboxText
                                            : isValid
                                                ? style.checkboxText
                                                : style.checkboxErrorText}>
                                            {/* TODO: Avoid 'I' as string constant.
                                                    TODO: Make whole string as constant instead */}
                                            {`${t('i')}, ${auth!.name} (${t('bahai_id')}:${auth!.id}), 
                                        ${t('teller:confirm_that_to_the_best_of_my_knowledge_all_members_of_the')}.`}
                                        </span>
                                    </Checkbox>
                                </span>
                            </span>
                        </div>
                    </reportingAssets.components.ReportLeftCardBlock>
                    <reportingAssets.components.ReportRightCardBlock>
                        <reportingAssets.components.TitleStatistic number={'1.'}
                            text={ElectionType.LOCAL_DELEGATE_ELECTION === election?.type
                                ? t('teller:total_of_eligible_electors_in_the_local_unit')
                                : ElectionType.NATIONAL_DELEGATE_ELECTION === election?.type
                                    ? t('teller:total_of_eligible_electors_in_the_unit')
                                    : t('teller:total_of_eligible_electors_in_the_locality')}
                            statistic={participantsStatistic?.voterCount ?? 0}
                        />
                        <reportingAssets.components.TitleStatistic number={'2.'}
                            text={t('teller:total_of_eligible_electors_voting')}
                            statistic={(verifiedVoterCount?.totalCount ?? 0) + (votersCount?.totalCount ?? 0)}
                        />
                        <reportingAssets.components.SubtitleStatistic
                            number={'2.1'}
                            text={t('teller:voting_in_person')}
                            statistic={(verifiedVoterCount?.votedInPersonCount ?? 0) +
                                (inPersonVotesCount?.totalCount ?? 0)}
                        />
                        <reportingAssets.components.ListItem>
                            <reportingAssets.components.StatisticList
                                text={t('teller:paper_ballot_at_the_meeting')}
                                statistic={verifiedVoterCount?.votedInPersonCount ?? 0}
                            />
                            <reportingAssets.components.StatisticList
                                text={t('teller:voting_online_at_the_meeting')}
                                statistic={inPersonVotesCount?.totalCount ?? 0}
                            />
                        </reportingAssets.components.ListItem>
                        <reportingAssets.components.SubtitleStatistic
                            number={'2.2'}
                            text={t('teller:voting_absentee')}
                            statistic={(verifiedVoterCount?.votedInAbsenceCount ?? 0) +
                                (inAbsenceVotesCount?.totalCount ?? 0)}
                        />
                        <reportingAssets.components.ListItem>
                            <reportingAssets.components.StatisticList
                                text={t('teller:physical_ballots')}
                                statistic={verifiedVoterCount?.votedInAbsenceCount ?? 0}
                            />
                            <reportingAssets.components.StatisticList
                                text={t('teller:voting_online')}
                                statistic={inAbsenceVotesCount?.totalCount ?? 0}
                            />
                        </reportingAssets.components.ListItem>
                        <reportingAssets.components.TitleStatistic number={'3.'}
                            text={t('teller:total_of_valid_ballots')}
                            statistic={(ballotsCounts?.validBallotCount ?? 0) +
                                (onlineBallotsCount?.totalCount ?? 0)}
                        />
                        <reportingAssets.components.ListItem>
                            <reportingAssets.components.StatisticList
                                text={t('teller:from_paper_ballots')}
                                statistic={ballotsCounts?.validBallotCount ?? 0}
                            />
                            <reportingAssets.components.StatisticList
                                text={t('teller:from_online_ballots')}
                                statistic={onlineBallotsCount?.totalCount ?? 0}
                            />
                        </reportingAssets.components.ListItem>
                        <reportingAssets.components.TitleStatistic number={'4.'}
                            text={t('total_of_invalid_ballots')}
                            statistic={(ballotsCounts?.invalidBallotCount || 0) +
                                (electionVoterSammaryReport?.markedInnerEnvelopeCount || 0)}
                        />
                        <reportingAssets.components.InvalidBallotsStatistic
                            electionVoterSammaryReport={electionVoterSammaryReport}
                            ballotsCounts={ballotsCounts}
                        />
                        <reportingAssets.components.InfoBlock />
                        <reportingAssets.components.TitleStatistic number={'5.'}
                            text={t('total_of_valid_votes')}
                            statistic={(stationsVotesCounts?.validVoteCount ?? 0) +
                                (votesCount?.totalCount ?? 0)}
                        />
                        <reportingAssets.components.TitleStatistic number={'6.'}
                            text={t('total_of_invalid_votes')}
                            statistic={totalInvalidVotes}
                        />
                        <reportingAssets.components.InvalidVotesStatistic
                            stationsVotesCounts={stationsVotesCounts}
                            onTotalInvalidVotesChange={handleTotalInvalidVotesChange}
                        />
                        {ElectionType.NATIONAL_DELEGATE_ELECTION === election?.type && summary?.tiePresent
                            &&
                            <div className={style.warningNote}>
                                <Note mode={'warning'} icon={'filled'}>
                                    <div>
                                        {t('teller:please_note_the_election_has_resulted_in_a_tie')}
                                    </div>

                                </Note>
                            </div>}
                        <reportingAssets.components.TitleStatistic mainText={
                            ElectionType.LOCAL_DELEGATE_ELECTION === election?.type
                                ? t('teller:elected_local_delegate')
                                : ElectionType.NATIONAL_DELEGATE_ELECTION === election?.type
                                    ? t('teller:elected_delegates')
                                    : t('teller:elected_assembly_members')
                        } />
                        <reportingAssets.components.ListOfCandidates
                            candidates={electedElectionResultsCandidates}
                            election={election}
                            tiePresent={summary?.tiePresent}
                        />
                        {(electionResultTie?.resolution.resolved || summary?.breakingTieSkipped)
                            &&
                            <TieResolvedInfo electionId={election?.id.toString() || ''} />}
                        {topAlternativeCandidates
                            && topAlternativeCandidates.length > 0
                            && (ElectionType.LOCAL_DELEGATE_ELECTION === election?.type
                                || ElectionType.NATIONAL_DELEGATE_ELECTION === election?.type)
                            &&
                            <>
                                <reportingAssets.components.TitleStatistic mainText={
                                    t('teller:individuals_receiving_the_next_highest_number_of_votes')} />
                                <reportingAssets.components.ListOfCandidates
                                    candidates={topAlternativeCandidates}
                                    tiePresent={summary?.tiePresent} />
                            </>
                        }
                        {ElectionType.BY_ELECTION === election?.type &&
                            <>
                                <reportingAssets.components.TitleStatistic
                                    mainText={t('teller:persons_no_longer_serving')} />
                                <reportingAssets.components.ListOfCandidates
                                    candidates={noLongerServingOfficers} />
                            </>
                        }
                    </reportingAssets.components.ReportRightCardBlock>
                </div>
            </Card >
        </>
    )
}

export default ElectionResult