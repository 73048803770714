import { useLocalDelegateElectionPushResult } from 'api'
import MilestoneItem from 'components/atoms/MilestoneItem'
import { MilestoneColorType, ElectionStatusType } from 'models'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DelegateElectionItem } from '../../models'
import { electionResultsColor } from '../../utils'

type DelegateElectionResultsCardProps = {
    election: DelegateElectionItem,
    status: ElectionStatusType
}

const DelegateElectionResultsCard: React.FC<DelegateElectionResultsCardProps> = ({ election, status }) => {
    const { t } = useTranslation('election')
    const navigate = useNavigate()

    const { data: ldePushResult } =
        useLocalDelegateElectionPushResult(election.electionPeriodId.toString() || '',
            !!election.electionPeriodId)

    const getCardData = () => {
        switch (status) {
            case ElectionStatusType.NOT_STARTED:
                return {
                    color: MilestoneColorType.DEFAULT,
                    buttonClass: 'btn-main-secondary-md not-underline',
                    buttonText: t('go_to_election_board'),
                    onClick: () => { },
                    disabled: true
                }
            case ElectionStatusType.IN_PROGRESS:
                return {
                    color: electionResultsColor(
                        election.resultsSignedBy,
                        ldePushResult
                    ),
                    buttonClass: election.resultsSignedBy
                        ? 'btn-main-primary-md not-underline' : 'btn-main-secondary-md not-underline',
                    buttonText: t('go_to_election_board'),
                    onClick: () => election.resultsSignedBy
                        && navigate(`/local-delegate-election-board/${election.electionPeriodId}`),
                    disabled: !election.resultsSignedBy
                }
            case ElectionStatusType.COMPLETED:
                return {
                    color: MilestoneColorType.SUCCESS,
                    buttonClass: 'btn-main-secondary-md not-underline',
                    buttonText: t('go_to_election_board'),
                    onClick: () => navigate(`/local-delegate-election-board/${election.electionPeriodId}`),
                    disabled: false
                }
            default:
                return null
        }
    }

    const cardData = getCardData()

    if (!cardData) {
        return null
    }

    const { color, buttonClass, buttonText, onClick, disabled } = cardData

    return (
        <MilestoneItem
            title={t('election_results')}
            color={color}
            description={t('confirmed_results_confirmed_of_total', {
                confirmed: election.unitsWithResultsConfirmedByNumber,
                total: election.unitsNumber
            })}
            children={
                <button
                    className={buttonClass}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {buttonText}
                </button>
            }
        />
    )
}

export default DelegateElectionResultsCard
