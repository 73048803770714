import { useBreakingTieRounds, useElection, useElectionSummary } from 'api'
import ContentWrapper from 'components/atoms/ContentWrapper'
import { useAuth, useConfirmPopup, useHandleEntityLoadingError } from 'hooks'
import { ElectionStatusType } from 'models'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ConsolidatedBallotEntry from '../../../../components/ConsolidatedBallotEntry'
import { useCancelLastTieRoundResultPopup, useMergeResultsData } from '../../hooks'
import MergedResultsHeader from '../MergedResultsHeader'
import MergedResultsTable from '../MergedResultsTable'
import TieInfoBlock from '../TieInfoBlock'
import TieRoundBlock from '../TieRoundBlock'

function MergedResults() {
    const { auth } = useAuth()
    const { electionId } = useParams()
    const navigate = useNavigate()
    const { confirmPortal, showConfirm, hide: closePopup } = useConfirmPopup()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()
    const { cancelLastTieRoundResultPopup } = useCancelLastTieRoundResultPopup(showConfirm, closePopup)
    const { data: election, error } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: summary, isError: isSummaryError } = useElectionSummary(electionId || auth!.electionId!,
        (!!electionId || !!auth?.electionId) && !!election)
    const { data: breakingTieRounds } =
        useBreakingTieRounds(auth!.electionId! || electionId!, !!auth!.electionId! || !!electionId)
    const [canUnmergeResults, setCanUnmergeResults] = useState(false)
    const { mergedResultsData } = useMergeResultsData()

    useEffect(() => {
        if (isSummaryError) {
            navigate('/page-not-found', { replace: true })

            return
        }
        if (!!election && !canUnmergeResults && summary && !summary?.mergedBy) {
            navigate('/page-not-found', { replace: true })
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [election, isSummaryError, summary])
    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if (!canUnmergeResults && summary && summary.mergedBy) {
            setCanUnmergeResults(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summary])

    useEffect(() => { window.scrollTo({ top: 0, left: 0 }) }, [])

    const completedRounds = breakingTieRounds?.filter(round =>
        round.status === ElectionStatusType.COMPLETED || round.status === ElectionStatusType.CANCELED)
        .sort((a, b) => b.roundNumber - a.roundNumber)

    const lastCompletedRound = breakingTieRounds
        ?.filter(round => round.status === ElectionStatusType.COMPLETED)
        .reduce((prev, current) => (prev.roundNumber > current.roundNumber)
            ? prev : current, {} as typeof breakingTieRounds[0])

    const deleteRoundPopup = () => {
        cancelLastTieRoundResultPopup(true)
    }

    return (
        <>
            {confirmPortal}
            <ContentWrapper>
                <MergedResultsHeader election={election} />
                {(summary?.tiePresent || mergedResultsData.isTieResolved)
                    && <TieInfoBlock
                        election={election}
                        summary={summary}
                    />
                }
                {election && <MergedResultsTable election={election} />
                }
                {completedRounds?.map(round => (
                    <TieRoundBlock
                        deleteRoundPopup={deleteRoundPopup}
                        isSigned={summary?.signedBy}
                        key={round.id}
                        round={round}
                        isRoundEnd={true}
                        isLastRound={lastCompletedRound?.id === round.id}
                    />
                ))}
                <ConsolidatedBallotEntry />
            </ContentWrapper>
        </>
    )
}

export default MergedResults