import { ElectionStatusType, ElectionType } from 'models'
import { RidvanElectionItem } from '../models'

export const getRidvanDraftElectionItem = (
    electionTitle: string,
    electionPeriodId: number
): RidvanElectionItem => ({
    status: ElectionStatusType.DRAFT,
    type: ElectionType.RIDVAN_ELECTION,
    title: electionTitle,
    onlineVotingStartDate: '-',
    electionDay: '-',
    electionPeriodId,
    requiredTellersAssigned: false,
    resultsSignedBy: false,
    resultsConfirmedBy: false,
    electionId: 0,
    isRidvanStage: false,
    atLeastOneTellerAssigned: false
})