import { useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { VotersSummary } from '../../models'

export const useVoterSummary = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getVoterSummary = async (): Promise<VotersSummary> => {
        const response = await axios<VotersSummary>(moduleUrl + 'stations/my/voter-summary-report')

        return response.data
    }

    return useQuery({
        queryKey: ['voter-summary-report'],
        queryFn: getVoterSummary,
        enabled
    })
}