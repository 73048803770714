import { Checkbox } from 'components/atoms/Checkbox'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { useCopyToClipboardWithToast } from 'hooks'
import { ElectionResult } from 'models'
import { CSSProperties } from 'react'
import { applyPhoneMask, capitalizeFirstLetter } from 'utils'
import style from './index.module.scss'

type ResultTableItemProps = {
    style?: CSSProperties,
    index: number,
    result: ElectionResult,
    resultsConfirmed: boolean,
    onConfirmCheckboxChange: () => void,
    isTieElectee?: boolean,
    isSkippedTieCandidate?: boolean,
    isTieButNotElected?: boolean,
    isConfirmedTieCandidate?: boolean,
    withLatestRound?: boolean
}
function ResultTableItem({
    result,
    index,
    style: itemStyle,
    resultsConfirmed,
    onConfirmCheckboxChange,
    isTieElectee,
    isSkippedTieCandidate,
    isTieButNotElected,
    isConfirmedTieCandidate,
    withLatestRound
}: ResultTableItemProps) {
    const gender = result.gender ? capitalizeFirstLetter(result.gender) : String.fromCharCode(8212)
    const { copy, contextHolder } = useCopyToClipboardWithToast()

    const itemClassName = `${style.listItem} 
    ${isTieElectee ? style.tieElectee : ''} 
    ${isSkippedTieCandidate ? style.skippedTieCandidate : ''} 
    ${isTieButNotElected && isConfirmedTieCandidate ? style.tieElectee : ''}`

    return (
        <div className={itemClassName} style={itemStyle}>
            {contextHolder}
            <div style={{ width: 60 }} className={style.column}>
                {index + 1}
            </div >
            <div style={{ width: 78 }} className={style.column}>
                {result.id}
            </div >
            <div style={{ width: !!withLatestRound ? 220 : 300 }} className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {result.name}
                </OptionalTooltip>
            </div >
            <div style={{ width: 72 }} className={style.column}>
                {gender}
            </div >
            <div style={{ width: 90 }} className={style.column}>
                {result.locality?.code || String.fromCharCode(8212)}
            </div >
            <div
                style={{ width: 136 }}
                className={`${style.column} ${style.pointer}`}
                onClick={() => { if (result.phone) copy(result.phone) }}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {result.phone ? applyPhoneMask(result.phone) : String.fromCharCode(8212)}
                </OptionalTooltip>
            </div >
            <div style={{ width: 214 }} className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {result.race}
                </OptionalTooltip>
            </div >
            <div style={{ width: 215 }} className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {result.ethnicity}
                </OptionalTooltip>
            </div >
            <div style={{ width: 80 }} className={style.column}>
                {result.voteCount}
            </div >
            {withLatestRound
                && <div style={{ width: 80 }} className={style.column}>
                    {result.tieLatestRoundVoteCount ? '+' + result.tieLatestRoundVoteCount : ''}
                </div >
            }
            <div style={{ width: 86 }} className={`${style.column} ${style.centered}`}>
                <Checkbox
                    checked={result.confirmed}
                    disabled={resultsConfirmed}
                    onChange={() => onConfirmCheckboxChange()}
                />
            </div >
        </div >
    )
}

export default ResultTableItem