import { PropsWithChildren, useState } from 'react'
import { TellingStationContext } from '../context'
import { TellingStationInfo } from '../models'

function TellingStationProvider({ children }: PropsWithChildren) {
    const [info, setInfo] = useState<TellingStationInfo>({})

    return (
        <TellingStationContext.Provider value={{ info, setInfo }} >
            {children}
        </TellingStationContext.Provider>
    )
}

export default TellingStationProvider