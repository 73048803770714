import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { StationTellers } from '../../models'

export const useStationTellers = (stationId: string, electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getStationTellers = async ({ queryKey }: QueryFunctionContext)
        : Promise<any> => {
        const [, stationId, electionId] = queryKey

        const res = await axios<StationTellers>(
            `${moduleUrl}elections/${electionId}/stations/${stationId}/tellers`)

        return res.data
    }

    return useQuery<StationTellers>({
        queryKey: ['tellers', stationId, electionId],
        queryFn: getStationTellers,
        enabled
    })
}