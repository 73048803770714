import { Radio } from 'antd'
import PopupRadioButton from 'components/atoms/PopupRadioButton'
import PopupButtons from 'components/molecules/PopupButtons'
import { ReactComponent as PeopleCommunityFilled } from 'icons/people-community-filled.svg'
import { ReactComponent as PeopleCommunity } from 'icons/people-community.svg'
import { ReactComponent as VoteFilled } from 'icons/vote-filled.svg'
import { ReactComponent as Vote } from 'icons/vote.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type TieSelectFlowProps = {
    onClose?: () => void,
    onProceedDesignateElectee?: () => void,
    onProceedRevoteUsing?: () => void
}

const TieSelectFlow: React.FC<TieSelectFlowProps> = ({ onClose, onProceedRevoteUsing, onProceedDesignateElectee }) => {
    const [selected, setSelected] = useState(false)
    const { t } = useTranslation('teller')

    const handleProceed = async () => {
        if (selected === true) {
            onProceedRevoteUsing?.()
        } else if (selected === false) {
            onProceedDesignateElectee?.()
        }
    }

    const handleCancel = () => {
        onClose?.()
    }

    return (
        <>
            <Radio.Group
                className={style.contentBlock}
                onChange={(e) => setSelected(e.target.value)}
                value={selected}
            >
                <PopupRadioButton
                    value={false}
                    defaultChecked={true}
                    buttonIcon={selected ? <PeopleCommunity /> : <PeopleCommunityFilled />}
                    labelTitle={t('designate_electee_in_obs')}
                    labelText={
                        // eslint-disable-next-line max-len
                        t('after_resolving_the_tie_using_the_minority_principle_or_a_revote_done_entirely_on_paper')}
                />
                <PopupRadioButton
                    value={true}
                    buttonIcon={selected ? <VoteFilled /> : <Vote />}
                    labelTitle={t('revote_using_obs')}
                    labelText={t('resolve_the_tie_through_another_round_of_voting_using_obs_or_paper_ballots')}
                />
            </Radio.Group>
            <PopupButtons
                cancelButtonName={t('common:cancel')}
                proccedButtonName={t('proceed_with_selected_option')}
                onCancel={handleCancel}
                onProceed={handleProceed}
            />
        </>
    )
}

export default TieSelectFlow