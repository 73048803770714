import { FormInstance } from 'antd'
import { TellerType } from 'models'
import { convertToServerString } from 'utils'
import { useAppointTellersTeam } from '../api'
import { SelectedTeller, TellerTeamMember, TellersTeam } from '../models'

type ManageTellersParams = {
    form: FormInstance,
    electionId: string,
}

export const useManageTellersActions = () => {
    const { mutateAsync: appointTellersTeam } = useAppointTellersTeam()

    const manageTellers = async ({
        form,
        electionId
    }: ManageTellersParams) => {
        const team = extractTellersTeam(form)

        await appointTellersTeam({ team, electionId })
    }

    const extractTellersTeam = (form: FormInstance) => {
        const tellers = form.getFieldsValue().tellers as SelectedTeller[]
        const chief = tellers.find(teller => teller.role === TellerType.CHIEF_TELLER)!

        const team: TellersTeam = {
            chiefTeller: chief.id ? {
                id: chief.id,
                phone: convertToServerString(chief.phone || ''),
                email: chief.email
            } : undefined,
            chiefTellerAssistants: [],
            tellers: []
        }


        tellers.forEach(teller => {
            if (teller.id) {
                const tellerTeamMember: TellerTeamMember = {
                    id: teller.id,
                    phone: convertToServerString(teller.phone || ''),
                    email: teller.email
                }
                switch (teller.role) {
                    case TellerType.CHIEF_TELLER_ASSISTANT:
                        team.chiefTellerAssistants.push(tellerTeamMember)
                        break
                    case TellerType.TELLER:
                        team.tellers.push(tellerTeamMember)
                }
            }
        })

        return team
    }

    const formContainsUnselectedTeller = (form: FormInstance) => {
        const formValue = form.getFieldsValue()
        if (formValue?.tellers) {
            const tellers = formValue.tellers as SelectedTeller[]

            return !!tellers.find(teller => !teller?.name)
        }

        return false
    }

    return { manageTellers, extractTellersTeam, formContainsUnselectedTeller }
}