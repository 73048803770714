import { Radio } from 'antd'
import IconWrapper from 'components/atoms/IconWrapper'
import PopupRadioButton from 'components/atoms/PopupRadioButton'
import { useChangeUser } from 'hooks'
import { TFunction } from 'i18next'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ReactComponent as DocumentFilled } from 'icons/document-add-filled.svg'
import { ReactComponent as Document } from 'icons/document-add.svg'
import { ReactComponent as GearFilled } from 'icons/gear-filled.svg'
import { ReactComponent as Gear } from 'icons/gear.svg'
import { ReactComponent as PeopleCommunityFilled } from 'icons/people-community-filled.svg'
import { ReactComponent as PeopleCommunity } from 'icons/people-community.svg'
import { ReactComponent as VoteFilled } from 'icons/vote-filled.svg'
import { ReactComponent as Vote } from 'icons/vote.svg'
import { Role, RoleAndElection } from 'models'
import { useEffect, useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useAvailableRoles } from '../../hooks'
import { AvailableRoles } from '../../models'
import PanelContent from '../common/PanelContent'
import style from './index.module.scss'

interface ActionsPanelProps {
    onProceed?: () => void
    showTutorialLink?: boolean
    defaultSelectedRole?: Role | RoleAndElection
}

const getAvailableFunctions = (
    t: TFunction<'auth', undefined>,
    selected: string,
    availableRoles: AvailableRoles
) => {
    const availableFunctions = []

    if (availableRoles.isLsaOfficer) {
        availableFunctions.push(
            <PopupRadioButton
                centerAlign={true}
                value={Role.LSA_OFFICER}
                key={Role.LSA_OFFICER}
                buttonIcon={selected === Role.LSA_OFFICER ? <DocumentFilled /> : <Document />}
                labelTitle={t('create_finalize_election')}
                labelSubTitle={t('as_role', { role: t('lsa_officer') })}
            />
        )
    }

    availableRoles.chiefTellerElections?.forEach(election => {
        availableFunctions.push(
            <PopupRadioButton
                centerAlign={true}
                key={election.id}
                value={JSON.stringify({ electionId: election.id.toString(), role: Role.CHIEF_TELLER })}
                buttonIcon={selected === JSON.stringify({ electionId: election.id.toString(), role: Role.CHIEF_TELLER })
                    ? <PeopleCommunityFilled /> : <PeopleCommunity />}
                labelTitle={election.name}
                labelSubTitle={t('as_role', {
                    role: t('chief_assistant_chief_teller'),
                    interpolation: { escapeValue: false }
                })}
            />
        )
    })

    if (availableRoles.isNsaAdmin) {
        availableFunctions.push(
            <PopupRadioButton
                value={Role.NSA_ADMIN}
                key={Role.NSA_ADMIN}
                buttonIcon={selected === Role.NSA_ADMIN ? <GearFilled /> : <Gear />}
                labelTitle={t('manage_elections')}
                labelSubTitle={t('as_role', { role: t('nsa_admin') })}
                centerAlign={true}
            />
        )
    }

    return availableFunctions
}

const ActionsPanel = ({
    onProceed,
    showTutorialLink = true,
    defaultSelectedRole
}: ActionsPanelProps) => {
    const { t } = useTranslation('auth')
    const { changeRole } = useChangeUser()
    const { availableRoles, isVoterOnly, hasDesktopOnlyRoles } = useAvailableRoles()

    const getSelectedValue = (role: Role | RoleAndElection | undefined) => {
        if (role && typeof role === 'object' && 'electionId' in role) {
            return JSON.stringify(role)
        }

        return role || Role.VOTER
    }

    const [selected, setSelected] = useState<string>(getSelectedValue(defaultSelectedRole))

    useEffect(() => {
        setSelected(getSelectedValue(defaultSelectedRole))
    }, [defaultSelectedRole])

    const proceed = (selected: string) => {
        try {
            const roleAndElection = JSON.parse(selected)
            changeRole(roleAndElection.role, roleAndElection.electionId)
        } catch {
            changeRole(selected as Role)
        }
        onProceed?.()
    }

    const proceedBtnAndServiceText = (
        <>
            <button className={`${style.button} btn-main-primary`} onClick={() => proceed(selected)}>
                <span>{t('common:proceed')}</span>
                <IconWrapper><Chevron /></IconWrapper>
            </button>
            {showTutorialLink && (
                <label
                    className={style.tutorialLink}>
                    {t('common:for_more_information')}
                    <Link
                        aria-label={`${t('common:for_more_information')} ${t('common:click_here')}`}
                        role="link"
                        to="https://helpcenter.obsbahai.org/"
                        target="_blank">{t('common:click_here')}</Link>
                </label>
            )}
            {hasDesktopOnlyRoles(availableRoles) && (
                <MobileView className={style.notSupportedOnMobile}>
                    <label>{t('options_not_supported_on_mobile')}</label>
                </MobileView>
            )}
        </>
    )

    return (
        <PanelContent>
            <Radio.Group
                className={style.options}
                defaultValue={selected}
                onChange={(e) => setSelected(e.target.value)}
            >
                {<PopupRadioButton
                    value={Role.VOTER}
                    buttonIcon={selected === Role.VOTER ? <VoteFilled /> : <Vote />}
                    labelTitle={t('cast_your_online_ballot')}
                    labelSubTitle={t('as_role', { role: t('common:online_voter') })}
                    disabledOnMobile={false}
                    centerAlign={true}
                />}

                {!!availableRoles.officerElectionTellerInfo && (
                    <PopupRadioButton
                        value={Role.LSA_TELLER}
                        buttonIcon={selected === Role.LSA_TELLER ? <PeopleCommunityFilled /> : <PeopleCommunity />}
                        labelTitle={`${t('officers_election')}, ${availableRoles.officerElectionTellerInfo.name}`}
                        labelSubTitle={t('as_role', { role: t('lsa_teller') })}
                        disabledOnMobile={false}
                        centerAlign={true}
                    />
                )}
                <MobileView>{proceedBtnAndServiceText}</MobileView>
                {!isVoterOnly(availableRoles) &&
                    getAvailableFunctions(t, selected, availableRoles)}
            </Radio.Group>
            <BrowserView>{proceedBtnAndServiceText}</BrowserView>
        </PanelContent>
    )
}

export default ActionsPanel