import { usePageHeader } from 'hooks'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import WizardChips from './WizardChips'
import WizardNextButton from './WizardNextButton'
import style from './index.module.scss'
import { IWizardStepProps, WizardTabStatus } from './models'

interface Props {
    headerLeftPanel: ReactNode
    steps: ReactElement<IWizardStepProps>[],
    stepStorageKey?: string
    pageHeader?: ReactNode,
    initStep?: number,
    onStepChange?: (newStepIndex: number) => void
}

const useWizard = (steps: ReactElement<IWizardStepProps>[],
    stepStorageKey?: string,
    onStepChange?: (newStepIndex: number) => void) => {

    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [isSessionStorageRead, setIsSessionStorageRead] = useState(false)
    const [tabStatuses, setTabStatuses] = useState<WizardTabStatus[]>(
        steps.map((step, index) =>
            index === 0 ? WizardTabStatus.Active : WizardTabStatus.NotActivated
        )
    )
    useEffect(() => {
        if (stepStorageKey) {
            const storageStep = sessionStorage.getItem(stepStorageKey)
            if (storageStep) {
                const storageStepIndex = parseInt(storageStep)
                if (!isNaN(storageStepIndex)) {
                    goToStep(storageStepIndex)
                    setIsSessionStorageRead(true)

                    return
                }
            }
            sessionStorage.setItem(stepStorageKey, currentStepIndex.toString())
        }
        setIsSessionStorageRead(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0 })
    }

    const goToNextStep = () => {
        if (currentStepIndex !== steps.length - 1) {
            setTabStatuses(tabStatuses.map((status, index) => {
                if (index <= currentStepIndex)
                    return WizardTabStatus.Valid

                if (index === currentStepIndex + 1)
                    return WizardTabStatus.Active

                return WizardTabStatus.NotActivated
            }))
            setCurrentStepIndex(currentStepIndex + 1)
            scrollToTop()
            onStepChange?.(currentStepIndex + 1)
            if (stepStorageKey) {
                sessionStorage.setItem(stepStorageKey, (currentStepIndex + 1).toString())
            }
        }
        else {
            if (stepStorageKey) {
                sessionStorage.removeItem(stepStorageKey)
            }
        }
    }

    const goToStep = (index: number) => {
        setTabStatuses(tabStatuses.map((status, i) => {
            if (i < index)
                return WizardTabStatus.Valid

            if (i === index)
                return WizardTabStatus.Active

            return WizardTabStatus.NotActivated
        }))
        onStepChange?.(index)
        if (stepStorageKey) {
            sessionStorage.setItem(stepStorageKey, index.toString())
        }
        scrollToTop()
        setCurrentStepIndex(index)
    }

    return {
        currentStepIndex,
        currentStepProps: steps[currentStepIndex].props,
        tabStatuses,
        goToNextStep,
        goToStep,
        isSessionStorageRead
    }
}

function Wizard({ headerLeftPanel, steps, stepStorageKey, pageHeader, initStep, onStepChange }: Props) {
    const {
        currentStepIndex,
        currentStepProps,
        tabStatuses,
        goToStep,
        goToNextStep,
        isSessionStorageRead
    } = useWizard(steps, stepStorageKey, onStepChange)
    const { setPageHeader } = usePageHeader()

    useEffect(() => {
        setPageHeader(
            <div className={style.headers}>
                {pageHeader}
                <div className={`${style.wizardHeaderWrapper} ${pageHeader
                    ? style.wizardHeaderWrapperWithPageHeader : ''}`}>
                    <div className={style.wizardHeader}>
                        <div className={style.headerContent}
                            style={{ minWidth: steps[currentStepIndex].props.minHeaderContentWidth || 860 }}>
                            <div className={style.wizardHeaderLeft}>
                                {headerLeftPanel}
                            </div>
                            <WizardChips currentStepIndex={currentStepIndex}
                                goToStep={goToStep}
                                steps={steps}
                                tabStatuses={tabStatuses} />
                            <div className={style.rightSide}>
                                {currentStepProps.rightSideExtraContent}
                                <WizardNextButton buttonSettings={currentStepProps.nextButton} onClick={onNextClick} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        return () => setPageHeader(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageHeader, tabStatuses, steps, currentStepIndex])

    useEffect(() => {
        if (initStep !== undefined) goToStep(initStep)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initStep])

    const onNextClick = async (callback: () => Promise<boolean>) => {
        const canGoNext = await callback()
        if (canGoNext) {
            goToNextStep()
        }
    }

    return (
        <div className={style.wizard}>
            <div className={style.wizardContent}>
                {isSessionStorageRead && steps[currentStepIndex]}
            </div>
        </ div>
    )
}

export default Wizard