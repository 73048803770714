import React from 'react'
import BackToDashboardBtn from '../BackToDashboardBtn'
import styles from './index.module.scss'

interface BackToDashboardLineProps {
    children?: React.ReactNode,
    onBackToDashboardBtnClick?: (navigateToDashboard: (replace?: boolean) => void) => void,
    fixed?: boolean,
    sticky?: boolean,
    fixedWidth?: boolean,
    backButtonTitle?: string,
    minHeaderContentWidth?: number
}

const BackToDashboardLine: React.FC<BackToDashboardLineProps> =
    ({ children, onBackToDashboardBtnClick, fixed, sticky, fixedWidth, backButtonTitle, minHeaderContentWidth }) => (
        <div className={`${styles.backToDashboardLineWrapper} ${sticky ? styles.sticky : ''}`}>
            <div className={`${styles.BackToDashboardLine} ${fixed ? styles.fixed : ''} 
         ${fixedWidth ? styles.fixedWidth : ''}`}>
                <div className={styles.content} style={{ minWidth: minHeaderContentWidth || 860 }}>
                    <BackToDashboardBtn backButtonTitle={backButtonTitle} onClick={onBackToDashboardBtnClick} />
                    <div>{children}</div>
                </div>
            </div>
        </div>
    )

export default BackToDashboardLine