import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionParticipant, Sortable } from 'models'
import { getUrlParamsFromFilter, transformSortable } from 'utils'

type CandidatesFilter = Sortable & {
    name?: string,
    offset?: number,
    limit?: number,
    id?: string
}

export const useElectionCandidates = (electionId: string, filter?: CandidatesFilter, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectionCandidates = async ({ queryKey }: QueryFunctionContext)
        : Promise<ElectionParticipant[]> => {
        const [, electionId, filter] = queryKey
        const queryParams = getUrlParamsFromFilter(transformSortable(filter as CandidatesFilter))

        const result = await axios<ElectionParticipant[]>(
            `${moduleUrl}elections/${electionId}/candidates${queryParams ? `?${queryParams}` : ''}`)

        return result.data
    }

    return useQuery({
        queryKey: ['election-candidates', electionId, filter],
        queryFn: getElectionCandidates,
        enabled
    })
}