import { ReasonForVacancy } from 'models'
import { useTranslation } from 'react-i18next'

export const useReasonForVacancyMap = () => {
    const { t } = useTranslation()

    return {
        toString: (reason: ReasonForVacancy): string => {
            switch (reason) {
                case ReasonForVacancy.ADDED_IN_ERROR:
                    return t('added_in_error')
                case ReasonForVacancy.DEATH:
                    return t('death')
                case ReasonForVacancy.RELOCATION:
                    return t('relocation')
                case ReasonForVacancy.REMOVAL:
                    return t('removal')
                case ReasonForVacancy.RESIGNATION:
                    return t('resignation')
                case ReasonForVacancy.UNKNOWN:
                    return t('unknown')
            }
        }
    }

}