import { useElectionTypeMap } from 'hooks'
import { Election, ElectionType } from 'models'
import { useTranslation } from 'react-i18next'
import InstitutionName from '../InstitutionName'
import style from './index.module.scss'

type ElectionEndYearBlockProps = {
    election?: Election
}

function ElectionEndYearBlock({ election }: ElectionEndYearBlockProps) {
    const { t } = useTranslation('telling-station')
    const { toString } = useElectionTypeMap()

    const details = {
        year: election?.ballotingYear,
        election: toString(election?.type as ElectionType),
        euCode: election?.region.unit,
        euNumberOfDelegates: election?.numberOfVacancies
    }

    return (
        <div className={style.electionNameBlock}>
            <span className={style.yearAndElectionWrapper}>
                {ElectionType.NATIONAL_DELEGATE_ELECTION === election?.type ?
                    `${t('year_b_e_election', details)} (${details.euCode})` :
                    t('year_b_e_election', details)}
            </span>
            <span>
                <InstitutionName election={election} />
            </span>
            {ElectionType.NATIONAL_DELEGATE_ELECTION === election?.type
                &&
                <>
                    <span className={style.yearAndElectionWrapper}>
                        {`${details.euNumberOfDelegates} ${t('teller:delegate_s')}`}
                    </span>
                </>
            }
        </div>
    )
}

export default ElectionEndYearBlock