import Note from 'components/molecules/Note'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

function InfoBlock() {
    const { t } = useTranslation('telling-station')

    return (
        <div className={style.noteWrapper}>
            <Note mode={'info'} icon={'filled'}>
                <div>
                    <span className={style.textBold}>{t('common:note')}</span>
                    {` ${t('duplicate_envelopes_outer_ballot_envelopes')} `}
                    <span className={style.textBold}>{`${t('not_included')} `}</span>
                    {t('in_the_total_invalid_ballots_count')}
                </div>
            </Note>
        </div>
    )
}

export default InfoBlock