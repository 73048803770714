import { Can as BaseCan, BoundCanProps } from '@casl/react'
import { AppAbility } from 'ability/ability'
import { AbilityContext, IAbilityContextProps } from 'context'
import { Consumer, FunctionComponent, createElement } from 'react'

function createContextualCan(Getter: Consumer<IAbilityContextProps>):
    FunctionComponent<BoundCanProps<AppAbility>> {
    return (props: BoundCanProps<AppAbility>) => createElement(Getter, {
        children: (abilityContextProps: IAbilityContextProps) => createElement(BaseCan, {
            ability: abilityContextProps.ability,
            ...props
        } as any)
    })
}

export const Can = createContextualCan(AbilityContext.Consumer)