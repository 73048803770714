import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionPushResult } from 'models'

export const useElectionPushResult = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectionPushResult = async (electionId: string) => {
        const result =
            await axios<ElectionPushResult>(
                `${moduleUrl}elections/${electionId}/push`)

        return result.data
    }

    return useQuery({
        queryKey: ['election-push-result', electionId],
        queryFn: () => getElectionPushResult(electionId),
        enabled
    })
}
