
import { useSelectStandardFlow } from 'api'
import PopupTitle from 'components/atoms/PopupTitle'
import { PopupProps } from 'components/molecules/Popup'
import SelectElectionFlow from 'components/organisms/SelectElectionFlow'
import { queryClient } from 'config/query-client'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useOnStandardFlowSelect } from './useOnStandardFlowSelect'

export const useSelectElectionFlow = (
    showPopup: (props: PopupProps) => void,
    hidePopup: () => void,
    electionId: string
) => {
    const { t } = useTranslation('teller')
    const navigate = useNavigate()
    const { mutateAsync: selectStandardFlow } = useSelectStandardFlow()
    const { onStandardFlowSelect } = useOnStandardFlowSelect(showPopup, hidePopup, electionId)

    const selectElectionFlow = async (autoStandardSelectFlow?: boolean) => {
        if (autoStandardSelectFlow) {
            await selectStandardFlow(electionId)
            await queryClient.invalidateQueries(['election', electionId])
            onStandardFlowSelect()
            
            return
        }
        showPopup({
            width: '660px',
            title: <PopupTitle>{t('physical_paper_ballots_exist')}</PopupTitle >,
            children: <SelectElectionFlow
                byPassFlowOption={{
                    title: t('no_we_dont_have_physical_ballots'),
                    subtitle: t('online_ballots_only'),
                    text: t('if_only_online_balloting_is_used_and_there_are_no_physical_ballots')
                }}
                standardFlowOption={{
                    title: t('yes_we_have_physical_ballots'),
                    subtitle: t('setup_telling_station_s'),
                    text: t('this_is_for_processing_physical_ballots_including_voter_verification')
                }}
                onClose={() => hidePopup()}
                electionId={electionId}
                onByPassSelect={() => {
                    navigate('/merge-results')
                }}
                onStandardSelect={() => { onStandardFlowSelect() }} />,
            footer: null
        })
    }

    return {
        selectElectionFlow
    }
}