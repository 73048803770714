import { Select } from 'components/atoms/Select'
import Tooltip from 'components/atoms/Tooltip'
import { useColorStyleMap } from 'hooks'
import { ColorStyle } from 'models'
import { useCallback, useEffect, useState } from 'react'
import style from './index.module.scss'

interface RichTextEditorTextColorPickerProps {
    color: ColorStyle
    disabled?: boolean,
    onChange?: (value: ColorStyle) => void
}

function RichTextEditorTextColorPicker({
    color,
    disabled,
    onChange
}: RichTextEditorTextColorPickerProps) {
    const [selfColor, setSelfColor] = useState(color)
    const { toString } = useColorStyleMap()

    const onColorChange = useCallback((newColor: ColorStyle) => {
        setSelfColor(newColor)
        onChange?.(newColor)
    }, [])

    useEffect(() => {
        if (color === undefined) {
            return
        }
        setSelfColor(color)
    }, [color])

    return (<>
        <Select
            disabled={disabled}
            value={selfColor}
            onChange={(value) => onColorChange(value as ColorStyle)}
            popupMatchSelectWidth={104}
            className={style.select}
            options={(Object.keys(ColorStyle) as Array<keyof typeof ColorStyle>)
                .map((fs) => ({
                    value: ColorStyle[fs],
                    label: <Tooltip title={toString(ColorStyle[fs])}>
                        <div className={style.colorOption}
                            style={{ backgroundColor: ColorStyle[fs] }} />
                    </Tooltip>

                })

                )}
        />
    </>
    )
}

export default RichTextEditorTextColorPicker