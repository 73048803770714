
import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { queryClient } from 'config/query-client'
import { useState } from 'react'

type UpdateCandidatePhysicalVotesProps = {
    electionId: string,
    candidateId: string,
    votesCount: number
}

export function useUpdateCandidatePhysicalVotes() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)
    const [electionId, setElectionId] = useState('')

    async function updateCandidatePhysicalVotes(
        { electionId, candidateId, votesCount }
            : UpdateCandidatePhysicalVotesProps) {

        setElectionId(electionId)

        return await axios(
            `${moduleUrl}draft/elections/${electionId}/election-results/break-tie/candidates/${candidateId}/vote-count`,
            {
                method: 'post',
                data: votesCount
            })
    }

    return useMutation({
        mutationFn: updateCandidatePhysicalVotes,
        onSuccess: async () => {
            await queryClient.invalidateQueries(['candidates-physical-votes', electionId])
        }
    })
}