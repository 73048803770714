import { TourProps } from 'antd'
import TutorialHighlight from 'components/molecules/TutorialHighlight'
import { useBrowserZoomRatio } from 'hooks'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTellingStationProcessInfo, useTourStepCreator } from '../../hooks'

const TIPS_KEY = 'includeReportingTips'
const MaxRatioForTutorialVisible = 1.20
const StepsAdaptationRatio = 1.03

function ReportingTutorial() {
    const { t } = useTranslation('telling-station-sandbox')
    const { info, setInfo } = useTellingStationProcessInfo()
    const { createTourStep } = useTourStepCreator()
    const { browserZoomRatio } = useBrowserZoomRatio()

    const onClose = () => {
        setInfo(current => ({ ...current, tutorialShown: false }))
        sessionStorage.removeItem(TIPS_KEY)
    }

    useEffect(() => {
        const tutorialShown = sessionStorage.getItem(TIPS_KEY) === 'true'
        setInfo(current => ({ ...current, tutorialShown }))
    }, [setInfo])

    const steps: TourProps['steps'] = useMemo(() => {
        if (browserZoomRatio > MaxRatioForTutorialVisible) {
            return []
        }

        return [
            createTourStep({
                placement: 'left',
                targetId: 'station-statistic',
                title: t('station_statistics'),
                body: t('verify_the_total_number_of_ballots_is_equal_to_total_number_of_verified_eligible_electors'),
                width: browserZoomRatio > StepsAdaptationRatio ? 390 : 423,
                onClose
            }),
            createTourStep({
                placement: 'right',
                targetId: 'signature-comment',
                title: t('comment'),
                body: t('in_case_the_total_ballots_exceed_the_number_of_verified_voters'),
                onClose
            }),
            createTourStep({
                placement: 'right',
                targetId: 'signature-section',
                title: t('confirm_telling_data'),
                body: t('tellers_must_check_their_corresponding_boxes_only_when_all_of_them_agree'),
                onClose
            }),
            createTourStep({
                placement: 'leftTop',
                targetId: 'wizard-next-step',
                title: t('complete_telling_process'),
                body: t('now_the_tellers_job_is_done'),
                onClose
            })
        ]
    }, [browserZoomRatio, info])

    return (
        <TutorialHighlight
            onChange={() => window.scrollTo({ top: 0, left: 0 })}
            steps={steps}
            gap={{ offset: 12 }}
            open={info.tutorialShown}
            disabledInteraction
            scrollIntoViewOptions
        />
    )
}

export default ReportingTutorial