import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionRestrictions } from 'models'

export const useElectionRestrictionsOld = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getRestrictionsOld = async (): Promise<ElectionRestrictions> => {
        const restrictions = await axios<ElectionRestrictions>(moduleUrl + 'elections/restrictions/old')

        return restrictions.data
    }

    return useQuery({
        queryKey: ['restrictions-old'],
        queryFn: getRestrictionsOld,
        enabled
    })
}