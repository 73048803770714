import { ConfirmPopupSettings, useAuth } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCancelTieRound } from '../../../api'
import { useMergeResultsData } from './useMergeResultsData'

export const useCancelLastTieRoundResultPopup
    = (showConfirm: (props: ConfirmPopupSettings) => void, hide: () => void) => {
        const { t } = useTranslation()
        const { auth } = useAuth()
        const { electionId } = useParams()
        const { mergedResultsData } = useMergeResultsData()
        const { mutateAsync: cancelTieRound } = useCancelTieRound()

        const cancelLastTieRoundResultPopup = (isAdminView: boolean, onDiscard?: () => void) => {
            const round = mergedResultsData.lastRound?.roundNumber

            const texts = isAdminView
                ? {
                    title: t('administration:delete_revote_using_obs_round', { number: round }),
                    text: t('administration:you_are_about_to_delete_revote_using_obs_round', { number: round }),
                    okText: t('administration:cancel'),
                    cancelText: t('administration:delete_round')
                }
                : {
                    title: t('teller:clear_all_votes'),
                    text: t('teller:you_are_about_to_cancel_revote_using_obs_round_number_and_clear_all_votes',
                        { number: round }),
                    okText: t('teller:continue_voting'),
                    cancelText: t('teller:clear_all_votes')
                }

            showConfirm({
                title: <>{texts.title}</>,
                text: (
                    <>
                        <div>
                            {texts.text}
                            <div style={{ paddingTop: 'var(--default-space)' }}>
                                <b>{t('teller:please_note_this_is_an_irreversible_action')}</b>
                            </div>
                        </div>
                    </>
                ),
                okText: <>{texts.okText}</>,
                cancelText: <>{texts.cancelText}</>,
                onOk: async () => { },
                onCancel: async () => {
                    await cancelTieRound({ electionId: auth?.electionId! || electionId! })
                    onDiscard?.()
                    hide()
                },
                onClose: () => { }
            })
        }

        return {
            cancelLastTieRoundResultPopup
        }
    }