import { TourStepProps } from 'antd'
import IconWrapper from 'components/atoms/IconWrapper'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type TutorialPanelFooterProps = {
    step: TourStepProps
    current: number
    noStepIndicator?: boolean
    noStepControls?: boolean
}

function TutorialPanelFooter({
    step,
    current,
    noStepIndicator,
    noStepControls
}: TutorialPanelFooterProps) {
    const { t } = useTranslation()

    const isLastStep = step.total === current + 1

    return (
        <div className={style.footer}>
            <div className={style.stepIndicator}>
                {!noStepIndicator
                    && `${current + 1}/${step.total}`}
            </div>
            {!noStepControls
                && <div className={style.stepControls}>
                    {current !== 0 && <button
                        type="button"
                        className="btn-main-secondary-md"
                        onClick={() => step.onPrev?.()}
                    >

                        <IconWrapper>
                            <Chevron className="flip-horizontal" height={12} />
                        </IconWrapper>
                        <span>{t('previous')}</span>
                    </button>
                    }
                    {<button
                        type="button"
                        className={`${style.next} btn-main-primary-md`}
                        onClick={() => isLastStep ? step.onFinish?.() : step.onNext?.()}
                    >
                        <span>{isLastStep ? t('complete') : t('next')}</span>
                        {!isLastStep
                            && <IconWrapper>
                                <Chevron height={12} />
                            </IconWrapper>
                        }
                    </button>
                    }
                </div>}
        </div>
    )
}

export default TutorialPanelFooter