import { Dispatch, SetStateAction, createContext } from 'react'
import { VotingStatisticTableData } from '../models'

export type VotingStatisticTableDataContextProps = {
    tableData: VotingStatisticTableData,
    setTableData: Dispatch<SetStateAction<VotingStatisticTableData>>
}

export const VotingStatisticTableDataContext =
    createContext<VotingStatisticTableDataContextProps>({} as VotingStatisticTableDataContextProps)
