import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export function useAnnounceByElectionPeriod() {
    const axios = useAxios()
    const queryClient = useQueryClient()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function announceByElectionPeriod(electionPeriod: string) {
        return await axios(moduleUrl
            + `by-election-periods/${electionPeriod}/election/announce`,
            {
                method: 'post'
            })
    }

    return useMutation({
        mutationFn: announceByElectionPeriod,
        onSuccess: () => {
            queryClient.invalidateQueries(['chief-teller-elections'])
        }
    })
}