import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'
import { isPhoneNumberValidFunc } from 'utils/validation'

const US_REGION = 'US'

export const convertToServerString = (phoneString: string) => {
    if (!phoneString) {
        return ''
    }
    const phoneUtil = PhoneNumberUtil.getInstance()
    try {
        const phoneNumber = phoneUtil.parse(phoneString, US_REGION)

        return phoneUtil.format(phoneNumber, PhoneNumberFormat.E164)
    } catch {
        return ''
    }
}

export const applyPhoneMask = (phoneString: string) => {
    if (!phoneString || !isPhoneNumberValidFunc(phoneString)) {
        return ''
    }

    const phoneUtil = PhoneNumberUtil.getInstance()
    const phoneNumber = phoneUtil.parse(phoneString, US_REGION)

    return phoneUtil.formatInOriginalFormat(phoneNumber, US_REGION)

}