import { AppAbility, defineAbility } from 'ability/ability'
import { AbilityContext } from 'context'
import { PropsWithChildren, useState } from 'react'

function AbilityProvider({ children }: PropsWithChildren) {
    const [ability, setAbility] = useState<AppAbility | null>(defineAbility())

    return (
        <AbilityContext.Provider value={{ ability, setAbility }} >
            {children}
        </AbilityContext.Provider>
    )
}

export default AbilityProvider