import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

function SupportedBrowsersHint() {
    const { t } = useTranslation('auth')

    return (
        <div tabIndex={0} className={style.supportedBy}>{t('supported_by')}</div>
    )
}

export default SupportedBrowsersHint