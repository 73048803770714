import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { TimeZone } from 'models'

dayjs.extend(utc)
dayjs.extend(timezone)

export const compareTimeZones = (zoneA: TimeZone, zoneB: TimeZone) => {
    const date = dayjs()

    const offsetA = date.tz(zoneA).utcOffset()
    const offsetB = date.tz(zoneB).utcOffset()

    return offsetA - offsetB
}