import IconWrapper from 'components/atoms/IconWrapper'
import { ReactComponent as Bubbles } from 'icons/bubbles.svg'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import { ReactNode } from 'react'
import style from './index.module.scss'

type TutorialPanelProps = {
    title?: ReactNode
    body?: ReactNode
    footer?: ReactNode
    onClose?: () => void
    placement?: string
}

function TutorialPanel({
    title,
    body,
    footer,
    onClose,
    placement
}: TutorialPanelProps) {
    return (
        <div className={`${style.panel} ${placement ? style[placement] : ''}`}>
            <div className={style.header}>
                <div className={style.title}>
                    {title}
                </div>
                {
                    !!onClose && <button
                        className={`btn-main-tertiary-md ${style.dismiss}`}
                        type="button"
                        onClick={() => onClose()}>
                        <IconWrapper>
                            <Dismiss width="20" height="20" />
                        </IconWrapper>
                    </button>
                }
            </div>
            <div className={style.body}>
                {body}
            </div>
            {!!footer
                && <div className={style.footer}>
                    {footer}
                </div>
            }
            <Bubbles height={44} width={90} className={`${style.bubbles} ${placement ? style[placement] : ''}`} />
        </div>
    )
}

export default TutorialPanel