import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { Election, ElectionStatusType } from 'models'

export const useCompletedElections = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getCompletedElections = async () => {
        const res = await axios<Election[]>(`${moduleUrl}me/lsa-officer/elections/completed?balloting-year=current`)

        return res.data.filter(e => e.status === ElectionStatusType.COMPLETED)
    }



    return useQuery({
        queryKey: ['elections-my-completed'],
        queryFn: () => getCompletedElections(),
        enabled
    })
}