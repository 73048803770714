import { Col, Form, Row } from 'antd'
import IconWrapper from 'components/atoms/IconWrapper'
import Input from 'components/atoms/Input'
import Tooltip from 'components/atoms/Tooltip'
import HighlightedDuplicatesSelect from 'components/molecules/HighlightedDuplicatesSelect'
import { useConfirmPopup, useTellerTypeMap } from 'hooks'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import { AppointedTeller } from 'models'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MIN_TELLERS_PER_STATION } from '../../constants'
import { useStations, useTellerActions } from '../../hooks'
import styles from './index.module.scss'

type TellerFormProps = {
    name: any,
    remove: (field: any) => void,
    availableTellers?: AppointedTeller[],
    stationName: number
}

const TellerForm: FC<TellerFormProps> = ({
    name,
    remove,
    availableTellers,
    stationName
}) => {
    const { t } = useTranslation()
    const { toString } = useTellerTypeMap()
    const { stations } = useStations()
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { changeTeller, onStationTellerRemove } = useTellerActions()

    const station = useMemo(() => {
        if (!stations) return

        return stations[stationName]
    }, [stations, stationName])

    const canDelete = useMemo(() => {
        if (!stations) return false

        return stations[stationName].tellers.length > MIN_TELLERS_PER_STATION
    }, [stations, stationName])

    const onTellerSelectChange = (selectedTeller: any, tellerNumber: number) => {
        const teller = availableTellers?.find((t) => (t.id === selectedTeller?.id))

        changeTeller(station!, tellerNumber, showConfirm, teller)
    }

    const defaultValue = useMemo(() => {
        if (station) {
            const teller = station.tellers[name]
            if (teller?.id) {
                return {
                    id: teller.id,
                    name: teller.name
                }
            }
        }

        return undefined
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [station, name])

    const selectOptionTellers = useMemo(() => {
        if (!availableTellers) return

        const options = availableTellers.map((teller) => ({
            name: teller.name,
            id: teller.id,
            role: teller.role
        }))

        return options.sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
    }, [availableTellers]) // eslint-disable-line react-hooks/exhaustive-deps

    const selectedTellers = useMemo(() => {
        const tellers: Array<{ id: string, name: string }> = []
        stations.forEach(s => s.tellers?.forEach(t => {
            if (t.id) {
                tellers.push({
                    id: t.id,
                    name: t.name
                })
            }
        }))

        return tellers
        // eslint-disable-line @typescript-eslint/no-unused-vars
    }, [stations])

    return (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={t('full_name')}
                        name={[name, 'id']}
                        rules={[{ required: true, message: t('teller:please_select_the_full_name') }]}
                    >
                        <HighlightedDuplicatesSelect
                            placeholder={t('select_input', { input: t('full_name') })}
                            onChange={(selectedTeller: any) => { onTellerSelectChange(selectedTeller, name) }}
                            allowClear
                            defaultValue={defaultValue}
                            optionRenderFunction={(participant: any) =>
                                `${participant.name} (${toString(participant.role)})`}
                            options={selectOptionTellers}
                            alreadySelectedOptions={selectedTellers}
                            disableDuplicates />

                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        label={t('bahai_id')}
                        name={[name, 'id']}
                    >
                        <Input disabled={true} placeholder={t('bahai_id')} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <div className={styles.columnHasActionBtn}>
                        <Form.Item
                            label={t('position')}
                        >
                            <Input
                                disabled={true}
                                placeholder={t('position')}
                                value={station?.tellers[name]?.role ? toString(station.tellers[name].role) : ''}
                            />
                        </Form.Item>
                        {canDelete &&
                            <Tooltip
                                title={t('teller:delete_teller')}
                                destroyTooltipOnHide={true}
                            >
                                <button
                                    className="btn-main-tertiary"
                                    type="button"
                                    onClick={() => {
                                        showConfirm({
                                            text: <>{
                                                t('teller:are_you_sure_you_want_to_delete_the_teller_from_the_station')
                                            }</>,
                                            title: <>{t('teller:delete_teller')}</>,
                                            okText: <>{t('teller:delete_teller')}</>,
                                            onOk: async () => {
                                                remove(name)
                                                onStationTellerRemove(stationName, name)
                                            }
                                        })
                                    }}>
                                    <IconWrapper><Dismiss width="15" height="15" /></IconWrapper>
                                </button>
                            </Tooltip>
                        }
                    </div>
                </Col>
            </Row>
            {confirmPortal}
        </>
    )
}

export default TellerForm