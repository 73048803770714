import { useAuth } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import style from './index.module.scss'

type StationInfoProps = {
    stationId?: string
}

function StationInfo({ stationId }: StationInfoProps) {
    const { auth } = useAuth()
    const { t } = useTranslation('telling-station')
    const params = useParams()
    const paramsStationId = params.stationId

    return (
        <div className={style.infoBlock}>
            <span className={style.infoTitle}>
                {`${t('teller:telling_station')} 
                                ${(paramsStationId || auth!.stationId || stationId)?.replace('S', '')}`}</span>
            <span className={style.infoText}>{t('checking_the_box_next_to_each_tellers_name')}</span>
        </div>
    )
}

export default StationInfo