import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionResultTie } from 'models'

export const useElectionResultsTie = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectionResultsTie = async ({ queryKey }: QueryFunctionContext) => {
        const [, electionId] = queryKey

        const res =
            await axios<ElectionResultTie>(`${moduleUrl}elections/${electionId}/election-results/tie`)

        return res.data
    }

    return useQuery<ElectionResultTie, Error>({
        queryKey: ['election-results-tie', electionId],
        queryFn: getElectionResultsTie,
        enabled: !!electionId && enabled
    })
}