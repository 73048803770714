import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { queryClient } from 'config/query-client'
import { useState } from 'react'

type RevokeLastTieRoundResultsProps = {
    electionId: string,
}

export function useRevokeDesignatedCandidates() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)
    const [electionId, setElectionId] = useState('')

    async function RevokeDesignatedCandidates({ electionId }: RevokeLastTieRoundResultsProps) {

        setElectionId(electionId)

        return await axios(
            `${moduleUrl}elections/${electionId}/election-results/revoke-designated-candidates`,
            {
                method: 'post',
                data: {}
            })
    }

    return useMutation({
        mutationFn: RevokeDesignatedCandidates,
        onSuccess: async () => {
            await queryClient.invalidateQueries(['election-summary', electionId])
            await queryClient.invalidateQueries(['candidates-elected', electionId])
            await queryClient.invalidateQueries(['election-results-candidates', electionId])
            await queryClient.invalidateQueries(['election-results-tie', electionId])
            await queryClient.invalidateQueries(['breaking-tie-rounds', electionId])
            await queryClient.invalidateQueries(['online-voting', electionId])
            await queryClient.invalidateQueries(['designated-tie-candidates', electionId])
        }
    })
}