import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

type useStartTrainingSessionProps = {
    envelopes: number
    vacancies: number
}

export function useStartTrainingSession() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.NoModule)

    async function startSession({ envelopes, vacancies }: useStartTrainingSessionProps) {
        return await axios(`${moduleUrl}start`,
            {
                method: 'post',
                data: {
                    envelopes,
                    vacancies
                }
            })
    }

    return useMutation({
        mutationFn: startSession
    })
}