import { HeaderLogoRedirectContext } from 'context'
import { PropsWithChildren, useState } from 'react'

function HeaderLogoRedirectProvider({ children }: PropsWithChildren) {
    const [confirmationCallback, setConfirmationCallback] =
        useState<((redirect: (replace?: boolean) => void) => void) | boolean>(false)

    return (
        <HeaderLogoRedirectContext.Provider value={{ confirmationCallback, setConfirmationCallback }} >
            {children}
        </HeaderLogoRedirectContext.Provider>
    )
}

export default HeaderLogoRedirectProvider