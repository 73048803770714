import { ReactComponent as CheckmarkInDoubleCircleFilled } from 'icons/checkmark-in-double-circle-filled.svg'
import { ReactComponent as Circle } from 'icons/circle.svg'
import { ReactComponent as RoundedCircle } from 'icons/rounded-circle.svg'
import { ElectionStatusType } from 'models'
import { useEffect, useState } from 'react'
import { ChiefTellersTasks } from '../../model'
import style from './index.module.scss'

enum Status {
    TellingNotStarted,
    TellingInProgress,
    TellingComplete,
    MergingInProgress,
    MergingComplete,
    ReportingInProgress,
    ReportingComplete
}

interface DelegateElectionBallotingProgressProps {
    chiefTellerTasks: ChiefTellersTasks
    electionStatus: ElectionStatusType
}

function DelegateElectionBallotingProgress(
    { chiefTellerTasks, electionStatus }: DelegateElectionBallotingProgressProps) {
    const [status, setStatus] = useState<Status>(Status.TellingNotStarted)

    useEffect(() => {
        if (chiefTellerTasks) {
            const signed = !!chiefTellerTasks.reportSigned
            const merged = !!chiefTellerTasks.resultsMerged

            if (signed) {
                setStatus(Status.ReportingComplete)

                return
            }

            if (merged) {
                setStatus(Status.MergingComplete)

                return
            }

            const notStartedElectionStatuses = [
                ElectionStatusType.NOT_CREATED,
                ElectionStatusType.DRAFT,
                ElectionStatusType.NOT_STARTED
            ]

            if (notStartedElectionStatuses.includes(electionStatus)) {
                setStatus(Status.TellingNotStarted)

                return
            }

            setStatus(Status.TellingInProgress)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chiefTellerTasks])

    const getTellingItem = () => {
        if (chiefTellerTasks.bypassFlowEnabled) {
            return <div className={`${style.status} ${style.hidden}`}>
                <Circle />
            </div>
        }
        switch (status) {
            case Status.TellingNotStarted:
                return <div className={`${style.status} ${style.untouched} ${style.nextStepUntouched}`}>
                    <Circle />
                </div>
            case Status.TellingInProgress:
                return <div className={`${style.status} ${style.expected} ${style.nextStepUntouched}`}>
                    <RoundedCircle />
                </div>
            default:
                return <div className={`${style.status} ${style.done} ${style.nextStepDone}`}>
                    <CheckmarkInDoubleCircleFilled />
                </div>
        }
    }

    const getMergingItem = () => {
        switch (status) {
            case Status.TellingNotStarted:
            case Status.TellingInProgress:
                return <div className={`${style.status} ${style.untouched}
                ${chiefTellerTasks.bypassFlowEnabled ? style.nextStepHidden : style.nextStepUntouched}`}>
                    <Circle />
                </div>
            case Status.TellingComplete:
                return <div className={`${style.status} ${style.expected}
                ${chiefTellerTasks.bypassFlowEnabled ? style.nextStepHidden : style.nextStepExpected}`}>
                    {chiefTellerTasks.bypassFlowEnabled ? <><Circle /></> : <><RoundedCircle /></>}
                </div>
            case Status.MergingInProgress:
                return <div className={`${style.status} ${style.expected}
                ${chiefTellerTasks.bypassFlowEnabled ? style.nextStepHidden : style.nextStepExpected}`}>
                    <RoundedCircle />
                </div>
            default:
                return <div className={`${style.status} ${style.done}
                ${chiefTellerTasks.bypassFlowEnabled ? style.nextStepHidden : style.nextStepDone}`}>
                    <CheckmarkInDoubleCircleFilled />
                </div>
        }
    }

    const getReportingItem = () => {
        switch (status) {
            case Status.MergingComplete:
                return <div className={`${style.status} ${style.expected} ${style.currentStepExpected}`}>
                    <RoundedCircle />
                </div>
            case Status.ReportingInProgress:
                return <div className={`${style.status} ${style.expected} ${style.currentStepExpected}`}>
                    <RoundedCircle />
                </div>
            case Status.ReportingComplete:
                return <div className={`${style.status} ${style.done} ${style.currentStepDone}`}>
                    <CheckmarkInDoubleCircleFilled />
                </div>
            default:
                return <div className={`${style.status} ${style.untouched} ${style.currentStepUntouched}`}>
                    <Circle />
                </div>
        }
    }

    return (
        <div className={style.ballotingProgress}>
            {getTellingItem()}
            {getMergingItem()}
            {getReportingItem()}
        </div>
    )
}

export default DelegateElectionBallotingProgress