import { ElectionStatusType } from 'models'
import { useTranslation } from 'react-i18next'

export const useElectionStatusMap = () => {
    const { t } = useTranslation()

    return {
        toString: (status: ElectionStatusType): string => {
            switch (status) {
                case ElectionStatusType.DRAFT:
                    return t('draft')
                case ElectionStatusType.COMPLETED:
                    return t('completed')
                case ElectionStatusType.NOT_STARTED:
                    return t('not_started')
                case ElectionStatusType.IN_PROGRESS:
                case ElectionStatusType.PENDING_APPROVAL:
                case ElectionStatusType.STOPED:
                    return t('in_progress')
                case ElectionStatusType.CONFIRMED:
                    return t('confirmed')
                case ElectionStatusType.CANCELED:
                    return t('canceled')
                case ElectionStatusType.NOT_CREATED:
                    return t('not_created')
            }
        }
    }
}