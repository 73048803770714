import { BallotsSummary, VoterSummaryReport } from 'models'
import { useTranslation } from 'react-i18next'
import ListItem from '../ListItem'
import StatisticList from '../StatisticList'

type InvalidBallotsStatisticProps = {
    electionVoterSammaryReport?: VoterSummaryReport,
    voterSummaryReport?: VoterSummaryReport,
    ballotSummary?: BallotsSummary,
    ballotsCounts?: BallotsSummary
}

function InvalidBallotsStatistic({
    voterSummaryReport,
    ballotSummary,
    electionVoterSammaryReport,
    ballotsCounts
}: InvalidBallotsStatisticProps) {

    const { t } = useTranslation('telling-station')

    return (
        <>
            <ListItem>
                <StatisticList
                    text={t('inner_ballot_envelopes_with_marks_that_identify_an_elector_or_are_missing')}
                    statistic={voterSummaryReport?.markedInnerEnvelopeCount ||
                        electionVoterSammaryReport?.markedInnerEnvelopeCount || 0}
                />
                <StatisticList
                    text={t('too_many_few_names_duplicate_names_separated_votes_or_marks')}
                    statistic={ballotSummary?.invalidBallotCount || ballotsCounts?.invalidBallotCount || 0}
                />
                <StatisticList
                    text={`${t('duplicate_envelopes_from_individuals_who_already_voted_online')} 
                    [${voterSummaryReport?.votedOnlinePersonEnvelopeCount ||
                        electionVoterSammaryReport?.votedOnlinePersonEnvelopeCount || 0}]`}
                />
                <StatisticList
                    text={`${t('outer_ballot_envelopes_from_ineligible_electors')} 
                    [${voterSummaryReport?.ineligiblePersonEnvelopeCount ||
                        electionVoterSammaryReport?.ineligiblePersonEnvelopeCount || 0}]`}
                />
            </ListItem>
        </>
    )
}

export default InvalidBallotsStatistic
