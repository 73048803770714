import PopupFooter from 'components/atoms/PopupFooter'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type FailedResetProps = {
    hide: () => void
    failedStations: string[]
}

function FailedReset({ hide, failedStations }: FailedResetProps) {
    const { t } = useTranslation('teller')

    return (
        <>
            <div className={style.label}>
                {t('could_not_perform_the_operation_for_the_following_station_s')}:
            </div>
            <ul className={style.stations}>
                {!!failedStations && failedStations.sort().map(station => <li className={style.station} key={station}>
                    {t('station_n', { n: station.replace('S', '') })}
                </li>)}
            </ul>
            <PopupFooter>
                <button
                    type="submit"
                    onClick={hide}
                    className={'btn-main-primary'}>
                    {t('ok')}
                </button>
            </PopupFooter >
        </>
    )
}

export default FailedReset