import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { VotesCounts } from 'models'

export const useElectionStationsVotesCounts = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getStationsVotesCounts = async () => {
        const res =
            await axios<VotesCounts>(`${moduleUrl}elections/${electionId}/votes/count`)

        return res.data
    }

    return useQuery<VotesCounts>({
        queryKey: ['election-stations-votes-counts', electionId],
        queryFn: getStationsVotesCounts,
        enabled
    })
}