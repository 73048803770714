import OfficersElectionsProvider from '../../provider/OfficersElectionsProvider'
import OfficersElections from '../OfficersElection'

function Elections() {

    return (
        <OfficersElectionsProvider>
            <OfficersElections />
        </OfficersElectionsProvider>
    )

}

export default Elections