import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionResult } from 'models'

type ResultsFilter = {
    limit?: number
}

export const useAlternativeCandidates = (
    electionId: string,
    enabled = false,
    filter: ResultsFilter = {}
) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getAlternativeCandidates =
        async ({ queryKey }: QueryFunctionContext): Promise<ElectionResult[]> => {
            const [, , filterParam] = queryKey
            const actualFilter = filterParam as ResultsFilter
            const queryParams = actualFilter.limit ? `number-of-positions=${actualFilter.limit}` : ''

            const url = `${moduleUrl}elections/${electionId}/election-results/candidates/alternative?${queryParams}`

            const { data } = await axios.get<ElectionResult[]>(url)

            return data
        }

    return useQuery<ElectionResult[], Error>({
        queryKey: ['election-results-alternative-candidates', electionId, filter],
        queryFn: getAlternativeCandidates,
        enabled: !!enabled
    })
}
