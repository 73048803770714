import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { VotesCounts } from 'models'

type StationTypes = {
    electionId: string,
    stationId: string
}
export const useStationVotesCounts = ({ electionId, stationId }: StationTypes, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getStationVotesCounts = async () => {
        const res =
            await axios<VotesCounts>(`${moduleUrl}elections/${electionId}/stations/${stationId}/votes/count`)

        return res.data
    }

    return useQuery<VotesCounts>({
        queryKey: ['station-votes-counts', electionId, stationId],
        queryFn: getStationVotesCounts,
        enabled
    })
}