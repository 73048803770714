import { $generateHtmlFromNodes } from '@lexical/html'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { FormInstance } from 'antd'
import { EditorThemeClasses, TextNode } from 'lexical'
import { RichTextEditorExtendedTextNode } from 'plugins'
import { useState } from 'react'
import RichTextEditorAutoLinkPlugin from '../RichTextEditorAutoLinkPlugin'
import RichTextEditorLinkEditorPlugin from '../RichTextEditorLinkEditorPlugin'
import RichTextEditorLoadInitialContent from '../RichTextEditorLoadInitialContent'
import RichTextEditorToolbar from './../RichTextEditorToolbar'
import style from './index.module.scss'

const theme: EditorThemeClasses = {
    text: {
        underline: 'rte-underline',
        strikethrough: 'rte-strikethrough',
        bold: 'rte-bold',
        italic: 'rte-italic',
        underlineStrikethrough: 'rte-underlineStrikethrough'
    },
    link: 'rte-link'
}

function onError(error: any) {
    throw new Error('unexpected error in rich text editor', { cause: error })
}

type RichTextEditorProps = {
    height?: number,
    width?: number,
    placeholder?: string
    defaultValue?: string
    form: FormInstance
    fieldName: string
    allowEmpty?: boolean
    errorMessage?: string
    initValue?: string
    onChange?: () => void
}

function RichTextEditor({
    height,
    width,
    placeholder,
    fieldName,
    form,
    initValue,
    onChange
}: RichTextEditorProps) {
    const initialConfig = {
        namespace: 'RichTextEditor',
        theme,
        onError,
        nodes: [AutoLinkNode, LinkNode,
            RichTextEditorExtendedTextNode,
            {
                replace: RichTextEditorExtendedTextNode,
                with: (node: TextNode) => new RichTextEditorExtendedTextNode(node.__text)
            }
        ]
    }

    const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null)
    const [isLinkEditMode, setIsLinkEditMode] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const [isValid, setIsValid] = useState(true)
    const [isInitailValueApplied, setIsInitailValueApplied] = useState(false)

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem)
        }
    }

    const onFocusLose = () => {
        setIsFocused(false)
    }

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <div
                className={`${style.container} ${isFocused ? style.focused : ''} ${isValid ? '' : style.invalid}`}
                style={{ width }}>
                <RichTextEditorLoadInitialContent initialContent={initValue}
                    onContentLoaded={() => setIsInitailValueApplied(true)} />
                <RichTextEditorToolbar setIsLinkEditMode={setIsLinkEditMode}
                    containerClassName={style.container}
                    onFocusLose={onFocusLose}
                    setIsValid={setIsValid}
                />
                <div>
                    <RichTextPlugin
                        contentEditable={
                            <div ref={onRef}>
                                <ContentEditable
                                    marginHeight={22}
                                    placeholder={placeholder}
                                    style={{ height }}
                                    onFocus={() => {
                                        if (isInitailValueApplied) {
                                            setIsFocused(true)
                                            setIsValid(true)
                                        }
                                    }}
                                    className={`${style.input} link-input`} />
                            </div>
                        }
                        placeholder={<div className={style.placeholder}>{placeholder}</div>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    {!!floatingAnchorElem && <RichTextEditorLinkEditorPlugin
                        anchorElem={floatingAnchorElem}
                        isLinkEditMode={isLinkEditMode}
                        setIsLinkEditMode={setIsLinkEditMode}
                    />
                    }
                    <HistoryPlugin />
                    <LinkPlugin />
                    <OnChangePlugin
                        onChange={(_, editor) => {
                            if (isInitailValueApplied) {
                                editor.update(
                                    () => {
                                        const value = $generateHtmlFromNodes(editor)
                                        if (value !== initValue) {
                                            onChange?.()
                                        }
                                        form.setFieldValue(fieldName, value)
                                    }
                                )
                            }
                        }}
                        ignoreHistoryMergeTagChange
                        ignoreSelectionChange />
                    <RichTextEditorAutoLinkPlugin />
                </div>
            </div>
        </LexicalComposer>
    )
}

export default RichTextEditor