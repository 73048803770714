import { Drawer } from 'antd'
import IconWrapper from 'components/atoms/IconWrapper'
import { useConfirmPopup, useSignOut } from 'hooks'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import { ReactComponent as LogoBig } from 'icons/logo-big.svg'
import { ReactComponent as Navigation } from 'icons/navigation.svg'
import { ReactComponent as Question } from 'icons/question.svg'
import { ReactComponent as SignOut } from 'icons/sign-out.svg'
import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import style from './index.module.scss'

interface HeaderProps {
    logo?: ReactNode
}

function Header({logo}: HeaderProps) {
    const { t } = useTranslation()
    const [opened, setOpened] = useState(false)
    const { confirmPortal, showConfirm } = useConfirmPopup()
    const divLogoRef = React.useRef<HTMLDivElement>(null)
    const { signOut } = useSignOut(showConfirm)

    useEffect(() => {
        divLogoRef.current?.focus()
    }, [])

    return (
        <div className={`${style.header} ${opened ? style.opened : ''}`}>
            {confirmPortal}
            <div
                className={style.logoWrapper}
                tabIndex={0}
                ref={divLogoRef}
                aria-label={t('online_balloting_system')}>
                {logo || <LogoBig />}
            </div>
            <div className={style.actions}>
                <section className={style.helpLinkWrapper}>
                    <Link
                        to="https://helpcenter.obsbahai.org/"
                        target="_blank"
                        className={`${style.helpLink}`}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault()
                            }
                        }}>
                        <IconWrapper><Question height="20" /></IconWrapper>
                        {t('help')}
                    </Link>
                </section>

                <section className={style.logoutWrapper}>
                    <span
                        tabIndex={0}
                        role="button"
                        onClick={() => signOut()}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault()
                                signOut()
                            }
                        }}
                        className={`${style.logoutLink}`}>
                        <IconWrapper><SignOut height="17" /></IconWrapper>
                        {t('log_out')}
                    </span>
                </section>
            </div>

            <div className={style.navigation} tabIndex={0} role="button">
                {opened
                    ? <Dismiss
                        aria-label={t('close_burger_menu')}
                        height="20" width="20" onClick={() => setOpened(false)} />
                    : <Navigation
                        aria-label={t('burger_menu')}
                        height="20" width="20" onClick={() => setOpened(true)} />
                }
            </div>

            <Drawer
                placement="top"
                rootClassName={style.mobileActions}
                height="100px"
                closable={false}
                onClose={() => setOpened(false)}
                open={opened}
                key="top"
            >
                <ul>
                    <Link to="https://helpcenter.obsbahai.org/" target="_blank">
                        <li>
                            <IconWrapper><Question height="20" width="20" /></IconWrapper>
                            <span>{t('help')}</span>
                        </li>
                    </Link>
                    <li role="button" onClick={() => signOut()}>
                        <IconWrapper><SignOut height="20" width="20" /></IconWrapper>
                        <span>{t('log_out')}</span>
                    </li>
                </ul>
            </Drawer>
        </div>
    )
}

export default Header