import { useOfficersElections, useOnlineVoting } from 'api'
import { useMemo } from 'react'
import { useVotingSessionStep } from '../../../../api'
import ElectionResultsBeforeConfirm from '../ElectionResultsBeforeConfirm'
import InputPhysicalVotes from '../InputPhysicalVotes'
import OnlineVotingStatus from '../OnlineVotingStatus'

const PHYSICAL_VOTES_CONFIRMED = 'physical_votes_confirmed'

function OfficersElectionVotingSession() {
    const { data: elections, isFetched } = useOfficersElections()

    const election = useMemo(() => {
        if (elections?.length) {
            return elections[0]
        }
    }, [elections])

    const { data: step } = useVotingSessionStep(election?.id.toString() || '', !!election)
    const { data: onlineVotingInfo } = useOnlineVoting(election?.id.toString() || '', !!election)

    return (
        <>
            {isFetched && !elections && 'no data'}
            {election
                && <>
                    {onlineVotingInfo?.closed === false
                        ? <OnlineVotingStatus election={election} />
                        : step === PHYSICAL_VOTES_CONFIRMED
                            ? <ElectionResultsBeforeConfirm election={election} />
                            : <InputPhysicalVotes election={election} />
                    }
                </>
            }
        </>

    )
}

export default OfficersElectionVotingSession