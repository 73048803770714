
import PopupTitle from 'components/atoms/PopupTitle'
import { PopupProps } from 'components/molecules/Popup'
import { useTranslation } from 'react-i18next'
import StopOnlineVoting from '../components/StopOnlineVoting'
import { useSelectElectionFlow } from './useSelectElectionFlow'

export const useStopOnlineVoting = (
    showPopup: (props: PopupProps) => void,
    hidePopup: () => void,
    electionId: string,
    onVotingStop?: () => Promise<void>
) => {
    const { t } = useTranslation('teller')
    const { selectElectionFlow } =
        useSelectElectionFlow(showPopup, hidePopup, electionId)

    const initStopOnlineVoting = (mustSelectFlow: boolean, autoSelectFlow: boolean) => {
        showPopup({
            width: '432px',
            title: <PopupTitle>{t('stop_online_voting')}?</PopupTitle>,
            children:
                <StopOnlineVoting
                    mustSelectFlow={mustSelectFlow}
                    autoSelectFlow={autoSelectFlow}
                    onClose={() => hidePopup()}
                    selectElectionFlow={selectElectionFlow}
                    electionId={electionId}
                    hidePopup={hidePopup}
                    showPopup={showPopup}
                    onVotingStop={onVotingStop} />,
            footer: null
        })
    }

    return {
        stopOnlineVoting: initStopOnlineVoting
    }
}
