import { Radio } from 'antd'
import { useLocality } from 'api'
import PopupRadioButton from 'components/atoms/PopupRadioButton'
import { useAuth, useElectionTypeMap } from 'hooks'
import { ReactComponent as DocumentAddFilled } from 'icons/document-add-filled.svg'
import { ReactComponent as DocumentAdd } from 'icons/document-add.svg'
import { AvailableElection, ElectionSubType, ElectionType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { byElection } from '../../features/byElection'
import { nationalDelegate } from '../../features/national'
import { usePlanRidvanElectionPeriod } from '../../features/ridvan/api'
import style from './index.module.scss'

type SelectElectionToCreateProps = {
    onSelect?: () => void,
    availableElections: AvailableElection[]
}

function SelectElectionToCreate({ onSelect, availableElections }: SelectElectionToCreateProps) {
    const navigate = useNavigate()
    const { t } = useTranslation('common')
    const { toString } = useElectionTypeMap()
    const [selected, setSelected] = useState<ElectionType>()
    const [availableElectionTypes, setAvailableElectionTypes] = useState<ElectionType[]>([])
    const { mutate: planRidvanElectionPeriod } = usePlanRidvanElectionPeriod()
    const { mutateAsync: planByElectionPeriod } = byElection.api.mutations.usePlanByElectionPeriod()
    const { mutateAsync: planNationalDelegateElectionPeriod } =
        nationalDelegate.api.mutations.usePlanNationalDelegateElectionPeriod()
    const { auth } = useAuth()
    const { data: localityInfo } = useLocality(auth?.homeLocality.code || '', !!auth)

    useEffect(() => {
        if (!localityInfo) return

        setAvailableElectionTypes(() => ([]))
        availableElections.forEach(election => {
            switch (election.electionType) {
                case ElectionType.RIDVAN_ELECTION:
                    if (election.electionSubType === ElectionSubType.SINGLE_STAGE) {
                        setAvailableElectionTypes((current) => ([...current, ElectionType.RIDVAN_ELECTION]))
                    }
                    if (election.electionSubType === ElectionSubType.TWO_STAGE) {
                        setAvailableElectionTypes((current) => ([...current, ElectionType.LOCAL_DELEGATE_ELECTION]))
                    }
                    break
                case ElectionType.BY_ELECTION:
                    setAvailableElectionTypes((current) => ([...current, ElectionType.BY_ELECTION]))
                    break
                case ElectionType.NATIONAL_DELEGATE_ELECTION:
                    setAvailableElectionTypes((current) => ([...current, ElectionType.NATIONAL_DELEGATE_ELECTION]))
                    break
            }
        })
    }, [localityInfo, availableElections])

    useEffect(() => {
        if (availableElectionTypes.length > 0) {
            setSelected(availableElectionTypes[0])
        }
    }, [availableElectionTypes])

    const proceed = async (type?: ElectionType) => {
        if (!type)
            return
        switch (type) {
            case ElectionType.LOCAL_DELEGATE_ELECTION:
            case ElectionType.RIDVAN_ELECTION:
                planRidvanElectionPeriod({}, {
                    onSuccess: data => {
                        navigate(`/ridvan/${data}`)
                        onSelect?.()
                    }
                })
                break
            case ElectionType.BY_ELECTION:
                const periodId = await planByElectionPeriod()
                navigate(`/by/${periodId}`)
                onSelect?.()
                break
            case ElectionType.NATIONAL_DELEGATE_ELECTION:
                const id = await planNationalDelegateElectionPeriod()
                navigate(`/national-delegate/${id}`)
                onSelect?.()
                break
        }
    }

    return (
        <>
            <Radio.Group
                className={style.electionTypes}
                onChange={(e) => setSelected(e.target.value)}
                value={selected}
            >
                {
                    availableElectionTypes.map(election =>
                        <PopupRadioButton
                            key={election}
                            labelTitle={t(toString(election))}
                            value={election}
                            buttonIcon={() => selected === election ? <DocumentAddFilled /> : <DocumentAdd />}
                            centerAlign={true}
                        />)
                }
            </Radio.Group>
            <button className={`${style.proceed} btn-main-primary`} onClick={async () => await proceed(selected)}>
                <span>{t('proceed')}</span>
            </button>
        </>
    )
}

export default SelectElectionToCreate