import { useElection, useElectionSummary, useElectionTellingStations } from 'api'
import ContentWrapper from 'components/atoms/ContentWrapper'
import { useAuth } from 'hooks'
import { ElectionStation } from 'models'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BallotingStatistic from '../components/BallotingStatistic'
import PageHeaderButtons from '../components/PageHeaderButtons'

function MergeResults() {
    const { auth } = useAuth()
    const navigate = useNavigate()

    const { data: election, isError } = useElection(auth!.electionId!, !!auth?.electionId)
    const { data: stations } = useElectionTellingStations(auth!.electionId!, !!auth?.electionId)
    const { data: summary, isLoading: isSummaryLoading } = useElectionSummary(auth!.electionId!, !!auth!.electionId!)

    const [canMergeResults, setCanMergeResults] = useState(false)

    useEffect(() => {
        if (isError) {
            navigate('/page-not-found', { replace: true })

            return
        }
        if (election?.bypassFlow === false) {
            if (stations?.length === 0) {
                navigate('/page-not-found', { replace: true })

                return
            }
            if (stations?.some((station: ElectionStation) => !station.stationReportAccepted || !station.closed)) {
                navigate('/page-not-found', { replace: true })

                return
            }
        }
        if (!canMergeResults && !isSummaryLoading && !!summary?.mergedBy) {
            navigate('/page-not-found', { replace: true })
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isError, election, stations, summary, isSummaryLoading, canMergeResults])

    useEffect(() => {
        if (!canMergeResults && election && !isSummaryLoading && !summary?.mergedBy) {
            setCanMergeResults(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, election, isSummaryLoading, summary, canMergeResults])

    return (
        <>
            <ContentWrapper>
                <PageHeaderButtons election={election} />
                <BallotingStatistic stations={stations} election={election} />
            </ContentWrapper>
        </>
    )
}

export default MergeResults