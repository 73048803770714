import { Radio } from 'antd'
import { useOfficersElectionCandidates } from 'api'
import Note from 'components/molecules/Note'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { useAuth } from 'hooks'
import { VotingApproach } from 'models'
import React, { useContext, useEffect, useRef } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCastBallot } from '../../api/mutations/castBallot'
import { BallotingElection } from '../../models/balloting-election'
import { BallotingContext, Steps } from '../BallotingStepsManagement'
import style from './index.module.scss'

type OfficersElectionProps = {
    election?: BallotingElection
    setShouldFocusHeader: React.Dispatch<React.SetStateAction<boolean>>
}

export function OfficersElection({ election, setShouldFocusHeader }: OfficersElectionProps) {
    const { t } = useTranslation('voter')
    const { auth } = useAuth()
    const [selectedCandidateId, setSelectedCandidateId] = useState<string | null>(null)
    const [submitAttempted, setSubmitAttempted] = useState(false)
    const errorNoteRef = useRef<HTMLDivElement>(null)
    const { setCurrentStep } = useContext(BallotingContext)

    const { data: officersElectionCandidates } = useOfficersElectionCandidates(auth?.homeLocality?.code || '',
        !!auth?.homeLocality)
    const { mutateAsync: castBallotMutation } = useCastBallot()

    const handleSubmit = async () => {
        if (!selectedCandidateId) {
            setSubmitAttempted(true)
        } else {
            await castBallotMutation({
                candidates: [selectedCandidateId],
                electionId: Number(election?.id) || 0,
                votingApproach: VotingApproach.IN_ABSENCE
            })
            setSubmitAttempted(false)
            setCurrentStep(Steps.ThankYou)
            setShouldFocusHeader(true)
        }
    }

    useEffect(() => {
        if (submitAttempted && !selectedCandidateId && errorNoteRef.current) {
            errorNoteRef.current.focus()
        }
    }, [submitAttempted, selectedCandidateId])

    const sortedCandidates = officersElectionCandidates?.sort((a, b) => a.name.localeCompare(b.name))

    return (
        <div className={style.contentBlock}>
            <div tabIndex={0} className={style.infoBlockWrapper}>
                <Note icon={'regular'} mode={'info'}>
                    {t('please_vote_for_one_individual_then_click_submit_my_vote')}
                </Note>
                {submitAttempted && !selectedCandidateId && (
                    <div ref={errorNoteRef} tabIndex={-1}>
                        <Note icon={'filled'} mode={'error'}>
                            {t('please_select_an_individual')}
                        </Note>
                    </div>
                )}
            </div>
            <div className={style.fieldList}>
                <div className={style.fieldWrapper}>
                    <Radio.Group
                        className={style.radioWrapper}
                        onChange={e => {
                            setSelectedCandidateId(e.target.value)
                            setSubmitAttempted(false)
                        }}
                        value={selectedCandidateId}>
                        {sortedCandidates?.map(candidate => (
                            <Radio
                                className={`typo-body1 ${style.radio}`}
                                key={candidate.id}
                                value={candidate.id}>
                                <OptionalTooltip contenWrapperClassName="ellipsis">
                                    {candidate.name}
                                </OptionalTooltip>

                            </Radio>
                        ))}
                    </Radio.Group>
                </div>
            </div>
            <div className={style.buttonWrapper}>
                <button
                    className={`btn-main-primary hasIcon ${style.submitButton}`}
                    onClick={handleSubmit}>
                    <span>{t('submit_my_vote')}</span>
                </button>
            </div>
        </div>
    )
}

export default OfficersElection
