import Collapse from 'components/atoms/Collapse'
import { ElectionType, ReportFormat, ReportType } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReportGroup } from '../../model'
import { ReportFilter } from '../../model/report-filter'
import ReportGroupItems from '../ReportGroupItems'
import style from './index.module.scss'

function Reports() {
    const { t } = useTranslation('administration')

    const reports: ReportGroup[] = useMemo((): ReportGroup[] => [
        {
            label: t('lsa_elections'),
            key: 'lsa_elections',
            items: [{
                type: ReportType.RidvanConsolidatedReport,
                format: ReportFormat.XLSX,
                label: t('election_results'), 
                key: 'ridvan',
                filters: [],
                electionType: ElectionType.RIDVAN_ELECTION
            },
            {
                type: ReportType.LocalDelegateElections,
                format: ReportFormat.XLSX,
                label: t('voting_statistics'),
                key: 'lde',
                filters: []
            },
            {
                type: ReportType.RejectedUnityWebPushReasonReport,
                format: ReportFormat.XLSX,
                label: t('election:rejected_unity_web_push_reason'),
                key: 'ruwpr',
                filters: [ReportFilter.ElectionType]
            }
            ]
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])

    return (
        <div className={style.reportsWrapper}>
            <Collapse
                className="obs-collapse"
                items={reports.map(({ key, label, items }) => ({
                    key,
                    label,
                    children: <ReportGroupItems items={items} />
                }))}
                defaultActiveKey={['lsa_elections']} />
        </div>
    )
}

export default Reports