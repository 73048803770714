import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export function usePlanNationalDelegateElectionPeriod() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function planNationalDelegateElectionPeriod() {
        const res = await axios<{ electionPeriod: number }>(moduleUrl + 'national-delegate-election-periods/plan',
            {
                method: 'post'
            })

        return res.data.electionPeriod
    }

    return useMutation({
        mutationFn: planNationalDelegateElectionPeriod
    })
}