import { Checkbox as AntCheckbox } from 'antd'

type CheckboxProps = React.ComponentProps<typeof AntCheckbox>
export function Checkbox(props: CheckboxProps) {
    return (
        <AntCheckbox {...props} className={props.className || ''}>
            {props.children}
        </AntCheckbox>
    )
}

export default Checkbox
