export const useCopyToClipboard = () => {

    const copy = (text: string, cb?: (text: string) => any) => {
        navigator.clipboard.writeText(text)
        if (cb) {
            cb(text)
        }
    }

    return {
        copy
    }

}