import { useElection } from 'api'
import { Checkbox } from 'components/atoms/Checkbox'
import Input from 'components/atoms/Input'
import { useAuth } from 'hooks'
import { ElectionType } from 'models'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type TieRevoteUsingHeaderProps = {
    tiePositions: number | undefined
    onCheckboxChange: (isChecked: boolean) => void
}

function TieRevoteUsingHeader({ tiePositions, onCheckboxChange }: TieRevoteUsingHeaderProps) {
    const { t } = useTranslation()
    const { auth } = useAuth()
    const { data: election } = useElection(auth?.electionId!, !!auth?.electionId)
    const [isChecked, setIsChecked]
        = useState(election?.type !== ElectionType.NATIONAL_DELEGATE_ELECTION ? true : false)
    const toggleCheckbox = () => {
        const newChecked = !isChecked
        setIsChecked(newChecked)
        onCheckboxChange(newChecked)
    }

    return (
        <div className={style.contentWrapper}>
            <div>{t('election:number_of_vacancies')}</div>
            <div className={style.inputWrapper}>
                <div>
                    <Input
                        value={tiePositions}
                        className="small"
                        disabled />
                </div>
                {
                    election?.type !== ElectionType.NATIONAL_DELEGATE_ELECTION
                    &&
                    <div>
                        <Checkbox
                            checked={isChecked}
                            onChange={toggleCheckbox}>
                            <span className={isChecked ? `${style.checkboxWrapper} ${style.checkedTextColor}` :
                                style.checkboxWrapper}>{t('teller:send_invitation_for_online_voting')}
                            </span>
                        </Checkbox>
                    </div>
                }
            </div>
            <div className={style.textWrapper}>{t('teller:votes_may_be_cast_for_the_individuals_below')}:</div>
        </div>
    )
}

export default TieRevoteUsingHeader