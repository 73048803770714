import EditBallot from '../components/EditBallot'
import BallotVotesProvider from '../providers/BallotVotesProvider'

function Ballot() {
    return (
        <BallotVotesProvider>
            <EditBallot />
        </BallotVotesProvider>
    )
}

export default Ballot