import { ElectionType } from 'models'
import {
    ByElectionConfig,
    ChangeRestrictionData,
    ElectionRestriction,
    LocalDelegateConfig,
    NationalDelegateModel,
    OfficersElectionConfig,
    RidvanConfig
} from '../model'

export function getElectionRestriction(data: ChangeRestrictionData): ElectionRestriction {
    switch (data.electionType) {
        case ElectionType.RIDVAN_ELECTION:
            return data.data as RidvanConfig
        case ElectionType.LOCAL_DELEGATE_ELECTION:
            return data.data as LocalDelegateConfig
        case ElectionType.BY_ELECTION:
            return data.data as ByElectionConfig
        case ElectionType.OFFICERS_ELECTION:
            return data.data as OfficersElectionConfig
        case ElectionType.NATIONAL_DELEGATE_ELECTION:
            return data.data as NationalDelegateModel
        default:
            throw new Error(`Unknown election type: ${data.electionType}`)
    }
}