import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfigProvider } from 'antd'
import Empty from 'components/molecules/Empty'
import Preloader from 'components/molecules/Preloader'
import 'config/i18n'
import { queryClient } from 'config/query-client'
import AbilityProvider from 'providers/AbilityProvider'
import AccessTokenProvider from 'providers/AccessTokenProvider'
import AuthProvider from 'providers/AuthProvider'
import BackToDashboardLineProvider from 'providers/BackToDashboardLineProvider'
import BrowserZoomRatioProvider from 'providers/BrowserZoomRatioProvider'
import HeaderLogoRedirectProvider from 'providers/HeaderLogoRedirectProvider'
import PageHeaderProvider from 'providers/PageHeaderProvider'
import RefreshTokenInfoProvider from 'providers/RefreshTokenInfoProvider'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.scss'
import ColorThemeProvider from './providers/ColorThemeProvider'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <React.Suspense fallback={
                <Preloader />}>
                <ConfigProvider renderEmpty={() => (<Empty />)}>
                    <AbilityProvider>
                        <AccessTokenProvider>
                            <AuthProvider>
                                <RefreshTokenInfoProvider>
                                    <HeaderLogoRedirectProvider>
                                        <PageHeaderProvider>
                                            <BackToDashboardLineProvider>
                                                <ColorThemeProvider>
                                                    <BrowserZoomRatioProvider>
                                                        <App />
                                                    </BrowserZoomRatioProvider>
                                                    <ReactQueryDevtools initialIsOpen={false} />
                                                </ColorThemeProvider>
                                            </BackToDashboardLineProvider>
                                        </PageHeaderProvider>
                                    </HeaderLogoRedirectProvider>
                                </RefreshTokenInfoProvider>
                            </AuthProvider>
                        </AccessTokenProvider>
                    </AbilityProvider>
                </ConfigProvider>
            </React.Suspense>
        </QueryClientProvider>
    </React.StrictMode>
)