import { Checkbox } from 'components/atoms/Checkbox'
import PopupFooter from 'components/atoms/PopupFooter'
import { Select } from 'components/atoms/Select'
import { electionProcessing } from 'features/electionProcessing'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStartTrainingSession } from '../../api'
import style from './index.module.scss'

const createOptions = (numbers: Number[]) => numbers.map(number => ({
    label: number.toString(),
    value: number
}))

const numbersOfEnvelopesOptions = createOptions([3, 6, 9, 12, 15, 18])
const numbersOfVacanciesOptions = createOptions([1, 2, 3, 5, 7, 9])

const INCLUDE_VOTER_VERIFICATION_TIPS_KEY = 'includeVoterVerificationTips'
const INCLUDE_ENTER_BALLOTS_TIPS_KEY = 'includeEnterBallotsTips'
const INCLUDE_TIPS_AFTER_FIRST_BALLOT_KEY = 'includeTipsAfterFirstBallot'
const INCLUDE_REPORTING_TIPS_KEY = 'includeReportingTips'
const SHOW_PDF_DOWNLOAD_SUGGESTION_KEY = 'showPdfDownloadSuggestion'

type TrainingSessionConfigProps = {
    hide: () => void
}

function TrainingSessionConfig({ hide }: TrainingSessionConfigProps) {
    const { t } = useTranslation('telling-station-sandbox')
    const [includeTrainingTips, setIncludeTrainingTips] = useState(true)
    const [numberOfEnvelopes, setNumbersOfEnvelopes] = useState(6)
    const [numberOfVacancies, setNumbersOfVacancies] = useState(3)

    const { mutateAsync: startTrainingSession } = useStartTrainingSession()
    const { launchStation } = electionProcessing.stationLauncher.hooks.useStationLauncher()

    const startTrainingProcess = async () => {
        const res = await startTrainingSession({ envelopes: numberOfEnvelopes, vacancies: numberOfVacancies })
        const electionId = res.data.electionId
        await launchStation({
            electionId: electionId?.toString() || '',
            stationId: 'S1'
        })
        if (includeTrainingTips) {
            sessionStorage.setItem(INCLUDE_VOTER_VERIFICATION_TIPS_KEY, 'true')
            sessionStorage.setItem(INCLUDE_ENTER_BALLOTS_TIPS_KEY, 'true')
            sessionStorage.setItem(INCLUDE_TIPS_AFTER_FIRST_BALLOT_KEY, 'true')
            sessionStorage.setItem(INCLUDE_REPORTING_TIPS_KEY, 'true')
        } else {
            sessionStorage.removeItem(INCLUDE_VOTER_VERIFICATION_TIPS_KEY)
            sessionStorage.removeItem(INCLUDE_ENTER_BALLOTS_TIPS_KEY)
            sessionStorage.removeItem(INCLUDE_TIPS_AFTER_FIRST_BALLOT_KEY)
            sessionStorage.removeItem(INCLUDE_REPORTING_TIPS_KEY)
        }
        sessionStorage.setItem(SHOW_PDF_DOWNLOAD_SUGGESTION_KEY, 'true')
    }

    return (
        <>
            <div className={style.formItem}>
                <div className={style.formItemTitles}>
                    <div className={style.formItemTitle}>{t('select_the_number_of_virtual_ballots')}</div>
                </div>
                <div className={style.input}>
                    <Select
                        className={style.input}
                        value={numberOfEnvelopes}
                        options={numbersOfEnvelopesOptions}
                        onChange={(e) => setNumbersOfEnvelopes(e as any)} />
                </div>
            </div>

            <div className={style.formItem}>
                <div className={style.formItemTitles}>
                    <div className={style.formItemTitle}>{t('select_the_number_of_vacancies_in_a_ballot')}</div>
                    <div className={style.formItemSubtitle}>
                        {t('it_will_impact_how_many_names_per_ballot')}
                    </div>
                </div>
                <div className={style.input}>
                    <Select
                        className={style.input}
                        value={numberOfVacancies}
                        options={numbersOfVacanciesOptions}
                        onChange={(e) => setNumbersOfVacancies(e as any)} />
                </div>
            </div>
            <div className={style.formItem}>
                <div className={style.formItemWideTitles}>
                    <Checkbox
                        checked={includeTrainingTips}
                        onChange={(e) => setIncludeTrainingTips(e.target.checked)}>
                        <div className={style.checkboxText}>
                            {t('show_training_tips')}
                        </div>
                    </Checkbox>
                    <div className={style.formItemWideSubtitle}>
                        {t('your_sample_ballots_and_envelopes_can_be_found_in_instructions')}
                    </div>
                </div>
            </div>
            <PopupFooter>
                <button className={'btn-main-secondary'}
                    onClick={hide}>
                    {t('cancel')}
                </button>
                <button className={'btn-main-primary'} onClick={startTrainingProcess}>
                    {t('start')}
                </button>
            </PopupFooter>
        </>
    )
}

export default TrainingSessionConfig
