import { Empty as AntEmpty } from 'antd'
import { ReactComponent as Vote } from 'icons/vote.svg'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type EmptyProps = {
    text?: ReactNode,
    height?: string | number
}

function Empty({ text, height }: EmptyProps) {
    const { t } = useTranslation()

    return (
        <AntEmpty
            rootClassName={style.empty}
            style={{ height: height || 200 }}
            imageStyle={{ height: 'auto' }}
            image={<div className={style.iconWrapper}><Vote height={32} width={32} className={style.icon} /></div>}
            description={<div className={style.description}>
                {text || t('no_items_found_for_the_current_search_criteria')}
            </div>}
        />
    )
}

export default Empty