import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export function useRevokeVoterVerification() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    async function revokeVoterVerification(voterId: string) {
        return await axios(moduleUrl
            + 'stations/my/revoke-voter-verification',
            {
                method: 'post',
                data: {
                    id: voterId
                }
            })
    }

    return useMutation({
        mutationFn: revokeVoterVerification
    })
}