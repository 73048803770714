import Card from 'components/atoms/Card'
import CardHeader from 'components/molecules/CardHeader'
import { useTranslation } from 'react-i18next'
import { useOfficers } from '../../hooks'
import LsaOfficer from '../LsaOfficer'
import style from './index.module.scss'

function CurrentLsaMembership() {
    const { t } = useTranslation('election')

    const { officers } = useOfficers()

    return (
        <Card title={<CardHeader title={t('current_lsa_membership')} />} >
            <div className={style.note}>
                {t('listing_below_is_the_current_lsa_membership')}
            </div>
            <>
                {
                    officers.map((officer, index) => (
                        <LsaOfficer key={officer.id} officer={officer} index={index + 1} />
                    ))
                }
            </>
        </Card>
    )
}

export default CurrentLsaMembership