import { $patchStyleText } from '@lexical/selection'
import { Select as AntSelect } from 'antd'
import { Select } from 'components/atoms/Select'
import { $getSelection, LexicalEditor } from 'lexical'
import { FontStyle } from 'models'
import { useCallback, useEffect, useState } from 'react'
import { fontStyleRules } from './font-style-rules'
import style from './index.module.scss'

type RichTextEditorFontStylesProps = {
    editor: LexicalEditor,
    fontStyle: FontStyle,
    onChange?: (value: FontStyle) => void
}

function RichTextEditorFontStyles({ editor, fontStyle: propFontStyle, onChange }: RichTextEditorFontStylesProps) {
    const [fontStyle, setFontStyle] = useState<FontStyle>(FontStyle.Title2)

    useEffect(() => {
        if (propFontStyle === undefined) {
            return
        }
        setFontStyle(propFontStyle)
    }, [propFontStyle])

    const updateFontStyle = useCallback(
        (newFontSize: FontStyle) => {
            editor.update(() => {
                if (editor.isEditable()) {
                    const selection = $getSelection()
                    if (selection !== null) {
                        const rules = fontStyleRules(newFontSize)
                        $patchStyleText(selection, {
                            'font-size': rules.fontSize,
                            'line-height': rules.lineHeight
                        })
                        onChange?.(newFontSize)
                    }
                }
            })
        },
        [editor]
    )

    useEffect(() => {
        updateFontStyle(fontStyle)
    }, [fontStyle, updateFontStyle])

    return (
        <Select
            value={fontStyle}
            onChange={(value) => {
                setFontStyle(value as FontStyle)
            }}
            popupMatchSelectWidth={160}
            disabled={!editor.isEditable()}
            className={`small ${style.select}`}>
            {(Object.keys(FontStyle) as Array<keyof typeof FontStyle>).map(fs => <AntSelect.Option key={fs} value={fs} >
                {fs}
            </AntSelect.Option>)}

        </Select>
    )
}

export default RichTextEditorFontStyles