import ContentWrapper from 'components/atoms/ContentWrapper'
import React from 'react'
import { iconsList,bigIconsList } from './iconsList'
import style from './index.module.scss'

function Icons() {
    return (
        <ContentWrapper>
            <div className={style.icons}>
                {iconsList.map((icon: React.ReactNode, index) => (
                    <div key={index} className={style.icon}>{icon}</div>
                ))}
            </div>

            <div className={style.icons}>
                {bigIconsList.map((icon: React.ReactNode, index) => (
                    <div key={index} className={style.bigIcon}>{icon}</div>
                ))}
            </div>
        </ContentWrapper>
    )
}

export default Icons