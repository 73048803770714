import { ChildrenProps } from 'models'
import style from './content-wrapper.module.scss'

interface IContentWrapperProps extends ChildrenProps {
    noSpaced?: boolean
}

function ContentWrapper({ noSpaced, children }: IContentWrapperProps) {
    const noSpacedClass = noSpaced ? style.noSpaced : ''

    return (
        <div className={`${style.contentWrapper} ${noSpacedClass}`}>{children}</div>
    )
}

export default ContentWrapper