import { ReactNode } from 'react'
import Spinner from '../Spinner'
import style from './index.module.scss'

type PreloaderProps = {
    title?: ReactNode
}

const Preloader = ({ title }: PreloaderProps) => (

    <div className={style.spinWrapper}>
        <Spinner />
        {title && <div className={style.title}>{title}</div>}
    </div>
)

export default Preloader