import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { VoterSummaryReport } from 'models'

export const useElectionVoterSammaryReport = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getElectionVoterSammaryReport = async ({ queryKey }: QueryFunctionContext)
        : Promise<any> => {
        const [, electionId] = queryKey

        const res = await axios<VoterSummaryReport>(`
        ${moduleUrl}elections/${electionId}/voter-summary-report`)

        return res.data
    }

    return useQuery<VoterSummaryReport>({
        queryKey: ['election-voter-summary-report', electionId],
        queryFn: getElectionVoterSammaryReport,
        enabled
    })
}