import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { LsaOfficer, LsaOfficerRole } from 'models'

export const useLocalityOfficers = (localityId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getLocalityOfficers = async () => {
        const result = await axios<any[]>(
            `${moduleUrl}institutions/localities/${localityId}/officers`)

        const lsaOfficers: LsaOfficer[] = []
        result.data.forEach(d => lsaOfficers.push({
            ...d, role: d.role.split(',')
                .filter((r: LsaOfficerRole) => r !== LsaOfficerRole.MEMBER)
        }))

        return lsaOfficers
    }

    return useQuery({
        queryKey: ['locality-officers', localityId],
        queryFn: () => getLocalityOfficers(),
        enabled
    })
}
