import { ReactComponent as Bubbles } from 'icons/bubbles.svg'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type BallotIdReminderProps = {
    ballotId: string,
    onGotItClick: () => void
}

function BallotIdReminder({ ballotId, onGotItClick }: BallotIdReminderProps) {
    const { t } = useTranslation('ballot')

    return (
        <div className={style.reminder}>
            <div className={style.title}>
                {t('ballot_id')}: {ballotId}
            </div>
            <div className={style.subtitle}>
                {t('important_reminder')}
            </div>
            <div className={style.text}>
                {t('please_write_the_unique_ballot_id_generated_by_the_system')}
            </div>
            <button
                className={`btn-main-primary ${style.confirm}`}
                onClick={onGotItClick}>
                {t('common:got_it')}</button>
            <Bubbles height={44} width={90} className={style.bubbles} />
        </div>
    )
}

export default BallotIdReminder