import { useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionResultSummary } from 'models'

type ElectionsSummaryParams = {
    electionIds: string[]
}

export const useElectionsSummary = ({ electionIds }: ElectionsSummaryParams, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectionsSummary = async () => {

        const result = await axios<{ election: number, electionResults: ElectionResultSummary }[]>(
            `${moduleUrl}elections/election-results/summary?elections=${electionIds.join(',')}`)

        return result.data
    }

    return useQuery({
        queryKey: ['elections-summary', electionIds],
        queryFn: getElectionsSummary,
        enabled
    })
}