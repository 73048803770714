import axios from 'axios'
import { isSandbox } from 'utils'

export const BASE_URL = process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080/'
    : '/api/'

export const TELLING_STATION_URL = process.env.NODE_ENV === 'development'
    ? 'http://localhost:8082/'
    : '/ts-api/'

export const ONLINE_BALLOT_URL = process.env.NODE_ENV === 'development'
    ? (isSandbox ? 'http://localhost:8080/' : 'http://localhost:8083/')
    : '/ob-api/'

export const REPORT_URL = process.env.NODE_ENV === 'development'
    ? (isSandbox ? 'http://localhost:8080/' : 'http://localhost:8084/')
    : '/rp-api/'
export const TOOLKIT_URL = process.env.NODE_ENV === 'development'
    ? 'http://localhost:8086/'
    : '/tk-api/'

export const NOTIFICATION_URL = process.env.NODE_ENV === 'development'
    ? 'http://localhost:8085/'
    : '/nt-api/'

export const BOSA_URL = process.env.NODE_ENV === 'development'
    ? 'https://localhost:8079/'
    : (window as any).__RUNTIME_CONFIG__?.BOSA_LOGIN_URI + '/'

export const obsApi = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    }
})

export const setTokenToApi = (token: string) => {
    if (token) {
        obsApi.defaults.headers.authorization = `Bearer ${token}`
    }
}