import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'

export const useCandidatesOnlineVotes = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.OnlineBallot)

    const getCandidatesOnlineVotes = async ({ queryKey }: QueryFunctionContext) => {
        const [, electionId] = queryKey

        const result = await axios<{ candidate: string, voteCount: number }[]>(
            `${moduleUrl}elections/${electionId}/votes`)

        return result.data
    }

    return useQuery({
        queryKey: ['candidates-online-votes', electionId],
        queryFn: getCandidatesOnlineVotes,
        enabled
    })
}