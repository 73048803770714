import { Form, FormInstance } from 'antd'
import { useAppointedTellers } from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import BottomFixedPanel from 'components/molecules/BottomFixedPanel'
import { LookupOption } from 'components/molecules/LookupSelect'
import { useInfoPopup, useScrollToBottom } from 'hooks'
import { ReactComponent as Plus } from 'icons/plus.svg'
import { ElectionBase, ElectionParticipant, TellerType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { applyPhoneMask } from 'utils'
import { useManageTellersActions } from '../../hooks'
import { SelectedTeller } from '../../models'
import TellerSelectionForm from '../TellerSelectionForm'

import style from './index.module.scss'

type ManageTellersFormProps = {
    form: FormInstance,
    election: ElectionBase,
    canSaveWithoutMandatoryTellers?: boolean
}

function ManageElectionTellersForm({
    form, election, canSaveWithoutMandatoryTellers }: ManageTellersFormProps) {
    const { t } = useTranslation()
    const [canAddTeller, setCanAddTeller] = useState(false)
    const [selectedTellers, setSelectedTellers] = useState<LookupOption[]>([])
    const [initialTellers, setInitialTellers] = useState<SelectedTeller[]>([])
    const { formContainsUnselectedTeller } = useManageTellersActions()
    const { infoPortal, showInfo } = useInfoPopup()
    const { data: appointedTellers, isFetching } = useAppointedTellers(election?.id.toString())
    const { bottomRef, setShouldScroll } = useScrollToBottom(selectedTellers)

    useEffect(() => {
        if (appointedTellers && !isFetching) {
            let mandatoryCtAssistantMapped = false
            const appointed: any[] = []
            appointedTellers.forEach((appointedTeller) => {

                let isMandatory = false
                if (appointedTeller.role === TellerType.CHIEF_TELLER_ASSISTANT && !mandatoryCtAssistantMapped) {
                    isMandatory = true
                    mandatoryCtAssistantMapped = true
                }

                if (appointedTeller.role === TellerType.CHIEF_TELLER) {
                    appointed.unshift({
                        ...appointedTeller,
                        phone: appointedTeller.phone ? applyPhoneMask(appointedTeller.phone) : '',
                        mandatory: true
                    })
                } else {
                    appointed.push({
                        ...appointedTeller,
                        phone: appointedTeller.phone ? applyPhoneMask(appointedTeller.phone) : '',
                        mandatory: isMandatory
                    })
                }
            })

            if (appointed.length) {
                const chief = appointed.find(t => t.role === TellerType.CHIEF_TELLER)
                if (!chief) {
                    appointed.unshift({ id: '', name: '', role: TellerType.CHIEF_TELLER, mandatory: true })
                }
                const assistant = appointed.find(t => t.role === TellerType.CHIEF_TELLER_ASSISTANT)
                if (!assistant) {
                    appointed.splice(1, 0,
                        { id: '', name: '', role: TellerType.CHIEF_TELLER_ASSISTANT, mandatory: true })
                }
                setInitialTellers(appointed)
            } else {
                return setInitialTellers([
                    { id: '', name: '', role: TellerType.CHIEF_TELLER, mandatory: true },
                    { id: '', name: '', role: TellerType.CHIEF_TELLER_ASSISTANT, mandatory: true }
                ])
            }
        } else {
            setInitialTellers([])
        }
    }, [appointedTellers, isFetching]) // eslint-disable-line react-hooks/exhaustive-deps

    const checkIfCanAddTeller = () => setCanAddTeller(!formContainsUnselectedTeller(form))

    useEffect(() => {
        if (!initialTellers) return

        checkIfCanAddTeller()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialTellers])

    const addNewTeller = async (addFunc: (defaultValue?: any, insertIndex?: number | undefined) => void) => {
        try {
            await form.validateFields()
            addFunc({
                id: '', name: '', mandatory: false
            })

            setShouldScroll(true)
        } catch {
            const errors = form.getFieldsError()
            const firstError = errors.find(e => e.errors.length)
            if (firstError) {
                const footerOffset = 160
                form.scrollToField(firstError.name, {
                    block: 'end',
                    behavior: actions =>
                        actions.forEach(({ el, top, left }) => {
                            el.scrollTop = top + footerOffset
                            el.scrollLeft = 0
                        })
                })
            }

            return
        }
    }

    const onTellerChange = (reset: (autoClear?: boolean) => void, teller?: ElectionParticipant) => {
        checkIfCanAddTeller()
        const tellers = form.getFieldsValue().tellers
        if (teller) {
            const filtered = tellers.filter((t: any) => teller?.id && t?.id === teller.id)
            const hasDuplicates = filtered.length > 1

            if (hasDuplicates) {
                showInfo({
                    title: t('duplicate_name'),
                    text: t('the_individual_is_already_selected_please_select_another_name'),
                    onOk: () => reset(true),
                    stayOpenedOnCancel: true
                })
            }
        }
        setTellers()
    }

    const setTellers = () => {
        const tellers = form.getFieldsValue().tellers
        if (tellers) {
            setSelectedTellers(tellers.map((t: any) => ({ id: t.id, name: t.name, role: t.role })))
        } else {
            setSelectedTellers([])
        }
    }

    return (
        <>
            {infoPortal}
            {!!initialTellers?.length && <Form
                layout="vertical"
                className={style.manageTellersForm}
                name="tellers"
                onValuesChange={async () => { checkIfCanAddTeller() }}
                form={form}>
                <Form.List name="tellers" initialValue={initialTellers}>
                    {(fields, { add: addFunc, remove }) => (
                        <>
                            {fields.map((field) => {
                                const selectedTeller = form.getFieldValue(['tellers', field.name])
                                if (selectedTeller) {
                                    return <TellerSelectionForm
                                        canSaveWithoutMandatoryTellers={canSaveWithoutMandatoryTellers}
                                        initSelectedTeller={selectedTeller}
                                        selectedTellers={selectedTellers}
                                        election={election!}
                                        key={field.key}
                                        tellerName={field.name}
                                        onRemove={() => remove(field.name)}
                                        onChange={onTellerChange}
                                        onChangeRole={setTellers}
                                        form={form} />
                                }

                                return <></>
                            })}
                            <Form.Item>
                                <BottomFixedPanel>
                                    <button
                                        id={style.addTeller}
                                        className={`btn-main-secondary ${style.addTellerBtn}`}
                                        type="button"
                                        disabled={!canAddTeller}
                                        onClick={() => addNewTeller(addFunc)}>
                                        <IconWrapper><Plus width="10" height="10" /></IconWrapper>
                                        <span>{t('teller:add_teller')}</span>
                                    </button>
                                </BottomFixedPanel>
                            </Form.Item>
                        </>)}
                </Form.List>
            </Form>}
            <div>
                <div ref={bottomRef} />
            </div>
        </>
    )
}

export default ManageElectionTellersForm