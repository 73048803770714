import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { User } from '../../model'

type UserListFilter = {
    loginName?: string
    fullName?: string
}

export const useUserList = ({ loginName, fullName }: UserListFilter, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.AuthenticationService)

    const getBallotCandidates = async () => {

        const result = await axios<any>(
            `${moduleUrl}admin-user/list-users?${loginName
                ? `login-name=${loginName}`
                : `full-name=${fullName || 'no-full-name-provided'}`
            }`)

        const users: User[] = result.data.map((u: any) => ({ id: u.loginName, name: u.fullName }))

        return users
    }

    return useQuery({
        queryKey: ['users', loginName, fullName],
        queryFn: getBallotCandidates,
        enabled
    })

}