import { useElections } from './features/dashboard/api'
import Dashboard from './pages/Dashboard'

export const electionManagement = {
    api: {
        queries: {
            useElections
        }
    },
    pages: {
        Dashboard: Dashboard
    }
}

