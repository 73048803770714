import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import style from './index.module.scss'

type UnmergeResultsPopupProps = {
    onClose?: () => void
}

function UnmergeResultsPopup({ onClose }: UnmergeResultsPopupProps) {
    const { t } = useTranslation('teller')
    const navigate = useNavigate()
    const unmergeResults = async () => {
        navigate('/merged-results')

        onClose?.()
    }

    const handleCancel = () => {
        onClose?.()
    }

    return (
        <>
            <div className={style.contentBlock}>
                {t('your_results_merged')}
            </div>
            <div className={style.boldText}>
                {t('if_you_need_to_enter_some_physical_ballots_please_unmerge')}
            </div>
            <div className={style.buttonsWrapper}>
                <button className={`${style.unmerge} btn-main-secondary`} onClick={() => unmergeResults()}>
                    <span>{t('unmerge_results')}</span>
                </button>
                <button className={`${style.proceed} btn-main-primary`} onClick={handleCancel}>
                    <span>{t('proceed_with_online_ballots_only')}</span>
                </button>
            </div>
        </>
    )
}

export default UnmergeResultsPopup