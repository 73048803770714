import { Col, Form, FormInstance, Row } from 'antd'
import RichTextEditor from 'components/molecules/RichTextEditor'
import { useTranslation } from 'react-i18next'
import { richTextEditorEmptyValue } from 'utils'
import { useElectionConfigData } from '../../hooks/useElectionConfigData'
import SectionTitle from '../SectionTitle'
import style from './index.module.scss'

type ElectionWordingSettingsProps = {
    form: FormInstance
    importantInitValue?: string
}

function ImportantMessage({ form, importantInitValue }: ElectionWordingSettingsProps) {
    const { t } = useTranslation('configuration')
    const { setElectionConfigData } = useElectionConfigData()

    return (
        <Row gutter={16} className={style.alignBottom}>
            <Col span={6}>
                <SectionTitle>{t('important_message')}:</SectionTitle>
            </Col>
            <Col span={18}>
                <div className={style.inputAndLabel}>
                    <Form.Item
                        name={'importantMessage'}
                        className={`no-padding ${style.wideInput}`}
                        valuePropName="checked"
                        label={t('text')}
                        rules={[{
                            required: true,
                            validator: () => {
                                if (form.getFieldValue('importantMessage') === richTextEditorEmptyValue)
                                    return Promise.reject()

                                return Promise.resolve()
                            },
                            message: t('please_enter_the_input', { input: t('text') })
                        }]}
                    >
                        <RichTextEditor width={817} height={90}
                            placeholder={t('please_enter_the_input', { input: t('text') })}
                            errorMessage={t('please_enter_the_input', { input: t('text') })}
                            form={form}
                            fieldName="importantMessage"
                            initValue={importantInitValue}
                            onChange={() => {
                                setElectionConfigData(current => ({ ...current, isFormTouched: true }))
                            }}
                        />
                    </Form.Item>
                    <div className={style.label}>
                        {t('displays_on_the_first_step_of_setup_election_process')}
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default ImportantMessage