import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useElectionRestrictionsOld, useElectionWordingConfiguration } from 'api'
import Preloader from 'components/molecules/Preloader'
import { ElectionType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toDayjs } from 'utils'
import ConfigWrapper from '../../../../components/ConfigWrapper'
import Divider from '../../../../components/Divider'
import { useElectionConfigData } from '../../hooks/useElectionConfigData'
import { ElectionPeriod } from '../../model'
import GeneralSettings from '../GeneralSettings'
import ImportantMessage from '../ImportantMessage'
import InPersonPopupWordingSettings from '../InPersonPopupWordingSettings'
import ShowVotingInPersonPopup from '../ShowVotingInPersonPopup'
import StartTellingProcessFrom from '../StartTellingProcessFrom'

function ByElectionConfig() {
    const { t } = useTranslation('configuration')
    const [form] = useForm()
    const [isFormInitialized, setIsFormInitialized] = useState(false)
    const { setElectionConfigData } = useElectionConfigData()
    const { data: restrictions, isLoading: isLoadingRestrictions } = useElectionRestrictionsOld()
    const { data: wordingConfiguration, isLoading: isLoadingWording } = useElectionWordingConfiguration(
        ElectionType.BY_ELECTION
    )

    useEffect(() => {
        form.setFieldsValue({
            onlineVotingInPersonPromptEnabled: false,
            electionEnabled: false,
            byPassFlowEnabled: false
        })

        setElectionConfigData(current => {
            const updatedData = {
                ...current, form,
                isFormTouched: false,
                electionType: ElectionType.BY_ELECTION,
                electionPeriod: ElectionPeriod.RIDVAN_ELECTION_PERIODS
            }

            return updatedData
        })
    },
        [form, setElectionConfigData])

    useEffect(() => {
        if (restrictions && wordingConfiguration) {
            const byElectionRestrictions = restrictions.byElectionRestriction

            setElectionConfigData(current => ({
                ...current,
                electionRestrictions: byElectionRestrictions
            }))
            const formValues = {
                electionEnabled: byElectionRestrictions.electionCreationEnabled,
                byPassFlowEnabled: byElectionRestrictions.bypassFlowAllowed,
                startTellingProcessDateTypeTime: byElectionRestrictions.voteCountingStartTime
                    ? toDayjs(byElectionRestrictions.voteCountingStartTime, 'HH:mm:ss') : null,
                showVotingInPersonPopupDateTypeTime: byElectionRestrictions.inPersonVotingAvailableFromTime
                    ? toDayjs(byElectionRestrictions.inPersonVotingAvailableFromTime, 'HH:mm:ss') : null,
                onlineVotingInPersonPromptEnabled: byElectionRestrictions.inPersonVotingAvailable,

                importantMessage: wordingConfiguration.importantMessage,
                votingInPersonTitle: wordingConfiguration.votingInPersonTitle,
                votingInPersonBody: wordingConfiguration.votingInPersonBody,
                votingInPersonYes: wordingConfiguration.votingInPersonYes,
                votingInPersonNo: wordingConfiguration.votingInPersonNo
            }

            form.setFieldsValue(formValues)
            setIsFormInitialized(true)
        }
    }, [restrictions, wordingConfiguration, setElectionConfigData, form])

    if (isLoadingRestrictions || isLoadingWording || !isFormInitialized) {
        return <Preloader />
    }

    return (
        <ConfigWrapper >
            <Form
                layout="vertical"
                form={form}
                onValuesChange={() => {
                    setElectionConfigData(current => ({ ...current, isFormTouched: true }))
                }}>
                <GeneralSettings form={form}
                    enabledTitle={t('enable_disable_by_election')}
                    enabledSubtitle={
                        <>
                            <div>{t('show_hide_option')}.</div>
                        </>
                    }
                />
                <Divider />

                <StartTellingProcessFrom
                    form={form}
                    timeOnly
                />
                <Divider />

                <ShowVotingInPersonPopup
                    form={form}
                    timeOnly
                />
                <InPersonPopupWordingSettings
                    form={form} bodyInitValue={wordingConfiguration?.votingInPersonBody}
                />
                <Divider />

                <ImportantMessage
                    form={form}
                    importantInitValue={wordingConfiguration?.importantMessage}
                />
            </Form>
        </ConfigWrapper>
    )
}

export default ByElectionConfig