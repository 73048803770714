import ContentWrapper from 'components/atoms/ContentWrapper'
import Reports from '../components/Reports'

export function Reporting() {

    return (
        <>
            <ContentWrapper>
                <Reports />
            </ContentWrapper>
        </>
    )
}

export default Reporting