import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { BreakingTieRound, ElectionResultWithTieInfo } from 'models'
import { CSSProperties } from 'react'
import { capitalizeFirstLetter } from 'utils'
import style from './index.module.scss'

type MergedResultItemProps = {
    style?: CSSProperties,
    index: number,
    result: ElectionResultWithTieInfo,
    isTiePresent: boolean | undefined,
    lastCompletedRound?: BreakingTieRound,
    onlineVotingClosed?: boolean
}

function MergedResultItem({
    style: itemStyle,
    index, result,
    isTiePresent,
    lastCompletedRound,
    onlineVotingClosed
}: MergedResultItemProps) {
    const gender = result.gender ? capitalizeFirstLetter(result.gender) : String.fromCharCode(8212)
    let voteCountClassName = ''

    const listItemClassNames = [style.listItem]
    if (result.isTie && isTiePresent) {
        listItemClassNames.push(style.tie)
        voteCountClassName = style.tie
    } else if (result.isElecteeAfterTie) {
        listItemClassNames.push(style.electionResultTie)
        voteCountClassName = style.electionResultTie
    }

    const formattedVoteCount = (result?.tieLatestRoundVoteCount ?? 0) > 0
        ? `+${result.tieLatestRoundVoteCount}`
        : result.tieLatestRoundVoteCount?.toString()

    return (
        <div className={listItemClassNames.join(' ')} style={itemStyle}>
            <div style={{ width: 60 }} className={style.column}>
                {index + 1}
            </div >
            <div style={{ width: 105 }} className={style.column}>
                {result.id}
            </div >
            <div style={{ width: lastCompletedRound ? 250 : 300 }} className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {result.name}
                </OptionalTooltip>
            </div >
            <div style={{ width: 80 }} className={style.column}>
                {gender}
            </div >
            <div style={{ width: 90 }} className={style.column}>
                {result.locality?.code || String.fromCharCode(8212)}
            </div >
            <div style={{ width: 160 }} className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {result.locality?.name || String.fromCharCode(8212)}
                </OptionalTooltip>
            </div >
            <div style={{ width: lastCompletedRound ? 200 : 230 }} className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {result.race}
                </OptionalTooltip>
            </div >
            <div style={{ width: lastCompletedRound ? 200 : 230 }} className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {result.ethnicity}
                </OptionalTooltip>
            </div >
            <div style={{ width: 80 }} className={style.column}>
                {result.voteCount}
            </div >
            {lastCompletedRound
                && <div style={{ width: 100 }} className={`${style.column} ${voteCountClassName}`}>
                    {formattedVoteCount}
                </div >}
        </div >
    )
}

export default MergedResultItem