import MilestoneItem from 'components/atoms/MilestoneItem'
import { MilestoneColorType, ElectionStatusType } from 'models'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DelegateElectionItem } from '../../models'

type DelegateTellersCardProps = {
    election: DelegateElectionItem,
    status: ElectionStatusType
}

const DelegateTellersCard: React.FC<DelegateTellersCardProps> = ({ election, status }) => {
    const { t } = useTranslation('election')
    const navigate = useNavigate()

    const getCardData = () => {
        switch (status) {
            case ElectionStatusType.NOT_STARTED:
                return {
                    color: election.unitsWithSetUpTellersNumber > 0
                        ? MilestoneColorType.SUCCESS : MilestoneColorType.PRIMARY,
                    buttonClass: election.unitsWithSetUpTellersNumber > 0
                        ? 'btn-main-secondary-md not-underline' : 'btn-main-primary-md',
                    buttonText: t('go_to_election_board'),
                    onClick: () => navigate(`/local-delegate-election-board/${election.electionPeriodId}`),
                    disabled: false
                }
            case ElectionStatusType.IN_PROGRESS:
                return {
                    color: election.unitsWithSetUpTellersNumber > 0
                        ? MilestoneColorType.SUCCESS : MilestoneColorType.PRIMARY,
                    buttonClass: election.unitsWithSetUpTellersNumber > 0
                        ? 'btn-main-secondary-md not-underline' : 'btn-main-primary-md',
                    buttonText: t('go_to_election_board'),
                    onClick: () => navigate(`/local-delegate-election-board/${election.electionPeriodId}`),
                    disabled: false
                }
            case ElectionStatusType.COMPLETED:
                return {
                    color: MilestoneColorType.SUCCESS,
                    buttonClass: 'btn-main-secondary-md not-underline',
                    buttonText: t('go_to_election_board'),
                    onClick: () => navigate(`/local-delegate-election-board/${election.electionPeriodId}`),
                    disabled: false
                }
            default:
                return null
        }
    }

    const cardData = getCardData()

    if (!cardData) {
        return null
    }

    const { color, buttonClass, buttonText, onClick, disabled } = cardData

    return (
        <MilestoneItem
            title={t('tellers')}
            color={color}
            description={t('tellers_set_up_for_units_setup_of_total', {
                setUp: election.unitsWithSetUpTellersNumber,
                total: election.unitsNumber
            })}
            children={
                <button
                    className={buttonClass}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {buttonText}
                </button>
            }
        />
    )
}

export default DelegateTellersCard
