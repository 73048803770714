import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'

export function useUnmergeResults() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function unmergeResults(electionId: string) {

        return await axios(`${moduleUrl}elections/${electionId}/unmerge-results`,
            {
                method: 'post',
                data: {}
            })
    }

    return useMutation({
        mutationFn: unmergeResults
    })
}