import { useCheckReportStatus, useGenerateReport, useGetReport } from 'api'
import Preloader from 'components/molecules/Preloader'
import { queryClient } from 'config/query-client'
import { ElectionType, ReportFormat, ReportStatus, ReportType } from 'models'
import { useEffect, useState } from 'react'
import { useLsaElectionsData } from '../../hooks'
import { ElectionsReport } from '../../model'

//TODO: Refactor this component to use the useJsonReportLoader hook

type ElectionsLoaderPorps = {
    electionType: ElectionType
}

function ElectionsLoader({ electionType }: ElectionsLoaderPorps) {
    const [reportIsReady, setReportIsReady] = useState(false)
    const [timerId, setTimerId] = useState<NodeJS.Timer>()

    const { lsaElectionsData, setLsaElectionsData } = useLsaElectionsData()

    const { data: reportId } = useGenerateReport({
        reportType: ReportType.LsaElections,
        reportFormat: ReportFormat.JSON,
        params: {
            ballotingYear: lsaElectionsData.badiYear,
            electionType
        }
    }, !!lsaElectionsData?.badiYear)

    const { data: status, isLoading: isStatusLoading } = useCheckReportStatus(reportId!, !!reportId)
    const { data: report } = useGetReport<ElectionsReport>(reportId!, reportIsReady)

    useEffect(() => {
        const resetReportStatusChecking = () => {
            if (timerId) {
                clearInterval(timerId)
                setTimerId(undefined)
                setLsaElectionsData(current => ({
                    ...current,
                    isReportLoading: false
                }))
            }
        }
        if (status) {
            if (status.includes(ReportStatus.FAILED)) {
                setLsaElectionsData(current => ({
                    ...current,
                    reportError: status.split(`${ReportStatus.FAILED}: `)[1]
                }))
            }
            switch (status) {
                case ReportStatus.PENDING:
                case ReportStatus.IN_PROGRESS:
                    if (!timerId) {
                        setLsaElectionsData(current => ({
                            ...current,
                            isReportLoading: true
                        }))
                        const tId = setInterval(async () => {
                            await queryClient.invalidateQueries(['report-status', reportId])
                        }, 1000)
                        setTimerId(tId)

                    }
                    break
                case ReportStatus.COMPLETED:
                    resetReportStatusChecking()
                    setReportIsReady(true)
                    break
                default:
                    resetReportStatusChecking()
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, isStatusLoading])

    useEffect(() => {
        if (report) {
            setLsaElectionsData(current => ({
                ...current,
                allElections: report.elections
            }))
            setLsaElectionsData(current => ({
                ...current,
                summary: report.summary
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report])

    return (
        <>{lsaElectionsData.isReportLoading && <Preloader />}</>
    )
}

export default ElectionsLoader