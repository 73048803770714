import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { OnlineBallotCount } from 'models'

export const useElectionOnlineBallotsCount = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.OnlineBallot)

    const getElectionOnlineBallotsCount = async () => {
        const res = await axios<OnlineBallotCount>(`${moduleUrl}elections/${electionId}/ballots/count`)

        return res.data
    }

    return useQuery<OnlineBallotCount>({
        queryKey: ['election-online-ballots-counts', electionId],
        queryFn: getElectionOnlineBallotsCount,
        enabled
    })
}