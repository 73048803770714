import { PropsWithChildren, ReactNode } from 'react'
import style from './index.module.scss'

interface IPageHeaderProps {
    greeting?: ReactNode,
    subgreeting?: ReactNode,
    sticky?: boolean,
    subheader?: ReactNode,
    fixedWidth?: boolean,
    minHeaderContentWidth?: number
}

const PageHeader = ({
    children,
    greeting,
    subgreeting,
    sticky = true,
    subheader,
    minHeaderContentWidth,
    fixedWidth = true
}: PropsWithChildren<IPageHeaderProps>) => (
    <div className={`${style.wrapper} ${sticky ? style.sticky : ''}`}>
        <div className={`${style.pageHeader} ${fixedWidth ? style.fixedWidth : style.notFixedWidth}`}>
            <div className={style.content} style={{ minWidth: minHeaderContentWidth || 860 }}>
                <div className={style.greeting}>
                    {greeting}
                    <div className={style.subgreeting}>{subgreeting}</div>
                </div>
                {children}
            </div>
        </div>
        {subheader && (
            <div className={`${style.subheader} ${fixedWidth ? style.fixedWidthSubheader : ''}`}>
                {subheader}
            </div>
        )}
    </div>
)

export default PageHeader
