import { useOfficersElectionParticipants } from 'api'
import Note from 'components/molecules/Note'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { useAuth } from 'hooks'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type StartVotingProps = {
    viewMode: boolean,
    onStartVoting?: () => void,
    onCancel?: () => void
}

const VACANSIES_COUNT = 9

const StartVoting = ({ viewMode, onStartVoting, onCancel }: StartVotingProps) => {
    const { t } = useTranslation('officers-election')
    const { auth } = useAuth()

    const { data: participants } = useOfficersElectionParticipants(auth?.homeLocality?.code || '', !!auth?.homeLocality)

    const participantsList = useMemo(() => (
        <>
            {participants?.sort(
                (a, b) => a.name.localeCompare(b.name) || a.id.localeCompare(b.id))
                .map((participant) =>
                    <div key={participant.id}>
                    <OptionalTooltip contenWrapperClassName="ellipsis">
                        {`${participant.name} (${t('bid_id', { id: participant.id })})`}
                    </OptionalTooltip>
                    </div>
                )}
        </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [participants])

    const emptyVacansies = useMemo(() => {
        if (!viewMode) return

        const emptyVacansies = []
        for (let i = 0; i < VACANSIES_COUNT - (participants?.length || 0); i++) {
            emptyVacansies.push(<div key={i} className={style.emptyVacancy}>{t('election:vacancy')}</div>)
        }

        return emptyVacansies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participants, viewMode])

    return (
        <div className={style.contentBlock}>
            {viewMode &&
                <div className={style.infoBlockWrapper}>
                    <Note icon={'regular'} mode={'info'}>
                        {t('please_verify_the_current_lsa_membership')}
                    </Note>
                </div>
            }
            {!viewMode && <div>{t('following_members_can_participate_in_this_voting_session')}</div>}
            <div className={style.membersList}>
                {participantsList}
                {emptyVacansies}
            </div>
            {!viewMode && <>
                <div className={style.infoBlockWrapper}>
                    <Note icon={'regular'} mode={'info'}>
                        {t('by_clicking_start_voting_the_election_will_become_available')}
                    </Note>
                </div>
                <div className={style.actionsGroup}>
                    <button className={`btn-main-secondary ${style.actionButton}`} onClick={onCancel}>
                        {t('cancel')}
                    </button>
                    <button className={`btn-main-primary ${style.actionButton}`} onClick={onStartVoting}>
                        {t('start_voting')}
                    </button>
                </div>
            </>
            }
        </div>
    )
}

export default StartVoting