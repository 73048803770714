import { BallotVote, ElectionParticipant, InvalidationReason } from 'models'
import { isVoteVacant } from '../utils'
import { useBallotVotes } from './useBallotVotes'

export const useBallotVotesActions = () => {
    const { ballotVotes, setBallotVotes } = useBallotVotes()

    const appendVote = (candidate: ElectionParticipant) => {
        const vacancyIndex = ballotVotes.findIndex(v => isVoteVacant(v))
        if (vacancyIndex === -1 || !candidate) {
            return false
        }
        setBallotVotes(ballotVotes.map((v, i) => {
            if (i === vacancyIndex) {
                return {
                    candidate: { ...candidate },
                    id: v.id
                }
            }

            return { ...v }
        }))

        return true
    }

    const removeVote = (ballotVote: BallotVote) => {
        setBallotVotes(ballotVotes.map(vote => {
            if (vote.id === ballotVote.id) {
                return {
                    id: vote.id
                }
            }

            return { ...vote }
        }))
    }

    const updateVoteValidity = (ballotVote: BallotVote) => {
        if (ballotVote.invalid) {
            setBallotVotes(ballotVotes.map((v) => {
                if (v.id === ballotVote.id) {
                    return { id: ballotVote.id }
                }

                return { ...v }
            }))

            return
        }
        setBallotVotes(ballotVotes.map((v) => {
            if (v.id === ballotVote.id) {
                return { ...ballotVote, invalid: true, candidate: undefined }
            }

            return { ...v }
        }))


    }

    const setReason = (ballotVote: BallotVote, invalidationReason?: InvalidationReason) => {
        setBallotVotes(ballotVotes.map((v) => {
            if (v.id === ballotVote.id) {
                return { ...ballotVote, invalidationReason }
            }

            return { ...v }
        }))
    }

    const canAppendMore = () => {
        const vacancy = ballotVotes.find(v => isVoteVacant(v))

        return !!vacancy
    }

    const isAlreadyInBallot = (candidateId: string) => !!ballotVotes.find(v => v?.candidate?.id === candidateId)

    const allValidationReasonsProvided = () => {
        const ballots = ballotVotes.map((v) => {
            if (v.invalid && !v.invalidationReason) {
                return { ...v, hasValidationError: true }
            }

            return { ...v, hasValidationError: false }
        })

        setBallotVotes(ballots)

        return !ballots.find(b => b.hasValidationError)
    }

    const resetBallot = () => {
        setBallotVotes(ballotVotes.map((v) => ({ id: v.id })))
    }

    return {
        appendVote,
        removeVote,
        resetBallot,
        canAppendMore,
        isAlreadyInBallot,
        updateVoteValidity,
        setReason,
        allValidationReasonsProvided
    }
}