import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { Ballot } from 'models'
import { getUrlParamsFromFilter } from 'utils'

type BallotsFilter = {
    offset?: number,
    limit?: number
}

export const useElectionStationsBallots = (electionId: string, filter?: BallotsFilter, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getElectionStationBallots = async ({ queryKey }: QueryFunctionContext)
        : Promise<Ballot[]> => {
        const [, electionId, filter] = queryKey

        const queryParams = getUrlParamsFromFilter(filter as BallotsFilter)

        const res = await axios<Ballot[]>
            (`${moduleUrl}elections/${electionId}/stations/ballots${queryParams ? `?${queryParams}` : ''}`)

        return res.data
    }

    return useQuery({
        queryKey: ['election-stations-ballots', electionId, filter],
        queryFn: getElectionStationBallots,
        enabled
    })
}