import { ElectionStation } from 'models'
import { PropsWithChildren, useState } from 'react'
import { StationsContext } from '../context'

function StationsProvider({ children }: PropsWithChildren) {
    const [stations, setStations] = useState<ElectionStation[]>([])

    return (
        <StationsContext.Provider value={{ stations, setStations }} >
            {children}
        </StationsContext.Provider>
    )
}
export default StationsProvider