import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'

type SignStationReportParams = {
    signatures: Array<{
        teller: string,
        agreement: boolean
    }>,
    comment?: string
}

export function useSignStationReport() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    async function signStationReport({ signatures, comment }: SignStationReportParams) {
        return await axios(`${moduleUrl}stations/my/sign-station-report`,
            {
                method: 'post',
                data: {
                    signatures,
                    comment
                }
            })
    }

    return useMutation({
        mutationFn: signStationReport
    })
}