import { useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { TellerElection } from '../../models'

export const useChiefTellerElections = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getChiefTellerElections = async (): Promise<TellerElection[]> => {
        const elections = await axios<TellerElection[]>(moduleUrl + 'me/chief-teller/elections')

        return elections.data
    }

    return useQuery({
        queryKey: ['chief-teller-elections'],
        queryFn: getChiefTellerElections,
        enabled,
        staleTime: 3 * (60 * 1000)
    })
}