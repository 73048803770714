import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { TellingStation } from 'models'

export const useElectionStations = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getElectionStations = async (): Promise<TellingStation[]> => {
        const response = await axios<TellingStation[]>(moduleUrl + `elections/${electionId}/stations`)

        return response.data
    }

    return useQuery({
        queryKey: ['election-stations-details', electionId],
        queryFn: getElectionStations,
        enabled
    })
}