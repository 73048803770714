import { ColumnsType } from 'antd/es/table'
import { useElectionTellingStations } from 'api'
import Table from 'components/molecules/Table'
import { useAuth } from 'hooks'
import { ElectionStation } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import StationAbsenteeVoters from '../StationInAbsenteeVoters'
import StationInPersonVoters from '../StationInPersonVoters'
import StationInvalidBallots from '../StationInvalidBallots'
import StationValidBallots from '../StationValidBallots'
import style from './index.module.scss'

function StationsVotes() {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const { electionId } = useParams()
    const { data: tellingStations } = useElectionTellingStations(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const [stations, setStations] = useState<ElectionStation[]>([])

    useEffect(() => {
        if (tellingStations?.length) {
            setStations(Array.from(tellingStations).sort((a, b) => {
                const aId = parseInt(a.id.replace('S', ''), 10)
                const bId = parseInt(b.id.replace('S', ''), 10)

                return aId - bId
            }))
        } else {
            setStations([])
        }
    },
        [tellingStations])

    const columns: ColumnsType<ElectionStation> = [
        {
            title: t('in_person'),
            render: (station: ElectionStation) => <StationInPersonVoters
                electionId={electionId || auth!.electionId!}
                stationId={station.id} />,
            width: '25%'
        },
        {
            title: t('absentee'),
            render: (station: ElectionStation) => <StationAbsenteeVoters
                electionId={electionId || auth!.electionId!}
                stationId={station.id} />,
            width: '25%'
        },
        {
            title: t('valid'),
            render: (station: ElectionStation) => <StationValidBallots
                electionId={electionId || auth!.electionId!}
                stationId={station.id} />,
            width: '25%'
        },
        {
            title: t('invalid'),
            render: (station: ElectionStation) => <StationInvalidBallots
                electionId={electionId || auth!.electionId!} stationId={station.id} />,
            width: '25%'
        }
    ]

    return (
        <Table
            obsSize="small"
            obsHeaderSize="small"
            headerDividerVisible={true}
            dataSource={stations}
            columns={columns}
            pagination={false}
            caption={
                <div className={style.caption}>
                    <div className={style.textWrapper}>
                        <div>{t('voter_verification')}</div>
                        <div>{t('ballots_entering')}</div>
                    </div>
                </div>
            }
            rootClassName={style.table}
            rowKey="id" />
    )
}

export default StationsVotes