import { ElectionParticipant, ElectionType, ParticipantsStatistic, SortOrder } from 'models'
import { useEffect, useState } from 'react'
import VerifyParticipantsTable from '../../../../components/VerifyParticipantsTable'
import { ParticipantFilter } from '../../../../models'
import {
    useCities,
    useLocalDelegateParticipantsStatistic,
    useLocalities,
    useParticipants,
    useRidvanParticipantsStatistic
} from '../../api'

type VerifyLocalDelegateParticipantsProps = {
    electionPeriodId: string,
    localUnits: string[],
    isRidvanSecondStage?: boolean
}

function VerifyLocalDelegateParticipants({
    electionPeriodId,
    localUnits,
    isRidvanSecondStage
}: VerifyLocalDelegateParticipantsProps) {
    const defaultFilter: ParticipantFilter = { sortBy: { field: 'NAME', order: SortOrder.ASC }, offset: 0, limit: 30 }
    const [participantFilter, setParticipantFilter] = useState<ParticipantFilter>({ ...defaultFilter })
    const [participants, setParticipants] = useState<ElectionParticipant[]>([])
    const [participantsStatistic, setParticipantsStatistic] = useState<ParticipantsStatistic>({} as any)

    const { data: loadedParticipants, isFetching }
        = useParticipants(
            electionPeriodId,
            isRidvanSecondStage ? ElectionType.RIDVAN_ELECTION : ElectionType.LOCAL_DELEGATE_ELECTION,
            participantFilter
        )
    const { data: localities } = useLocalities(electionPeriodId, ElectionType.LOCAL_DELEGATE_ELECTION)
    const { data: cities } = useCities(electionPeriodId, ElectionType.LOCAL_DELEGATE_ELECTION)
    const { data: localDelegateParticipantsStatistic } =
        useLocalDelegateParticipantsStatistic(electionPeriodId, !isRidvanSecondStage)
    const { data: ridvanParticipantsStatistic } =
        useRidvanParticipantsStatistic(electionPeriodId, !!isRidvanSecondStage)

    const [page, setPage] = useState(0)
    const [canLoadMore, setCanLoadMore] = useState(true)

    useEffect(() => {
        const statistic: ParticipantsStatistic = {
            totalCount: 0,
            candidateCount: 0,
            voterCount: 0
        }

        if (localDelegateParticipantsStatistic) {
            for (const [, value] of Object.entries(localDelegateParticipantsStatistic)) {
                statistic.totalCount += value.totalCount
                statistic.candidateCount += value.candidateCount
                statistic.voterCount += value.voterCount
            }
        }

        setParticipantsStatistic(statistic)

    }, [localDelegateParticipantsStatistic])

    useEffect(() => {
        if (ridvanParticipantsStatistic) {
            setParticipantsStatistic({ ...ridvanParticipantsStatistic })
        }
    }, [ridvanParticipantsStatistic])

    const handleScrollToBottom = () => {
        if (!canLoadMore || isFetching)
            return
        setPage(page + 1)
        setParticipantFilter({ ...participantFilter, offset: participantFilter.limit! * (page + 1) })
    }
    const onFilterChange = (filter: ParticipantFilter) => {
        window.scrollTo(0, 0)
        setPage(0)
        setParticipantFilter({ ...filter, sortBy: (filter.sortBy ? { ...filter.sortBy } : undefined), offset: 0 })
    }

    useEffect(() => {
        setCanLoadMore(!!loadedParticipants?.length)
        if (loadedParticipants) {
            setParticipants(page ? participants.concat(loadedParticipants) : [...loadedParticipants])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedParticipants])

    return (
        <VerifyParticipantsTable
            withLocalUnit={true}
            localUnits={localUnits}
            participants={participants}
            participantsStatistic={participantsStatistic}
            localities={localities}
            cities={cities}
            onFilterChange={onFilterChange}
            onScrollToBottom={handleScrollToBottom}
            defaultFilter={defaultFilter}
        />
    )
}

export default VerifyLocalDelegateParticipants