import { useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ParticipantsStatistic } from 'models'

type ElectionsParticipantsStatisticParams = {
    electionIds: string[]
}

export const useElectionsParticipantsStatistic =
    ({ electionIds }: ElectionsParticipantsStatisticParams, enabled?: boolean) => {
        const axios = useAxios()
        const moduleUrl = getModuleUrl(ApiModule.Administration)

        const getElectionsParticipantsStatistic = async () => {
            const res = await axios<{ election: number, participants: ParticipantsStatistic }[]>(
                `${moduleUrl}elections/participants/count?elections=${electionIds.join(',')}`)

            return res.data
        }

        return useQuery({
            queryKey: ['elections-participants-count', electionIds],
            queryFn: getElectionsParticipantsStatistic,
            enabled
        })
    }