import { Select as AntSelect, Col, Form, Row } from 'antd'
import { useElection, useElectionWordingConfiguration } from 'api'
import { Select } from 'components/atoms/Select'
import { IWizardStepProps } from 'components/molecules/Wizard/models'
import dayjs from 'dayjs'
import DOMPurify from 'dompurify'
import { useConfirmPopup, useInfoPopup } from 'hooks'
import { ReactComponent as ChevronBold } from 'icons/chevron-bold.svg'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ElectionStatusType, ElectionType, RidvanElectionPeriod } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { isFormValid, isToday, toDayjs } from 'utils'
import ElectionEditingWizard from '../../../../components/ElectionEditingWizard'
import ManageElectionTellersForm from '../../../../components/ManageElectionTellersForm'
import SetUpElectionForm from '../../../../components/SetUpElectionForm'
import {
    useCheckIfStartDateExpired,
    useElectionWizardChipsSettings, useExtractSetUpElectionSchedule,
    useManageTellersActions,
    useSaveElectionAsDraft,
    useSetUpElectionActions,
    useVotingStartDateDependentTitles
} from '../../../../hooks'
import {
    useAnnounceRidvanElectionPeriod, useSetUpRidvanSchedule
} from '../../api'
import { isRidvanSecondStage } from '../../utils'
import ElectedLocalDelegates from '../ElectedLocalDelegates'
import VerifyLocalDelegateParticipants from '../VerifyLocalDelegateParticipants'
import VerifyRidvanParticipants from '../VerifyRidvanParticipants'

const numberOfVacancies = 9

type RidvanEditingProcessProps = {
    ridvanElectionPeriod: RidvanElectionPeriod
}

function RidvanEditingProcess({ ridvanElectionPeriod }: RidvanEditingProcessProps) {
    const { t } = useTranslation('election')
    const navigate = useNavigate()
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [manageTellersForm] = Form.useForm()
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { showInfo, infoPortal } = useInfoPopup()
    const { mutateAsync: setUpSchedule } = useSetUpRidvanSchedule()
    const { saveAsDraft } = useSaveElectionAsDraft()
    const { extractSchedule } = useExtractSetUpElectionSchedule()
    const { saveSetUpElectionForm } = useSetUpElectionActions()
    const { setUpElectionChipSettings, verifyParticipantsChipSettings, setUpTellersChipSettings }
        = useElectionWizardChipsSettings()
    const { manageTellers } = useManageTellersActions()
    const { mutateAsync: announceRidvanElectionPeriod } = useAnnounceRidvanElectionPeriod()
    const { getCompleteSetUpButtonText, getLastStepNote } = useVotingStartDateDependentTitles()
    const { data: election } = useElection(ridvanElectionPeriod.ridvanElectionStage.election.id.toString())
    const [initStep, setInitStep] = useState<number | undefined>()
    const { checkIfStartDateExpired } = useCheckIfStartDateExpired(showInfo)
    const { data: wordingConfig } = useElectionWordingConfiguration(ElectionType.RIDVAN_ELECTION,
        isRidvanSecondStage(ridvanElectionPeriod))

    useEffect(() => {
        if (election?.onlineVotingStart &&
            (election?.status === ElectionStatusType.DRAFT
                || election?.status === ElectionStatusType.NOT_STARTED)) {
            checkIfStartDateExpired(election.onlineVotingStart, () => { setInitStep(0) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [election])

    const setUpElectionFormInitial = {
        ballotingYear: t('common:year_b_e_period',
            {
                year: ridvanElectionPeriod.ballotingYear,
                period: `${dayjs().year()}-${dayjs().year() + 1}`
            }),
        numberOfVacancies,
        timeZone: ridvanElectionPeriod.ridvanElectionStage.election.timeZone,
        electionDay: ridvanElectionPeriod.ridvanElectionStage.election.electionDay ?
            toDayjs(ridvanElectionPeriod.ridvanElectionStage.election.electionDay)
            : undefined,
        onlineVotingStart: ridvanElectionPeriod.ridvanElectionStage.election.onlineVotingStart ?
            toDayjs(ridvanElectionPeriod.ridvanElectionStage.election.onlineVotingStart)
            : undefined
    }

    const saveRidvanSetUpForm = async () => {
        const electionSchedule = extractSchedule(manageTellersForm)
        await setUpSchedule({
            schedule: electionSchedule,
            electionPeriod: ridvanElectionPeriod.id.toString(),
            type: ElectionType.RIDVAN_ELECTION
        })
    }

    const saveForm = async () => await saveSetUpElectionForm({
        form: manageTellersForm,
        withValidation: true,
        saveFunc: saveRidvanSetUpForm
    })

    const getVotingStartDate = () => {
        const formValue = manageTellersForm.getFieldValue('onlineVotingStart')
        if (formValue)
            return formValue
        if (ridvanElectionPeriod && ridvanElectionPeriod.ridvanElectionStage.election.onlineVotingStart) {
            return dayjs(new Date(ridvanElectionPeriod.ridvanElectionStage.election.onlineVotingStart))
        }

        return null
    }

    const getLastStepButtonText = () => getCompleteSetUpButtonText(getVotingStartDate())

    const importantText = <div dangerouslySetInnerHTML={
        { __html: DOMPurify.sanitize(wordingConfig?.importantMessage || '') }} />

    const steps: IWizardStepProps[] = [
        {
            chipSettings: setUpElectionChipSettings,
            nextButton: {
                callback: async () => await saveForm(),
                icon: <ChevronBold height="10px" width="5.5px" />
            },
            children: <Row justify="space-evenly" gutter={16}>
                <Col span={12}>
                    <SetUpElectionForm form={manageTellersForm}
                        electionType={isRidvanSecondStage(ridvanElectionPeriod)
                            ? 'TWO_STAGE_RIDVAN_ELECTION' as any //TWO_STAGE_RIDVAN_ELECTION is used only here
                            // Looks like back end doesn't have a single enum to describe ElectionType
                            // and defines a new one once in a while
                            : ElectionType.RIDVAN_ELECTION}
                        initialValues={setUpElectionFormInitial}
                        importantText={importantText}>
                        <Form.Item
                            name="numberOfVacancies"
                            label={<>
                                {t('number_of_vacancies')}
                            </>}>
                            <Select
                                disabled={true}
                                placeholder={t('number_of_vacancies')}>
                                <AntSelect.Option value={numberOfVacancies} >
                                    {numberOfVacancies}
                                </AntSelect.Option>
                            </Select>
                        </Form.Item>
                    </SetUpElectionForm>
                </Col>
                {isRidvanSecondStage(ridvanElectionPeriod)
                    && <Col span={12}>
                        <ElectedLocalDelegates ridvanElectionPeriod={ridvanElectionPeriod} />
                    </Col>}
            </Row>
        },
        {
            chipSettings: verifyParticipantsChipSettings,
            nextButton: { callback: () => Promise.resolve(true), icon: <Chevron height="10" width="5.5" /> },
            leaveStepCallback: () => Promise.resolve(true),
            children: isRidvanSecondStage(ridvanElectionPeriod)
                ? <VerifyLocalDelegateParticipants electionPeriodId={ridvanElectionPeriod.id.toString()}
                    isRidvanSecondStage
                    localUnits={ridvanElectionPeriod
                        .localDelegateElectionStage!.elections.map(e => e.localUnitCode)} />
                : <VerifyRidvanParticipants
                    electionPeriodId={ridvanElectionPeriod.id.toString()} />
        },
        {
            chipSettings: setUpTellersChipSettings,
            nextButton: {
                callback: async () => {
                    const tellersValid = await isFormValid({ form: manageTellersForm })
                    if (!tellersValid) {
                        return false
                    }
                    if (election?.status === ElectionStatusType.DRAFT
                        || election?.status === ElectionStatusType.NOT_STARTED) {
                        if (await checkIfStartDateExpired(getVotingStartDate(), () => { setInitStep(0) })) {
                            return Promise.resolve(false)
                        }
                    }

                    const promise = new Promise<boolean>(async (resolve) => {
                        const electionStringDetails = {
                            electionYear: ridvanElectionPeriod.ballotingYear,
                            electionName: t('ridvan_election')
                        }

                        showConfirm({
                            onOk: async () => {
                                await manageTellers({
                                    form: manageTellersForm,
                                    electionId: ridvanElectionPeriod.ridvanElectionStage.election.id.toString()
                                })
                                await announceRidvanElectionPeriod({
                                    type: ElectionType.RIDVAN_ELECTION,
                                    electionPeriod: ridvanElectionPeriod.id.toString()
                                })
                                navigate('/')
                                resolve(true)
                            },
                            onCancel: async () => resolve(false),
                            title: isToday(getVotingStartDate())
                                ? t('complete_setup_and_start_online_election')
                                : t('complete_setup'),
                            text: isToday(getVotingStartDate())
                                ? t('you_are_about_to_start_the_year_name_would_you_like_to_proceed',
                                    electionStringDetails)
                                // eslint-disable-next-line max-len
                                : t('you_are_about_to_complete_setup_and_create_the_year_name_would_you_like_to_proceed',
                                    electionStringDetails
                                ),
                            okText: isToday(getVotingStartDate())
                                ? t('start_election') : t('complete_setup')
                        })
                    })

                    return promise
                },
                title: getLastStepButtonText()
            },
            minHeaderContentWidth: 1016,
            children: <ManageElectionTellersForm
                form={manageTellersForm} canSaveWithoutMandatoryTellers
                election={election!} />
        }

    ]

    const checkAndSaveAsDraft = async () => {
        const votingStartDate = getVotingStartDate()

        if (votingStartDate) {
            const isExpired = await checkIfStartDateExpired(votingStartDate.format(), () => { setInitStep(0) })
            if (isExpired) {
                return
            }
        }

        saveAsDraft({
            showConfirm: showConfirm,
            showInfo: showInfo,
            nextStepButtonText: currentStepIndex === 2 ? getLastStepButtonText() : t('common:next_step'),
            saveAsItIs: async () => {
                switch (currentStepIndex) {
                    case 0:
                        return await saveSetUpElectionForm({
                            form: manageTellersForm,
                            saveFunc: saveRidvanSetUpForm
                        })
                    case 2:
                        await manageTellers({
                            form: manageTellersForm,
                            electionId: ridvanElectionPeriod.ridvanElectionStage.election.id.toString()
                        })

                        return Promise.resolve(true)
                }


                return Promise.resolve(true)
            }
        })
    }

    return (
        <>
            {confirmPortal}
            {infoPortal}
            <ElectionEditingWizard
                steps={steps}
                initStep={initStep}
                announced={ridvanElectionPeriod.ridvanElectionStage.election.status !== ElectionStatusType.DRAFT}
                saveAsDraftCb={checkAndSaveAsDraft}
                lastStepNote={getLastStepNote(getVotingStartDate())
                    .map((line, index) => <div key={index}>{line}</div>)}
                type={ElectionType.RIDVAN_ELECTION}
                year={ridvanElectionPeriod.ballotingYear}
                wizardStepStorageKey={`${ElectionType.RIDVAN_ELECTION}_${ridvanElectionPeriod.id}_wizard_step`}
                onCurrentStepChange={(index) => setCurrentStepIndex(index)} />
        </>
    )
}

export default RidvanEditingProcess