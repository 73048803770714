import { Select as AntSelect, Col, Form, Row } from 'antd'
import { useElectionWordingConfiguration } from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import { Select } from 'components/atoms/Select'
import { IWizardStepProps } from 'components/molecules/Wizard/models'
import dayjs from 'dayjs'
import DOMPurify from 'dompurify'
import { useConfirmPopup, useInfoPopup } from 'hooks'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ElectionStatusType, ElectionType, RidvanElectionPeriod } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { isToday, toDayjs } from 'utils'
import ElectionEditingWizard from '../../../../components/ElectionEditingWizard'
import SetUpElectionForm from '../../../../components/SetUpElectionForm'
import {
    useCheckIfStartDateExpired,
    useElectionWizardChipsSettings,
    useExtractSetUpElectionSchedule, useSaveElectionAsDraft,
    useSetUpElectionActions,
    useVotingStartDateDependentTitles
} from '../../../../hooks'
import { useAnnounceRidvanElectionPeriod, useSetUpRidvanSchedule } from '../../api'
import { getLocalDelegateStatus } from '../../utils'
import LocalUnitList from '../LocalUnitList'
import VerifyLocalDelegateParticipants from '../VerifyLocalDelegateParticipants'

type LocalDelegateEditingProcessProps = {
    ridvanElectionPeriod: RidvanElectionPeriod
}

function LocalDelegateEditingProcess({ ridvanElectionPeriod }: LocalDelegateEditingProcessProps) {
    const { t } = useTranslation('election')
    const navigate = useNavigate()
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [numberOfDelegates, setNumberOfDelegates] = useState(0)
    const [setUpElectionForm] = Form.useForm()
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { showInfo, infoPortal } = useInfoPopup()
    const { mutateAsync: setUpSchedule } = useSetUpRidvanSchedule()

    const { data: wordingConfig } = useElectionWordingConfiguration(ElectionType.LOCAL_DELEGATE_ELECTION)

    const { saveAsDraft } = useSaveElectionAsDraft()
    const { extractSchedule } = useExtractSetUpElectionSchedule()
    const { saveSetUpElectionForm } = useSetUpElectionActions()
    const { setUpElectionChipSettings, verifyParticipantsChipSettings, setUpTellersChipSettings }
        = useElectionWizardChipsSettings()
    const { getCompleteSetUpButtonText, getLastStepNote } = useVotingStartDateDependentTitles()
    const { mutateAsync: announceRidvanElectionPeriod } = useAnnounceRidvanElectionPeriod()
    const [initStep, setInitStep] = useState<number | undefined>()
    const { checkIfStartDateExpired } = useCheckIfStartDateExpired(showInfo)

    useEffect(() => {
        if (ridvanElectionPeriod?.localDelegateElectionStage?.onlineVotingStart) {
            const status = getLocalDelegateStatus(ridvanElectionPeriod.localDelegateElectionStage!.elections)
            if (status === ElectionStatusType.DRAFT || status === ElectionStatusType.NOT_STARTED) {
                checkIfStartDateExpired(ridvanElectionPeriod!.localDelegateElectionStage!.onlineVotingStart,
                    () => { setInitStep(0) })
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ridvanElectionPeriod])

    useEffect(() => {
        if (ridvanElectionPeriod) {
            const allDelegates = ridvanElectionPeriod.localDelegateElectionStage!
                .elections.map(e => e.numberOfDelegates)
            setNumberOfDelegates(allDelegates.reduce((sum, current) => sum + current, 0)
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setUpElectionFormInitial = {
        ballotingYear: t('common:year_b_e_period',
            {
                year: ridvanElectionPeriod.ballotingYear,
                period: `${dayjs().year()}-${dayjs().year() + 1}`
            }),
        numberOfDelegates,
        timeZone: ridvanElectionPeriod.localDelegateElectionStage!.timeZone,
        electionDay: ridvanElectionPeriod.localDelegateElectionStage!.electionDay ?
            toDayjs(ridvanElectionPeriod.localDelegateElectionStage!.electionDay)
            : undefined,
        onlineVotingStart: ridvanElectionPeriod.localDelegateElectionStage!.onlineVotingStart ?
            toDayjs(ridvanElectionPeriod.localDelegateElectionStage!.onlineVotingStart)
            : undefined
    }

    const saveLocalDelegateSetUpForm = async () => {
        const electionSchedule = extractSchedule(setUpElectionForm)
        await setUpSchedule({
            schedule: electionSchedule,
            electionPeriod: ridvanElectionPeriod.id.toString(),
            type: ElectionType.LOCAL_DELEGATE_ELECTION
        })
    }

    const saveForm = async () => await saveSetUpElectionForm({
        form: setUpElectionForm,
        withValidation: true,
        saveFunc: saveLocalDelegateSetUpForm
    })

    const getVotingStartDate = () => {
        const formValue = setUpElectionForm.getFieldValue('onlineVotingStart')
        if (formValue)
            return formValue
        if (ridvanElectionPeriod && ridvanElectionPeriod.localDelegateElectionStage!.onlineVotingStart) {
            return dayjs(new Date(ridvanElectionPeriod.localDelegateElectionStage!.onlineVotingStart))
        }

        return null
    }

    const getLastStepButtonText = () => getCompleteSetUpButtonText(getVotingStartDate())

    const steps: IWizardStepProps[] = [
        {
            chipSettings: setUpElectionChipSettings,
            nextButton: {
                callback: async () => await saveForm(),
                icon: <IconWrapper><Chevron height="16px" width="16px" /></IconWrapper>
            },
            children: <Row justify="space-evenly" gutter={24}>
                <Col span={12}>
                    <SetUpElectionForm form={setUpElectionForm}
                        electionType={ElectionType.LOCAL_DELEGATE_ELECTION}
                        initialValues={setUpElectionFormInitial}
                        importantText={<div dangerouslySetInnerHTML={
                            { __html: DOMPurify.sanitize(wordingConfig?.importantMessage || '') }} />}
                    >
                        <Form.Item
                            name="numberOfDelegates"
                            label={<>
                                {t('number_of_delegates')}
                            </>}>
                            <Select
                                disabled={true}
                                placeholder={t('number_of_delegates')}>
                                <AntSelect.Option value={numberOfDelegates} >
                                    {numberOfDelegates}
                                </AntSelect.Option>
                            </Select>
                        </Form.Item>
                    </SetUpElectionForm>
                </Col>
            </Row >
        },
        {
            chipSettings: verifyParticipantsChipSettings,
            nextButton: {
                callback: () => Promise.resolve(true),
                icon: <IconWrapper> <Chevron height="16px" width="16px" /></IconWrapper>
            },
            children: <VerifyLocalDelegateParticipants electionPeriodId={ridvanElectionPeriod.id.toString()}
                localUnits={ridvanElectionPeriod.localDelegateElectionStage!.elections.map(e => e.localUnitCode)} />
        },
        {
            chipSettings: setUpTellersChipSettings,
            nextButton: {
                callback: async () => {
                    const status = getLocalDelegateStatus(ridvanElectionPeriod.localDelegateElectionStage!.elections)
                    if (status === ElectionStatusType.DRAFT || status === ElectionStatusType.NOT_STARTED) {
                        if (await checkIfStartDateExpired(getVotingStartDate(), () => { setInitStep(0) })) {
                            return Promise.resolve(false)
                        }
                    }

                    const promise = new Promise<boolean>((resolve) => {
                        const electionStringDetails = {
                            electionYear: ridvanElectionPeriod.ballotingYear,
                            electionName: t('local_delegate_election')
                        }
                        showConfirm({
                            onOk: async () => {
                                await announceRidvanElectionPeriod({
                                    type: ElectionType.LOCAL_DELEGATE_ELECTION,
                                    electionPeriod: ridvanElectionPeriod.id.toString()
                                })
                                navigate('/')
                                resolve(true)
                            },
                            onCancel: async () => resolve(false),
                            title: isToday(getVotingStartDate())
                                ? t('complete_setup_and_start_online_election')
                                : t('complete_setup'),
                            text: isToday(getVotingStartDate())
                                ? t('you_are_about_to_start_the_year_name_would_you_like_to_proceed',
                                    electionStringDetails)
                                // eslint-disable-next-line max-len
                                : t('you_are_about_to_complete_setup_and_create_the_year_name_would_you_like_to_proceed',
                                    electionStringDetails
                                ),
                            okText: isToday(getVotingStartDate())
                                ? t('start_election') : t('complete_setup')
                        })

                    })

                    return promise
                },
                title: getLastStepButtonText()
            },
            minHeaderContentWidth: 1016,
            children: <LocalUnitList
                elections={ridvanElectionPeriod.localDelegateElectionStage!.elections}
                periodId={ridvanElectionPeriod.id} />
        }

    ]

    const checkAndSaveAsDraft = async () => {
        const votingStartDate = getVotingStartDate()

        if (votingStartDate) {
            const isExpired = await checkIfStartDateExpired(votingStartDate.format(), () => { setInitStep(0) })
            if (isExpired) {
                return
            }
        }

        saveAsDraft({
            showConfirm: showConfirm,
            showInfo: showInfo,
            saveAsItIs: async () => {
                if (currentStepIndex === 0) {
                    return await saveSetUpElectionForm({
                        form: setUpElectionForm,
                        saveFunc: saveLocalDelegateSetUpForm
                    })
                }

                return Promise.resolve(true)
            },
            nextStepButtonText: currentStepIndex === 2 ? getLastStepButtonText() : t('common:next_step')
        })
    }

    return (
        <>
            {confirmPortal}
            {infoPortal}
            <ElectionEditingWizard
                steps={steps}
                initStep={initStep}
                announced={ridvanElectionPeriod.localDelegateElectionStage!.elections[0].status
                    !== ElectionStatusType.DRAFT}
                saveAsDraftCb={checkAndSaveAsDraft}
                lastStepNote={getLastStepNote(getVotingStartDate())
                    .map((line, index) => <div key={index}>{line}</div>)}
                type={ElectionType.LOCAL_DELEGATE_ELECTION}
                year={ridvanElectionPeriod.ballotingYear}
                wizardStepStorageKey={
                    `${ElectionType.LOCAL_DELEGATE_ELECTION}_${ridvanElectionPeriod.id}_wizard_step`}
                onCurrentStepChange={(index) => setCurrentStepIndex(index)} />
        </>
    )
}

export default LocalDelegateEditingProcess