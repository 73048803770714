import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { isToday } from 'utils'

export const useVotingStartDateDependentTitles = () => {
    const { t } = useTranslation('election')

    const getCompleteSetUpButtonText = (votingStartDate: dayjs.Dayjs) => {
        if (!votingStartDate)
            return ''

        return isToday(votingStartDate) ? t('complete_setup_and_start_online_election') : t('complete_setup')
    }

    const getLastStepNote = (votingStartDate: dayjs.Dayjs) => {
        if (!votingStartDate)
            return []

        const result = [t('you_may_enter_tellers_names_now_or_later')]

        const completeButtonText = getCompleteSetUpButtonText(votingStartDate)
        result.push(isToday(votingStartDate)
            ? t('click_on_title_button_to_start_online_election',
                {
                    title: completeButtonText,
                    interpolation: { escapeValue: false }
                })
            : t('click_on_title_button_to_finish_setup', { title: completeButtonText })
        )

        return result
    }

    return {
        getCompleteSetUpButtonText,
        getLastStepNote
    }
}