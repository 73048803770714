import { Col, Form, FormInstance, Row } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import DatePicker from 'components/atoms/DatePicker'
import { useTranslation } from 'react-i18next'
import { toDayjs } from 'utils'
import SectionTitle from '../SectionTitle'
import style from './index.module.scss'

const minElectionDurationDays = 0

type ElectionDateProps = {
    form: FormInstance
}

function ElectionDate({ form }: ElectionDateProps) {
    const { t } = useTranslation('configuration')

    const electionDateRangeFromDisabledDate: RangePickerProps['disabledDate'] = (current) => {
        const electionDateRangeTo = form.getFieldValue('electionDateRangeTo')
        const startDateRangeFrom = form.getFieldValue('startDateRangeFrom')
        let isBeforeOnlineStartDate = false
        let isAfterElectionDayTo = false

        if (startDateRangeFrom) {
            isBeforeOnlineStartDate =
                current.endOf('day').diff(startDateRangeFrom.endOf('day'), 'days') < minElectionDurationDays
        }
        if (electionDateRangeTo) {
            isAfterElectionDayTo = current && (
                electionDateRangeTo.endOf('day').diff(current.endOf('day'), 'days') < minElectionDurationDays)
        }

        return isAfterElectionDayTo || isBeforeOnlineStartDate
    }

    const electionDateRangeToDisabledDate: RangePickerProps['disabledDate'] = (current) => {
        const electionDateRangeFrom = form.getFieldValue('electionDateRangeFrom')
        if (electionDateRangeFrom) {
            const isBeforeElectionDateFrom =
                current.endOf('day').diff(electionDateRangeFrom.endOf('day'), 'days') < minElectionDurationDays

            return isBeforeElectionDateFrom
        }

        return false
    }

    return (
        <Row gutter={16}>
            <Col span={6}>
                <SectionTitle>{t('election_date_range')}:</SectionTitle>
            </Col>
            <Col span={6}>
                <Form.Item
                    className={style.input}
                    name="electionDateRangeFrom"
                    label={t('election_date_range_from')}
                    rules={[{
                        required: true,
                        message: t('please_select_the_input', { input: t('election_date_range_from') })
                    },
                    {
                        message: t('election_date_range_from_cannot_be_earlier_than_start_date_range_from'),
                        validator: (_, value) => {
                            if (value) {
                                const dayjsValue = toDayjs(value)

                                return electionDateRangeFromDisabledDate(dayjsValue, {
                                    type: 'date'
                                })
                                    ? Promise.reject()
                                    : Promise.resolve()
                            }

                            return Promise.resolve()
                        }
                    }]}
                    validateTrigger={['onBlur', 'onChange']}
                >
                    <DatePicker
                        placeholder={t('select_input', { input: t('date') })}
                        inputReadOnly={true}
                        disabledDate={electionDateRangeFromDisabledDate}
                        format="MM/DD/YYYY"
                        style={{ width: '100%' }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <div className={style.inputAndLabel}>
                    <Form.Item
                        className={style.input}
                        name="electionDateRangeTo"
                        label={t('election_date_range_to')}
                        rules={[{
                            required: true,
                            message: t('please_select_the_input', { input: t('election_date_range_to') })
                        }]}
                        validateTrigger={['onBlur', 'onChange']}
                    >
                        <DatePicker
                            placeholder={t('select_input', { input: t('date') })}
                            disabledDate={electionDateRangeToDisabledDate}
                            inputReadOnly={true}
                            format="MM/DD/YYYY"
                            style={{ width: '100%' }} />
                    </Form.Item>
                    <div className={style.label}>{t('allow_to_select_1_day')}</div>
                </div>
            </Col>
        </Row>
    )
}

export default ElectionDate