import IconWrapper from 'components/atoms/IconWrapper'
import Note from 'components/molecules/Note'
import Preloader from 'components/molecules/Preloader'
import { useAbility, useReportLoader } from 'hooks'
import { ReactComponent as ArrowDownload } from 'icons/arrow-download.svg'
import { ReportFormat, ReportType } from 'models'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import style from './index.module.scss'

function LuElectionResultsNotPushedNote() {
    const { t } = useTranslation('results')
    const { loadReport, isReportLoading } = useReportLoader()
    const { electionId } = useParams()
    const { ability } = useAbility()

    const downloadReport = () => {
        loadReport({
            reportType: ReportType.ConfirmedCandidateTellerReport,
            reportFormat: ReportFormat.PDF,
            params: {
                election: electionId
            }
        })
    }

    return (
        <>{isReportLoading && <Preloader title={t('working_on_your_report')} />}
        <Note mode="info" icon="regular">
            <div className={style.note}>
                <div className={style.noteTitle}>
                    <div className={style.noteItem}>
                        {t('lu_results_have_been_confirmed_but_have_not_been_sent_to_dest_yet',
                        { dest: t('common:eMembership2') })}
                    </div> 
                </div>
                { ability?.can('view', 'TellersReportButton') &&
                <button className="btn-main-tertiary-md hasIcon" onClick={downloadReport}>
                                <IconWrapper>
                                    <ArrowDownload className="flip-horizontal" width="16" height="16" />
                                </IconWrapper>
                        <span>{t('tellers_report_confirmed_results')}</span>
                    </button>}
            </div>
        </Note>
        </>
    )
}

export default LuElectionResultsNotPushedNote