import { useElectionStationsBallots, useOnlineBallots } from 'api'
import { ballotingAssets } from 'features/ballotingAssets'
import { useAuth } from 'hooks'
import { Ballot } from 'models'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ConsolidatedBallotEntryStatistic from '../ConsolidatedBallotEntryStatistic'

const sortAllBallots = (a: any, b: any): number => {
    const isAOnline = a.id.startsWith('OS-')
    const isBOnline = b.id.startsWith('OS-')

    if (!isAOnline && isBOnline) {
        return -1
    } else if (isAOnline && !isBOnline) {
        return 1
    }

    if (!isAOnline && !isBOnline) {
        const stationA = a.station
        const stationB = b.station
        const idANum = parseInt(a.id.split('-')[1], 10)
        const idBNum = parseInt(b.id.split('-')[1], 10)

        if (stationA === stationB) {
            return idBNum - idANum
        }

        return parseInt(stationB.split('S')[1], 10) - parseInt(stationA.split('S')[1], 10)
    }

    if (isAOnline && isBOnline) {
        const idANum = parseInt(a.id.replace('OS-', ''), 10)
        const idBNum = parseInt(b.id.replace('OS-', ''), 10)

        return idBNum - idANum
    }

    return 0
}

function ConsolidatedBallotEntry() {
    const { auth } = useAuth()
    const { electionId } = useParams()
    const { data: stationsBallots } = useElectionStationsBallots(electionId || auth!.electionId!, {},
        !!electionId || !!auth?.electionId)
    const { data: onlineBallots } = useOnlineBallots(electionId || auth!.electionId!)

    const [ballots, setBallots] = useState<Ballot[]>([])

    useEffect(() => {
        const all = [
            ...(stationsBallots || []),
            ...(onlineBallots?.map(ballot => ({
                ...ballot,
                id: `OS-${ballot.id}`
            })) || [])]
        all.sort(sortAllBallots)

        setBallots(all)
    },
        [stationsBallots, onlineBallots])

    return (
        <>
            <ballotingAssets.components.InternalScrollBallotsTable
                ballots={ballots}
                statistic={<ConsolidatedBallotEntryStatistic />} />
        </>
    )
}

export default ConsolidatedBallotEntry