
import { useElectionList } from '../../api/queries/electionList'
import AvaliableElections from '../../components/AvaliableElections'
import NoActiveElection from '../../components/NoActiveElection'
import { BallotingElection } from '../../models/balloting-election'

export function Elections() {
    const { data: elections, isFetching } = useElectionList() as {
        data: BallotingElection[] | undefined, isFetching: boolean
    }

    if (isFetching) {
        return null
    }

    return (
        elections?.length
            ?
            <AvaliableElections elections={elections} />
            : <NoActiveElection />
    )
}

export default Elections
