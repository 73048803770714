import { reporting } from '../../features/reporting'

import { useTellingStationInfo } from '../../hooks'
import TellingStationProcess from '../TellingStationProcess'

function DefineDashboardComponent() {
    const { info } = useTellingStationInfo()

    return (
        <>
            {
                info?.completed
                    ? <reporting.components.LeaveTellingStationProcessFeedback />
                    : <TellingStationProcess />
            }
        </>
    )
}

export default DefineDashboardComponent