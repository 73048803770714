import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import Preloader from 'components/molecules/Preloader'
import { queryKeysWithoutGeneralPreloader as noPreloaderKeys } from 'config/query-keys-without-general-preloader'
import { useColorTheme } from 'hooks'
import { useEffect } from 'react'

function GeneralPreloader() {
    const { colorTheme } = useColorTheme()

    const isFetching = useIsFetching({
        predicate: query => {
            for (let i = 0; i < noPreloaderKeys.length; i++) {
                if (query.queryKey.includes(noPreloaderKeys[i])) {
                    return false
                }
            }

            return true
        }
    })

    const isMutating = useIsMutating()


    useEffect(() => {
        document.body.setAttribute('data-color-theme', colorTheme)
    }, [colorTheme])

    return <>{(!!isFetching || !!isMutating) && <Preloader />}</>

}

export default GeneralPreloader