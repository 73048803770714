import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionParticipant } from 'models'

export const useElectionParticipants = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectionParticipants = async ({ queryKey }: QueryFunctionContext)
        : Promise<ElectionParticipant[]> => {
        const [, electionId] = queryKey

        const result = await axios<ElectionParticipant[]>(
            `${moduleUrl}elections/${electionId}/participants`)

        return result.data
    }

    return useQuery({
        queryKey: ['election-participants', electionId],
        queryFn: getElectionParticipants,
        enabled
    })
}