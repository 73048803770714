import Card from 'components/atoms/Card'
import Tabs from 'components/atoms/Tabs'
import { useConfirmPopup, useHeaderLogoRedirect } from 'hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAnyFormChanged } from '../../../../hooks'
import { useElectionConfigData } from '../../hooks/useElectionConfigData'
import ElectionConfigProvider from '../../provider/ElectionConfigProvider'
import ByElectionConfig from '../ByElectionConfig'
import ElectionConfigTitle from '../ElectionConfigTitle'
import LocalDelegateElectionConfig from '../LocalDelegateElectionConfig'
import NationalDelegateElectionConfig from '../NationalDelegateElectionConfig'
import OfficersElectionConfig from '../OfficersElectionConfig'
import style from './index.module.scss'

const STORAGE_KEY = 'admin_config_election_tab'

function ElectionConfigurationContent() {
    const { t } = useTranslation('election')
    const { confirmPortal, showConfirm } = useConfirmPopup()
    const [activeTab, setActiveTab] = useState(localStorage.getItem(STORAGE_KEY) || 'ridvan')
    const { electionConfigData } = useElectionConfigData()
    const { setConfirmationCallback } = useHeaderLogoRedirect()
    const { setIsAnyFormChanged } = useAnyFormChanged()

    const tabs = useMemo(() => [
        {
            key: 'ridvan',
            label: t('administration:ridvan'),
            children: 'Comming Soon...'
        },
        {
            key: 'by-election',
            label: t('by_election'),
            children: <ByElectionConfig />
        },
        {
            key: 'local-delegate',
            label: t('local_delegate_election'),
            children: <LocalDelegateElectionConfig />
        },
        {
            key: 'national-delegate',
            label: t('national_delegate_election'),
            children: <NationalDelegateElectionConfig />
        },
        {
            key: 'rbc',
            label: t('administration:rbc_election'),
            children: 'Comming Soon...'
        },
        {
            key: 'officers',
            label: t('officers_election'),
            children: <OfficersElectionConfig />
        }
    ], [])

    useEffect(() => {
        if (electionConfigData.isFormTouched) {
            setIsAnyFormChanged(true)
            setConfirmationCallback(true)
        } else {
            setIsAnyFormChanged(false)
            setConfirmationCallback(false)
        }

        return () => {
            setConfirmationCallback(false)
        }
    }, [electionConfigData.isFormTouched, setIsAnyFormChanged, setConfirmationCallback])

    const changeTab = useCallback((newTab: string) => {
        setActiveTab(newTab)

        localStorage.setItem(STORAGE_KEY, newTab)
    }, [])

    const handleTabChange = useCallback((newTab: string) => {
        if (newTab !== activeTab && electionConfigData.isFormTouched) {
            showConfirm({
                text: <>{t('would_you_like_to_leave_this_page_without_saving')}</>,
                title: <>{t('leave_current_page')}</>,
                okText: <>{t('leave')}</>,
                cancelText: <>{t('continue_editing')}</>,
                onOk: async () => changeTab(newTab)
            })
        } else {
            changeTab(newTab)
        }
    }, [activeTab, electionConfigData.isFormTouched, showConfirm, changeTab])

    return (
        <>
            {confirmPortal}
            <Card
                title={<ElectionConfigTitle />}
                className={style.electionConfigCard}
                noContentPadding={true}
            >
                <Tabs
                    activeKey={activeTab}
                    onChange={handleTabChange}
                    className={style.electionConfigTabs}
                    destroyInactiveTabPane
                    rootClassName={style.tabs}
                    items={tabs}
                />
            </Card>
        </>
    )
}

function ElectionConfiguration() {
    return (
        <ElectionConfigProvider>
            <ElectionConfigurationContent />
        </ElectionConfigProvider>
    )
}

export default ElectionConfiguration