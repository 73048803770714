import { useEffect } from 'react'

export const useAntSelectClearButtonAttributes = () => {

    useEffect(() => {
        const applyAttributes = (element: Element) => {
            element.setAttribute('aria-hidden', 'false')
        }

        const callback = (mutationsList: MutationRecord[]) => {
            mutationsList.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach((node) => {
                        if (node instanceof Element && node.classList.contains('ant-select-clear')) {
                            applyAttributes(node)
                        }
                    })
                }
            })
        }

        const observer = new MutationObserver(callback)

        observer.observe(document.body, { childList: true, subtree: true })

        document.querySelectorAll('.ant-select-clear').forEach((element) => {
            if (element instanceof Element) {
                applyAttributes(element)
            }
        })

        return () => observer.disconnect()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}