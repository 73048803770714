import { FontStyle } from 'models'

export const fontStyleRules = (fontStyle: FontStyle): {
    fontSize: string,
    lineHeight: string
} => {
    switch (fontStyle) {
        case FontStyle.Small:
            return {
                fontSize: '10px',
                lineHeight: '14px'
            }
        case FontStyle.Caption:
            return {
                fontSize: '12px',
                lineHeight: '16px'
            }
        case FontStyle.Medium:
            return {
                fontSize: '14px',
                lineHeight: '18px'
            }
        case FontStyle.Body1:
            return {
                fontSize: '14px',
                lineHeight: '20px'
            }
        case FontStyle.Large:
            return {
                fontSize: '16px',
                lineHeight: '20px'
            }
        case FontStyle.Subheadline1:
            return {
                fontSize: '18px',
                lineHeight: '24px'
            }
        case FontStyle.Headline:
            return {
                fontSize: '20px',
                lineHeight: '28px'
            }
        case FontStyle.Title3:
            return {
                fontSize: '24px',
                lineHeight: '32px'
            }
        case FontStyle.Title2:
            return {
                fontSize: '28px',
                lineHeight: '36px'
            }
        case FontStyle.Title1:
            return {
                fontSize: '32px',
                lineHeight: '40px'
            }
    }
}

export const getFontStyleByProps = (fontSize: string, lineHeight: string): FontStyle => {

    const style = Object.keys(FontStyle).find((fs: string) => {
        const rules = fontStyleRules(FontStyle[fs as FontStyle])
        if (rules.fontSize === fontSize && rules.lineHeight === lineHeight)
            return true

        return false
    })

    return style ? style as FontStyle : FontStyle.Title2
}