import { SortOrder } from 'models'
import { LsaElection, LsaElectionsFilter } from '../model'

export const filterElections = (allElections: LsaElection[], filter: LsaElectionsFilter) => {
    let elections = [...allElections]

    let sortingOrder = SortOrder.ASC
    let sortingField: keyof LsaElectionsFilter = 'localityName'

    if (filter.sortBy) {
        sortingOrder = filter.sortBy.order
        sortingField = filter.sortBy.field as keyof LsaElectionsFilter
    }

    elections = elections.sort((a: LsaElection, b: LsaElection) => {
        const conditions = sortingOrder === SortOrder.ASC
            ? [
                (p1: any, p2: any) => p1 > p2,
                (p1: any, p2: any) => p1 < p2
            ]
            : [
                (p1: any, p2: any) => p1 < p2,
                (p1: any, p2: any) => p1 > p2
            ]

        if (sortingField === 'contactPerson') {
            if (!a[sortingField] && b[sortingField]) {
                return 1
            }
            if (!b[sortingField] && a[sortingField]) {
                return -1
            }
        }

        if (sortingField !== 'sortBy') {
            if (conditions[0](a[sortingField], b[sortingField])) {
                return 1
            }
            if (conditions[1](a[sortingField], b[sortingField])) {
                return -1
            }
        }

        if (sortingField !== 'localityName') {
            const field = 'localityName'
            if (a[field] > b[field]) {
                return 1
            }
            if (a[field] < b[field]) {
                return -1
            }
        }

        return 0
    })

    if (filter.localityName) {
        const names = filter.localityName!.trim().toLowerCase().split(' ').filter(n => !!n)
        names.forEach(name => {
            elections = elections.filter(e => e.localityName.toLowerCase().includes(name))
        })
    }

    if (filter.localityCode) {
        const code = filter.localityCode!.trim().toLowerCase()
        elections = elections.filter(e => e.localityCode.toLowerCase().includes(code))
    }

    if (filter.contactPerson) {
        const names = filter.contactPerson!.trim().toLowerCase().split(' ').filter(n => !!n)
        names.forEach(name => {
            const isId = !isNaN(parseInt(name, 10))
            if (isId) {
                const id = name
                elections = elections.filter(e => e.contactPersonIdn?.includes(id))
            } else {
                elections = elections.filter(e => e.contactPerson?.toLowerCase().includes(name))
            }
        })
    }

    if (filter.electionStatus.length) {
        elections = elections.filter(e => filter.electionStatus.includes(e.electionStatus))
    }

    if (filter.electionPushStatus.length) {
        elections = elections.filter(e => filter.electionPushStatus.includes(e.electionPushStatus))
    }

    return elections
}