import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useElectionResultCandidates } from '../api'
import ElectionResults from '../components/ElectionResults'

function ViewElectionResults() {
    const { electionId } = useParams()

    const [candidates, setCandidates] = useState<any[]>([])

    const { data: electedPersons } = useElectionResultCandidates(electionId || '', !!electionId)

    useEffect(() => {
        if (electedPersons) {
            setCandidates(electedPersons.map(p => ({
                id: p.id,
                name: p.name,
                votes: p.voteCount
            })).sort((a,b)=>{
                if (a.votes !== b.votes) {
                    return b.votes - a.votes
                }

                return a.name.localeCompare(b.name) || a.id.localeCompare(b.id)
            }))
        }
    }, [electedPersons])

    return (
        <>
            {!!electionId && <ElectionResults electionId={electionId} candidates={candidates} />}
        </>
    )
}

export default ViewElectionResults