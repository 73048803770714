import Tabs from 'components/atoms/Tabs'
import { useConfirmPopup, useHeaderLogoRedirect } from 'hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { electionConfig } from '../../features/electionConfig'
import { useAnyFormChanged } from '../../hooks'
import AnyFormChangedProvider from '../../provider/AnyFormChangedContextProvider'
import EmailRecipients from '../EmailRecipients'
import VoterDashboard from '../VoterDashboard'
import style from './index.module.scss'

const STORAGE_KEY = 'admin_config_tab'

function ConfigurationContent() {
    const { t } = useTranslation('configuration')
    const { confirmPortal, showConfirm } = useConfirmPopup()
    const [activeTab, setActiveTab] = useState(localStorage.getItem(STORAGE_KEY) || 'voter-dashboard')
    const { isAnyFormChanged, setIsAnyFormChanged } = useAnyFormChanged()
    const { setConfirmationCallback } = useHeaderLogoRedirect()

    const tabs = useMemo(() => [
        {
            key: 'voter-dashboard',
            label: t('voter_dashboard'),
            children: <VoterDashboard />
        },
        {
            key: 'election-config',
            label: t('election_configuration'),
            children: <electionConfig.component />
        },
        {
            key: 'email-recipients',
            label: t('email_recipients'),
            children: <EmailRecipients />
        },
        {
            key: 'timezones',
            label: t('system_timezones'),
            children: 'Coming Soon...'
        }
    ], [])

    useEffect(() => {
        if (isAnyFormChanged) {
            setConfirmationCallback(true)
        } else {
            setConfirmationCallback(false)
        }

        return () => {
            setConfirmationCallback(false)
        }
    }, [isAnyFormChanged, setConfirmationCallback])

    const showConfirmDialog = useCallback((callback: () => Promise<void>) => {
        showConfirm({
            text: <>{t('would_you_like_to_leave_this_page_without_saving')}</>,
            title: <>{t('leave_current_page')}</>,
            okText: <>{t('leave')}</>,
            cancelText: <>{t('continue_editing')}</>,
            onOk: callback
        })
    }, [showConfirm, t])

    const changeTab = (newTab: string) => {
        setActiveTab(newTab)
        localStorage.setItem(STORAGE_KEY, newTab)
        setIsAnyFormChanged(false)
    }

    const handleTabChange = useCallback((newTab: string) => {
        if (newTab !== activeTab && isAnyFormChanged) {
            showConfirmDialog(async () => {
                changeTab(newTab)
                setIsAnyFormChanged(false)
            })
        } else {
            changeTab(newTab)
        }
    }, [activeTab, isAnyFormChanged, showConfirmDialog, changeTab])

    useEffect(() => {
        if (isAnyFormChanged) {
            const handleBeforeUnload = (e: BeforeUnloadEvent) => {
                e.preventDefault()
            }
            window.addEventListener('beforeunload', handleBeforeUnload)

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload)
            }
        }
    }, [isAnyFormChanged])


    return (
        <>
            {confirmPortal}
            <Tabs
                destroyInactiveTabPane
                activeKey={activeTab}
                onChange={handleTabChange}
                className={style.configTabs}
                items={tabs}
            />
        </>
    )
}

function Configuration() {
    return (
        <AnyFormChangedProvider>
            <ConfigurationContent />
        </AnyFormChangedProvider>
    )
}

export default Configuration