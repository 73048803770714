import { useElectionVerifiedVoters } from 'api'
import { VotingApproach } from 'models'
import { useEffect, useState } from 'react'

type StationAbsenteeVotersProps = {
    stationId: string,
    electionId: string
}


function StationAbsenteeVoters({ electionId, stationId }: StationAbsenteeVotersProps) {
    const { data: electionVerifiedVoters } = useElectionVerifiedVoters(electionId)

    const [stationAbsenteeVoters, setStationAbsenteeVoters] = useState(0)

    useEffect(() => {
        if (electionVerifiedVoters?.verification?.length) {
            const absentee = electionVerifiedVoters.verification
                .filter(v => v.verifier === stationId && v.votingApproach === VotingApproach.IN_ABSENCE).length
            setStationAbsenteeVoters(absentee)
        } else {
            setStationAbsenteeVoters(0)
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [electionVerifiedVoters])

    return <>{stationAbsenteeVoters}</>
}

export default StationAbsenteeVoters