import { useCompletedPopup, useEndTrainingPopup } from './hooks'
import Dashboard from './pages/Dashboard'

export const tellingStation = {
    pages: {
        Dashboard
    },
    hooks: {
        useEndTrainingPopup,
        useCompletedPopup
    }
}