import { useHandleEntityLoadingError } from 'hooks'
import { ElectionParticipant, ElectionType } from 'models'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useOnlineVotingElection } from '../../api/queries/onlineVotingElection'
import BallotingStepWrapper from '../BallotingStepWrapper'
import EnterOfVotes from '../EnterOfVotes'
import OfficersElection from '../OfficersElection'
import SubmitBallot from '../SubmitBallot'
import ThankYou from '../ThankYou'

interface BallotingStepsManagementProps {
    currentStep?: Steps
}

export enum Steps {
    EnterVotes = 1,
    SubmitBallot,
    OfficersElection,
    ThankYou
}

type SelectedPeopleContextType = {
    selectedPeople: ElectionParticipant[],
    setSelectedPeople: React.Dispatch<React.SetStateAction<ElectionParticipant[]>>
}

export const SelectedPeopleContext = React.createContext<SelectedPeopleContextType | undefined>(undefined)

export const BallotingContext = React.createContext({
    currentStep: Steps.EnterVotes as Steps | undefined,
    setCurrentStep: (step: Steps) => { }
})


export const FocusHeaderContext = React.createContext<React.RefObject<HTMLDivElement> | null>(null)
export const BallotingStepsManagement: React.FC<BallotingStepsManagementProps> = () => {

    const { electionId } = useParams<{ electionId: string }>()
    const { data: election, error } = useOnlineVotingElection(electionId!, !!electionId)
    const [currentStep, setCurrentStep] = useState<Steps | undefined>(undefined)
    const [selectedPeople, setSelectedPeople] = useState<ElectionParticipant[]>([])
    const [shouldFocusHeader, setShouldFocusHeader] = useState(false)
    const focusHeaderRef = React.useRef<HTMLDivElement>(null)
    const { handleEntityLoadingError } = useHandleEntityLoadingError()

    useEffect(() => {
        election?.electionType === ElectionType.OFFICERS_ELECTION ?
            setCurrentStep(Steps.OfficersElection) :
            setCurrentStep(Steps.EnterVotes)

    }, [election])

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const renderStepComponent = (): React.ReactElement | null => {
        if (election) {
            switch (currentStep) {
                case Steps.EnterVotes:
                    return (
                        <BallotingStepWrapper
                            election={election}>
                            <EnterOfVotes election={election} />
                        </BallotingStepWrapper>
                    )
                case Steps.SubmitBallot:
                    return (
                        <BallotingStepWrapper
                            election={election}

                        >
                            <SubmitBallot
                                election={election}
                                setShouldFocusHeader={setShouldFocusHeader}
                            />
                        </BallotingStepWrapper>
                    )
                case Steps.ThankYou:
                    return (
                        <FocusHeaderContext.Provider value={focusHeaderRef}>
                            <ThankYou
                                election={election}
                                shouldFocusHeader={shouldFocusHeader}
                                setShouldFocusHeader={setShouldFocusHeader}
                            />
                        </FocusHeaderContext.Provider>
                    )
                case Steps.OfficersElection:
                    return (
                        <BallotingStepWrapper
                            election={election}
                        >
                            <OfficersElection
                                election={election}
                                setShouldFocusHeader={setShouldFocusHeader}
                            />
                        </BallotingStepWrapper>
                    )
            }
        }

        return null
    }

    return (
        <SelectedPeopleContext.Provider value={{ selectedPeople, setSelectedPeople }}>
            <BallotingContext.Provider value={{ currentStep, setCurrentStep }}>
                {renderStepComponent()}
            </BallotingContext.Provider>
        </SelectedPeopleContext.Provider>
    )
}

export default BallotingStepsManagement
