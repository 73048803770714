import { Radio as AntRadio, Select as AntSelect, Col, Row, Space } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import { useElection } from 'api'
import DatePicker from 'components/atoms/DatePicker'
import PopupFooter from 'components/atoms/PopupFooter'
import Radio from 'components/atoms/Radio'
import RequiredMark from 'components/atoms/RequiredMark'
import { Select } from 'components/atoms/Select'
import Note from 'components/molecules/Note'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useClarifyMeetingStartTime, useConfirmResults } from '../../../../api'
import { useConfirmation } from '../../hooks'
import style from './index.module.scss'


const hoursText: string[] = []
const hoursValues: string[] = []

for (let hour = 7; hour <= 23; hour++) {
    hoursText.push(dayjs('1970-01-01').hour(hour).minute(0).format('hh:mm A'))
    hoursValues.push(dayjs('1970-01-01').hour(hour).minute(0).format('HH:mm'))

    hoursText.push(dayjs('1970-01-01').hour(hour).minute(30).format('hh:mm A'))
    hoursValues.push(dayjs('1970-01-01').hour(hour).minute(30).format('HH:mm'))
} 

type ConfirmResultsAndTransmitProps = {
    hidePopup: () => void,
    onConfirm: () => void
}

function ConfirmResultsAndTransmit({
    hidePopup,
    onConfirm
}: ConfirmResultsAndTransmitProps) {
    const { t } = useTranslation('results')
    const { electionId } = useParams()
    const { data: election } = useElection(electionId!, !!electionId)
    const { candidatesToConfirm } = useConfirmation()
    const { mutateAsync: confirmResults } = useConfirmResults()
    const { mutateAsync: clarifyMeetingStartTime } = useClarifyMeetingStartTime()
    const [contactPerson, setContactPerson] = useState<string | undefined>()
    const [showNoContactPersonWarning, setShowNoContactPersonWarning] = useState(false)
    const [meetingDate, setMeetingDate] = useState()
    const [meetingStartTime, setMeetingStartTime] = useState<string | undefined>()
    const [noMeetingStartTimeWarning, setNoMeetingStartTimeWarning] = useState(false)
    const [noMeetingDateWarning, setNoMeetingDateWarning] = useState(false)

    useEffect(() => {
        if (!!contactPerson) {
            setShowNoContactPersonWarning(false)
        }
    }, [contactPerson])

    useEffect(() => {
        if (meetingStartTime) {
            setNoMeetingStartTimeWarning(false)
        }
    }, [meetingStartTime])

    useEffect(() => {
        if (meetingDate) {
            setNoMeetingDateWarning(false)
        }
    }, [meetingDate])

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        const electionDay = dayjs(election?.electionEnd || '')
        const isInPast = current && current.endOf('day').diff(dayjs().endOf('day'), 'days') < 1
        
        return current && (current < electionDay || !isInPast)
      }

    return (<>
        <div className={style.noContactPerson}>
            {showNoContactPersonWarning &&
                <Note icon="filled" mode="error">
                    {t('common:please_select_the_input', { input: t('temporary_contact_person') })}
                </Note>
            }
        </div>
        <div className={style.title}>
            <Trans i18nKey={t(
                'please_confirm_the_elected_members_and_select_one_as_the_person',
                {
                    interpolation: { escapeValue: false },
                    person: `<strong>${t('temporary_contact_person')}</strong>`
                })} components={[<strong />]} />
            <strong>.</strong>  {t(
                'enter_the_time_of_your_annual_meeting_and_click_button_to_electronically_transmit_the_results_to_dest',
                {
                    interpolation: { escapeValue: false },
                    button: t('confirm_and_send'),
                    dest: t('common:eMembership2')
                })}
        </div>
        <AntRadio.Group value={contactPerson} onChange={(e) => setContactPerson(e.target.value)}>
            <Space direction="vertical">
                {candidatesToConfirm
                    .sort((a, b) => b.voteCount - a.voteCount || a.name.localeCompare(b.name))
                    .map(c =>
                        <Radio value={c.id} key={c.id} className={style.delegateOption}  >
                            <div className={style.delegate}>
                                <OptionalTooltip contenWrapperClassName="ellipsis">
                                    {c.name}
                                </OptionalTooltip>
                            </div>
                        </Radio>)}
            </Space>
        </AntRadio.Group>
        <Row gutter={24} >
            <Col span={12}>
            <div className={`${style.inputLabel} ${noMeetingStartTimeWarning ? style.warning : ''}`}>
                    {t('date_of_your_annual_meeting')}
                    <RequiredMark />
                </div>
                <DatePicker
                    value={meetingDate}
                    onChange={(e) => {
                        setMeetingDate(e as any)
                    }}
                    placeholder={t('common:select_input', { input: t('common:date') })}
                    inputReadOnly={true}
                    format="MM/DD/YYYY"
                    disabledDate={disabledDate}
                    className={`${style.select} ${noMeetingDateWarning ? 'ant-picker-status-error' : ''} 'obs'`} /> 
                    {noMeetingDateWarning &&
                    <div className={style.validation}>
                        {t('common:please_select_the_input', { input: t('date_of_your_annual_meeting') })}
                    </div>
                }
            </Col>
            <Col span={12}>
                <div className={`${style.inputLabel} ${noMeetingStartTimeWarning ? style.warning : ''}`}>
                    {t('time_annual_meeting_started')}
                    <RequiredMark />
                </div>
                <Select
                    value={meetingStartTime}
                    onChange={(e) => {
                        setMeetingStartTime(e as any)
                    }}
                    placeholder={t('common:select_the_input', { input: t('common:time') })}
                    className={`${style.select} ${noMeetingStartTimeWarning ? 'ant-select-status-error' : ''}`}
                >
                    {
                        hoursValues.map((v, index) => (
                            <AntSelect.Option value={v} key={v} >
                                {hoursText[index]}
                            </AntSelect.Option>
                        ))
                    }
                </Select >
                {noMeetingStartTimeWarning &&
                    <div className={style.validation}>
                        {t('common:please_select_the_input', { input: t('time_annual_meeting_started') })}
                    </div>
                }
            </Col>

        </Row>
        <div className={style.note}>
            <Note icon="regular" mode="info">
                {t('confirming_and_sending_the_results_will_automatically_complete_the_election')}
            </Note>
        </div>
        <PopupFooter>
            <button className="btn-main-secondary"
                onClick={hidePopup}>
                {t('common:cancel')}
            </button>
            <button
                type="submit"
                onClick={async () => {
                    let canConfirm = true
                    if (!contactPerson) {
                        window.scrollTo({ top: 0, left: 0 })
                        setShowNoContactPersonWarning(true)
                        canConfirm = false
                    }
                    if(!meetingDate){
                        setNoMeetingDateWarning(true)
                        canConfirm = false
                    }
                    if (!meetingStartTime) {
                        setNoMeetingStartTimeWarning(true)
                        canConfirm = false
                    }
                    if (canConfirm) {
                        await clarifyMeetingStartTime({
                            electionId: election?.id.toString() || '',
                            time: meetingStartTime!,
                            timeZone: election?.timeZone || '',
                            date: dayjs(meetingDate!).format('YYYY-MM-DD')
                        })
                        await confirmResults({
                            electionId: election?.id.toString() || '',
                            confirmedCandidatesIds: candidatesToConfirm.map(c => c.id),
                            contactPersonId: contactPerson!
                        })
                        onConfirm()
                    }
                }
                }
                className={'btn-main-primary'}>
                {t('confirm_and_send')}
            </button>
        </PopupFooter>
    </>
    )
}

export default ConfirmResultsAndTransmit