import IconWrapper from 'components/atoms/IconWrapper'
import PopupTitle from 'components/atoms/PopupTitle'
import { useAuth } from 'hooks'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type CountVerificationPopupTitleProps = {
    hide: () => void,
    banner?: ReactNode
}

function CountVerificationPopupTitle({ hide, banner }: CountVerificationPopupTitleProps) {
    const { t } = useTranslation('telling-station')
    const { auth } = useAuth()

    return (
        <div className={style.stickyTitle}>
            <PopupTitle>
                <div className={style.wrapper}>
                    <div className={style.titles}>
                        {t('paper_ballot_count_verification')}
                        <div className={style.popupSubtitle}>{t('telling_station_number',
                            { number: auth?.stationId?.replace('S', '') })}</div>
                    </div>
                    <button
                        className={`btn-main-tertiary-md ${style.dismiss}`}
                        type="button"
                        onClick={hide}>
                        <IconWrapper><Dismiss width="16" height="16" /></IconWrapper>
                    </button>
                </div>
            </PopupTitle >
            {!!banner && <div className={style.bannerWrapper}>{banner}</div>}
            <div className={style.popupServiceText}>
                {t('please_enter_verify_and_submit')}
            </div>
        </div>
    )
}

export default CountVerificationPopupTitle