import { PageHeaderContext } from 'context'
import { PropsWithChildren, ReactNode, useState } from 'react'

function PageHeaderProvider({ children }: PropsWithChildren) {
    const [pageHeader, setPageHeader] = useState<ReactNode>(null)

    return (
        <PageHeaderContext.Provider value={{ pageHeader, setPageHeader }} >
            {children}
        </PageHeaderContext.Provider>
    )
}

export default PageHeaderProvider