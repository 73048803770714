import { PropsWithChildren, useState } from 'react'
import { LsaElectionsContext } from '../context'
import { LsaElectionsData } from '../model'

function LsaElectionsProvider({ children }: PropsWithChildren) {
    const [lsaElectionsData, setLsaElectionsData] = useState<LsaElectionsData>({
        summary: {} as any
    } as any)

    return (
        <LsaElectionsContext.Provider value={{ lsaElectionsData, setLsaElectionsData }}>
            {children}
        </LsaElectionsContext.Provider >
    )
}

export default LsaElectionsProvider