import { usePlanNationalDelegateElectionPeriod } from './api'
import NationalDelegateEditing from './pages/NationalDelegateEditing'

export const nationalDelegate = {
    api: {
        mutations: {
            usePlanNationalDelegateElectionPeriod
        }
    },
    pages: {
        NationalDelegateEditing
    }
}