import { Select as AntSelect, Skeleton } from 'antd'
import { Select } from 'components/atoms/Select'
import { useAntSelectClearButtonAttributes } from 'hooks'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import { ReactComponent as Loupe } from 'icons/loupe.svg'
import { ReactComponent as Preloader } from 'icons/preloader.svg'
import { debounce } from 'lodash'
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OptionalTooltip from '../OptionalTooltip'
import style from './index.module.scss'

const { Option } = AntSelect

const DEBOUNCE_TIME_MS = 500
const MIN_FILTER_LENGTH = 1
const MAX_FILTER_LENGTH = 100

export interface LookupOption {
    id: string | number,
    name: string
}

type LookupSelectProps<T extends LookupOption> =
    Omit<React.ComponentProps<typeof Select>, 'onChange' | 'options'> & {
        onChange?: (value?: T) => void,
        onSearchChange: (value?: string) => void,
        onBeforeClear?: (clearFunction: () => void) => void,
        options?: T[],
        alreadySelectedOptions?: T[],
        isFetchingOptions: boolean,
        autoClear?: boolean,
        popupWidth?: number,
        autoSelectOnFirstDataReceive?: boolean,
        isClearedAllFields?: boolean,
        onClear?: () => void,
        minFilterLengthToSearch?: number,
        optionRenderFunction?: (option: T) => ReactNode,
        noOptionalTooltip?: boolean,
        customNotFoundContent?: ReactNode,
        searchEnabled?: boolean,
        autoselectOptionId?: string,
        allowPaste?: boolean,
        autoFocus?: boolean
    }

function LookupSelect<T extends LookupOption>({
    onChange,
    onBeforeClear,
    options,
    alreadySelectedOptions,
    isFetchingOptions,
    autoClear,
    popupWidth,
    onSearchChange,
    autoSelectOnFirstDataReceive,
    minFilterLengthToSearch,
    isClearedAllFields,
    onClear = () => { },
    optionRenderFunction,
    noOptionalTooltip,
    customNotFoundContent,
    searchEnabled = true,
    autoselectOptionId,
    allowPaste,
    autoFocus,
    ...restProps }: LookupSelectProps<T>) {

    const { t } = useTranslation()
    const pleaseEnterCharacter = t('please_enter_character_or_more_characters', { count: MIN_FILTER_LENGTH })
    const noResultsFound = t('no_results_found')
    const [selectedOption, setSelectedOption] = useState<T | undefined>(undefined)
    const [inputValue, setInputValue] = useState<string>('')
    const selectRef = useRef<HTMLSelectElement | null>(null)
    const [needToAutoSelect, setNeedToAutoSelect] = useState(autoSelectOnFirstDataReceive || false)
    const [isHovered, setIsHovered] = useState(false)

    const isSearchActive = useMemo(() => inputValue?.trim ? inputValue.trim().length >= MIN_FILTER_LENGTH : false,
        [inputValue])

    useAntSelectClearButtonAttributes()

    const handleSearchInput = (value: string) => {
        const trimmedValue = value.substring(0, MAX_FILTER_LENGTH)
        setInputValue(trimmedValue)
        onNameChange(trimmedValue)
    }

    const notFoundContent = selectedOption || !searchEnabled
        ? null
        : isFetchingOptions
            ? <div className={style.empty}><Skeleton.Input active size="large" block={true} /></div>
            : !isSearchActive
                ? <div tabIndex={0} role="contentinfo" aria-label={pleaseEnterCharacter}>
                    {pleaseEnterCharacter}
                </div>
                : <div tabIndex={0} role="contentinfo" aria-label={noResultsFound}>
                    {noResultsFound}
                </div>

    useEffect(() => {
        if (options && needToAutoSelect) {
            onSelectChange(autoselectOptionId || options[0].id)
            setNeedToAutoSelect(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, autoselectOptionId])

    useEffect(() => {
        if (autoFocus && selectRef.current) {
            selectRef.current.focus()
        }
    }, [selectRef, autoFocus])

    const onSelectChange = (id: any) => {
        if (onChange) {
            if (id) {
                const option = options?.find(p => p.id === id)
                setSelectedOption(option)
                onChange(option)
            } else {
                setSelectedOption(undefined)
                onChange()
            }
        }
    }

    const isAlreadySelected = (option: T) => {
        if (!alreadySelectedOptions?.length) {
            return false
        }

        if (option.id === selectedOption?.id) {
            return false
        }

        if (alreadySelectedOptions.find(o => o.id === option.id)) {
            return true
        }

        return false
    }

    const handleClear = useCallback(() => {
        setSelectedOption(undefined)
        setInputValue('')

        if (onChange) {
            onChange(undefined)
        }

        selectRef.current?.focus()
        onClear()
    }, [onChange, setSelectedOption, onClear])

    const handlePaste = (event: React.ClipboardEvent) => {
        if (!allowPaste) {
            event.preventDefault()
        }
    }

    useEffect(() => {
        if (autoClear) {
            handleClear()
        }

        if (isClearedAllFields) {
            selectRef.current?.blur()
        }
    }, [autoClear, handleClear, isClearedAllFields])

    const onNameChange = useMemo(
        () =>
            debounce((value: string) => {
                if (!value?.length ||
                    value?.length >= (minFilterLengthToSearch ? minFilterLengthToSearch : MIN_FILTER_LENGTH)) {
                    onSearchChange(value)
                }
                setInputValue(value)
            }, DEBOUNCE_TIME_MS),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    useEffect(() => () => {
        onNameChange.cancel()
    }, [onNameChange])

    const suffixIcon = useMemo(() => {
        if (isFetchingOptions) {
            return <Preloader className="obs-spin" width="100%" height="100%" />
        }

        if (!searchEnabled) {
            if (selectedOption && isHovered) {
                return <Dismiss className="rotate-90" width="100%" height="100%" />
            }

            return <Chevron className="rotate-90" width="100%" height="100%" />
        }

        return selectedOption || inputValue
            ? <Dismiss className="rotate-90" width="100%" height="100%" />
            : <Loupe />
    }, [isFetchingOptions, searchEnabled, selectedOption, inputValue, isHovered])

    const handleClearKeyDown = (event: React.KeyboardEvent<SVGElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault()
            if (onBeforeClear) {
                onBeforeClear(handleClear)
            } else {
                handleClear()
            }
        }
    }

    const handleClearData = (e: any) => {
        e.stopPropagation()
        if (onBeforeClear) {
            onBeforeClear(handleClear)
        } else {
            handleClear()
        }
    }

    return (
        <Select
            {...restProps}
            className={`${restProps.className || ''} lookup`}
            getPopupContainer={node => {
                node.onpaste = handlePaste

                return node
            }}
            searchValue={searchEnabled ? inputValue : undefined}
            aria-label={selectedOption?.name}
            ref={selectRef}
            showSearch={searchEnabled && !selectedOption}
            notFoundContent={customNotFoundContent === undefined ? notFoundContent : customNotFoundContent}
            value={selectedOption ?
                {
                    label: <OptionalTooltip contenWrapperClassName="ellipsis">{selectedOption.name}
                    </OptionalTooltip>, value: selectedOption.id
                } : undefined}
            filterOption={false}
            onSearch={searchEnabled && !selectedOption ? handleSearchInput : undefined}
            popupMatchSelectWidth={popupWidth || true}
            onChange={onSelectChange}
            allowClear={
                {
                    clearIcon: isFetchingOptions && !(selectedOption || inputValue) ?
                        <Preloader className="obs-spin" width="100%" height="100%" />
                        : <Dismiss
                            style={{
                                position: 'relative', margin: '-5px', width: 'var(--triple-space)',
                                height: 'var(--triple-space)'
                            }}
                            role="button"
                            onKeyDown={handleClearKeyDown}
                            onMouseDown={handleClearData}
                            tabIndex={0}
                            aria-label={t('delete')}
                            className={`${style.clearIcon} rotate-90`}
                            width="100%"
                            height="100%" />
                }
            }
            loading={isFetchingOptions}
            onClear={handleClear}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            suffixIcon={suffixIcon}
        >
            {(!searchEnabled || !selectedOption) && options?.map(option =>
                <Option role="option" aria-label={option.name} className={style.option}
                    value={option.id} label={option.name} key={option.id}>
                    {noOptionalTooltip
                        ? <div>{optionRenderFunction ? optionRenderFunction(option) : option.name}</div>
                        : <OptionalTooltip contenWrapperClassName="ellipsis">
                            {optionRenderFunction ? optionRenderFunction(option) : option.name}
                        </OptionalTooltip>}
                    {isAlreadySelected(option) &&
                        <div className={style.alreadySelected}>{t('already_selected')}</div>}
                </Option>
            )}
        </Select>
    )
}

export default LookupSelect