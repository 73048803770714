import { administration } from 'features/administration'
import { electionManagement } from 'features/electionManagement'
import { electionProcessing } from 'features/electionProcessing'
import { officersElection } from 'features/officersElection'
import { onlineVoting } from 'features/onlineVoting'
import { tellingStation } from 'features/tellingStation'
import { useAuth } from 'hooks'
import { Role } from 'models'
import { useNavigate } from 'react-router-dom'

function DefaultRouter() {
    const { auth } = useAuth()
    const navigate = useNavigate()
    if (!auth?.role) {
        navigate('/sign-in', { replace: true })
    }
    switch (auth!.role) {
        case Role.SYSTEM_ADMIN:
        case Role.NSA_ADMIN:
            return <administration.pages.Dashboard />
        case Role.LSA_OFFICER:
            return <electionManagement.pages.Dashboard />
        case Role.VOTER:
            return <onlineVoting.pages.Dashboard />
        case Role.TELLING_STATION:
            return <tellingStation.pages.Dashboard />
        case Role.LSA_TELLER:
            return <officersElection.dashboard.pages.Dashboard />
        default:
            return <electionProcessing.electionProcessingBoard.pages.Dashboard />
    }
}

export default DefaultRouter