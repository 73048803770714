export enum ElectionStatusType {
    DRAFT = 'DRAFT',
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CONFIRMED = 'CONFIRMED',
    CANCELED = 'CANCELED',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    STOPED = 'STOPED',
    NOT_CREATED = 'NOT_CREATED'
}