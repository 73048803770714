import { ReactNode } from 'react'
import VoterVerificationTableDataProvider from '../../providers/VoterVerificationTableDataProvider'
import VoterVerificationTable from '../VoterVerificationTable'

type VoterVerificationProps = {
    tutorial?: ReactNode
    tableHeight?: string
    tableColHighlightingHeight?: string
}

function VoterVerification({
    tutorial,
    tableHeight,
    tableColHighlightingHeight
}: VoterVerificationProps) {
    return <VoterVerificationTableDataProvider>
        <VoterVerificationTable
            tutorial={tutorial}
            height={tableHeight}
            colHighlightingHeight={tableColHighlightingHeight} />
    </VoterVerificationTableDataProvider>
}

export default VoterVerification
