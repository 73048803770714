import { useElection } from 'api'
import PopupTitle from 'components/atoms/PopupTitle'
import { PopupProps } from 'components/molecules/Popup'
import { useAuth } from 'hooks'
import { Ballot } from 'models'
import { useTranslation } from 'react-i18next'
import BallotEntryView, { BallotEntryViewProps } from '../components/BallotEntryView'
import { getValidVotesCount  } from '../utils'

export const useBallotEntryViewPopupProps = () => {
    const { t } = useTranslation('ballot')
    const { auth } = useAuth()
    const { data: election } = useElection(auth?.electionId!)

    const getPopupTitle = (ballot: Ballot, style: any) => {
        if (ballot.deleted) {
            return <PopupTitle withHeaderDivider>
                <div className={style.singlePoputTitle}>{`${t('deleted_ballot')} ${ballot.id}`}</div>
            </PopupTitle >
        }

        if (ballot.invalidationReason) {
            return <PopupTitle withHeaderDivider>
                <div className={style.singlePoputTitle}>{`${t('ballot_is_invalid')} ${ballot.id}`}</div>
            </PopupTitle >
        }

        return <PopupTitle withHeaderDivider>
            {`${t('ballot_details')} ${ballot.id}`}
            <div className={style.validVotesCounter}>
                {t('valid_votes') + ': '}
                {getValidVotesCount (ballot)}
                /{election?.numberOfVacancies}
            </div></PopupTitle >
    }

    const getPopupProps = (props: BallotEntryViewProps, style: any) => {
        const result: PopupProps = {
            closeIcon: true,
            title: getPopupTitle(props.ballot, style),
            width: '660px',
            children: <BallotEntryView {...props} />,
            footer: null
        }

        return result
    }

    return { getPopupProps }
}