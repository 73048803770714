import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'

export const useCandidatesPhysicalVotes = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getCandidatesPhysicalVotes = async ({ queryKey }: QueryFunctionContext) => {
        const [, electionId] = queryKey

        const res =
            await axios<{ candidate: string, voteCount: number }[]>(
                `${moduleUrl}draft/elections/${electionId}/election-results/break-tie`)

        return res.data
    }

    return useQuery({
        queryKey: ['candidates-physical-votes', electionId],
        queryFn: getCandidatesPhysicalVotes,
        enabled: !!electionId && enabled
    })
}