import DefineDashboardComponent from '../components/DefineDashboardComponent'
import TellingStationProvider from '../providers/TellingStationProvider'

function Dashboard() {
    return (
        <TellingStationProvider>
            <DefineDashboardComponent />
        </TellingStationProvider>
    )
}

export default Dashboard