import DashboardWrapper from '../components/DashboardWrapper'
import GreetingCard from '../components/GreetingCard'

function SandboxDashboard() {

    return (
        <DashboardWrapper>
            <GreetingCard />
        </DashboardWrapper>
    )
}

export default SandboxDashboard