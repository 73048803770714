import TieResolvedInfo from './components/TieResolvedInfo'
import { reportingAssets } from './features/reportingAssets'
import { MainElectionReport } from './pages/MainElectionReport'
import { Reporting } from './pages/Reporting'
import ViewStationReport from './pages/ViewStationReport'

export const reporting = {
    components: {
        TieResolvedInfo
    },
    pages: {
        ViewStationReport,
        MainElectionReport,
        Reporting
    },
    features: {
        reportingAssets
    }
}