import Badge, { BadgeType } from 'components/atoms/Badge'
import { useElectionStatusMap } from 'hooks'
import { ElectionStatusType } from 'models'


type ElectionBadgeProps = {
    status: ElectionStatusType
}

const ElectionBadge = ({ status }: ElectionBadgeProps) => {
    const { toString } = useElectionStatusMap()

    let type = BadgeType.Preinitial
    switch (status) {
        case ElectionStatusType.NOT_STARTED:
        case ElectionStatusType.NOT_CREATED:
            type = BadgeType.Initial
            break
        case ElectionStatusType.IN_PROGRESS:
        case ElectionStatusType.STOPED:
        case ElectionStatusType.PENDING_APPROVAL:
            type = BadgeType.Pending
            break
        case ElectionStatusType.COMPLETED:
        case ElectionStatusType.CONFIRMED:
            type = BadgeType.Success
            break
        case ElectionStatusType.CANCELED:
            type = BadgeType.Error
            break
    }

    return <Badge title={toString(ElectionStatusType[status])} type={type} />
}

export default ElectionBadge