import { ConfirmPopupSettings, InfoPopupSettings } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type SaveAsDraftParams = {
    showConfirm: (settings: ConfirmPopupSettings) => void,
    showInfo: (settings: InfoPopupSettings) => void,
    saveAsItIs: () => Promise<boolean>,
    nextStepButtonText?: string
}

export function useSaveElectionAsDraft() {
    const { t } = useTranslation('election')
    const navigate = useNavigate()

    const saveAsDraft = ({
        showConfirm,
        showInfo,
        saveAsItIs,
        nextStepButtonText
    }: SaveAsDraftParams) => {
        showConfirm({
            title: t('you_are_about_to_save_as_draft'),
            text:
                t('save_an_election_as_draft_confirmation_message',
                    {
                        nextStepButtonText: nextStepButtonText || t('next_step'),
                        interpolation: { escapeValue: false }
                    }),
            okText: t('save_as_draft'),
            cancelText: t('continue_editing'),
            onOk: async () => {
                if (await saveAsItIs()) {
                    showInfo({
                        title: t('saved_as_draft'),
                        text: t('you_have_not_completed_creating_the_new_election'),
                        onOk: () => navigate('/'),
                        stayOpenedOnCancel: true
                    })
                }
            }
        })
    }

    return { saveAsDraft }
}