import Card from 'components/atoms/Card'
import Tabs from 'components/atoms/Tabs'
import { ElectionType } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import LsaElectionsProvider from '../../provider/LsaElectionsProvider'
import Elections from '../Elections'
import LsaElectionsTitle from '../LsaElectionsTitle'
import style from './index.module.scss'

function LsaElections() {

    const { t } = useTranslation('administration')

    const tabs = useMemo(() => [
        {
            key: 'ridvan',
            label: t('ridvan'),
            children: <Elections electionType={ElectionType.RIDVAN_ELECTION} />
        },
        {
            key: 'by_election',
            label: t('election:by_election'),
            children: <Elections electionType={ElectionType.BY_ELECTION} />
        },
        {
            key: 'local_delegate',
            label: t('local_delegate'),
            children: <Elections electionType={ElectionType.LOCAL_DELEGATE_ELECTION} />
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])

    return (
        <LsaElectionsProvider>
            <Card
                title={<LsaElectionsTitle />}
                className={style.lsaElections}
                noContentPadding={true}
            >
                <Tabs
                    defaultActiveKey={localStorage.getItem('admin_dbd_lsa_tab') || undefined}
                    onChange={(tab) => localStorage.setItem('admin_dbd_lsa_tab', tab)}
                    className={style.lsaElectionsTabs}
                    destroyInactiveTabPane
                    rootClassName={style.tabs}
                    items={tabs}
                />
            </Card>
        </LsaElectionsProvider>
    )
}

export default LsaElections