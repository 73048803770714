import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import TieInputPhysicalBallots from '../TieInputPhysicalBallots'
import TieSubmitPhysicalBallots from '../TieSubmitPhysicalBallots'

const TIE_PHYSICAL_BALLOT_KEY = 'tie_physical_ballot_step_'

export enum PhysicalBallotsStep {
    InputBallots = 1,
    SubmitBallots
}

function TiePhysicalBallotsManagement() {
    const [currentStep, setCurrentStep] = useState<PhysicalBallotsStep>(PhysicalBallotsStep.InputBallots)
    const { electionId } = useParams()

    useEffect(() => {
        const stepStr = sessionStorage.getItem(`${TIE_PHYSICAL_BALLOT_KEY}${electionId}`)
        if (stepStr) {
            const step = parseInt(stepStr)
            if (!isNaN(step) && step === PhysicalBallotsStep.SubmitBallots) {
                setCurrentStep(PhysicalBallotsStep.SubmitBallots)
            }
        }
    }, [electionId])

    const endRevote = () => {
        setCurrentStep(PhysicalBallotsStep.SubmitBallots)
        sessionStorage.setItem(`${TIE_PHYSICAL_BALLOT_KEY}${electionId}`, PhysicalBallotsStep.SubmitBallots.toString())
    }

    const endEdit = () => {
        setCurrentStep(PhysicalBallotsStep.InputBallots)
        sessionStorage.setItem(`${TIE_PHYSICAL_BALLOT_KEY}${electionId}`, PhysicalBallotsStep.InputBallots.toString())
    }

    const renderStepComponent = () => {
        switch (currentStep) {
            case PhysicalBallotsStep.InputBallots:
                return <TieInputPhysicalBallots
                    onEndRevote={() => endRevote()}
                />
            case PhysicalBallotsStep.SubmitBallots:
                return <TieSubmitPhysicalBallots onEditClick={() => endEdit()} />
            default:
                return null
        }
    }

    return renderStepComponent()
}

export default TiePhysicalBallotsManagement
