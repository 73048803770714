import PageNotFound from 'components/organisms/PageNotFound'
import RoleBasedLayout from 'components/organisms/RoleBasedLayout'
import { administration } from 'features/administration'
import { auth } from 'features/auth'
import { ballotingAssets } from 'features/ballotingAssets'
import { electionEditing } from 'features/electionEditing'
import { electionProcessing } from 'features/electionProcessing'
import { electionResults } from 'features/electionResults'
import { officersElection } from 'features/officersElection'
import { onlineVoting } from 'features/onlineVoting'
import { reporting } from 'features/reporting'
import { tellingStation } from 'features/tellingStation'
import { Role } from 'models'
import { createBrowserRouter, redirect } from 'react-router-dom'
import RequireAuth from 'routing/RequireAuth'
import AdaptiveLayout from '../components/templates/AdaptiveLayout'
import Layout from '../components/templates/Layout'
import DefaultLayout from './DefaultLayout'
import DefaultRouter from './DefaultRouter'
import { errorHandlingRoutes } from './error-handling-routes'

export const router = createBrowserRouter([
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            {
                index: true,
                element: <DefaultRouter />
            }
        ]
    },
    //Avoid 404 when old urls are used
    { path: '/community/voter/dashboard', loader: () => redirect('/') },
    { path: '/community/login', loader: () => redirect('/') },
    { path: '/community/auth', loader: () => redirect('/') },
    { path: '/user/password', loader: () => redirect('/') },
    { path: '/user/login', loader: () => redirect('/') },
    { path: '/user/password/reset', loader: () => redirect('/') },
    { path: '/obs-database', loader: () => redirect('/') },
    { path: '/obs-database/*', loader: () => redirect('/') },
    { path: '/system/admin-user/login', loader: () => redirect('/') },
    {
        element: <AdaptiveLayout header={<></>} />,
        children: [
            {
                path: '/auth',
                element: <auth.pages.Auth />
            },
            {
                path: '/sign-in',
                element: <auth.pages.SignIn />
            },
            {
                path: '/system',
                element: <auth.pages.SaAuth />
            }
        ]
    },
    {
        element: <AdaptiveLayout header={<auth.components.Header />} />,
        children: [
            {
                path: '/function',
                element: <auth.pages.SelectFunction />
            }
        ]
    },
    errorHandlingRoutes,
    {
        element: <RequireAuth allowedRoles={[Role.VOTER]} />,
        children: [
            {
                element: <AdaptiveLayout />,
                children: [
                    {
                        path: '/balloting/:electionId',
                        element: <onlineVoting.pages.Balloting />
                    }
                ]
            }

        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.LSA_OFFICER]} />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/ridvan/:electionPeriodId',
                        element: <electionEditing.ridvan.pages.RidvanElectionEditing />
                    },
                    {
                        path: '/by/:electionPeriodId',
                        element: <electionEditing.byElection.pages.ByElectionEditing />
                    },
                    {
                        path: '/national-delegate/:electionPeriodId',
                        element: <electionEditing.nationalDelegate.pages.NationalDelegateEditing />
                    },
                    {
                        path: '/officers-election',
                        element: <officersElection.manage.pages.Manage />
                    }
                ]
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.LSA_OFFICER, Role.NSA_ADMIN, Role.SYSTEM_ADMIN]} />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/election-board/:electionId',
                        element: <electionProcessing.electionProcessingBoard.pages.ElectionBoard />
                    },
                    {
                        path: '/confirm-results/:electionId',
                        element: <electionResults.pages.ConfirmResults />
                    },
                    {
                        path: '/local-delegate-election-board/:electionPeriodId',
                        element: <electionEditing.ridvan.pages.LocalDelegateElectionBoard />
                    }
                ]
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.CHIEF_TELLER_ASSISTANT, Role.CHIEF_TELLER]} />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/merge-results',
                        element: <electionResults.pages.MergeResults />
                    },
                    {
                        path: '/report/:electionId',
                        element: <reporting.pages.MainElectionReport />
                    },
                    {
                        path: '/tie-physical-ballots/:electionId',
                        element: <electionResults.pages.TiePhysicalBallots />
                    },
                    {
                        path: '/feedback',
                        element: <electionProcessing.pages.LeaveFeedback />
                    }
                ]
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.CHIEF_TELLER_ASSISTANT,
        Role.CHIEF_TELLER,
        Role.LSA_OFFICER,
        Role.NSA_ADMIN,
        Role.SYSTEM_ADMIN]} />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/merged-results/:electionId?',
                        element: <electionResults.pages.MergedResults />
                    },
                    {
                        path: '/voter-verification/:electionId?',
                        element: <tellingStation.pages.ViewVoterVerification />
                    },
                    {
                        path: '/confirmed-results/:electionId',
                        element: <electionResults.pages.ConfirmedResults />
                    },
                    {
                        path: '/eligible-individuals/:electionId?',
                        element: <electionProcessing.eligibleIndividuals.pages.EligibleIndividuals />
                    },
                    {
                        path: '/election-team/:electionId',
                        element: <electionEditing.pages.ElectionTeam />
                    },
                    {
                        path: '/election-stations/:electionId',
                        element: <electionProcessing.stationsManagement.pages.SetUpStations />
                    },
                    {
                        path: '/reports/:electionId',
                        element: <reporting.pages.Reporting />
                    },
                    {
                        path: '/station-report/:stationId/:electionId',
                        element: <reporting.pages.ViewStationReport />
                    },
                    {
                        path: '/tie-round-info/:electionId/:roundId',
                        element: <electionResults.pages.RoundResultsInfo />
                    },
                    {
                        path: '/voting-statistic/:electionId',
                        element: <electionProcessing.votingStatistic.pages.VotingStatistic />
                    }
                ]
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.CHIEF_TELLER_ASSISTANT, Role.CHIEF_TELLER, Role.TELLING_STATION]} />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/ballot/:ballotId',
                        element: <ballotingAssets.pages.Ballot />
                    }
                ]
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.LSA_TELLER]} />,
        children: [
            {
                element: <AdaptiveLayout />,
                children: [
                    {
                        path: '/officers-election-session',
                        element: <officersElection.votingSession.pages.OfficersElection />
                    }
                ]
            },
            {
                element: <AdaptiveLayout />,
                children: [
                    {
                        path: '/election-results/:electionId',
                        element: <officersElection.pages.ViewElectionResults />
                    }
                ]
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.SYSTEM_ADMIN]} />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/config',
                        element: <administration.features.configuration.components.Configuration />
                    }
                ]
            }
        ]
    },
    {
        path: '*',
        element:
            <RoleBasedLayout>
                <PageNotFound />
            </RoleBasedLayout>,
        children: [
            {
                path: '*',
                element: <PageNotFound />
            }
        ]
    }
])