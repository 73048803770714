import { useAccessToken, useChangeUser } from 'hooks'
import { Role } from 'models'
import { useAuthStationUser, useLaunchStation } from '../api'

type LaunchStationSilentlyProps = {
    electionId: string
    stationId: string
    onLaunch?: () => void
}

export const useStationLauncher = () => {
    const { mutateAsync: launchStationMutation } = useLaunchStation()
    const { mutateAsync: authStationUser } = useAuthStationUser()
    const { setAccessToken } = useAccessToken()
    const { changeRole } = useChangeUser()

    const launchStation = async ({ electionId, stationId, onLaunch }: LaunchStationSilentlyProps) => {
        await launchStationMutation({ electionId, stationId })
        const stationTokens = await authStationUser({ electionId, stationId })

        setAccessToken(stationTokens.accessToken)
        changeRole(Role.TELLING_STATION, electionId, stationId)

        onLaunch?.()
    }

    return {
        launchStation
    }
}