import { createMongoAbility, MongoAbility } from '@casl/ability'
import { Auth, Role } from 'models'

export function defineHeaderAbility(auth?: Auth | null): MongoAbility {
    const rules = auth && auth.role && auth.role !== Role.VOTER
        ? [
            {
                action: 'use',
                subject: 'SupportedWidth',
                conditions: { role: { $ne: Role.VOTER } }
            }
        ]
        : []

    return createMongoAbility(rules)
}
