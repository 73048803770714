import React, { Dispatch, ReactNode, SetStateAction, createContext, useMemo, useState } from 'react'
import { ColorThemeType } from '../models'

interface IColorThemeContextProps {
    colorTheme: ColorThemeType,
    setColorTheme: Dispatch<SetStateAction<ColorThemeType>>
}

export const ColorThemeContext = createContext<IColorThemeContextProps>({} as IColorThemeContextProps)

type Props = { children: ReactNode }

const ColorThemeProvider: React.FC<Props> = ({ children }) => {
    const theme = localStorage.getItem('theme')
    const [colorTheme, setColorTheme] = useState<ColorThemeType>(theme
        ? theme as ColorThemeType
        : ColorThemeType.AuthTheme)

    const value = useMemo(() => ({ colorTheme, setColorTheme }), [colorTheme])

    return <ColorThemeContext.Provider value={value}>{children}</ColorThemeContext.Provider>
}

export default ColorThemeProvider