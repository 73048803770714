import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: (failureCount, err: any) => {
                if (err?.response?.status === 404) {
                    return false
                }

                const defaultRetry = new QueryClient().getDefaultOptions().queries?.retry as any || 1

                return Number.isSafeInteger(defaultRetry) ? failureCount < (defaultRetry) : false
            }
        }
    }
})