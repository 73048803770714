import { Spin } from 'antd'
import style from './index.module.scss'


function Spinner() {
    return (
        <Spin
            className={style.globalSpin}
            spinning={true}
            size="large"
        />
    )
}

export default Spinner