import { useQuery } from '@tanstack/react-query'
import { ApiModule } from 'api/api-module'
import { useAxios } from 'api/hooks'
import { getModuleUrl } from 'api/utils'

export const useReportUrl = () => {
    const moduleUrl = getModuleUrl(ApiModule.Report)

    const getReportUrl = (reportId: string) => moduleUrl + `report-generation-process/${reportId}/file`

    return {
        getReportUrl
    }
}

export function useGetReport<T>(reportId: string, enabled?: boolean) {
    const axios = useAxios()
    const { getReportUrl } = useReportUrl()

    const getReportStatus = async () => {
        const response = await axios<T>(getReportUrl(reportId))

        return response.data
    }

    return useQuery({
        queryKey: ['report', reportId],
        queryFn: getReportStatus,
        enabled
    })
}