//{{host}}/administration/draft/officers-elections/elections/{{electionId}}/current-step
import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { queryClient } from 'config/query-client'

type UpdateVotingSessionStepProps = {
    electionId: string
    step: string
}

export function useUpdateVotingSessionStep() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function updateVotingSessionStep({
        electionId,
        step
    }: UpdateVotingSessionStepProps) {
        return await axios(moduleUrl +
            `draft/officers-elections/elections/${electionId}/current-step`,
            {
                method: 'post',
                data: step
            })
    }

    return useMutation({
        mutationFn: updateVotingSessionStep,
        onSuccess: () => {
            queryClient.invalidateQueries(['election-voting-session-step'])
        }
    })
}