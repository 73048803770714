import Footer from 'components/molecules/Footer'
import SignInPanel from '../components/SignInPanel'
import AuthPageWrapper from '../components/common/AuthPageWrapper'

function SignIn() {
    return (
        <AuthPageWrapper>
            <SignInPanel />
            <Footer />
        </AuthPageWrapper>
    )
}

export default SignIn