import { ReactComponent as CheckmarkInCircle } from 'icons/checkmark-in-circle.svg'
import { ReactComponent as DismissInCircleFilled } from 'icons/dismiss-circle.svg'
import { ReactComponent as InfoRoundedFilled } from 'icons/info-rounded-filled.svg'
import { ReactComponent as InfoRounded } from 'icons/info-rounded.svg'
import { ReactComponent as WarningFilled } from 'icons/warning-filled.svg'
import { ReactComponent as Warning } from 'icons/warning.svg'
import { PropsWithChildren } from 'react'
import style from './index.module.scss'

export type NoteProps = PropsWithChildren & {
    title?: React.ReactNode
    mode: 'warning' | 'info' | 'active' | 'error' | 'success'
    icon: 'regular' | 'filled'
}

function Note({ children, mode, icon, title }: NoteProps) {
    const whiteBackground = icon === 'regular' && mode !== 'active'
    const regularIcon = icon === 'regular'
    const isInfoRegular = mode === 'info' && icon === 'regular'
    const isActiveRegular = mode === 'active' && icon === 'regular'
    const isWarningRegular = mode === 'warning' && icon === 'regular'
    const isSuccessRegular = mode === 'success' && icon === 'regular'
    const titleColorClass = style[`${mode}Title`]
    const textColorClass = style[`${icon}-${mode}Text`]
    const borderWarningClass = isWarningRegular ? style.warningRegularBorder : style[`${mode}Border`]
    const borderSuccessClass = isSuccessRegular ? style.successBorder : style[`${mode}Border`]
    const borderInfoClass = isInfoRegular || isActiveRegular ? style.infoRegularBorder : style[`${mode}Border`]
    const IconComponent = {
        'warning': regularIcon ? Warning : WarningFilled,
        'info': regularIcon ? InfoRounded : InfoRoundedFilled,
        'active': regularIcon ? InfoRounded : InfoRoundedFilled,
        'error': DismissInCircleFilled,
        'success': CheckmarkInCircle
    }[mode]

    return (
        <div className={`${style.note} ${whiteBackground ? style.whiteBackground :
            style[`${mode}Background`]} ${borderWarningClass} ${borderInfoClass} ${borderSuccessClass}`}>
            <div className={`${style.icon} ${style[`${mode}Icon`]}`}>
                <IconComponent />
            </div>
            <div className={style.noteContent}>
                <div className={`${style.title} ${titleColorClass}`}>
                    {title}
                </div>
                <div className={`${style.text} ${textColorClass}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Note