import PopupTitle from 'components/atoms/PopupTitle'
import { ConfirmPopupSettings, useAccessToken } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useFinish } from '../api'
import { useReturnFromStationToUser } from './useReturnFromStationToUser'

export const useEndTrainingPopup = () => {
    const { t } = useTranslation('telling-station-sandbox')
    const { mutateAsync: finish } = useFinish()
    const { returnFromStationToUser } = useReturnFromStationToUser()
    const { setAccessToken } = useAccessToken()

    const openEndTrainingPopup = (showConfirm: (settings: ConfirmPopupSettings) => void, hide: () => void) => {
        showConfirm({
            title: <PopupTitle>{t('end_training')}?</PopupTitle >,
            text: <>
                {t('you_are_about_to_cancel_the_training_and_return_to_the_dashboard')}
                <div style={{ paddingTop: 'var(--default-space' }}>
                    <b>{t('please_note_this_is_an_irreversible_action')}</b>
                </div>
            </>,
            cancelText: t('continue_training'),
            okText: t('end_training'),
            onOk: async () => {
                const response = await finish()
                setAccessToken(response.headers['access-token'])
                returnFromStationToUser(hide)
            }
        })
    }

    return { openEndTrainingPopup }
}