import { defineAbility } from 'ability/ability'
import { AuthContext } from 'context'
import { useAbility } from 'hooks'
import { Auth } from 'models'
import { PropsWithChildren, useEffect, useState } from 'react'



function AuthProvider({ children }: PropsWithChildren) {
    const userInStorage = localStorage.getItem('user')
    const [auth, setAuth] = useState<Auth | null>(userInStorage ? JSON.parse(userInStorage) : {})
    const { setAbility } = useAbility()

    useEffect(() => {
        setAbility(defineAbility(auth || undefined))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <AuthContext.Provider value={{ auth, setAuth }} >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider