import { Col, Form, FormInstance, Row } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import DatePicker from 'components/atoms/DatePicker'
import { useTranslation } from 'react-i18next'
import { toDayjs } from 'utils'
import SectionTitle from '../SectionTitle'
import style from './index.module.scss'

const minElectionDurationDays = 0

type OnlineVotingStartDateProps = {
    form: FormInstance
}

function OnlineVotingStartDate({ form }: OnlineVotingStartDateProps) {
    const { t } = useTranslation('configuration')

    const startDateRangeFromDisabledDate: RangePickerProps['disabledDate'] = (current) => {
        const startDateRangeTo = form.getFieldValue('startDateRangeTo')
        if (startDateRangeTo) {
            const afterElectionDay = current && (
                startDateRangeTo.endOf('day').diff(current.endOf('day'), 'days') < minElectionDurationDays)

            return afterElectionDay
        }

        return false
    }

    const startDateRangeToDisabledDate: RangePickerProps['disabledDate'] = (current) => {
        const startDateRangeFrom = form.getFieldValue('startDateRangeFrom')
        const electionDateRangeTo = form.getFieldValue('electionDateRangeTo')

        let isBeforeOnlineStartDate = false
        let isAfterElectionDayTo = false

        if (startDateRangeFrom) {
            isBeforeOnlineStartDate =
                current.endOf('day').diff(startDateRangeFrom.endOf('day'), 'days') < minElectionDurationDays
        }

        if (electionDateRangeTo) {
            isAfterElectionDayTo = current && (
                electionDateRangeTo.endOf('day').diff(current.endOf('day'), 'days') < minElectionDurationDays)
        }

        return isBeforeOnlineStartDate || isAfterElectionDayTo
    }

    return (
        <Row gutter={16}>
            <Col span={6}>
                <SectionTitle>{t('online_voting_start_date')}:</SectionTitle>
            </Col>
            <Col span={6}>
                <Form.Item
                    className={style.input}
                    name="startDateRangeFrom"
                    label={t('start_date_range_from')}
                    rules={[{
                        required: true,
                        message: t('please_select_the_input', { input: t('start_date_range_from') })
                    }]}
                    validateTrigger={['onBlur', 'onChange']}
                >
                    <DatePicker
                        placeholder={t('common:select_input', { input: t('common:date') })}
                        inputReadOnly={true}
                        disabledDate={startDateRangeFromDisabledDate}
                        format="MM/DD/YYYY"
                        style={{ width: '100%' }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <div className={style.inputAndLabel}>
                    <Form.Item
                        className={style.input}
                        name="startDateRangeTo"
                        label={t('start_date_range_to')}
                        rules={[{
                            required: true,
                            message: t('please_select_the_input', { input: t('start_date_range_to') })
                        },
                        {
                            message: t('start_date_range_to_cannot_be_later_than_election_date_range_to'),
                            validator: (_, value) => {
                                if (value) {
                                    const dayjsValue = toDayjs(value)

                                    return startDateRangeToDisabledDate(dayjsValue, {
                                        type: 'date'
                                    })
                                        ? Promise.reject()
                                        : Promise.resolve()
                                }

                                return Promise.resolve()
                            }
                        }]}
                        validateTrigger={['onBlur', 'onChange']}
                    >
                        <DatePicker
                            placeholder={t('select_input', { input: t('date') })}
                            disabledDate={startDateRangeToDisabledDate}
                            inputReadOnly={true}
                            format="MM/DD/YYYY"
                            style={{ width: '100%' }} />
                    </Form.Item>
                    <div className={style.label}>
                        {t('allow_to_select_1_day')} ({t('always_starts_at_12_00_am_local_timezone')})
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default OnlineVotingStartDate