import { useStation } from 'api'
import Wizard from 'components/molecules/Wizard'
import WizardStep from 'components/molecules/Wizard/WizardStep'
import { IWizardStepProps } from 'components/molecules/Wizard/models'
import { useAuth, useConfirmPopup, useInfoPopup, usePopup } from 'hooks'
import { ReactComponent as ChevronBold } from 'icons/chevron-bold.svg'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBallots } from '../../api'
import { enterBallots } from '../../features/enterBallots'
import { reporting } from '../../features/reporting'
import { StatisticalSignatureProps } from '../../features/reporting/components/StatisticalSignature'
import { voterVerification } from '../../features/voterVerification'
import { useTellingStationInfo, useWizardChipsSettings } from '../../hooks'
import CountVerification from '../CountVerification'
import CountVerificationPopupTitle from '../CountVerificationPopupTitle'
import style from './index.module.scss'

type TellingStationProcessProps = {
    voterVerificationTutorial?: ReactNode
    voterVerificationTableHeight?: string
    voterVerificationTableColHighlightingHeight?: string
    enterBallotsTutorial?: ReactNode
    enteredBallotsTableOffset?: string
    reportingTutorial?: ReactNode
    wizardRightSideExtraContent?: ReactNode
    banner?: ReactNode
    onStationReportSigned?: () => void
    hideElectionTypeInfo?: boolean
}

function TellingStationProcess({
    voterVerificationTutorial,
    voterVerificationTableHeight,
    voterVerificationTableColHighlightingHeight,
    enterBallotsTutorial,
    enteredBallotsTableOffset,
    reportingTutorial,
    wizardRightSideExtraContent,
    banner,
    onStationReportSigned,
    hideElectionTypeInfo
}: TellingStationProcessProps) {
    const { t } = useTranslation('telling-station')
    const { auth } = useAuth()
    const { voterVerificationChipsSettings,
        enterBallotsChipSettings,
        reportingChipSettings } = useWizardChipsSettings()
    const [initStep, setInitStep] = useState(0)
    const { info } = useTellingStationInfo()
    const { infoPortal, showInfo } = useInfoPopup()
    const { confirmPortal, showConfirm } = useConfirmPopup()
    const { show, hide, popupPortal } = usePopup()
    const infoRef = useRef(info)
    const { data: station } = useStation({ electionId: auth!.electionId!, stationId: auth!.stationId! }, !!auth)
    const { data: ballots } = useBallots(!!auth)
    const submitTellingProcessRef = useRef<StatisticalSignatureProps>(null)

    const submitTellingProcess = () => {
        if (submitTellingProcessRef.current) {
            submitTellingProcessRef.current.handleButtonClick!()
        }
    }

    useEffect(() => {
        if (station && ballots) {
            if (station.stationReport.voterSummaryVerified) {
                if (!ballots.length) {
                    setInitStep(1)

                    return
                }
                setInitStep(2)

                return
            }
            if (station.stationReport.voterSummaryReported) {
                setInitStep(1)

                return
            }
        }
    }, [station, ballots])

    useEffect(() => {
        infoRef.current = info
    },
        [info])

    const stationId = () => auth!.stationId!.substring(1)

    const onComplete = async () => {
        submitTellingProcess()

        return true
    }

    const steps: IWizardStepProps[] = [

        {
            chipSettings: voterVerificationChipsSettings,
            minHeaderContentWidth: !!wizardRightSideExtraContent ? 944 : 760,
            rightSideExtraContent: wizardRightSideExtraContent,
            nextButton: {
                callback: () => {
                    const promise = new Promise<boolean>((resolve) => {
                        if (infoRef.current.hasVerifiedVoters) {
                            show({
                                width: '660px',
                                children: <>
                                    <CountVerificationPopupTitle hide={hide} banner={banner} />
                                    <CountVerification
                                        onCancel={() => {
                                            resolve(false)
                                            hide()
                                        }}
                                        onSubmit={() => {
                                            resolve(true)
                                            hide()
                                        }}
                                    />
                                </>,
                                footer: null
                            }
                            )
                        } else {
                            showInfo({
                                onOk: async () => {
                                    resolve(false)
                                },
                                title: t('voter_verification_missed'),
                                text: <div>
                                    {t('you_have_not_verified_any_voters_as_in_person_or_absentee')}
                                    <div style={{ paddingTop: 'var(--default-space' }}>
                                        <b>{t('please_verify_voters_and_click_next_step')}</b>
                                    </div>
                                </div>
                            })
                        }
                    })

                    return promise
                },
                icon: <ChevronBold height="10px" width="5.5px" />
            },
            children: <voterVerification.component tutorial={voterVerificationTutorial}
                tableHeight={voterVerificationTableHeight}
                tableColHighlightingHeight={voterVerificationTableColHighlightingHeight} />
        },
        {
            chipSettings: enterBallotsChipSettings,
            minHeaderContentWidth: !!wizardRightSideExtraContent ? 944 : 760,
            rightSideExtraContent: wizardRightSideExtraContent,
            nextButton: {
                callback: () => {
                    const promise = new Promise<boolean>((resolve) => {

                        const showCountVerification = () => {
                            show({
                                width: '660px',
                                children: <>
                                    <CountVerificationPopupTitle hide={hide} banner={banner} />
                                    <CountVerification
                                        includeNotCountedDuringBallotEntry
                                        onCancel={() => {
                                            resolve(false)
                                            hide()
                                        }}
                                        onSubmit={() => {
                                            resolve(true)
                                            hide()
                                        }}
                                    />
                                </>,
                                footer: null
                            }
                            )
                        }

                        const showNoBallotsPopup = () => {
                            showInfo({
                                onOk: async () => {
                                    resolve(false)
                                },
                                title: t('ballot:no_submitted_ballots'),
                                text: <div>
                                    {t('ballot:this_station_id_doesnt_contain_ballots', { id: stationId() })}
                                    <div style={{ paddingTop: 'var(--default-space' }}>
                                        <b>
                                            {t('ballot:to_proceed_please_add_votes')}
                                        </b>
                                    </div>
                                </div>
                            })
                        }

                        const showNextStepPopup = () => {
                            if (!infoRef.current.hasEnteredBallots) {
                                showNoBallotsPopup()
                            } else {
                                showCountVerification()
                            }
                        }

                        if (infoRef.current.hasNotSubmittedBallot) {
                            showConfirm({
                                onOk: async () => {
                                    showNextStepPopup()
                                },
                                onCancel: async () => {
                                    resolve(false)
                                },
                                okText: t('complete_step'),
                                title: t('ballot:not_submitted_ballot'),
                                text: <div>
                                    {t('ballot:ballot_is_not_submitted', { id: stationId() })}
                                    <div style={{ paddingTop: 'var(--default-space' }}>
                                        <b>
                                            {t('ballot:would_you_like_to_complete_step_without_submitting_the_ballot')}
                                        </b>
                                    </div>
                                </div>
                            })
                        } else {
                            showNextStepPopup()
                        }
                    })

                    return promise
                },
                icon: <ChevronBold height="10px" width="5.5px" />
            },
            children: <enterBallots.component tutorial={enterBallotsTutorial}
                enteredBallotsTableOffset={enteredBallotsTableOffset} />
        },
        {
            chipSettings: reportingChipSettings,
            minHeaderContentWidth: !!wizardRightSideExtraContent ? 944 : 760,
            rightSideExtraContent: wizardRightSideExtraContent,
            nextButton: {
                title: t('complete_telling_process'),
                callback: () => onComplete()
            },
            children: <reporting.components.StatisticalSignature ref={submitTellingProcessRef}
                tutorial={reportingTutorial}
                onSigned={onStationReportSigned}
                hideElectionTypeLabel={hideElectionTypeInfo} />
        }
    ]

    return (
        <>
            {infoPortal}
            {confirmPortal}
            {popupPortal}
            <Wizard headerLeftPanel={<div className={style.stationName}>{t('station:station_number',
                { number: stationId() })}</div>}
                steps={steps.map((step, index) => <WizardStep key={index} {...step} />)}
                initStep={initStep}
                pageHeader={banner}
            />
        </>
    )
}

export default TellingStationProcess