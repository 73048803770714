import { auth } from 'features/auth'
import SandboxAuthPageWrapper from '../SandboxAuthPageWrapper'
import SandboxLogo from '../SandboxLogo'
import style from './index.module.scss'

function SignIn() {
    return (
        <SandboxAuthPageWrapper>
            <auth.components.SignInPanel desktopOnly
                logo={
                    <div className={style.logoWrapper}>
                        <SandboxLogo reversedColors />
                    </div>}
                cardClassName={style.card}
                panelClassName={style.panel}
            />
        </SandboxAuthPageWrapper>
    )
}

export default SignIn