import { Collapse, CollapseProps } from 'antd'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { useTranslation } from 'react-i18next'
import { useCompletedElections } from '../../api'
import ActiveElections from '../ActiveElections'
import CompletedElections from '../CompletedElections'

const ElectionList = () => {
    const { data: elections } = useCompletedElections()
    const { t } = useTranslation('election')

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: t('history') + ' (' + t('completed_elections') + ')',
            children: <CompletedElections elections={elections || []} />
        }
    ]

    return (
        <>
            <ActiveElections />
            {!!elections?.length &&
                <div className="collapse-like-card">
                    <Collapse
                        defaultActiveKey="1"
                        items={items}
                        expandIcon={() => <Chevron />}
                    />
                </div>
            }
        </>
    )
}

export default ElectionList