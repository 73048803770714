import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

type ElectionsOnlineVotersCountParams = {
    electionIds: string[]
}

type ElectionOnlineVotersCountInfo = {
    election: number,
    voters: {
        totalCount: number,
        votedOnlineInAbsence: number,
        votedOnlineInPerson: number
    }
}

export const useElectionsOnlineVotersCount =
    ({ electionIds }: ElectionsOnlineVotersCountParams, enabled?: boolean) => {
        const axios = useAxios()
        const moduleUrl = getModuleUrl(ApiModule.OnlineBallot)

        const getElectionsOnlineVotersCount = async () => {
            const res = await axios<ElectionOnlineVotersCountInfo[]>(
                `${moduleUrl}elections/voters/count?elections=${electionIds.join(',')}`)

            return res.data
        }

        return useQuery({
            queryKey: ['elections-online-voters-count', electionIds],
            queryFn: getElectionsOnlineVotersCount,
            enabled
        })
    }