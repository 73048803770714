import {
    useBallotInvalidationReasons,
    useStationInvalidateBallot,
    useInvalidateBallot,
    useVoteInvalidationReasons
} from './api'
import BallotEditor from './components/BallotEditor'
import BallotsTable from './components/BallotsTable'
import DeleteBallot from './components/DeleteBallot'
import InternalScrollBallotsTable from './components/InternalScrollBallotsTable'
import MarkBallotAsInvalid from './components/MarkBallotAsInvalid'
import { useBallotActions, useBallotVotes, useBallotVotesActions } from './hooks'
import Ballot from './pages/Ballot'
import BallotVotesProvider from './providers/BallotVotesProvider'
import { isVoteVacant, isVoteValid } from './utils'

export const ballotingAssets = {
    api: {
        queries: {
            useVoteInvalidationReasons,
            useBallotInvalidationReasons
        },
        mutations: {
            useStationInvalidateBallot,
            useInvalidateBallot
        }
    },
    components: {
        BallotsTable,
        MarkBallotAsInvalid,
        BallotEditor,
        DeleteBallot,
        InternalScrollBallotsTable
    },
    hooks: {
        useBallotVotes,
        useBallotActions,
        useBallotVotesActions
    },
    pages: { Ballot },
    providers: {
        BallotVotesProvider
    },
    utils: {
        isVoteVacant,
        isVoteValid
    }
}