import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionResult } from 'models'

export const useTieDesignatedCandidates = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getTieDesignatedCandidates = async ({ queryKey }: QueryFunctionContext) => {
        const [, electionId] = queryKey

        const res =
            await axios<ElectionResult[]>(
                `${moduleUrl}elections/${electionId}/election-results/candidates/designated`)

        return res.data
    }

    return useQuery<ElectionResult[], Error>({
        queryKey: ['designated-tie-candidates', electionId],
        queryFn: getTieDesignatedCandidates,
        enabled: !!electionId && enabled
    })
}