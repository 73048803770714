import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionResultSummary } from 'models'

export const useElectionSummary = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElectionSummary = async ({ queryKey }: QueryFunctionContext)
        : Promise<ElectionResultSummary> => {
        const [, electionId] = queryKey

        const result = await axios<ElectionResultSummary>(
            `${moduleUrl}elections/${electionId}/election-results/summary`)

        return result.data
    }

    return useQuery({
        queryKey: ['election-summary', electionId],
        queryFn: getElectionSummary,
        enabled
    })
}