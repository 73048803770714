import { makeKebabWithCamel } from './case-transforming'

export const getUrlParamsFromFilter = (filter: any) => {
    if (!filter) {
        return ''
    }
    const params: string[] = []
    for (const [key, value] of Object.entries(filter)) {
        if (value != null && value !== '')
            params.push(`${makeKebabWithCamel(key)}=${value}`)
    }

    return params.join('&')
} 