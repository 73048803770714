import { WizardChipSettings } from 'components/molecules/Wizard/models'
import { ReactComponent as CalendarFilled } from 'icons/calendar-filled.svg'
import { ReactComponent as GearFilled } from 'icons/gear-filled.svg'
import { ReactComponent as Gear } from 'icons/gear.svg'
import { ReactComponent as PeopleCheckmarkFilled } from 'icons/people-checkmark-filled.svg'
import { ReactComponent as PeopleCheckmark } from 'icons/people-checkmark.svg'
import { useTranslation } from 'react-i18next'
export function useElectionWizardChipsSettings() {
    const { t } = useTranslation('election')

    const setUpElectionChipSettings: WizardChipSettings = {
        title: t('set_up_election'),
        icon: <CalendarFilled width="20" height="20" />
    }
    const verifyParticipantsChipSettings: WizardChipSettings = {
        title: t('verify_participants'),
        icon: <PeopleCheckmark width="20" height="20" />,
        activeIcon: <PeopleCheckmarkFilled width="20" height="20" />
    }
    const setUpTellersChipSettings: WizardChipSettings = {
        title: t('set_up_tellers'),
        icon: <Gear width="20" height="20" />,
        activeIcon: <GearFilled width="20" height="20" />
    }

    return {
        setUpElectionChipSettings,
        verifyParticipantsChipSettings,
        setUpTellersChipSettings
    }
}