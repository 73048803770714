export enum ApiModule {
    Administration = 'administration/',
    BallotProcessing = 'ballot-processing/',
    AuthenticationService = 'authentication-service/',
    OnlineBallot = 'online-ballot/',
    TellingStation = 'telling-station/',
    Report = 'report/',
    Notification = 'notification/',
    Toolkit = 'toolkit/',
    NoModule = ''
}