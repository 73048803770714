import { Select as AntSelect, Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { ColumnsType } from 'antd/es/table'
import { useElectionTellingStations, useStation } from 'api'
import PopupFooter from 'components/atoms/PopupFooter'
import { Select } from 'components/atoms/Select'
import Note from 'components/molecules/Note'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import Table from 'components/molecules/Table'
import { useInfoPopup, useTellerTypeMap } from 'hooks'
import { AppointedTeller, ElectionStation, TellerType, TellingStationStatus } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStationLauncher } from '../../hooks'
import style from './index.module.scss'

type SelectTellingStationProps = {
    electionId: string,
    onLaunch: () => void
}

function sortTellers(tellers: AppointedTeller[]) {
    const rolePriority = {
        [TellerType.CHIEF_TELLER]: 1,
        [TellerType.CHIEF_TELLER_ASSISTANT]: 2,
        [TellerType.TELLER]: 3
    }

    return tellers.sort((a, b) => {
        const roleDiff = (rolePriority[a.role] || 100) - (rolePriority[b.role] || 100)
        if (roleDiff !== 0) {
            return roleDiff
        }

        return a.name.localeCompare(b.name)
    })
}

function SelectTellingStation({ onLaunch, electionId }: SelectTellingStationProps) {
    const [form] = useForm()
    const { t } = useTranslation('teller')
    const { toString } = useTellerTypeMap()
    const { infoPortal, showInfo } = useInfoPopup()
    const { launchStation } = useStationLauncher()
    const [stationId, setStationId] = useState<string>('')
    const [tellers, setTellers] = useState<AppointedTeller[]>([])
    const { data: station } = useStation({ electionId: electionId, stationId: stationId }, !!stationId)
    const { data: stations } = useElectionTellingStations(electionId, !!electionId)
    const [sortedStations, setSortedStations] = useState<ElectionStation[]>([])

    useEffect(() => {
        if (stations) {
            setSortedStations(stations.sort((a, b) => {
                if (a.id > b.id) {
                    return 1
                }
                if (a.id < b.id) {
                    return -1
                }

                return 0
            }))
        } else {
            setSortedStations([])
        }
    }, [stations])

    useEffect(() => {
        if (sortedStations?.length >= 1) {
            form.setFieldsValue({ station: sortedStations[0].id })
            onStationChange()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedStations])

    const onPressButton = async () => {
        const stationId = form.getFieldsValue().station
        await launchStation({
            stationId,
            electionId,
            onLaunch
        })
    }

    const onSubmit = async () => {
        try {
            await form.validateFields()
        } catch {
            return
        }

        if (station?.status === TellingStationStatus.CLOSED) {
            onPressButton()
        } else {
            showInfo({
                title: t('station_has_already_launched'),
                text:
                    <>
                        <div className={style.popupBodyText}>{t('the_telling_station_mode_is_already_running_for')}
                            <span className={style.stationText}>
                                {` ${t('station')} ${station?.id.replace(/^S/, '')}.`}
                            </span>
                        </div>
                        <div className={style.popupFooterText}>
                            {t('click_confirm_to_quit_previously_launched_mode')}
                        </div>
                    </>,
                okText: <>{t('common:confirm')}</>,
                onOk: () => {
                    onPressButton()
                }
            })
        }
    }

    const columns: ColumnsType<AppointedTeller> = [
        {
            title: t('common:position'),
            render: (teller: AppointedTeller) => <>{toString(teller.role)}</>,
            width: 176,
            key: 'role'
        },
        {
            title: t('common:bahai_id'),
            dataIndex: 'id',
            width: 104,
            key: 'id'
        },
        {
            title: t('common:full_name'),
            width: 332,
            render: (teller: AppointedTeller) =>
                <div
                    style={{ maxWidth: 'calc(332px - calc(var(--double-space) * 2)' }}>
                    <OptionalTooltip className="ellipsis">{teller.name}</OptionalTooltip>
                </div>
        }
    ]

    const onStationChange = () => {
        const stationId = form.getFieldsValue().station
        setStationId(stationId)
        const station = sortedStations.find((s) => s.id === stationId)!
        const sortedTellers = sortTellers([...station.tellers])
        setTellers(sortedTellers)
    }

    return (
        <>
            {infoPortal}
            <Form
                name="stations"
                onValuesChange={onStationChange}
                layout="vertical"
                form={form}>
                <Form.Item
                    name={['station']}
                    className="no-padding"
                    label={t('telling_station')}
                    rules={[{
                        required: true,
                        message: t('common:please_select_the_input', { input: t('telling_station') })
                    }
                    ]}
                >
                    <Select
                        placeholder={t('common:please_select_the_input', { input: t('telling_station') })}
                    >
                        {sortedStations.map(station => <AntSelect.Option
                            value={station.id} key={station.id}>
                            {`${t('station')} ${station.id.substring(1)}`}</AntSelect.Option>)}
                    </Select>
                </Form.Item>
            </Form >
            {!!tellers?.length && <Table
                className={style.table}
                rowKey={t => t.id}
                noOuterBorder
                obsSize="small"
                obsHeaderSize="small"
                headerDividerVisible={true}
                dataSource={tellers}
                pagination={false}
                columns={columns}
            />}
            {
                tellers?.length === 1 && <div className={style.error}>
                    <Note mode={'error'} icon={'filled'}>
                        {t('telling_station_must_have_2_or_more_tellers_to_operate')}</Note>
                </div>
            }
            <div className={style.footer}>
                <PopupFooter>
                    <button
                        type="submit"
                        disabled={tellers?.length === 1}
                        onClick={onSubmit}
                        className={'btn-main-primary'}>
                        {t('common:launch')}
                    </button>
                </PopupFooter>
            </div>
        </>
    )
}

export default SelectTellingStation