import { BallotsSummary, StationStatisticStatus, TellingStation, TellingStationStatus } from 'models'

export const useGetStationStatus = () => {
    const getStationStatus = (station: TellingStation, summary: BallotsSummary) => {
        if (station.stationReport.signed && station.status === TellingStationStatus.CLOSED) {
            return StationStatisticStatus.REPORT_SIGNED
        }
        if (station.stationReport.voterSummaryVerified && summary.validBallotCount) {
            return StationStatisticStatus.BALLOTS_ENTERED
        }
        if (station.stationReport.voterSummaryReported) {
            return StationStatisticStatus.VOTERS_VERIFIED
        }
        if (station.stationReport.voterCount) {
            return StationStatisticStatus.LAUNCHED
        }

        return StationStatisticStatus.CREATED
    }

    return { getStationStatus }
}