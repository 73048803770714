import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { queryClient } from 'config/query-client'

type UpdateCandidatePhysicalVotesProps = {
    electionId: string
    candidateId: string
    votes: number
}

export function useUpdateCandidatePhysicalVotes() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function updateCandidatePhysicalVotes({
        candidateId,
        electionId,
        votes
    }: UpdateCandidatePhysicalVotesProps) {
        return await axios(moduleUrl +
            `draft/officers-elections/elections/${electionId}/election-results/candidates/${candidateId}/vote-count`,
            {
                method: 'post',
                data: votes
            })
    }

    return useMutation({
        mutationFn: updateCandidatePhysicalVotes,
        onSuccess: () => {
            queryClient.invalidateQueries(['election-physical-votes'])
        }
    })
}