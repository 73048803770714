import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { BahaiYear } from '../../model'

export const useBahaiYears = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getBahaiYears = async () => {
        const years = await axios<BahaiYear[]>(moduleUrl + 'election-periods/bahai-years')

        return years.data?.sort((a, b) => b.badiYear - a.badiYear)
    }

    return useQuery({
        queryKey: ['bahai-years'],
        queryFn: getBahaiYears,
        enabled
    })
}