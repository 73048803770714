import { Select as AntSelect, Skeleton } from 'antd'
import { useElectionCandidates } from 'api'
import { Select } from 'components/atoms/Select'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import { BallotVote } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useVoteInvalidationReasons } from '../../api'
import { useBallotVotes, useBallotVotesActions } from '../../hooks'
import style from './index.module.scss'

type BallotEditorVoteProps = {
    ballotVote: BallotVote,
    electionId: string
}

function BallotEditorVote({ ballotVote, electionId }: BallotEditorVoteProps) {
    const { t } = useTranslation('ballot')
    const { data: voteInvalidationReasons } = useVoteInvalidationReasons(electionId)
    const { updateVoteValidity, setReason, removeVote } = useBallotVotesActions()
    const { setBallotVotes } = useBallotVotes()
    const queryEnabled = !!ballotVote.candidate?.id && !ballotVote.candidate?.name
    const { data: candidate, isLoading } = useElectionCandidates(electionId,
        {
            id: ballotVote.candidate?.id || ''
        }, queryEnabled)

    const [waitForMapping, setWaitForMapping] = useState(queryEnabled)

    useEffect(() => {
        if (candidate?.length) {
            setBallotVotes(current => current.map(v => {
                const c = candidate[0]
                if (v.candidate?.id === c.id) {
                    return { ...v, candidate: c }
                } else {
                    return { ...v }
                }
            }
            ))
            setWaitForMapping(false)
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [candidate])

    const content = () => {
        if (queryEnabled && (isLoading || waitForMapping)) {
            return <div className={style.skeleton}><Skeleton.Input active block /></div>
        }

        if (ballotVote.candidate?.name) {
            return <>
                <div className={`${style.column} ${style.name}`}>
                    <OptionalTooltip contenWrapperClassName="ellipsis">
                        {ballotVote.candidate.name}
                    </OptionalTooltip>
                </div>
                <div className={`${style.column} ${style.bid}`}>
                    {ballotVote.candidate.id}
                </div>
                <div className={`${style.column} ${style.address}`}>
                    <OptionalTooltip contenWrapperClassName="ellipsis">
                        {`${ballotVote.candidate.homeLocality?.name}, ${ballotVote.candidate.homeLocality?.state}`}
                    </OptionalTooltip>
                </div>

            </>
        }

        if (ballotVote.invalid) {
            return <div className={`${style.column} ${style.reason} ${ballotVote
                .hasValidationError ? style.error : ''}`}>
                <div className={style.selectWrapper}>
                    <Select
                        value={ballotVote.invalidationReason?.id}
                        onChange={(reasonId) => {
                            setReason(ballotVote, voteInvalidationReasons!.find(r => r.id === reasonId))
                        }}
                        placeholder={t('common:select_the_input', { input: t('reason') })}
                        className={`${style.select} small ${ballotVote
                            .hasValidationError ? 'ant-select-status-error' : ''}`}
                    >
                        {voteInvalidationReasons?.map((reason, index) =>
                            <AntSelect.Option value={reason.id} key={index} >
                                {reason.text}
                            </AntSelect.Option>)
                        }
                    </Select>
                </div>
                {ballotVote.hasValidationError
                    && <div className={style.reasonNotProvided}>
                        {t('common:please_select_the_input', { input: t('reason') })}
                    </div>
                }
            </div>
        }

        return <>
            <div className={`${style.column} ${style.empty} ${style.name}`}>
                <span className="ellipsis">{t('empty_vote')}</span>
            </div>
            <div className={`${style.column} ${style.empty} ${style.bid}`}>
                <span >{String.fromCharCode(8212)}</span>
            </div>
            <div className={`${style.column} ${style.empty} ${style.address}`}>
                <span >{String.fromCharCode(8212)}</span>
            </div>
        </>
    }

    return (
        <div className={style.vote}>
            {content()}
            {ballotVote.invalid
                ? < div className={`${style.column} ${style.changeToValid}`}>
                    <button onClick={() => updateVoteValidity(ballotVote)}
                        className="btn-success-tertiary-md"
                    >{t('change_to_valid')}</button>
                </div>
                : < div className={`${style.column} ${style.markAsInvalid}`}>
                    <button onClick={() => updateVoteValidity(ballotVote)}
                        className="btn-main-tertiary-md"
                    >{t('mark_vote_invalid')}</button>
                </div>
            }
            <div className={`${style.column} ${style.remove}`}>
                {(ballotVote.invalid || ballotVote.invalidationReason || ballotVote.candidate)
                    && <button
                        className={`btn-main-tertiary-md ${style.dismiss}`}
                        type="button"
                        onClick={() => removeVote(ballotVote)}>
                        <div><Dismiss width="16" height="16" /></div>
                    </button>
                }
            </div>
        </div >
    )
}

export default BallotEditorVote