import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { queryClient } from 'config/query-client'
import { useState } from 'react'

export function useStopOnlineVoting() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.OnlineBallot)
    const [electionId, setElectionId] = useState('')

    async function stopOnlineVoting(electionId: string) {

        setElectionId(electionId)

        return await axios(`${moduleUrl}elections/${electionId}/voting/complete`,
            {
                method: 'post',
                data: {}
            })
    }

    return useMutation({
        mutationFn: stopOnlineVoting,
        onSuccess: async () => {
            await queryClient.invalidateQueries(['election-summary', electionId])
            await queryClient.invalidateQueries(['candidates-elected', electionId])
            await queryClient.invalidateQueries(['election-results-candidates', electionId])
            await queryClient.invalidateQueries(['election-results-tie', electionId])
            await queryClient.invalidateQueries(['breaking-tie-rounds', electionId])
            await queryClient.invalidateQueries(['online-voting', electionId])
            await queryClient.invalidateQueries(['designated-tie-candidates', electionId])
        }
    })
}