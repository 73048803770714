import { PropsWithChildren, useState } from 'react'
import { VoterVerificationTableDataContext } from '../context'
import { VoterVerificationTableData } from '../models'

function VoterVerificationTableDataProvider({ children }: PropsWithChildren) {
    const [tableData, setTableData] = useState<VoterVerificationTableData>({
        voters: [],
        statistics: {}
    })

    return (
        <VoterVerificationTableDataContext.Provider value={{ tableData, setTableData }} >
            {children}
        </VoterVerificationTableDataContext.Provider>
    )
}

export default VoterVerificationTableDataProvider