import {
    useElectionOnlineBallotsCount,
    useOnlineBallots,
    useStationBallots,
    useStationBallotsSummary,
    useStationVotesCounts
} from 'api'
import Card from 'components/atoms/Card'
import { Tabs } from 'components/atoms/Tabs'
import StatisticCounter from 'components/molecules/StatisticCounter'
import { queryClient } from 'config/query-client'
import { ballotingAssets } from 'features/ballotingAssets'
import { useAuth } from 'hooks'
import { Ballot, Election, ElectionStation } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type BallotingStatisticProps = {
    election?: Election,
    stations?: ElectionStation[]
}

const pageLimit = 30
const pageOffset = 30

function BallotingStatistic({ election, stations }: BallotingStatisticProps) {
    const { t } = useTranslation()
    const { auth } = useAuth()
    const [loadPage, setLoadPage] = useState(0)
    const [canLoadMore, setCanLoadMore] = useState(true)
    const [ballotsState, setBallotsState] = useState<Ballot[]>([])
    const [activeTabKey, setActiveTabKey] = useState<string>('online-ballots')
    const [stationIds, setStationIds] = useState<string[]>([])
    const isValidStationId = stationIds.includes(activeTabKey)

    const { data: onlineBallots, isFetching } =
        useOnlineBallots(auth!.electionId!,
            { limit: pageLimit, offset: loadPage * pageOffset }, !!auth?.electionId)
    const { data: onlineBallotsCount } = useElectionOnlineBallotsCount(auth!.electionId!, !!auth?.electionId)
    const { data: stationBallots, refetch } = useStationBallots({
        electionId: auth!.electionId!,
        stationId: activeTabKey
    }, !!auth && isValidStationId)
    const { data: stationBallotsCounts } = useStationBallotsSummary(
        { electionId: auth!.electionId!, stationId: activeTabKey }, !!auth && isValidStationId)
    const { data: stationVotesCounts } =
        useStationVotesCounts({ electionId: auth!.electionId!, stationId: activeTabKey }, !!auth && isValidStationId)

    const isBypassFlow = election?.bypassFlow
    const invalidVotes = stationVotesCounts?.invalidVoteCount || 0
    const invalidBallots = stationBallotsCounts?.invalidBallotCount || 0

    const handleScrollToBottom = () => {
        if (activeTabKey !== 'online-ballots' || !canLoadMore || isFetching)
            return
        setLoadPage(loadPage + 1)
    }

    useEffect(() => {
        if (!!auth && isValidStationId) {
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabKey, isValidStationId])

    useEffect(() => {
        if (stations) {
            const ids = stations
                .sort((a, b) => {
                    const stationNumberA = parseInt(a.id.substring(1))
                    const stationNumberB = parseInt(b.id.substring(1))

                    return stationNumberA - stationNumberB
                })
                .map(s => s.id.toString())
            setStationIds(ids)
            if (ids.length > 0 && !election?.bypassFlow) {
                setActiveTabKey(ids[0])
            }
        }
    }, [election, stations])

    useEffect(() => {
        setCanLoadMore(!!onlineBallots?.length)

        if (onlineBallots) {
            setBallotsState(loadPage ? ballotsState.concat(onlineBallots) : [...onlineBallots])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlineBallots])

    const tabItems = isBypassFlow ? [] : stations?.map((station) => ({
        key: station.id,
        label: `${t('teller:station')} ${station.id.replace('S', '')}`,
        children: <ballotingAssets.components.BallotsTable
            stickyOffset="143px"
            isStation={true}
            canEditBallot={false}
            ballots={stationBallots || []}
            onBallotUpdate={() => {
                queryClient.invalidateQueries(['election-station-ballots'])
            }}
        />
    })).sort((a, b) => {
        const stationNumberA = parseInt(a.label.split(' ').pop() || '0')
        const stationNumberB = parseInt(b.label.split(' ').pop() || '0')

        return stationNumberA - stationNumberB
    }) || []

    tabItems.push({
        key: 'online-ballots',
        label: t('teller:online_ballots'),
        children: <ballotingAssets.components.BallotsTable
            onScrollToBottom={handleScrollToBottom}
            stickyOffset="143px"
            isStation={false}
            ballots={ballotsState || []} />
    })

    return (
        <Card noHeaderLine={true} noContentPadding={true}>
            <div className={style.content}>
                <div className={style.titleWrapper}>
                    {t('balloting_results')}
                </div>
                {activeTabKey === 'online-ballots' ? (
                    <div className={style.counters}>
                        <StatisticCounter
                            value={onlineBallotsCount?.totalCount || 0}
                            title={t('teller:online_ballots')} />
                        <StatisticCounter
                            value={(onlineBallotsCount?.totalCount || 0) * (election?.numberOfVacancies || 0)}
                            title={t('teller:valid_votes')} />
                    </div>
                ) : (
                    <div className={style.counters}>
                        <StatisticCounter
                            value={(stationBallotsCounts?.validBallotCount || 0)}
                            title={t('election:valid_ballots')} />
                        <StatisticCounter isInvalid={invalidBallots > 0}
                            value={(stationBallotsCounts?.invalidBallotCount || 0)}
                            title={t('election:invalid_ballots')} />
                        <StatisticCounter value={(stationVotesCounts?.validVoteCount || 0)}
                            title={t('teller:valid_votes')} />
                        <StatisticCounter isInvalid={invalidVotes > 0}
                            value={stationVotesCounts?.invalidVoteCount || 0} title={t('election:invalid_votes')} />
                    </div>
                )}
            </div>
            <div className={style.tabBar}>
                <Tabs
                    activeKey={activeTabKey}
                    onTabClick={(key) => setActiveTabKey(key)}
                    items={tabItems}
                />
            </div>
        </Card>
    )
}

export default BallotingStatistic