import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

type TellingStationSignersProps = {
    id: string
    name: string
}

export const useTellingStationSigners = (electionId: string, stationId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getTellingStationSigners = async ({ queryKey }: QueryFunctionContext)
        : Promise<any> => {
        const [, electionId, stationId] = queryKey

        const res = await axios<TellingStationSignersProps>(`
        ${moduleUrl}elections/${electionId}/stations/${stationId}/signers`)

        return res.data
    }

    return useQuery<TellingStationSignersProps>({
        queryKey: ['telling-station-signers', electionId, stationId],
        queryFn: getTellingStationSigners,
        enabled
    })
}