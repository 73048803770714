import {ElectionStatusType, RidvanElectionPeriod} from 'models'

export const isRidvanSecondStage = (ridvanElectionPeriod: RidvanElectionPeriod) => {
    if (!ridvanElectionPeriod?.localDelegateElectionStage)
        return false

    const openLocalDelegates =
        ridvanElectionPeriod.localDelegateElectionStage.elections
            .filter(e => e.status !== ElectionStatusType.COMPLETED).length

    return !openLocalDelegates
}
