import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

type setUpScheduleParams = {
    electionPeriod: string,
}


export function usePushLocalDelegateResults() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function pushLocalDelegateResults({ electionPeriod }: setUpScheduleParams) {
        return await axios(moduleUrl
            + `ridvan-election-periods/${electionPeriod}/local-delegate-elections/election-results/push`,
            {
                method: 'post'
            })

    }

    return useMutation({
        mutationFn: pushLocalDelegateResults
    })
}