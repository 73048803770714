
import PopupTitle from 'components/atoms/PopupTitle'
import { PopupProps } from 'components/molecules/Popup'
import { useTranslation } from 'react-i18next'
import UnmergeResultsPopup from '../components/UnmergeResultsPopup'

export const useUnmergeResults = (showPopup: (props: PopupProps) => void,
    hidePopup: () => void) => {
    const { t } = useTranslation('teller')

    const initUnmergeResults = () => {
        showPopup({
            width: '620px',
            title: <PopupTitle>{t('physical_ballots_exist')}</PopupTitle >,
            children: <UnmergeResultsPopup onClose={() => hidePopup()} />,
            footer: null
        })
    }

    return {
        unmergeResults: initUnmergeResults
    }
}