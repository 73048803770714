import { InputRef, Tag } from 'antd'
import Card from 'components/atoms/Card'
import Input from 'components/atoms/Input'
import Tooltip from 'components/atoms/Tooltip'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { emailPattern } from '../../utils'
import style from './index.module.scss'

interface EmailsInputProps {
    initialEmails: string[]
    isValidationActive?: boolean
    className?: string
    onChange: (emails: string[], isValid: boolean) => void
}

function EmailsInput({ initialEmails, isValidationActive, className, onChange }: EmailsInputProps) {
    const { t } = useTranslation('configuration')
    const [emails, setEmails] = useState<string[]>(initialEmails)
    const [isValid, setIsValid] = useState<boolean>(true)
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [editInputIndex, setEditInputIndex] = useState(-1)
    const [editInputValue, setEditInputValue] = useState('')
    const inputRef = useRef<InputRef>(null)
    const editInputRef = useRef<InputRef>(null)

    useEffect(() => {
        setEmails(initialEmails)
    }, [initialEmails])

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus()
        }
    }, [inputVisible])

    useEffect(() => {
        editInputRef.current?.focus()
    }, [editInputValue])

    useEffect(() => {
        validateEmails(emails)
    }, [emails, isValidationActive])

    const validateEmails = (emails: string[]) => {
        const isValid = emails.every(isEmailValid)
        setIsValid(isValid)
        onChange(emails, isValid)
    }

    const handleClose = (removedTag: string) => {
        const newTags = emails.filter((tag) => tag !== removedTag)
        setEmails(newTags)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    const handleInputConfirm = () => {
        if (inputValue && !emails.includes(inputValue)) {
            setEmails([...emails, inputValue])
        }
        setInputVisible(false)
        setInputValue('')
    }

    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditInputValue(e.target.value)
    }

    const handleEditInputConfirm = () => {
        const newTags = [...emails]
        newTags[editInputIndex] = editInputValue
        setEmails(newTags)
        setEditInputIndex(-1)
        setEditInputValue('')
    }

    const handleTagClick = (index: number) => {
        setEditInputIndex(index)
        setEditInputValue(emails[index])
        setInputVisible(false)
    }

    const isEmailValid = (email: string) => emailPattern.test(email)

    return (
        <>
            <Card noHeaderLine className={`${!isValid && isValidationActive ? style.errorInput : ''} ${className}`}>
                {emails.map<React.ReactNode>((email, index) => {
                    if (editInputIndex === index) {
                        return (
                            <Input
                                ref={editInputRef}
                                className={`small ${style.inputEmails}`}
                                key={email}
                                size="small"
                                value={editInputValue}
                                onChange={handleEditInputChange}
                                onBlur={handleEditInputConfirm}
                                onPressEnter={handleEditInputConfirm}
                            />
                        )
                    }

                    const isLongTag = email.length > 20
                    const tagElem = (
                        <Tag
                            key={email}
                            closable
                            className={`${style.tagClass} ${!isEmailValid(email) && 
                                isValidationActive ? style.invalidTagClass : ''}`}
                            onClose={() => handleClose(email)}
                            onClick={() => handleTagClick(index)}>
                            {isLongTag ? `${email.slice(0, 20)}...` : email}
                        </Tag>
                    )

                    return isLongTag ? (
                        <Tooltip title={email} key={email}>
                            {tagElem}
                        </Tooltip>
                    ) : (tagElem)
                })}
                <Input
                    ref={inputRef}
                    type="text"
                    className={`small ${style.inputEmails}`}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            </Card>
            {!isValid && isValidationActive && (
                <div className={style.validation}>{t('please_ender_a_valid_email')}.</div>
            )}
        </>
    )
}

export default EmailsInput