import {
    useElection,
    useElectionRestrictionsOld,
    useElectionStations,
    useElectionSummary
} from 'api'
import { PopupProps } from 'components/molecules/Popup'
import { useAuth } from 'hooks'
import { Restriction } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getElectionRestrictions } from 'utils'
import StationsProgress from '../StationsProgress'
import StationsVotes from '../StationsVotes'
import WhatIfWeDontHavePhysicalBallotsLink from '../WhatIfWeDontHavePhysicalBallotsLink'
import style from './index.module.scss'

interface StationProcessStatisticProps {
    show: (props: PopupProps) => void
    hide: () => void
}

function StationProcessStatistic({ show, hide }: StationProcessStatisticProps) {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()

    const { electionId } = useParams()
    const { data: election } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: restrictions } = useElectionRestrictionsOld()
    const { data: electionResultSummary } = useElectionSummary(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const [restriction, setRestriction] = useState<Restriction | null | undefined>()
    const { data: electionStations } = useElectionStations(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)

    useEffect(() => {
        if (election && restrictions) {
            setRestriction(getElectionRestrictions(election.type, restrictions))
        }
    },
        [election, restrictions])

    const checkOpenStations = () =>
        electionStations?.every(station => !station.stationReport.signed)

    return (
        <div>
            <div className={style.title}>
                {t('station_telling_process_statistic')}
                {restriction?.bypassFlowAllowed
                    && !electionResultSummary?.confirmedBy
                    && checkOpenStations()
                    && <WhatIfWeDontHavePhysicalBallotsLink show={show} hide={hide} />}
            </div>
            <div className={style.stations}>
                <StationsProgress />
                <StationsVotes />
            </div>
        </div>
    )
}

export default StationProcessStatistic