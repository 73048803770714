import { useAuth } from 'hooks'
import { Role } from 'models'
import { useNavigate } from 'react-router-dom'
import { dashboard } from '../features/dashboard'
import Dashboard from '../features/tellingStation/pages/Dashboard'

function DefaultRouter() {
    const { auth } = useAuth()
    const navigate = useNavigate()

    if (!auth?.id) {
        navigate('/sign-in', { replace: true })
    }
    switch (auth!.role) {
        case Role.TELLING_STATION:
            return <Dashboard />
        default:
            return <dashboard.pages.SandboxDashboard />
    }
}

export default DefaultRouter