import { RefreshTokenInfoContext } from 'context'
import { RefreshTokenInfo } from 'models'
import { PropsWithChildren, useState } from 'react'

function RefreshTokenInfoProvider({ children }: PropsWithChildren) {
    const [refreshTokenInfo, setRefreshTokenInfo] = useState<RefreshTokenInfo>({})

    return (
        <RefreshTokenInfoContext.Provider value={{ refreshTokenInfo, setRefreshTokenInfo }} >
            {children}
        </RefreshTokenInfoContext.Provider>
    )
}

export default RefreshTokenInfoProvider