import Card, { CardProps } from 'components/atoms/Card'
import style from './index.module.scss'

interface PanelProps extends Omit<CardProps, 'noContentPadding' | 'contentClassName'> {
    showSupportedBrowserHint ?: boolean
    maxWidth?: string
}

function SandboxPanel({ children, title, noHeaderLine = true, maxWidth }: PanelProps) {
    return (
        <div className={style.panel}>
            <Card highlightedWithOrange
                title={title}
                noHeaderLine={noHeaderLine}
                contentClassName={style.adaptiveContent}
            >
                {children}
            </Card>
        </div>
    )
}

export default SandboxPanel