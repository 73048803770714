import { AbilityBuilder } from '@casl/ability'
import { Auth, Role } from 'models'
import { AppAbility } from './ability'

export type MergedResultsSubject = 'ChangeTieResultsButton' | 'InfoWording' | 'DeleteRoundButton'

export function defineMergedResultsAbility(builder: AbilityBuilder<AppAbility>, auth?: Auth) {
    const { can } = builder

    if (auth && (auth.role === Role.CHIEF_TELLER ||
        auth?.role === Role.CHIEF_TELLER_ASSISTANT)) {
        can('view', 'ChangeTieResultsButton')
        can('view', 'InfoWording')
    }

    if (auth?.role === Role.SYSTEM_ADMIN) {
        can('view', 'DeleteRoundButton')
    }
}
