import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionType } from 'models'
import { getElectionTypeApiModule } from '../../utils'

export type AnnounceRidvanElectionPeriodParams = {
    type: ElectionType
    electionPeriod: string
}

export function useAnnounceRidvanElectionPeriod() {
    const axios = useAxios()
    const queryClient = useQueryClient()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function announceRidvanElectionPeriod({ type, electionPeriod }: AnnounceRidvanElectionPeriodParams) {
        return await axios(moduleUrl
            + `ridvan-election-periods/${electionPeriod}/${getElectionTypeApiModule(type)}/announce`,
            {
                method: 'post'
            })
    }

    return useMutation({
        mutationFn: announceRidvanElectionPeriod,
        onSuccess: () => {
            queryClient.invalidateQueries(['chief-teller-elections'])
        }
    })
}