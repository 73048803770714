import style from './index.module.scss'

type SubtitleStatisticProps = {
    number: string,
    text: string,
    statistic: number
}

function SubtitleStatistic({ number, text, statistic }: SubtitleStatisticProps) {

    return (
        <div className={style.totalSubTitle}>
            <div>
                <span className={style.totalStatisticNumber}>{number}</span>
                {text}
            </div>
            <div className={style.totalStatistic}>
                {statistic}
            </div>
        </div>
    )
}

export default SubtitleStatistic