import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionSchedule, ElectionType } from 'models'
import { getElectionTypeApiModule } from '../../utils'

type setUpScheduleParams = {
    schedule: ElectionSchedule,
    electionPeriod: string,
    type: ElectionType
}


export function useSetUpRidvanSchedule() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function setUpRidvanSchedule({ schedule, electionPeriod, type }: setUpScheduleParams) {
        return await axios(moduleUrl
            + `ridvan-election-periods/${electionPeriod}/${getElectionTypeApiModule(type)}/set-up-schedule`,
            {
                method: 'post',
                data: schedule
            })

    }

    return useMutation({
        mutationFn: setUpRidvanSchedule
    })
}