import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { TellingStation } from 'models'

type StationProps = {
    electionId: string,
    stationId: string
}

export const useStation = ({ electionId, stationId }: StationProps, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getStation = async (): Promise<TellingStation> => {
        const response = await axios<TellingStation>(moduleUrl + `elections/${electionId}/stations/${stationId}`)

        return response.data
    }

    return useQuery({
        queryKey: ['station-details', stationId, 'for-eleciton', electionId],
        queryFn: getStation,
        enabled
    })
}