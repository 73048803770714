import SaSignInPanel from '../components/SaSignInPanel'
import AuthPageWrapper from '../components/common/AuthPageWrapper'

function SaAuth() {
    return (
        <AuthPageWrapper>
            <SaSignInPanel />
        </AuthPageWrapper>
    )
}

export default SaAuth