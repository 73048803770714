import { SortOrder } from 'models'
import { Voter, VotersFilter } from '../models'
export const filterVoters = (allVoters: Voter[], filter: VotersFilter): Voter[] => {
    let voters = [...allVoters]

    let sortingOrder = SortOrder.ASC
    let sortingField = 'name'

    if (filter.sortBy) {
        sortingOrder = filter.sortBy.order
        sortingField = filter.sortBy.field
    }

    voters = voters.sort((a: any, b: any) => {
        const conditions = sortingOrder === SortOrder.ASC
            ? [
                (p1: any, p2: any) => p1 > p2,
                (p1: any, p2: any) => p1 < p2
            ]
            : [
                (p1: any, p2: any) => p1 < p2,
                (p1: any, p2: any) => p1 > p2
            ]

        if (sortingField === 'verifier') {
            if (!a[sortingField] && b[sortingField]) {
                return 1
            }
            if (!b[sortingField] && a[sortingField]) {
                return -1
            }
        }

        if (conditions[0](a[sortingField], b[sortingField])) {
            return 1
        }
        if (conditions[1](a[sortingField], b[sortingField])) {
            return -1
        }

        if (sortingField !== 'name') {
            const field = 'name'
            if (a[field] > b[field]) {
                return 1
            }
            if (a[field] < b[field]) {
                return -1
            }
        }

        return 0
    })

    if (filter.name) {
        const names = filter.name!.trim().toLowerCase().split(' ').filter(n=>!!n)
        names.forEach(name=>{
            voters = voters.filter(v => v.name.toLowerCase().includes(name))
        })
    }

    if (filter.id) {
        const id = filter.id!.trim().toLowerCase()
        voters = voters.filter(v => v.id.toLowerCase().includes(id))
    }

    if (filter.state?.length) {
        const states = filter.state!.map(s => s.toLowerCase())
        voters = voters.filter(v => states.includes(v.homeLocality?.state?.toLowerCase()))
    }

    if (filter.homeLocality?.length) {
        const localities = filter.homeLocality!.map(l => l.toLowerCase())
        voters = voters.filter(v => localities.includes(v.homeLocality?.name?.toLowerCase()))
    }

    return voters
}