export enum ElectionType {
    RIDVAN_ELECTION = 'RIDVAN_ELECTION',
    LOCAL_DELEGATE_ELECTION = 'LOCAL_DELEGATE_ELECTION',
    BY_ELECTION = 'BY_ELECTION',
    NATIONAL_DELEGATE_ELECTION = 'NATIONAL_DELEGATE_ELECTION',
    OFFICERS_ELECTION = 'OFFICERS_ELECTION',
    BALLOTING_FOR_REGIONAL_BAHAI_COUNCILS = 'BALLOTING_FOR_REGIONAL_BAHAI_COUNCILS',
    BREAKING_TIE = 'BREAKING_TIE'
}

