import { Form, FormInstance } from 'antd'
import { LookupOption } from 'components/molecules/LookupSelect'
import { useEffect, useState } from 'react'
import { OfficersElectionTeller } from '../../../../models'
import TellerSelectionForm from '../TellerSelectionForm'
import style from './index.module.scss'

interface ManageTellersFormProps {
    form: FormInstance,
    initAssignedTellers: (OfficersElectionTeller | undefined)[]
}

function ManageTellersForm({ form, initAssignedTellers }: ManageTellersFormProps) {
    const [selectedTellers, setSelectedTellers] = useState<LookupOption[]>([])

    useEffect(() => {
        const selected: LookupOption[] = []
        initAssignedTellers.forEach(t => {
            if (!!t?.id) {
                selected.push({ ...t })
            }
        })
        setSelectedTellers(selected)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initAssignedTellers])

    const updateSelectedTellers = () => {
        const tellers = form.getFieldValue('tellers')
        setSelectedTellers(tellers.filter((t: any) => !!t?.id))
    }

    return (
        <>
            <Form
                layout="vertical"
                className={style.manageTellersForm}
                name="tellers"
                form={form}>
                <Form.List name="tellers">
                    {(fields) => <>
                        {fields.map((field) => <TellerSelectionForm
                            selectedTellers={selectedTellers}
                            key={field.key}
                            tellerName={field.name}
                            onChange={updateSelectedTellers}
                            form={form} />
                        )}
                    </>
                    }
                </Form.List>
            </Form>
        </>
    )
}

export default ManageTellersForm