import { ElectionStatusType, RidvanElectionPeriod } from 'models'

export const isRidvanStage = (ridvanElectionPeriod: RidvanElectionPeriod) => {
    if (!ridvanElectionPeriod?.localDelegateElectionStage)
        return true

    const openLocalDelegates =
        ridvanElectionPeriod.localDelegateElectionStage.elections
            .filter(e => e.status !== ElectionStatusType.COMPLETED).length

    return !openLocalDelegates
}