import { FormInstance } from 'antd'
import { useLocalityOfficers, useMembersName } from 'api'
import { useAuth } from 'hooks'
import { LsaOfficerRole, NoLongerServingOfficer } from 'models'
import { useEffect, useState } from 'react'
import { useOfficers } from '../../hooks'
import { ByElectionOfficer } from '../../models'
import CurrentLsaMembership from '../CurrentLsaMembership'
import NoLongerServing from '../NoLongerServing'
import style from './index.module.scss'

const TOTAL_VACANCIES = 9

type OfficersParams = {
    form: FormInstance,
    noLongerServing: NoLongerServingOfficer[]
}

function Officers({ form, noLongerServing }: OfficersParams) {
    const { auth } = useAuth()
    const { setOfficers } = useOfficers()

    const [memberIds, setMemberIds] = useState<string[]>([])

    const { data: localityOfficers } = useLocalityOfficers(auth?.homeLocality.code || '', !!auth)
    const { data: membersName } = useMembersName(memberIds, !!memberIds.length)

    useEffect(() => {
        if (localityOfficers) {
            setMemberIds(localityOfficers.map(o => o.id))
        }
    }, [localityOfficers])

    useEffect(() => {
        if (!localityOfficers)
            return

        const officersLoaded = !!membersName || !localityOfficers.length
        if (officersLoaded) {
            const officers: ByElectionOfficer[] =
                localityOfficers?.map(o => {
                    const memName = membersName?.find(n => n.id === o.id)
                    const noLongerServingOfficer = noLongerServing.find(s => s.id === o.id)

                    return {
                        ...o, name: memName?.name, noLongerServing: !!noLongerServingOfficer,
                        role: noLongerServingOfficer?.officeHeld
                            ?.split(',')
                            .map(r => (r as LsaOfficerRole))
                            .filter(r => r !== LsaOfficerRole.MEMBER)
                            || o.role,
                        reasonForVacancy: (noLongerServingOfficer?.reasonForVacancy as any) !== 'NONE'
                            ? noLongerServingOfficer?.reasonForVacancy : undefined
                    }
                }) || []

            noLongerServing.filter(o => !o.id).forEach(o => {
                officers.push({
                    id: `${TOTAL_VACANCIES - officers.length}_empty`,
                    name: '',
                    noLongerServing: true,
                    role: []
                })
            })

            while (officers.length < TOTAL_VACANCIES) {
                officers.push({
                    id: `${TOTAL_VACANCIES - officers.length}_empty`,
                    name: '',
                    noLongerServing: false,
                    role: []
                })
            }
            officers.sort((a, b) => {
                if (!a.name && !b.name)
                    return 0
                if (!a.name)
                    return 1
                if (!b.name)
                    return -1

                return a.name.localeCompare(b.name, undefined, { numeric: true })
            })
            setOfficers(officers)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membersName, localityOfficers, noLongerServing])

    return (
        <div className={style.officers}>
            <CurrentLsaMembership />
            <NoLongerServing form={form} />
        </div>
    )
}

export default Officers