import { MilestoneColorType } from '../../models/milestone'

export const useMilestoneColorMap = () => ({
    toString: (color: MilestoneColorType) => {
        switch (color) {
            case MilestoneColorType.SUCCESS:
                return 'success'
            case MilestoneColorType.PRIMARY:
                return 'primary'
            case MilestoneColorType.ACTIVE:
                return 'active'
            case MilestoneColorType.DEFAULT:
                return 'default'
        }
    }
})