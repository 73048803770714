import PopupFooter from 'components/atoms/PopupFooter'
import PopupTextContent from 'components/atoms/PopupTextContent'
import PopupTitle from 'components/atoms/PopupTitle'
import { useTranslation } from 'react-i18next'
import { usePopup } from './usePopup'

export type InfoPopupSettings = {
    title: React.ReactNode,
    text: React.ReactNode,
    okText?: React.ReactNode,
    onOk: (e: React.MouseEvent<HTMLButtonElement>) => void,
    onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void,
    onClose?: () => void,
    stayOpenedOnCancel?: boolean,
    closeIcon?: boolean
}

export const useInfoPopup = () => {
    const { show, hide, popupPortal: infoPortal } = usePopup()
    const { t } = useTranslation()

    const showInfo = (settings: InfoPopupSettings) => {

        const confirm = (e: React.MouseEvent<HTMLButtonElement>) => {
            settings.onOk(e)
            hide()
        }

        const close = () => {
            settings.onClose?.()
            if (settings.stayOpenedOnCancel) {
                return false
            } else {
                hide()
            }
        }

        show({
            ...settings,

            title: <PopupTitle>{settings.title}</PopupTitle>,
            children: <PopupTextContent>{settings.text}</PopupTextContent>,
            footer:
                <PopupFooter>
                    <button className={'btn-main-primary'} onClick={(e) => confirm(e)}>
                        {settings.okText || t('ok')}
                    </button>
                </PopupFooter>,
            onCancel: close
        })
    }

    return { showInfo, infoPortal }
}