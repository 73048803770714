import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { VotersSummary } from '../../models'

export function useReportVotersSummary() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    async function reportVotersSummary(votersSummary: VotersSummary) {
        return await axios(moduleUrl
            + 'stations/my/report-voter-summary',
            {
                method: 'post',
                data: {
                    ...votersSummary
                }
            })
    }

    return useMutation({
        mutationFn: reportVotersSummary
    })
}