import { Gender } from 'models'
import { useTranslation } from 'react-i18next'

export const useGenderMap = () => {
    const { t } = useTranslation('election')

    return {
        toString: (gender: Gender): string => {
            switch (gender) {
                case Gender.MALE:
                    return t('male')
                case Gender.FEMALE:
                    return t('female')
            }
        }
    }

}