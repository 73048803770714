import style from './index.module.scss'

type PopupButtonsProps = {
    cancelButtonName: string,
    proccedButtonName: string,
    onCancel: () => void,
    onProceed: () => void,
    proccedDisabled?: boolean
}

function PopupButtons({
    cancelButtonName,
    proccedButtonName,
    onCancel,
    onProceed,
    proccedDisabled
}: PopupButtonsProps) {

    return (
        <div className={style.buttonsWrapper}>
            <button className={`${style.cancel} btn-main-secondary`} onClick={onCancel}>
                <span>{cancelButtonName}</span>
            </button>
            <button
                disabled={proccedDisabled} className={`${style.proceed} btn-main-primary`} onClick={() => onProceed()}>
                <span>{proccedButtonName}</span>
            </button>
        </div>
    )
}

export default PopupButtons
