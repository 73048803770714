import { dayjsToString } from 'utils'

export const getLocalDelegateDate = (elements: string[]) => {
    const uniqDates = elements.filter(function (item: string, index: number) {
        return elements.indexOf(item) === index
    })

    if (uniqDates.length === 1) {
        return dayjsToString(uniqDates[0], 'MM/DD/YYYY')
    } else {
        const dateObjects = elements.map((date: any) => new Date(date))
        const sorted = dateObjects.sort(function (a: any, b: any) { return a - b })
        const stringDates = sorted.map((date: any) => dayjsToString(date, 'MM/DD/YYYY'))

        return `${stringDates[0]} - ${stringDates[stringDates.length - 1]}`
    }
}

