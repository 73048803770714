import { queryClient } from 'config/query-client'
import { ElectionResultSummary } from 'models'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUnmergeResults } from '../../api'

export function useUnmergeResultsLogic(
    electionId: string | undefined,
    summary: ElectionResultSummary | undefined,
    isError: boolean | undefined) {
    const [waitingForUnmergeResults, setWaitingForUnmergeResults] = useState(false)
    const { mutateAsync: unmergeResultsMutation } = useUnmergeResults()
    const navigate = useNavigate()

    const confirmUnmergeResults = async () => {
        if (electionId) {
            await unmergeResultsMutation(electionId.toString())
        }
        setWaitingForUnmergeResults(true)
    }

    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval> | undefined

        if (waitingForUnmergeResults) {
            intervalId = setInterval(async () => {
                await queryClient.invalidateQueries(['election'])
                await queryClient.removeQueries(['election-summary'])
                await queryClient.invalidateQueries(['election-summary'])

                if (!isError && !!summary?.mergedBy) {
                    clearInterval(intervalId)
                    setWaitingForUnmergeResults(false)
                    navigate('/merge-results', { replace: true })
                }
            }, 1000)
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitingForUnmergeResults, summary, isError])

    return {
        waitingForUnmergeResults,
        confirmUnmergeResults
    }
}

export default useUnmergeResultsLogic