import { useEffect } from 'react'
import SignIn from '../components/SignIn'

function SandboxSignIn() {

    useEffect(() => {
        sessionStorage.removeItem('includeVoterVerificationTips')
        sessionStorage.removeItem('includeEnterBallotsTips')
        sessionStorage.removeItem('includeTipsAfterFirstBallot')
        sessionStorage.removeItem('includeReportingTips')
        sessionStorage.removeItem('showPdfDownloadSuggestion')
    }, [])

    return (
        <SignIn />
    )
}

export default SandboxSignIn