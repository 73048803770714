import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { CSSProperties } from 'react'
import { dayjsToString } from 'utils'
import { useOfficersElectionsData } from '../../hooks'
import OfficersElectionReportCell from '../ElectionReportCell'
import style from './index.module.scss'

type ElectionsItemProps = {
    index: number,
    style?: CSSProperties
}

function OfficersElectionsItem({ index, style: itemStyle }: ElectionsItemProps) {

    const { officersElectionsData } = useOfficersElectionsData()
    const electionItem = officersElectionsData?.elections[index]

    return (
        <div className={style.listItem} style={itemStyle}>
            <div style={{ width: 200 }}
                className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {electionItem?.localityName}
                </OptionalTooltip>
            </div>
            <div style={{ width: 130 }}
                className={style.column}>
                {electionItem?.localityCode}
            </div>
            <div style={{ width: 140 }}
                className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {electionItem?.chairman || String.fromCharCode(8212)}
                </OptionalTooltip>
            </div >

            <div style={{ width: 140 }}
                className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {electionItem?.viceChairman || String.fromCharCode(8212)}
                </OptionalTooltip>
            </div >

            <div style={{ width: 150 }}
                className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {electionItem?.treasurer || String.fromCharCode(8212)}
                </OptionalTooltip>
            </div >

            <div style={{ width: 140 }}
                className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {electionItem?.correspondenceSecretary || String.fromCharCode(8212)}
                </OptionalTooltip>
            </div >

            <div style={{ width: 140 }}
                className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {electionItem?.recordSecretary || String.fromCharCode(8212)}
                </OptionalTooltip>
            </div >

            <div style={{ width: 170 }}
                className={style.column}>
                <OptionalTooltip contenWrapperClassName="ellipsis">
                    {dayjsToString(electionItem.lastConfirmedElectionDate, 'MM/DD/YYYY')
                        || String.fromCharCode(8212)}
                </OptionalTooltip>
            </div >

            <div style={{ width: 100 }}
                className={`${style.column} ${style.report}`}>
                <OfficersElectionReportCell election={electionItem} />
            </div >
        </div>
    )
}

export default OfficersElectionsItem