import DefineDashboardComponent from './components/DefineDashboardComponent'
import TellingStationProcess from './components/TellingStationProcess'
import { enterBallots } from './features/enterBallots'
import { reporting } from './features/reporting'
import { useStationTellers, useVoteSummary } from './features/reporting/api'
import StatisticalSignature from './features/reporting/components/StatisticalSignature'
import { voterVerification } from './features/voterVerification'
import { useTellingStationInfo } from './hooks'
import Dashboard from './pages/Dashboard'
import ViewVoterVerification from './pages/ViewVoterVerification'
import TellingStationProvider from './providers/TellingStationProvider'


export const tellingStation = {
    api: {
        queries: {
            useStationTellers,
            useVoteSummary
        }
    },
    components: {
        StatisticalSignature,
        DefineDashboardComponent,
        TellingStationProcess
    },
    pages: {
        Dashboard,
        ViewVoterVerification
    },
    hooks: {
        useTellingStationInfo
    },
    reporting,
    voterVerification,
    enterBallots,
    providers: { TellingStationProvider }
}