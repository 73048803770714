import { Col, Form, FormInstance, Input, Row } from 'antd'
import { useOfficersElectionCandidates } from 'api'
import HighlightedDuplicatesSelect from 'components/molecules/HighlightedDuplicatesSelect'
import { LookupOption } from 'components/molecules/LookupSelect'
import { useAuth } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useLsaTellersEditing } from '../../hooks'

type TellerSelectionFormProps = {
    selectedTellers: LookupOption[]
    tellerName: number,
    onChange?: () => void,
    form: FormInstance<any>,
}

function TellerSelectionForm({
    selectedTellers,
    tellerName,
    form,
    onChange
}: TellerSelectionFormProps) {
    const { t } = useTranslation('officers-election')
    const { isEditing: editMode } = useLsaTellersEditing()
    const { auth } = useAuth()

    const { data: tellerCandidats }
        = useOfficersElectionCandidates(auth?.homeLocality?.code || '', !!auth?.homeLocality)

    const onTellerChange = (teller?: LookupOption) => {
        if (teller) {
            form.setFieldValue(['tellers', tellerName], { ...teller })
        } else {
            form.setFieldValue(['tellers', tellerName], null)
        }
        form.validateFields()
        onChange?.()
    }

    const defaultValue = form.getFieldValue(['tellers', tellerName])?.id
        ? form.getFieldValue(['tellers', tellerName]) : null


    const isRequired = !!form.getFieldValue(['tellers']).find((t: any) => !!t && !!Object.keys(t).length)

    return (
        <Row gutter={16}>
            <Col span={12}>
                <Form.Item
                    className="no-padding"
                    name={[tellerName, 'name']}
                    label={editMode ? t('full_name') : `${t('teller')} ${tellerName + 1}`}
                    rules={
                        editMode && isRequired
                            ? [{
                                required: true,
                                message: t('please_select_the_name_or_remove_both_tellers')
                            }]
                            : []
                    }
                >
                    <HighlightedDuplicatesSelect
                        placeholder={t('select_input', { input: t('full_name') })}
                        onChange={(teller) => onTellerChange(teller)}
                        allowClear={!!form.getFieldValue(['tellers', tellerName])}
                        defaultValue={defaultValue}
                        options={tellerCandidats?.sort((a, b) => a.name.localeCompare(b.name))}
                        alreadySelectedOptions={selectedTellers}
                        disabled={!editMode}
                        disableDuplicates />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    className="no-padding"
                    name={[tellerName, 'id']}
                    label={t('bahai_id')}>
                    <Input
                        disabled
                        placeholder={t('bahai_id')}
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}

export default TellerSelectionForm