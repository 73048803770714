import { useEffect, useRef, useState } from 'react'

export const useScrollToBottom = (dependency: any[]) => {
    const [shouldScroll, setShouldScroll] = useState(false)
    const bottomRef = useRef<HTMLDivElement | null>(null)
    const targetRef = useRef<HTMLDivElement | null>(null)
    const observerRef = useRef<MutationObserver | null>(null)

    useEffect(() => {
        if (shouldScroll && bottomRef.current) {
            if (targetRef.current) {
                if (!observerRef.current) {
                    observerRef.current = new MutationObserver((mutations) => {
                        mutations.forEach((mutation) => {
                            if (mutation.addedNodes.length > 0 && bottomRef.current) {
                                bottomRef.current.scrollIntoView({ behavior: 'smooth' })
                                if (observerRef.current) {
                                    observerRef.current.disconnect()
                                    observerRef.current = null
                                    setShouldScroll(false)
                                }
                            }
                        })
                    })

                    const config = { childList: true, subtree: true }
                    observerRef.current.observe(targetRef.current, config)
                }
            } else {
                bottomRef.current.scrollIntoView({ behavior: 'smooth' })
                setShouldScroll(false)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldScroll, bottomRef.current, targetRef.current, ...dependency])

    return { bottomRef, targetRef, setShouldScroll }
}

export default useScrollToBottom
