import ContentWrapper from 'components/atoms/ContentWrapper'
import StationReport from '../components/StationReport'

function ViewStationReport() {
    return (
        <>
            <ContentWrapper>
                <StationReport />
            </ContentWrapper>
        </>
    )
}

export default ViewStationReport
