import { useElectionPushResult, useLocalDelegateElectionPushResult } from 'api'
import Tooltip from 'components/atoms/Tooltip'
import ElectionPushBadge from 'components/molecules/ElectionPushBadge'
import { electionEditing } from 'features/electionEditing'
import { ReactComponent as Bin } from 'icons/bin.svg'
import { ReactComponent as InfoRounded } from 'icons/info-rounded.svg'
import { ReactComponent as Pen } from 'icons/pen.svg'
import { ElectionPushStatus, ElectionStatusType, ElectionType } from 'models'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { dayjsToString } from 'utils'
import { ElectionItem, ElectionItemBase } from '../../../models'
import styles from './index.module.scss'

type SubHeaderRightSectionProps = {
    election: ElectionItemBase,
    deleteElection?: (electionPeriodId: number) => void
}

const SubHeaderRightSection: React.FC<SubHeaderRightSectionProps> = ({
    election,
    deleteElection
}) => {
    const { t } = useTranslation('election')
    const navigate = useNavigate()
    const [isRidvanStage, setIsRidvanStage] = useState(false)
    const [isRidvanSecondStage, setIsRidvanSecondStage] = useState(false)
    const { data: ridvanElectionPeriod } = electionEditing.ridvan.api.useElectionPeriod(
        election.electionPeriodId.toString(),
        election.type === ElectionType.RIDVAN_ELECTION)
    const { data: ldePushResult } =
        useLocalDelegateElectionPushResult(election?.electionPeriodId.toString() || '',
            !!election && (election?.type === ElectionType.LOCAL_DELEGATE_ELECTION || isRidvanSecondStage))
    const { data: electionPushResult } =
        useElectionPushResult((election as ElectionItem)?.electionId?.toString() || '',
            !!election && election.type !== ElectionType.LOCAL_DELEGATE_ELECTION)

    const pushResult = election.type === ElectionType.LOCAL_DELEGATE_ELECTION ? ldePushResult : electionPushResult

    useEffect(() => {
        if (ridvanElectionPeriod) {
            setIsRidvanStage(electionEditing.ridvan.utils.isRidvanStage(ridvanElectionPeriod))
            setIsRidvanSecondStage(electionEditing.ridvan.utils.isRidvanSecondStage(ridvanElectionPeriod))
        }
    }, [ridvanElectionPeriod])

    if (!deleteElection) return <></>

    const deleteElectionButton = <button
        className="btn-main-tertiary-md"
        onClick={() => deleteElection(election.electionPeriodId)}>
        <Bin width="15" height="16" />
        <div>{t('delete_election')}</div>
    </button>

    const DraftSection = () => {
        if (election.type === ElectionType.RIDVAN_ELECTION && !isRidvanStage) {
            return (
                <div className={styles.infoMessage}>
                    <InfoRounded />
                    {t('ridvan_setup_can_be_conducted_as_soon_as_the_local_delegate_election_is_completed')}
                </div>
            )
        }

        return (
            <div className={styles.btnWrapper}>
                {(election.type !== ElectionType.RIDVAN_ELECTION || !isRidvanSecondStage) && deleteElectionButton}
                {
                    (election.type !== ElectionType.RIDVAN_ELECTION || !isRidvanSecondStage || !!ldePushResult)
                    && <button
                        className="btn-main-primary-md not-underline"
                        onClick={() => navigateToEdit()}
                    >
                        {t('complete_setup')}
                    </button>
                }
            </div>
        )
    }

    const navigateToEdit = () => {
        let editorPrefix = ''
        switch (election.type) {
            case (ElectionType.LOCAL_DELEGATE_ELECTION):
            case (ElectionType.RIDVAN_ELECTION):
                editorPrefix = 'ridvan'
                break
            case (ElectionType.BY_ELECTION):
                editorPrefix = 'by'
                break
            case (ElectionType.NATIONAL_DELEGATE_ELECTION):
                editorPrefix = 'national-delegate'
                break
        }
        navigate(`${editorPrefix}/${election.electionPeriodId}`)
    }

    switch (election.status) {
        case ElectionStatusType.DRAFT:
            return <DraftSection />
        case ElectionStatusType.NOT_STARTED:
            return (
                <div className={styles.btnWrapper}>
                    <button
                        className="btn-main-tertiary-md"
                        onClick={() => navigateToEdit()}
                    >
                        <Pen width="15" height="16" />
                        <div>{t('common:edit')}</div>
                    </button>
                    {!isRidvanSecondStage && deleteElectionButton
                    }
                </div>
            )
        case ElectionStatusType.COMPLETED:
            return (
                <>
                    {process.env.NODE_ENV === 'development' && deleteElectionButton}
                    {pushResult?.createdAt && (
                        <>
                            <div className={styles.pushInfo}>
                                <div className={styles.pushInfoItem}>
                                    {t('results_sent_to_emembership2')}
                                </div>
                                {dayjsToString(pushResult.createdAt, 'MM/DD/YYYY hh:mm A')}
                            </div>
                            {pushResult?.status === ElectionPushStatus.REJECTED
                                || pushResult?.status === ElectionPushStatus.AZURE_ERROR
                                ?
                                <Tooltip title={pushResult?.responseError}>
                                    <div>
                                        <ElectionPushBadge status={pushResult?.status || ''} />
                                    </div>
                                </Tooltip>
                                :
                                <div>
                                    <ElectionPushBadge status={pushResult?.status || ''} />
                                </div>}
                        </>
                    )}
                </>
            )
    }

    return <>{process.env.NODE_ENV === 'development' && deleteElectionButton}</>
}

export default SubHeaderRightSection