import { Col, Form, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import Card from 'components/atoms/Card'
import { Checkbox } from 'components/atoms/Checkbox'
import DatePicker from 'components/atoms/DatePicker'
import RequiredMark from 'components/atoms/RequiredMark'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    useJobConfiguration,
    useNotificationsRecipients,
    useSaveDailyReportEmails,
    useSaveEmailRecipients
} from '../../api'
import SectionTitle from '../../features/electionConfig/components/SectionTitle'
import { useAnyFormChanged } from '../../hooks'
import { NotificationName } from '../../model'
import ConfigWrapper from '../ConfigWrapper'
import EmailsInput from '../EmailsInput'
import style from './index.module.scss'

function EmailRecipients() {
    const { t } = useTranslation('configuration')
    const [form] = useForm()
    const [isValidationActive, setIsValidationActive] = useState<boolean>(false)
    const { data: ndeReportRecipients } = 
        useNotificationsRecipients(NotificationName.NATIONAL_DELEGATE_ELECTION_REPORT_CREATED)
    const { data: byElectionReportRecipients } = useNotificationsRecipients(NotificationName.BY_ELECTION_REPORT_CREATED)
    const [emailValidationState, setEmailValidationState] = useState({
        byElection: true,
        ndeReport: true
    })
    const { data: emailNotificationsInfo } = useJobConfiguration()
    const { mutateAsync: saveDailyReportEmails } = useSaveDailyReportEmails()
    const { mutateAsync: saveEmailRecipients } = useSaveEmailRecipients()
    const { isAnyFormChanged, setIsAnyFormChanged } = useAnyFormChanged()

    const updateEmailValidationState = (key: string, isValid: boolean) => {
        setEmailValidationState(prevState => ({
            ...prevState,
            [key]: isValid
        }))
    }

    useEffect(() => {
        form.setFieldsValue({
            isEmailEnabled: emailNotificationsInfo?.enabled,
            notificationsTime: dayjs(emailNotificationsInfo?.reportJobConfiguration.triggerTime, 'hh:mm:ss'),
            byElectionNewRecipients: byElectionReportRecipients || [],
            ndeReportNewRecipients: ndeReportRecipients || []
        })
    }, [emailNotificationsInfo, byElectionReportRecipients, form])

    const saveChanges = async () => {
        setIsValidationActive(true)
        const allEmailsValid = emailValidationState.byElection && emailValidationState.ndeReport
    
        if (allEmailsValid && emailNotificationsInfo) {
            const updatedEmailInfo = {
                ...emailNotificationsInfo,
                enabled: form.getFieldValue('isEmailEnabled'),
                reportJobConfiguration: {
                    triggerTime: form.getFieldValue('notificationsTime').format('HH:mm:ss')
                }
            }
            await saveDailyReportEmails(updatedEmailInfo)
            await saveEmailRecipients({
                emailRecipients: form.getFieldValue('byElectionNewRecipients') || [],
                notificationName: NotificationName.BY_ELECTION_REPORT_CREATED
            })
            await saveEmailRecipients({
                emailRecipients: form.getFieldValue('ndeReportNewRecipients') || [],
                notificationName: NotificationName.NATIONAL_DELEGATE_ELECTION_REPORT_CREATED
            })
            setIsAnyFormChanged(false)
        }
    }

    const handleFormChange = () => {
        setIsAnyFormChanged(true)
    }

    const cardTitle = (
        <div className={style.content}>
            <div className={style.label}>{t('email_recipients')}</div>
            <button className={`${style.commentButton} btn-main-primary`}
                type="button"
                disabled={!isAnyFormChanged}
                onClick={saveChanges}>
                {t('save_changes')}
            </button>
        </div>
    )

    return (
        <Card
            title={cardTitle}
            className={style.emailsCard}
            noContentPadding={true}
        >
            <ConfigWrapper>
                <Form form={form} onValuesChange={handleFormChange}>
                    <Row gutter={16} className={style.a}>
                        <Col span={8}>
                            <SectionTitle> 1. {t('daily_report_with_by_election_statistics')}:</SectionTitle>
                            <div className={`${style.inputLabel}`}>{t('time')}<RequiredMark /></div>
                            <div className={style.inputBody}>
                                <Form.Item name="notificationsTime">
                                    <DatePicker
                                        placeholder={t('select_input', { input: t('time') })}
                                        inputReadOnly={true}
                                        format="HH:mm A"
                                        picker="time"
                                        className={`${style.select} obs`}
                                        onChange={handleFormChange}
                                    />
                                </Form.Item>
                                <span className={style.subHeader}> {t('central_timezone_cst')}.</span>
                            </div>
                            <Form.Item name="isEmailEnabled" valuePropName="checked">
                                <Checkbox className={style.checkbox} onChange={handleFormChange}>
                                    <div className={style.checkboxTitle}>{t('enable_disable_email')}</div>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item name="byElectionNewRecipients">
                                <EmailsInput
                                    className={style.emailInput}
                                    initialEmails={byElectionReportRecipients || []}
                                    isValidationActive={isValidationActive}
                                    onChange={(emails, isValid) => {
                                        form.setFieldsValue({ byElectionNewRecipients: emails })
                                        updateEmailValidationState('byElection', isValid)
                                        if (isValid && emailValidationState.ndeReport) {
                                            setIsValidationActive(false)
                                        }
                                        handleFormChange()
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <SectionTitle> 2. {t('nde_tellers_report_for_assembly_s_use_only')}:</SectionTitle>
                            <div className={style.subHeader}>{t('send_by_default_to_hostinglsacontactemail')}.</div>
                        </Col>
                        <Col span={16}>
                            <div className={style.emailInput}>
                                <Form.Item name="ndeReportNewRecipients">
                                    <EmailsInput
                                        className={style.emailInput}
                                        initialEmails={ndeReportRecipients || []}
                                        isValidationActive={isValidationActive}
                                        onChange={(emails, isValid) => {
                                            form.setFieldsValue({ ndeReportNewRecipients: emails })
                                            updateEmailValidationState('ndeReport', isValid)
                                            if (isValid && emailValidationState.byElection) {
                                                setIsValidationActive(false)
                                            }
                                            handleFormChange()
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ConfigWrapper>
        </Card>
    )
}

export default EmailRecipients