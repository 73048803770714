import ContentWrapper from 'components/atoms/ContentWrapper'
import BackToDashboardLine from 'components/molecules/BackToDashboardLine'
import { useBackToDashboardLine } from 'hooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import RoundResults from '../components/RoundResults'

function RoundResultsInfo() {
    const { t } = useTranslation('teller')
    const navigate = useNavigate()
    const { setBackToDashboardLine } = useBackToDashboardLine()

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine
                sticky
                fixedWidth
                backButtonTitle={t('back_to_merged_results')}
                onBackToDashboardBtnClick={() => { navigate(-1) }} />)

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ContentWrapper>
            <RoundResults />
        </ContentWrapper>
    )
}

export default RoundResultsInfo