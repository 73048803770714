import { useStationBallotsSummary } from 'api'
import { ReactComponent as CheckmarkInDoubleCircleFilled } from 'icons/checkmark-in-double-circle-filled.svg'
import { ReactComponent as Circle } from 'icons/circle.svg'
import { ReactComponent as RoundedCircle } from 'icons/rounded-circle.svg'
import { StationStatisticStatus, TellingStation } from 'models'
import { useGetStationStatus } from '../../hooks'
import style from './index.module.scss'

type ProgressItemProps = {
    station: TellingStation
}

function BallotsEntering({ station }: ProgressItemProps) {
    const { data: summary } = useStationBallotsSummary(
        { electionId: station.election.toString(), stationId: station.id }, !!station)
    const { getStationStatus } = useGetStationStatus()

    if (!summary)
        return <></>

    const status = getStationStatus(station, summary)

    switch (status) {
        case StationStatisticStatus.CREATED:
            return <div className={`${style.status} ${style.untouched}
            ${style.currentStepUntouched} ${style.nextStepUntouched}`}>
                <Circle />
            </div>
        case StationStatisticStatus.LAUNCHED:
            return <div className={`${style.status} ${style.untouched}
            ${style.currentStepExpected} ${style.nextStepUntouched}`}>
                <Circle />
            </div>
        case StationStatisticStatus.VOTERS_VERIFIED:
            return <div className={`${style.status} ${style.expected}
            ${style.currentStepDone} ${style.nextStepExpected}`}>
                <RoundedCircle />
            </div>
        case StationStatisticStatus.BALLOTS_ENTERED:
            return <div className={`${style.status} ${style.done}
            ${style.currentStepDone} ${style.nextStepDone}`}>
                <CheckmarkInDoubleCircleFilled />
            </div>
        case StationStatisticStatus.REPORT_SIGNED:
            return <div className={`${style.status} ${style.done}
                 ${style.currentStepDone} ${style.nextStepDone}`}>
                <CheckmarkInDoubleCircleFilled />
            </div>
    }
}

export default BallotsEntering