import { queryClient } from 'config/query-client'
import { ElectionResultSummary } from 'models'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMergeResults } from '../../api'

export function useMergeResultsLogic(
    electionId: string | undefined,
    summary: ElectionResultSummary | undefined) {
    const navigate = useNavigate()
    const { mutateAsync: mergeResultsMutation } = useMergeResults()
    const [waitingForConfirmMergeResults, setWaitingForConfirmMergeResults] = useState(false)

    const confirmMergeResults = async () => {
        if (electionId) {
            await mergeResultsMutation(electionId.toString())
        }
        setWaitingForConfirmMergeResults(true)
    }
    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval> | undefined

        if (waitingForConfirmMergeResults) {
            intervalId = setInterval(async () => {
                await queryClient.invalidateQueries(['election'])
                await queryClient.invalidateQueries(['election-summary'])

                if (summary?.mergedBy) {
                    clearInterval(intervalId)
                    setWaitingForConfirmMergeResults(false)
                    navigate('/merged-results', { replace: true })
                }
            }, 1000)
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitingForConfirmMergeResults, summary])


    return {
        waitingForConfirmMergeResults,
        confirmMergeResults
    }
}

export default useMergeResultsLogic