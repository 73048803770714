import Survey from 'components/molecules/Survey'
import { PropsWithChildren } from 'react'
import style from './index.module.scss'

type LeaveFeedbackProps = PropsWithChildren & {

}

function LeaveFeedback({ children }: LeaveFeedbackProps) {
    return (
        <div className={style.leaveFeedback}>
            {children}
            <Survey />
        </div>
    )
}

export default LeaveFeedback