import { usePlanByElectionPeriod } from './api'
import ByElectionEditing from './pages/ByElectionEditing'

export const byElection = {
    api: {
        mutations: {
            usePlanByElectionPeriod
        }
    },
    pages: {
        ByElectionEditing
    }
}