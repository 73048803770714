import BackToDashboardLine from 'components/molecules/BackToDashboardLine'
import { useBackToDashboardLine } from 'hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function ConfirmedResultsHeader() {
    const navigate = useNavigate()
    const { setBackToDashboardLine } = useBackToDashboardLine()

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine sticky fixedWidth
                onBackToDashboardBtnClick={() => { navigate(-1) }} />
        )

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <></>
}

export default ConfirmedResultsHeader