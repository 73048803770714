import { Select as AntSelect, Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import PopupFooter from 'components/atoms/PopupFooter'
import { Select } from 'components/atoms/Select'
import { useAuth } from 'hooks'
import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useBallotInvalidationReasons } from '../../api/queries'
import style from './index.module.scss'

type MarkBallotAsInvalidProps = {
    onMarkAsInvalid: (invalidationReason: string) => Promise<void>
    onCancel: () => void,
    defaultReasonKey?: string,
    text?: ReactNode
}

function MarkBallotAsInvalid({ onMarkAsInvalid, onCancel, defaultReasonKey, text }: MarkBallotAsInvalidProps) {
    const [form] = useForm()
    const { t } = useTranslation('ballot')
    const { auth } = useAuth()
    const navigate = useNavigate()
    const { data: ballotInvalidationReasons } = useBallotInvalidationReasons(auth?.electionId!)

    useEffect(() => {
        form.setFieldsValue({ reason: defaultReasonKey })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultReasonKey])

    return (
        <>
            <Form
                layout="vertical"
                form={form}>
                {text || <div className={style.note}>{t('please_select_the_reason_why_this_ballot_is_invalid')}</div>}
                <Form.Item
                    name={['reason']}
                    className="no-padding"
                    label={t('ballot_is_invalid_due_to_the_following_reason', { reason: '' })}
                    rules={[{
                        required: true,
                        message: t('common:please_select_the_input', { input: t('reason') })
                    }
                    ]}
                >
                    <Select
                        placeholder={t('common:please_select_the_input', { input: t('reason') })}
                    >
                        {ballotInvalidationReasons?.map(reason => <AntSelect.Option
                            value={reason.id} key={reason.id}>
                            {reason.text}</AntSelect.Option>)}
                    </Select>
                </Form.Item>
            </Form>
            <div className={style.footer}>
                <PopupFooter>
                    <button className="btn-main-secondary"
                        onClick={(e) => {
                            onCancel()
                        }}>
                        {t('common:cancel')}
                    </button>
                    <button
                        type="submit"
                        onClick={async () => {
                            try {
                                await form.validateFields()
                            } catch {
                                return
                            }

                            await onMarkAsInvalid(form.getFieldsValue().reason)
                            navigate('/')
                        }
                        }
                        className={'btn-error-primary'}>
                        {t('mark_as_invalid')}
                    </button>
                </PopupFooter>
            </div>
        </>
    )
}

export default MarkBallotAsInvalid