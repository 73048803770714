import { PropsWithChildren, useState } from 'react'
import { OfficersElectionsContext } from '../context'
import { OfficersElectionsData } from '../model'

function OfficersElectionsProvider({ children }: PropsWithChildren) {
    const [officersElectionsData, setOfficersElectionsData] = useState<OfficersElectionsData>({} as any)

    return (
        <OfficersElectionsContext.Provider value={{ officersElectionsData, setOfficersElectionsData }}>
            {children}
        </OfficersElectionsContext.Provider >
    )
}

export default OfficersElectionsProvider