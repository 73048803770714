import { PropsWithChildren } from 'react'
import style from './index.module.scss'

type ReportLeftCardTitleProps = PropsWithChildren<{
    title: string
}>

function ReportLeftCardTitle({ title, children }: ReportLeftCardTitleProps) {
    return (
        <div className={style.contentWrapper}>
            <div className={style.sticky}>
                <div className={style.title}>{title}</div>
                {children}
            </div>
        </div>
    )
}

export default ReportLeftCardTitle
