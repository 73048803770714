import { useAuth } from 'hooks'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type PanelProps = {
    header?: React.ReactNode
    subheader?: React.ReactNode
    children: React.ReactNode
}

export function Panel({ children }: PanelProps) {

    const { t } = useTranslation()
    const { auth } = useAuth()
    const stringsDetails = {
        name: auth?.name,
        bidNumber: auth?.id,
        interpolation: { escapeValue: false }
    }

    return (
        <div className={style.contentBlock}>
            <div className={style.contentWrapper}>
                <div tabIndex={0} className={style.headerWrapper}>
                    <div className={style.header}>{t('welcome_name', stringsDetails)}</div>
                    <div className={style.semiHeader}>{t('bid_id', { id: stringsDetails.bidNumber })}</div>
                </div>
                <div className={style.childrenWrapper}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Panel