import { useQuery } from '@tanstack/react-query'
import { ApiModule } from 'api/api-module'
import { useAxios } from 'api/hooks'
import { getModuleUrl } from 'api/utils'

export const useCheckReportStatus = (reportId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Report)

    const getReportStatus = async () => {
        const response = await axios<string>(moduleUrl + `report-generation-process/${reportId}/status`)

        return response.data
    }

    return useQuery({
        queryKey: ['report-status', reportId],
        queryFn: getReportStatus,
        enabled
    })
}