import { ReactNode } from 'react'

import styles from './index.module.scss'

export enum BadgeType {
    Preinitial = 'Preinitial',
    Initial = 'Initial',
    Pending = 'Pending',
    Success = 'Success',
    Error = 'Error'
}

type BadgeProps = {
    type: BadgeType
    title: ReactNode
}

const Badge = ({ type, title }: BadgeProps) => (<div className={styles[`badge-${type.toLowerCase()}`]}>{title}</div>)

export default Badge