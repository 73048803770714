import { useQueryClient } from '@tanstack/react-query'
import PopupTitle from 'components/atoms/PopupTitle'
import { PopupProps } from 'components/molecules/Popup'
import { useAbility, useAuth, useHandleStationLogoutError, useInfoPopup } from 'hooks'
import { InvalidationReason } from 'models'
import { useTranslation } from 'react-i18next'
import {
    useDeleteBallot,
    useInvalidateBallot,
    useStationDeleteBallot,
    useStationInvalidateBallot,
    useStationSubmitBallot,
    useStationUpdateBallot,
    useUpdateBallot
} from '../api'
import MarkBallotAsInvalid from '../components/MarkBallotAsInvalid'
import { isVoteVacant } from '../utils'
import style from './style/useBallotActions.module.scss'
import { useBallotVotes } from './useBallotVotes'
import { useBallotVotesActions } from './useBallotVotesActions'

const TOO_FEW_NAMES_KEY = 'TOO_FEW_NAMES'

type VotesMismatchPopupProps = {
    show: (props: PopupProps) => void,
    hide: () => void,
    onOk?: () => void,
    ballotExists?: boolean,
    ballotId: string,
    invalidationReasonsKey: string,
    title: string,
    text: string
}

type SubmitBallotProps = {
    ballotId: string,
    ballotInvalidationReasons?: InvalidationReason[],
    show: (props: PopupProps) => void,
    hide: () => void,
    onSuccess?: () => void
}

type UpdateBallotProps = SubmitBallotProps
type PostBallotProps = SubmitBallotProps & {
    saved: boolean
}

export const useBallotActions = () => {
    const { t } = useTranslation('ballot')
    const { auth } = useAuth()
    const { ability } = useAbility()
    const { mutateAsync: stationSubmit } = useStationSubmitBallot()
    const { mutateAsync: update } = useUpdateBallot()
    const { mutateAsync: stationUpdate } = useStationUpdateBallot()
    const { mutateAsync: deleteAsync } = useDeleteBallot()
    const { mutateAsync: stationDeleteAsync } = useStationDeleteBallot()
    const queryClient = useQueryClient()
    const { mutateAsync: invalidateBallot } = useInvalidateBallot()
    const { mutateAsync: stationInvalidateBallot } = useStationInvalidateBallot()
    const { resetBallot, allValidationReasonsProvided } = useBallotVotesActions()
    const { ballotVotes } = useBallotVotes()
    const { infoPortal, showInfo } = useInfoPopup()
    const { handleError } = useHandleStationLogoutError()

    const markBallotAsInvalid = async (invalidationReason: string, ballotId: string) => {
        await stationSubmit({ ballotId, invalidationReason })

        queryClient.invalidateQueries(['my-station-ballots'])
        resetBallot()
    }

    const deleteBallot = async (comment: string, ballotId: string) => {
        if (ability?.can('edit', 'StationBallot')) {
            await stationDeleteAsync({ id: ballotId, comment })
        } else {
            await deleteAsync({
                id: ballotId, comment, electionId: auth!.electionId!, stationId: ballotId.split('-')[0]
            })
        }
        queryClient.invalidateQueries(['my-station-ballots'])
        queryClient.invalidateQueries(['station-votes-counts'])
    }

    const showVotesMismatchPopup = ({
        show,
        hide,
        onOk,
        ballotExists,
        ballotId,
        invalidationReasonsKey,
        title,
        text
    }: VotesMismatchPopupProps) => {
        show({
            title: <PopupTitle>{title}</PopupTitle >,
            children: <MarkBallotAsInvalid
                text={
                    <>
                        <div className={style.note}>{t('please_check_the_ballot')}</div>
                        <div className={style.tip}>{text}</div>
                    </>
                }
                onCancel={() => hide()}
                onMarkAsInvalid={async (invalidationReason: string) => {
                    try {
                        if (ballotExists) {
                            if (ability?.can('edit', 'StationBallot')) {
                                await stationInvalidateBallot({ id: ballotId, invalidationReason })
                            } else {

                                await invalidateBallot({
                                    id: ballotId,
                                    electionId: auth!.electionId!,
                                    stationId: ballotId.split('-')[0],
                                    invalidationReason
                                })
                            }
                        } else {
                            await markBallotAsInvalid(invalidationReason, ballotId)
                        }
                        onOk?.()
                        hide()
                    } catch (e) {
                        handleError(e, showInfo)

                        return
                    }
                }}
                defaultReasonKey={invalidationReasonsKey} />,
            footer: null
        })
    }

    const postBallot = async ({
        saved,
        ballotId,
        ballotInvalidationReasons,
        show,
        hide,
        onSuccess
    }: PostBallotProps) => {
        try {
            if (!!ballotVotes.find(v => isVoteVacant(v))) {
                showVotesMismatchPopup({
                    show,
                    hide,
                    ballotExists: saved,
                    ballotId: ballotId,
                    title: t('too_few_names'),
                    text: t('if_the_ballot_has_fewer_names_than_required_select_reason_and_mark_it_as_invalid',
                        {
                            reason: ballotInvalidationReasons?.find(r => r.id === TOO_FEW_NAMES_KEY)?.text
                                || 'No reasons'
                        }),
                    invalidationReasonsKey: TOO_FEW_NAMES_KEY
                })

                return
            }

            if (allValidationReasonsProvided()) {
                const votes = ballotVotes.map(v => (v.invalidationReason
                    ? { invalidationReason: v.invalidationReason.id }
                    : { candidate: v.candidate?.id }))
                const callback = saved ?
                    ability?.can('submit', 'StationBallot') ? stationUpdate : update
                    : stationSubmit
                await callback({
                    ballotId,
                    stationId: ballotId.split('-')[0],
                    electionId: auth!.electionId!,
                    votes
                })
                queryClient.invalidateQueries(['my-station-ballots'])
                resetBallot()
                onSuccess?.()
            }
        } catch (e) {
            handleError(e, showInfo)

            return
        }
    }
    const updateBallot = async (props: UpdateBallotProps) => {
        await postBallot({ ...props, saved: true })
    }

    const submitBallot = async (props: SubmitBallotProps) => {
        await postBallot({ ...props, saved: false })
    }

    return {
        infoPortal,
        markBallotAsInvalid,
        deleteBallot,
        showVotesMismatchPopup,
        submitBallot,
        updateBallot
    }
}