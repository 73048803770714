import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export type AuthStationUserParams = {
    stationId: string,
    electionId: string
}

export function useAuthStationUser() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.AuthenticationService)

    async function authStationUser({ stationId, electionId }: AuthStationUserParams) {
        const initWithCred = axios.defaults.withCredentials
        axios.defaults.withCredentials = true
        const response = await axios(moduleUrl
            + `station-user/authenticate?station=${electionId}-${stationId}`,
            {
                method: 'post'
            })
        axios.defaults.withCredentials = initWithCred

        return {
            accessToken: response.headers['access-token']
        }
    }

    return useMutation({
        mutationFn: authStationUser
    })
}