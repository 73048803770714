import { Form } from 'antd'
import Card from 'components/atoms/Card'
import IconWrapper from 'components/atoms/IconWrapper'
import CardHeader from 'components/molecules/CardHeader'
import { queryClient } from 'config/query-client'
import { useAuth, useConfirmPopup } from 'hooks'
import { ReactComponent as Pen } from 'icons/pen.svg'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isFormValid } from 'utils'
import { useOfficerElectionTellers, useSetupOfficersElectionTellers } from '../../../../api'
import { ElectionParticipant, OfficersElectionTeller } from '../../../../models'
import { useLsaTellersEditing } from '../../hooks'
import { SelectedTeller } from '../../models'
import ManageTellersForm from '../ManageTellersForm'
import style from './index.module.scss'

function Tellers() {
    const { t } = useTranslation('officers-election')
    const [form] = Form.useForm()
    const { auth } = useAuth()
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const [tellersArr, setTellersArr] = useState<Array<OfficersElectionTeller | undefined>>([])
    const { isEditing, setIsEditing } = useLsaTellersEditing()
    const { mutateAsync: setupTellers } = useSetupOfficersElectionTellers(auth?.homeLocality?.code || '')

    const { data: assignedTellers, isFetching }
        = useOfficerElectionTellers(auth?.homeLocality?.code || '', !!auth?.homeLocality)

    useEffect(() => {
        if (!isFetching) {
            setTellersArr([
                assignedTellers?.firstTeller || {} as any,
                assignedTellers?.secondTeller || {} as any
            ])
        }
    }, [assignedTellers, isFetching])

    useEffect(() => {
        form.setFieldsValue({ tellers: tellersArr.map(t => t || {}) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tellersArr])

    const save = async () => {
        if (await isFormValid({ form })) {
            showConfirm({
                onOk: async () => {
                    updateTellers()
                },
                title: t('save_changes'),
                text: t('are_you_sure_you_want_to_save_changes_of_these_tellers'),
                okText: t('save_changes')
            })
        }
    }

    const noTellersAssigned = () => tellersArr.every(t => !t?.id)

    const updateTellers = async () => {
        if (isFormChanged()) {
            const tellers = form.getFieldsValue().tellers as ElectionParticipant[]
            const temp: Array<OfficersElectionTeller | undefined> = []
            tellers.forEach(teller => {
                temp.push(teller ? { id: teller.id, name: teller.name } : ({} as any))
            })
            await setupTellers({ firstTeller: temp[0]?.id || '', secondTeller: temp[1]?.id || '' })
        }

        setIsEditing(false)
    }

    const startSettingUp = () => {
        setIsEditing(true)
        if (!tellersArr.length) {
            setTellersArr([{} as any, {} as any])
        }
    }

    const cancel = () => {
        showConfirm({
            onOk: async () => {
                await queryClient.invalidateQueries(['officers-election-tellers', auth?.homeLocality?.code])

                setIsEditing(false)
            },
            title: t('cancel_tellers_changes'),
            text: t('would_you_like_to_cancel_editing_of_tellers'),
            cancelText: t('continue_editing'),
            okText: t('cancel')
        })
    }

    const isFormChanged = () => {
        const tellers = form.getFieldsValue().tellers as SelectedTeller[]
        const diff = tellersArr.filter(initTeller => !tellers.find(formTeller => formTeller?.id === initTeller?.id))

        return !!diff.length
    }

    return (
        <>
            {confirmPortal}
            <Card
                noContentPadding={true}
                className={style.cardContainer}
                title={
                    <CardHeader
                        title={t('tellers')}
                        additionalTitleContent={
                            !isEditing && !noTellersAssigned() &&
                            <button className="btn-main-tertiary-md" onClick={() => { setIsEditing(true) }}>
                                <IconWrapper><Pen /></IconWrapper>
                                {t('edit')}
                            </button>
                        }
                    />
                }>
                <div className={style.tellersContainer}>
                    <span className={style.selectTellersInfo}>{t('please_select_two_tellers')}</span>
                    {noTellersAssigned() && !isEditing &&
                        <button
                            className={`btn-main-primary ${style.actionButton}`}
                            onClick={() => { startSettingUp() }}>
                            {t('set_up_tellers')}
                        </button>
                    }
                    {(isEditing || !noTellersAssigned()) &&
                        <>
                            <ManageTellersForm form={form}
                                initAssignedTellers={tellersArr}
                            />
                            {isEditing &&
                                <div className={style.actionsGroup}>
                                    <button className={`btn-main-secondary ${style.actionButton}`} onClick={cancel}>
                                        {t('cancel')}
                                    </button>
                                    <button className={`btn-main-primary ${style.actionButton}`} onClick={save}>
                                        {t('save_changes')}
                                    </button>
                                </div>
                            }
                        </>
                    }
                </div>
            </Card>
        </>
    )
}

export default Tellers
