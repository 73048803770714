import Footer from 'components/molecules/Footer'
import { useTranslation } from 'react-i18next'
import ErrorPage from '../ErrorAlert'

function SomethingWentWrong() {
    const { t } = useTranslation()

    return (
        <ErrorPage
            title={t('something_went_wrong')}
            text={<div>
                {t('contact_with_the_support_team_please')}
                <Footer />
            </div>
            }
        />
    )
}

export default SomethingWentWrong