import { ElectionStatusType, ElectionType } from 'models'
import React from 'react'
import { DelegateElectionItem, ElectionItem, ElectionItemBase } from '../../models'
import DefaultElectionResultsCard from '../DefaultElectionResultsCard'
import DelegateElectionResultsCard from '../DelegateElectionResultsCard'

type ElectionResultsCardProps = {
    election: ElectionItemBase
    status: ElectionStatusType
}

const ElectionResultsCard: React.FC<ElectionResultsCardProps> = ({ election, status }) => (
    (election.type === ElectionType.LOCAL_DELEGATE_ELECTION)
        ? <DelegateElectionResultsCard election={election as DelegateElectionItem} status={status} />
        : <DefaultElectionResultsCard election={election as ElectionItem} status={status} />
)

export default ElectionResultsCard