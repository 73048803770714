import { TourProps } from 'antd'
import TutorialHighlight from 'components/molecules/TutorialHighlight'
import { ballotingAssets } from 'features/ballotingAssets'
import { tellingStation } from 'features/tellingStation'
import { useAuth } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { useEnterBallotsInfo } from '../../hooks'
import BallotIdReminder from '../BallotIdReminder'

const isVoteVacant = ballotingAssets.utils.isVoteVacant
const storageTutorialPrefix = 'tutorial_'

type BallotEditorProps = {
    hideBallotIdTutorial?: boolean
}

function BallotEditor({ hideBallotIdTutorial }: BallotEditorProps) {
    const { auth } = useAuth()
    const { info } = useEnterBallotsInfo()
    const { ballotVotes } = ballotingAssets.hooks.useBallotVotes()
    const { info: tsInfo, setInfo: setTsInfo } = tellingStation.hooks.useTellingStationInfo()
    const ballotId = () => `${auth?.stationId}-${(info.ballots?.length || 0) + 1}`
    const [openTutorialHighlight, setOpenTutorialHighlight] = useState(false)
    const idRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (ballotVotes?.length) {
            setTsInfo({
                ...tsInfo,
                hasNotSubmittedBallot: ballotVotes.length !==
                    ballotVotes.filter(v => isVoteVacant(v)).length
            })
        } else {
            setTsInfo({ ...tsInfo, hasNotSubmittedBallot: false })
        }

    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ballotVotes])

    useEffect(() => {
        if (auth) {
            const shown = sessionStorage.getItem(`${storageTutorialPrefix}${auth.electionId}-${auth.stationId}`)
            if (!shown && !hideBallotIdTutorial) {
                sessionStorage.setItem(`${storageTutorialPrefix}${auth.electionId}-${auth.stationId}`, 'shown')
                document.documentElement.style.overflow = 'hidden'
                setOpenTutorialHighlight(true)
            }
        }
    }, [auth])

    const steps: TourProps['steps'] = [
        {
            title: '',
            target: () => idRef!.current!,
            style: { width: 210 },
            renderPanel: ((step) => <BallotIdReminder
                onGotItClick={() => {
                    document.documentElement.style.overflow = ''
                    setOpenTutorialHighlight(false)
                    step.onNext?.()
                }}
                ballotId={ballotId()} />)
        }
    ]

    return <>
        <ballotingAssets.components.BallotEditor
            ballotId={ballotId()}
            ballotIdRef={idRef}
            electionId={auth?.electionId!}
        />
        <TutorialHighlight open={openTutorialHighlight}
            placement="right"
            steps={steps}
        />
    </>
}

export default BallotEditor