import { PropsWithChildren, useState } from 'react'
import { CommentInfoContext } from '../context/CommentInfoContext'
import { CommentInfo } from '../model'

function CommentInfoProvider({ children }: PropsWithChildren) {
    const [commentInfo, setCommentInfo] = useState<CommentInfo>({ isEditing: false })

    return (
        <CommentInfoContext.Provider value={{ commentInfo, setCommentInfo }}>
            {children}
        </CommentInfoContext.Provider >
    )
}

export default CommentInfoProvider