import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { NotificationName, NotificationRecipients } from '../../model'

export const useNotificationsRecipients = (notificationName: NotificationName, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Notification)
    

    const getNotificationsRecipients = async () => {
        const recipients = await axios<NotificationRecipients>(moduleUrl + notificationName)

        return recipients.data.asEmail.emailNotificationRecipients.map(
            (recipient: { recipient: string }) => recipient.recipient)
    }

    return useQuery({
        queryKey: ['job-configuration-recipiens', notificationName],
        queryFn: getNotificationsRecipients,
        enabled
    })
}