import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { VersionInfo } from '../../models'

export const useBackendVersion = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.NoModule)

    const getBackendStationVersion = async () => {
        const res =
            await axios<VersionInfo>(moduleUrl)

        return res.data
    }

    return useQuery({
        queryKey: ['be-version'],
        queryFn: getBackendStationVersion,
        enabled
    })
}