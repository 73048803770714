import style from './index.module.scss'

export const getNameWithMatchHighlighting = (name: string, nameFilter: string | undefined) => {
    if (!nameFilter?.length) {
        return <>{name}</>
    }

    const pattern = new RegExp(`(${nameFilter.split(' ').join('|')})`, 'ig')

    return name.split(' ').map((word, index) => {
        const parts = word.split(pattern)

        return (
            <span key={index}>
                {parts.map((part, partIndex) =>
                    pattern.test(part)
                        ? <span key={partIndex} className={style.match}>{part}</span>
                        : part
                )}
            </span>
        )
    }).reduce((prev, curr) => <>{prev} {curr}</>, <></>)
}

