import { useQuery } from '@tanstack/react-query'
import { TELLING_STATION_URL, useAxios } from 'api'
import { VersionInfo } from '../../models'

export const useTellingStationVersion = (enabled?: boolean) => {
    const axios = useAxios()

    const getTellingStationVersion = async () => {
        const res =
            await axios<VersionInfo>(TELLING_STATION_URL)

        return res.data
    }

    return useQuery({
        queryKey: ['ts-version'],
        queryFn: getTellingStationVersion,
        enabled
    })
}