import { useElection, useElectionSummary } from 'api'
import MilestoneItem from 'components/atoms/MilestoneItem'
import ElectionBadge from 'components/molecules/ElectionBadge'
import { useAbility, useAuth } from 'hooks'
import { ElectionStatusType, MilestoneColorType } from 'models'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import style from './index.module.scss'

function ReportingMilestone() {
    const { t } = useTranslation('teller')
    const navigate = useNavigate()
    const { electionId } = useParams()
    const { auth } = useAuth()
    const { ability } = useAbility()
    const { data: election } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: electionResultSummary } = useElectionSummary(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)

    const [milestoneColor, setMilestoneColor] = useState<MilestoneColorType>(MilestoneColorType.DEFAULT)
    const [milestoneButton, setMilestoneButton] = useState<ReactNode>()
    const [milestoneStatus, setMilestoneStatus] = useState<ElectionStatusType>(ElectionStatusType.NOT_STARTED)

    useEffect(() => {
        if (electionResultSummary?.signedBy) {
            setMilestoneStatus(ElectionStatusType.COMPLETED)

            return
        }
        setMilestoneStatus(ElectionStatusType.NOT_STARTED)
    },
        [
            electionResultSummary
        ])

    useEffect(() => {
        if (election) {
            if (electionResultSummary?.signedBy) {
                setMilestoneColor(MilestoneColorType.SUCCESS)

                return
            }

            if ((!electionResultSummary?.tiePresent && electionResultSummary?.mergedBy)
                || electionResultSummary?.breakingTieSkipped) {
                setMilestoneColor(MilestoneColorType.PRIMARY)

                return
            }
            setMilestoneColor(MilestoneColorType.DEFAULT)
        }
    },
        [
            election,
            electionResultSummary
        ])

    useEffect(() => {
        if (election) {
            if (electionResultSummary?.signedBy) {
                setMilestoneButton(<button className="btn-main-secondary-md"
                    onClick={() => {
                        navigate(`/reports/${electionId || auth!.electionId!}`,
                            ability?.can('redirect', 'ElectionBoard')
                                ? {
                                    state: { backButtonTitle: t('election:back_to_election_board') }
                                }
                                : undefined)
                    }}>{t('view_reports')}</button>)

                return
            }

            if (ability?.can('view-only', 'ChiefTellerActions')) {
                setMilestoneButton(null)

                return
            }

            if ((!electionResultSummary?.tiePresent && electionResultSummary?.mergedBy)
                || electionResultSummary?.breakingTieSkipped) {
                setMilestoneButton(<button className="btn-main-primary-md"
                    onClick={() => {
                        navigate(`/report/${electionId || auth!.electionId!}`)
                    }}>{t('sign_report')}</button>)

                return
            }
            setMilestoneButton(<button className="btn-main-secondary-md" disabled>{t('sign_report')}</button>)
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            election,
            electionResultSummary
        ])

    return (
        <div className={style.contentWrapper}>
            <MilestoneItem
                title={t('reporting')}
                color={milestoneColor}
                children={milestoneButton}
                status={<ElectionBadge status={milestoneStatus} />}
            />
        </div>
    )
}

export default ReportingMilestone
