import Card from 'components/atoms/Card'
import Empty from 'components/molecules/Empty'
import Scrollbars from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { useOfficersElectionsData } from '../../hooks'
import OfficersElectionsLoader from '../ElectionsLoader'
import OfficersElectionsTitle from '../OfficerElectionTitle'
import OfficersElectionsHeader from '../OfficersElectionHeader'
import OfficersElectionsItem from '../OfficersElectionItem'
import style from './index.module.scss'

function OfficersElections() {

    const LEFT_AND_RIGHT_BORDER = 2
    const { officersElectionsData } = useOfficersElectionsData()
    const { t } = useTranslation('administration')

    return (
        <Card
            title={<OfficersElectionsTitle />}
            className={style.officersElections}
            noContentPadding={true}>
        <div className={style.elections}>
            <OfficersElectionsLoader />
            <OfficersElectionsHeader />
            {officersElectionsData?.elections?.length ?
            <AutoSizer>
                {({ height, width }: any) => (
                    <List
                    style={style}
                    outerElementType={Scrollbars}
                    className={style.list}
                    height={height - 89}
                    itemCount={officersElectionsData?.elections?.length}
                    itemSize={40}
                    width={width - LEFT_AND_RIGHT_BORDER}>
                        {OfficersElectionsItem}
                    </List>
                )}
            </AutoSizer> : 
            <div className={style.empty}>
            <Empty text={officersElectionsData.reportError && !officersElectionsData.elections?.length
                ? t('elections_not_loaded_reason',
                    {
                        reason: officersElectionsData.reportError,
                        interpolation: { escapeValue: false }
                    })
                : undefined} />
        </div>}
        </div>
    </Card>)
} 

export default OfficersElections