import { FormInstance } from 'antd'
import { LsaOfficerRole, NoLongerServingOfficer } from 'models'
import { ByElectionOfficer } from '../models'

export function useExtractNoLongerServing() {
    const extractNoLongerServing = (form: FormInstance) => {
        const officers = form.getFieldsValue().officers

        return officers ? officers.map((o: ByElectionOfficer): NoLongerServingOfficer => ({
            id: o.id,
            officeHeld: o.role?.length
                ? `${LsaOfficerRole.MEMBER},${o.role.join(',')}`
                : `${LsaOfficerRole.MEMBER}`,
            reasonForVacancy: o.reasonForVacancy
        })) : []
    }

    return { extractNoLongerServing }
}