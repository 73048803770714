import { TourProps } from 'antd'
import TutorialHighlight from 'components/molecules/TutorialHighlight'
import { tellingStation } from 'features/tellingStation'
import { useBrowserZoomRatio } from 'hooks'
import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useTellingStationProcessInfo, useTourStepCreator } from '../../hooks'

const TIPS_KEY = 'includeEnterBallotsTips'
const INCLUDE_TIPS_AFTER_FIRST_BALLOT_KEY = 'includeTipsAfterFirstBallot'
const MaxRatioForTutorialVisible = 1.20
const StepsAdaptationRatio = 1.03

function EnterBallotsTutorial() {
    const { info: enterBallotsInfo } = tellingStation.enterBallots.hooks.useEnterBallotsInfo()
    const { t } = useTranslation('telling-station-sandbox')
    const { info, setInfo } = useTellingStationProcessInfo()
    const { createTourStep } = useTourStepCreator()
    const [noBallotsYet, setNoBallotsYet] = useState<boolean>()
    const [showTutorialAfterFirstBallot, setShowTutorialAfterFirstBallot] = useState(false)
    const [showFullTutorial, setShowFullTutorial] = useState(false)
    const { browserZoomRatio } = useBrowserZoomRatio()

    const onClose = () => {
        if (showTutorialAfterFirstBallot) {
            sessionStorage.removeItem(INCLUDE_TIPS_AFTER_FIRST_BALLOT_KEY)
        }
        sessionStorage.removeItem(TIPS_KEY)
        setInfo(current => ({ ...current, tutorialShown: false }))
    }

    useEffect(() => {
        const tutorialShown = sessionStorage.getItem(TIPS_KEY) === 'true'
        setInfo(current => ({ ...current, tutorialShown }))
    }, [setInfo])

    useEffect(() => {
        if (enterBallotsInfo?.ballots?.length === 0) {
            setNoBallotsYet(true)

            return
        }
        if (enterBallotsInfo.ballots?.length) {
            if (enterBallotsInfo.ballots.length === 1 && noBallotsYet) {
                setShowTutorialAfterFirstBallot(true)
                setNoBallotsYet(false)

                return
            } else {
                setShowFullTutorial(true)
            }
        }
    }, [enterBallotsInfo])

    useEffect(() => {
        const canShow = sessionStorage.getItem(INCLUDE_TIPS_AFTER_FIRST_BALLOT_KEY) === 'true'
        if (canShow && showTutorialAfterFirstBallot) {
            setInfo((current) => ({ ...current, tutorialShown: true }))
        }
    }, [showTutorialAfterFirstBallot])

    useEffect(() => {
        if (showTutorialAfterFirstBallot && !info.tutorialShown) {
            setShowTutorialAfterFirstBallot(false)
            setShowFullTutorial(true)
        }
    }, [info])

    const steps: TourProps['steps'] = useMemo(() => {
        if (browserZoomRatio > MaxRatioForTutorialVisible) {
            return []
        }

        const ballotEntryFormSteps: TourProps['steps'] = [
            createTourStep({
                scrollIntoViewOptions: { block: 'end' },
                placement: 'right',
                targetId: 'ballot-editor-form',
                title: t('ballot_entry_form'),
                body: t('online_form_for_entering_names_from_the_paper_ballots_in_obs'),
                width: browserZoomRatio > StepsAdaptationRatio ? 250 : 423,
                onClose
            }),
            createTourStep({
                scrollIntoViewOptions: { block: 'end' },
                placement: 'rightTop',
                targetId: 'ballot-id',
                title: t('obs_ballot_id'),
                body: t('this_number_is_automatically_generated_by_obs'),
                onClose
            }),
            createTourStep({
                scrollIntoViewOptions: { block: 'end' },
                placement: 'rightTop',
                targetId: 'mark-ballot-as-invalid-btn',
                title: t('mark_ballot_as_invalid'),
                body: <Trans i18nKey={t(
                    'mark_the_paper_ballot_as_invalid_with_the_current_obs_ballot_id',
                    {
                        interpolation: { escapeValue: false },
                        reasons: `<strong>${t('invalid_ballot_reasons')}</strong>`
                    })} components={[<strong />]} />,
                onClose
            }),
            createTourStep({
                scrollIntoViewOptions: { block: 'end' },
                placement: browserZoomRatio > StepsAdaptationRatio ? 'bottomRight' : 'rightTop',
                targetId: 'enter-vote',
                title: t('search_for_those_eligible_to_serve'),
                body: t('for_valid_ballots_begin_by_typing_the_last_name'),
                width: browserZoomRatio > StepsAdaptationRatio ? 800 : 423,
                onClose
            }),
            createTourStep({
                scrollIntoViewOptions: { block: 'end' },
                placement: browserZoomRatio > StepsAdaptationRatio ? 'left' : 'right',
                targetId: 'ballot-editor-actions-col',
                title: t('mark_vote_as_invalid'),
                body: t('if_a_single_vote_is_invalid_click_the_mark_vote_invalid'),
                onClose
            }),
            createTourStep({
                scrollIntoViewOptions: { block: 'end' },
                placement: browserZoomRatio > StepsAdaptationRatio ? 'leftTop' : 'rightTop',
                targetId: 'submit-ballot-btn',
                title: t('submit_ballot'),
                body: t('click_on_the_submit_ballot_button_to_save_this_ballot'),
                onClose
            })
        ]

        const ballotExistSteps: TourProps['steps'] = [
            createTourStep({
                scrollIntoViewOptions: { block: 'end' },
                placement: 'left',
                targetId: 'real-time-results',
                title: t('real_time_results'),
                body: t('with_every_ballot_submitted_the_system_will_display_the_top_10_individuals'),
                onClose
            }),
            createTourStep({
                placement: browserZoomRatio > StepsAdaptationRatio ? 'top' : 'topRight',
                targetId: 'entered-ballots-highlight',
                scrollIntoViewOptions: { block: 'end' },
                title: t('ballot_entry_log'),
                body: t('ballot_entry_displays_all_the_entered_ballots_and_names'),
                onClose
            }),
            createTourStep({
                placement: 'rightBottom',
                targetId: 'first-ballot-id',
                title: t('view_edit_delete_ballot'),
                body: t('you_can_view_and_or_modify_a_ballot_s_content_and_resubmit'),
                onClose
            })
        ]

        if (showTutorialAfterFirstBallot) {
            return ballotExistSteps
        }

        if (showFullTutorial) {
            return ballotEntryFormSteps.concat(ballotExistSteps)
        }

        return ballotEntryFormSteps
    }, [enterBallotsInfo, showTutorialAfterFirstBallot, showFullTutorial, browserZoomRatio])


    return (
        <>
            {info.tutorialShown && <TutorialHighlight
                onChange={() => window.scrollTo({ top: 0, left: 0 })}
                steps={steps}
                open={info.tutorialShown}
                disabledInteraction
                scrollIntoViewOptions
            />
            }
        </>
    )
}

export default EnterBallotsTutorial