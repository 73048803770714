import AdaptiveLayout from 'components/templates/AdaptiveLayout'
import Layout from 'components/templates/Layout'
import { auth } from 'features/auth'
import { ballotingAssets } from 'features/ballotingAssets'
import { Role } from 'models'
import { createBrowserRouter } from 'react-router-dom'
import RequireAuth from 'routing/RequireAuth'
import { errorHandlingRoutesChildren } from 'routing/error-handling-routes'
import { auth as sandboxAuth } from '../features/auth'
import SandboxLogo from '../features/auth/components/SandboxLogo'
import DefaultLayout from './DefaultLayout'
import DefaultRouter from './DefaultRouter'



export const tellingStationSandboxRouter = createBrowserRouter([
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            {
                index: true,
                element: <DefaultRouter />
            }
        ]
    },
    {
        element: <AdaptiveLayout header={<></>} />,
        children: [
            {
                path: '/sign-in',
                element: <sandboxAuth.pages.SandboxSignIn />
            },
            {
                path: '/auth',
                element: <auth.pages.Auth />
            }
        ]
    },
    {
        element: <RequireAuth allowedRoles={[Role.TELLING_STATION]} />,
        children: [
            {
                element: <DefaultLayout />,
                children: [
                    {
                        path: '/ballot/:ballotId',
                        element: <ballotingAssets.pages.Ballot />
                    }
                ]
            }
        ]
    },
    {
        element: <Layout headerLogoBig={<SandboxLogo />} />,
        children: errorHandlingRoutesChildren
    }
])