import { PropsWithChildren, useState } from 'react'
import { OfficersContext } from '../context'
import { ByElectionOfficer } from '../models'

function OfficersProvider({ children }: PropsWithChildren) {
    const [officers, setOfficers] = useState<ByElectionOfficer[]>([])

    return (
        <OfficersContext.Provider value={{ officers, setOfficers }} >
            {children}
        </OfficersContext.Provider>
    )
}

export default OfficersProvider