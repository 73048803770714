import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { UrlPeriodType } from 'models'

type deleteElectionsParams = {
    electionPeriodId: number,
    periodType: UrlPeriodType
}
export function useDeleteElectionPeriod() {
    const queryClient = useQueryClient()
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function deleteElectionPeriod({ electionPeriodId, periodType }: deleteElectionsParams) {
        return await axios(moduleUrl
            + `${periodType}/${electionPeriodId}/cancel`,
            {
                method: 'post',
                data: {}
            })
    }

    return useMutation({
        mutationFn: deleteElectionPeriod,
        onSuccess: () => {
            queryClient.invalidateQueries(['elections-my-ongoing'])
            queryClient.invalidateQueries(['elections-available-for-creation'])
            queryClient.invalidateQueries(['chief-teller-elections'])
        }
    })
}