export enum ColorStyle {
    Active = 'rgb(0, 87, 155)',
    Primary = 'rgb(32, 31, 30)',
    Secondary = 'rgb(99, 99, 98)',
    Tertiary = 'rgb(144, 143, 143)',
    Disabled = 'rgb(159, 158, 158)',
    Body = 'rgb(255, 255, 255)',
    Link = 'rgb(0, 148, 255',
    Error = 'rgb(229, 12, 0)',
    Success = 'rgb(4, 195, 0)',
    Warning = 'rgb(229, 115, 0)'
}