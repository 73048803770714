import AdaptiveLayot from 'components/templates/AdaptiveLayout'
import Layout from 'components/templates/Layout'
import { useAuth } from 'hooks'
import { useNavigate } from 'react-router-dom'

function DefaultLayout() {
    const { auth } = useAuth()
    const navigate = useNavigate()
    if (!auth?.role) {
        navigate('/sign-in', { replace: true })

        return <></>
    }
    switch (auth!.role) {
        case 'VOTER':
        case 'LSA_TELLER':    
            return <AdaptiveLayot />
        default:
            return <Layout />
    }
}

export default DefaultLayout