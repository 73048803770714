import Card from 'components/atoms/Card'
import { useAuth, usePopup } from 'hooks'
import { useTranslation } from 'react-i18next'
import SandboxPanel from '../SandboxPanel'
import TrainingSessionConfig from '../TrainingSessionConfig'
import style from './index.module.scss'

function GreetingCard() {
    const { t } = useTranslation('telling-station-sandbox')
    const { popupPortal, show, hide } = usePopup()
    const { auth } = useAuth()

    const startTraining = () => {
        show({
            width: '660px',
            closeIcon: true,
            style: {
                border: '1px solid var(--border-button-warning-tertiary-active)',
                borderRadius: 'var(--double-space)'
            },
            title: <><div className={style.titlePopup}>{t('training_settings')}</div>
                <div className={style.popupSubHeader}>{t('adjust_the_properties')}</div></>,
            onOk: async () => { },
            footer: null,
            onCancel: hide,
            children: <TrainingSessionConfig hide={hide} />
        })
    }

    return (
        <>
            {popupPortal}
            <SandboxPanel
                noHeaderLine
                title={<div className={style.greeting}>
                    <div tabIndex={0} className={style.hello}>
                        {t('hello_name', { name: auth?.name, interpolation: { escapeValue: false } })}
                    </div>
                    <div
                        tabIndex={0}
                        className={style.bid}>
                        {t('bid_id', { id: auth?.id })}
                    </div>
                </div>}
            >
                <div className={style.body}>
                    <div className={style.panel}>
                        <div style={{ width: '100%', height: '100%' }}>
                            <Card
                                noHeaderLine={true}
                                contentClassName={style.adaptiveContent}>
                                <div className={style.title}>
                                    {t('welcome_to_the_obs_training_environment')}
                                </div>
                                <div className={style.welcomeText}>
                                    {t('the_obs_training_environment_is_designed')}
                                    <div className={style.textBold}><b>{t('what_s_included')}</b></div>
                                    {t('the_obs_training_environment_covers_the_telling_process')}
                                    <ol>
                                        <li>{t('voter_verification_tellers_verify_voters')}</li>
                                        <li>{t('enter_ballots_tellers_open_all_inner_envelopes')}</li>
                                        <li>{t('reporting_tellers_check')}</li>
                                    </ol>
                                    <div className={style.importantText}>
                                        <b>{t('please_note_that_the_obs_training_environment')}</b>
                                    </div>
                                    <div className={style.textBold}><b>{t('how_it_works')}</b></div>
                                    <ol>
                                        <li>{t('click_start_and_choose')}</li>
                                        <li>{t('the_system_will_generate')}</li>
                                        <li>{t('after_finishing_you_can_repeat')}</li>
                                    </ol>
                                    <div className={style.textBold}><b>{t('practice_makes_perfect')}</b></div>
                                </div>
                            </Card>
                        </div>
                        <div className={style.divider} />
                        <div className={style.buttonWrapper}>
                            <button className={`btn-main-primary ${style.startBtn}`}
                                onClick={startTraining}>
                                {t('start').toUpperCase()}
                            </button>
                        </div>
                    </div>
                </div>
            </SandboxPanel></>
    )
}

export default GreetingCard