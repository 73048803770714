import { ReactNode, useState } from 'react'
import { AnyFormChangedContext } from '../context'


const AnyFormChangedProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAnyFormChanged, setIsAnyFormChanged] = useState(false)

    return (
        <AnyFormChangedContext.Provider value={{ isAnyFormChanged, setIsAnyFormChanged }}>
            {children}
        </AnyFormChangedContext.Provider>
    )
}

export default AnyFormChangedProvider