import { Progress } from 'antd'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import React, { CSSProperties, useMemo } from 'react'
import { useVotingStatisticTableData } from '../../hooks'
import { LocalityVotingStatistic } from '../../models'
import { getTableColumnWidths } from '../../utils'
import styles from './index.module.scss'
import 'style/shared/full-data-table/full-data-table.scss'

type VotingStatisticItemProps = {
    index: number,
    style?: CSSProperties,
    isScrolling?: boolean
}

const tableCellContentWith = (cellWidth: number) => `calc(${cellWidth}px - calc(var(--double-space) * 2)`

const VotingStatisticItem = ({ index, style: itemStyle }: VotingStatisticItemProps) => {
    const { tableData } = useVotingStatisticTableData()
    const locality: LocalityVotingStatistic = tableData.localities[index]
    const tableColumnWidths = useMemo(() => getTableColumnWidths(), [])

    return (
        <div className="full-data-table-listItem" style={itemStyle}>
            <div
                style={{ width: tableColumnWidths.localityName }}
                className="full-data-table-cell-column"
            >
                <span style={{ maxWidth: tableCellContentWith(tableColumnWidths.localityName) }}>
                    <OptionalTooltip contenWrapperClassName="ellipsis">{locality.localityName}</OptionalTooltip>
                </span>
            </div>

            <div
                style={{ width: tableColumnWidths.localityCode }}
                className="full-data-table-cell-column"
            >
                {locality.localityCode}
            </div>

            <div
                style={{ width: tableColumnWidths.eligibleToVote }}
                className="full-data-table-cell-column"
            >
                {locality.eligibleToVote}
            </div>

            <div
                style={{ width: tableColumnWidths.onlineVoters }}
                className="full-data-table-cell-column"
            >
                {locality.votedOnlineInAbsence + locality.votedOnlineInPerson}
            </div>

            <div
                style={{ width: tableColumnWidths.paperBallots }}
                className="full-data-table-cell-column"
            >
                {locality.votedPaperInAbsence + locality.votedPaperInPerson}
            </div>

            <div
                style={{ width: tableColumnWidths.totalVoted }}
                className="full-data-table-cell-column"
            >
                {locality.totalVoted}
            </div>

            <div
                style={{ width: tableColumnWidths.percentageTotalVoted }}
                className="full-data-table-cell-column"
            >
                {
                    <div className={styles.progressWrapper}>
                        <Progress
                            percent={locality.percentageTotalVoted}
                            format={(percent) => `${percent}%`}
                            strokeColor="var(--icon-progress-bar-inactive)"
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default VotingStatisticItem