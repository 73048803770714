
import { dashboard } from './features/dashboard'
import { manage } from './features/manage'
import { votingSession } from './features/votingSession'
import ViewElectionResults from './pages/ViewElectionResults'

export const officersElection = {
    manage,
    dashboard,
    votingSession,
    pages: {
        ViewElectionResults
    }
}