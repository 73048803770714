import { ElectionType } from 'models'

export function getElectionTypeApiModule(type: ElectionType) {
    switch (type) {
        case ElectionType.RIDVAN_ELECTION:
            return 'ridvan-election'
        case ElectionType.LOCAL_DELEGATE_ELECTION:
            return 'local-delegate-elections'
    }

    return ''
}