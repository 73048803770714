import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

export const useMembersName = (membersId: string[], enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getMembersName = async () => {
        const result = await axios<{ bahaiId: string, name: { name: string } }[]>(
            `${moduleUrl}members/names?members=${membersId.join(',')}`)

        return result.data.map(d => ({ id: d.bahaiId, name: d.name.name }))
    }

    return useQuery({
        queryKey: ['members-name', membersId.join(',')],
        queryFn: () => getMembersName(),
        enabled
    })
}
