import { Select as AntSelect } from 'antd'
import { Select, SelectProps } from 'components/atoms/Select'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OptionalTooltip from '../OptionalTooltip'
import style from './index.module.scss'
const { Option } = AntSelect

export interface SelectOption {
    id: string | number,
    name: string
}

type HighlightedDuplicatesSelectProps<T extends SelectOption> = Omit<SelectProps,
    'onChange' | 'options' | 'defaultValue'> & {
        onChange?: (value?: T) => void,
        options?: T[],
        defaultValue?: T,
        optionRenderFunction?: (option: T) => ReactNode,
        alreadySelectedOptions?: T[],
        disableDuplicates?: boolean
    }

function HighlightedDuplicatesSelect<T extends SelectOption>({
    options,
    defaultValue,
    optionRenderFunction,
    alreadySelectedOptions,
    disableDuplicates,
    onChange,
    ...restProps }: HighlightedDuplicatesSelectProps<T>) {
    const { t } = useTranslation()
    const [selectedOption, setSelectedOption] = useState<T | undefined>(undefined)

    const isAlreadySelected = (option: T) => {
        if (!alreadySelectedOptions?.length) {
            return false
        }

        if (option.id === selectedOption?.id) {
            return false
        }

        if (alreadySelectedOptions.find(o => o.id === option.id)) {
            return true
        }

        return false
    }

    useEffect(() => {
        if (defaultValue) {
            setSelectedOption({ ...defaultValue })
        } else {
            setSelectedOption(undefined)
        }
    },
        [defaultValue])

    const onSelectChange = (id: any) => {
        if (onChange) {
            if (id) {
                const option = options?.find(p => p.id === id)
                setSelectedOption(option)
                onChange(option)
            } else {
                setSelectedOption(undefined)
                onChange()
            }
        }
    }

    const handleClear = useCallback(() => {
        setSelectedOption(undefined)

        if (onChange) {
            onChange(undefined)
        }
    }, [onChange, setSelectedOption])

    return (
        <Select
            {...restProps}
            onClear={handleClear}
            value={selectedOption
                ? { label: selectedOption.name, value: selectedOption.id }
                : undefined}
            onChange={onSelectChange}
        >
            {options?.map(option =>
                <Option aria-label={option.name} className={style.option}
                    value={option.id} label={option.name} key={option.id}
                    disabled={isAlreadySelected(option) && disableDuplicates}
                >
                    <OptionalTooltip contenWrapperClassName="ellipsis"
                        title={optionRenderFunction ? optionRenderFunction(option) : option.name}>
                        {optionRenderFunction ? optionRenderFunction(option) : option.name}
                    </OptionalTooltip>
                    {isAlreadySelected(option)
                        ? <div className={`${style.alreadySelected} ${(isAlreadySelected(option) && disableDuplicates)
                            ? 'disabled'
                            : ''}`}>{t('already_selected')}</div>
                        : <></>
                    }
                </Option>
            ) || []
            }
        </Select>
    )
}

export default HighlightedDuplicatesSelect