import { useSelectStandardFlow, useStopOnlineVoting } from 'api'
import PopupTitle from 'components/atoms/PopupTitle'
import { PopupProps } from 'components/molecules/Popup'
import { queryClient } from 'config/query-client'
import { ConfirmPopupSettings, useLsaOfficerRoleMap } from 'hooks'
import { LsaOfficerRole, OfficersElection } from 'models'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAnnounceOfficersElection, useCancelOfficersElection, useUpdateVotingSessionStep } from '../api'
import StartVoting from '../components/StartVoting'
import { useRevotePosition } from './useRevotePosition'

const PHYSICAL_VOTES_CONFIRMED = 'physical_votes_confirmed'

type VotingActionPopupProps = {
    show: (props: PopupProps) => void,
    hide: () => void,
    positionType: LsaOfficerRole
}

type VerifyMembershipProps = {
    show: (props: PopupProps) => void,
    hide: () => void
}

type CompleteVotingWithoutWinnerProps = {
    showConfirm: (settings: ConfirmPopupSettings) => void,
    role: LsaOfficerRole,
    election: OfficersElection,
    show: (props: PopupProps) => void,
    hide: () => void
}

export const useVotingPositionActions = () => {
    const { t } = useTranslation('officers-election')
    const { toString } = useLsaOfficerRoleMap()
    const navigate = useNavigate()

    const { revotePosition } = useRevotePosition()

    const { mutateAsync: announce } = useAnnounceOfficersElection()
    const { mutateAsync: cancelElection } = useCancelOfficersElection()
    const { mutateAsync: stopOnlineVoting } = useStopOnlineVoting()
    const { mutateAsync: selectStandardFlow } = useSelectStandardFlow()
    const { mutateAsync: updateVotingSessionStep } = useUpdateVotingSessionStep()

    const startVoting = ({ show, hide, positionType }: VotingActionPopupProps) => {
        show({
            title: <PopupTitle>
                {`${t('start_voting_session_for_the_position_of')} ${toString(positionType)}`}?
            </PopupTitle >,
            children: <StartVoting
                onCancel={() => hide()}
                onStartVoting={async () => {
                    await announce(positionType)
                    hide()
                }
                }
                viewMode={false}
            />,
            footer: null
        })
    }

    const verifyMembership = ({ show, hide }: VerifyMembershipProps) => {
        show({
            title: <PopupTitle>{t('current_spiritual_assembly_membership')}</PopupTitle >,
            children: <StartVoting
                onCancel={() => hide()}
                viewMode={true}
            />,
            footer: null,
            closeIcon: true
        })
    }

    const cancelPositionVoting = async (showConfirm: (settings: ConfirmPopupSettings) => void, role: LsaOfficerRole,
        electionPeriod: string, withConfirmation?: boolean) => {
        const cancel = async () => {
            await cancelElection(electionPeriod)
            navigate('/', { replace: true })
        }

        if (withConfirmation) {
            showConfirm({
                title: t('cancel_position_voting') + '?',
                text: (
                    <div>
                        <Trans i18nKey={t(
                            'you_are_about_to_action_the_online_voting_for_the_position_of',
                            {
                                interpolation: { escapeValue: false },
                                positionName: `<strong>${toString(role)}</strong>`,
                                action: `<strong>${t('cancel').toLowerCase()}</strong>`
                            })} components={[<strong />]} />
                        <div style={{ paddingTop: 'var(--default-space' }}>
                            <b>{t('please_note_this_is_an_irreversible_action')}</b>
                        </div>
                    </div>
                ),
                stayOpenedOnCancel: true,
                maskClosable: false,
                okText: t('continue_voting'),
                cancelText: t('cancel_voting'),
                onOk: async () => { },
                onCancel: async () => {
                    await cancel()
                }
            })
        } else {
            await cancel()
        }
    }

    const stopOnlinePositionVoting = (showConfirm: (settings: ConfirmPopupSettings) => void, role: LsaOfficerRole,
        electionId: string) => {
        showConfirm({
            title: t('end_position_voting') + '?',
            text: (
                <div>
                    <Trans i18nKey={t(
                        'you_are_about_to_action_the_online_voting_for_the_position_of',
                        {
                            interpolation: { escapeValue: false },
                            positionName: `<strong>${toString(role)}</strong>`,
                            action: `<strong>${t('end').toLowerCase()}</strong>`
                        })} components={[<strong />]} />
                    <div style={{ paddingTop: 'var(--default-space' }}>
                        <b>{t('please_note_this_is_an_irreversible_action')}</b>
                    </div>
                </div>
            ),
            okText: t('continue_voting'),
            cancelText: t('end_voting'),
            stayOpenedOnCancel: true,
            maskClosable: false,
            onOk: async () => { },
            onCancel: async () => {
                await stopOnlineVoting(electionId)
                await selectStandardFlow(electionId)
                await queryClient.invalidateQueries(['online-voting', electionId])
            }
        })
    }

    const completeVotingWithWinner = (showConfirm: (settings: ConfirmPopupSettings) => void,
        electionId: string) => {
        showConfirm({
            title: t('are_you_sure_you_want_to_submit_voting_entry'),
            text: t('please_note_this_is_an_irreversible_action'),
            okText: t('submit'),
            onOk: async () => {
                await updateVotingSessionStep({
                    electionId,
                    step: PHYSICAL_VOTES_CONFIRMED
                })
            },
            stayOpenedOnCancel: true
        })
    }

    const completeVotingWithoutWinner = ({
        election,
        role,
        show,
        hide,
        showConfirm
    }: CompleteVotingWithoutWinnerProps) => {
        showConfirm({
            title: t('no_one_received_the_required_minimum_of_5_votes'),
            text: t('please_note_this_is_an_irreversible_action'),
            okText: t('revote_position'),
            cancelText: t('cancel_position_voting'),
            closeIcon: true,
            onOk: async () => {
                await revotePosition({
                    role,
                    electionToCancel: election,
                    onSuccess: () => {
                        queryClient.invalidateQueries(['my-officers-election'])
                    },
                    show,
                    hide
                })
            },
            onCancel: async () => {
                cancelPositionVoting(showConfirm, role, election.electionPeriod.toString())
            },
            stayOpenedOnCancel: true,
            onClose: () => { }
        })
    }

    return {
        startVoting,
        verifyMembership,
        cancelPositionVoting,
        stopOnlinePositionVoting,
        completeVotingWithWinner,
        completeVotingWithoutWinner
    }
}