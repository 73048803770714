import AdaptiveLayout from 'components/templates/AdaptiveLayout'
import Layout from 'components/templates/Layout'
import { auth as authFeature } from 'features/auth'
import { useAuth } from 'hooks'
import { useNavigate } from 'react-router-dom'
import SandboxLogo from '../features/auth/components/SandboxLogo'
import { tellingStation } from '../features/tellingStation'

function DefaultLayout() {
    const { auth } = useAuth()
    const navigate = useNavigate()
    const { openEndTrainingPopup } = tellingStation.hooks.useEndTrainingPopup()

    if (!auth) {
        navigate('/sign-in', { replace: true })

        return <></>
    }
    if (!auth?.role) {
        return <AdaptiveLayout header={<authFeature.components.Header
            logo={<SandboxLogo />} />} />
    }

    return <Layout headerLogoBig={<SandboxLogo />} onEndTraining={openEndTrainingPopup} />
}

export default DefaultLayout