import { DatePicker as AntDatePicker } from 'antd'
import { ReactComponent as Calendar } from 'icons/calendar.svg'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
type DatePickerProps = React.ComponentProps<typeof AntDatePicker>

function DatePicker(props: DatePickerProps) {

    return (
        <AntDatePicker
            {...props}
            className={`obs ${props.className || ''}`}
            popupClassName={`obs ${props.popupClassName || ''}`}
            allowClear={props.allowClear === true
                ? { clearIcon: < Dismiss height="100%" width="100%" /> }
                : (props.allowClear || false)}
            suffixIcon={props.suffixIcon || < Calendar height="100%" width="100%" />}
        />
    )
}

export default DatePicker