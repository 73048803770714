
import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { OnlineVoterDashboardWording } from 'models'

export const useOnlineVoterDashboardWording = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Toolkit)

    const getOnlineVoterDashboardWording = async (): Promise<OnlineVoterDashboardWording> => {
        const result = await axios<OnlineVoterDashboardWording>(moduleUrl + 'ui/online-voters-dashboard')

        return result.data
    }

    return useQuery({
        queryKey: ['online-voters-dashboard'],
        queryFn: getOnlineVoterDashboardWording,
        enabled
    })
}