import { electionProcessingBoard } from './features/dashboard'
import { eligibleIndividuals } from './features/eligibleIndividuals'
import { stationLauncher } from './features/stationLauncher'
import { stationsManagement } from './features/stationsManagement'
import { tellingProcess } from './features/tellingProcess'
import { votingStatistic } from './features/votingStatistic'
import LeaveFeedback from './pages/LeaveFeedback'

export const electionProcessing = {
    electionProcessingBoard,
    stationsManagement,
    eligibleIndividuals,
    tellingProcess,
    stationLauncher,
    votingStatistic,
    pages: {
        LeaveFeedback
    }
}