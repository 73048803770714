import { Select as AntSelect, Form } from 'antd'
import { Select } from 'components/atoms/Select'
import { ElectionType } from 'models'
import { Key } from 'react'
import { useTranslation } from 'react-i18next'

type ElectionTypeFilterProps = {
    disabled?: boolean,
    key?: Key | null | undefined
}

function ElectionTypeFilter({ disabled, key }: ElectionTypeFilterProps) {
    const { t } = useTranslation()

    return (
        <Form.Item
            key={key}
            name={['electionType']}
            className="no-padding"
            label={t('election:election_type')}
            style={{ width: 300 }}
            required
        >
            <Select
                disabled={disabled}
                placeholder={t('select_input', { input: t('election:election_type') })}
                className="small">
                <AntSelect.Option value={ElectionType.RIDVAN_ELECTION}>
                    {t('election:ridvan_and_local_delegate_election')}
                </AntSelect.Option>
                <AntSelect.Option value={ElectionType.BY_ELECTION}>
                    {t('election:by_election')}
                </AntSelect.Option>
            </Select>
        </Form.Item>
    )
}

export default ElectionTypeFilter