import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionVerifiedVoters } from 'models'

export const useElectionVerifiedVoters = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    const getElectionVerifiedVoters = async ({ queryKey }: QueryFunctionContext)
        : Promise<ElectionVerifiedVoters> => {
        const [, electionId] = queryKey

        const result = await axios<ElectionVerifiedVoters>(
            `${moduleUrl}elections/${electionId}/verified-voters`)

        return { ...result.data, verification: (result.data as any).voters }
    }

    return useQuery({
        queryKey: ['verified-voters-of-election', electionId],
        queryFn: getElectionVerifiedVoters,
        enabled
    })
}