import ByElectionEditingProcess from '../components/ByElectionEditingProcess'
import OfficersProvider from '../providers/OfficersProvider'

function ByElectionEditing() {

    return (
        <OfficersProvider>
            <ByElectionEditingProcess />
        </OfficersProvider>
    )
}

export default ByElectionEditing