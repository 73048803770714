import Input from 'components/atoms/Input'
import FilterableHeaderCell from 'components/molecules/FilterableHeaderCell'
import SortableHeaderCell from 'components/molecules/SortableHeaderCell'
import { useFilterable, useSortable } from 'hooks'
import { SortOrder } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOfficersElectionsData } from '../../hooks'
import { OfficersElectionsFilter } from '../../model/officers-election-filter'
import { filterElections } from '../../utils/filterElections'
import style from './index.module.scss'

const DEFAULT_FILTER = {
    sortBy: {
        field: 'localityName',
        order: SortOrder.ASC
    }
}

function OfficersElectionsHeader() {
    const { t } = useTranslation('administration')
    const { changeFilter } = useFilterable<OfficersElectionsFilter>()
    const { changeSortBy } = useSortable()

    const { officersElectionsData, setOfficersElectionsData } = useOfficersElectionsData()
    const [filter, setFilter] = useState<OfficersElectionsFilter>({ ...DEFAULT_FILTER })

    useEffect(() => {
        const filterFromStorage = localStorage.getItem('admin_dbd_filters_officers')
        if (filterFromStorage) {
            setFilter(JSON.parse(filterFromStorage))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateFilter = (newFilter: OfficersElectionsFilter) => {
        localStorage.setItem('admin_dbd_filters_officers', JSON.stringify(newFilter))
        setFilter(newFilter)
    }

    useEffect(() => {
        setOfficersElectionsData(current => ({
            ...current,
            elections: current.allElections ? filterElections(current.allElections, filter) : []
        }))
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [officersElectionsData.allElections, filter])

    const clearAllFilters = () => {
        updateFilter({ ...DEFAULT_FILTER })
    }

    const onSortingChange = (field: string, order?: SortOrder) => {
        updateFilter(changeSortBy(filter, field, order) as OfficersElectionsFilter)
    }

    return (
        <div className={style.header}>
            <div className={style.column}
                style={{ width: 200 }}
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Input
                            value={filter.localityName}
                            allowClear={true}
                            className="small"
                            style={{ width: 210 }}
                            onChange={(e) => updateFilter(changeFilter(filter, 'localityName', e.target.value))}
                            placeholder={t('search_by_name')}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('locality_name')}
                            field={'localityName'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div>
            <div className={style.column}
                style={{ width: 130 }}
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <Input
                            allowClear={true}
                            value={filter.localityCode}
                            className="small"
                            style={{ width: 112 }}
                            onChange={(e) => updateFilter(changeFilter(filter, 'localityCode', e.target.value))}
                            placeholder={t('search_by') + '...'}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('locality_code')}
                            field={'localityCode'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div>
            <div className={style.column}
                style={{ width: 154 }}
            >
                <FilterableHeaderCell
                    size="small"
                    filter={<></>}
                    title={t('chairman')} />
            </div>
            {<div className={style.column}
                style={{ width: 154 }}
            >
                < FilterableHeaderCell
                    size="small"
                    filter={<></>}
                    title={t('vice_chairman')} />
            </div>
            }
            <div className={style.column}
                style={{ width: 154 }}
            >
                < FilterableHeaderCell
                    size="small"
                    filter={<></>}
                    title={t('treasurer')} />
            </div>
            <div className={style.column}
                style={{ width: 154 }}
            >
                < FilterableHeaderCell
                    size="small"
                    filter={<></>}
                    title={t('corresponding_secretary')} />
            </div>
            <div className={style.column}
                style={{ width: 154 }}
            >
                < FilterableHeaderCell
                    size="small"
                    filter={<></>}
                    title={t('recording_secretary')} />
            </div>
            <div className={style.column}
                style={{ width: 170 }}
            >
                < FilterableHeaderCell
                    size="small"
                    filter={<></>}
                    title={
                        <SortableHeaderCell
                            value={filter.sortBy}
                            title={t('last_confirmed_election_date')}
                            field={'lastConfirmedElectionDate'}
                            onChange={onSortingChange}
                        />
                    }
                />
            </div>
            <div className={style.column}
                style={{ width: 160 }}
            >
                <FilterableHeaderCell
                    size="small"
                    filter={
                        <button
                            className="btn-main-tertiary-md"
                            onClick={clearAllFilters}
                        >
                            {t('clear_all_fillters')}
                        </button>
                    }
                    title={t('reports')}
                />
            </div>
        </div>
    )
}

export default OfficersElectionsHeader