import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function ManageOfficersElectionButton() {
    const { t } = useTranslation('election')
    const navigate = useNavigate()

    return (
        <button className="btn-main-tertiary-md" onClick={() => {navigate('/officers-election')}}>
            {t('manage_officers_election')}
        </button>
    )
}

export default ManageOfficersElectionButton