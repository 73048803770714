import DelegateElections from './components/DelegateElections'
import Elections from './components/Elections'
import DelegateElectionsProvider from './provider/DelegateElectionsProvider'

export const delegateElections = {
    components: {
        DelegateElections,
        Elections
    },
    providers: {
        DelegateElectionsProvider
    }
}