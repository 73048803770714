import { LsaOfficerRole } from 'models'
import { useTranslation } from 'react-i18next'

export const useLsaOfficerRoleMap = () => {
    const { t } = useTranslation()

    return {
        toString: (role: LsaOfficerRole): string => {
            switch (role) {
                case LsaOfficerRole.MEMBER:
                    return t('member')
                case LsaOfficerRole.CHAIRMAN:
                    return t('chairman')
                case LsaOfficerRole.VICE_CHAIRMAN:
                    return t('vice_chairman')
                case LsaOfficerRole.TREASURER:
                    return t('treasurer')
                case LsaOfficerRole.CORRESPONDING_SECRETARY:
                    return t('corresponding_secretary')
                case LsaOfficerRole.RECORDING_SECRETARY:
                    return t('recording_secretary')
            }
        }
    }

}