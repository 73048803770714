import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { BreakingTieRound } from 'models'

export const useBreakingTieRounds = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getBreakingTieRounds = async ({ queryKey }: QueryFunctionContext) => {
        const [, electionId] = queryKey

        const res =
            await axios<BreakingTieRound[]>(`${moduleUrl}elections/${electionId}/breaking-tie-rounds`)

        return res.data
    }

    return useQuery<BreakingTieRound[], Error>({
        queryKey: ['breaking-tie-rounds', electionId],
        queryFn: getBreakingTieRounds,
        enabled: !!electionId && enabled
    })
}