import IconWrapper from 'components/atoms/IconWrapper'
import LeaveFeedback from 'components/organisms/LeaveFeedback'
import { ReactComponent as SignOut } from 'icons/sign-out.svg'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import style from './index.module.scss'

function LeaveTellingStationProcessFeedback() {
    const { t } = useTranslation('telling-station')
    const navigate = useNavigate()

    return (
        <LeaveFeedback>
            <div className={style.thankYouWrapper}>
                <div className={style.thankYou}>
                    <div className={style.title}>{t('thank_you')}</div>
                    <div className={style.message}>{t('your_chief_teller_will_now_have_to_log_back')}</div>
                    <div className={style.note}>{t('thank_you_for_completing_this_step')}</div>
                    <div className={style.logoutNote}>{t('you_are_about_to_be_logged_out')}</div>
                    <button
                        onClick={() => navigate('/sign-in')}
                        className={`btn-main-primary ${style.logoutBtn}`}>
                        <IconWrapper><SignOut height="17" /></IconWrapper>
                        {t('log_out')}
                    </button>
                </div>
            </div>
        </LeaveFeedback>
    )
}

export default LeaveTellingStationProcessFeedback