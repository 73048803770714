import { ConfirmPopupSettings } from 'hooks'
import { AppointedTeller, ElectionStation, ElectionStationTeller } from 'models'
import { useTranslation } from 'react-i18next'
import { EMPTY_TELLER } from '../constants'
import { useStations } from './useStations'

export const useTellerActions = () => {
    const { t } = useTranslation('teller')
    const { stations, setStations } = useStations()

    const onStationTellerRemove = (stationName: number, tellerNumber: number) => {
        const updatedStationsData = [...stations]
        updatedStationsData[stationName].tellers.splice(tellerNumber, 1)
        setStations(updatedStationsData)
    }

    const changeTeller = (station: ElectionStation, tellerIndex: number,
        showConfirm: (settings: ConfirmPopupSettings) => void, teller?: AppointedTeller) => {

        const change = (newTeller?: AppointedTeller, updateSaved?: boolean) => {
            setStations(prev => (prev.map(s => {
                if (s.id === station.id) {
                    return {
                        ...s,
                        saved: updateSaved ? false : s.saved,
                        tellers: s.tellers.map((t, tIndex) => {
                            if (tIndex === tellerIndex) {
                                return newTeller ? { ...newTeller } : { ...EMPTY_TELLER }
                            }

                            return t
                        })
                    }
                }

                return s
            })))
        }

        if (teller && station.tellers[tellerIndex]?.isSavedInOpenStation) {
            showConfirm({
                title: <>{t('replace_teller')}</>,
                okText: t('replace'),
                text: <>
                    {t('are_you_sure_you_want_to_change_this_teller')}
                    <br />
                    {t('this_teller_participating_in_the_telling_station')}
                    <br />
                    {t('the_previous_teller_will_be_replaced_with_a_new_one_after_saving_changes')}
                </>,
                onOk: async () => {
                    change({ ...teller, isSavedInOpenStation: false } as ElectionStationTeller, true)
                },
                onCancel: async () => {
                    change(station.tellers[tellerIndex])
                }
            })
        } else if (teller) {
            change({ ...teller, isSavedInOpenStation: false } as ElectionStationTeller, true)
        } else {
            showConfirm({
                title: t('delete_teller'),
                okText: t('delete_teller'),
                text: <>{t('are_you_sure_you_want_to_delete_the_teller_from_the_station')}</>,
                onOk: async () => {
                    change(undefined, true)
                },
                onCancel: async () => {
                    change(station.tellers[tellerIndex])
                }
            })
        }
    }

    const appendNewTeller = (station: ElectionStation) => {
        setStations(stations.map(s => {
            if (s.id === station.id) {
                return {
                    ...s,
                    saved: false,
                    tellers: [...s.tellers, { ...EMPTY_TELLER }]
                }
            }

            return s
        }))
    }

    return {
        changeTeller,
        appendNewTeller,
        onStationTellerRemove
    }
}