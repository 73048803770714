import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { AxiosError } from 'axios'
import Checkbox from 'components/atoms/Checkbox'
import PopupFooter from 'components/atoms/PopupFooter'
import LookupSelect from 'components/molecules/LookupSelect'
import Note from 'components/molecules/Note'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { queryClient } from 'config/query-client'
import { useAccessToken } from 'hooks'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getNameWithMatchHighlighting } from 'utils'
import { useStartView, useUserList } from '../../api'
import { User } from '../../model'
import style from './index.module.scss'

type ViewUserProps = {
    onCancel: () => void,
    onSubmit: () => void
}

function ViewUser({
    onCancel,
    onSubmit
}: ViewUserProps) {
    const { t } = useTranslation()
    const [form] = useForm()
    const [error, setError] = useState('')
    const { mutateAsync: startView } = useStartView()
    const { setAccessToken } = useAccessToken()
    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const goButtonRef = useRef<HTMLButtonElement | null>(null)
    const { data: users, isFetching: usersFetching } = useUserList({ fullName: name, loginName: id },
        !!id || !!name)

    const onUserChange = (user?: User) => {
        if (!!user) {
            goButtonRef?.current?.focus()
        }
    }

    const updateFilter = (value?: string) => {
        if (!value) {
            setId('')
            setName('')
        } else {
            const num = +value
            if (isNaN(num)) {
                setName(value)
                setId('')
            } else {
                setId(value)
                setName('')
            }
        }
    }

    const submit = async () => {
        try {
            await form.validateFields()
        } catch {
            return
        }

        const params = form.getFieldsValue()

        try {
            const token = await startView({
                bahaId: params.member.id,
                isLsa: params.isLsa || false
            })
            setAccessToken(token.accessToken)
            await queryClient.invalidateQueries(['chief-teller-elections'])
        } catch (e: any) {
            const err = e as AxiosError
            setError((err?.response?.data || '') as string)

            return
        }
        onSubmit()
    }

    return (
        <>
            {!!error
                && <div className={style.error}>
                    <Note
                        icon="filled"
                        mode="error">
                        {error}
                    </Note>
                </div>
            }
            <Form
                name="user"
                layout="vertical"
                onValuesChange={() => { if (error) setError('') }}
                form={form}>
                <Form.Item
                    name={['member']}
                    rules={[{
                        required: true,
                        message: t('common:please_enter_the_input', { input: t('bahai_id') })
                    }
                    ]}
                >
                    <LookupSelect
                        noOptionalTooltip
                        allowPaste
                        autoFocus
                        optionRenderFunction={(user) =>
                            <div className={style.candidate}>
                                <strong className={style.id}>
                                    {user.id}
                                </strong>
                                <span className={style.name}>
                                    <OptionalTooltip contenWrapperClassName="ellipsis">
                                        {name
                                            ? getNameWithMatchHighlighting(user.name, name)
                                            : user.name
                                        }
                                    </OptionalTooltip>
                                </span>

                            </div>}
                        onSearchChange={(name) => updateFilter(name?.trim())}
                        onChange={onUserChange}
                        options={users}
                        isFetchingOptions={usersFetching}
                        placeholder={t('bahai_id')} />
                </Form.Item>
                <Form.Item
                    name={['isLsa']}
                    className="no-padding"
                    valuePropName="checked"
                    initialValue={true}
                >
                    <Checkbox>
                        {t('lsa_officer')}
                    </Checkbox>
                </Form.Item>
                <PopupFooter>
                    <button
                        onClick={onCancel}
                        className={'btn-main-secondary'}>
                        {t('cancel')}
                    </button>
                    <button
                        ref={goButtonRef}
                        type="submit"
                        onClick={submit}
                        className={'btn-main-primary'}>
                        {t('go')}
                    </button>
                </PopupFooter>
            </Form >
        </>
    )
}

export default ViewUser