import { useCheckReportStatus, useGenerateReport, useGetReport } from 'api'
import Preloader from 'components/molecules/Preloader'
import { queryClient } from 'config/query-client'
import { ReportFormat, ReportStatus, ReportType } from 'models'
import { useEffect, useState } from 'react'
import { useOfficersElectionsData } from '../../hooks'
import { OfficersElection } from '../../model'

//TODO: Refactor this component to use the useJsonReportLoader hook

function OfficersElectionsLoader() {
    const [reportIsReady, setReportIsReady] = useState(false)
    const [timerId, setTimerId] = useState<NodeJS.Timer>()

    const { officersElectionsData, setOfficersElectionsData } = useOfficersElectionsData()

    const { data: reportId } = useGenerateReport({
        reportType: ReportType.OfficersElections,
        reportFormat: ReportFormat.JSON,
        params: {
            ballotingYear: officersElectionsData?.badiYear
        }
    }, !!officersElectionsData?.badiYear)

    const { data: status, isLoading: isStatusLoading } = useCheckReportStatus(reportId!, !!reportId)
    const { data: report } = useGetReport<OfficersElection[]>(reportId!, reportIsReady)

    useEffect(() => {
        const resetReportStatusChecking = () => {
            if (timerId) {
                clearInterval(timerId)
                setTimerId(undefined)
                setOfficersElectionsData(current => ({
                    ...current,
                    isReportLoading: false
                }))
            }
        }
        if (status) {
            if (status.includes(ReportStatus.FAILED)) {
                setOfficersElectionsData(current => ({
                    ...current,
                    reportError: status.split(`${ReportStatus.FAILED}: `)[1]
                }))
            }
            switch (status) {
                case ReportStatus.PENDING:
                case ReportStatus.IN_PROGRESS:
                    if (!timerId) {
                        setOfficersElectionsData(current => ({
                            ...current,
                            isReportLoading: true
                        }))
                        const tId = setInterval(async () => {
                            await queryClient.invalidateQueries(['report-status', reportId])
                        }, 1000)
                        setTimerId(tId)

                    }
                    break
                case ReportStatus.COMPLETED:
                    resetReportStatusChecking()
                    setReportIsReady(true)
                    break
                default:
                    resetReportStatusChecking()
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, isStatusLoading])

    useEffect(() => {
        if (report) {
            setOfficersElectionsData(current => ({
                ...current,
                allElections: report
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report])

    return (
        <>{officersElectionsData?.isReportLoading && <Preloader />}</>
    )
}

export default OfficersElectionsLoader