import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'

type StationDeleteBallotParams = {
    id: string,
    comment: string
}

export function useStationDeleteBallot() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    async function stationDeleteBallot({ id, comment }: StationDeleteBallotParams) {
        return await axios(moduleUrl
            + 'stations/my/delete-ballot',
            {
                method: 'post',
                data: {
                    id,
                    comment
                }
            })
    }

    return useMutation({
        mutationFn: stationDeleteBallot
    })
}