import PopupFooter from 'components/atoms/PopupFooter'
import PopupTextContent from 'components/atoms/PopupTextContent'
import PopupTitle from 'components/atoms/PopupTitle'
import { useTranslation } from 'react-i18next'
import styles from './useConfirmPopup.module.scss'
import { usePopup } from './usePopup'

export type ConfirmPopupSettings = {
    title: React.ReactNode,
    text: React.ReactNode,
    okText?: React.ReactNode,
    cancelText?: React.ReactNode,
    onOk: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>,
    onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>,
    onClose?: () => void,
    stayOpenedOnCancel?: boolean,
    maskClosable?: boolean,
    closeIcon?: boolean
    reverseButtons?: boolean
    width?: string
}

export const useConfirmPopup = () => {
    const { show, hide, popupPortal: confirmPortal } = usePopup()
    const { t } = useTranslation()

    const showConfirm = (settings: ConfirmPopupSettings) => {

        const close = () => {
            if (settings.onClose) {
                settings.onClose()
            } else {
                settings.onCancel?.({} as any)
            }
            hide()
        }

        const cancel = (e: React.MouseEvent<HTMLButtonElement>) => {

            if (settings.onCancel) {
                const onCancel = async () => {
                    await settings.onCancel!(e)

                    if (settings.stayOpenedOnCancel !== true) {
                        hide()
                    }
                }
                onCancel()

                return
            }
            hide()
        }

        const confirm = (e: React.MouseEvent<HTMLButtonElement>) => {
            const onConfirm = async () => {
                await settings.onOk(e)
                hide()
            }

            onConfirm()
        }

        show({
            ...settings,
            title: <PopupTitle>{settings.title}</PopupTitle>,
            children: <PopupTextContent>{settings.text}</PopupTextContent>,
            footer:
                <PopupFooter reverseButtons={settings.reverseButtons} >
                    <button className={`btn-main-primary ${styles.ok}`} onClick={(e) => confirm(e)}>
                        {settings.okText || t('ok')}
                    </button>
                    <button className={`btn-main-secondary ${styles.cancel}`}
                        onClick={(e) => cancel(e)}>
                        {settings.cancelText || t('cancel')}
                    </button>
                </PopupFooter>,
            onCancel: close,
            maskClosable: settings.maskClosable
        })
    }

    return { hide, showConfirm, confirmPortal }
}