import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { VotingApproach } from '../../models'

export type VerifyVoterParams = {
    voterId: string,
    votingApproach: VotingApproach
}

export function useVerifyVoter() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    async function verifyVoter({ voterId, votingApproach }: VerifyVoterParams) {
        return await axios(moduleUrl
            + 'stations/my/verify-voter',
            {
                method: 'post',
                data: {
                    id: voterId,
                    votingApproach
                }
            })
    }

    return useMutation({
        mutationFn: verifyVoter
    })
}