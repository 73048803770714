import Card from 'components/atoms/Card'
import EmptyMessage from 'components/atoms/EmptyMessage'
import CardHeader from 'components/molecules/CardHeader'
import { ReactComponent as Vote } from 'icons/vote.svg'
import { useTranslation } from 'react-i18next'
import { useElections } from '../../api'
import ElectionsPeriods from '../ElectionsPeriods'


const ActiveElections = () => {
    const { data: elections, isSuccess } = useElections()
    const { t } = useTranslation('election')

    return (
        <>
            <Card title={<CardHeader title={t('active_elections')} />} noContentPadding={true}>
                {isSuccess &&
                    (
                        (elections && elections.length)
                            ?
                            <ElectionsPeriods elections={elections} />
                            :
                            <EmptyMessage
                                title={t('there_is_no_active_election')}
                                description={t('click_button_and_go_through_the_creation_steps')}
                            >
                                <Vote />
                            </EmptyMessage>
                    )
                }
            </Card>
        </>
    )
}

export default ActiveElections