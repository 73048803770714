import { useElection, useLocality } from 'api'
import { useAuth } from 'hooks'
import { ElectionType, Role } from 'models'
import { useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

const needToLoadLocality = (role: Role) => {
    switch (role) {
        case Role.CHIEF_TELLER:
        case Role.CHIEF_TELLER_ASSISTANT:
            return true
    }

    return false
}

function InstitutionName() {
    const { auth } = useAuth()
    const { t } = useTranslation()
    const { data: locality } = useLocality(auth?.homeLocality?.code || '',
        !!auth?.homeLocality?.code && auth?.role && needToLoadLocality(auth.role)
    )
    const { data: election } = useElection(auth?.electionId || '',
        !!auth?.electionId)

    const lsaNameAndState = useMemo(() => (
        <>
            {t('local_spiritual_assembly_of')}
            <b> {` ${auth?.homeLocality?.name}, 
                ${auth?.homeLocality?.stateName}`
            }</b>
        </>
    ), [auth])

    const lsaNameAndStateAndCode = useMemo(() => (
        <>
            {lsaNameAndState}
            <b>{` (${auth?.homeLocality?.code})`}</b>
        </>
    ), [auth, lsaNameAndState])

    const electionBasedInstitutionName = useMemo(() => {
        if (election) {
            switch (election.type) {
                case ElectionType.RIDVAN_ELECTION:
                case ElectionType.BY_ELECTION:
                case ElectionType.LOCAL_DELEGATE_ELECTION:
                    return lsaNameAndStateAndCode
                case ElectionType.NATIONAL_DELEGATE_ELECTION:
                    return (
                        <>
                            <b>
                                {` ${locality?.unit?.name} ${locality?.unit?.code
                                    ? `(${locality.unit.code})`
                                    : ''}`}
                            </b>
                        </>
                    )
            }
        }

        return ''
    }, [election, locality])

    const getDesktopName = () => {
        if (auth?.role) {
            switch (auth.role) {
                case Role.LSA_OFFICER:
                    return lsaNameAndStateAndCode
                case Role.CHIEF_TELLER:
                case Role.CHIEF_TELLER_ASSISTANT:
                case Role.TELLING_STATION:
                    return electionBasedInstitutionName
                case Role.LSA_TELLER:
                    return lsaNameAndState
                case Role.NSA_ADMIN:
                case Role.SYSTEM_ADMIN:
                    return `${t('national_spiritual_assembly_of_the')} ${t('bahais_of_the_united_states')}`
            }
        }

        return ''
    }

    const getMobileName = () => <b>{`${auth?.homeLocality?.name} (${auth?.homeLocality?.code})`}</b>

    return (
        <div>
            {isMobile
                ? getMobileName()
                : getDesktopName()}
        </div>
    )
}

export default InstitutionName