import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { Comment } from '../../model'

export const useComment = (electionId: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getComment = async ({ queryKey }: QueryFunctionContext) => {
        const [, electionId] = queryKey

        const res = await axios<Comment>(
            `${moduleUrl}elections/${electionId}/election-results/comment`)

        return res.data
    }

    return useQuery<Comment>({
        queryKey: ['comment', electionId],
        queryFn: getComment,
        enabled
    })
}