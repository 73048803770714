import { AccessTokenContext } from 'context'
import { PropsWithChildren, useState } from 'react'



function AccessTokenProvider({ children }: PropsWithChildren) {
    const [accessToken, setAccessToken] = useState<string | null>(null)

    return (
        <AccessTokenContext.Provider value={{ accessToken, setAccessToken }} >
            {children}
        </AccessTokenContext.Provider>
    )
}

export default AccessTokenProvider