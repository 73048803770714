import Tooltip from 'components/atoms/Tooltip'
import { ElectionPushResult, ElectionPushStatus } from 'models'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { dayjsToString } from 'utils'
import ElectionPushBadge from '../ElectionPushBadge'
import Note from '../Note'
import style from './index.module.scss'

type ElectionPushResultNoteProps = {
    pushResult?: ElectionPushResult
}

function ElectionPushResultNote({ pushResult, children }: PropsWithChildren<ElectionPushResultNoteProps>) {
    const { t } = useTranslation('election')

    return (
        <>
            {!!pushResult &&
                <div className={style.pushedNote}>
                    <Note icon={'regular'} mode={'active'}>
                        <div className={style.note}>
                            <div className={style.noteTitle}>
                                <div className={style.noteItem}>
                                    {t('results_sent_to')} {t('common:eMembership2')}:
                                </div>
                                <div className={style.noteItem}>
                                    {dayjsToString(pushResult?.createdAt || '', 'MM/DD/YYYY hh:mm A')}
                                </div>
                                {pushResult?.status === ElectionPushStatus.REJECTED ? 
                                <Tooltip title={pushResult?.responseError}>
                                    <div className={style.badge}>
                                        <ElectionPushBadge status={pushResult?.status || ''} />
                                    </div>
                                </Tooltip> : 
                                <div className={style.badge}>
                                    <ElectionPushBadge status={pushResult?.status || ''} />
                                </div>}
                            </div>
                            {
                                children
                            }
                        </div>
                    </Note>
                </div>}
        </>
    )
}

export default ElectionPushResultNote