import { useQuery } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { OfficersElection } from 'models'

export const useOfficersElections = (enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getOfficersElection = async () => {
        const response = await axios<OfficersElection[]>(moduleUrl
            + 'me/teller/officers-elections')

        return response.data
    }

    return useQuery({
        queryKey: ['my-officers-election'],
        queryFn: getOfficersElection,
        enabled
    })
}