import { useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { ElectionParticipant } from 'models'

export const useOfficersElectionParticipants = (localityCode: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const officersElectionParticipants = async ()
        : Promise<ElectionParticipant[]> => {

        const result = await axios<ElectionParticipant[]>(
            `${moduleUrl}participants/officers-election?locality=${localityCode}`)

        return result.data
    }

    return useQuery({
        queryKey: ['officers-election-participants', localityCode],
        queryFn: officersElectionParticipants,
        enabled
    })
}