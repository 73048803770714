import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { ElectionSchedule } from 'models'

type setUpScheduleParams = {
    schedule: ElectionSchedule,
    electionPeriod: string
}

export function useSetUpSchedule() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function setUpSchedule({ schedule, electionPeriod }: setUpScheduleParams) {
        return await axios(moduleUrl
            + `by-election-periods/${electionPeriod}/election/set-up-schedule`,
            {
                method: 'post',
                data: schedule
            })

    }

    return useMutation({
        mutationFn: setUpSchedule
    })
}