import { PropsWithChildren, useState } from 'react'
import { VotingStatisticTableDataContext } from '../context'
import { VotingStatisticTableData } from '../models'

function VotingStatisticTableDataProvider({ children }: PropsWithChildren) {
    const [tableData, setTableData] = useState<VotingStatisticTableData>({
        summary: {
            eligibleToVote: 0,
            votedOnlineInAbsence: 0,
            votedOnlineInPerson: 0,
            votedPaperInAbsence: 0,
            votedPaperInPerson: 0,
            totalVoted: 0
        },
        localities: []
    })

    return (
        <VotingStatisticTableDataContext.Provider value={{ tableData, setTableData }}>
            {children}
        </VotingStatisticTableDataContext.Provider>
    )
}

export default VotingStatisticTableDataProvider