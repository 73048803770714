import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import Card from 'components/atoms/Card'
import IconWrapper from 'components/atoms/IconWrapper'
import CardHeader from 'components/molecules/CardHeader'
import Preloader from 'components/molecules/Preloader'
import { useReportLoader } from 'hooks'
import { ReactComponent as Printer } from 'icons/printer.svg'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReportingData } from '../../hooks'
import { ReportFilter } from '../../model/report-filter'
import BallotingYear from '../BallotingYear'
import ElectionTypeFilter from '../ElectionTypeFilter'
import style from './index.module.scss'

function Filters() {
    const { t } = useTranslation('administration')
    const { reportingData } = useReportingData()
    const [form] = useForm()
    const [isFormValid, setIsFormValid] = useState(false)
    const { loadReport, isReportLoading } = useReportLoader()

    useEffect(() => {
        form.resetFields()
        setIsFormValid(false)
    }, [reportingData.selectedReport, form])

    const handleFormValuesChange = (_: any, allFields: Record<string, any>) => {
        const allFieldsValid = Object.values(allFields).every(value => value !== undefined && value !== '')
        setIsFormValid(allFieldsValid)
    }

    const load = () => {
        const report = reportingData.selectedReport
        if (report) {
            loadReport({
                reportType: report.type,
                reportFormat: report.format,
                params: {
                    ...form.getFieldsValue()
                }
            })
        }
    }

    return (
        <Card title={<CardHeader title={!!reportingData.selectedReport
            ? t('report_report_name', {
                reportName: reportingData.selectedReport.label,
                interpolation: { escapeValue: false }
            })
            : t('select_report')} />}>
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            <Form
                layout="vertical"
                onValuesChange={handleFormValuesChange}
                form={form}>
                <div className={style.content}>
                    <div className={style.filters}>
                        <BallotingYear disabled={!reportingData.selectedReport} />
                        {reportingData.selectedReport?.filters.length
                            ? reportingData.selectedReport?.filters.map(filter => {
                                switch (filter) {
                                    case ReportFilter.Institution:
                                        return 'institution'
                                    case ReportFilter.ElectionType:
                                        return <ElectionTypeFilter />
                                    default:
                                        return 'unknown filter'
                                }
                            })
                            : <></>
                        }
                    </div>
                    <button
                        type="submit"
                        className={`${style.generate} btn-main-primary-md`}
                        disabled={!isFormValid}
                        onClick={() => load()}
                    >
                        <IconWrapper>
                            <Printer />
                        </IconWrapper>
                        <span>{t('generate_n_download', { interpolation: { escapeValue: false } })}</span>
                    </button>
                </div>
            </Form>
        </Card>
    )
}

export default Filters