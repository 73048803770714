import { MenuProps } from 'antd'
import { useElection, useElectionRestrictionsOld, useElectionSummary, useElectionTellingStations } from 'api'
import { Can } from 'components/atoms/Can'
import Card from 'components/atoms/Card'
import IconWrapper from 'components/atoms/IconWrapper'
import Dropdown from 'components/molecules/Dropdown'
import { useAuth, useConfirmPopup, useInfoPopup, usePopup } from 'hooks'
import { ReactComponent as Dots } from 'icons/dots.svg'
import { ReactComponent as Reset } from 'icons/reset.svg'
import { Restriction } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getElectionRestrictions } from 'utils'
import { useRollBackTellersProcess } from '../../api/mutations/rollBackTellersProcess'
import MergingResultsMilestone from '../MergingResultsMilestone'
import OnlineVotingMilestone from '../OnlineVotingMilestone'
import ReportingMilestone from '../ReportingMilestone'
import StationProcessStatistic from '../StationProcessStatistic'
import TellingProcessMilestone from '../TellingProcessMilestone'
import WhatIfWeDontHavePhysicalBallotsLink from '../WhatIfWeDontHavePhysicalBallotsLink'
import style from './index.module.scss'

function Tasks() {
    const { t } = useTranslation('teller')

    const { electionId } = useParams()
    const { auth } = useAuth()
    const { confirmPortal, showConfirm } = useConfirmPopup()
    const { infoPortal, showInfo } = useInfoPopup()
    const { data: election } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: tellingStations } = useElectionTellingStations(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const [restriction, setRestriction] = useState<Restriction | null | undefined>()
    const { data: restrictions } = useElectionRestrictionsOld()
    const { data: summary } = useElectionSummary(electionId || '', !!electionId)
    const { mutateAsync: rollBackTellersProcess } = useRollBackTellersProcess()

    // TODO: Improve by creating a global popup portal
    const { show, hide, popupPortal } = usePopup()

    const confirmRollBack = async () => {
        try {
            await rollBackTellersProcess({ electionId: electionId || '' })
            showSuccessPopup()
        } catch (error) {
            showErrorPopup()
        }
    }

    const showSuccessPopup = () => {
        showInfo({
            title: t('common:success'),
            text: t('administration:the_progress_has_been_successfully_rolled_back'),
            onOk: () => { }
        })
    }

    const showErrorPopup = () => {
        showInfo({
            title: t('common:error'),
            text: t('administration:could_not_perform_the_operation'),
            onOk: () => { }
        })
    }

    const showRollBackPopup = () => {
        showConfirm({
            title: t('administration:roll_back_to_merged_results'),
            text:
                <>
                    <div>
                        {t('administration:are_you_sure_you_want_to_roll_back_the_progress')}
                        <div style={{ paddingTop: 'var(--default-space' }}>
                            {t('administration:this_action_will_cancel_the_signed_tellers_report')}
                        </div>
                        <div style={{ paddingTop: 'var(--default-space' }}>
                            <b>{t('common:please_note_this_is_an_irreversible_action')}</b>
                        </div>
                    </div>
                </>,
            okText: t('common:confirm'),
            onOk: async () => { confirmRollBack() }
        })
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <div className={style.option}>
                <IconWrapper>
                    <Reset className={style.icon} />
                </IconWrapper>
                {t('administration:roll_back_to_merged_results')}</div>,
            onClick: () => showRollBackPopup()
        }
    ]

    useEffect(() => {
        if (election && restrictions) {
            setRestriction(getElectionRestrictions(election.type, restrictions))
        }
    },
        [election, restrictions])

    return (
        <>
            {infoPortal}
            {confirmPortal}
            {popupPortal}
            <Card noContentPadding={true} noHeaderLine>
                <div className={style.title}>
                    <div>
                        {t('chief_tellers_tasks')}
                    </div>
                    {summary?.signedBy && !summary?.confirmedBy
                        && <div>
                            <Can I="perform" a="RollBackTellersProcess">
                                <Dropdown menu={{ items }} openClassName={style.active}
                                    trigger={['click']}
                                >
                                    <button className={`btn-main-tertiary-md ${style.actions}`}>
                                        <IconWrapper>
                                            <Dots />
                                        </IconWrapper>
                                    </button>
                                </Dropdown>
                            </Can>
                        </div>
                    }
                </div>
                <div className={style.cardsContainer}>
                    {<OnlineVotingMilestone />}
                    {<TellingProcessMilestone />}
                    {<MergingResultsMilestone />}
                    {<ReportingMilestone />}
                </div>
                <div className={style.link}>
                    {restriction?.bypassFlowAllowed &&
                        election?.bypassFlow === false &&
                        !tellingStations?.length &&
                        <WhatIfWeDontHavePhysicalBallotsLink show={show} hide={hide} />}
                </div>
                {election?.bypassFlow === false && !!tellingStations?.length
                    && <StationProcessStatistic show={show} hide={hide} />}
            </Card>
        </>
    )
}

export default Tasks
