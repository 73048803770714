import ContentWrapper from 'components/atoms/ContentWrapper'
import { useAuth, useBackToDashboardLine } from 'hooks'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import BackToDashboardLine from '../../../../../components/molecules/BackToDashboardLine'
import VotingStatistic from '../components/VotingStatistic'

const ViewVotingStatistic = () => {
    const { setBackToDashboardLine } = useBackToDashboardLine()
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { electionId } = useParams()
    const { t } = useTranslation('teller')
    const goToDashboard = useCallback(() => navigate(-1), [navigate])

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine
                sticky={true}
                fixedWidth={true}
                onBackToDashboardBtnClick={goToDashboard}
                backButtonTitle={t('back_to_eligible_individuals')} />
        )

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setBackToDashboardLine, goToDashboard, auth, electionId])

    return (
        <ContentWrapper>
            <VotingStatistic />
        </ContentWrapper>
    )
}

export default ViewVotingStatistic