import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { NoLongerServingOfficer } from 'models'

type setUpScheduleParams = {
    electionPeriod: string,
    noLongerServing: NoLongerServingOfficer[]
}

export function useDefineCandidates() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function defineCandidates({ noLongerServing, electionPeriod }: setUpScheduleParams) {
        return await axios(moduleUrl
            + `by-election-periods/${electionPeriod}/election/define-candidates`,
            {
                method: 'post',
                data: { noLongerServing }
            })

    }

    return useMutation({
        mutationFn: defineCandidates
    })
}