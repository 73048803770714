import React, {PropsWithChildren} from 'react'
import styles from './index.module.scss'

interface IEmptyMessageProps {
    title: string,
    description: string
}

const EmptyMessage: React.FC<PropsWithChildren<IEmptyMessageProps>> = ({title, description, children}) => (
        <div className={styles.wrapper}>
            <div className={styles.icon}>{children}</div>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
        </div>
    )

export default EmptyMessage