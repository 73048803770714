import { ColumnsType } from 'antd/es/table'
import { useElectionsSummary, useLocalDelegateElectionPushResult, useLocality } from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import PageHeader from 'components/atoms/PageHeader'
import TableHeaderCell from 'components/atoms/TableHeaderCell'
import Tooltip from 'components/atoms/Tooltip'
import BackToDashboardBtn from 'components/molecules/BackToDashboardBtn'
import ElectionBadge from 'components/molecules/ElectionBadge'
import ElectionPushResultNote from 'components/molecules/ElectionPushResultNote'
import Preloader from 'components/molecules/Preloader'
import StatisticCounter from 'components/molecules/StatisticCounter'
import Table from 'components/molecules/Table'
import { useAbility, useElectionTypeMap, useHandleEntityLoadingError, usePageHeader, useReportLoader } from 'hooks'
import { ReactComponent as ArrowDownload } from 'icons/arrow-download.svg'
import {
    ElectionStatusType,
    ElectionType,
    LocalDelegateStageElection,
    ReportFormat,
    ReportType
} from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useElectionPeriod, usePushLocalDelegateResults } from '../../api'
import { getLocalDelegateStatus } from '../../utils'
import UnitElectionBallotingProgress from '../UnitElectionBallotingProgress'
import UnitElectionBallotsDetails from '../UnitElectionBallotsDetails'
import UnitElectionOnlineVotingStatus from '../UnitElectionOnlineVotingStatus'
import UnitElectionReports from '../UnitElectionReports'
import UnitElectionResultConfirm from '../UnitElectionResultConfirm'
import UnitElectionTellers from '../UnitElectionTellers'
import UnitElectionVotingStatistic from '../UnitElectionVotingStatistic'
import style from './index.module.scss'

function UnitsElections() {
    const { t } = useTranslation('election')
    const { handleEntityLoadingError } = useHandleEntityLoadingError()
    const navigate = useNavigate()
    const { setPageHeader } = usePageHeader()
    const { toString } = useElectionTypeMap()
    const { ability } = useAbility()
    const { electionPeriodId } = useParams()
    const { data: ridvanElectionPeriod, error } = useElectionPeriod(electionPeriodId!)
    const { loadReport, isReportLoading } = useReportLoader()

    const [statistic, setStatistic] = useState<{ inProgress: number, confirmed: number }>(
        { inProgress: 0, confirmed: 0 })

    const allElectionsIds = useMemo(() =>
        ridvanElectionPeriod?.localDelegateElectionStage?.elections.map(e => e.id.toString()) || [],
        [ridvanElectionPeriod]
    )

    const { data: electionsSummary } = useElectionsSummary({
        electionIds: allElectionsIds
    }, !!ridvanElectionPeriod)
    const { mutateAsync: pushLocalDelegateResults } = usePushLocalDelegateResults()
    const { data: pushResult, refetch } = useLocalDelegateElectionPushResult(electionPeriodId || '')
    const { data: locality } = useLocality(ridvanElectionPeriod?.localityCode || '', !!ridvanElectionPeriod)

    const sendResults = () => {
        if (electionPeriodId) {
            pushLocalDelegateResults({ electionPeriod: electionPeriodId })
            refetch()
        }
    }

    const canSend = useMemo(() =>
        electionsSummary &&
        electionsSummary.filter(eS => eS.electionResults.confirmedBy).length === allElectionsIds.length &&
        !pushResult?.status,
        [electionsSummary, allElectionsIds, pushResult]
    )

    useEffect(() => {
        setPageHeader(
            <PageHeader
                sticky
                greeting={<BackToDashboardBtn />}>
                {!pushResult && <button className="btn-main-primary-md"
                    onClick={() => sendResults()}
                    disabled={!canSend}>
                    {canSend
                        ? <>{t('send_results_to')} {t('common:eMembership2')}</>
                        : <Tooltip title={t('election:please_confirm_the_results_for_each_unit')}>
                            {t('send_results_to')} {t('common:eMembership2')}
                        </Tooltip>}
                </button>
                }
            </PageHeader >
        )

        return () => { setPageHeader(null) }
    }, [pushResult, electionsSummary, canSend]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (ridvanElectionPeriod && !ridvanElectionPeriod.localDelegateElectionStage)
            navigate('/page-not-found', { replace: true })
        if (ridvanElectionPeriod?.localDelegateElectionStage?.elections?.length) {
            setStatistic(() => ({ confirmed: 0, inProgress: 0 }))
            ridvanElectionPeriod.localDelegateElectionStage.elections.forEach(e => {
                if (e.status === ElectionStatusType.IN_PROGRESS) {
                    setStatistic((current) => ({ ...current, inProgress: current.inProgress + 1 }))

                    return
                }
                if (e.status === ElectionStatusType.COMPLETED) {
                    setStatistic((current) => ({ ...current, confirmed: current.confirmed + 1 }))

                    return
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        ridvanElectionPeriod
    ])

    useEffect(() => {
        handleEntityLoadingError(error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const downloadSummaryReport = () => {
        loadReport({
            reportType: ReportType.LDESummaryReport,
            reportFormat: ReportFormat.PDF,
            params: {
                electionPeriod: electionPeriodId
            }
        })
    }

    const columns: ColumnsType<LocalDelegateStageElection> = useMemo(() => [
        {
            title: <TableHeaderCell>{t('local_unit')}</TableHeaderCell>,
            render: (election: LocalDelegateStageElection) => election.status === ElectionStatusType.NOT_STARTED
                ? election.localUnitCode
                : <Link
                    to={`/election-board/${election.id}`} state={{ backButtonTitle: t('back_to_list') }}>
                    {election.localUnitCode}
                </Link>,
            width: 110,
            key: 'localUnitCode'
        },
        {
            title: <TableHeaderCell>{t('#_of_delegates')}</TableHeaderCell>,
            dataIndex: 'numberOfDelegates',
            width: 100,
            key: 'numberOfDelegates'
        },
        {
            title: <TableHeaderCell>{t('tellers')}</TableHeaderCell>,
            render: (election: LocalDelegateStageElection) => <UnitElectionTellers
                allElectionsIds={allElectionsIds}
                election={election}
            />,
            width: 220
        },
        {
            title: <TableHeaderCell>{t('participated_eligible_voters')}</TableHeaderCell>,
            render: (election: LocalDelegateStageElection) => <UnitElectionVotingStatistic
                allElectionsIds={allElectionsIds}
                electionId={election.id.toString()}
            />,
            width: 152
        },
        {
            title: <TableHeaderCell>{t('online_voting_status')}</TableHeaderCell>,
            render: (election: LocalDelegateStageElection) => <UnitElectionOnlineVotingStatus
                allElectionsIds={allElectionsIds}
                electionId={election.id.toString()}
            />,
            width: 130
        },
        {
            title: <TableHeaderCell>
                <div className={style.progressWrapper}>
                    <div className={style.ballotingProgress}>
                        {t('chief_teller_s_tasks')}
                    </div>
                    <div className={style.progressSteps}>
                        <div className={style.progressStep}>
                            {t('common:telling_process')}
                        </div>
                        <div className={style.progressStep}>
                            {t('common:merging_results')}
                        </div>
                        <div className={style.progressStep}>
                            {t('common:reporting')}
                        </div>
                    </div>
                </div>
            </TableHeaderCell>,
            render: (election: LocalDelegateStageElection) => !!ridvanElectionPeriod
                ? <UnitElectionBallotingProgress
                    ridvanElectionPeriod={ridvanElectionPeriod}
                    allElectionsIds={allElectionsIds}
                    election={election}
                />
                : null,
            width: 309
        },
        {
            title: <TableHeaderCell>{t('common:details')}</TableHeaderCell>,
            render: (election: LocalDelegateStageElection) => (
                <UnitElectionBallotsDetails allElectionsIds={allElectionsIds}
                    electionId={election.id.toString()} />),
            width: 72
        },
        {
            title: <TableHeaderCell>{t('common:action')}</TableHeaderCell>,
            render: (election: LocalDelegateStageElection) => <UnitElectionResultConfirm
                allElectionsIds={allElectionsIds}
                electionId={election.id.toString()} />,
            width: 171
        },
        {
            title: <TableHeaderCell>{t('common:reports')}</TableHeaderCell>,
            render: (election: LocalDelegateStageElection) => <UnitElectionReports
                allElectionsIds={allElectionsIds}
                electionId={election.id.toString()} />,
            width: 80
        }
    ], [allElectionsIds, ridvanElectionPeriod, t])

    if (!ridvanElectionPeriod?.localDelegateElectionStage) return null

    return <>
        {isReportLoading && <Preloader title={t('working_on_your_report')} />}
        {!!pushResult && <div className={style.pushResult}>
            <ElectionPushResultNote pushResult={pushResult} />
        </div>}
        <Table
            dataSource={ridvanElectionPeriod?.localDelegateElectionStage?.elections?.sort(
                (a, b) => a.localUnitCode.localeCompare(b.localUnitCode)
            ) || []}
            className={style.table}
            columns={columns}
            pagination={false}
            stickyOffset="calc(var(--header-height) + var(--page-header-height))"
            headerDividerVisible={true}
            rowKey="id"
            caption={
                <div className={style.caption}>
                    <div className={style.electionInfo}>
                        {t('year_b_e_election',
                            {
                                year: ridvanElectionPeriod.ballotingYear,
                                election: toString(ElectionType.LOCAL_DELEGATE_ELECTION)
                            })}
                        {ability?.can('view', 'LocalityName') && !!locality
                            && ` (${locality.name}, ${locality.code})`
                        }
                        <ElectionBadge
                            status={getLocalDelegateStatus(
                                ridvanElectionPeriod.localDelegateElectionStage?.elections || [],
                                pushResult
                            )}
                        />
                    </div>
                    <div className={style.statistic}>
                        {!!pushResult &&
                            <button
                                className={`btn-main-tertiary-md ${style.download}`}
                                onClick={downloadSummaryReport}>
                                <IconWrapper>
                                    <ArrowDownload />
                                </IconWrapper>
                                {t('administration:summary_report')}
                            </button>
                        }
                        <StatisticCounter
                            value={ridvanElectionPeriod.localDelegateElectionStage?.elections?.length}
                            title={t('total_number_of_units')} />
                        <StatisticCounter value={
                            (ridvanElectionPeriod.localDelegateElectionStage?.elections?.length || 0)
                            - statistic.inProgress - statistic.confirmed} title={t('not_started')} />
                        <StatisticCounter value={statistic.inProgress} title={t('in_progress')} />
                        <StatisticCounter value={statistic.confirmed} title={t('common:confirmed')} />
                    </div>
                </div>
            } />
    </>
}

export default UnitsElections