import { queryClient } from 'config/query-client'
import { useAuth } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import { useGenerateIntructions, useInstructionUrl } from '../api'
import { useTellingStationProcessInfo } from './useTellingStationProcessInfo'

export const useDownloadPdfInstruction = () => {
    const { info, setInfo } = useTellingStationProcessInfo()
    const { auth } = useAuth()
    const { getInstructionUrl } = useInstructionUrl()
    const [instructionIsNeeded, setInstructionIsNeeded] = useState(false)
    const [timerId, setTimerId] = useState<NodeJS.Timer>()
    const { data: responseCode } = useGenerateIntructions(instructionIsNeeded)

    const redirectToInstruction = useCallback(() => {
        const tempLink = document.createElement('a')
        tempLink.href = getInstructionUrl(auth?.electionId || '')
        tempLink.target = '_blank'
        tempLink.click()
    }, [auth])

    useEffect(() => {
        if (instructionIsNeeded) {
            const resetInstructionLoading = () => {
                if (timerId) {
                    clearInterval(timerId)
                    setTimerId(undefined)
                    clearInterval(timerId)
                    setInfo(current => ({ ...current, isPdfInstructionLoading: false }))
                }
            }
            if (responseCode) {
                if (responseCode === 204 && !timerId) {
                    setInfo(current => ({ ...current, isPdfInstructionLoading: true }))
                    const tId = setInterval(async () => {
                        await queryClient.invalidateQueries(['instructions'])
                    }, 1000)
                    setTimerId(tId)
                } else {
                    if (responseCode === 201) {
                        redirectToInstruction()
                        setInfo(current => ({ ...current, isPdfInstructionReady: true }))
                    }
                    resetInstructionLoading()
                    setInfo(current => ({ ...current, isPdfInstructionLoading: false }))

                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseCode, instructionIsNeeded])

    const downloadPdfInstruction = () => {
        if (info.isPdfInstructionReady) {
            redirectToInstruction()
        } else { setInstructionIsNeeded(true) }
    }

    return { downloadPdfInstruction }

}