import { useMutation } from '@tanstack/react-query'
import { ApiModule, getModuleUrl, useAxios } from 'api'
import { Vote } from '../../models'

type StationUpdateBallotParams = {
    ballotId: string,
    votes?: Vote[],
    invalidationReason?: string
}

export function useStationUpdateBallot() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.TellingStation)

    async function stationUpdateBallot({ ballotId, votes, invalidationReason }: StationUpdateBallotParams) {
        return await axios(moduleUrl
            + `stations/my/ballots/${ballotId}/update-votes`,
            {
                method: 'post',
                data: {
                    votes,
                    invalidationReason
                }
            })
    }

    return useMutation({
        mutationFn: stationUpdateBallot
    })
}