import { useLocality } from 'api'
import { useElectionTypeMap } from 'hooks'
import { ElectionType } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BallotingElection } from '../models'

export const useElectionTitle = (election: BallotingElection) => {
    const { t } = useTranslation()
    const { toString } = useElectionTypeMap()
    const { data: locality } = useLocality(election.region.locality, !!election?.region?.locality)

    const yearAndType = useMemo(() => t('year_b_e_election',
        {
            year: election.ballotingYear,
            election: toString(election.electionType)
        }
    ), [election])

    const lsaAndLocality = useMemo(() => t('local_spiritual_assembly_with_locality_and_state',
        {
            locality: locality?.name || '',
            state: locality?.state?.name || ''
        }
    ), [locality])

    const lsaAndLocalityAndUnit = useMemo(() => t('local_spiritual_assembly_with_locality_and_state_unit',
        {
            locality: locality?.name || '',
            state: locality?.state?.name || '',
            localUnit: election?.region?.localUnit || ''
        }
    ), [locality])

    const electionYearAndTypeTitle = useMemo(() => {
        switch (election.electionType) {
            case ElectionType.BY_ELECTION:
            case ElectionType.RIDVAN_ELECTION:
            case ElectionType.NATIONAL_DELEGATE_ELECTION:
            case ElectionType.LOCAL_DELEGATE_ELECTION:
                return yearAndType
            case ElectionType.OFFICERS_ELECTION:
            case ElectionType.BREAKING_TIE:
                return election.name
        }

        return ''
    }, [yearAndType])

    const electionInstitutionTitle = useMemo(() => {
        switch (election.electionType) {
            case ElectionType.BY_ELECTION:
            case ElectionType.RIDVAN_ELECTION:
            case ElectionType.OFFICERS_ELECTION:
                return lsaAndLocality
            case ElectionType.LOCAL_DELEGATE_ELECTION:
                return lsaAndLocalityAndUnit
            case ElectionType.NATIONAL_DELEGATE_ELECTION:
                return t('for_unit_name_electoral_unit', {
                    unitName: election?.region?.name,
                    electoralUnit: election?.region?.unit,
                    interpolation: { escapeValue: false }
                })
            case ElectionType.BREAKING_TIE:
                return election.region.name
        }

        return ''
    }, [lsaAndLocality])

    const electionTitle = useMemo(() => {
        switch (election.electionType) {
            case ElectionType.BY_ELECTION:
            case ElectionType.RIDVAN_ELECTION:
            case ElectionType.OFFICERS_ELECTION:
            case ElectionType.BREAKING_TIE:
            case ElectionType.LOCAL_DELEGATE_ELECTION:
                return [`${electionYearAndTypeTitle}:`, electionInstitutionTitle].join(' ')
            case ElectionType.NATIONAL_DELEGATE_ELECTION:
                return [`${electionYearAndTypeTitle}`, electionInstitutionTitle].join(' ')
        }

        return ''
    }, [election, lsaAndLocality, electionYearAndTypeTitle, electionInstitutionTitle])

    return { electionTitle, electionYearAndTypeTitle, electionInstitutionTitle }
}