import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'

type CommentCommand = {
    electionId: string,
    comment: string
}

export function useSaveComment() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    async function mutateSaveComment({ electionId, comment }: CommentCommand) {

        return await axios.post(
            `${moduleUrl}elections/${electionId}/election-results/comment`,
            comment,
            {
                headers: {
                    'Content-Type': 'text/plain'
                }
            }
        )
    }

    return useMutation({
        mutationFn: mutateSaveComment
    })
}