import { useQuery } from '@tanstack/react-query'
import { ApiModule } from 'api/api-module'
import { useAxios } from 'api/hooks'
import { getModuleUrl } from 'api/utils'
import { ReportFilter } from 'models'
import { getUrlParamsFromFilter } from 'utils'

export const useGenerateReport = ({ reportType, reportFormat, params }: ReportFilter, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Report)

    const queryParams = getUrlParamsFromFilter(params)

    const generate = async () => {
        const response = await axios<string>(moduleUrl + `${reportType}/generate/${reportFormat}?${queryParams}`)

        return response.data
    }

    return useQuery({
        queryKey: ['report', reportType, reportFormat, params],
        queryFn: generate,
        enabled
    })
}