import {PropsWithChildren} from 'react'
import styles from './popup-footer.module.scss'

type PopupFooterProps = {
    reverseButtons?: boolean
}

function PopupFooter({ children, reverseButtons }: PropsWithChildren<PopupFooterProps>) {
    return (
        <div className={`${styles.footer} ${reverseButtons ? styles.reverseButtons : ''}`}>{children}</div>
    )
}

export default PopupFooter