import { useElectionBallotsCounts, useVerifiedVoterCount } from 'api'
import { Can } from 'components/atoms/Can'
import IconWrapper from 'components/atoms/IconWrapper'
import { PopupProps } from 'components/molecules/Popup'
import { useAuth, useInfoPopup } from 'hooks'
import { ReactComponent as QuestionInCircle } from 'icons/question-mark-in-circle.svg'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { tellingProcess } from '../../../tellingProcess'
import style from './index.module.scss'

interface WhatIfWeDontHavePhysicalBallotsLinkProps {
    show: (props: PopupProps) => void
    hide: () => void
}

function WhatIfWeDontHavePhysicalBallotsLink({ show, hide }: WhatIfWeDontHavePhysicalBallotsLinkProps) {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const { infoPortal, showInfo } = useInfoPopup()

    const { electionId } = useParams()
    const { selectElectionFlow } =
        tellingProcess.hooks.useSelectElectionFlow(show, hide, auth!.electionId!)
    const { data: ballotsCounts } = useElectionBallotsCounts(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: verifiedVoterCount } = useVerifiedVoterCount(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)

    const onSwitchToBypassFlowClick = () => {
        if (ballotsCounts?.validBallotCount || ballotsCounts?.invalidBallotCount
            || verifiedVoterCount?.totalCount) {
            showInfo({
                title: t('cannot_start_telling_with_online_ballots_only'),
                text: <>{t('to_proceed_with_this_action_you_need_to_launch_stations_and')}
                    <ul className={style.popupList}>
                        <li>{t('delete_entered_ballots_on_the_enter_ballots_step')}</li>
                        <li>{t('uncheck_voters_marked_as_having_voted_with_a_paper_ballot')}</li>
                    </ul></>,
                onOk: async () => { }
            })
        } else {
            selectElectionFlow()
        }
    }

    return (
        <div>
            {infoPortal}
            {<Can not I="view-only" a="ChiefTellerActions">
                <button className={`btn-main-tertiary-md ${style.linkLikeButton}`}
                    onClick={onSwitchToBypassFlowClick}>
                    <IconWrapper><QuestionInCircle height={16} width={16} /></IconWrapper>
                    {t('what_if_we_dont_have_any_physical_ballots')}
                </button>
            </Can>}
        </div>
    )
}

export default WhatIfWeDontHavePhysicalBallotsLink