import { useElection, useElectionPushResult, useLocalDelegateElectionPushResult } from 'api'
import IconWrapper from 'components/atoms/IconWrapper'
import ElectionPushResultNote from 'components/molecules/ElectionPushResultNote'
import Preloader from 'components/molecules/Preloader'
import { useReportLoader } from 'hooks'
import { ReactComponent as ArrowDownload } from 'icons/arrow-download.svg'
import { ElectionType, ReportFormat, ReportType } from 'models'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import LuElectionResultsNotPushedNote from '../LuElectionResultsNotPushedNote'

function ConfirmedResultsNote() {
    const { t } = useTranslation('results')
    const { loadReport, isReportLoading } = useReportLoader()
    const { electionId } = useParams()
    const { data: election } = useElection(electionId?.toString() || '')
    const { data: ldePushResult } = useLocalDelegateElectionPushResult(election?.electionPeriod.toString() || '',
        election?.type === ElectionType.LOCAL_DELEGATE_ELECTION)
    const { data: electionPushResult } = useElectionPushResult(election?.id.toString() || '',
        !!election && election.type !== ElectionType.LOCAL_DELEGATE_ELECTION)

    const pushResult = ldePushResult || electionPushResult

    const downloadReport = () => {
        loadReport({
            reportType: ReportType.ConfirmedCandidateTellerReport,
            reportFormat: ReportFormat.PDF,
            params: {
                election: electionId
            }
        })
    }

    return (
        <>
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            {pushResult?.status
                ? <ElectionPushResultNote pushResult={pushResult}>
                    <button className="btn-main-tertiary-md hasIcon" onClick={downloadReport}>
                        <IconWrapper>
                            <ArrowDownload className="flip-horizontal" width="16" height="16" />
                        </IconWrapper>
                        <span>{t('tellers_report_confirmed_results')}</span>
                    </button>
                </ElectionPushResultNote>
                : <LuElectionResultsNotPushedNote />}
        </>
    )
}

export default ConfirmedResultsNote