import { useMutation } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { queryClient } from 'config/query-client'
import { useState } from 'react'

type StartBreakTheTieRoundProps = {
    electionId: string,
    invitation?: boolean
}

export function useStartBreakTheTieRound() {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)
    const [electionId, setElectionId] = useState('')

    async function StartBreakTheTieRound({ electionId, invitation }: StartBreakTheTieRoundProps) {

        setElectionId(electionId)

        return await axios(`${moduleUrl}elections/${electionId}/initiate-breaking-tie-round`,
            {
                method: 'post',
                data: {
                    sendInvitations: invitation
                }
            })
    }

    return useMutation({
        mutationFn: StartBreakTheTieRound,
        onSuccess: async () => {
            await queryClient.invalidateQueries(['election-summary', electionId])
            await queryClient.invalidateQueries(['candidates-elected', electionId])
            await queryClient.invalidateQueries(['election-results-candidates', electionId])
            await queryClient.invalidateQueries(['election-results-tie', electionId])
            await queryClient.invalidateQueries(['breaking-tie-rounds', electionId])
        }
    })
}