import { ReactComponent as ArrowDown } from 'icons/arrow-down.svg'
import { ReactComponent as Sorting } from 'icons/sorting.svg'
import { SortBy, SortOrder } from 'models'
import { ReactNode } from 'react'

import style from './index.module.scss'

interface SortableHeaderCellProps {
    value?: SortBy,
    title: ReactNode,
    field: string,
    onChange?: (name: string) => void
}

const SortableHeaderCell = ({ value, title, field, onChange }: SortableHeaderCellProps) => {
    const handleClick = () => {
        if (onChange) {
            onChange(field)
        }
    }

    let icon = <Sorting className={style.arrows} />
    if (value?.field === field) {
        if (value.order === SortOrder.ASC) {
            icon = <ArrowDown className={`${style.arrows} flip-vertical`} />
        } else {
            icon = <ArrowDown className={style.arrows} />
        }
    }

    return (
        <div className={style.columnHeader} onClick={handleClick}>
            <span>{title}</span>
            {icon}
        </div>
    )
}

export default SortableHeaderCell
