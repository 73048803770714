import { RuleObject } from 'antd/es/form'

export const isEmailValidFunc = (
    emailValidationError: string,
    emailLengthError: string,
    maxEmailLength: number,
    _: RuleObject,
    value: string | undefined
): Promise<void> => {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,7}$/
    if (!value) {
        return Promise.resolve()
    }
    if (value.length > maxEmailLength) {
        return Promise.reject(new Error(emailLengthError))
    }
    if (emailPattern.test(value)) {
        return Promise.resolve()
    } else {
        return Promise.reject(new Error(emailValidationError))
    }
}