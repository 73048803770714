import IconWrapper from 'components/atoms/IconWrapper'
import { useMemo } from 'react'
import { WizardChipSettings } from '../models'
import { WizardTabStatus } from '../models/wizard-tab-status'
import styles from './wizard-chip.module.scss'

type Props = {
    settings: WizardChipSettings,
    status: WizardTabStatus,
    leaveStepCallback?: () => Promise<boolean>
    onClick: () => void
}

const getClassName = (tabStatus: WizardTabStatus) => {
    switch (tabStatus) {
        case WizardTabStatus.Active:
            return styles.wizardChipActive
        case WizardTabStatus.NotActivated:
            return styles.wizardChipNotActivated
        case WizardTabStatus.Valid:
            return styles.wizardChipValid
    }
}

function WizardChip({ settings, status, leaveStepCallback, onClick }: Props) {
    const onChipClick = async () => {
        if (status === WizardTabStatus.Valid)
            if (leaveStepCallback) {
                if (await leaveStepCallback()) {
                    onClick()
                }
            } else {
                onClick()
            }
    }

    const icon = useMemo(() => {
        switch (status) {
            case WizardTabStatus.NotActivated:
                return settings.icon
            case WizardTabStatus.Valid:
            case WizardTabStatus.Active:
                return settings.activeIcon ? settings.activeIcon : settings.icon
        }
    }, [status, settings])

    return (
        <button className={getClassName(status)} onClick={onChipClick}>
            <IconWrapper>
                {icon}
            </IconWrapper>
            <span>{settings.title}</span>
        </button>
    )
}

export default WizardChip