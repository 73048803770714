import { useElectionPushResult, useElectionSummary } from 'api'
import MilestoneItem from 'components/atoms/MilestoneItem'
import { MilestoneColorType, ElectionStatusType } from 'models'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ElectionItem } from '../../models'
import { electionResultsColor } from '../../utils'

type DefaultElectionResultsCardProps = {
    election: ElectionItem,
    status: ElectionStatusType
}

const DefaultElectionResultsCard: React.FC<DefaultElectionResultsCardProps> = ({ election, status }) => {
    const { t } = useTranslation('election')
    const navigate = useNavigate()

    const { data: summary } = useElectionSummary(election?.electionId.toString(), !!election)
    const { data: electionPushResult } = useElectionPushResult(election?.electionId.toString() || '', !!election)

    const getCardData = () => {
        switch (status) {
            case ElectionStatusType.NOT_STARTED:
                return {
                    color: MilestoneColorType.DEFAULT,
                    buttonClass: 'btn-main-secondary-md',
                    buttonText: t('common:confirm_results'),
                    onClick: () => { },
                    disabled: true
                }
            case ElectionStatusType.IN_PROGRESS:
                return {
                    color: electionResultsColor(!!summary?.signedBy, electionPushResult),
                    buttonClass: !!summary?.signedBy ? 'btn-main-primary-md' : 'btn-main-secondary-md',
                    buttonText: t('common:confirm_results'),
                    onClick: () => navigate(`/confirm-results/${election.electionId}`),
                    disabled: !summary?.signedBy
                }
            case ElectionStatusType.COMPLETED:
                return {
                    color: MilestoneColorType.SUCCESS,
                    buttonClass: 'btn-main-secondary-md',
                    buttonText: t('common:view_results'),
                    onClick: () => navigate(`/confirmed-results/${election.electionId}`),
                    disabled: false
                }
            default:
                return null
        }
    }

    const cardData = getCardData()

    if (!cardData) {
        return null
    }

    const { color, buttonClass, buttonText, onClick, disabled } = cardData

    return (
        <MilestoneItem
            title={t('election_results')}
            color={color}
            children={
                <button
                    className={buttonClass}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {buttonText}
                </button>
            }
        />
    )
}

export default DefaultElectionResultsCard