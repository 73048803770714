import { useQuery } from '@tanstack/react-query'
import { getModuleUrl, useAxios } from 'api'
import { ApiModule } from 'api/api-module'
import { Election } from 'models'

export const useElection = (id: string, enabled?: boolean) => {
    const axios = useAxios()
    const moduleUrl = getModuleUrl(ApiModule.Administration)

    const getElection = async (id: string) => {
        const res = await axios<Election>(`${moduleUrl}elections/${id}`)

        return res.data
    }

    return useQuery({
        queryKey: ['election', id],
        queryFn: () => getElection(id),
        enabled
    })
}