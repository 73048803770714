import { setTokenToApi } from 'api'
import { AccessTokenContext } from 'context'
import { useContext } from 'react'

export const useAccessToken = () => {
    const context = useContext(AccessTokenContext)

    return {
        accessToken: context.accessToken,
        setAccessToken: (accessToken: string | null) => {
            setTokenToApi(accessToken || '')
            context.setAccessToken(accessToken)
        }
    }
}