import { AbilityBuilder } from '@casl/ability'
import { Auth, Role } from 'models'
import { AppAbility } from './ability'

export type ManageStationBallotSubject = 'StationBallot' | 'TellingProcess'
export function defineManageStationBallotAbility(builder: AbilityBuilder<AppAbility>, auth?: Auth) {
    const { can } = builder

    if (auth && auth.role && auth.role === Role.TELLING_STATION) {
        can('submit', 'StationBallot')
        can('edit', 'StationBallot')
        can('redirect', 'TellingProcess')
    }
}