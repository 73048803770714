
import { configuration } from './features/configuration'
import { useResetAdminDashboardData, useViewUser } from './hooks'
import Dashboard from './pages/Dashboard'

export const administration = {
    hooks: {
        useViewUser,
        useResetAdminDashboardData
    },
    pages: {
        Dashboard
    },
    features: {
        configuration
    }
}