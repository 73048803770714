import {ElectionParticipant, SortOrder} from 'models'
import {EligibleIndividualsParticipantFilter} from '../models'

export const filterParticipants = (
    allParticipants: ElectionParticipant[],
    filter: EligibleIndividualsParticipantFilter
): ElectionParticipant[] => {
    let participants = [...allParticipants]

    let sortingOrder = SortOrder.ASC
    let sortingField = 'name'

    if (filter.sortBy) {
        sortingOrder = filter.sortBy.order
        sortingField = filter.sortBy.field
    }

    participants = participants.sort((a: any, b: any) => {
        const conditions = sortingOrder === SortOrder.ASC
            ? [
                (p1: any, p2: any) => p1 > p2,
                (p1: any, p2: any) => p1 < p2
            ]
            : [
                (p1: any, p2: any) => p1 < p2,
                (p1: any, p2: any) => p1 > p2
            ]

        if (sortingField === 'homeLocalUnit') {
            if (conditions[0](a[sortingField]['code'], b[sortingField]['code'])) {
                return 1
            }
            if (conditions[1](a[sortingField]['code'], b[sortingField]['code'])) {
                return -1
            }
        }

        if (conditions[0](a[sortingField], b[sortingField])) {
            return 1
        }
        if (conditions[1](a[sortingField], b[sortingField])) {
            return -1
        }

        if (sortingField !== 'name') {
            const field = 'name'
            if (a[field] > b[field]) {
                return 1
            }
            if (a[field] < b[field]) {
                return -1
            }
        }

        return 0
    })

    if (filter.name) {
        const names = filter.name!.trim().toLowerCase().split(' ').filter(n=>!!n)
        names.forEach(name=>{
            participants = participants.filter(p => p.name.toLowerCase().includes(name))
        })
    }

    if (filter.id) {
        const id = (filter.id! as any).trim()
        participants = participants.filter(p => p.id.toLowerCase().includes(id))
    }

    if (filter.gender) {
        const gender = (filter.gender! as any).trim()
        participants = participants.filter(p => p.gender === gender)
    }

    if (filter.ageCategory) {
        const ageCategory = (filter.ageCategory! as any).trim()
        participants = participants.filter(p => p.ageCategory === ageCategory)
    }

    if (filter.homeLocality?.length) {
        const states = filter.homeLocality!.map(s => s.toLowerCase())
        participants = participants.filter(p => states.includes(p.homeLocality?.code?.toLowerCase()))
    }

    if (filter.homeLocalUnit?.length) {
        const units = filter.homeLocalUnit!.map(s => s.toLowerCase())
        participants = participants.filter(p => units.includes(p.homeLocalUnit?.code?.toLowerCase()))
    }

    if (filter.city?.length) {
        const localities = filter.city!.map(l => l.toLowerCase())
        participants = participants.filter(p => localities.includes(p.city?.toLowerCase()))
    }
    
    return participants
}