import { useNavigate } from 'react-router-dom'

export function useHandleEntityLoadingError() {
    const navigate = useNavigate()

    const handleEntityLoadingError = (error: any) => {
        const errorStatus = error?.response?.status
        if (errorStatus) {
            switch (errorStatus) {
                case 404:
                    navigate('/', { replace: true })
                    break
                case 403:
                    navigate('/access-denied', { replace: true })
                    break
            }
        }
    }

    return { handleEntityLoadingError }
}